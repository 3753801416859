import { ProcessingSpinner } from "@sermo/ui-components";
import { useEffect, useState } from "react";
import PostEditor from "@components/PostEditor";
import { PostUIDefaultValues, PostUIProvider } from "@contexts/PostUI";
import { PostUIEditingActionTypes } from "@contexts/PostUI/Editing";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useFetch, useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./Draft.styles";
const Draft = ({ type, publishCard, }) => {
    const { dispatch: dispatchPostUIEditing } = useMemoizedContext("postUIEditing", []);
    const { postEditorSelectedType } = useMemoizedContext("ui", ["postEditorSelectedType"]);
    const [initialized, setInitialized] = useState(false);
    const [initialState, setInitialState] = useState({});
    // ensure we get a fresh copy of the draft when switching to it
    // so the data is always up to date
    const [updateDraft, setUpdateDraft] = useState(0);
    const [data, loading] = useFetch("api/posts/getDraft", {
        postType: type,
        rerun: updateDraft,
    });
    useEffect(() => {
        if (initialized
            && type === postEditorSelectedType) {
            setInitialized(false);
            setUpdateDraft(updateDraft + 1);
        }
        // dont include updateDraft or you will get an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        postEditorSelectedType,
        type,
    ]);
    const { suggestedTopics = [], ...cardDraftData } = data;
    useEffect(() => {
        if (!loading
            && !initialized) {
            dispatchPostUIEditing({
                type: PostUIEditingActionTypes.SET_SUGGESTED_TOPICS,
                payload: {
                    suggestedTopics,
                },
            });
            let is = {
                ...PostUIDefaultValues,
                draftStatus: DraftStatusTypes.COMPOSING,
                cardDraftData,
            };
            const { contentHtml } = cardDraftData;
            if (!contentHtml
                || (typeof contentHtml === "string"
                    && (!contentHtml.trim().length
                        || !contentHtml.startsWith("<")))) {
                is.cardDraftData.contentHtml = "<p></p>";
            }
            setInitialState(is);
        }
    }, [
        cardDraftData,
        dispatchPostUIEditing,
        initialized,
        loading,
        suggestedTopics,
    ]);
    useEffect(() => {
        if (Object.keys(initialState).length > 0) {
            setInitialized(true);
        }
    }, [initialState]);
    if (loading
        && type === postEditorSelectedType) {
        return (<Styles.ProcessingSpinnerContainer>
				<ProcessingSpinner height={32} width={32}/>
			</Styles.ProcessingSpinnerContainer>);
    }
    if (!loading
        && initialized) {
        return (<PostUIProvider initialState={initialState} key={data.id}>
				{type === postEditorSelectedType
                && <PostEditor publishCard={publishCard}/>}
			</PostUIProvider>);
    }
    return null;
};
export default Draft;
