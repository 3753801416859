import PropTypes from "prop-types";
import React from "react";
import { RadioButtonList } from "@components/FormFields/FormFields";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { useTrackEvent } from "../../../tracking/tracking";

const filters = [
	{
		name: getTranslation("frontend.railCards.drugRatingFilter.brand", true),
		value: "DrugBrand",
	},
	{
		name: getTranslation("frontend.railCards.drugRatingFilter.indication", true),
		value: "Indication",
	},
];

const DrugRatingFilter = ({ trackingCategory }) => {
	const trackEvent = useTrackEvent();
	const { 
		ratingType, 
		searchUpdate, 
	} = useMemoizedContext("search", [
		"ratingType", 
		"searchUpdate",
	]);

	const changeHandler = (val) => {
		trackEvent({
			category: trackingCategory,
			action: "selection",
			label: val.toLowerCase(),
		});
		searchUpdate({ ratingType: val });
	};

	return (
		<ModuleWrapper
			label={getTranslation("frontend.railCards.drugRatingFilter.label", true)}
			border={true}
			contextClass="left-rail"
		>
			<RadioButtonList
				className="full-width"
				name="ratingType"
				options={filters}
				value={ratingType}
				contextClass="rail-module"
				handleChange={changeHandler}
			/>
		</ModuleWrapper>
	);
};

DrugRatingFilter.propTypes = {
	trackingCategory: PropTypes.string,
};

DrugRatingFilter.defaultProps = {
	trackingCategory: "search-results-filter-drug-ratings",
};

export default DrugRatingFilter;
