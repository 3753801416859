import { ColorGrey60 } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { applyBorderDarker } from "@frontend/styled";
import { StyledMobileAndTabletPortraitQuery } from "../MediaQueries/MediaQueries";

export const InfoScrollerContainer = styled(TextStyled)`
	&:not(.largerWithoutBackground) {
		font-family: Graphik;
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
	}
	& strong {
		// overrides the default font-weight for strong tags in TextStyled.
		font-weight: 600;
	}
	max-height: 96px;
	padding: 16px;
	background-color: ${({ theme }) => theme.color.ui02};
	overflow-y: auto;
	color: ${ColorGrey60};

	&.sticky {
		padding: 12px 16px 0;
		border-radius: 0 !important;
	}

	.open &.sticky {
		padding: 16px 16px 0;
	}

	&.no-max-height {
		max-height: none;
		height: 100%;
	}

	&.with-air {
		margin-bottom: 16px;
		margin-top: 16px;
		margin-left: -32px;
		margin-right: -32px;
	}

	& + .info-scroller {
		margin-top: 0;
	}

	&.in-feed {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 0;
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	&.has-featured {

		&.in-feed {
			margin-top: 0;
		}

		&.isi-only {
			margin-top: 17px;
		}

		&.headline-and-read-more {
			margin-top: 17px;
		}
	}

	a {
		color: ${({ theme }) => theme.color.textIsi};
		text-decoration: underline;
	}

	p {
		margin-bottom: 5px;
	}

	ul {
		margin-bottom: 5px;
	}

	sup {
		top: -0.35em;
	}

	&.expanded {
		max-height: 260px;
		margin: 40px 0 25px;
		padding: 16px;

		sup {
			font-size: x-small;
		}

		sub {
			font-size: x-small;
		}

		&.above-poll {
			margin-top: 25px !important;
			margin-bottom: 40px !important;
		}

		&.on-top {
			margin-top: 25px;
		}
	}

	&.suppress-padding {
		margin-left: -16px;
		margin-right: -16px;
	}

	&.largerWithoutBackground {
		background-color: ${({ theme }) => theme.color.uiBackground};
		color: ${({ theme }) => theme.color.text01};
		max-height: 96px;
		${applyBorderDarker("top bottom")};
		padding: 16px;
		border-left-width: 0;
		border-right-width: 0;

		sup {
			top: -0.7em;
		}

		&.in-feed {
			max-height: 96px;
		}

		&.expanded {
			max-height: 400px;
			margin: 40px 0 25px;
			padding: 16px;
			border-left-width: 1px;
			border-right-width: 1px;

			&.above-poll {
				margin-top: 25px !important;
				margin-bottom: 40px !important;
			}

			&.on-top {
				margin-top: 25px;
				margin-bottom: 40px;
			}
		}
	}

	&.merck & {
		color: ${({ theme }) => theme.color.text01};
	}

	${StyledMobileAndTabletPortraitQuery} {
		padding-left: 16px;
		padding-right: 16px;
	}
`;