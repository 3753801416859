import { useMemo } from "react";
import { ContentContainer, contentContainerClickHandler, maxLength, sanitizeLinks, useContentAreaClickEventHandler, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import ReadMore from "@components/PostContent/components/ReadMore";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./UserGeneratedContentInFeed.styles";
const UserGeneratedContentInFeed = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { contentHtml, id, originalContentHtml, type, } = useMemoizedContext("postData", [
        "contentHtml",
        "id",
        "originalContentHtml",
        "type",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : contentHtml;
        if (type !== "Question") {
            // Check for max length of string, and shorten accordingly
            if (editorContent.length > maxLength) {
                // The initial trimmed string
                let trimmedString = editorContent.substring(0, maxLength);
                // Trim again to avoid breaking a word and add ...
                trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
                return `${trimmedString}...<span id=read-more-portal-${id}></span>`;
            }
        }
        return sanitizeLinks(editorContent);
    }, [
        contentHtml,
        id,
        originalContentHtml,
        showOriginalContent,
        type,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={PostTypeTypes.QUESTION === type
                ? "heading03"
                : "bodyLong02"} $legacy={PostTypeTypes.QUESTION !== type} $zeroMarginBottom={PostTypeTypes.QUESTION === type} onClick={contentAreaClickEventHandler}/>
					<ReadMore />
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentHtml,
        contentToDisplay,
        id,
        readOnlyMember,
        resizeListener,
        type,
    ]);
};
export default UserGeneratedContentInFeed;
