import {
	ColorGrey60,
	ColorGrey100,
	TypographyBs01FontFamily,
	TypographyBs01FontSize,
	TypographyBs01FontWeight,
	TypographyBs01LineHeight,
	TypographyBs03FontFamily,
	TypographyBs03FontSize,
	TypographyBs03FontWeight,
	TypographyBs03LineHeight,
} from "@sermo/design-tokens";
import { onMobileOrTabletPortrait, Panel } from "@sermo/ui-components";
import styled from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const StyledHeader = styled.div`
	color: #101830;
	margin-bottom: 8px;
`;

export const StyledSponsorLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 48px;
	> div {
		padding-right: 4px;
	}
	& img {
		object-fit: contain;
		max-width: 128px;
		max-height: 72px;
	}
`;

const PanelStyled = styled(Panel)`
  border-radius: 8px;
`;

export const StyledPanelPadded = styled(PanelStyled)`
	padding: 16px;
	margin-bottom: 16px;

	& label, > label ~ div:last-of-type { // Targets library fields (label) and custom dropdown
		margin-bottom: 16px;
	}

	& label[for=countryCode] {
			padding: 0;
			margin-bottom: 4px;
	}

	& > div[data-component="profile"]:not(:last-child) {
		margin-bottom: 32px;
	}

	${onMobileOrTabletPortrait(`
		width: 100%;
		margin: 0;
		border-radius: 0;
	`)}
`;

export const StyledTitlePanel = styled.div`
	color: #101830;
	margin-bottom: 16px;
`;

export const StyledSeparator = styled.div.attrs(props => props)`
	background-color: #ecedef;
	height: 1px;
	margin: ${props => props.top}px 0 ${props => props.bottom}px 0;
`;

export const StyledPanel = styled(PanelStyled)`
	margin-bottom: 16px;

	@media ${MobileAndTabletPortraitQuery} {
		margin-bottom: 4px;
		border-radius: 0;
	}
`;

export const StyledTitle = styled.div`
	margin-top: 4px;
`;

export const StyledEventRightRail = styled.div``;

export const StyledTextBS03 = styled.div`
	color: ${ColorGrey100};
	font-family: ${TypographyBs03FontFamily};
	font-size: ${TypographyBs03FontSize};
	font-weight: ${TypographyBs03FontWeight};
	line-height: ${TypographyBs03LineHeight};
`;

export const StyledTextBS01 = styled.div`
	color: ${ColorGrey60};
	font-family: ${TypographyBs01FontFamily};
	font-size: ${TypographyBs01FontSize};
	font-weight: ${TypographyBs01FontWeight};
	line-height: ${TypographyBs01LineHeight};
`;
