import { ColorWhite } from "@sermo/design-tokens";
import { Select } from "@sermo/ui-components";
import styled from "styled-components";

export const SubNav = styled.div`
	width: 100%;
	min-height: 48px;
	padding: 0 12px;
	display: flex;
	align-items: center;
	background-color: ${ColorWhite};
	border-bottom: 1px solid #ecedef;
	position: sticky;
	top: 0;
	z-index: 1;
`;

export const StyledSelect = styled(Select)`
	margin-left: 16px;

	span, svg {
		color: ${({ theme }) => theme.color.text01};
	}

	span {
		font-weight: 500;
		line-height: 20px;
	}

	svg {
		width: 24px;
		height: 24px;
	}
`;