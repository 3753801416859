import { Icon, VerifiedCheckSVG } from "@sermo/ui-components";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import Button from "@components/Button/Button";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { Option } from "@components/FormFields/FormFields";
import SetNewUsernameModal from "@components/Modal/Modals/SetNewUsernameModal";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";
import { getUrlParameter } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./FirstTimeLogin.scss";

const FirstTimeLogin = () => {
	if ("undefined" === typeof window) {
		return null;
	}

	return (<>
		<FirstTimeLoginWrapped />
	</>)
}

const FirstTimeLoginWrapped = () => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const fileInputRef = React.createRef();
	const limitToFileTypes = window?.sermo?.supportProperties.allowedImageExtensions;
	const memberContext = useMemoizedContext("member", ["username"]);
	const openModal = useOpenModal();
	const validatePreviewImageRef = React.createRef();
	const [loading, setLoading] = useState(false);
	const [showSetNewUsernameModal, setShowSetNewUsernameModal] = useState(false);
	const [didChangeProfilePicture, setDidChangeProfilePicture] = useState(false);
	const [selectedPredefinedProfilePicture, setSelectedPredefinedProfilePicture] = useState("");
	const [previewImageSource, setPreviewImageSource] = useState(
		memberContext.profilePic
			? memberContext.profilePic
			: window.sermo.publicCdnBaseUrl + "profileimages/user.png"
	);
	const [validatePreviewImageSource, setValidatePreviewImageSource] = useState("");
	const [previewImageStyle, setPreviewImageStyle] = useState({});
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [profilePictureFile, setProfilePictureFile] = useState(null);
	const [currentStep, setCurrentStep] = useState(
		memberContext.legacyUser
			? "selectDisplayName"
			: "selectProfilePicture"
	);
	const [useRealName, setUseRealName] = useState(true);
	const [anonymizeOldPostsAndComments, setAnonymizeOldPostsAndComments] = useState(false);

	const displayNameChangeHandler = (selectedValue) => {
		setUseRealName(selectedValue === "realName");
	};

	const anonymizeOldPostsAndCommentsChangeHandler = (selectedValue) => {
		setAnonymizeOldPostsAndComments(selectedValue === "anonymous");
	};

	const expandSelectDisplayNameClickHandler = () => {
		setCurrentStep("selectDisplayName");
	};

	const submitSelectDisplayNameClickHandler = () => {
		if (useRealName) {
			setCurrentStep("anonymizeOldPostsAndComments");
		} else {
			setCurrentStep("selectProfilePicture");
		}
	};

	const expandAnonymizeOldPostsAndCommentsClickHandler = () => {
		setCurrentStep("anonymizeOldPostsAndComments");
	};

	const submitAnonymizeOldPostsAndCommentsClickHandler = () => {
		setCurrentStep("selectProfilePicture");
	};

	const openFileDialog = () => {
		fileInputRef.current.click();
	};

	const isFileExtensionAllowed = (fileName) => {
		if (limitToFileTypes) {
			if (limitToFileTypes.length > 0) {
				let a = fileName.split(".");
				let extension = a[a.length - 1].toLowerCase().trim();

				let s = limitToFileTypes.split(",");
				let t = [];
				for (let i = 0; i < s.length; i++) {
					let h = s[i].toLowerCase().trim().replace(".", "");
					if (h.length > 0) {
						if (extension === h) {
							return true;
						}
					}
				}

				return false;
			}
		}

		return true;
	};

	const onFileAdded = (event) => {
		setPreviewImageSource("");

		let file = event.target.files.item(0);

		if (file !== null) {
			if (isFileExtensionAllowed(file.name)) {
				setProfilePictureFile(file);

				setSelectedPredefinedProfilePicture("");

				let reader = new FileReader();

				reader.onload = (e) => {
					setValidatePreviewImageSource(e.target.result);
				};

				reader.readAsDataURL(file);

				event.target.files.item(0);

				setDidChangeProfilePicture(true);
			} else {
				setGlobalValidationError(
					getTranslation(
						"system.validationMessages.validationOnlySpecifiedFileTypesAreAllowed",
						true,
						limitToFileTypes
					)
				);
			}
		} else {
			setGlobalValidationError(getTranslation("system.validationMessages.validationPleaseSelectAFile", true));
		}
	};

	const handleValidateImagePreviewLoaded = (e) => {
		let maxWidth = 70;
		let maxHeight = 70;

		let imageWidth = validatePreviewImageRef.current.width;
		let imageHeight = validatePreviewImageRef.current.height;

		if (imageWidth >= maxWidth && imageHeight >= maxHeight) {
			let previewImageWidth = maxWidth;
			let previewImageHeight = maxHeight;
			let previewImageTop = 0;
			let previewImageLeft = 0;

			if (imageHeight > imageWidth) {
				previewImageHeight = Math.round((imageHeight / imageWidth) * maxHeight);
				previewImageTop = Math.round((maxHeight - previewImageHeight) / 2.0);
			} else if (imageHeight < imageWidth) {
				previewImageWidth = Math.round((imageWidth / imageHeight) * maxWidth);
				previewImageLeft = Math.round((maxWidth - previewImageWidth) / 2.0);
			}

			setPreviewImageStyle({
				width: previewImageWidth + "px",
				height: previewImageHeight + "px",
				left: previewImageLeft + "px",
				top: previewImageTop + "px",
			});

			setPreviewImageSource(validatePreviewImageSource);
		} else {
			setGlobalValidationError(getTranslation("system.validationMessages.validationImageTooSmall", true));

			fileInputRef.current.value = null;
		}
	};

	const selectPredefinedImageClickHandler = (selectedImageUrl) => {
		setPreviewImageStyle({
			width: "100%",
			height: "100%",
			left: "0",
			top: "0",
		});

		setSelectedPredefinedProfilePicture(selectedImageUrl.substring(selectedImageUrl.lastIndexOf("/") + 1));

		setPreviewImageSource(selectedImageUrl);

		setDidChangeProfilePicture(true);
	};

	const submitForm = (formObject) => {
		setLoading(true);

		apiEndpoint(
			"settings/completeFirstTimeLoginProfileSettings",
			formObject,
			true
		).then((dataResponse) => {
			dataResponse.json().then((body) => {
				setLoading(false);
				if (dataResponse.ok) {
					const returnUrl = getUrlParameter("returnUrl");

					if (returnUrl) {
						// TODO fix this on the server
						setTimeout(() => {
							window.location = returnUrl;
						}, 200);
					} else {
						memberContext.updateMember({
							profilePic: body.profilePictureUrl,
							hasConfiguredProfile: true,
						});
					}
				} else {
					if (body.message) {
						setGlobalValidationError(body.message);
					}

					if (body.fields !== null) {
						for (var property in body.fields) {
							setGlobalValidationError(body.fields[property].join("<br />"));
						}
					}
				}
			});
		});
	};

	const submitFormClickHandler = () => {
		const data = new FormData();
		data.append("useRealName", useRealName);
		data.append("anonymizeOldPostsAndComments", anonymizeOldPostsAndComments);

		if (didChangeProfilePicture) {
			if (selectedPredefinedProfilePicture && selectedPredefinedProfilePicture.length > 0) {
				data.append("profilePicturePredefined", selectedPredefinedProfilePicture);
			} else {
				data.append("profilePictureFile", profilePictureFile, profilePictureFile.name);
			}
		}

		submitForm(data);
	};

	const skipFormClickHandler = () => {
		const data = new FormData();
		data.append("useRealName", useRealName);
		data.append("anonymizeOldPostsAndComments", anonymizeOldPostsAndComments);

		submitForm(data);
	};

	const newUsernameSubmittedSuccessfullyEventHandler = (newUsername) => {
		memberContext.updateMember(
			{
				username: newUsername,
				displayName: newUsername,
			});
		setShowSetNewUsernameModal(false);
		setUseRealName(false);
	};

	const toggleSetNewUsernameModalClickHandler = (e) => {
		if (e) {
			e.preventDefault();
		}

		setShowSetNewUsernameModal(!showSetNewUsernameModal);
	};

	const toggleSetUseRealNameModalClickHandler = (e) => {
		if (e) {
			e.preventDefault();
		}
		setUseRealName(true);
	};

	useEffect(() => {
		if (showSetNewUsernameModal) {
			openModal({
				[EUIKey.MODAL_CLOSE_METHOD]: toggleSetNewUsernameModalClickHandler,
				[EUIKey.MODAL_COMPONENT]: (
					<SetNewUsernameModal
						newUsernameSubmittedSuccessfullyEventHandler={newUsernameSubmittedSuccessfullyEventHandler}
						closeModalEventHandler={toggleSetNewUsernameModalClickHandler}
					/>
				),
				[EUIKey.MODAL_LABEL]: "set-new-username-modal",
				[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.setNewUsername.title", true),
			});
		}

		if (!showSetNewUsernameModal) {
			closeModal("set-new-username-modal");
		}
	}, [showSetNewUsernameModal]);

	let fullName = "";
	let specialties = "";

	if (memberContext.firstName !== null && memberContext.firstName !== "") {
		if (memberContext.lastName !== null && memberContext.lastName !== "") {
			fullName = memberContext.firstName + " " + memberContext.lastName;
		} else {
			fullName = memberContext.firstName;
		}
	} else if (memberContext.lastName !== null && memberContext.lastName !== "") {
		fullName = memberContext.lastName;
	}

	if (memberContext.primarySpecialtyName !== null && memberContext.primarySpecialtyName !== "") {
		if (memberContext.secondarySpecialtyName !== null && memberContext.secondarySpecialtyName !== "") {
			specialties = memberContext.primarySpecialtyName + ", " + memberContext.secondarySpecialtyName;
		} else {
			specialties = memberContext.primarySpecialtyName;
		}
	} else if (memberContext.secondarySpecialtyName !== null && memberContext.secondarySpecialtyName !== "") {
		specialties = memberContext.secondarySpecialtyName;
	}

	return (
		<div data-component={"FirstTimeLogIn"}>
			<ModuleWrapper
				border={true}
				solid={true}
			>
				<div styleName={classNames(["styles.inner-module-container"])}>
					{
						memberContext.legacyUser && (
							<>
								<div styleName={classNames(["styles.step-container", "styles.step-container--first"])}>
									<div
										styleName={
											classNames([
												"styles.step-header",
												{ [`styles.step-header--collapsed`]: currentStep !== "selectDisplayName" },
											])
										}
									>
										<div styleName={classNames(["styles.title", "typography.heading-brand-03"])}>
											{getTranslation("frontend.firstTimeLoginProfileConfiguration.appearanceTitle")}
										</div>
										{
											currentStep !== "selectDisplayName" && (
												<div styleName="styles.edit-button">
													<Button
														style="flatNeutral"
														theme="light"
														size="medium"
														clickHandler={expandSelectDisplayNameClickHandler}
													>
														{getTranslation("frontend.generics.edit", true)}
													</Button>
												</div>
											)
										}
									</div>

									{
										currentStep === "selectDisplayName" && (
											<div styleName={classNames(["styles.step-content"])}>
												<div styleName={classNames(["styles.step-content--options"])}>
													<Option
														active={
															useRealName
																? 0
																: 1
														}
														options={
															[
																{
																	title: fullName,
																	description: specialties,
																	value: "realName",
																},
																{
																	title: memberContext.username,
																	description: specialties,
																	value: "username",
																},
															]
														}
														changeHandler={displayNameChangeHandler}
													/>
												</div>

												<div
													styleName={
														classNames([
															"styles.content",
															"typography.body-long-brand-02",
														])
													}
												>
													{
														getTranslation(
															"frontend.firstTimeLoginProfileConfiguration.appearanceContent"
														)
													}
												</div>

												<div styleName="styles.submit-container">
													<Button
														name="submit"
														size="medium"
														clickHandler={submitSelectDisplayNameClickHandler}
													>
														{getTranslation("frontend.generics.next", true)}
													</Button>
												</div>
											</div>
										)
									}
								</div>

								{
									memberContext.legacyUser && currentStep !== "selectDisplayName" && useRealName && (
										<div
											styleName={
												classNames([
													"styles.step-container",
													{
														"styles.step-content--bottom-border":
												currentStep === "anonymizeOldPostsAndComments",
													},
												])
											}
										>
											<div
												styleName={
													classNames([
														"styles.step-header",
														{
															"styles.step-header--collapsed":
													currentStep !== "anonymizeOldPostsAndComments",
														},
													])
												}
											>
												<div
													styleName={
														classNames([
															"styles.step-indicator",
															"typography.overline-brand-01",
															{ "styles.hidden": currentStep !== "anonymizeOldPostsAndComments" },
														])
													}
												>
													{getTranslation("frontend.firstTimeLoginProfileConfiguration.step2Of3")}
												</div>
												<div styleName={classNames(["styles.title", "typography.heading-brand-03"])}>
													{
														getTranslation(
															"frontend.firstTimeLoginProfileConfiguration.pastContentTitle"
														)
													}
												</div>
												{
													currentStep !== "anonymizeOldPostsAndComments" && (
														<div styleName="styles.edit-button">
															<Button
																style="flatNeutral"
																theme="light"
																size="medium"
																clickHandler={expandAnonymizeOldPostsAndCommentsClickHandler}
															>
																{getTranslation("frontend.generics.edit", true)}
															</Button>
														</div>
													)
												}
											</div>

											{
												currentStep === "anonymizeOldPostsAndComments" && (
													<div styleName={classNames(["styles.step-content"])}>
														<div styleName={classNames(["styles.step-content--options"])}>
															<Option
																active={
																	anonymizeOldPostsAndComments
																		? 0
																		: 1
																}
																options={
																	[
																		{
																			title: getTranslation(
																				// eslint-disable-next-line max-len
																				"frontend.firstTimeLoginProfileConfiguration.pastContentMakeAnonymousOption",
																				true
																			),
																			value: "anonymous",
																		},
																		{
																			title: getTranslation(
																				// eslint-disable-next-line max-len
																				"frontend.firstTimeLoginProfileConfiguration.pastContentDisplayRealNameOption",
																				true
																			),
																			value: "realname",
																		},
																	]
																}
																changeHandler={anonymizeOldPostsAndCommentsChangeHandler}
															/>
														</div>

														<div
															styleName={
																classNames(
																	[
																		"styles.content",
																		"typography.body-long-brand-03",
																	]
																)
															}
														>
															{
																getTranslation(
																	"frontend.firstTimeLoginProfileConfiguration.pastContentContent"
																)
															}
														</div>

														<div styleName="styles.submit-container">
															<Button
																name="submit"
																size="medium"
																clickHandler={submitAnonymizeOldPostsAndCommentsClickHandler}
															>
																{getTranslation("frontend.generics.next", true)}
															</Button>
														</div>
													</div>
												)
											}
										</div>
									)
								}
							</>
						)
					}

					{
						currentStep !== "selectDisplayName" && (
							<div styleName={classNames(["styles.step-container", "styles.step-container--last"])}>
								<div
									styleName={
										classNames([
											"styles.step-header",
											{
												"styles.step-header--collapsed--no-border":
											currentStep !== "selectDisplayName",
											},
										])
									}
								>
									{
										memberContext.legacyUser && (
											<div
												styleName={
													classNames([
														"styles.step-indicator",
														"typography.overline-brand-01",
														{ "styles.hidden": currentStep === "anonymizeOldPostsAndComments" },
													])
												}
											>
												{
													memberContext.legacyUser && useRealName && (
														<>
															{getTranslation("frontend.firstTimeLoginProfileConfiguration.step3Of3")}
														</>
													)
												}
												{
													(!memberContext.legacyUser || !useRealName) && (
														<>
															{getTranslation("frontend.firstTimeLoginProfileConfiguration.step2Of2")}
														</>
													)
												}
											</div>
										)
									}
									<div
										styleName={
											classNames([
												"styles.title",
												"typography.heading-brand-03",
												{ "styles.title--faded": currentStep === "anonymizeOldPostsAndComments" },
											])
										}
									>
										{getTranslation("frontend.firstTimeLoginProfileConfiguration.profilePictureTitle")}
									</div>
								</div>

								{
									currentStep === "selectProfilePicture" && (
										<div styleName={classNames(["styles.step-content"])} data-component={"SelectProfilePic"}>
											<div styleName={classNames(["styles.content", "typography.body-short-brand-03"])}>
												{
													getTranslation(
														"frontend.firstTimeLoginProfileConfiguration.profilePictureContent"
													)
												}
											</div>

											<div styleName={classNames(["styles.images-wrapper"])}>
												<div styleName={classNames(["styles.profile-container"])}>
													<div styleName={classNames(["styles.profile-picture"])}>
														<img
															src={previewImageSource}
															styleName={classNames(["styles.profile-picture-image"])}
															style={previewImageStyle}
														/>
													</div>
													<div styleName="styles.hidden">
														<img
															src={validatePreviewImageSource}
															onLoad={handleValidateImagePreviewLoaded}
															ref={validatePreviewImageRef}
														/>
													</div>
													{
														useRealName && (
															<div
																styleName={
																	classNames([
																		"typography.heading-brand-04",
																		"styles.profile-name",
																	])
																}
															>
																{memberContext.firstName} {memberContext.lastName}
																<div styleName={classNames(["styles.profile-status-icon"])}>
																	<Icon
																		src={VerifiedCheckSVG}
																		width={27}
																		height={27}
																	/>
																</div>
															</div>
														)
													}
													{
														!useRealName && (
															<div
																styleName={
																	classNames([
																		"typography.heading-brand-04",
																		"styles.profile-name",
																	])
																}
															>
																{memberContext.username}
															</div>
														)
													}
													<div
														styleName={
															classNames([
																"typography.body-short-brand-02",
																"styles.profile-specialties",
															])
														}
													>
														{memberContext.primarySpecialtyName}
														{
															memberContext.secondarySpecialtyName && (
																<span>, {memberContext.secondarySpecialtyName}</span>
															)
														}
													</div>
												</div>

												{
													didChangeProfilePicture
														? (
															<Button
																name="submit"
																size="small"
																style="secondary"
																clickHandler={() => setDidChangeProfilePicture(false)}
															>
																{
																	getTranslation(
																	// eslint-disable-next-line max-len
																		"frontend.firstTimeLoginProfileConfiguration.profilePictureChangeImageButtonText",
																		true
																	)
																}
															</Button>
														)
														: (
															<>
																<div styleName={classNames(["styles.upload-image-container"])}>
																	<Button
																		name="submit"
																		size="large"
																		clickHandler={openFileDialog}
																	>
																		{
																			getTranslation(
																			// eslint-disable-next-line max-len
																				"frontend.firstTimeLoginProfileConfiguration.profilePictureUploadImageButtonText",
																				true
																			)
																		}
																	</Button>
																</div>

																<input
																	ref={fileInputRef}
																	styleName="styles.hidden"
																	type="file"
																	accept={limitToFileTypes}
																	onChange={onFileAdded}
																/>

																<div styleName={classNames(["styles.choose-image-container"])}>
																	<div
																		styleName={
																			classNames([
																				"styles.choose-image-text",
																				"typography.heading-brand-03",
																			])
																		}
																	>
																		{
																			getTranslation(
																			// eslint-disable-next-line max-len
																				"frontend.firstTimeLoginProfileConfiguration.profilePictureChooseSubtitleText"
																			)
																		}
																	</div>

																	<div styleName={classNames(["styles.predefined-images-container"])}>
																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user1.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user1.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user2.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user2.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user3.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user3.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user4.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user4.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user5.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user5.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user6.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user6.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user7.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user7.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user8.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user8.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user9.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user9.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user10.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user10.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user11.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user11.png"
																				}
																			/>
																		</div>

																		<div
																			styleName={classNames(["styles.predefined-image"])}
																			onClick={
																				() => {
																					selectPredefinedImageClickHandler(
																						window.sermo.publicCdnBaseUrl
																		+ "profileimages/user12.png"
																					);
																				}
																			}
																		>
																			<img
																				src={
																					window.sermo.publicCdnBaseUrl
																	+ "profileimages/user12.png"
																				}
																			/>
																		</div>
																	</div>
																</div>
															</>
														)
												}
											</div>

											<GlobalValidationMessage text={globalValidationError} />

											<div styleName="styles.submit-container">
												<Button
													name="skip"
													style="flatNeutral"
													contextClass="modal-cancel"
													theme="light"
													size="medium"
													disabled={loading}
													clickHandler={skipFormClickHandler}
												>
													{
														getTranslation(
															// eslint-disable-next-line max-len
															"frontend.firstTimeLoginProfileConfiguration.profilePictureSkipButtonText",
															true
														)
													}
												</Button>
												<Button
													name="submit"
													size="medium"
													clickHandler={submitFormClickHandler}
													disabled={loading}
													loading={loading}
												>
													{
														getTranslation(
															// eslint-disable-next-line max-len
															"frontend.firstTimeLoginProfileConfiguration.profilePictureCompleteSetupButtonText",
															true
														)
													}
												</Button>
											</div>
										</div>
									)
								}
							</div>
						)
					}
				</div>
			</ModuleWrapper>

			{
				!memberContext.legacyUser && (
					<div styleName={classNames(["styles.first-time-member-username-container"])}>
						{
							useRealName && !memberContext.username && (
								<div
									styleName={
										classNames([
											"styles.first-time-member-username-text",
											"typography.body-short-brand-02",
										])
									}
								>
									{getTranslation("frontend.firstTimeLoginProfileConfiguration.addUsernameFooterText")}
									<a
										href="#"
										styleName={classNames(["styles.first-time-member-username-modal-trigger"])}
										onClick={toggleSetNewUsernameModalClickHandler}
									>
										{
											getTranslation(
												"frontend.firstTimeLoginProfileConfiguration.addUsernameFooterActionLinkText",
												true
											)
										}
									</a>
								</div>
							)
						}
						{
							useRealName && memberContext.username && (
								<div
									styleName={
										classNames([
											"styles.first-time-member-username-text",
											"typography.body-short-brand-02",
										])
									}
								>
									{getTranslation("frontend.firstTimeLoginProfileConfiguration.useUsernameFooterText")}
									<a
										href="#"
										styleName={classNames(["styles.first-time-member-username-modal-trigger"])}
										onClick={toggleSetNewUsernameModalClickHandler}
									>
										{
											getTranslation(
												"frontend.firstTimeLoginProfileConfiguration.useUsernameFooterActionLinkText",
												true
											)
										}
									</a>
								</div>
							)
						}
						{
							!useRealName && (
								<div
									styleName={
										classNames([
											"styles.first-time-member-username-text",
											"typography.body-short-brand-02",
										])
									}
								>
									{getTranslation("frontend.firstTimeLoginProfileConfiguration.useRealNameFooterText")}
									<a
										href="#"
										styleName={classNames(["styles.first-time-member-username-modal-trigger"])}
										onClick={toggleSetUseRealNameModalClickHandler}
									>
										{
											getTranslation(
												"frontend.firstTimeLoginProfileConfiguration.useRealNameFooterActionLinkText",
												true
											)
										}
									</a>
								</div>
							)
						}
					</div>
				)
			}
		</div>
	);
};

export default FirstTimeLogin;
