import { ColorGrey100 } from "@sermo/design-tokens";
import {
	ChevronDownSVG,
	ChevronUpSVG,
	ClockSVG,
	CommentEmptySVG,
	Icon,
	PatientCaseSVG,
	PlayCircleFilledSVG,
	ResourceCenterSVG,
	SurveySVG,
} from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useCloseMenu } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./ActivityItem.scss";

const ActivityFieldIconStyled = styled(Icon)`
	fill: ${ColorGrey100};
`;

const ActivityItem = ({ activity, borderBottom, isFollowedPosts }) => {
	const closeMenu = useCloseMenu();
	const trackEvent = useTrackEvent();
	const [redirect, setRedirect] = useState("");

	const {
		brandAdministrator,
		id,
	} = useMemoizedContext("member", [
		"brandAdministrator",
		"id",
	]);

	useEffect(() => {
		if (redirect) {
			setRedirect("");
			closeMenu();
		}
	}, [redirect]);

	const {
		amount = "",
		commentId,
		eventId,
		imageUrls,
		memberId,
		new: isNew,
		notificationId,
		postId,
		resourceCenterId,
		resourceCenterItemId,
		text,
		time = "placeholder",
		type,
	} = activity;

	const actions = {
		post: [
			"LikedYourPost",
			"RepliedToYourComment",
			"VotedOnYourPoll",
			"WasVotedAsTopAnswer",
			"YourCommentReceivedVotes",
			"YourAnswerReceivedVotes",
			"MentionedYouInAPost",
			"MentionedYouInAComment",
			"YourAnswerWasVotedTopAnswer",
			"YourCommentWasSelectedBestAnswer",
			"YourPatientCaseIsUnsolved",
			"YourPollHasClosed",
			"NewPatientCaseInYourSpecialty",
			"FollowedMemberAskedAQuestion",
			"FollowedMemberSharedAPost",
			"FollowedMemberLikedASponsoredPost",
			"FollowedMemberCommentedOnASponsoredPost",
			"SermoTeamSharedAPost",
			"FollowedMemberPostedASermoContentCard",
		],
		postWithNotification: [
			"CommentedOnYourPost",
			"AnsweredYourQuestion",
			"FollowedPostHasBeenCommentedOn",
			"FollowedCommentHasBeenCommentedOn",
		],
		profile: [
			"StartedFollowingYou",
			"YourReferralHasJoined",
			"YourPostSurveyReferralHasJoined",
			"ReferralHasCompletedStudyButVerificationStillRequiredForBonus",
			"ReferralHasBeenVerifiedAndStudyCompletedBonusEarned",
			"ReferralHasBeenVerifiedButBonusIsPendingStudyCompletion",
			"ReferralHasBeenVerifiedAndPreviouslyPendingStudyHasBeenCompletedBonusEarned",
		],
		accountSettings: [
			"YourNameChangeHasBeenApproved",
			"YourSpecialtyChangeHasBeenApproved",
			"YourEmailChangeHasBeenApproved",
		],
		survey: [
			"YouScreenedOut",
			"QuotaIsFull",
			"YouCompletedASurvey",
			"RealTimeQualYouScreenedOut",
			"RealTimeQualQuotaIsFull",
			"RealTimeQualYouCompletedASurvey",
			"RealTimeQualOnHold",
		],
		surveyNoIncentives: [
			"YouScreenedOutNoIncentive",
			"QuotaIsFullNoIncentive",
			"YouCompletedASurveyNoIncentive",
			"RealTimeQualYouScreenedOutNoIncentive",
			"RealTimeQualQuotaIsFullNoIncentive",
			"RealTimeQualYouCompletedASurveyNoIncentive",
			"RealTimeQualOnHoldNoIncentive",
		],
		event: ["EventInvitationNotification", "EventReminderNotification", "EventInvitationReminderNotification"],
		resourceCenter: [
			"NewFollowersOnResourceCenter",
			"NewResourceCenterItemsPublished",
			"NewResourceCenterPublished",
		],
		resourceCenterItem: [
			"NewCommentsOnResourceCenter",
			"RepliedToYourCommentOnResourceCenter",
			"MentionedYouInResourceCenter",
		],
	};

	const icons = {
		profilePic: [
			"CommentedOnYourPost",
			"AnsweredYourQuestion",
			"LikedYourPost",
			"RepliedToYourComment",
			"VotedOnYourPoll",
			"WasVotedAsTopAnswer",
			"MentionedYouInAPost",
			"MentionedYouInAComment",
			"YourAnswerWasVotedTopAnswer",
			"YourCommentWasSelectedBestAnswer",
			"StartedFollowingYou",
			"YourPatientCaseIsUnsolved",
			"YourReferralHasJoined",
			"FollowedMemberSharedAPost",
			"FollowedMemberLikedASponsoredPost",
			"FollowedMemberCommentedOnASponsoredPost",
			"FollowedMemberAskedAQuestion",
			"YourNameChangeHasBeenApproved",
			"YourSpecialtyChangeHasBeenApproved",
			"YourEmailChangeHasBeenApproved",
			"YourPostSurveyReferralHasJoined",
			"ReferralHasCompletedStudyButVerificationStillRequiredForBonus",
			"ReferralHasBeenVerifiedAndStudyCompletedBonusEarned",
			"ReferralHasBeenVerifiedButBonusIsPendingStudyCompletion",
			"ReferralHasBeenVerifiedAndPreviouslyPendingStudyHasBeenCompletedBonusEarned",
			"FollowedMemberPostedASermoContentCard",
			"MentionedYouInResourceCenter",
		],
		sermoTeam: ["SermoTeamSharedAPost"],
		commentVotes: ["YourCommentReceivedVotes", "YourAnswerReceivedVotes"],
		clock: ["YourPollHasClosed"],
		patientCase: ["NewPatientCaseInYourSpecialty"],
		survey: actions.survey.concat(actions.surveyNoIncentives),
		event: actions.event,
		resourceCenter: actions.resourceCenter.concat(actions.resourceCenterItem),
	};

	const onClick = (e) => {
		e.stopPropagation();
		const actionKeys = Object.keys(actions);
		const actionType = actionKeys.find((action) => actions[action].includes(type));
		const routePrefix = brandAdministrator
			? "/profile/" + id + "/overview"
			: "/feed/all-sermo";

		let redirectValue = "";
		switch (actionType) {
			case "post":
				if (commentId) {
					redirectValue = routePrefix + `/post/${postId}/expanded/comment/${commentId}`;
					break;
				}
				redirectValue = routePrefix + `/post/${postId}/expanded`;
				break;
			case "postWithNotification":
				if (notificationId) {
					redirectValue = routePrefix + `/post/${postId}/expanded/activity/${notificationId}`;
					break;
				}
				redirectValue = routePrefix + `/post/${postId}/expanded`;
				break;
			case "profile":
				// redirecting to new follower profile if notification has single new
				// follower mentioned, otherwise redirecting to followers list
				redirectValue = memberId
					? `/profile/${memberId}`
					: `/profile/${id}/followers`;
				break;
			case "accountSettings":
				redirectValue = "/settings";
				break;
			case "survey":
				redirectValue = "/surveys/withdrawalhistory";
				break;
			case "surveyNoIncentives":
				redirectValue = "/surveys/surveyhistory";
				break;
			case "event":
				redirectValue = `/event/${eventId}`;
				break;
			case "resourceCenter":
				redirectValue = `/resource/${resourceCenterId}`;
				break;
			case "resourceCenterItem":
				redirectValue = `/resource/item/${resourceCenterItemId}`;
				break;
			default:
				break;
		}

		trackEvent({
			category: "notification",
			label: type.toLowerCase(),
			action: "click",
			url: redirectValue,
		});

		setRedirect(redirectValue);
	};

	const getIcon = () => {
		if (isFollowedPosts) {
			return (
				<div styleName={classNames("styles.followed", "typography.heading-brand-00")}>
					<Icon
						src={CommentEmptySVG}
						width={33}
						height={33}
					/>
					<div styleName="styles.comment-count">{`+${amount}`}</div>
				</div>
			);
		}

		const iconKeys = Object.keys(icons);
		const iconType = iconKeys.find((icon) => icons[icon].includes(type));

		switch (iconType) {
			case "sermoTeam":
				return (
					<div>
						<MobileAndTabletPortrait>
							<ProfilePic
								user={{ profilePic: "assets/images/sermo-mark-white.png" }}
								size={
									imageUrls.length > 1
										? "small"
										: "medium"
								}
							/>
						</MobileAndTabletPortrait>
						<TabletLandscapeAndDesktop>
							<ProfilePic
								user={{ profilePic: "assets/images/sermo-mark-white.png" }}
								size={
									imageUrls.length > 1
										? "medium"
										: "mediumlarge"
								}
							/>
						</TabletLandscapeAndDesktop>
					</div>
				)
			case "profilePic":
				if (imageUrls && imageUrls.length > 0) {
					const style = imageUrls.length === 1
						? "single"
						: "double";
					return (
						<div styleName={classNames("styles.profile-pic", `styles.${style}`)}>
							{
								imageUrls.map((url, i) => (
									<div key={i}>
										<MobileAndTabletPortrait>
											<ProfilePic
												user={{ profilePic: url }}
												size={
													imageUrls.length > 1
														? "small"
														: "medium"
												}
											/>
										</MobileAndTabletPortrait>
										<TabletLandscapeAndDesktop>
											<ProfilePic
												user={{ profilePic: url }}
												size={
													imageUrls.length > 1
														? "medium"
														: "mediumlarge"
												}
											/>
										</TabletLandscapeAndDesktop>
									</div>
								))
							}
						</div>
					);
				}
				return (
					<>
						<MobileAndTabletPortrait>
							<ProfilePic
								user={{ profilePic: undefined }}
								size={"medium"}
							/>
						</MobileAndTabletPortrait>
						<TabletLandscapeAndDesktop>
							<ProfilePic
								user={{ profilePic: undefined }}
								size={"mediumlarge"}
							/>
						</TabletLandscapeAndDesktop>
					</>
				);
			case "clock":
				return <ActivityFieldIconStyled
					src={ClockSVG}
				/>;
			case "commentVotes":
				return (
					<div styleName="styles.vote">
						<Icon src={ChevronUpSVG} />
						<div styleName="styles.vote-total">{amount}</div>
						<Icon src={ChevronDownSVG} />
					</div>
				);
			case "patientCase":
				return <ActivityFieldIconStyled
					src={PatientCaseSVG}
				/>;
			case "survey":
				return <ActivityFieldIconStyled
					src={SurveySVG}
				/>;
			case "event":
				return <ActivityFieldIconStyled
					src={PlayCircleFilledSVG}
				/>;
			case "resourceCenter":
			case "resourceCenterItem":
				return <ActivityFieldIconStyled
					src={ResourceCenterSVG}
				/>;
			default:
				return null;
		}
	};

	return useMemo(() => (
		<ModuleWrapper padded={false}>
			{Boolean(redirect) && <Navigate to={redirect} />}
			<div
				styleName={
					classNames(
						"styles.activity-item",
						{ "styles.new": isNew },
						{ "styles.include-bottom-border": borderBottom },
						`styles.${isFollowedPosts
							? "followed-post"
							: "general"}`
					)
				}
				onClick={onClick}
			>
				<div styleName={classNames("styles.left")}>{getIcon()}</div>
				<div styleName={classNames("styles.right")}>
					<div
						styleName={classNames("styles.text", "typography.body-short-02")}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
					{
						time && (
							<div styleName={classNames("styles.date", "typography.body-short-00")}>
								{
									isFollowedPosts
										? `${getTranslation("frontend.navMenu.lastComment", true)}: ${time}`
										: time
								}
							</div>
						)
					}
				</div>
			</div>
		</ModuleWrapper>
	), [redirect, isNew]);
};

ActivityItem.propTypes = {
	activity: PropTypes.shape({
		amount: PropTypes.number,
		commentId: PropTypes.oneOfType([PropTypes.number,PropTypes.bool]),
		imageUrls: PropTypes.arrayOf(PropTypes.string),
		memberId: PropTypes.oneOfType([PropTypes.number,PropTypes.bool]),
		new: PropTypes.bool.isRequired,
		notificationId: PropTypes.number.isRequired,
		popup: PropTypes.bool,
		postId: PropTypes.oneOfType([PropTypes.number,PropTypes.bool]),
		resourceCenterId: PropTypes.oneOfType([PropTypes.number,PropTypes.bool]),
		resourceCenterItemId: PropTypes.oneOfType([PropTypes.number,PropTypes.bool]),
		text: PropTypes.string,
		time: PropTypes.string,
		type: PropTypes.oneOf([
			"LikedYourPost",
			"RepliedToYourComment",
			"VotedOnYourPoll",
			"WasVotedAsTopAnswer",
			"YourCommentReceivedVotes",
			"YourAnswerReceivedVotes",
			"MentionedYouInAPost",
			"MentionedYouInAComment",
			"YourAnswerWasVotedTopAnswer",
			"YourCommentWasSelectedBestAnswer",
			"YourPatientCaseIsUnsolved",
			"YourPollHasClosed",
			"NewPatientCaseInYourSpecialty",
			"FollowedMemberAskedAQuestion",
			"FollowedMemberSharedAPost",
			"FollowedMemberLikedASponsoredPost",
			"FollowedMemberCommentedOnASponsoredPost",
			"SermoTeamSharedAPost",
			"CommentedOnYourPost",
			"AnsweredYourQuestion",
			"FollowedPostHasBeenCommentedOn",
			"FollowedCommentHasBeenCommentedOn",
			"StartedFollowingYou",
			"YourReferralHasJoined",
			"YourNameChangeHasBeenApproved",
			"YourSpecialtyChangeHasBeenApproved",
			"YourEmailChangeHasBeenApproved",
			"YouScreenedOut",
			"QuotaIsFull",
			"YouCompletedASurvey",
			"YouScreenedOutNoIncentive",
			"QuotaIsFullNoIncentive",
			"YouCompletedASurveyNoIncentive",
			"YourPostSurveyReferralHasJoined",
			"EventInvitationNotification",
			"EventReminderNotification",
			"EventInvitationReminderNotification",
			"FollowedMemberPostedASermoContentCard",
			"NewResourceCenterPublished",
			"NewResourceCenterItemsPublished",
			"NewFollowersOnResourceCenter",
			"NewCommentsOnResourceCenter",
			"RepliedToYourCommentOnResourceCenter",
		]),
	}).isRequired,
	borderBottom: PropTypes.bool.isRequired,
	isFollowedPosts: PropTypes.bool.isRequired,
};

ActivityItem.defaultProps = {
	postId: false,
	commentId: false,
}

export default ActivityItem;
