import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { PostDataActionTypes } from "@contexts/PostData";
import { useExpandPost } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
export const useExpand = () => {
    const expandPost = useExpandPost();
    const { cardData } = useMemoizedContext("postUI", ["cardData"]);
    const { dispatch: dispatchPostData } = useMemoizedContext("postData");
    return ({ commentsClicked = false, } = {}) => {
        expandPost({
            cardData,
            commentsClicked,
            syncPostDataFromExpanded: (postData) => {
                dispatchPostData({
                    type: PostDataActionTypes.SYNC_POST_DATA,
                    payload: {
                        postData,
                    },
                });
            },
        });
    };
};
const useExpandEffect = () => {
    const expand = useExpand();
    const { pathname, state, } = useLocation();
    const { expanded, isSingleCard, } = useMemoizedContext("postUIViewing", [
        "expanded",
        "isSingleCard",
    ]);
    const { adId } = useMemoizedContext("postData", ["adId"]);
    const hasExpandedInPathname = !!pathname.match(/\/expanded/);
    useEffect(() => {
        if (hasExpandedInPathname
            && !expanded) {
            if (isSingleCard
                && !state?.goBack) {
                expand();
            }
            else if (pathname.match(/\/search/)) {
                expand();
            }
        }
    }, []);
    // if we are on the expanded route expand it
    const adPreviewMatch = useMatch("/ad-preview/:routeAdId/*");
    const isExpandedPreview = adPreviewMatch?.params?.["*"]?.includes("expanded");
    const routeAdId = parseInt(adPreviewMatch?.params?.routeAdId ?? "");
    useEffect(() => {
        if (isExpandedPreview
            && routeAdId === adId) {
            expand();
        }
    }, [isExpandedPreview]);
    return null;
};
export default useExpandEffect;
