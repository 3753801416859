import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalWithdrawalComponents.scss";

const DetailPair = ({ title, value }) => (
	<div styleName="styles.detail-pair">
		<div styleName="styles.title">{title}</div>
		<div styleName="styles.value">{value}</div>
	</div>
);

DetailPair.propTypes = {
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default DetailPair;
