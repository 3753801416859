export const MEMBER_LOGOUT = "MEMBER_LOGOUT";
export const MEMBER_UPDATE = "MEMBER_UPDATE";
export const MEMBER_TOPIC_FOLLOW = "MEMBER_TOPIC_FOLLOW";
export const MEMBER_TOPIC_UNFOLLOW = "MEMBER_TOPIC_UNFOLLOW";
export const MEMBER_FOLLOW = "MEMBER_FOLLOW";
export const MEMBER_UNFOLLOW = "MEMBER_UNFOLLOW";
export const MEMBER_PRIVACY_POLICY_ACCEPTED = "MEMBER_PRIVACY_POLICY_ACCEPTED";

const logoutMember = (id, state) => {
	const defaults = {
		loggedIn: false,
		id: 0,
		profilePic: "",
		username: "",
		firstName: "",
		lastName: "",
		email: "",
		locale: "en-US",
		countryCode: "",
		countryName: "",
		joinDate: "",
		initialReferral: null,
		initialQueryParameters: null,
		queryParams: {},
		displayName: "",
		primarySpecialtyId: 0,
		primarySpecialtyName: "",
		secondarySpecialtyId: 0,
		secondarySpecialtyName: "",
		hasPreferredStatus: false,
		userType: "",
		hasPendingDeactivationRequest: false,
		daysUntilDeactivation: 0,
		hasConfiguredProfile: false,
		hasSetPassword: false,
		surveyOutcome: "",
		surveyOutcomeIncentiveAmount: 0,
		surveyOutcomeIncentiveCurrency: "",
		hasAcceptedTermsAndConditions: false,
		legacyUser: false,
		showTourGuide: false,
		brandAdministrator: false,
		trialMember: false,
		affiliateMember: false,
		hasSeenTrialMemberPopup: false,
		hasSeenReadOnlyMemberPopup: false,
		hasSeenPostSurveyReferralPopup: false,
		hasSeenReverificationPopup: false,
		icdCodes: "",
		numberOfUnreadMessages: 0,
		affiliation: "",
		followedTopics: [],
		showLeadLandingPage: false,
		rights: [],
		hasAccessToCovidBarometer: false,
		contactType: "unknown",
		contactSource: null,
		isReverificationRequired: false,
		readOnlyMember: false,
		showFirstSurveyPendingVerificationPage: false,
		worksAtSermo: false,
	};
	return {
		...state,
		...defaults,
	};
};

const updateMember = (newValues, state) => {
	return {
		...state,
		...newValues,
	};
};

export const member = (state, action) => {
	switch (action.type) {
		case MEMBER_LOGOUT: {
			return logoutMember(action.id, state);
		}
		case MEMBER_UPDATE: {
			let changed = false;

			for (const prop in action.data) {
				if (action.data[prop] !== state[prop]) {
					let incomingData = JSON.stringify(action.data[prop]);
					let currentData = JSON.stringify(state[prop]);

					if (incomingData !== currentData) {
						changed = true;
						break;
					}
				}
			}

			if (changed) {
				return updateMember(action.data, state);
			}

			return state;
		}
		case MEMBER_PRIVACY_POLICY_ACCEPTED: {
			return {
				...state,
				privacyPolicy: {
					...state.privacyPolicy,
					show: false,
				},
			};
		}
		case MEMBER_TOPIC_FOLLOW: {
			const newState = { ...state };
			newState.followedTopics.push(action.topic);
			return newState;
		}
		case MEMBER_TOPIC_UNFOLLOW: {
			const newState = { ...state };
			newState.followedTopics = state.followedTopics.filter(topic => topic.id !== action.topic.id);
			return newState;
		}
		case MEMBER_FOLLOW: {
			// Check for existing members in state
			if (state.followedMembers[action.member]) {
				return state;
			}
			// Set member as true
			return {
				...state,
				followedMembers: {
					...state.followedMembers,
					[action.member]: true,
				},
			};
		}
		case MEMBER_UNFOLLOW: {
			// Set member as false
			return {
				...state,
				followedMembers: {
					...state.followedMembers,
					[action.member]: false,
				},
			};
		}
		default:
			return state;
	}
};

export const SET_ELEMENT = "SET_ELEMENT";
export const SET_SCROLL_LOCKED = "SET_SCROLL_LOCKED";
export const SET_LEFT_RAIL_HEIGHT = "SET_LEFT_RAIL_HEIGHT";
export const SET_RIGHT_RAIL_HEIGHT = "SET_RIGHT_RAIL_HEIGHT";
export const SET_CONTENT_MIN_HEIGHT = "SET_CONTENT_MIN_HEIGHT";
export const SET_SCROLL_Y = "SET_SCROLL_Y";
export const SET_SCROLL_X = "SET_SCROLL_X";

export const Scroll = (state, action) => {
	switch (action.type) {
		case SET_SCROLL_X: {
			return {
				...state,
				x: action.x,
			};
		}
		case SET_SCROLL_Y: {
			return {
				...state,
				y: action.y,
			};
		}
		case SET_ELEMENT: {
			return {
				...state,
				el: action.el,
			};
		}
		case SET_LEFT_RAIL_HEIGHT: {
			return {
				...state,
				leftRailHeight: action.leftRailHeight,
			};
		}
		case SET_RIGHT_RAIL_HEIGHT: {
			return {
				...state,
				rightRailHeight: action.rightRailHeight,
			};
		}
		case SET_CONTENT_MIN_HEIGHT: {
			return {
				...state,
				contentMinHeight: action.contentMinHeight,
			};
		}
		case SET_SCROLL_LOCKED: {
			return {
				...state,
				scrollLocked: action.val,
			};
		}
		default:
			return state;
	}
};

export const SET_MEMBER_ID = "SET_MEMBER_ID";
export const SET_AID = "SET_AID";
export const SET_THREADS = "SET_THREADS";
export const SET_ID_BY_TYPE = "SET_ID_BY_TYPE";

export const Messages = (state, { type, payload }) => {
	switch(type) {
		case SET_MEMBER_ID:
			return {
				...state,
				aIdState: null,
				memberIdState: payload.memberId,
			}
		case SET_AID:
			return {
				...state,
				memberIdState: null,
				aIdState: payload.aId,
			}
		case SET_THREADS:
			return {
				...state,
				messageListThreads: payload.messageListThreads,
			}
		case SET_ID_BY_TYPE:
			return {
				...state,
				memberIdState: payload.type === "member"
					? payload.id
					: null,
				aIdState: payload.type === "ad"
					? payload.id
					: null,
			}
	}
}
