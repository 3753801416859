import { Icon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const Item = styled.div `
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 4px;
	cursor: pointer;

	&:hover {
		background-color: var(--background-01, #ecedef);
    }

    ${StyledTabletLandscapeAndDesktopQuery} {
    	margin: 0 -16px;
		padding: 12px 12px 12px 16px;
	}
`;
export const Left = styled.div `
	${StyledTabletLandscapeAndDesktopQuery} {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;
export const SponsorLabel = styled(TextStyled) `
	color: var(--text-03, #707483);
`;
export const Title = styled(TextStyled) `
	color: #101830;

	${StyledMobileAndTabletPortraitQuery} {
		padding: 4px 0 2px;
	}
`;
export const Status = styled(TextStyled) `
	color: var(--text-03, #707483);
`;
export const Right = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const IconStyled = styled(Icon) `
	color: var(--icon-03, rgba(183, 185, 192, 1));
`;
