import { Icon } from "@sermo/ui-components";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { navRestrictedRoutesRegex } from "@components/TopNav/TopNav";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import { left, right } from "./MobileBottomNav.data";
import MobileBottomNavPreview from "./MobileBottomNav.preview";
import * as Styles from "./MobileBottomNav.styles";
const MobileBottomNav = () => {
    const { pathname } = useLocation();
    const trackEvent = useTrackEvent();
    const { hasAccess } = useMemoizedContext("member", ["hasAccess"]);
    const leftAccessible = useMemo(() => left.filter(({ claim }) => hasAccess(claim)), [hasAccess]);
    const rightAccessible = useMemo(() => right.filter(({ claim }) => hasAccess(claim)), [hasAccess]);
    const handleClick = (label) => {
        trackEvent({
            category: "bottom-navigation",
            label,
            action: "click",
        });
    };
    const renderItems = ({ component, icon, label, to, trackingLabel }) => (<Styles.Link data-component={`${label}Nav`} key={label} onClick={() => handleClick(trackingLabel)} to={to}>
			<Styles.Selection $isSelected={pathname.startsWith(to)} $kind={"bodyShort00"}>
				{component
            ? component
            : <Icon height={24} width={24} src={icon}/>}
				{label}
			</Styles.Selection>
		</Styles.Link>);
    if (leftAccessible.length === 0
        && rightAccessible.length === 2) {
        leftAccessible.push(rightAccessible.shift());
    }
    else if (leftAccessible.length === 2
        && rightAccessible.length === 0) {
        rightAccessible.push(leftAccessible.pop());
    }
    return (<MobileAndTabletPortrait>
			<Styles.Container data-component={"MobileBottomNav"}>
				<Styles.Left>
					{leftAccessible.map(renderItems)}
				</Styles.Left>
				<Styles.Right>
					{rightAccessible.map(renderItems)}
				</Styles.Right>
			</Styles.Container>
		</MobileAndTabletPortrait>);
};
const MobileBottomNavWithData = () => {
    const { pathname } = useLocation();
    const isPreview = pathname.includes("preview");
    const showNav = !navRestrictedRoutesRegex.test(pathname);
    const { loggedIn, hasAcceptedTermsAndConditions, trialMember, affiliateMember, readOnlyMember, showLeadLandingPage, isPostSurveyReferral, wasPostSurveyReferralVerified, showFirstSurveyPendingVerificationPage, } = useMemoizedContext("member", [
        "loggedIn",
        "hasAcceptedTermsAndConditions",
        "trialMember",
        "affiliateMember",
        "readOnlyMember",
        "showLeadLandingPage",
        "isPostSurveyReferral",
        "wasPostSurveyReferralVerified",
        "showFirstSurveyPendingVerificationPage",
    ]);
    if (isPreview) {
        return <MobileBottomNavPreview />;
    }
    if ((hasAcceptedTermsAndConditions
        || trialMember
        || affiliateMember
        || readOnlyMember
        || isPostSurveyReferral
        || wasPostSurveyReferralVerified)
        && loggedIn
        && !showLeadLandingPage
        && !showFirstSurveyPendingVerificationPage
        && showNav) {
        return <MobileBottomNav />;
    }
    return null;
};
export default MobileBottomNavWithData;
