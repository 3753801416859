import MobileNewPostSubNav from "@components/MobileSubNav/MobileNewPostSubNav";
import Draft from "@components/NewPost/components/Draft";
import { useTogglePostEditor } from "@contexts/UI";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./NewPostMobile.styles";
const NewPostMobile = ({ publishCard, }) => {
    const openEditor = useTogglePostEditor();
    const { postEditorIsOpen, postEditorSelectedType, } = useMemoizedContext("ui", [
        "postEditorIsOpen",
        "postEditorSelectedType",
    ]);
    const handlePublish = (card) => {
        openEditor(false);
        if (publishCard) {
            publishCard(card);
        }
    };
    if (postEditorIsOpen) {
        return (<>
				<Styles.MobileEditorContainer data-component="NewPost">
					<MobileNewPostSubNav />
					{PostTypeTypes.PATIENT_CASE === postEditorSelectedType
                && (<Draft type="PatientCase" publishCard={handlePublish}/>)}
					{PostTypeTypes.DISCUSSION === postEditorSelectedType
                && (<Draft type="Discussion" publishCard={handlePublish}/>)}
					{PostTypeTypes.QUESTION === postEditorSelectedType
                && (<Draft type="Question" publishCard={handlePublish}/>)}
				</Styles.MobileEditorContainer>
			</>);
    }
    return null;
};
export default NewPostMobile;
