import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Pages = () => (
	<Routes>
		<Route
			path="*"
			element={<Navigate to={`/feed/all-sermo`} />}
		/>
	</Routes>
);

export default Pages;
