import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import { RegisterStyles } from "@components/Register/Register.styles";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useCloseModal } from "@contexts/UI";
import getTranslation from "@translation/translation";

const ConfirmManualVerification = ({ data }) => {
	const closeModal = useCloseModal();
	const isRegister = !!useMatch("/register/*");

	const videoVerificationRequired = data.videoVerificationRequired;
	const showContinueToSurveyButton = data.showContinueToSurveyButton;
	const continueToSurveyUrl = data.continueToSurveyUrl + window.location.search;
	const projectCode = data.surveyProjectCode;

	const registerTrackEvent = useRegisterTrack();

	useEffect(() => {
		// registration-process-almost-complete-page-view
		registerTrackEvent({
			label: "almost-complete-page",
			action: "view",
		});
	}, []);

	const contactEmailHandler = (e) => {
		e.preventDefault();
		if (e.target.tagName.toUpperCase() === "A") {
			// registration-process-almost-contact-sermo-click
			registerTrackEvent({
				label: "almost-contact-sermo",
				action: "click",
			});
		}
	}

	if (isRegister && showContinueToSurveyButton === false) {
		return (
			<LoggedOutStyles>
				<RegisterStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<TextStyled
								className={classNames(["form-title"])}
								$kind="heading04"
							>
								<strong>
									{getTranslation("frontend.registration.manualVerificationPending.title")}
								</strong>
							</TextStyled>
							<TextStyled
								className={classNames(["form-field-row", "form-subtitle"])}
								onClick={contactEmailHandler}
								$kind="bodyLong02"
							>
								{getTranslation("frontend.registration.manualVerificationPending.descriptionFirst")}
							</TextStyled>
							<TextStyled
								className={classNames(["form-subtitle"])}
								$kind="bodyLong02"
							>
								{getTranslation("frontend.registration.manualVerificationPending.descriptionSecond")}
							</TextStyled>
						</div>
					</div>
				</RegisterStyles>
			</LoggedOutStyles>
		);
	}
	else if (isRegister && showContinueToSurveyButton === true) {
		return (
			<LoggedOutStyles>
				<RegisterStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<TextStyled
								className={classNames(["form-title"])}
								$kind="heading04"
							>
								<strong>
									{getTranslation("frontend.registration.manualVerificationPending.continueToSurveyTitle")}
								</strong>
							</TextStyled>
							<TextStyled
								className={classNames(["form-field-row", "form-subtitle"])}
								onClick={contactEmailHandler}
								$kind="bodyLong02"
							>
								{getTranslation("frontend.registration.manualVerificationPending.continueToSurveyDescriptionFirst")}
							</TextStyled>
							<TextStyled
								className={classNames(["form-field-row", "form-subtitle"])}
								$kind="bodyLong02"
							>
								{getTranslation("frontend.registration.manualVerificationPending.continueToSurveyDescriptionSecond", false, projectCode)}
							</TextStyled>
							<TextStyled
								className={classNames(["form-subtitle"])}
								$kind="bodyLong02"
							>
								{getTranslation("frontend.registration.manualVerificationPending.continueToSurveyDescriptionThird")}
							</TextStyled>
							<br />
							<a href={continueToSurveyUrl}>
								<Button
									size="medium"
								>
									{getTranslation("frontend.registration.manualVerificationPending.continueToSurveyButton")}
								</Button>
							</a>
						</div>
					</div>
				</RegisterStyles>
			</LoggedOutStyles>
		);
	}

	return (
		<LoggedOutStyles>
			<RegisterStyles>
				<div className={classNames("reverification")}>
					<TextStyled
						className={classNames(["form-title"])}
						$kind="heading04"
					>
						<strong>
							{getTranslation("frontend.modals.reverification.manualVerificationPending.title")}
						</strong>
					</TextStyled>

					{
						videoVerificationRequired && (
							<>
								<TextStyled
									className={classNames("form-field-row", "form-subtitle")}
									$kind="bodyLong02"
								>
									{getTranslation("frontend.modals.reverification.manualVerificationPending.descriptionVideoVerificationRequired")}
								</TextStyled>
								<div className={classNames("form-field-row")}>
									<Button
										size="medium"
										external={true}
										target="_external"
										to="https://outlook.office365.com/owa/calendar/verification@sermo.com/bookings/"
									>
										{getTranslation("frontend.modals.reverification.manualVerificationPending.scheduleACall")}
									</Button>
								</div>
								<TextStyled
									className={classNames(["form-subtitle"])}
									$kind="bodyLong02"
								>
									{getTranslation("frontend.modals.reverification.manualVerificationPending.descriptionVideoVerificationRequired2")}
								</TextStyled>
							</>
						)
					}
					{
						!videoVerificationRequired && (
							<>
								<TextStyled
									className={classNames(["form-subtitle"])}
									$kind="bodyLong02"
								>
									{getTranslation("frontend.modals.reverification.manualVerificationPending.description")}
								</TextStyled>
								<div className={classNames(["submit-container"])}>
									<Button
										size="medium"
										clickHandler={() => closeModal("post-survey-referral")}
									>
										{getTranslation("frontend.generics.done", true)}
									</Button>
								</div>
							</>
						)
					}
				</div>
			</RegisterStyles>
		</LoggedOutStyles>
	)
};

export default ConfirmManualVerification;
