import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalFieldLabel = ({ children }) => <div styleName="styles.modal-field-label">{children}</div>;

ModalFieldLabel.propTypes = {
	children: PropTypes.node,
};

export default ModalFieldLabel;
