import isEqual from "lodash.isequal";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams as useSearchParamsRRD } from "react-router-dom";
import { ElementScrollContext } from "@components/ElementScrollContextProvider/ElementScrollContextProvider";
import { FeedContext } from "@components/FeedLayout/FeedContext";
import { Member, MessagesContext, ScrollContext } from "@components/GlobalState/contexts";
import EventsContext from "@components/LiveEvents/components/EventsContext/EventsContext";
import SearchContext from "@components/Search/SearchContext";
import { AttachmentsContext } from "@contexts/Attachments";
import { CommentContext } from "@contexts/Comment";
import { DetachedFooterContext } from "@contexts/DetachedFooter";
import { OnboardingContext } from "@contexts/Onboarding";
import { PollContext } from "@contexts/Poll";
import { PollAnswerContext } from "@contexts/PollAnswer";
import { PollQuestionContext } from "@contexts/PollQuestion";
import PostDataContext from "@contexts/PostData/PostDataContext";
import PostUIEditingContext from "@contexts/PostUI/Editing/PostUIEditingContext";
import PostUIContext from "@contexts/PostUI/PostUIContext";
import PostUIViewingContext from "@contexts/PostUI/Viewing/PostUIViewingContext";
import { RunWithPeterContext } from "@contexts/RunWithPeter";
import { UIContext } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { apiUrl, fetchUrl, getCookie } from "@frontend/Utils";

const usePrevious = value => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

export { usePrevious };

const useSearchParams = () => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const searchParams = {
		has: (key = "q") => queryParams.has(key), // returns boolean for q(default) if has value
		get: function (key = "q") {
			return this.has(key)
				? queryParams.get(key)
				: null; // returns value of param
		},
		set: function (value, key = "q") {
			this.has(key)
				? queryParams.set(key, value)
				: queryParams.append(key, value)
		}, // set the value
		stringify: function (query, key = "q") {
			if (query) {
				this.set(query, key);
			}
			return queryParams.toString().length
				? "?" + queryParams.toString()
				: ""; // returns ?q=what_is_searched encoded
		},
	};
	return searchParams;
};
export { useSearchParams };

export const useGetSearchParamsObject = () => {
	const [searchParams] = useSearchParamsRRD();
	const keys = searchParams.keys();
	const searchParamsObject = {};

	for (const key of keys) {
		searchParamsObject[key] = searchParams.get(key);
	}

	return searchParamsObject;
};

const useMemoizedContext = (context, contextValues = [], log = false) => {
	const contexts = {
		attachments: AttachmentsContext,
		comment: CommentContext,
		detachedFooter: DetachedFooterContext,
		elementScroll: ElementScrollContext,
		events: EventsContext,
		feed: FeedContext,
		member: Member,
		messages: MessagesContext,
		onboarding: OnboardingContext,
		poll: PollContext,
		pollAnswer: PollAnswerContext,
		pollQuestion: PollQuestionContext,
		postData: PostDataContext,
		postUI: PostUIContext,
		postUIEditing: PostUIEditingContext,
		postUIViewing: PostUIViewingContext,
		runWithPeter: RunWithPeterContext,
		scroll: ScrollContext,
		search: SearchContext,
		ui: UIContext,
	};
	// get the current context state
	const currentContextState = useContext("string" === typeof context
		? contexts[context]
		: context);

	// TODO: discuss using JSON.stringify for comparison
	// create an array of only the values we care about to give to useMemo
	const contextProps = contextValues.map(val => currentContextState[val]);

	// only update when one of those passed values is changed
	// eslint-disable-next-line react-hooks/exhaustive-deps, arrow-body-style
	return useMemo(() => {
		return currentContextState;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, contextProps);
};

export { useMemoizedContext };

export const useGetPostTrackingCategory = () => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adType,
		cardType,
	} = useMemoizedContext("postData", [
		"adType",
		"cardType",
	]);

	let category = "post";

	if (adType) {
		category = adType.toLowerCase();
	}
	else if (CardTypeTypes.SERMO_CONTENT_CARD === cardType) {
		category = "sermocontentcard";
	}
	else if (CardTypeTypes.RESOURCE_CENTER === cardType) {
		category = "resourcecenter";
	}
	else if (CardTypeTypes.RESOURCE_CENTER_ITEM === cardType) {
		category = "resourceitem";
	}

	if (expanded) {
		category += "-expanded";
	}
	else {
		category += "-feed";
	}

	return useMemo(() => category, [category]);
};

// heavily modified from example at:
//	https://medium.com/@cwlsn/how-to-fetch-data-with-react-hooks-in-a-minute-e0f9a15a44d6
/**
 * this hook fetches a given url with the provided params
 * fetch is called whenever the url or body params change
 *
 * @param {string} url "the url of th endpoint to fetch"
 * @param {object} body "all the params to pass to the endpoint"
 * @param {bool} run "allows component to control weather or not fetching occurs"
 */
const useFetch = (url, body = {}, run = true, method = "POST") => {
	const { locale, updateMember } = useMemoizedContext("member", ["locale"]);

	// the actual data returned from the server
	const [data, setData] = useState({});
	// lets the parent component know when the fetching is done
	const [loading, setLoading] = useState(true);
	// a JSON error object
	const [error, setError] = useState(false);
	// actually controls weather or not fetching is happening
	const [shouldFetch, setShouldFetch] = useState(run);
	const previousUrl = usePrevious(url);
	const bodyString = JSON.stringify(body);
	const previousBody = usePrevious(bodyString);

	const abortController = useRef(new AbortController());

	const fetchUrl = async (url, body, setData, setLoading, setError) => {
		if ("undefined" === typeof window) {
			return;
		}

		setData({});
		setLoading(true);
		setShouldFetch(false);
		setError(false);
		abortController.current = new AbortController();

		const { signal } = abortController.current;

		const antiForgeryHeaderName = "X-XSRF-TOKEN";
		const antiForgeryCookieName = "XSRF-TOKEN";

		// Fix for IE and Edge: using fetch on Edge is causing the set-cookie header to not set a cookie on the browser.
		// The solution was to add credentials: "same-origin" to the fetch options object.
		const response = await fetch(`${locale}/${url}`, {
			signal,
			method,
			credentials: "same-origin",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				[antiForgeryHeaderName]: getCookie(antiForgeryCookieName),
				"X-Sermo-MemberId": window?.sermo?.user?.memberId ?? "",
			},
			...(method === "POST" && { body: JSON.stringify(body) }),
		}).catch(err => {
			// catch cancel error and log it for dev purposes and to keep console clean
			// no need to set the error though becuase if it was cancelled we are making
			// a new call and this just confuses matters.
			// eslint-disable-next-line no-console
			console.log(`Error aborting endpoint! URL: ${locale}/${url} %s`, err);
		});

		if (response !== null && response !== undefined) {
			// on success
			if (response.status === 200) {
				const json = await response.json();
				setData(json);
			} else if (response.status === 503) {
				window.location.reload();
			} else if (response.status === 401) {
				setData({});
				// We got a 401 Access Denied. Check the system endpoint to verify access.
				// If that endpoint fails as well, it means that the member has been signed out,
				// and we will redirect them to the login screen.
				fetch(apiUrl(`/system/getmemberdata`, locale), {
					method: "POST",
					credentials: "same-origin",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						[antiForgeryHeaderName]: getCookie(antiForgeryCookieName),
					},
					body: JSON.stringify({}),
				}).then(systemResponse => {
					if (systemResponse.status === 401) {
						window.location = "/login";
						return;
					}
					if ( systemResponse.status === 200) {
						systemResponse.json().then((body) => {
							if ( systemResponse.ok) {
								updateMember({ ...body });
							}
						})
					}
				});
			} else {
				// eslint-disable-next-line no-console
				console.log(`Error fetching endpoint! URL: ${locale}/${url} %s`, response);

				const json = await response.json();
				setError(json);
				setData({});
			}
		}

		// always set loading false when the fetch ends
		setLoading(false);
	}

	// initial fetch
	useEffect(() => {
		if (shouldFetch) {
			fetchUrl(url, body, setData, setLoading, setError, true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const shouldFetchPrevious = usePrevious(shouldFetch);
	// fetch any time loading changes from false to true for subsequent fetches
	useEffect(() => {
		if ("undefined" !== typeof shouldFetchPrevious && !shouldFetchPrevious && shouldFetch) {
			fetchUrl(url, body, setData, setLoading, setError, true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldFetch]);

	// if the body or url changed and we already have data
	// assume its a new call and reload
	useEffect(() => {
		// "previous" values start off undefined so this check prevents an unneccesary call on load
		// only run if body or url actually changed
		if (
			"undefined" !== typeof previousBody
			&& "undefined" !== typeof previousUrl
			&& (!isEqual(body, previousBody) || url !== previousUrl)
		) {
			// if its loading cancel the current fetch before we start a new one
			// abortController may not exist if "run" was previously false.
			if (loading) {
				abortController.current?.abort();
			}
			setShouldFetch(run);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyString, url]);

	return [data, loading, error];
}

export { useFetch };

const useApiEndpoint = () => {
	const {
		locale,
		updateMember,
	} = useMemoizedContext("member", ["locale"]);
	const {
		associatedEndpoints,
		updateOnboardingApiCall,
	} = useMemoizedContext("onboarding", ["associatedEndpoints"]);
	const { pathname } = useLocation();

	// You POST method here
	const callAPI = (url, data, formData = false, method = "POST", noCors = false) => {
		let bodyData = null;

		const headers = {
			Accept: "application/json",
		};

		if (window?.sermo?.user?.memberId !== undefined)
		{
			headers["X-Sermo-MemberId"] = window.sermo.user.memberId;
		}

		let fetchUrl;

		if(url.toLowerCase().startsWith("http")) {
			fetchUrl = url;
		} else {
			fetchUrl = apiUrl(`/${url}`, locale);
			headers["X-XSRF-TOKEN"] = getCookie("XSRF-TOKEN");
		}

		if (data !== null && data !== undefined) {
			if (!formData) {
				bodyData = JSON.stringify(data);
				headers["Content-Type"] = "application/json";
				headers["Accept"] = "application/json";
			} else {
				bodyData = data;
			}
		} else {
			headers["Content-Type"] = "application/json";
			headers["Accept"] = "application/json";

			if (method === "POST") {
				bodyData = JSON.stringify({});
			}
		}

		return new Promise((resolve, reject) => {
			// Fix for IE and Edge: using fetch on Edge is causing the set-cookie header to not
			// set a cookie on the browser.
			// The solution was to add credentials: "same-origin" to the fetch options object.

			let initValues = {
				method: method,
				credentials: "same-origin",
				headers: headers,
				body: bodyData,
			};

			if (noCors) {
				initValues.mode = "no-cors";
			}

			fetch(fetchUrl, initValues)
				.then(response => {
					if (response.status === 500) {
						response
							.clone()
							.json()
							.then(body => {
								if (body.debugErrorMessage !== null) {
									if ("undefined" !== typeof window && window.sermo.env) {
										if (window.sermo.env !== "staging" && window.sermo.env !== "production") {
											let a = document.createElement("pre");
											a.appendChild(
												document.createTextNode("Error invoking endpoint: " + url + "\r\n")
											);
											a.appendChild(
												document.createTextNode(
													// eslint-disable-next-line max-len
													"-----------------------------------------------------------------------\r\n"
												)
											);
											a.appendChild(document.createTextNode(body.debugErrorMessage));
											a.setAttribute(
												"style",
												// eslint-disable-next-line max-len
												"border: 4px solid red; margin: 140px 15% 40px 15%; background-color: yellow; color: red; max-height: 80%; overflow: auto; font-family: consolas; padding: 20px 100px; line-height:20px;position: fixed; font-size:12px; top: 0; left: 0; width: 70%;white-space: pre-wrap; z-index:1000;"
											);
											document.body.appendChild(a);
										}
									}
								}
							});
						resolve(response);
					} else if (response.status === 503) {
						window.location.reload();
					} else if (response.status === 401) {
						// We got a 401 Access Denied. Check the member data to see if their
						// rights have changed or they have been logged out
						// If that endpoint fails as well, it means that the member has been
						// signed out, and we will redirect them to the login screen.
						fetch(apiUrl(`/system/getmemberdata`, locale), {
							method: "POST",
							credentials: "same-origin",
							headers: headers,
							body: JSON.stringify({}),
						}).then(systemResponse => {
							if (systemResponse.status === 401) {
								if ("undefined" !== typeof window) {
									if (window.location.pathname === "/read-only") {
										window.location.reload();
									}
									else {
										window.location = "/login?returnUrl=" + escape(window.location.href);
									}
									return;
								}
							}
							if (systemResponse.status === 200) {
								systemResponse.json().then((body) => {
									if ( systemResponse.ok) {
										updateMember({ ...body });
									}
								})
							}
							resolve(response);
						});
					} else {
						resolve(response);

						// if the API call is associated with an onboarding endpoint (ep)
						// then we need to update the onboarding state
						if (associatedEndpoints?.find(ep => ep.includes(url.toLowerCase()) || ep.includes(pathname))) {
							updateOnboardingApiCall();
						}
					}
				})
				.catch(errorData => {
					// eslint-disable-next-line no-console
					console.log("API endpoint error: %s", url, errorData);
					reject(errorData);
				});
		});
	};

	return callAPI;
}

export { useApiEndpoint }

const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

export { useInterval };

// taken from
// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
const useDebounce = (value, delay) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
	const handler = useRef();

	useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			handler.current = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Return a cleanup function that will be called every time ...
			// ... useEffect is re-called. useEffect will only be re-called ...
			// ... if value changes (see the inputs array below).
			// This is how we prevent debouncedValue from changing if value is ...
			// ... changed within the delay period. Timeout gets cleared and restarted.
			// To put it in context, if the user is typing within our app's ...
			// ... search box, we don't want the debouncedValue to update until ...
			// ... they've stopped typing for more than 500ms.
			return () => {
				clearTimeout(handler.current);
			};
		},
		// Only re-call effect if value changes
		// delay is included in case we need to be able to change that dynamically.
		[value, delay]
	);

	return [debouncedValue, handler];
};

export { useDebounce };

const useABTest = (a, b, startDate, endDate) => {
	const defaultStart = (new Date()).toLocaleString("en-US");
	const defaultEnd = "1/1/2100"
	let component = b;

	const curTime = new Date(startDate || defaultStart);
	const cutOff = new Date(endDate || defaultEnd);

	if (curTime > cutOff) {
		component = a;
	}

	return component;
};

export { useABTest };

const useScript = (url, callback) => {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = url;
		script.async = true;
		script.onload = callback;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, [callback, url])
}

export { useScript };

const useProgress = (
	area,
	params
) => {
	const {
		adId,
		clickTags,
		expanded,
		postId,
		adFrequency,
		category,
		sermoContentCardId,
	} = params;

	const trackEvent = useTrackEvent();

	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [tracked2sec, setTracked2sec] = useState(false);
	const [tracked5sec, setTracked5sec] = useState(false);
	const [tracked10sec, setTracked10sec] = useState(false);
	const [tracked25percent, setTracked25percent] = useState(false);
	const [tracked50percent, setTracked50percent] = useState(false);
	const [tracked75percent, setTracked75percent] = useState(false);
	const [tracked100percent, setTracked100percent] = useState(false);

	const getProgressPercent = useCallback(() => {
		if (duration) {
			return (currentTime / duration) * 100;
		}

		return 0;
	}, [currentTime, duration])

	const [progressPercent, setProgressPercent] = useState(getProgressPercent())

	const onProgress = (e) => {
		setCurrentTime(e.target.currentTime);
		setDuration(e.target.duration);
	}

	useEffect(() => {
		setProgressPercent(getProgressPercent());
	}, [duration, currentTime, getProgressPercent])

	const defaults = useMemo(() => {return {
		category,
		action: "video-progress",
		postId,
		adId,
		adFrequency,
		expanded,
		area: area
			? area
			: "featured-video",
		sermoContentCardId,
	}}, [area, adFrequency, adId, category, expanded, postId, sermoContentCardId]);

	useEffect(() => {
		// if the user scrubs to the beginning of the video track again
		if (currentTime < 2 && tracked2sec) {
			setTracked2sec(false);
			setTracked5sec(false);
			setTracked10sec(false);
			setTracked25percent(false);
			setTracked50percent(false);
			setTracked75percent(false);
			setTracked100percent(false);
		} else if (currentTime >= 2 && !tracked2sec) {
			setTracked2sec(true);
			trackEvent({
				...defaults,
				label: "2-secs",
				value: 2,
				videoEventType: "Watched2Seconds",
				...params,
			});
			if (clickTags?.video2SecondsWatched) {
				clickTags?.video2SecondsWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (currentTime >= 5 && !tracked5sec ) {
			setTracked5sec(true);
			trackEvent({
				...defaults,
				label: "5-secs",
				value: 5,
				videoEventType: "Watched5Seconds",
				...params,
			});
			if (clickTags?.video5SecondsWatched) {
				clickTags?.video5SecondsWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (currentTime >= 10 && !tracked10sec ) {
			setTracked10sec(true);
			trackEvent({
				...defaults,
				label: "10-secs",
				value: 10,
				videoEventType: "Watched10Seconds",
				...params,
			});
			if (clickTags?.video10SecondsWatched) {
				clickTags?.video10SecondsWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (progressPercent >= 25 && progressPercent < 50 && !tracked25percent) {
			setTracked25percent(true);
			trackEvent({
				...defaults,
				label: "25-percent",
				value: 25,
				videoEventType: "Watched25Percent",
				...params,
			});
			if (clickTags?.video25PercentWatched) {
				clickTags?.video25PercentWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (progressPercent >= 50 && progressPercent < 75 && !tracked50percent) {
			setTracked50percent(true);
			trackEvent({
				...defaults,
				label: "50-percent",
				value: 50,
				videoEventType: "Watched50Percent",
				...params,
			});
			if (clickTags?.video50PercentWatched) {
				clickTags?.video50PercentWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (progressPercent >= 75 && progressPercent < 99 && !tracked75percent) {
			setTracked75percent(true);
			trackEvent({
				...defaults,
				label: "75-percent",
				value: 75,
				videoEventType: "Watched75Percent",
				...params,
			});
			if (clickTags?.video75PercentWatched) {
				clickTags?.video75PercentWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		} else if (progressPercent >= 99 && !tracked100percent) {
			setTracked100percent(true);
			trackEvent({
				...defaults,
				label: "100-percent",
				value: 100,
				videoEventType: "Watched100Percent",
				...params,
			});
			if (clickTags?.videoCompletelyWatched) {
				clickTags?.videoCompletelyWatched.forEach((url) => {
					fetchUrl(url);
				})
			}
		}
	}, [clickTags, currentTime, progressPercent, tracked2sec, tracked5sec, tracked10sec, tracked25percent, tracked50percent, tracked75percent, tracked100percent, trackEvent, defaults, params])

	return onProgress;
}

export { useProgress };

const useProgressWithPostContext = (area, params = {}) => {
	const category = useGetPostTrackingCategory();

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		clickTags,
		postId,
		sermoContentCardId,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"clickTags",
		"postId",
		"sermoContentCardId",
	])

	return useProgress(
		area,
		{
			adFrequency,
			adId,
			category,
			clickTags,
			expanded,
			postId,
			sermoContentCardId,
			...params,
		}
	)
}

export { useProgressWithPostContext };

/**
 * InView configuration viewability levels.
 *
 * Every percentage after 0 will mark a point to send tracking data, once is overcome.
 * @type {number[]}
 */
export const inViewlevels = [0, 30, 50];

export const FULL_INVIEW = inViewlevels[inViewlevels.length - 1];

const useIsElementInView = () => {
	const getThreshold = (value) => (
		inViewlevels[[...inViewlevels, value].sort((a, b) => a - b).indexOf(value) - 1]
	);

	return (element) => {
		if (element) {
			const rect = element.getBoundingClientRect();
			const {
				top,
				bottom,
				height,
			} = rect;

			if (height === 0) {
				return FULL_INVIEW;
			}

			// only top in check %
			if (top < window.innerHeight && bottom > window.innerHeight) {
				return getThreshold(((window.innerHeight - top) * 100) / height);
			}
			// only bottom in check %
			if (top < 0 && bottom > 0) {
				getThreshold(((bottom) * 100) / height)
			}

			// the ad is fully in view
			if (top > 0 && bottom < window.innerHeight) {
				return FULL_INVIEW;
			}

			// the ad is somehow bigger than twice the screen
			if (height > window.innerHeight * 2) {
				// ensure top and bottom are offscreen so the ad fully covers the page
				if (top < 0 && bottom > window.innerHeight) {
					return FULL_INVIEW;
				}
			}

			return 0;
		}

		return 0;
	};
}

export { useIsElementInView };
