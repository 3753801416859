import { Radio, RadioGroup } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import InfoChangeAddress from "@components/Modal/Modals/ModalComponents/Withdrawal/InfoChangeAddress";
import InfoChangeEmail from "@components/Modal/Modals/ModalComponents/Withdrawal/InfoChangeEmail";
import InfoChangeName from "@components/Modal/Modals/ModalComponents/Withdrawal/InfoChangeName";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import styles from "../ModalComponents/Withdrawal/ModalWithdrawalComponents.scss";
import {
	ModalFooter,
	ModalSeparator,
	Withdrawal,
	ModalSection,
} from "./../ModalComponents";
import WithdrawalComplete from "./WithdrawalComplete";
import { getTranslationSubKey, TYPE_MASTERCARD } from "./withdrawalUtils";

const RadioGroupAddress = styled(RadioGroup)`
	flex-flow: row;
	label {
		height: 100%;
		align-items: start;
		width: 50%;
		& > span {
			margin-top: 16px;
		}
		& + label {
			margin-left: 8px;
		}
	}
	& > label {
		margin-bottom: 0;
	}
`;

const WithdrawalConfirm = ({
	cancelEventHandler,
	currency,
	emailAddress,
	primaryAddress,
	secondaryAddress,
	showAddressOnConfirm,
	fixedFeeAmount,
	variableFeeAmount,
	variableFeePercentage,
	targetCurrency,
	targetCurrencyMultiplier,
	withdrawalCode,
	withdrawalCompleteEventHandler,
	withdrawalModalTextLabelChangeHandler,
	withdrawAmount,
	customConfirmStepDescription,
	customCompleteStepDescription,
	issueNewCard,
	setUpdateAddress,
}) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [withdrawalComplete, setWithdrawalComplete] = useState(false);
	const [confirmationCode, setConfirmationCode] = useState("");
	const [selectedAddress, setSelectedAddress] = useState(primaryAddress
		? "primary"
		: secondaryAddress
			? "secondary"
			: null);

	const { firstName, lastName } = useMemoizedContext("member", ["firstName", "lastName"]);
	const fullName = `${firstName ?? ""} ${lastName ?? ""}`;

	const translationKey = getTranslationSubKey(withdrawalCode);

	const submitWithdrawClickHandler = () => {
		setLoading(true);

		const data = {
			amount: withdrawAmount,
			withdrawalType: withdrawalCode,
			mailingAddressId: selectedAddress === "primary"
				? primaryAddress.addressId
				: selectedAddress === "secondary"
					? secondaryAddress.addressId
					: undefined,
			issueNewCard,
		};

		apiEndpoint("surveys/withdraw", data).then((dataResponse) => {
			dataResponse.json().then((body) => {
				setLoading(false);

				if (dataResponse.ok) {
					setGlobalValidationError("");
					setConfirmationCode(body.confirmationCode);
					setWithdrawalComplete(true);
				} else {
					setGlobalValidationError(body.message);
				}
			});
		});
	};

	const withdrawalCompleteClickHandler = () => {
		withdrawalCompleteEventHandler();
	};

	const changeAddressHandler = () => {
		setUpdateAddress(true);
	};

	const selectAddress = (event) => {
		setSelectedAddress(event.target.value);
	};

	const withdrawalDescription = customConfirmStepDescription || getTranslation(`frontend.modals.withdrawal.${translationKey}.stepConfirmDescription`);
	const additionalNote = !customConfirmStepDescription && targetCurrency && getTranslation(`frontend.modals.withdrawal.${translationKey}.stepConfirmAdditionalNote`, false, targetCurrency);

	return (
		<>
			{
				withdrawalComplete && (
					<WithdrawalComplete
						currency={currency}
						withdrawAmount={withdrawAmount}
						emailAddress={emailAddress}
						address={
							selectedAddress === "primary"
								? primaryAddress
								: secondaryAddress
						}
						fixedFeeAmount={fixedFeeAmount}
						variableFeePercentage={variableFeePercentage}
						variableFeeAmount={variableFeeAmount}
						targetCurrency={targetCurrency}
						targetCurrencyMultiplier={targetCurrencyMultiplier}
						confirmationCode={confirmationCode}
						withdrawalCode={withdrawalCode}
						withdrawalCompleteEventHandler={withdrawalCompleteClickHandler}
						withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
						customCompleteStepDescription={customCompleteStepDescription}
						issueNewCard={issueNewCard}
					/>
				)
			}
			{
				!withdrawalComplete && (
					<>
						<Withdrawal.PaymentMethod
							{...{
								title: getTranslation("frontend.modals.withdrawal.paymentMethodTitle"),
								method: getTranslation(`frontend.modals.withdrawal.${translationKey}.paymentMethodActual`),
								description: withdrawalDescription,
								additionalNote: additionalNote,
							}}
						/>

						{
							(showAddressOnConfirm && (primaryAddress || secondaryAddress)) && (
								<>
									<ModalSeparator />

									<div styleName="styles.section-title">{getTranslation(`frontend.modals.withdrawal.${translationKey}.mailingAddressTitle`)}</div>

									<ModalSection>
										<RadioGroupAddress
											value={selectedAddress}
											onChange={selectAddress}
											extended
										>
											{
												primaryAddress && (<Radio
													label={
														<Withdrawal.AddressCard
															addressType={primaryAddress.addressType}
															addressLine1={primaryAddress.addressLine1}
															addressLine2={primaryAddress.addressLine2}
															city={primaryAddress.city}
															region={primaryAddress.region}
															postalCode={primaryAddress.postalCode}
															country={primaryAddress.country}
														/>
													}
													value="primary"
													checked={selectedAddress==="primary"}
												/>)
											}
											{
												secondaryAddress && (<Radio
													label={
														<Withdrawal.AddressCard
															addressType={secondaryAddress.addressType}
															addressLine1={secondaryAddress.addressLine1}
															addressLine2={secondaryAddress.addressLine2}
															city={secondaryAddress.city}
															region={secondaryAddress.region}
															postalCode={secondaryAddress.postalCode}
															country={secondaryAddress.country}
														/>
													}
													value="secondary"
													checked={selectedAddress==="secondary"}
												/>)
											}
										</RadioGroupAddress>
									</ModalSection>
									<InfoChangeAddress onClickChangeAddress={changeAddressHandler} />
								</>
							)
						}

						{
							fullName && (
								<>
									<ModalSeparator />

									<Withdrawal.DetailPair
										{...{
											title: getTranslation("frontend.modals.withdrawal.name"),
											value: fullName,
										}}
									/>

									<InfoChangeName />
								</>)
						}

						{
							emailAddress && (
								<>
									<ModalSeparator />

									<Withdrawal.DetailPair
										{...{
											title: getTranslation("frontend.modals.withdrawal.emailAddress"),
											value: emailAddress,
										}}
									/>

									<InfoChangeEmail />
								</>)
						}

						<ModalSeparator />

						<Withdrawal.AmountOverview
							{...{
								currency: currency,
								fixedFeeAmount: withdrawalCode !== TYPE_MASTERCARD || issueNewCard
									? fixedFeeAmount
									: 0,
								variableFeePercentage: variableFeePercentage,
								variableFeeAmount: variableFeeAmount,
								withdrawAmount: Number(withdrawAmount),
								showOriginalAmount: false,
								showFee: false,
								targetCurrency: targetCurrency,
								targetCurrencyMultiplier: targetCurrencyMultiplier,
							}}
						/>

						<GlobalValidationMessage text={globalValidationError} />

						<ModalFooter
							{...{
								cancelModalEventHandler: cancelEventHandler,
								cancelText: getTranslation("frontend.generics.back", true),
								submitEventHandler: submitWithdrawClickHandler,
								submitTracking: {
									category: "withdrawal",
									action: "click",
									label: "confirm-" + withdrawalCode.toLowerCase(),
								},
								submitDisabled: loading,
								submitText: getTranslation(
									`frontend.modals.withdrawal.stepConfirmWithdrawButtonText`,
									true
								),
							}}
						/>
					</>
				)
			}
		</>
	);
};

WithdrawalConfirm.propTypes = {
	cancelEventHandler: PropTypes.func,
	currency: PropTypes.string,
	emailAddress: PropTypes.string,
	primaryAddress: PropTypes.object,
	secondaryAddress: PropTypes.object,
	showAddressOnConfirm: PropTypes.bool,
	fixedFeeAmount: PropTypes.number,
	variableFeePercentage: PropTypes.number,
	variableFeeAmount: PropTypes.number,
	targetCurrency: PropTypes.string,
	targetCurrencyMultiplier: PropTypes.number,
	withdrawalCode: PropTypes.string,
	withdrawalCompleteEventHandler: PropTypes.func,
	withdrawAmount: PropTypes.number,
	withdrawalModalTextLabelChangeHandler: PropTypes.func,
	setUpdateAddress: PropTypes.func,
	customConfirmStepDescription: PropTypes.string,
	customCompleteStepDescription: PropTypes.string,
	issueNewCard: PropTypes.bool,
};

WithdrawalConfirm.defaultProps = {
	cancelEventHandler: () => {},
	targetCurrency: "",
	targetCurrencyMultiplier: 0,
};

export default WithdrawalConfirm;
