import {
	ColorGrey40,
	ColorGrey60,
	ColorGrey80,
	ColorGrey100,
	ColorWhite,
} from "@sermo/design-tokens";

export const applyTextColor = (textColor) => {
	switch (textColor) {
		case "text-05":
			return `color: ${ColorWhite};`;
		case "text-04":
			return `color: ${ColorGrey40};`
		case "text-03":
			return `color: ${ColorGrey60};`;
		case "text-02":
			return `color: ${ColorGrey80};`
		case "text-01":
		default:
			return `color: ${ColorGrey100};`;
	}
}