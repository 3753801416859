import PropTypes from "prop-types";
import React from "react";
import { TYPE_MASTERCARD } from "@components/Modal/Modals/Withdrawals/withdrawalUtils";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import ModalColumn from "./../ModalColumn";
import ModalColumnWrapper from "./../ModalColumnWrapper";
import ModalSection from "./../ModalSection";
import styles from "./ModalWithdrawalComponents.scss";

const AmountOverview = ({
	currency,
	fixedFeeAmount,
	withdrawAmount,
	variableFeePercentage,
	variableFeeAmount,
	showOriginalAmount,
	showFee,
	targetCurrency,
	targetCurrencyMultiplier,
}) => {
	const feeMultiplier = -1;

	const deductedWithdrawalAmount = withdrawAmount - fixedFeeAmount - variableFeeAmount;

	return (
		<div styleName="styles.amount-overview">
			<div styleName="styles.title">{getTranslation("frontend.modals.withdrawal.amount")}</div>
			{
				showOriginalAmount && (
					<>
						<ModalSection subSection={true}>
							<ModalColumnWrapper>
								<ModalColumn>
									<div styleName="styles.description">
										{getTranslation("frontend.modals.withdrawal.withdrawalAmount")}
									</div>
								</ModalColumn>
								<ModalColumn>
									<div styleName="styles.value">{targetCurrency && currency} {getCurrencyTranslation(withdrawAmount, currency)}</div>
								</ModalColumn>
							</ModalColumnWrapper>
						</ModalSection>
					</>
				)
			}
			{
				!!showFee && !!(fixedFeeAmount || variableFeePercentage || targetCurrencyMultiplier) && (
					<>
						{
							!!fixedFeeAmount && (
								<>
									<ModalSection subSection={true}>
										<ModalColumnWrapper>
											<ModalColumn>
												<div styleName="styles.description">
													{getTranslation("frontend.modals.withdrawal.processingFee")}
												</div>
											</ModalColumn>
											<ModalColumn>
												<div styleName="styles.value">
													{targetCurrency && currency} {getCurrencyTranslation(fixedFeeAmount * feeMultiplier, currency)}
												</div>
											</ModalColumn>
										</ModalColumnWrapper>
									</ModalSection>
								</>
							)
						}
						{
							!!variableFeePercentage && (
								<>
									<ModalSection subSection={true}>
										<ModalColumnWrapper>
											<ModalColumn>
												<div styleName="styles.description">
													{getTranslation("frontend.modals.withdrawal.variableFee", true, variableFeePercentage)}
												</div>
											</ModalColumn>
											<ModalColumn>
												<div styleName="styles.value">
													{targetCurrency && currency} {
														withdrawAmount > 0
															? getCurrencyTranslation(variableFeeAmount * feeMultiplier, currency)
															: "--"
													}
												</div>
											</ModalColumn>
										</ModalColumnWrapper>
									</ModalSection>
								</>
							)
						}
					</>
				)
			}
			<ModalSection subSection={true}>
				<ModalColumnWrapper>
					<ModalColumn>
						<div styleName="styles.description styles.total">
							{getTranslation("frontend.modals.withdrawal.totalWithdrawal")}{
								targetCurrency && targetCurrencyMultiplier > 0
									? ` (${currency})`
									: ""
							}
						</div>
					</ModalColumn>
					<ModalColumn>
						<div styleName="styles.total-value">
							{targetCurrency && currency}
							&nbsp;
							{
								deductedWithdrawalAmount >= 0
									? getTranslation("system.currencies." + currency)
									: ""
							}
							{
								deductedWithdrawalAmount >= 0
									? Math.max(deductedWithdrawalAmount, 0).toFixed(2)
									: "--"
							}
						</div>
					</ModalColumn>
				</ModalColumnWrapper>
			</ModalSection>
			{
				targetCurrency && targetCurrencyMultiplier > 0 && deductedWithdrawalAmount > 0 && (
					<>
						<ModalColumnWrapper>
							<ModalColumn>
							</ModalColumn>
							<ModalColumn>
								<div styleName="styles.value">
								({getTranslation("frontend.modals.withdrawal.paymentValue")} {targetCurrency} {
										withdrawAmount >= 0
											? getCurrencyTranslation(deductedWithdrawalAmount * targetCurrencyMultiplier, targetCurrency)
											: "--"
									})
								</div>
							</ModalColumn>
						</ModalColumnWrapper>
					</>
				)
			}
		</div>
	);
};

AmountOverview.propTypes = {
	currency: PropTypes.string,
	fixedFeeAmount: PropTypes.number,
	variableFeePercent: PropTypes.number,
	variableFeeAmount: PropTypes.number,
	withdrawAmount: PropTypes.number,
	variableFeePercentage: PropTypes.number,
	showOriginalAmount: PropTypes.bool,
	showFee: PropTypes.bool,
	targetCurrency: PropTypes.string,
	targetCurrencyMultiplier: PropTypes.number,
};

AmountOverview.defaultProps = {
	currency: "USD",
	fixedFeeAmount: 0,
	variableFeePercent: 0,
	variableFeeAmount: 0,
	withdrawAmount: 0,
	showOriginalAmount: true,
	showFee: true,
	targetCurrency: "",
	targetCurrencyMultiplier: 0,
};

export default AmountOverview;
