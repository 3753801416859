import { Radio, RadioGroup } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { getLastFourDigitsOfTelephoneNumber, getObfuscateEmail } from "@components/Helpers/Helpers";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import loggedOutStyles from "../../../LoggedOut/LoggedOut.scss";
import registerStyles from "../../Register.scss";

export const METHOD_SMS = "SMS";
export const METHOD_EMAIL = "Email";

const RadioGroupStyled = styled(RadioGroup)`
	gap: 8px;
`;

const Form = ({ data, updateData, endpoint }) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const registerTrackEvent = useRegisterTrack();

	useEffect(() => {
		// registration-process-verification-page-view
		registerTrackEvent({
			label: "verification-page",
			action: "view",
		});
	}, []);

	const [globalValidationError, setGlobalValidationError] = useState("");

	const formSubmitHandler = (e) => {
		e.preventDefault();

		if (e.name !== "back") {
			setLoading(true);

			apiEndpoint(`${endpoint}/resendverificationcode`, { method: data.verificationCodeMethod }).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (dataResponse.ok) {
						registerTrackEvent({
							label: "verification",
							area: "resend-verification",
							action: "success",
						}, true);

						updateData({
							...body,
						});
					}
					else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}
					}
				});
			});
		}
	};

	const goBackClientEventHandler = (e) => {
		e.preventDefault();
		updateData({ currentStep: "EnterName" });

		// registration-process-verify-back-click
		registerTrackEvent({
			label: "verify-back",
			action: "click",
		});

		let eventRegistrantToken = null;
		let eventToken = null;
		if ("undefined" !== typeof window) {
			if (window.sermo) {
				if (window.sermo.temp) {
					if (window.sermo.temp.eventRegistrantToken) {
						eventRegistrantToken = window.sermo.temp.eventRegistrantToken;
					}

					if (window.sermo.temp.eventToken) {
						eventToken = window.sermo.temp.eventToken;
					}

					registerTrackEvent({
						label: "verification",
						area: "cancel-signup",
						action: "click",
					}, true);
				}
			}
		}
	};

	const onChangeMethod = (e) => {
		updateData({ verificationCodeMethod: e.target.value });
		// registration-process-verify-text-select
		// registration-process-verify-email-select
		registerTrackEvent({
			label: "verify",
			action: `${e.target.value === METHOD_SMS
				? "text"
				: "email" }-select`,
		});
	}

	return (
		<>
			<div styleName={classNames(["loggedOutStyles.form-title", "typography.heading-brand-04"])}>
				{getTranslation("frontend.registration.verify", true)}
			</div>
			<div styleName={classNames(["loggedOutStyles.form-subtitle", "typography.body-long-brand-02"])}>
				{getTranslation("frontend.registration.whereToSend", true)}
			</div>

			<form
				onSubmit={formSubmitHandler}
				styleName={classNames(["registerStyles.form"])}
			>
				<div styleName={classNames(["registerStyles.form-field-row"])}>
					<RadioGroupStyled
						value={data.verificationCodeMethod}
						name="telephoneType"
						onChange={onChangeMethod}
					>
						<Radio
							label={getTranslation("frontend.registration.sendSMS", true, getLastFourDigitsOfTelephoneNumber(data.mobileNumber, data.selectedCountryCode, data.availableCountries))}
							value={METHOD_SMS}

						/>
						<Radio
							label={getTranslation("frontend.registration.sendEmail", true, getObfuscateEmail(data.emailAddress))}
							value={METHOD_EMAIL}
						/>
					</RadioGroupStyled>
				</div>

				<GlobalValidationMessage text={globalValidationError} />

				<div styleName={classNames(["registerStyles.submit-container"])}>
					<div styleName={classNames(["registerStyles.submit-container-side"])}>
						<div styleName={classNames(["registerStyles.submit-container--back-button"])}>
							<Button
								name="back"
								size="medium"
								style="flatNeutral"
								disabled={loading}
								clickHandler={goBackClientEventHandler}
							>
								{getTranslation("frontend.generics.back", true)}
							</Button>
						</div>
						<Button
							name="submit"
							size="medium"
							type="submit"
							disabled={!data.verificationCodeMethod || loading}
							loading={loading}
							clickHandler={formSubmitHandler}
						>
							{getTranslation("frontend.generics.next", true)}
						</Button>
					</div>
					<div
						styleName={classNames("registerStyles.submit-container--link", "typography.body-long-brand-00")}
					>
						{getTranslation("frontend.registration.messageRate", true)}
					</div>
				</div>
			</form>
		</>
	)
}

const SelectVerificationCodeMethod = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");

	return (
		<>
			{
				isRegister
				&& <div
					styleName={classNames(["loggedOutStyles.content-box-wider"])}
					data-component="SelectVerificationCodeMethod"
				>
					<div styleName={classNames(["loggedOutStyles.content-box-inner-container"])}>
						<Form
							data={data}
							updateData={updateData}
							endpoint={endpoint}
						/>
					</div>
				</div>
			}
			{
				!isRegister
				&& <div
					styleName={classNames("registerStyles.reverification")}
					data-component="SelectVerificationCodeMethod"
				>
					<Form
						data={data}
						updateData={updateData}
						endpoint={endpoint}
					/>
				</div>
			}
		</>
	);
};

Form.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

SelectVerificationCodeMethod.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

export default SelectVerificationCodeMethod;
