import styled from "styled-components";
import { StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const MessagingContainer = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`;
export const MessagingContent = styled.div `
	width: 100%;
	max-width: var(--site-max-width);
	min-width: min(var(--site-max-width), 100vw);
	display: flex;

	${StyledTabletLandscapeAndDesktopQuery} {
		position: fixed;
		top: ${(props) => props.$top}px;
	}
`;
