import { ColorGrey20, ColorGrey40 } from "@sermo/design-tokens";
import styled, { css } from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const Date = styled.span`
	color: rgb(112, 116, 131);
	font-size: 12px;
	font-family: Graphik;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;

	&:before {
		content: "•";
		margin: 0 6px;
		color: ${ColorGrey20};
		font-size: 12px;
	}
`;

export const Header = styled.h2`
	margin-bottom: 0;
	color: rgb(64, 70, 89);
	font-size: 12px;
	font-family: Graphik;
	font-weight: 500;
	letter-spacing: 0px;
	line-height: 16px;
`;

export const TopicAndMenu = styled.div`
	min-height: 32px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;

	@media ${MobileAndTabletPortraitQuery} {
		width: 100%;
		justify-content: space-between;
	}
`;

export const TopicIndicator = styled.div`
	display: flex;
	align-items: center;
	margin-right: 12px;
	color: ${ColorGrey40};
	text-align: right;
	white-space: nowrap;
	cursor: pointer;
	> div {
		color: ${ColorGrey40};
	}
`;

export const WrapFollowedIndicator = styled.div`
	@media ${MobileAndTabletPortraitQuery} {
		padding: 16px 12px;
		flex-basis: 100%;
	}
`;

export const WrapCardHeader = styled.div`
	--cards-padding-mobile: ${props => props.theme.layout.cardsPaddingMobile};
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;

	@media ${MobileAndTabletPortraitQuery} {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}

	.card-menu {
		padding: 16px 16px 0 0;
		display: flex;

		@media ${MobileAndTabletPortraitQuery} {
			padding: 4px 12px 4px 4px;
			justify-content: space-between;
		}
	}

	.card-menu-inner {
		@media ${MobileAndTabletPortraitQuery} {
			display: flex;
		}
	}

	${props => props.themeType === "TopBar" && css`
		//padding-bottom: 12px;
		align-items: center;

		@media ${MobileAndTabletPortraitQuery} {
			padding-bottom: 12px;
			padding-top: 8px;
			align-items: flex-start;
        }
	`}

	${props => props.expanded && css`
		//padding: 32px 64px 0;
	  	padding-top: 8px;
		flex-direction: row;

		@media ${MobileAndTabletPortraitQuery} {
			//padding: var(--cards-padding-mobile) var(--cards-padding-mobile) 0;
			flex-direction: column-reverse;
		}

		${WrapFollowedIndicator} {
            padding-left: 0;
            padding-top: 0;
        }

		${Header} {
			padding-top: 8px;
        }
	`}
`;

