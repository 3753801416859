export const AttachmentType = Object.freeze({
    UNKNOWN: "Unknown",
    PDF_DOCUMENT: "PdfDocument",
    IMAGE: "Image",
    MP4_VIDEO: "Mp4Video",
    YOUTUBE_VIDEO: "YouTubeVideo",
    VIMEO_VIDEO: "VimeoVideo",
    SLIDE_SHARE: "SlideShare",
    WEB_PAGE: "WebPage",
});
const AttachmentTypeValues = Object.values(AttachmentType);
