import { TextStyled } from "@sermo/ui-components/unstable"
import { useEffect, useCallback, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AttachmentsActionTypes } from "@contexts/Attachments";
import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { useSaving } from "@contexts/PostUI/Editing";
import { PostUIEditingActionTypes } from "@contexts/PostUI/Editing/PostUIEditingReducer";
import { PostUIActionTypes } from "@contexts/PostUI/PostUIReducer";
import { EUIKey, useOpenAlert, useOpenModal, useCloseModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import { useDebounce, usePrevious, useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { requirements } from "./PostEditorAPISync.utils";

const ModalAlert = styled(TextStyled).attrs({
	$kind: "bodyShort02",
})`
	margin-right: 50px;
`;

// based on changes to the postDataContext this will automatically synchronize the post with the server
const PostEditorAPISync = ({ publishCard }) => {
	const apiEndpoint = useApiEndpoint();
	const debounceDelay = 2000;
	const isFeed = useMatch("/feed*");
	const isProfile = useMatch("/profile*");
	const member = useMemoizedContext("member");
	const navigate = useNavigate();
	const openAlert = useOpenAlert();
	const save = useSaving();
	const trackEvent = useTrackEvent();
	const openModal = useOpenModal();
	const closeModal = useCloseModal();

	const {
		dispatch: dispatchPostUI,
		draftStatus,
		publishing,
		single,
	} = useMemoizedContext("postUI", [
		"draftStatus",
		"publishing",
		"single",
	]);

	const {
		canPost,
		dispatch: dispatchPostUIEditing,
		fileUploadError,
		saving,
	} = useMemoizedContext("postUIEditing", [
		"fileUploadError",
		"canPost",
		"saving",
	]);

	const postDataContext = useMemoizedContext("postData",[
		"attachments",
		"contentHtml",
		"id",
		"isAnonymous",
		"mentions",
		"poll",
		"subType",
		"title",
		"topic",
		"topicId",
		"type",
		"type",
	]);

	const {
		contentHtml,
		dispatch: dispatchPostData,
		id: postId,
		isAnonymous,
		mentions,
		poll,
		subType,
		title,
		topic,
		topicId,
		type,
	} = postDataContext;

	const {
		attachmentsToBeDeleted,
		dispatch: dispatchAttachments,
	} = useMemoizedContext("attachments", [
		"attachmentsToBeDeleted",
	]);

	const apiCall = options => {
		const defaults = {
			endpoint: "posts",
			body: {},
			formData: false,
		};

		const { endpoint, action, body, formData } = {
			...defaults,
			...options,
		};

		return apiEndpoint(`${endpoint}/${action}`, body, formData);
	};

	const initialDataNotLoaded = useRef(!single); // TODO: test this
	useEffect(() => {
		if (initialDataNotLoaded.current) {
			initialDataNotLoaded.current = false;
		}
	});

	const testCanPost = useCallback(() => {
		const tests = requirements[type]?.map(test => test.test(postDataContext[test.prop], postDataContext));

		return !tests?.includes(false);
	}, [
		postDataContext,
		type,
	]);

	// controls whether or not the post button is active
	useEffect(() => {
		if (
			publishing
			|| !type
		) {
			return;
		}

		const canPostTested = testCanPost();

		if (
			[DraftStatusTypes.COMPOSING, DraftStatusTypes.EDITING].includes(draftStatus)
			&& canPost !== canPostTested
		) {
			dispatchPostUIEditing({
				type: canPostTested
					? PostUIEditingActionTypes.SET_CAN_POST_TRUE
					: PostUIEditingActionTypes.SET_CAN_POST_FALSE,
			});
		}
	},[
		canPost,
		dispatchPostUIEditing,
		draftStatus,
		poll,
		publishing,
		saving,
		testCanPost,
	]);

	const [DBTitle] = useDebounce(title, debounceDelay);
	const [shouldSaveTitle, setShouldSaveTitle] = useState(false);
	const [DBContent] = useDebounce(contentHtml, debounceDelay);
	const [shouldSaveContent, setShouldSaveContent] = useState(false);
	// If any of these values are not the same as their debounced value
	// we set shouldSave to true which sets the value of saving to true
	useEffect(() => {
		const shouldSave = DBTitle !== title;
		setShouldSaveTitle(shouldSave);
		if (!shouldSave && saving) {
			save.stop("saveTitle");
		}
	}, [
		title,
		DBTitle,
	])

	useEffect(() => {
		const shouldSave = DBContent.localeCompare(contentHtml) !== 0;
		setShouldSaveContent(shouldSave);
		if (!shouldSave && saving) {
			save.stop("saveContent");
		}
	}, [
		contentHtml,
		DBContent,
	])

	// whenever shouldSave becomes true set saving to true
	// this disables the post / save buttons and shows the loading spinner
	useEffect(() => {
		if (shouldSaveTitle) {
			save.start("saveTitle")
		}
		if (shouldSaveContent) {
			save.start("saveContent")
		}
	}, [
		shouldSaveTitle,
		shouldSaveContent,
	])

	useEffect(() => {
		if (fileUploadError) {
			openModal({
				label: "post-edited",
				closeMethod: () => {
					closeModal("post-edited");
				},
				component: (
					<ModalAlert>
						{fileUploadError}
					</ModalAlert>
				),
				modalType: "alert",
			});

			dispatchPostUIEditing({
				type: PostUIEditingActionTypes.RESET_FILE_UPLOAD_ERROR
			});
		}
	}, [
		fileUploadError,
	])

	const DBTitlePrev = usePrevious(DBTitle);
	const DBContentPrev = usePrevious(DBContent);
	const isAnonymousPrev = usePrevious(isAnonymous);
	const topicPrev = usePrevious(topicId);
	const subTypePrev = usePrevious(subType);

	// TODO: seems to save title and topic too much, needs looking into
	// the mentions value is update immediately but the change adding/removing one causes in the content is
	// what actually triggers a save call so we don't need to watch for changes to the actual value
	const apiSaveCall = (saveLabel) => {
		if (
			!publishing
			&& !initialDataNotLoaded.current
			&& [DraftStatusTypes.COMPOSING, DraftStatusTypes.EDITING].includes(draftStatus)
			&& postId
		) {
			save.start(saveLabel);
			apiCall({
				action: "save",
				body: {
					id: postId,
					subType,
					isAnonymous,
					title: DBTitle,
					contentHtml: DBContent,
					mentions,
					topicId: topicId || null,
				},
			}).then((dataResponse) => {
				if (dataResponse.ok) {
					dataResponse.json().then(body => {
						if (body.suggestedTopics) {
							dispatchPostUIEditing({
								type: PostUIEditingActionTypes.SET_SUGGESTED_TOPICS,
								payload: {
									suggestedTopics: body.suggestedTopics,
								},
							});
						}
					});
				}
				save.stop(saveLabel);
			});
		}
	}

	useEffect(() => {
		if(
			"undefined" !== typeof topicPrev
			&& topicId !== topicPrev
		) {
			apiSaveCall("topic");
		}
	}, [
		topicId,
		topicPrev,
	]);

	useEffect(() => {
		if(
			"undefined" !== typeof DBTitlePrev
			&& DBTitle !== DBTitlePrev
		) {
			// reset topic if no title and content
			if (
				DBTitle === ""
				&& DBContent === "<p></p>"
			) {
				dispatchPostData({
					type: PostDataActionTypes.SET_TOPIC_ID,
					payload: {
						topicId: null,
					},
				});
			}
			apiSaveCall("saveTitle");
		}
	}, [
		DBTitle,
		DBTitlePrev
	]);

	useEffect(() => {
		if (
			"undefined" !== typeof DBContentPrev
			&& DBContent !== DBContentPrev
		) {
			// reset topic if not title and content
			if (DBTitle === "" && DBContent === "<p></p>") {
				dispatchPostData({
					type: PostDataActionTypes.SET_TOPIC_ID,
					payload: {
						topicId: null,
					},
				});
			}
			apiSaveCall("saveContent");
		}
	}, [
		DBContent,
		DBContentPrev
	]);

	useEffect(() => {
		if (
			"undefined" !== typeof subTypePrev
			&& subType !== subTypePrev
		) {
			apiSaveCall("subType");
		}
	}, [
		subType,
		subTypePrev,
	]);

	useEffect(() => {
		if (
			(
				"undefined" !== typeof isAnonymous
				&& "undefined" !== typeof isAnonymousPrev
			)
			&& isAnonymous !== isAnonymousPrev
		) {
			apiSaveCall("anon");
		}
	}, [
		isAnonymous,
	]);

	useEffect(() => {
		const action = DraftStatusTypes.COMPOSING === draftStatus
			? "publish"
			: "republish";

		if (publishing) {
			if (subType) {
				trackEvent({
					category: "post-" + type.toLowerCase(),
					action: "click",
					label: subType.toLowerCase(),
					postId,
				});
			}

			if (isAnonymous) {
				trackEvent({
					category: "post-" + type.toLowerCase(),
					action: "click",
					label: "post-anonymous",
					postId,
				});
			}

			trackEvent({
				category: "post-" + type.toLowerCase(),
				action: "click",
				label: "post-publish",
				postId,
			});

			// give the save debounce time to complete before publishing
			window.setTimeout(() => {
				apiCall({
					action,
					body: {
						id: postId,
					},
				}).then(dataResponse => {
					if (dataResponse.ok) {
						dataResponse.json().then(body => {
							const {
								draft,
								topic,
							} = body;

							if (DraftStatusTypes.COMPOSING === draftStatus) {
								initialDataNotLoaded.current = true;

								if (publishCard) {
									publishCard({
										...postDataContext,
										topic,
										cardType: CardTypeTypes.POST,
										postDate: getTranslation("system.dateTime.justNow", true),
										user: member.formatMemberForFeed(member, isAnonymous),
									});

									if (
										!isFeed
										&& !isProfile
									) {
										navigate(`/post/${postId}`);
									}
								}

								if (draft) {
									dispatchPostData({
										type: PostDataActionTypes.LOAD_DRAFT,
										payload: {
											draft,
										},
									});
									dispatchPostUI({
										type: PostUIActionTypes.START_DRAFT,
									});
								}

								dispatchAttachments({ type: AttachmentsActionTypes.RESET_ATTACHMENTS });
							}
							else {
								dispatchPostUI({
									type: PostUIActionTypes.PUBLISH_DRAFT,
								});

								openAlert({
									[EUIKey.ALERT_COMPONENT]: (
										<ModalAlert>
											{getTranslation("frontend.alerts.postEdited")}
										</ModalAlert>
									),
									[EUIKey.ALERT_LABEL]: "post-edited",
								});
							}
						});
					}
				});
			}, debounceDelay + 1000);
		}
	}, [publishing]);

	useEffect(() => {
		if (
			publishing
			&& attachmentsToBeDeleted?.length
		) {
			attachmentsToBeDeleted?.forEach((id) => {
				apiCall({
					action: "deleteAttachment",
					body: {
						id,
					},
				});
			});
			dispatchAttachments({
				type: AttachmentsActionTypes.RESET_ATTACHMENT_TO_BE_DELETED,
			});
		}
	}, [
		attachmentsToBeDeleted,
		publishing
	]);

	// topic change
	const topicTagId = topic
		? topic.id
		: undefined;
	const topicPrevious = usePrevious(topicTagId);
	useEffect(() => {
		if (
			"undefined" !== typeof topicPrevious
			&& "undefined" !== typeof topic
			&& "undefined" !== typeof topic.id
			&& topicTagId !== topicPrevious
		) {
			apiCall({
				action: "selecttopic",
				body: {
					postId,
					topicId: topicTagId,
				},
			});
		}
	}, [topicTagId]);

	return null;
};

export default PostEditorAPISync;
