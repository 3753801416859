import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { GlobalValidationMessage, TextField } from "@components/FormFields/FormFields";
import AddPhoneNumber from "@components/Modal/Modals/Withdrawals/AddPhoneNumber";
import UpdateAddress from "@components/Modal/Modals/Withdrawals/UpdateAddress";
import getTranslation, { findByKey, getCurrencyTranslation } from "@translation/translation";
import {
	ModalColumn,
	ModalColumnWrapper,
	ModalFooter,
	ModalSection,
	ModalSeparator,
	ModalText,
	ModalTitle,
	Withdrawal,
} from "./../ModalComponents";
import AddDateOfBirth from "./AddDateOfBirth";
import AddNewAddress from "./AddNewAddress";
import LosePreferredStatusConfirm from "./LosePreferredStatusConfirm";
import WithdrawalConfirm from "./WithdrawalConfirm";
import {
	getTranslationSubKey,
	getWithdrawalAmountValidationTranslation,
	TYPE_MASTERCARD,
	useModalStepsFramework,
	validateWithdrawalAmount,
	withdrawalWillRevokePreferredStatus,
} from "./withdrawalUtils";

const WithdrawalFlow = ({
	allCountries,
	allRegions,
	availableFunds,
	currency,
	phoneNumber,
	emailAddress,
	primaryAddress,
	secondaryAddress,
	dateOfBirth,
	fixedFeeAmount,
	variableFeePercentage,
	targetCurrency,
	targetCurrencyMultiplier,
	isPreferredMember,
	needPhoneNumber,
	needEmail,
	needAddress,
	showAddressOnConfirm,
	needDateOfBirth,
	limitToWithdrawalAmounts,
	minimumWithdrawalAmount,
	preferredStatusThreshold,
	stepBackClickHandler,
	withdrawalCode,
	withdrawalCompleteEventHandler,
	withdrawalModalTextLabelChangeHandler,
	customConfirmStepDescription,
	customCompleteStepDescription,
	issueNewCard,
}) => {
	const [primaryAddressInternal, setPrimaryAddressInternal] = useState(primaryAddress);
	const [secondaryAddressInternal, setSecondaryAddressInternal] = useState(secondaryAddress);
	const [updateAddress, setUpdateAddress] = useState(false);
	const [dateOfBirthInternal, setDateOfBirthInternal] = useState(dateOfBirth || undefined);
	const [phoneNumberInternal, setPhoneNumberInternal] = useState(phoneNumber || "");
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const [withdrawAmountValidationError, setWithdrawAmountValidationError] = useState("");
	const [variableFeeAmount, setVariableFeeAmount] = useState(0.0);
	const translationKey = getTranslationSubKey(withdrawalCode);
	// Used to trigger Modal title change when step changes
	const [stepChanger, setStepChanger] = useState(false);

	// Defining and setting all withdrawal flow steps
	const steps = [];

	steps.push({
		name: "enterWithdrawalAmount",
		condition: true,
	});

	steps.push({
		name: "confirmRevokingPreferredStatus",
		condition: withdrawalWillRevokePreferredStatus(
			isPreferredMember,
			preferredStatusThreshold,
			availableFunds,
			withdrawAmount
		),
	});

	steps.push({
		name: "askAddress",
		condition: needAddress,
	});

	steps.push({
		name: "askDateOfBirth",
		condition: needDateOfBirth,
	});

	steps.push({
		name: "askPhoneNumber",
		condition: needPhoneNumber,
	});

	steps.push({
		name: "confirm",
		condition: true,
	});

	const [goToNextStep, goToPreviousStep, currentStepName] = useModalStepsFramework(steps, () => {
		setStepChanger(!stepChanger);
	});

	useEffect(() => {
		// Since some translations are withdrawal type specialized, we are returning specific or fallback translations.
		let key, fallback;
		switch (currentStepName()) {
			case "askAddress":
				key = `frontend.modals.withdrawal.${translationKey}.newAddressTitle`;
				fallback = `frontend.modals.withdrawal.newAddressTitle`;
				withdrawalModalTextLabelChangeHandler(getTranslation(findByKey(key)
					? key
					: fallback, true));
				break;
			case "askDateOfBirth":
				key = dateOfBirth
					? `frontend.modals.withdrawal.${
						translationKey}.stepVerifyDOBTitle`
					: `frontend.modals.withdrawal.${
						translationKey}.stepDOBTitle`;
				fallback = dateOfBirth
					? `frontend.modals.withdrawal.stepVerifyDOBTitle`
					: `frontend.modals.withdrawal.stepDOBTitle`;
				withdrawalModalTextLabelChangeHandler(getTranslation(dateOfBirth
					? `frontend.modals.withdrawal.${
						translationKey}.stepVerifyDOBTitle`
					: `frontend.modals.withdrawal.${
						translationKey}.stepDOBTitle`, true));
				break;
			case "askPhoneNumber":
				key = fallback = `frontend.modals.withdrawal.addPhoneNumber`;
				withdrawalModalTextLabelChangeHandler(getTranslation(`frontend.modals.withdrawal.addPhoneNumber`));
				break;
			case "confirm":
				key = fallback = updateAddress
					? "frontend.modals.withdrawal.updateAddressTitle"
					: "frontend.modals.withdrawal.stepConfirmTitle";
				break;
			case "enterWithdrawalAmount":
				key = fallback = "frontend.modals.withdrawal.stepStartTitle";
				break;
			default:
				key = fallback = "frontend.modals.withdrawal.selectWithdrawalMethod.title";
				break;
		}
		withdrawalModalTextLabelChangeHandler(getTranslation(findByKey(key)
			? key
			: fallback, true));
	}, [stepChanger, updateAddress]);

	const withdrawAmountChangeHandler = (event) => {
		setWithdrawAmount(event.target.value);

		let amount = parseInt(event.target.value);

		if (variableFeePercentage) {
			if (amount >= minimumWithdrawalAmount) {
				let fee = amount * variableFeePercentage / 100;
				// https://stackoverflow.com/a/11832950/3655745
				setVariableFeeAmount(Math.round((fee + Number.EPSILON) * 100) / 100);
			} else {
				setVariableFeeAmount(0.0);
			}
		}
	};

	const stepBackClickEventHandler = () => {
		stepBackClickHandler(true);
	}

	const updateAddressCompleteEventHandler = (newAddress, addressType) => {
		addressType === "secondary"
			? setSecondaryAddressInternal(newAddress)
			: setPrimaryAddressInternal(newAddress);
		setUpdateAddress(false);
	};

	const cancelUpdateAddressHandler = () => {
		goToPreviousStep();
	};

	const newAddressCompleteEventHandler = (newAddress) => {
		setPrimaryAddressInternal(newAddress);
		goToNextStep();
	};

	const newDateOfBirthCompleteEventHandler = (newDateOfBirth) => {
		setDateOfBirthInternal(newDateOfBirth);
		goToNextStep();
	};

	const newPhoneNumberCompleteEventHandler = (newPhoneNumber) => {
		setPhoneNumberInternal(newPhoneNumber);
		goToNextStep();
	};

	const confirmAmountClickHandler = () => {
		const [isWithdrawalAmountValid, validationMessage] = validateWithdrawalAmount(
			withdrawAmount,
			currency,
			availableFunds,
			issueNewCard
				? Math.ceil(fixedFeeAmount) + minimumWithdrawalAmount
				: minimumWithdrawalAmount,
			limitToWithdrawalAmounts,
			translationKey
		);
		if (isWithdrawalAmountValid) {
			goToNextStep();
		}
		setWithdrawAmountValidationError(validationMessage);
	};

	return (
		<>
			{
				currentStepName() === "confirm" && !updateAddress && (
					<WithdrawalConfirm
						withdrawalCode={withdrawalCode}
						currency={currency}
						withdrawAmount={Number(withdrawAmount)}
						emailAddress={
							needEmail
								? emailAddress
								: ""
						}
						primaryAddress={primaryAddressInternal}
						secondaryAddress={secondaryAddressInternal}
						showAddressOnConfirm={showAddressOnConfirm}
						cancelEventHandler={goToPreviousStep}
						withdrawalCompleteEventHandler={withdrawalCompleteEventHandler}
						withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
						fixedFeeAmount={fixedFeeAmount}
						variableFeePercentage={variableFeePercentage}
						variableFeeAmount={variableFeeAmount}
						targetCurrency={targetCurrency}
						targetCurrencyMultiplier={targetCurrencyMultiplier}
						customConfirmStepDescription={customConfirmStepDescription}
						customCompleteStepDescription={customCompleteStepDescription}
						issueNewCard={issueNewCard}
						setUpdateAddress={setUpdateAddress}
					/>
				)
			}
			{
				currentStepName() === "confirm" && updateAddress && (
					<UpdateAddress
						description={getTranslation(`frontend.modals.withdrawal.stepAddressDescription`)}
						cancelEventHandler={cancelUpdateAddressHandler}
						newAddressCompleteEventHandler={updateAddressCompleteEventHandler}
						allCountries={allCountries}
						allRegions={allRegions}
						valuePrimary={primaryAddressInternal}
						valueSecondary={secondaryAddressInternal}
					/>
				)
			}

			{
				currentStepName() === "confirmRevokingPreferredStatus" && (
					<LosePreferredStatusConfirm
						currency={currency}
						preferredStatusThreshold={preferredStatusThreshold}
						cancelEventHandler={goToPreviousStep}
						confirmEventHandler={goToNextStep}
					/>
				)
			}

			{
				currentStepName() === "askPhoneNumber" && (
					<AddPhoneNumber
						cancelEventHandler={goToPreviousStep}
						description={getTranslation(`frontend.modals.withdrawal.addPhoneNumberDescription`)}
						newPhoneNumberCompleteEventHandler={newPhoneNumberCompleteEventHandler}
						value={phoneNumberInternal}
					/>
				)
			}

			{
				currentStepName() === "askAddress" && (
					<AddNewAddress
						description={
							getTranslation(`frontend.modals.withdrawal.${
								translationKey}.newAddressDescription`)
						}
						cancelEventHandler={goToPreviousStep}
						newAddressCompleteEventHandler={newAddressCompleteEventHandler}
						allCountries={allCountries}
						allRegions={allRegions}
						value={primaryAddressInternal}
					/>
				)
			}

			{
				currentStepName() === "askDateOfBirth" && (
					<AddDateOfBirth
						description={
							getTranslation(dateOfBirth
								? `frontend.modals.withdrawal.${translationKey}.stepVerifyDOBDescription`
								: `frontend.modals.withdrawal.${translationKey}.stepDOBDescription`)
						}
						value={dateOfBirthInternal}
						cancelEventHandler={goToPreviousStep}
						newDateOfBirthCompleteEventHandler={newDateOfBirthCompleteEventHandler}
					/>
				)
			}

			{
				currentStepName() === "mastercardFee" && (
					<>
						<ModalTitle>{"mastercardFee"}</ModalTitle>

						<ModalSection>
							<ModalText>{"description"}</ModalText>
						</ModalSection>
						<ModalSection>
						mastercardFee
						</ModalSection>

						<GlobalValidationMessage text={"globalValidationError"} />

						<ModalFooter
							{...{
								cancelModalEventHandler: goToPreviousStep,
								cancelText: getTranslation("frontend.generics.back", true),
								cancelTracking: {
									category: "withdrawal",
									action: "click",
									label: "cancel-mastercard-fee",
								},
								submitEventHandler: () => console.log("Submit mastercardFee"),
								submitTracking: {
									category: "withdrawal",
									action: "click",
									label: "submit-mastercard-fee",
								},
								submitDisabled: true,
								submitText: getTranslation("frontend.generics.next", true),
							}}
						/>
					</>
				)
			}

			{
				currentStepName() === "enterWithdrawalAmount" && (
					<>
						<Withdrawal.PaymentMethod
							{...{
								title: getTranslation("frontend.modals.withdrawal.paymentMethodTitle"),
								method: getTranslation(`frontend.modals.withdrawal.${translationKey}.paymentMethodActual`),
							}}
						/>

						<ModalSection>
							<ModalColumnWrapper>
								<ModalColumn>
									<TextField
										name="withdrawAmount"
										type="text"
										placeholder="0"
										label={getTranslation("frontend.modals.withdrawal.amount", true)}
										autoComplete="withdraw-amount"
										validationError={withdrawAmountValidationError}
										handleChange={withdrawAmountChangeHandler}
										value={withdrawAmount}
										contextClass="withdrawal"
									/>
								</ModalColumn>
								<ModalColumn>
									<Withdrawal.BalanceAvailable
										{...{
											amount: getTranslation(
												"frontend.modals.withdrawal.amountAvailable",
												false,
												getCurrencyTranslation(availableFunds, currency, true)
											),
										}}
									/>
								</ModalColumn>
							</ModalColumnWrapper>
							<Withdrawal.AmountValidation
								{...{
									min: issueNewCard
										? getCurrencyTranslation(
											Math.ceil(fixedFeeAmount) + minimumWithdrawalAmount, currency, true)
										: getCurrencyTranslation(minimumWithdrawalAmount, currency, true) || null,
									withdrawalAmountMessage: limitToWithdrawalAmounts.length > 0
										? getWithdrawalAmountValidationTranslation(limitToWithdrawalAmounts, currency, translationKey)
										: null,
								}}
							/>
						</ModalSection>

						{
							!!(fixedFeeAmount || variableFeePercentage || targetCurrencyMultiplier) && (
								<>
									<ModalSeparator />

									<Withdrawal.AmountOverview
										{...{
											currency: currency,
											fixedFeeAmount: withdrawalCode !== TYPE_MASTERCARD || issueNewCard
												? fixedFeeAmount
												: 0,
											variableFeePercentage: variableFeePercentage,
											variableFeeAmount: variableFeeAmount,
											withdrawAmount: Number(withdrawAmount),
											showOriginalAmount: false,
											targetCurrency: targetCurrency,
											targetCurrencyMultiplier: targetCurrencyMultiplier,
											withdrawalCode: withdrawalCode,
										}}
									/>
								</>
							)
						}

						<ModalFooter
							{...{
								cancelModalEventHandler: stepBackClickEventHandler,
								cancelTracking: {
									category: "withdrawal",
									action: "click",
									label: "cancel-" + withdrawalCode.toLowerCase(),
								},
								cancelText: getTranslation("frontend.generics.back", true),
								submitText: getTranslation("frontend.generics.next", true),
								submitEventHandler: confirmAmountClickHandler,
							}}
						/>
					</>
				)
			}
		</>
	);
};

WithdrawalFlow.propTypes = {
	allCountries: PropTypes.array,
	allRegions: PropTypes.array,
	availableFunds: PropTypes.number,
	currency: PropTypes.string,
	emailAddress: PropTypes.string,
	primaryAddress: PropTypes.object,
	secondaryAddress: PropTypes.object,
	dateOfBirth: PropTypes.object,
	fixedFeeAmount: PropTypes.number,
	variableFeePercentage: PropTypes.number,
	targetCurrency: PropTypes.string,
	targetCurrencyMultiplier: PropTypes.number,
	isPreferredMember: PropTypes.bool,
	needEmail: PropTypes.bool,
	needAddress: PropTypes.bool,
	showAddressOnConfirm: PropTypes.bool,
	needDateOfBirth: PropTypes.bool,
	limitToWithdrawalAmounts: PropTypes.array,
	minimumWithdrawalAmount: PropTypes.number,
	preferredStatusThreshold: PropTypes.number,
	stepBackClickHandler: PropTypes.func,
	withdrawalCode: PropTypes.string,
	withdrawalCompleteEventHandler: PropTypes.func,
	withdrawalModalTextLabelChangeHandler: PropTypes.func,
	customConfirmStepDescription: PropTypes.string,
	customCompleteStepDescription: PropTypes.string,
	issueNewCard: PropTypes.bool,
	phoneNumber: PropTypes.string,
	needPhoneNumber: PropTypes.bool,
};

WithdrawalFlow.defaultProps = {
	allCountries: [],
	allRegions: [],
	limitToWithdrawalAmounts: [],
};

export default WithdrawalFlow;
