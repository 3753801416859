import { ColorUltraviolet50 } from "@sermo/design-tokens";
import { Button, Checkbox, Input } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import Image from "@assets/images/onboarding.jpg";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyTextColor } from "../../../../styled";

export const TopImage = styled.img.attrs({
	src: Image,
	alt: "trial-member-image",
})`
	width: 100%;
`;

export const ContentWrap = styled.div`
	padding: 24px;
`;

export const HeaderText = styled(TextStyled).attrs({ $kind: "heading02" })`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.18px;
`;

export const BodyText = styled(TextStyled).attrs({ $kind: "bodyLong02" })`
	font-size: 14px;
	line-height: 24px;
`;

export const Password = styled(Input).attrs({
	type: "password",
	showToggle: true,
})`
	& > div {
        height: 40px;
    }

	span {
		color: ${ColorUltraviolet50};
		right: 16px;
	}
`;

export const ConfirmCheckbox = styled(Checkbox)`
	align-items: baseline;

	span {
		font-size: 13px;
		line-height: 18px;

		a {
			color: ${ColorUltraviolet50};
			cursor: pointer;
		}
	}
`;

export const Spacer = styled.div`
	height: ${p => p.$size || 16}px;
`;

export const FooterWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const SuccessFooterWrap = styled(FooterWrap)`
	justify-content: flex-end;
`;

export const FooterText = styled(TextStyled).attrs({ $kind: "bodyShort01" })`
	font-size: 13px;
	line-height: 18px;
	${applyTextColor("text-03")};
`;

export const ClickableText = styled(FooterText)`
	color: ${ColorUltraviolet50};
	cursor: pointer;
	display: inline-block;
`;

export const FooterButton = styled(Button).attrs({ size: "medium" })``;

export const TrialMemberModalWrap = styled.div.attrs({"data-component": "TrialMemberModal"})`
	width: 560px;
	border-radius: 8px;
	overflow: hidden;

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
		height: 100%;
		border-radius: 0;
	}

	${BodyText} {
        margin-top: 8px;
		${StyledMobileAndTabletPortraitQuery} {
			margin-top: 16px;
		}
    }

	${Password} {
		margin-top: 32px;
		${StyledMobileAndTabletPortraitQuery} {
			margin-top: 16px;
		}
	}

	${ConfirmCheckbox} {
		margin-top: 20px;
		${StyledMobileAndTabletPortraitQuery} {
			margin-top: 16px;
		}
	}

	${FooterWrap} {
		margin-top: 32px;
		${StyledMobileAndTabletPortraitQuery} {
            margin-top: 24px;

			${FooterButton} {
                margin-top: 16px;
                align-self: flex-end;
            }
        }
	}
`;