import { ModalContainer, ModalTitle, ModalText, ModalSection } from "@components/Modal/Modals/ModalComponents";
import { usePostSurveyText } from "@components/Modal/Modals/PostSurveyBanner";
import EnterNameForm from "../Shared/EnterNameForm";

const EnterName = ({ data, updateData, endpoint }) => {
	const [title, subtitle] = usePostSurveyText();

	return (
		<ModalContainer>
			<ModalTitle>
				{title}
			</ModalTitle>
			<ModalSection>
				<ModalText>
					{subtitle}
				</ModalText>
			</ModalSection>
			<ModalSection>
				<EnterNameForm
					data={data}
					updateData={updateData}
					endpoint={endpoint}
				/>
			</ModalSection>
		</ModalContainer>
	);
};

export default EnterName;
