import {
	ColorRed60,
	ColorUltraviolet80,
	ColorWhite,
	TypographyBs01FontFamily,
} from "@sermo/design-tokens"
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const Badge = styled.p`
	--min-size: 16px;
	--size: ${p => p.digits === 3
		? "27px"
		: p.digits === 2
			? "23px"
			: "var(--min-size)"};
	--padding-top: 1px;
	width: var(--size);
	padding-top: var(--padding-top);
	border-radius: var(--min-size);
	border-width: 2px;
	border-style: solid;
	border-color: ${p => p.isMobile
		? ColorUltraviolet80
		: ColorWhite};
	background-color: ${ColorRed60};
	color: ${ColorWhite};
	font-family: ${TypographyBs01FontFamily};
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: calc(var(--min-size) - var(--padding-top));
	position: absolute;
	top: 6px;
	left: var(--min-size);
	text-align: center;
	box-sizing: content-box;
	margin: 0;
`;

export const BadgeAlternate = styled(Badge)`
	width: unset;
	padding: 1px 6px 0;
	left: 28px;
	${({ $left }) => $left && `left: ${$left};`}
	${({ $top }) => $top && `top: ${$top};`}
`;

export const CtaCopyText = styled(TextStyled).attrs({ $kind: "bodyShort02" })``;

export const CtaText = styled(TextStyled).attrs({ $kind: "bodyShort02" })`
	flex-basis: fit-content;
	white-space: nowrap;
	text-decoration: underline;
	color: ${ColorWhite};
	cursor: pointer;
`;

export const CtaWrap = styled.div`
	max-width: 510px;

	& > *:not(:first-child) {
		margin-top: 24px;
	}
`;

export const Dot = styled.div`
	display: none;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: ${({ theme }) => theme.color.buttonRoundBackground};
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid ${({ theme }) => theme.color.buttonRoundActive};

	.add-dot & {
		display: block;
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: 6px;
		height: 6px;
		border-width: 1px;
	}
`;

export const IconWrap = styled.div`
	position: relative;
	line-height: 0;
`;

export const ModalContentWrap = styled.div`
	display: flex;
	justify-content: space-between;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;

		& > *:not(:first-child) {
            margin-top: 16px;
        }
	}
`;

export const NavItem = styled(TextStyled).attrs({
	$kind: "bodyShort00",
	forwardedAs: "li",
})`
	list-style: none;
	position: relative;

	path {
		fill: ${({ theme }) => theme.color.icon03};
	}

	${StyledMobileAndTabletPortraitQuery} {
        width: 16.66666666%;
    }
`;

export const NavMenu = styled.ul`
	max-width: ${({ theme: { layout } }) => `calc(${layout.siteMaxWidth} - ${layout.logoWidth} - ${layout.logoMargin} - ${layout.searchWidth});`};
	margin: 0;
	padding: 0;
	display: flex;

	${StyledMobileAndTabletPortraitQuery} {
		max-width: 100%;
		width: 100%;
		height: ${({ theme }) => theme.layout.topNavHeightMobile};
		justify-content: space-evenly;
	}
`;

export const NavMenuHidden = styled.div`
	height: 61px;
`;

export const NotificationText = styled(TextStyled).attrs({ $kind: "heading02" })`
	font-weight: 500;
	color: ${ColorWhite};
`;