import { Button, Checkbox, MenuItemType, PopupMenu } from "@sermo/ui-components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const ApplyButton = styled(Button) `
	min-width: 226px;
`;
const FilterMenu = ({ triggerRef, open, setMenuOpen }) => {
    const { feedFiltersQuestions, feedFiltersDiscussions, feedFiltersPatientCases, feedFiltersMemberCountry, feedFiltersMemberSpecialties, update, } = useMemoizedContext("feed", [
        "feedFiltersQuestions",
        "feedFiltersDiscussions",
        "feedFiltersPatientCases",
        "feedFiltersMemberCountry",
        "feedFiltersMemberSpecialties",
    ]);
    const [questions, setQuestions] = useState(feedFiltersQuestions);
    const [discussions, setDiscussions] = useState(feedFiltersDiscussions);
    const [patientCases, setPatientCases] = useState(feedFiltersPatientCases);
    const [memberCountry, setMemberCountry] = useState(feedFiltersMemberCountry);
    const [memberSpecialties, setMemberSpecialties] = useState(feedFiltersMemberSpecialties);
    const reset = () => {
        setQuestions(feedFiltersQuestions);
        setDiscussions(feedFiltersDiscussions);
        setPatientCases(feedFiltersPatientCases);
        setMemberCountry(feedFiltersMemberCountry);
        setMemberSpecialties(feedFiltersMemberSpecialties);
    };
    const updateFeedFilters = () => {
        update({
            feedFiltersQuestions: questions,
            feedFiltersDiscussions: discussions,
            feedFiltersPatientCases: patientCases,
            feedFiltersMemberCountry: memberCountry,
            feedFiltersMemberSpecialties: memberSpecialties,
        });
        setMenuOpen(false);
    };
    useEffect(() => {
        const clickListener = () => {
            if (open) {
                setMenuOpen(false);
            }
        };
        window.addEventListener("click", clickListener);
        return () => {
            window.removeEventListener("click", clickListener);
        };
    }, [open]);
    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);
    return (<PopupMenu triggerRef={triggerRef} open={open} placement="bottom-end" data-component="feed-filter-menu" onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} items={[
            {
                type: MenuItemType.Group,
                divider: false,
                label: getTranslation("frontend.feedMenu.filterPostsBy"),
                items: [
                    {
                        label: <Checkbox data-testid="Your Specialty(ies)" defaultChecked label={getTranslation("frontend.feedMenu.yourSpecialties")} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setMemberSpecialties(!memberSpecialties);
                            }} onChange={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                            }} checked={memberSpecialties}/>
                    },
                    {
                        label: <Checkbox data-testid="Your Country" defaultChecked label={getTranslation("frontend.feedMenu.yourCountry")} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setMemberCountry(!memberCountry);
                            }} onChange={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                            }} checked={memberCountry}/>
                    },
                ],
            },
            {
                type: MenuItemType.Group,
                divider: true,
                label: false,
                items: [
                    {
                        label: <Checkbox data-testid="Patient Cases" defaultChecked label={getTranslation("frontend.generics.patientCases")} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setPatientCases(!patientCases);
                            }} onChange={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                            }} checked={patientCases}/>
                    },
                    {
                        label: <Checkbox data-testid="Discussions" defaultChecked label={getTranslation("frontend.generics.discussions")} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setDiscussions(!discussions);
                            }} onChange={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                            }} checked={discussions}/>
                    },
                    {
                        label: <Checkbox data-testid="Questions" defaultChecked label={getTranslation("frontend.generics.questions")} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setQuestions(!questions);
                            }} onChange={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                            }} checked={questions}/>
                    },
                    {
                        label: <ApplyButton kind={"primary"} size={"small"} onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                updateFeedFilters();
                            }} disabled={patientCases === feedFiltersPatientCases
                                && discussions === feedFiltersDiscussions
                                && questions === feedFiltersQuestions
                                && memberCountry === feedFiltersMemberCountry
                                && memberSpecialties === feedFiltersMemberSpecialties}>{getTranslation("frontend.generics.apply")}</ApplyButton>
                    },
                ],
            },
        ]}/>);
};
export default FilterMenu;
