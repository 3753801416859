import { TextStyled } from "@sermo/ui-components/unstable";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FollowedIndicator from "@components/Card/components/FollowedIndicator/FollowedIndicator";
import Title from "@components/Card/components/Title/Title";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardHeaderCustom from "@components/PostViewer/components/CardHeaderCustom";
import ResourceInFeedMenu from "@components/PostViewer/components/CardHeaderCustom/components/ResourceInFeedMenu";
import ResourceInFeedTitle from "@components/PostViewer/components/CardHeaderCustom/components/ResourceInFeedTitle";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
const FollowersText = styled(TextStyled).attrs({
    $kind: "bodyShort02",
}) `
	color: ${({ theme }) => theme.color.text03};
	font-weight: 500;
`;
const FollowContainer = styled.div `
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ResourceCenterInFeed = () => {
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();
    useEffect(() => {
        trackEvent({
            category: "resourcecenter",
            label: "content",
            action: "view",
            area: "in-feed",
        });
    }, [
        trackEvent,
    ]);
    const { description, disableFollows, followCount, resourceCenterId, } = useMemoizedContext("postData", [
        "description",
        "disableFollows",
        "followCount",
        "resourceCenterId",
    ]);
    const handleCardClick = () => {
        const url = `/resource/${resourceCenterId}`;
        trackEvent({
            category: "resourcecenter",
            label: "expand",
            action: "click",
            area: url,
            url,
        });
        navigate(url);
    };
    return (<>
			<CardContainer onClick={handleCardClick}>
				<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
					<SharedStyles.SpacingContainer>
						<CardHeaderCustom main={<ResourceInFeedTitle />} menu={ResourceInFeedMenu}/>
						<CardHeader />
						<div>
							<Title />
							<TextStyled dangerouslySetInnerHTML={{ __html: description }} $kind="bodyLong02"/>
						</div>
					</SharedStyles.SpacingContainer>
				</ModuleWrapper>
				{!disableFollows
            && followCount > 0
            && (<FollowContainer>
							<FollowersText>
								{followCount} Followers
							</FollowersText>
							<FollowedIndicator />
						</FollowContainer>)}
			</CardContainer>
		</>);
};
export default ResourceCenterInFeed;
