import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import InlineVideoPLayer from "@components/Card/components/VideoPlayer/InlineVideoPlayer";
import { ContentContainer, contentContainerClickHandler, sanitizeLinks, useContentAreaClickEventHandler, useInlineVideos, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import Spacer from "@styled/Spacer";
import * as Styles from "./SermoContentExpanded.styles";
const ContentSermo = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const inlineVideos = useInlineVideos();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { body, finePrintHeader, finePrintText, id, introText, } = useMemoizedContext("postData", [
        "body",
        "finePrintHeader",
        "finePrintText",
        "id",
        "introText",
    ]);
    return useMemo(() => {
        if (readOnlyMember) {
            return null;
        }
        return (<>
				{inlineVideos?.map((iv, i) => (<InlineVideoPLayer el={iv} key={i}/>))}

				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}

				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					{introText
                && (<>
								<Styles.IntroText dangerouslySetInnerHTML={{ __html: sanitizeLinks(introText) }} $kind="bodyLong00" onClick={contentAreaClickEventHandler}/>
								<Spacer $marginTop={16}/>
							</>)}
					{body
                && (<>
								<Styles.Body dangerouslySetInnerHTML={{ __html: sanitizeLinks(body) }} $kind="bodyLong03" onClick={contentAreaClickEventHandler}/>
								<Spacer $marginTop={24}/>
							</>)}
					{finePrintHeader
                && (<>
								<Styles.FinePrintHeader dangerouslySetInnerHTML={{ __html: sanitizeLinks(finePrintHeader) }} $kind="overline02" onClick={contentAreaClickEventHandler}/>
								<Spacer $marginTop={10}/>
							</>)}
					{finePrintText
                && (<>
								<Styles.FinePrintText dangerouslySetInnerHTML={{ __html: sanitizeLinks(finePrintText) }} $kind="bodyLong00" onClick={contentAreaClickEventHandler}/>
								<Spacer $marginTop={24}/>
							</>)}
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        body,
        contentAreaClickEventHandler,
        finePrintHeader,
        finePrintText,
        id,
        inlineVideos,
        introText,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
    ]);
};
export default ContentSermo;
