import styled, { css } from "styled-components";
const defaultSpacerStyles = css `
	width: 100%;
	height: 0;
	margin-top: 16px;
	display: block;
`;
const Spacer = styled.div `
	display: none;

	${({ $height, $marginTop, $width }) => ($height
    || $marginTop
    || $width) && css `
		${defaultSpacerStyles};
		width: ${typeof $width === "number"
    ? `${$width}px`
    : $width};
		height: ${typeof $height === "number"
    ? `${$height}px`
    : $height};
		margin-top: ${typeof $marginTop === "number"
    ? `${$marginTop}px`
    : $marginTop};
	`};
`;
export default Spacer;
