import React from "react";

const TRANSLATIONS_OBJ = window.sermo.translations;
// If you change this delimiter, please make sure it is in sync with the rest of the code base.
// (update the delimiter here as well: Sermo.BusinessLogic.Localization.SpecialTranslationFolders.cs -> Delimiter)
const TRANSLATIONS_SEPARATOR = ".";

export const findByKey = (path, base = TRANSLATIONS_OBJ) => (
	path.split(TRANSLATIONS_SEPARATOR).reduce((prev, curr) => prev[curr] || "", base)
)

// Merges in arguments to the translation string, for example a string:
// "Unsupported file type. Supported types are: {0}", which will merge in the first argument to this
// translation method as item {0}, next argument as {1}, etc.
const formatStringWithArguments = (text, args) => {
	if (args) {
		if (args.length > 0) {
			for (let i = 0; i < args.length; i++) {
				try {
					text = text.replace("{" + i + "}", args[i]);
				}
				catch(e) {
					console.error(e.message);
				}
			}
		}
	}
	return text;
};

const getTranslation = (path, returnString = false, ...args) => {
	if ("undefined" === typeof sermo || !window.sermo.translations) {
		// TODO: call the api if this happens maybe?
		// async fo sho
		// set sermo.translations so we don't keep doing this
		console.log("Error loading translations...");
		return "";
	}

	// helper to find text that has not been set up for translation
	if (window && window.location.search.search("find-missing-translation=true") > -1) {
		return "-translated-";
	}

	let translation;
	let argsa = [...args];
	const keys = path.split(TRANSLATIONS_SEPARATOR);

	if (!path || !keys.length) {
		return console.error("No, or incorrect, path supplied to getTranslation!");
	}

	const translationObj = findByKey(path);

	if(translationObj) {
		if (typeof translationObj === "string" || translationObj instanceof String) {
			translation = formatStringWithArguments(translationObj, argsa);
		} else {
			if (returnString) {
				if ("html" in translationObj) {
					console.warn(`Attempting to convert html translation "${path}" to non-HTML string.`);
				}
				translation = formatStringWithArguments(translationObj.text, argsa);
			} else {
				if ("text" in translationObj) {
					translation = <>{formatStringWithArguments(translationObj.text, argsa)}</>;
				}

				if ("html" in translationObj) {
					translation = (
						<div
							dangerouslySetInnerHTML={
								{
									__html: formatStringWithArguments(translationObj.html, argsa),
								}
							}
						/>
					);
				}
			}
		}
	} else {
		translation = returnString
			? path
			: <span style={{ color: "red" }}>{path}</span>;
	}

	return translation;
};

export const getCurrencyTranslation = (amount, threeLetterCurrency, returnString = false, decimals = 2) => {
	// helper to find text that has not been set up for translation
	if (window && window.location.search.search("find-missing-translation=true") > -1) {
		return "-translated-";
	}

	let symbol = getTranslation("system.currencies." + threeLetterCurrency, true);
	let number = Number(amount);
	let str = "";
	if (number < 0) {
		number *= -1;
		str = "-" + symbol + number.toFixed(decimals);
	} else {
		str = symbol + number.toFixed(decimals);
	}

	if (!returnString) {
		<>{str}</>;
	}
	return str;
};

export default getTranslation;
