import React, { useReducer } from "react";
import ParentContextUpdater from "@components/ParentContextUpdater";
import PostDataContext, { PostDataDefaultValues } from "@contexts/PostData/PostDataContext";
import PostDataReducer from "@contexts/PostData/PostDataReducer";
import { PostUIActionTypes } from "@contexts/PostUI";
const PostDataProvider = ({ children, cardData = {}, isEditing = false, }) => {
    const [state, dispatch] = useReducer(PostDataReducer, {
        ...PostDataDefaultValues,
        ...cardData,
    });
    const value = {
        ...state,
        dispatch,
    };
    const parentContextUpdaterProps = {
        actionType: isEditing
            ? PostUIActionTypes.SET_CARD_DRAFT_DATA
            : PostUIActionTypes.SET_CARD_DATA,
        childContext: PostDataContext,
        parentContext: "postUI",
        payloadKey: isEditing
            ? "cardDraftData"
            : "cardData",
    };
    return (<PostDataContext.Provider value={value}>
			<ParentContextUpdater {...parentContextUpdaterProps}/>
			{children}
		</PostDataContext.Provider>);
};
export default PostDataProvider;
