import { IconButton, Icon, VerifiedCheckSVG, CheckSVG, DotsHorizontalSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import CommentEditor from "@components/Card/components/CommentEditor/CommentEditor";
import { CommentsStylesContainer } from "@components/Card/components/Comments/Comments.styles";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import MiniProfile from "@components/MiniProfile/MiniProfile";
import { AttachmentsProvider } from "@contexts/Attachments";
import { CommentProvider } from "@contexts/Comment";
import { useTrackEvent } from "@frontend/tracking";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useGetPostTrackingCategory, useMemoizedContext, usePrevious } from "@hooks/Hooks";
import { PostTypeProps } from "@props/Post";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";
import Comments from "../Comments";
import CaughtUp from "./CaughtUp";
import CommentMenu from "./CommentMenu";
import Hidden from "./Hidden";
import Voting from "./Voting";

const CommentTopIcon = styled(Icon)`
		width: 16px;
		height: 26px;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 0 10px;
		background-color: $ui-background;

		svg path {
			fill: $support-02;
		}
`;

const Comment = (
	{
		index,
		parentHandleReplyChange,
		showCaughtUp,
	}
) => {
	const category = useGetPostTrackingCategory();
	const triggerRef = useRef();
	const trackEvent = useTrackEvent();
	const el = useRef();

	const {
		setElement,
		id: scrollToId,
	} = useMemoizedContext("scroll",[
		"id",
	]);

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adId,
		id: postContextId,
		sermoContentCardId,
		type,
		user: author,
	} = useMemoizedContext("postData", [
		"adId",
		"id",
		"sermoContentCardId",
		"type",
		"user",
	]);

	let creator = false;

	const {
		postId,
		id: commentId,
		comment,
		posted,
		user,
		votes,
		replies,
		totalReplies,
		best,
		blocked,
		display,
		attachments,
		replyToId,
		parentCommentId,
		parent,
		highlight,
		isEdited,
		repliesPageIndex,
		repliesNextComments,
	} = useMemoizedContext("comment", [
		"postId",
		"id",
		"comment",
		"posted",
		"user",
		"votes",
		"replies",
		"totalReplies",
		"best",
		"blocked",
		"display",
		"attachments",
		"replyToId",
		"parentCommentId",
		"parent",
		"highlight",
		"isEdited",
		"repliesPageIndex",
		"repliesNextComments",
	]);

	// see if we should scroll to this el
	useEffect(() => {
		if ( highlight ) {
			window.setTimeout(() => {
				setElement(el.current);
			}, 1000);
		}
	}, [
		scrollToId,
		commentId,
	]);

	if (author) {
		creator = author.id === user.id;
	}

	// if its a reply to a reply we need to get the parent comment id
	const replyCommentData = { parentCommentId: parentCommentId || commentId };
	const [replying, setReplying] = useState(false);
	const [blockedShown, setBlockedShown] = useState(false);
	const [hiddenShown, setHiddenShown] = useState(false);
	const [deletedShown, setDeletedShown] = useState(false);
	const [showHighlightedClass, setShowHighlightedClass] = useState(highlight);
	const [editing, setEditing] = useState(false);

	useEffect(() => {
		if (editing) {
			setReplying(false);
		}
	}, [editing]);

	useEffect(() => {
		if (replying) {
			setEditing(false);
		}
	}, [replying]);

	const previousReplies = usePrevious(replies);
	useEffect(()=>{
		if ( replies?.length > previousReplies?.length ) {
			setReplying(false);
		}
	}, [replies])

	const clearNew = useRef();
	useEffect(() => {
		clearNew.current = window.setTimeout(() => {
			setShowHighlightedClass(false);
		}, 4000);

		return () => {
			window.clearTimeout(clearNew.current);
		};
	}, []);

	const handleReplyChange = (e) => {
		e.stopPropagation();

		trackEvent({
			category,
			action: "click",
			label: "comment-reply",
			postId: postContextId,
			adId,
			expanded,
			sermoContentCardId,
		});

		if (parentCommentId) {
			parentHandleReplyChange(commentId, user);
		} else {
			setReplying(true);
		}
	};

	const showBlocked = () => {
		trackEvent({
			category,
			action: "click",
			label: "comment-show-blocked",
			postId: postContextId,
			adId,
			expanded,
			sermoContentCardId,
		});

		setBlockedShown(true);
	};

	const showHidden = () => {
		trackEvent({
			category,
			action: "click",
			label: "comment-show-hidden",
			postId: postContextId,
			adId,
			expanded,
			sermoContentCardId,
		});

		setHiddenShown(true);
	};

	const showDeleted = () => {
		trackEvent({
			category,
			action: "click",
			label: "comment-show-deleted",
			postId: postContextId,
			adId,
			expanded,
			sermoContentCardId,
		});

		setDeletedShown(true);
	};

	const cancel = () => {
		setReplying(false);
	};

	let blockText = getTranslation("frontend.card.comment.blocked");
	let hiddenText = getTranslation("frontend.card.comment.hidden");
	let deletedText = getTranslation("frontend.card.comment.deleted");

	if (replies && replies.length) {
		blockText = getTranslation("frontend.card.comment.blockedWithReplies", true, replies.length);
		hiddenText = getTranslation("frontend.card.comment.hiddenWithReplies", true, replies.length);
		deletedText = getTranslation("frontend.card.comment.deletedWithReplies", true, replies.length);
	}

	if (blocked && !blockedShown) {
		return <Hidden
			text={blockText}
			onClick={showBlocked}
			forwardRef={el}
		/>;
	}

	if (votes <= -5 && !hiddenShown) {
		return <Hidden
			text={hiddenText}
			onClick={showHidden}
			forwardRef={el}
		/>;
	}

	if (PostTypeTypes.QUESTION === type) {
		return (
			<>
				{
					showCaughtUp && <CaughtUp
						question={true}
						first={index === 0}
					/>
				}
				<div
					className={
						classNames("wrapper", {
							"new": showHighlightedClass,
						})
					}
					ref={el}
				>
					<div className="comments">
						<div className="row">
							<Voting
								id={commentId}
								parentCommentId={parentCommentId}
							/>
							<div className="comment-mini-profile">
								<MiniProfile
									user={user}
									postDate={posted}
									creator={creator}
									showSpecialties={false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="line" />
							{
								best && <CommentTopIcon
									src={VerifiedCheckSVG}
								/>
							}
							<div className="comment">
								<TextStyled
									$kind="bodyShort02"
									className="comment-wrapper"
									dangerouslySetInnerHTML={{ __html: comment }}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	let replyTo = commentId;

	// if we are reply to a reply we actually reply to the parent
	if (parent) {
		replyTo = replyToId;
	} else if (parentCommentId) {
		replyTo = parentCommentId;
	}

	const imageAttachments = attachments.filter((attachment) => "Image" === attachment.type);

	const onClickComment = (e) => {
		e.eventSource = "comments";
	};

	const previewLinkClickHandler = (e, title, url) => {
		trackEvent({
			category,
			action: "click",
			label: "link",
			area: "comments",
			url,
			urlTitle: title,
			postId: postContextId,
			adId,
			expanded,
			sermoContentCardId,
		});
	};

	return (
		<>
			{showCaughtUp && <CaughtUp first={index === 0} />}
			<div
				className={classNames("wrapper", { "new": showHighlightedClass })}
				ref={el}
				onClick={onClickComment}
			>
				{
					"Deleted" === display && <Hidden
						text={deletedText}
						onClick={showDeleted}
					/>
				}
				{
					"Deleted" !== display && (
						<div className="comments">
							<div className="row">
								<Voting
									id={commentId}
									parentCommentId={replyTo}
								/>
								<div className="comment-mini-profile">
									<MiniProfile
										user={user}
										postDate={posted}
										creator={creator}
									/>
								</div>
							</div>
							<div className="row">
								<div className="line" />
								{best && <CommentTopIcon src={CheckSVG}/>}
								<div className="comment">
									{
										!editing
										&& (
											<TextStyled
												$kind="bodyLong02"
												className={
													classNames(
														"comment-wrapper",
														{ "edited":isEdited }
													)
												}
												dangerouslySetInnerHTML={{ __html: comment }}
											/>
										)
									}
									{
										editing && (
											<CommentEditor
												content={comment}
												editing={true}
												commentId={commentId}
												replyToUser={user}
												onUpdate={
													() => {
														setEditing(false);
													}
												}
												existingAttachments={attachments}
												cancel={
													() => {
														setEditing(false);
													}
												}
											/>
										)
									}
									{
										!editing
										&& imageAttachments.map((attachment, i) => {
											const { thumbnail, title } = attachment;
											return (
												<a
													href={attachment.url}
													target="_blank"
													rel="noopener noreferrer"
													key={`image${i}`}
												>
													<img
														alt={title}
														className="comment-image"
														src={thumbnail}
													/>
												</a>
											);
										})
									}
									{
										imageAttachments.length === 0
										&& (
											<div className="row-no-padding">
												<LinkPreview clientEventHandler={previewLinkClickHandler} />
											</div>
										)
									}
									{
										replying
										&& (
											<CommentProvider comment={replyCommentData}>
												<CommentEditor
													replyToUser={user}
													cancel={cancel}
												/>
											</CommentProvider>
										)
									}
									<div className="comment-footer">
										{
											"Comment" !== type && (
												<TextStyled
													$kind="bodyShortMedium01"
													onClick={handleReplyChange}
													className="reply-button"
												>
													{getTranslation("frontend.card.comment.reply")}
												</TextStyled>
											)
										}
										{
											"Comment" === type && (
												<NavLink to={`/post/${postId}/expanded/comment/${commentId}`}>
													<TextStyled
														$kind="bodyShortMedium01"
														className="reply-button"
													>
														{getTranslation("frontend.card.comment.reply")}
													</TextStyled>
												</NavLink>
											)
										}
										<IconButton
											ref={triggerRef}
											icon={DotsHorizontalSVG}
											kind="flatNeutral"
											size="xsmall"
										/>
										<CommentMenu
											triggerRef={triggerRef}
											toggleEdit={
												() => {
													setEditing(!editing);
												}
											}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				}
				{
					replies
					&& replies.length > 0
					&& ("Deleted" !== display || deletedShown)
					&& (
						<div className="row">
							<div className="line" />
							<Comments
								replies={replies}
								isReply={true}
								replyToId={commentId}
								totalReplies={totalReplies}
								initialPageIndex={repliesPageIndex}
								initialNextComments={repliesNextComments}
							/>
						</div>
					)
				}
			</div>
		</>
	);
};

Comment.propTypes = {
	postId: PropTypes.number,
	id: PropTypes.number,
	comment: PropTypes.string,
	posted: PropTypes.string,
	user: PropTypes.shape(UserProps),
	votes: PropTypes.number,
	replies: PropTypes.array,
	totalReplies: PropTypes.number,
	best: PropTypes.bool,
	index: PropTypes.number,
	type: PropTypes.oneOf(PostTypeProps),
	blocked: PropTypes.bool,
	display: PropTypes.oneOf(["Deleted", "Normal"]),
	attachments: PropTypes.array,
	replyToId: PropTypes.number,
	parentCommentId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
	parent: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
	new: PropTypes.bool,
	highlight: PropTypes.bool,
	showCaughtUp: PropTypes.bool,
	isEdited: PropTypes.bool,
};

Comment.defaultProps = {
	deleted: false,
	display: "Normal",
	attachments: [],
	replyToId: null,
	parentCommentId: null,
	parent: null,
	showCaughtUp: false,
	isEdited: false,
};

const CommentWithContext = (props) => (
	<CommentProvider comment={props.comment}>
		<AttachmentsProvider parentContext="comment">
			<CommentsStylesContainer>
				<Comment {...props}/>
			</CommentsStylesContainer>
		</AttachmentsProvider>
	</CommentProvider>
);

CommentWithContext.propTypes = {
	comment: PropTypes.shape(Comment.propTypes).isRequired,
	isReply: PropTypes.bool,
};

export default CommentWithContext;
