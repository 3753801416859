import { ColorGrey00 } from "@sermo/design-tokens";
import { css } from "styled-components";
import { MembershipGlobalVariables } from "./variables";

export const MembershipGlobalStyles = css`
	* {
		box-sizing: border-box;
	}
	html {
		height: 100dvh;
		background-color: ${ColorGrey00};
	}
	body {
		margin: 0;
	}
	p {
      margin: 0;
    }
	input,
	button {
		border: none;
		background-image: none;
		background-color: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		padding: 0;
	}
	ul,
	ol {
		margin-left: 20px;
	}
	ul {
		list-style: disc;
	}
	ol {
		list-style: decimal;
	}
	sup {
		font-size: xx-small;
		font-weight: inherit;
		line-height: 0;
		position: relative;
		top: -0.5em;
		vertical-align: baseline;
	}
	sub {
		font-size: xx-small;
		font-weight: inherit;
		line-height: 0;
		position: relative;
		top: 0.4em;
		vertical-align: baseline;
	}
	em,
	i {
		font-style: italic;
	}

  ${MembershipGlobalVariables}
`;