import React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmPasswordModal from "@components/Modal/Modals/ConfirmPasswordModal/ConfirmPasswordModal";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import {
	ArrowIcon,
	OnboardingBar,
	BarWrap,
	CheckIcon,
	ModalBody,
	ModalHeader,
	ModalWrap,
	OnboardingImage,
	Progress,
	ProgressText,
	TaskDescription,
	TaskLabel,
	TaskWrap,
} from "./OnboardingModal.styles";

export const ONBOARDING_MODAL = "ONBOARDING_MODAL";

const Task = ({ task }) => {
	const closeModal = useCloseModal();
	const navigate = useNavigate();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const {
		hasAccess,
		hasBeenAuthenticatedViaToken,
	} = useMemoizedContext("member", [
		"hasAccess",
		"hasBeenAuthenticatedViaToken",
	]);

	const showConfirmPasswordModalClick = () => {
		openModal({
			[EUIKey.MODAL_COMPONENT]: (
				<ConfirmPasswordModal
					callbackEventHandler={() => navigate("/settings/accountsettings")}
				/>
			),
			[EUIKey.MODAL_LABEL]: "confirm-password",
		});
	}

	const handleTaskClick = () => {
		trackEvent({
			category: "onboarding",
			label: "task-" + task.label.toLowerCase().replace(" ", "-"),
			action: "click",
			url: task.ctaUrl,
		});

		if (
			task.ctaUrl?.includes("settings")
			&& hasAccess("canAccessFrontendAccountSettings")
			&& hasBeenAuthenticatedViaToken
		) {
			showConfirmPasswordModalClick();
		}
		else {
			task.ctaUrl && navigate(task.ctaUrl);
			closeModal(ONBOARDING_MODAL);
		}
	};

	return (
		<TaskWrap data-component={"OnboardingTask"}
			$cursor={task.ctaUrl}
			disabled={!task.ctaUrl}
			onClick={handleTaskClick}
		>
			<CheckIcon $completed={task.completed} />
			<div>
				<TaskLabel>{task.label}</TaskLabel>
				<TaskDescription>{task.description}</TaskDescription>
			</div>
			{task.ctaUrl && <ArrowIcon />}
		</TaskWrap>
	)
};

export const OnboardingModal = () => {
	const trackEvent = useTrackEvent();
	const { tasks, header, subHeader } = useMemoizedContext("onboarding", ["tasks", "header", "subHeader"]);

	React.useEffect(() => {
		trackEvent({
			category: "onboarding",
			label: "modal",
			action: "view",
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const completedTasks = tasks.filter((task) => task.completed);
	const completed = completedTasks.length;
	const total = tasks.length;

	const sortedTasks = tasks.sort((a, b) => a.order - b.order);
	const onboardingTasks = sortedTasks.map((task, index) => (
		<Task
			key={index}
			task={task}
		/>
	));

	return (
		<>
			<OnboardingImage />
			<ModalWrap data-component={"OnboardingModal"}>
				<ModalHeader>{header}</ModalHeader>
				<ModalBody dangerouslySetInnerHTML={{ __html: subHeader }} />
				<BarWrap>
					<OnboardingBar>
						<Progress $progress={completed/total}>
							<ProgressText>{completed}/{total}</ProgressText>
						</Progress>
					</OnboardingBar>
				</BarWrap>
				{onboardingTasks}
			</ModalWrap>
		</>
	);
}