import { Icon, VerifiedCheckSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./MessagesList.scss";

const VerificationCheckIconStyled = styled(Icon)`
	position: absolute;
	margin-left: 6px;
	width: 13px;
	height: 13px;
`;

export const MemberButton = (params) => {
	const {
		memberIdState,
		aIdState,
		currentAId,
		currentMemberId,
		id,
		memberId,
		aId,
		username,
		profilePic,
		messagePreview,
		messageDate,
		clickHandler,
		specialties,
		numberOfUnread,
		verified,
		memberWorksAtSermo = params.worksAtSermo || params.memberWorksAtSermo,
	} = params;

	const [hasUnreadMessages, setHasUnreadMessages] = useState(numberOfUnread && numberOfUnread > 0);
	const isDMAPreview = !!useMatch("/dma-preview/*");
	const onClick = () => {
		if (!isDMAPreview) {
			setHasUnreadMessages(false);

			if (memberId) {
				clickHandler(memberId, "member");
			} else if (aId) {
				clickHandler(aId, "ad");
			} else if (currentMemberId) {
				clickHandler(currentMemberId, "member");
			} else if (currentAId) {
				clickHandler(currentAId, "ad");
			} else {
				clickHandler(id, "member");
			}
		}
	};

	return (
		// double equal because string from route
		<div
			styleName={
				classNames(
					"styles.message",
					{ "styles.has-unread-messages": hasUnreadMessages },
					{ "styles.dma-preview": isDMAPreview },
					{
						"styles.active": aIdState
							?	aIdState === aId
							:	memberIdState
								? memberIdState === memberId
								:	aId
									? aId === currentAId
									:	currentMemberId === memberId
					}
				)
			}
			onClick={onClick}
		>
			<div styleName="styles.pic">
				<ProfilePic
					user={
						{
							id: memberId,
							profilePic: profilePic
						}
					}
				/>
			</div>
			<div styleName="styles.content">
				<div styleName="styles.top">
					<div styleName={classNames("styles.name", "typography.heading-brand-02")}>
						{username}
						{
							(
								verified
								|| memberWorksAtSermo
							) && (
								<VerificationCheckIconStyled
									src={
										memberWorksAtSermo
											? SermoMiniSVG
											: VerifiedCheckSVG
									}
								/>
							)
						}
					</div>
					{
						messageDate && (
							<div styleName={classNames("styles.date", "typography.body-short-brand-00")}>{messageDate}</div>
						)
					}
				</div>
				{
					messagePreview && (
						<div
							styleName={
								classNames(
									"styles.bottom",
									"typography.body-short-brand-00",
									"styles.message-preview"
								)
							}
						>
							{aId && <span>Sponsored • </span>}
							<span dangerouslySetInnerHTML={{ __html: messagePreview }} />
						</div>
					)
				}
				{
					specialties && specialties.length > 0 && (
						<div styleName={classNames("styles.specialty", "typography.body-short-brand-00")}>
							{
								memberWorksAtSermo
									? getTranslation("system.feeds.worksAtSermoName")
									: specialties[0].name
							}
						</div>
					)
				}
			</div>
		</div>
	);
};

MemberButton.propTypes = {
	active: PropTypes.bool,
	id: PropTypes.number,
	type: PropTypes.oneOf(["member", "ad"]),
	username: UserProps.username,
	profilePic: UserProps.profilePic,
	messagePreview: PropTypes.string,
	messageDate: PropTypes.string,
	clickHandler: PropTypes.func,
	specialties: PropTypes.oneOfType([PropTypes.bool, UserProps.specialities]),
	numberOfUnread: PropTypes.number,
	verified: UserProps.verified
};

MemberButton.defaultProps = {
	clickHandler: () => {
	},
	specialities: false
};
