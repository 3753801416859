import { Icon, DrugRatingSVG, SurveySVG, ActivitySVG, MessagesSVG, HomeSVG } from "@sermo/ui-components";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ActivityMenu from "@components/Activity/components/ActivityMenu/ActivityMenu";
import Button, { ButtonWithMenu } from "@components/Button/Button";
import { ClickStop } from "@components/Helpers/Helpers";
import {
	StyledMobileAndTabletPortraitQuery,
	TabletLandscapeAndDesktop,
	TabletLandscapeAndDesktopQuery,
} from "@components/MediaQueries/MediaQueries";
import { NavMenu, NavItem } from "@components/NavMenu";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import getTranslation from "@translation/translation";

const StyledIcon = styled(Icon).attrs({
	width: 24,
	height: 24,
})`
	align-self: center;
`;

const ClickStopWrap = styled.div`
	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;

const AdPreviewNavMenu = () => {
	const user = useMemoizedContext("member");
	const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
	const navItems = [];

	navItems.push({
		name: getTranslation("frontend.navMenu.home"),
		icon: <StyledIcon src={HomeSVG} />,
		to: `/feed/all-sermo`,
		exact: false,
	});

	navItems.push({
		name: getTranslation("frontend.navMenu.rate"),
		icon: <StyledIcon src={DrugRatingSVG} />,
		to: "/rate",
	});

	navItems.push({
		name: getTranslation("frontend.navMenu.surveys"),
		icon: <StyledIcon src={SurveySVG} />,
		to: "/surveys",
	});

	const item = {
		name: getTranslation("frontend.navMenu.activity"),
		icon: <StyledIcon	src={ActivitySVG}	/>,
		to: "/activity",
	};

	if (isDesktop) {
		item.menu = {
			component: ActivityMenu,
			name: "activity",
		};
	}

	navItems.push(item);

	navItems.push({
		name: getTranslation("frontend.navMenu.messages"),
		icon: <StyledIcon src={MessagesSVG} />,
		to: "/messages",
	});

	navItems.push({
		name: getTranslation("frontend.navMenu.me"),
		icon: <ProfilePic
			user={user}
			size="small"
			contextClass="top-nav-menu"
		/>,
		to: "/profile",
		withArrow: true,
	});

	if ( !user.hasAccessToAdPreview ) {
		return null;
	}

	return (
		<TabletLandscapeAndDesktop>
			<ClickStopWrap>
				<ClickStop>
					<NavMenu>
						{
							navItems.map((navItem, i) => {
								const { name } = navItem;

								return (
									<NavItem key={i}>
										{
											Object.prototype.hasOwnProperty.call( navItem, "menu") && (
												<ButtonWithMenu
													activeUnderline={true}
													{...navItem}
													labelPosition="bottom"
													theme="light"
													style="tab"
													size="small"
													contextClass="top-nav-menu"
												>
													{name}
												</ButtonWithMenu>
											)
										}
										{
											!Object.prototype.hasOwnProperty.call(navItem,"menu") && (
												<Button
													activeUnderline={true}
													{...navItem}
													labelPosition="bottom"
													theme="light"
													style="tab"
													size="small"
													contextClass="top-nav-menu"
												>
													{name}
												</Button>
											)
										}
									</NavItem>
								);
							})
						}
					</NavMenu>
				</ClickStop>
			</ClickStopWrap>
		</TabletLandscapeAndDesktop>
	);
};

export default AdPreviewNavMenu;
