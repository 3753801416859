import { Input } from "@sermo/ui-components";
import React from "react";
import { getFieldLabel } from "../../AdForm.utils";

const AdFormInput = ({ data, form }) => {
	const label = getFieldLabel(data);

	const handleChange = (e) => form.setFieldValue(data.adFormFieldType, e.target.value);

	return (
		<Input
			disabled={!data.isEditable}
			errors={form.errors[data.adFormFieldType]}
			label={label}
			onChange={handleChange}
			required={!data.isOptional}
			value={form.values[data.adFormFieldType]}
		/>
	);
};

AdFormInput.defaultProps = {
	data: {},
	form: {},
}

export default AdFormInput;