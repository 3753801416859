import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "@components/Button/Button";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useApiEndpoint } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "./../../../scss/typography.scss";
import styles from "./PendingAccountDeactivationModal.scss";

const PendingAccountDeactivationModal = ({ closeModalEventHandler }) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const { 
		daysUntilDeactivation, 
		updateMember, 
	} = useMemoizedContext("member", [
		"daysUntilDeactivation",
	]);
	const [globalValidationError, setGlobalValidationError] = useState("");

	const cancelDeactivationRequestClickHandler = () => {
		apiEndpoint("settings/cancelmemberdeactivation").then((dataResponse) => {
			dataResponse.json().then((body) => {
				setLoading(false);
				setGlobalValidationError("");

				if (dataResponse.ok) {
					updateMember({
						hasPendingDeactivationRequest: false,
						daysUntilDeactivation: 0, 
					});

					window.sermo.user.hasPendingDeactivationRequest = false;

					if (window.sermo.user.daysUntilDeactivation) {
						window.sermo.user.daysUntilDeactivation = null;
					}

					closeModalEventHandler(true);
				} else {
					setGlobalValidationError(body.message);
				}
			});
		});
	};

	return (
		<div>
			<div styleName={classNames(["styles.modal-alert-content", "typography.heading-brand-02"])}>
				{
					getTranslation(
						"frontend.modals.pendingAccountDeactivationModal.content",
						false,
						daysUntilDeactivation
					)
				}
			</div>
			<div styleName={classNames(["styles.modal-alert-action-button"])}>
				<Button
					name="submit"
					size="small"
					disabled={loading}
					clickHandler={cancelDeactivationRequestClickHandler}
				>
					{
						getTranslation(
							"frontend.modals.pendingAccountDeactivationModal.cancelDeactivationButtonText",
							true
						)
					}
				</Button>
				<GlobalValidationMessage text={globalValidationError} />
			</div>
		</div>
	);
};

PendingAccountDeactivationModal.propTypes = {
	closeModalEventHandler: PropTypes.func,
};

PendingAccountDeactivationModal.defaultProps = {
	closeModalEventHandler: () => {},
};

export default PendingAccountDeactivationModal;
