import { IconButton, XSVG } from "@sermo/ui-components";
import { useEffect } from "react";
import { useSetSidePanelLabel, useToggleBackdrop } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./SidePanel.styles";
const SidePanel = ({ children, label, side, }) => {
    const setSidePanelLabel = useSetSidePanelLabel();
    const { sidePanelLabel } = useMemoizedContext("ui", ["sidePanelLabel"]);
    const toggleBackdrop = useToggleBackdrop();
    const trackEvent = useTrackEvent();
    const handleXClick = () => {
        trackEvent({
            category: `${side}-side-panel`,
            label: "close",
            action: "click",
        });
        setSidePanelLabel(null);
        toggleBackdrop(false);
    };
    useEffect(() => {
        if (sidePanelLabel) {
            window.addEventListener("click", handleXClick);
        }
        return () => {
            window.removeEventListener("click", handleXClick);
        };
    }, [sidePanelLabel]);
    return (<Styles.Panel data-component={label} $open={sidePanelLabel === label} $side={side}>
			<Styles.Header $side={side}>
				<IconButton icon={XSVG} onClick={handleXClick} size={"small"}/>
			</Styles.Header>
			{children}
		</Styles.Panel>);
};
export default SidePanel;
