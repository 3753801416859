import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";

import typography from "../../../../scss/typography.scss";
import surveys from "../../Surveys.scss";

const SurveyHistoryCard = ({
	locale,
	index,
	completedAt,
	name,
	currency,
	honorariumAmount,
	programsEnrolled,
	status,
	shouldHighlightStatus,
}) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpandCollapseClickHandler = () => {
		setExpanded(!expanded);
	};

	let completedDate = new Date(Date.parse(completedAt));
	let completedDateLocaleString = completedDate.toLocaleDateString(locale, {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	});

	const enrolledProgramText = programsEnrolled?.[0]?.honorariumShortText;

	return (
		<div
			key={index}
			styleName={classNames(["surveys.mobile-card", { "surveys.expanded": expanded }])}
			onClick={toggleExpandCollapseClickHandler}
		>
			<p styleName={classNames(["surveys.mobile-card-date", "typography.body-short-brand-00"])}>
				{completedDateLocaleString}
			</p>
			<p
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"surveys.short-top-padding",
						"typography.body-short-brand-02",
					])
				}
			>
				{name}
			</p>
			<p styleName={classNames(["surveys.mobile-card-row", "typography.body-short-brand-02"])}>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.surveyHistory.tableHeaderEarned", true)}:{" "}
				</span>
				{
					!!honorariumAmount
					&& !!currency
					&& !enrolledProgramText
					&& getCurrencyTranslation(honorariumAmount, currency)
				}
				{!!enrolledProgramText && enrolledProgramText}
			</p>
			<p
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"typography.body-short-brand-02",
						"surveys.hidden-when-collapsed",
						{ "surveys.highlight-status": shouldHighlightStatus },
					])
				}
			>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.surveyHistory.tableHeaderStatus", true)}:{" "}
				</span>
				{status}
			</p>
		</div>
	);
};

SurveyHistoryCard.propTypes = {
	locale: PropTypes.string,
	index: PropTypes.number,
	completedAt: PropTypes.string,
	name: PropTypes.string,
	currency: PropTypes.string,
	honorariumAmount: PropTypes.number,
	status: PropTypes.string,
	shouldHighlightStatus: PropTypes.bool,
};

export default SurveyHistoryCard;
