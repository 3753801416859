import { Icon, Popup, SearchSVG, ChevronLeftSVG, FilterSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { Route, Navigate, Routes, useMatch, useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import MobileFilterModal from "@components/Search/MobileFilterModal";
import SearchContent from "@components/Search/SearchContent";
import { SearchResults } from "@components/Search/SearchResults";
import SearchResultsPreview, { SearchPreviewRecent } from "@components/SearchResultsPreview/SearchResultsPreview";
import { EUIKey, useCloseMenu, useOpenMenu, useOpenModal } from "@contexts/UI";
import { useMemoizedContext, useSearchParams } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import styles from "./Search.scss";
import SearchNav from "./SearchNav";

const types = {
	all: "Undefined",
	page: "Page",
	topic: "Topic",
	posts: "Post",
	people: "Member",
	rate: "DrugRating",
	survey: "Survey",
	comments: "Comment",
};

const NUM_RESULTS_TO_TRIGGER_AUTO_VISIT = 2; // 2 because of the one result and the "see-all" option

export const isRatePage = (pathname) => pathname.startsWith("/rate");

const ActualSearchInput = ({
	blurHandler = () => {},
	changeHandler,
	keyDownHandler = () => {},
	query,
	submitHandler = () => {},
	refs,
}) => {
	const apiEndpoint = useApiEndpoint();
	const closeMenu = useCloseMenu();
	const searchParams = useSearchParams();
	const [redirect, setRedirect] = useState("");
	const { pathname } = useLocation();
	const placeholder = useMemo(() => {
		if (isRatePage(pathname)) {
			return getTranslation("frontend.search.searchRatingsPlaceholder", true);
		}
		return getTranslation("frontend.search.searchPlaceholder", true);
	}, [pathname]);

	// remove redirect after it redirects
	useEffect(() => {
		if (redirect) {
			closeMenu();
			setRedirect(false);
		}
	}, [closeMenu, redirect]);

	const onKeyDown = e => {
		if ("Enter" === e.key
			&& query.trim().length > 0
		) {
			if (isRatePage(pathname)) {
				const refCount = refs?.current?.reduce((acc, ref) => {
					if (ref) {
						return acc + 1;
					}
					return acc;
				}, 0);

				if (NUM_RESULTS_TO_TRIGGER_AUTO_VISIT === refCount) {
					refs?.current?.[0]?.click();
				}
				else {
					setRedirect(`/search/rate${searchParams.stringify(query)}`);
				}
			}
			else {
				setRedirect(`/search/all${searchParams.stringify(query)}`);
			}
			apiEndpoint("search/savesearch", { query });
			submitHandler();
		}
		keyDownHandler(e);
	};

	return (
		<>
			<input
				styleName={classNames("styles.search")}
				placeholder={placeholder}
				onChange={changeHandler}
				onKeyDown={onKeyDown}
				value={query}
				onBlur={blurHandler}
			/>
			{redirect && <Navigate to={redirect} />}
		</>
	);
};

ActualSearchInput.propTypes = {
	blurHandler: PropTypes.func,
	changeHandler: PropTypes.func.isRequired,
	keyDownHandler: PropTypes.func,
	query: PropTypes.string.isRequired,
	refs: PropTypes.shape({ current: PropTypes.array }),
	submitHandler: PropTypes.func,
};

const getSearchSelectValues = (result, query, searchParams) => {
	switch (result.type) {
		case "Member":
			return {
				saveSearchData: {
					memberId: result.memberId,
					query: query,
				},
				redirect: `/profile/${result.memberId}`,
			};
		case "Topic":
			return {
				saveSearchData: {
					topicId: result.topicId,
					query: query,
				},
				redirect: `/feed/topic/${result.url}`,
			};
		case "Page":
			return {
				saveSearchData: {
					pageId: result.pageId,
					query: query,
				},
				redirect: `/${result.url}`,
			};
		case "DrugBrand":
		case "DrugRating":
			return {
				saveSearchData: {
					drugBrandId: result.drugBrandId,
					query: query,
				},
				redirect: `/rate/${result.drugBrandId}`,
			};
		case "Indication":
			return {
				saveSearchData: {
					indicationId: result.indicationId,
					query: query,
				},
				redirect: `/rate/indication/${result.indicationId}`,
			};
		case "Search":
		default:
			return {
				saveSearchData: {
					query: query,
				},
				redirect: `/search/all${searchParams.stringify(result.query)}`,
			};
	}
};

const StyledPopup = styled(Popup)`
	min-width: 0;
	min-height: 0;
	margin: 8px 0 0 0;
`;

const SearchInputDesktop = () => {
	const apiEndpoint = useApiEndpoint();
	const closeMenu = useCloseMenu();
	const openMenu = useOpenMenu();
	const searchParams = useSearchParams();
	const queryParam = searchParams.get();
	const initialQuery = queryParam || "";
	const trigger = React.useRef(null);
	const [query, setQuery] = useState(initialQuery);
	const {
		hasConfiguredProfile,
		hasAccess,
		trialMember,
		affiliateMember,
	} = useMemoizedContext("member", [
		"hasConfiguredProfile",
		"hasAccess",
		"trialMember",
		"affiliateMember",
	]);
	const menu = "searchPreview";
	const {
		menuName,
	} = useMemoizedContext("ui", [
		"menuName",
	]);

	const itemsListRefs = React.useRef([]);

	const handleKeyDown = (e) => {
		if (e.key === "Tab" || e.key === "Escape") {
			closeMenu();
		}
		else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			e.preventDefault();
			e.stopPropagation();
			itemsListRefs.current[0] && itemsListRefs.current[0].focus();
		}
	};

	const [redirect, setRedirect] = useState(false);

	// remove redirect after it redirects
	useEffect(() => {
		if (redirect !== false) {
			closeMenu();
			setRedirect(false);
		}
	}, [closeMenu, redirect]);

	if (!hasAccess("canAccessFrontendSearch")) {
		return null;
	}

	if (!hasConfiguredProfile && !trialMember && !affiliateMember) {
		return null;
	}

	const open = () => {
		if (menu !== menuName) {
			openMenu(menu);
		}
	};

	const handleChange = e => {
		setQuery(e.target.value);
		open();
	};

	const handleSubmit = () => setQuery("");

	const onClick = e => {
		e.stopPropagation();
		e.preventDefault();
		open();
	};

	const handleSearchSelect = result => {
		const values = getSearchSelectValues(result, query, searchParams);

		if ("" !== query) {
			apiEndpoint("search/savesearch", values.saveSearchData);
		}

		setRedirect(values.redirect);
	};

	return (
		<div
			styleName="styles.search-input-wrapper"
			onClick={onClick}
			ref={trigger}
		>
			<div styleName="styles.search-icon">
				<Icon
					src={SearchSVG}
					width={24}
					height={24}
				/>
			</div>
			<ActualSearchInput
				changeHandler={handleChange}
				keyDownHandler={handleKeyDown}
				query={query}
				submitHandler={handleSubmit}
				refs={itemsListRefs}
			/>
			{/* show the preview when user is in search and has entered something */}
			<StyledPopup
				open={menu === menuName}
				triggerRef={trigger}
				autoFocus={false}
			>
				{
					query !== "" && query.length >= 3 && (
						<SearchResultsPreview
							query={query}
							onSearchSelect={handleSearchSelect}
							refs={itemsListRefs}
						/>
					)
				}
				{
					(!query || query.length < 3) && (
						<SearchPreviewRecent
							onSearchSelect={handleSearchSelect}
							refs={itemsListRefs}
						/>
					)
				}
			</StyledPopup>
			{redirect && <Navigate to={redirect} />}
		</div>
	);
};

const SearchInputMobile = () => {
	const apiEndpoint = useApiEndpoint();
	const itemsListRefs = React.useRef([]);
	const openModal = useOpenModal();

	const queryMatch = useMatch("/search/:section");
	const initialShowingResults = !!queryMatch;
	const section = queryMatch?.params?.section;

	const searchParams = useSearchParams();
	const queryParams = searchParams.get();
	const initialQuery = queryParams || "";
	const [query, setQuery] = useState(initialQuery);

	// manages the results display state which can be set internally and externally
	const [showingResults, setShowingResults] = useState(initialShowingResults);
	useEffect(() => {
		setShowingResults(initialShowingResults);
	}, [initialShowingResults]);

	// remove redirect after it redirects
	const [redirect, setRedirect] = useState(false);
	useEffect(() => {
		if (redirect !== false) {
			setRedirect(false);
		}
	}, [redirect]);

	const handleSearchSelect = result => {
		const values = getSearchSelectValues(result, query, searchParams);
		apiEndpoint("search/savesearch", values.saveSearchData);
		setRedirect(values.redirect);
		setShowingResults(true);
	};

	const onClick = e => {
		e.stopPropagation();
		e.preventDefault();
		setShowingResults(false);
	};

	const handleSubmit = () => {
		setShowingResults(true);
		setQuery("");
	};

	const handleChange = e => {
		setQuery(e.target.value);
		setShowingResults(false);
	};

	const openFilter = e => {
		e.stopPropagation();

		openModal({
			[EUIKey.MODAL_COMPONENT]: <MobileFilterModal />,
			[EUIKey.MODAL_LABEL]: "search-filters",
			[EUIKey.MODAL_TEXT_LABEL]: "Filters",
		});
	};

	return useMemo(() => (
		<>
			<div styleName="styles.search-wrapper">
				<div
					styleName="styles.search-input-wrapper"
					onClick={onClick}
				>
					<Button
						style="icon"
						to="/feed"
						icon={<Icon src={ChevronLeftSVG} />}
					/>
					<ActualSearchInput
						changeHandler={handleChange}
						query={query}
						submitHandler={handleSubmit}
					/>

					<div styleName="styles.search-icon">
						<Routes>
							<Route
								path="all"
								element={null}
							/>
							<Route
								path=":section"
								element={
									(
										<Button
											style="icon"
											size="small"
											icon={<Icon src={FilterSVG} />}
											clickHandler={openFilter}
										/>
									)
								}
							/>
							<Route
								element={<Icon src={SearchSVG} />}
							/>
						</Routes>
					</div>
				</div>
			</div>
			{
				!showingResults && query !== "" && query.length >= 3 && (
					<SearchResultsPreview
						query={query}
						onSearchSelect={handleSearchSelect}
						refs={itemsListRefs}
					/>
				)
			}
			{
				!showingResults && (!query || query.length < 3) && (
					<SearchPreviewRecent
						onSearchSelect={handleSearchSelect}
						refs={itemsListRefs}
					/>
				)
			}
			{
				showingResults && (
					<>
						<SearchNav
							section={section}
							query={query}
						/>
						<div styleName="styles.results">
							<Routes>
								<Route
									path="all"
									element={
										(
											<SearchContent
												section="all"
												query={query}
											/>
										)
									}
								/>
								<Route
									path=":section"
									element={
										(
											<SearchResults
												section={types[section]}
												query={query}
											/>
										)
									}
								/>
							</Routes>
						</div>
					</>
				)
			}
			{redirect && <Navigate to={redirect} />}
		</>
	), [query, section, showingResults]);
};

const SearchInput = () => (
	<>
		<TabletLandscapeAndDesktop>
			<SearchInputDesktop />
		</TabletLandscapeAndDesktop>
		<MobileAndTabletPortrait>
			<SearchInputMobile />
		</MobileAndTabletPortrait>
	</>
);

export default SearchInput;
