import { Button, Input, QuickFind, SelectTrigger, Select } from "@sermo/ui-components"
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
	GlobalValidationMessage,
	FieldValidationMessage
} from "@components/FormFields/FormFields";
import { FormGroupWrap } from "@components/FormFields/FormFields.styles";
import { caseInsensitivePropertyGetter } from "@components/Helpers/Helpers";
import { RegisterStyles } from "@components/Register/Register.styles";
import useRegisterTrackEvent from "@components/Register/RegisterUtils";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { SESSION_TRACKING_COOKIE } from "@frontend/tracking/tracking";
import { getCookie, getUrlParameter, setCookie } from "@frontend/Utils";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const ReCAPTCHAContainer = styled.div`
	margin-bottom: 4px;
`;

const EnterNameForm = ({ data, updateData, endpoint }) => {
	const apiEndpoint = useApiEndpoint();
	const isRegister = !!useMatch("/register/*");
	const navigate = useNavigate();
	const recaptchaSiteKey = window?.sermo?.recaptchaSiteKey ?? "";
	const registerTrackEvent = useRegisterTrackEvent();

	const [emailAddressValidationError, setEmailAddressValidationError] = useState("");
	const [firstNameValidationError, setFirstNameValidationError] = useState("");
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [iAmAValidationError, setIAMAValidationError] = useState("");
	const [lastNameValidationError, setLastNameValidationError] = useState("");
	const [loading, setLoading] = useState(false);
	const [mobileNumberValidationError, setMobileNumberValidationError] = useState("");
	const [recaptchaJustEnabled, setRecaptchaJustEnabled] = useState(false);
	const [recaptchaRequired, setRecaptchaRequired] = useState(false);
	const [recaptchaResponseValue, setRecaptchaResponseValue] = useState(null);
	const [recaptchaValidationError, setRecaptchaValidationError] = useState("");
	const [selectedCountryCodeValidationError, setSelectedCountryCodeValidationError] = useState("");
	const [specialtyValidationError, setSpecialtyValidationError] = useState("");

	const {
		email: memberEmail,
		firstName: memberFirstName,
		lastName: memberLastName,
		queryParams: contextQueryParams,
	} = useMemoizedContext("member",[
		"email",
		"firstName",
		"lastName",
		"queryParams",
	]);

	const queryParams = window?.sermo?.queryParams ?? contextQueryParams ?? {};

	const onRecaptchaResponseChangeHandler = (responseValue) => {
		setRecaptchaResponseValue(responseValue);
		setRecaptchaValidationError("");
	}

	useEffect(() => {
		if (recaptchaRequired) {
			if (!recaptchaJustEnabled) {
				setRecaptchaJustEnabled(true);
				setRecaptchaValidationError(
					getTranslation("system.validationMessages.validationPleaseFillOutRecaptcha", true)
				);
			}
		}
	}, [recaptchaRequired]);

	// only check to see if the user needs to be rate limited
	useEffect(() => {
		const urlRef = getUrlParameter("refid");
		if (urlRef) {
			setCookie("refid", urlRef, 7);
		}

		apiEndpoint("register/verifylimit", {}).then(dataResponse => {
			if ( 429 === dataResponse.status ) {
				setRecaptchaRequired(true);
			}
		})

		// registration-process-person-info-page-view
		// psr-verification-module-view
		registerTrackEvent({
			label: "person-info-page",
			psrLabel: "verification-module",
			action: "view",
		});
	}, []);

	const { availableCountries } = data;

	const countryFromLabel = (availableCountries, countryFullName) => {
		if (!countryFullName) {
			return undefined;
		}

		if (!availableCountries) {
			return undefined;
		}

		const countryToSelect = availableCountries.find(
			c => c.name.toLowerCase().replace(/\s+/g, "") === countryFullName.toLowerCase().replace(/\s+/g, "")
				|| c.id.toLowerCase() === countryFullName.toLowerCase()
		);

		if (countryToSelect) {
			return countryToSelect.id;
		}

		return undefined;
	};

	const formQueryParams = {
		selectedCountryCode: "countryOfPractice",
		firstName: "firstName",
		lastName: "lastName",
		emailAddress: "email",
		mobileNumber: "mobileNumber",
		iAmA: "I_AM",
		specialty: "specialty",
	};

	const [selectedIAMA, setSelectedIAMA] = useState(() => {
		const iAmA = caseInsensitivePropertyGetter(
			queryParams, formQueryParams.iAmA
		);

		return iAmA
			? parseInt(iAmA)
			: null;
	});

	const shouldShowSpecialtyBasedOnIAMA = useMemo(() => {
		if (selectedIAMA) {
			return data?.availableMedicalSpecialtyTypes?.find(
				medicalSpecialtyType => medicalSpecialtyType.id === selectedIAMA
			)?.shouldShowSpecialty
		}

		return false;
	}, [
		data,
		selectedIAMA,
	]);

	const [selectedSpecialty, setSelectedSpecialty] = useState(() => {
		const specialty = caseInsensitivePropertyGetter(
			queryParams, formQueryParams.specialty
		)?.to

		return specialty
			? parseInt(specialty)
			: null;
	});

	const [selectedCountryCode, setSelectedCountryCode] = useState(
		countryFromLabel(
			availableCountries,
			caseInsensitivePropertyGetter(queryParams, formQueryParams.selectedCountryCode)
		)
		|| data.selectedCountryCode
	);

	const [firstName, setFirstName] = useState(
		caseInsensitivePropertyGetter(queryParams, formQueryParams.firstName)
		|| window?.sermo?.queryParams
			? caseInsensitivePropertyGetter(window?.sermo?.queryParams, formQueryParams.firstName)
			: data.firstName || memberFirstName
		|| ""
	);

	const [lastName, setLastName] = useState(
		caseInsensitivePropertyGetter(queryParams, formQueryParams.lastName)
		|| window?.sermo?.queryParams
			? caseInsensitivePropertyGetter(window?.sermo?.queryParams, formQueryParams.lastName)
			: data.lastName || memberLastName
		|| ""
	);

	const defaultEmailAddress
		= caseInsensitivePropertyGetter(queryParams, formQueryParams.emailAddress)?.replace(/.*@example\.com$/i, "")
		|| data.emailAddress
		|| memberEmail
		|| "";

	const [emailAddress, setEmailAddress] = useState(defaultEmailAddress);

	const [mobileNumber, setMobileNumber] = useState(
		caseInsensitivePropertyGetter(queryParams, formQueryParams.mobileNumber)
		|| data.mobileNumber
		|| ""
	);

	const selectedCountryCodeChangeHandler = event => {
		setSelectedCountryCode(event.target.value);
		setSelectedCountryCodeValidationError("");
	};

	const firstNameChangeHandler = event => {
		setFirstName(event.target.value);
		setFirstNameValidationError("");
	};

	const lastNameChangeHandler = event => {
		setLastName(event.target.value);
		setLastNameValidationError("");
	};

	const emailAddressChangeHandler = event => {
		setEmailAddress(event.target.value);
		setEmailAddressValidationError("");
	};

	const mobileNumberChangeHandler = event => {
		setMobileNumber(event.target.value);
		setMobileNumberValidationError("");
	};

	const iAmAChangeHandler = event => {
		setSelectedIAMA(event.target.value);
		setIAMAValidationError("");
	};

	// Reset specialty when IAMA changes
	useEffect(() => {
		if (!shouldShowSpecialtyBasedOnIAMA) {
			setSelectedSpecialty(null);
		}
	}, [shouldShowSpecialtyBasedOnIAMA]);

	const specialtyChangeHandler = (event) => {
		setSelectedSpecialty(event.target.value);
		setSpecialtyValidationError("");
	}

	const validateForm = () => {
		let valid = true;

		if (
			selectedCountryCode !== null
			&& selectedCountryCode.length === 2
		) {
			setSelectedCountryCodeValidationError("");
		} else {
			setSelectedCountryCodeValidationError(
				getTranslation("system.validationMessages.validationCountryIsRequired", true)
			);
			valid = false;
		}

		if (
			firstName !== null
			&& firstName.length >= 2
		) {
			setFirstNameValidationError("");
		} else {
			setFirstNameValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourFirstName", true)
			);
			valid = false;
		}

		if (
			lastName !== null
			&& lastName.length >= 2
		) {
			setLastNameValidationError("");
		} else {
			setLastNameValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourLastName", true)
			);
			valid = false;
		}

		if (
			selectedIAMA
			|| !isRegister
		) {
			setIAMAValidationError("");
		} else {
			setIAMAValidationError(
				getTranslation("system.validationMessages.validationPleaseSelectYourIAMA", true)
			);
			valid = false;
		}

		if (
			selectedIAMA
			&& shouldShowSpecialtyBasedOnIAMA
			&& null === selectedSpecialty
		) {
			setSpecialtyValidationError(
				getTranslation("system.validationMessages.validationPleaseSelectYourSpecialty", true)
			);
			valid = false;
		} else {
			setSpecialtyValidationError("");
		}

		if (
			emailAddress !== null
			&& emailAddress.length >= 7
		) {
			setEmailAddressValidationError("");
		} else {
			setEmailAddressValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourEmailAddress", true)
			);
			valid = false;
		}

		if (
			mobileNumber !== null
			&& mobileNumber.length > 0
		) {
			let mobileNumberValidationMessage = "";
			const mobileNumberWithoutWhitespace = mobileNumber.replace(/\s+/g, "");

			if (
				selectedCountryCode !== null
				&& selectedCountryCode.length === 2
			) {
				const selectedCountry = availableCountries.find(
					c => c.name.toLowerCase().replace(/\s+/g, "") === selectedCountryCode.toLowerCase().replace(/\s+/g, "")
						|| c.id.toLowerCase() === selectedCountryCode.toLowerCase()
				);

				if (selectedCountry !== null) {
					let countryCodeLength = 0;

					if (mobileNumberWithoutWhitespace[0] === "+") {
						countryCodeLength = (selectedCountry.telephoneCode + "").length + 1;
					}

					if (mobileNumberWithoutWhitespace.length
						< (selectedCountry.minimumTelephoneLength + countryCodeLength)
						|| mobileNumberWithoutWhitespace.length
						> (selectedCountry.maximumTelephoneLength + countryCodeLength)
					) {
						mobileNumberValidationMessage = getTranslation(
							"system.validationMessages.validationInvalidPhoneNumber", true
						);
						valid = false;
					}
				}
			}

			setMobileNumberValidationError(mobileNumberValidationMessage);
		}
		else {
			setMobileNumberValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourMobileNumber", true)
			);
			valid = false;
		}

		return valid;
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);

			let requestData = {
				firstName: firstName,
				lastName: lastName,
				emailAddress: emailAddress,
				mobileNumber: mobileNumber,
				countryCode: selectedCountryCode,
				medicalSpecialtyTypeId: selectedIAMA,
				// overkill but ensure we dont send a specialty if phsyician is selected
				specialtyId: selectedIAMA && selectedIAMA === 4
					? selectedSpecialty
					: null,
				channel: caseInsensitivePropertyGetter(
					queryParams, "utm_channel"
				),
				campaign: caseInsensitivePropertyGetter(
					queryParams, "utm_campaign"
				),
				medium: caseInsensitivePropertyGetter(
					queryParams, "utm_medium"
				),
				source: caseInsensitivePropertyGetter(
					queryParams, "utm_source"
				),
				content: caseInsensitivePropertyGetter(
					queryParams, "utm_content"
				),
				term: caseInsensitivePropertyGetter(
					queryParams, "utm_term"
				),
				// Get user_session_id from cookie
				pinpointUserSessionId: JSON.parse(getCookie(SESSION_TRACKING_COOKIE))?.user_session_id,
				realTimeproadlinkid: caseInsensitivePropertyGetter(
					queryParams, "qli"
				),
				realtimeprosessionid: caseInsensitivePropertyGetter(
					queryParams, "sid"
				),
			}

			// registration-process-person-info-create-account-click
			// psr-verification-module-submit-click
			registerTrackEvent({
				label: "person-info-create-account",
				psrLabel: "verification-module-submit",
				action: "click",
			});

			if (isRegister) {
				let timeZoneId = null;
				try
				{
					timeZoneId = new Intl.DateTimeFormat().resolvedOptions().timeZone;
				}
				catch (ex) {
					console.error(ex);
				}

				requestData = {
					...requestData,
					MemberReferralCode: getCookie("refid"),
					timeZoneOffset: -new Date().getTimezoneOffset(),
					timeZoneId: timeZoneId,
					recaptchaResponse: recaptchaResponseValue,
				};
			}

			setLoading(false);

			apiEndpoint(`${endpoint}/entername`, requestData).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (429 === dataResponse.status) {
						setRecaptchaRequired(true);
					}
					else if (dataResponse.ok) {
						setCookie("refid", null);
						updateData({
							selectedCountryCode: selectedCountryCode,
							firstName: firstName,
							lastName: lastName,
							emailAddress: emailAddress,
							mobileNumber: mobileNumber,
							...body,
						});
					}
					else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}

						if (body.fields !== null) {
							for (let property in body.fields) {
								switch (property) {
									case "firstName":
										setFirstNameValidationError(body.fields[property].join("<br />"));
										break;
									case "lastName":
										setLastNameValidationError(body.fields[property].join("<br />"));
										break;
									case "emailAddress":
										setEmailAddressValidationError(body.fields[property].join("<br />"));
										break;
									case "mobileNumber":
										setMobileNumberValidationError(body.fields[property].join("<br />"));
										break;
									case "countryCode":
										setSelectedCountryCodeValidationError(body.fields[property].join("<br />"));
										break;
									case "recaptchaResponse":
										setRecaptchaValidationError(body.fields[property].join("<br />"));
										setRecaptchaRequired(true);
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const checkCanSubmit = useCallback(() => firstName?.length > 0
		&& lastName?.length > 0
		&& emailAddress?.length >= 7
		&& mobileNumber?.length > 1
		&& (!isRegister || !!selectedIAMA)
		&& (!shouldShowSpecialtyBasedOnIAMA || !!selectedSpecialty)
	, [emailAddress?.length, firstName?.length, lastName?.length, mobileNumber?.length, selectedIAMA, selectedSpecialty, shouldShowSpecialtyBasedOnIAMA])

	const [canSubmit, setCanSubmit] = useState(checkCanSubmit);

	useEffect(() => {
		setCanSubmit(checkCanSubmit());
	},[firstName, lastName, mobileNumber, emailAddress, selectedCountryCode, checkCanSubmit]);

	useEffect(() => {
		if ( canSubmit) {
			formSubmitHandler(new CustomEvent("SubmitFromQuery"));
		}
	}, []);

	useEffect(() => {
		if (typeof URLSearchParams !== "undefined") {
			const params = new URLSearchParams(queryParams);
			const originalParams = params.toString();

			if (params.toString() !== originalParams) {
				navigate({
					pathanme: window.location.pathname,
					search: "?" + params.toString(),
				});
			}
		}
	}, []);

	const convertAPIResponseToQuickFindOptions = (data) => data?.map((item) => {
		return {
			value: item.id,
			label: item.name,
		};
	})

	return (
		<RegisterStyles data-component="EnterNameForm">
			<form
				onSubmit={formSubmitHandler}
				className={classNames(["form"])}
			>
				<div className={classNames(["form-field-row"])}>
					<div
						className={classNames(["half-width desktop-only first"])}
					>
						<Input
							name="firstName"
							label={getTranslation("frontend.registration.startPage.inputFirstNameLabel", true)}
							autoComplete="given-name"
							errors={
								firstNameValidationError
									? [firstNameValidationError]
									: null
							}
							onChange={firstNameChangeHandler}
							value={firstName}
							size="small"
						/>
					</div>
					<div
						className={classNames(["half-width desktop-only last"])}
					>
						<Input
							name="lastName"
							label={getTranslation("frontend.registration.startPage.inputLastNameLabel", true)}
							autoComplete="family-name"
							errors={
								lastNameValidationError
									? [lastNameValidationError]
									: null
							}
							onChange={lastNameChangeHandler}
							value={lastName}
							size="small"
						/>
					</div>
				</div>
				{
					isRegister
					&& (
						<div className={classNames(["form-field-row"])}>
							<Select
								value={selectedIAMA}
								onChange={iAmAChangeHandler}
								label={getTranslation("frontend.registration.startPage.inputIAmALabel", true)}
								size="small"
								options={convertAPIResponseToQuickFindOptions(data.availableMedicalSpecialtyTypes)}
								errors={
									iAmAValidationError
										? [iAmAValidationError]
										: null
								}
							/>
						</div>
					)
				}
				{
					isRegister
					&& shouldShowSpecialtyBasedOnIAMA
					&& (
						<div className={classNames(["form-field-row"])}>
							<QuickFind
								options={convertAPIResponseToQuickFindOptions(data.availableSpecialties)}
								onSelect={specialtyChangeHandler}
								value={selectedSpecialty}
							>
								<SelectTrigger
									options={convertAPIResponseToQuickFindOptions(data.availableSpecialties)}
									label={getTranslation("frontend.registration.startPage.MedicalSpecialtyProfessionLabel", true)}
									value={selectedSpecialty}
									size="small"
									error={
										specialtyValidationError
											? [specialtyValidationError]
											: null
									}
								/>
							</QuickFind>
						</div>
					)
				}
				<div className={classNames(["form-field-row"])}>
					<div className={classNames(["half-width desktop-only first"])}>
						<Input
							name="emailAddress"
							type="email"
							label={getTranslation("frontend.registration.startPage.inputEmailAddressLabel", true)}
							autoComplete="email"
							errors={
								emailAddressValidationError
									? [emailAddressValidationError]
									: null
							}
							onChange={emailAddressChangeHandler}
							value={emailAddress}
							size="small"
						/>
					</div>
					<div className={classNames(["half-width desktop-only last"])}>
						<Input
							name="mobileNumber"
							type="tel"
							label={getTranslation("frontend.registration.startPage.inputMobileNumberLabel", true)}
							autoComplete="tel"
							errors={
								mobileNumberValidationError
									? [mobileNumberValidationError]
									: null
							}
							onChange={mobileNumberChangeHandler}
							value={mobileNumber}
							size="small"
						/>
					</div>
				</div>

				<div className={classNames(["form-field-row"])}>
					<Select
						name="country"
						autoComplete="country"
						label={getTranslation("frontend.registration.startPage.inputCountryLabel", true)}
						value={selectedCountryCode}
						onChange={selectedCountryCodeChangeHandler}
						placeholder="Select"
						options={convertAPIResponseToQuickFindOptions(availableCountries)}
						errors={
							selectedCountryCodeValidationError
								? [selectedCountryCodeValidationError]
								: null
						}
					/>
				</div>

				{
					recaptchaRequired
					&& <FormGroupWrap className={classNames(["form-field-row"])}>
						<ReCAPTCHAContainer>
							<ReCAPTCHA
								sitekey={recaptchaSiteKey}
								onChange={onRecaptchaResponseChangeHandler}
							/>
						</ReCAPTCHAContainer>
						<FieldValidationMessage
							text={recaptchaValidationError}
							className="is-invalid"
						/>
					</FormGroupWrap>
				}

				<GlobalValidationMessage text={globalValidationError} />
				<div className={classNames(["confirmation-text-spacer"])}>
					<TextStyled
						className={classNames(["confirmation-text"])}
						$kind={"bodyShort00"}
					>
						{getTranslation("frontend.registration.startPage.agree")}
					</TextStyled>
				</div>

				<div className={classNames(["enter-name-submit-container"])}>
					<Button
						name="submit"
						size="medium"
						type="submit"
						disabled={!canSubmit}
						processing={loading}
						onClick={formSubmitHandler}
					>
						{
							isRegister
							&& getTranslation("frontend.registration.startPage.createAccount", true)
						}
						{
							!isRegister
							&& getTranslation("frontend.generics.continue", true)
						}
					</Button>
				</div>
			</form>
		</RegisterStyles>
	);
}

EnterNameForm.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

export default EnterNameForm;
