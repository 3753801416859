import React from "react";
import styled from "styled-components";
import { StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { useMemoizedContext } from "@hooks/Hooks";
import ResourcePreview from "./ResourcePreview";
const LayoutContainer = styled.div `
	${StyledTabletLandscapeAndDesktopQuery} {
		& > * {
			min-width: ${({ theme }) => theme.layout.siteMaxWidth};
		}
	}
`;
const ResourcePreviewLayout = () => {
    const { loggedIn, } = useMemoizedContext("member", [
        "loggedIn",
    ]);
    if (!loggedIn) {
        return (<LayoutContainer>
				<ResourcePreview />
			</LayoutContainer>);
    }
    return <ResourcePreview />;
};
export default ResourcePreviewLayout;
