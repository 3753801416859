import { TextStyled } from "@sermo/ui-components/unstable";
import React from "react";
import { Avatar, Specialty } from "./MentionMember.styles";
export const MentionMemberOption = (option) => (<>
		<Avatar src={option.profilePic || "/assets/images/user.svg"} title={option.username}/>
		<TextStyled $kind="bodyShort02">
			<strong>{option.username}</strong>
		</TextStyled>
		{option?.specialties && option?.specialties?.length > 0 && <Specialty>{option.specialties[0].name}</Specialty>}
	</>);
