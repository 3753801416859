import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import { getUrlParameter } from "../../Utils";
import AcceptTermsAndConditions from "../AcceptTermsAndConditions/AcceptTermsAndConditions";
import "./AcceptTermsAndConditionsLayout.scss";

const AcceptTermsAndConditionsLayout = () => {
	const { hasAcceptedTermsAndConditions, affiliateMember } = useMemoizedContext("member",
		["hasAcceptedTermsAndConditions", "affiliateMember"]);

	const returnUrl = getUrlParameter("returnUrl");

	// If the user is affiliate member will accept terms and conditions using the Affiliate Member Modal
	if (affiliateMember || hasAcceptedTermsAndConditions) {
		if (returnUrl) {
			if ("undefined" !== typeof window) {
				window.location = returnUrl;
				return;
			}
		}

		return <Navigate to="/feed/all-sermo" />;
	}

	if ("undefined" !== typeof window) {
		return (
			<div styleName="accept-terms-and-conditions-layout" data-component="AcceptTermsAndConditionsLayout">
				<Routes>
					<Route
						exact
						path="/accepttermsandconditions"
						element={<AcceptTermsAndConditions />}
					/>
					<Route
						path="*"
						element={
							<Navigate
								to={
									{
										...window.location,
										pathname: "/accepttermsandconditions",
										search: "returnUrl=" + escape(window.location.href),
									}
								}
							/>
						}
					/>
				</Routes>
			</div>
		);
	}

	return (
		<div>
			<div styleName="accept-terms-and-conditions-layout" data-component="AcceptTermsAndConditionsLayout">
				<Routes>
					<Route
						exact
						path="/accepttermsandconditions"
						element={AcceptTermsAndConditions}
					/>
					<Route
						path="*"
						element={<Navigate to="accepttermsandconditions" />}
					/>
				</Routes>
			</div>
		</div>
	);
};

export default AcceptTermsAndConditionsLayout;
