import { Icon, PreferredStatusSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@components/Button/Button";
import ConfirmPasswordModal from "@components/Modal/Modals/ConfirmPasswordModal/ConfirmPasswordModal";
import WithdrawalModal from "@components/Modal/Modals/WithdrawalModal";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { EUIKey, useCloseModal, useOpenModal, useUpdateModalAttributes } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { usePreconditionModal } from "@frontend/Utils";
import { useApiEndpoint } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import styles from "./MemberBalance.scss";

const modalLabel = "withdrawal-modal";

const MemberBalance = ({ trackingCategory }) => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const navigate = useNavigate();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const updateModalAttributes = useUpdateModalAttributes();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
	const [withdrawalModalTextLabel, setWithdrawalModalTextLabel] = useState("");
	const { modalIsOpen, modalLabel: openModalLabel } = useMemoizedContext("ui", ["modalIsOpen", "modalLabel"]);

	const params = useParams();
	const memberId = parseInt(params.memberId) || null;

	const {
		affiliateMember,
		id,
		hasAccess,
		brandAdministrator,
		hasBeenAuthenticatedViaToken,
		hasAcceptedTermsAndConditions,
	} = useMemoizedContext("member", [
		"affiliateMember",
		"id",
		"hasAccess",
		"brandAdministrator",
		"hasBeenAuthenticatedViaToken",
		"hasAcceptedTermsAndConditions",
	]);

	/* Initialize component. */
	useEffect(() => {
		setLoading(true);
		apiEndpoint("settings/getaccountbalance").then((dataResponse) => {
			dataResponse.json().then((body) => {
				setLoading(false);

				if (dataResponse.ok) {
					setData(body);
				}
			});
		});
	}, []);

	const toggleWithdrawalModalClickHandler = (e) => {
		setShowWithdrawalModal(!showWithdrawalModal);

		if ("backToFeed" === e) {
			trackEvent({
				category: trackingCategory,
				label: "withdrawal-confirmed",
				action: "back-to-feed",
			});
			navigate("/feed?utm_medium=button_feed&utm_source=sermo&utm_campaign=withdrawal");
		}
		else {
			apiEndpoint("settings/getaccountbalance").then((dataResponse) => {
				dataResponse.json().then((body) => {
					if (dataResponse.ok) {
						setData(body);
					}
				});
			});
		}

		return false;
	};

	const { openPreconditionModal } = usePreconditionModal(() => {
		trackEvent({
			category: trackingCategory,
			action: "click",
			label: "withdrawal",
		});
		/**
		 * for MSA-3326 -> if we show the pre-conditional modal, don't go further with withdrawalModal
		 *  because it's an affiliate member unless user accepted terms && conditions
		 */
		if (!affiliateMember && hasAcceptedTermsAndConditions) {
			toggleWithdrawalModalClickHandler();
		}
	});

	const modalTitleUpdateHandler = (title) => {
		setWithdrawalModalTextLabel(title);
	};

	useEffect(() => {
		if (showWithdrawalModal) {
			openModal({
				[EUIKey.MODAL_CLOSE_METHOD]: toggleWithdrawalModalClickHandler,
				[EUIKey.MODAL_COMPONENT]: (
					<WithdrawalModal
						closeModalEventHandler={toggleWithdrawalModalClickHandler}
						withdrawalModalTextLabelChangeHandler={modalTitleUpdateHandler}
					/>
				),
				[EUIKey.MODAL_LABEL]: modalLabel,
				[EUIKey.MODAL_TEXT_LABEL]: withdrawalModalTextLabel,
			});
		}

		if (!showWithdrawalModal) {
			closeModal(modalLabel);
		}
	}, [showWithdrawalModal]);

	useEffect(() => {
			if ( modalIsOpen && modalLabel === openModalLabel ) {
				updateModalAttributes({
					[EUIKey.MODAL_LABEL]: modalLabel,
					[EUIKey.MODAL_TEXT_LABEL]: withdrawalModalTextLabel,
				});
			}
	}, [withdrawalModalTextLabel]);

	// don't show to brand admins
	if (brandAdministrator) {
		return null;
	}

	// Only show this component on the member's own profile page.

	if (memberId && id !== memberId) {
		return null;
	}

	if (!data || loading) {
		return null;
	}

	if (!data.currency) {
		return null;
	}

	const callbackOnSuccess = e => {
		trackEvent({
			category: trackingCategory,
			action: "click",
			label: "withdrawal",
		});

		toggleWithdrawalModalClickHandler();
	}

	const showConfirmPasswordModalClick = e => {
		openModal({
			[EUIKey.MODAL_COMPONENT]: (
				<ConfirmPasswordModal
					callbackEventHandler={callbackOnSuccess}
				/>
			),
			[EUIKey.MODAL_LABEL]: "confirm-password",
		});

		e.preventDefault();
		e.stopPropagation();
	}

	return (
		<ModuleWrapper
			label={getTranslation("frontend.railCards.memberBalance.title", true)}
			border={true}
			solid={true}
			styleNames={["styles.member-balance"]}
			contextClass="right-rail"
		>
			<div styleName={classNames(["styles.balance-container"])}>
				<div styleName={classNames(["styles.balance", "typography.heading-brand-04"])}>
					{getCurrencyTranslation(data.currentBalance, data.currency)}
				</div>
				{
					(hasAccess("canWithdrawMoneyFromOwnAccount") && !hasBeenAuthenticatedViaToken) && (
						<div styleName={classNames(["styles.withbraw-button-container"])}>
							<Button
								clickHandler={toggleWithdrawalModalClickHandler}
								disabled={false}
								size="small"
								style="secondary"
								tracking={
									{
										category: trackingCategory,
										action: "click",
										label: "withdrawal",
									}
								}
								rightsRequired={["canWithdrawMoneyFromOwnAccount"]}
							>
								{getTranslation("frontend.railCards.memberBalance.withdrawButtonText", true)}
							</Button>
						</div>
					)
				}
				{
					(hasAccess("canWithdrawMoneyFromOwnAccount") && hasBeenAuthenticatedViaToken) && (
						<div styleName={classNames(["styles.withbraw-button-container"])}>
							<Button
								style="secondary"
								size="small"
								clickHandler={
									affiliateMember
										? openPreconditionModal
										: showConfirmPasswordModalClick
								}
							>
								{getTranslation("frontend.railCards.memberBalance.withdrawButtonText", true)}
							</Button>
						</div>
					)
				}
			</div>

			<div styleName={classNames(["styles.preferred-status-container"])}>
				{
					data.hasPreferredStatus && (
						<>
							<div styleName={classNames(["styles.preferred-status-icon-container"])}>
								<div styleName={classNames(["styles.preferred-status-icon"])}>
									<Icon src={PreferredStatusSVG} />
								</div>
								<div
									styleName={classNames(["styles.preferred-status-text", "typography.heading-brand-01"])}
								>
									{getTranslation("frontend.railCards.memberBalance.preferredStatus")}
								</div>
							</div>
							<div
								styleName={classNames(["styles.preferred-status-description", "typography.body-short-brand-00"])}
							>
								{getTranslation("frontend.railCards.memberBalance.youNowReceivePremium")}
							</div>
						</>
					)
				}

				{
					!data.hasPreferredStatus && (
						<>
							<div styleName={classNames(["styles.preferred-status-missing-amount"])}>
								<div
									styleName={
										classNames([
											"styles.preferred-status-missing-amount-text",
											"typography.body-short-brand-00",
										])
									}
								>
									{
										getTranslation(
											"frontend.railCards.memberBalance.youAreXAmountAwayFromPreferredStatus",
											false,
											getCurrencyTranslation(data.amountFromPreferredStatus, data.currency, true)
										)
									}
								</div>
							</div>
							<ProgressBar progressPercent={100 - data.percentFromPreferredStatus} />
						</>
					)
				}
			</div>
		</ModuleWrapper>
	);
};

MemberBalance.propTypes = {
	trackingCategory: PropTypes.string,
};

MemberBalance.defaultProps = {
	trackingCategory: "right-rail",
};

export default MemberBalance;
