import { ColorGrey100 } from "@sermo/design-tokens";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
	padding-top: 40px;
`;

const Amount = styled.div`
	color: ${ColorGrey100};
	font-size: 12px;
	font-family: Sailec;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;
`;

const BalanceAvailable = ({ amount }) => <Wrap><Amount>{amount}</Amount></Wrap>;

BalanceAvailable.propTypes = {
	amount: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default BalanceAvailable;
