import React from "react";
import EditorPortion from "@components/Card/components/PostContentEditor/EditorPortion";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import AttachmentManager from "@components/PostEditor/components/AttachmentManager";
import { PatientCaseOption } from "@components/PostEditor/components/Options";
import PollEdit from "@components/PostEditor/components/PollEdit";
import PostEditorFooter from "@components/PostEditor/components/PostEditorFooter";
import Title from "@components/PostEditor/components/Title";
import TopicSelection from "@components/PostEditor/components/TopicSelection";
import * as SharedStyles from "@components/PostEditor/PostEditor.styles";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardModal from "@components/PostViewer/components/CardModal";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const PatientCaseEditor = () => {
    const { draftStatus, } = useMemoizedContext("postUI", [
        "draftStatus",
    ]);
    const composing = DraftStatusTypes.COMPOSING === draftStatus;
    const { postEditorIsOpen } = useMemoizedContext("ui", ["postEditorIsOpen"]);
    if (composing
        && !postEditorIsOpen) {
        return (<SharedStyles.NotFocusedLabel>
				{getTranslation(`frontend.card.unfocusedLabelPatientCase`)}
			</SharedStyles.NotFocusedLabel>);
    }
    return (<>
			{!composing && <CardModal isEditing={true}/>}
			<CardContainer>
				<SharedStyles.TopLevelContainer data-component="PatientCaseEditor">
					<CardHeader />
					<SharedStyles.MainContent>
						<div className="mobile-flex-container">
							<SharedStyles.MarginBottom>
								<PatientCaseOption />
							</SharedStyles.MarginBottom>
							<SharedStyles.MarginBottom>
								<Title />
							</SharedStyles.MarginBottom>
							<EditorPortion emoji max={20000} placeholder={getTranslation("frontend.newPost.patientCaseBodyPlaceholder", true)} toolbar/>
						</div>
						<PollEdit />
						<AttachmentManager type="Image"/>
						<AttachmentManager type="PdfDocument"/>
						<LinkPreview />
						<TopicSelection />
					</SharedStyles.MainContent>
					<PostEditorFooter />
				</SharedStyles.TopLevelContainer>
			</CardContainer>
		</>);
};
export default PatientCaseEditor;
