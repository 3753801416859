export var EUIKey;
(function (EUIKey) {
    EUIKey["ALERT_CAN_CLOSE"] = "alertCanClose";
    EUIKey["ALERT_CLOSE_METHOD"] = "alertCloseMethod";
    EUIKey["ALERT_COMPONENT"] = "alertComponent";
    EUIKey["ALERT_LABEL"] = "alertLabel";
    EUIKey["ALERT_IS_OPEN"] = "alertIsOpen";
    EUIKey["ALERT_PERSIST"] = "alertPersist";
    EUIKey["ALERT_SUBTYPE"] = "alertSubtype";
    EUIKey["ALERT_TEXT_LABEL"] = "alertTextLabel";
    EUIKey["BACKDROP_IS_OPEN"] = "backdropIsOpen";
    EUIKey["BANNER_CAN_CLOSE"] = "bannerCanClose";
    EUIKey["BANNER_CLOSE_METHOD"] = "bannerCloseMethod";
    EUIKey["BANNER_COMPONENT"] = "bannerComponent";
    EUIKey["BANNER_IS_OPEN"] = "bannerIsOpen";
    EUIKey["BANNER_LABEL"] = "bannerLabel";
    EUIKey["BANNER_SUBTYPE"] = "bannerSubtype";
    EUIKey["BANNER_TEXT_LABEL"] = "bannerTextLabel";
    EUIKey["EXPANDED_POST"] = "expandedPost";
    EUIKey["FOOTER_HEIGHT"] = "footerHeight";
    EUIKey["HEADER_HEIGHT"] = "headerHeight";
    EUIKey["HEADER_HEIGHT_CACHE"] = "headerHeightCache";
    EUIKey["MENU_NAME"] = "menuName";
    EUIKey["MODAL_BACKGROUND_DARKENED"] = "modalBackgroundDarkened";
    EUIKey["MODAL_CAN_CLOSE"] = "modalCanClose";
    EUIKey["MODAL_CLOSE_METHOD"] = "modalCloseMethod";
    EUIKey["MODAL_COMPONENT"] = "modalComponent";
    EUIKey["MODAL_LABEL"] = "modalLabel";
    EUIKey["MODAL_IS_OPEN"] = "modalIsOpen";
    EUIKey["MODAL_OVER_THE_TOP"] = "modalOverTheTop";
    EUIKey["MODAL_SUBTYPE"] = "modalSubtype";
    EUIKey["MODAL_TEXT_LABEL"] = "modalTextLabel";
    EUIKey["POST_EDITOR_IS_OPEN"] = "postEditorIsOpen";
    EUIKey["POST_EDITOR_SELECTED_TYPE"] = "postEditorSelectedType";
    EUIKey["POST_TITLE"] = "postTitle";
    EUIKey["POST_TOPIC"] = "postTopic";
    EUIKey["RELOAD_TOUR"] = "reloadTour";
    EUIKey["SIDE_PANEL_LABEL"] = "sidePanelLabel";
    EUIKey["TOUR_IS_OPEN"] = "tourIsOpen";
    EUIKey["TYPE_SELECTOR_IS_OPEN"] = "typeSelectorIsOpen";
})(EUIKey || (EUIKey = {}));
