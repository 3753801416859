import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const TabsContainer = styled.div`
	display: flex;
	background-color: $ui-background;

	${StyledMobileAndTabletPortraitQuery} {
		flex-wrap: wrap;
	}

	${({ $isFullPage }) => $isFullPage && `
		background-color: transparent;
	`};
`;

export const DotContainer = styled.span`
	position: relative;
`;

export const Dot = styled.div`
	display: block;
	position: absolute;
	top: 0;
	right: -5px;
	background-color: ${({ theme }) => theme.color.buttonRoundBackground};
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid ${({ theme }) => theme.color.buttonRoundActive};
`;