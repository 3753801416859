import { ColorGrey60 } from "@sermo/design-tokens";
import { Icon, IconButton, CalendarSVG, LocationSVG, OverflowHorizontalSVG, VerifiedCheckSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect, useRef, useMemo } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import Button from "@components/Button/Button";
import { Divider } from "@components/Helpers/Helpers";
import {
	MobileAndTabletPortrait,
	mobileTabletBreak,
	TabletLandscapeAndDesktop,
} from "@components/MediaQueries/MediaQueries";
import MiniProfileMenu from "@components/Menus/MiniProfileMenu";
import * as Styles from "@components/MiniProfile/MiniProfile.styles";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useCloseMenu } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";

const MicroProfileIconStyled = styled(Icon)`
	display: inline-block;
	margin-right: 12px;
`;

const ProfileMenuIconStyled = styled(Icon)`
	margin-left: 4px;
	display: inline-block;
	position: relative;
	top: -1px;
	fill: ${ColorGrey60};
`;

const MicroProfile = ({ user }) => {
	const triggerMenuRef = useRef();
	const trackEvent = useTrackEvent();
	const apiEndpoint = useApiEndpoint();
	const { following: isFollowing, id, anonymous } = user;

	const {
		id: selfId,
		brandAdministrator: currentAuthenticatedMemberBrandAdministrator,
		followedMembers,
		memberFollow,
		memberUnfollow,
	} = useMemoizedContext("member", [
		"id",
		"brandAdministrator",
		"followedMembers",
	]);
	const self = id === selfId;

	// State: Initial following
	const [following, setFollowing] = useState(false);

	// Effect: Update follow state based on global context
	useEffect(() => {
		setFollowing(followedMembers[id]);
	}, [followedMembers[id]]);

	// Effect: Use isFollowing to add user to followers list if not self and if not already added
	useEffect(() => {
		if (!self && id && !Object.keys(followedMembers).includes(id.toString()) && isFollowing) {
			memberFollow(id);
		}
	}, [isFollowing]);

	const handleFollow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const action = following
			? "unfollow"
			: "follow";

		trackEvent({
			category: "mini-profile",
			action: "click",
			label: action,
		});

		apiEndpoint(`members/${action}member`, {
			memberId: id,
		});

		// Update global member follow state
		following
			? memberUnfollow(id)
			: memberFollow(id);
	};

	const { joinDate, location, brandAdministrator } = user;

	const followText = following
		? getTranslation("frontend.generics.unfollow")
		: getTranslation("frontend.generics.follow");

	return (
		<Styles.MainContainer>
			<Styles.MicroProfile>
				<ModuleWrapper
					contextClass="micro-profile"
					solid={true}
					border={true}
				>
					<Styles.MicroItem $kind="bodyShortLegacy00">
						<MicroProfileIconStyled src={CalendarSVG}/>
						{joinDate}
					</Styles.MicroItem>
					<Styles.MicroItem $kind="bodyShortLegacy00">
						<MicroProfileIconStyled src={LocationSVG}/>
						{location}
					</Styles.MicroItem>
					{
						!self && (
							<Styles.Buttons>
								{
									!currentAuthenticatedMemberBrandAdministrator && (
										<Button
											size="small"
											fluid={true}
											clickHandler={handleFollow}
											rightsRequired={["canFollowItemsInFrontend"]}
										>
											{followText}
										</Button>
									)
								}
								{
									!anonymous && !currentAuthenticatedMemberBrandAdministrator && !brandAdministrator && (
										<Button
											size="small"
											style="secondary"
											to={"/messages/" + id}
											rightsRequired={["canAccessFrontendPrivateMessages"]}
										>
											{getTranslation("frontend.generics.message")}
										</Button>
									)
								}
								{anonymous && <div />}
								{
									currentAuthenticatedMemberBrandAdministrator && (
										<>
											<div />
											<div />
										</>
									)
								}
								<IconButton
									icon={OverflowHorizontalSVG}
									size="small"
									kind="flatNeutral"
									ref={triggerMenuRef}
								/>
								<MiniProfileMenu
									user={user}
									// memberBlock={memberBlock}
									triggerRef={triggerMenuRef}
								/>
							</Styles.Buttons>
						)
					}
				</ModuleWrapper>
			</Styles.MicroProfile>
		</Styles.MainContainer>
	);
};

MicroProfile.propTypes = {
	user: PropTypes.shape(UserProps),
};

const hoverDelay = 1000;

const MiniProfile = ({ user, postDate, contextClass, sponsored, creator, showSpecialties, isEdited }) => {
	const closeMenu = useCloseMenu();
	const timeoutRef = useRef();
	const wrapperRef = useRef();
	const [height, setHeight] = useState("auto");
	const [hovered, setHovered] = useState(false);
	const [nameMaxLength, setNameMaxLength] = useState(0);
	const { expanded } = useMemoizedContext("postUIViewing",["expanded"]);
	const { resourceCenterItemId } = useMemoizedContext("postData",["resourceCenterItemId"]);

	const {
		brandAdministrator: currentAuthenticatedMemberBrandAdministrator,
		id: currentAuthenticatedMemberId,
		workAtSermo: currentAuthenticatedMemberWorksAtSermo,
	} = useMemoizedContext("member",[
		"brandAdministrator",
		"worksAtSermo",
	]);

	const isCurrentUser = useMemo(() => currentAuthenticatedMemberId === user.id, [
		currentAuthenticatedMemberId,
		user.id,
	]);

	const {
		name = "",
		username,
		id,
		specialties,
		verified,
		anonymous = false,
		partner = false,
		brandAdministrator = false,
		worksAtSermo = isCurrentUser
			? currentAuthenticatedMemberWorksAtSermo
			: false,
	} = user;

	const [nameUsed, setNameUsed] = useState(username
		? username
		: name);

	useEffect(() => {
		setNameUsed(username
			? username
			: name);
	}, [username, name]);

	const handleEnter = () => {
		if (sponsored || anonymous || !showSpecialties) {
			return;
		}

		clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(() => {
			if (id && wrapperRef.current) {
				setHeight(wrapperRef.current.getBoundingClientRect().height);
				setHovered(true);
			}
		}, hoverDelay);
	};

	const handleLeave = () => {
		setHeight("auto");
		clearTimeout(timeoutRef.current);
		closeMenu();
		setHovered(false);
	};

	const getClasses = () => [
		"mini-profile",
		{ "hovered": hovered },
		{ "anonymous": anonymous },
		{ "sponsored": sponsored },
		{ [`context-${contextClass}`]: contextClass },
	];

	const clickHandler = (e) => {
		e.stopPropagation();
	};

	const calculateNameMaxLength = () => {
		if (mobileTabletBreak > window?.innerWidth) {
			const width = window?.innerWidth || mobileTabletBreak;
			let maxLength = 110 - ((mobileTabletBreak - width) / 50 * 8.9);

			if (isEdited) {
				maxLength -= 6;
			}

			if (verified) {
				maxLength -= 2;
			}

			if (
				creator
				|| partner
			) {
				maxLength -= 10;
			}

			if (maxLength < 1) {
				maxLength = 1;
			}

			setNameMaxLength(maxLength);
		} else if (expanded) {
			setNameMaxLength(60);
		} else {
			setNameMaxLength(0);
		}
	}

	useEffect(() => {
		calculateNameMaxLength();
		window.addEventListener("resize", calculateNameMaxLength);

		return () => {
			window.removeEventListener("resize", calculateNameMaxLength);
		}
	}, []);

	const truncateName = (name) => {
		if (
			name.length > nameMaxLength
			&& nameMaxLength > 0
			&& !hovered
		) {
			return `${name.slice(0, nameMaxLength)}...`;
		}

		return name;
	};

	const getUserNameComponent = () => {
		const truncatedName = truncateName(nameUsed);

		if (
			anonymous
			|| sponsored
			|| currentAuthenticatedMemberBrandAdministrator
		) {
			return (
				<Styles.Name
					dangerouslySetInnerHTML={
						{
							__html: sponsored
								? nameUsed
								: truncatedName,
						}
					}
				/>
			);
		}

		return (
			<Styles.Name title={truncatedName}>
				{
					id
						? <NavLink to={`/profile/${id}/overview`}>{truncatedName}</NavLink>
						: truncatedName
				}
			</Styles.Name>
		);
	};

	const getShowProfilePic = () => {
		let show = true;
		if ( resourceCenterItemId && Object.keys(user).length=== 0 ) {
			show = false;
		}
		return show;
	}
	const showProfilePic = getShowProfilePic();

	const formatPostDateForMobile = () => {
		const isDate = !isNaN(Date.parse(postDate));

		if (
			isDate
			&& "number" === typeof window?.innerWidth
			&& mobileTabletBreak > window?.innerWidth
		) {
			const [month, day, year] = postDate.split(" ");

			return `${month.slice(0,3)} ${day} ${year}`;
		}

		return postDate;
	};

	return useMemo(() => {
		if (!user) {
			return null;
		}
		return (
			<Styles.MainContainer>
				<MobileAndTabletPortrait>
					<ModuleWrapper contextClass="mini-profile">
						<Styles.FixedPosition>
							<Styles.MiniProfile className={classNames(getClasses())}>
								{
									showProfilePic
									&& <Styles.ProfilePicContainer>
										<ProfilePic
											user={user}
											contextClass="card-header"
											sponsored={sponsored}
											size={
												hovered
													? "large"
													: "medium"
											}
										/>
									</Styles.ProfilePicContainer>
								}
								<Styles.Details>
									<Styles.NameAndPosted>
										<Styles.NameContainer
											$kind={
												hovered
													? "heading02"
													: "headingLegacy01"
											}
										>
											{getUserNameComponent()}
											{
												(verified || worksAtSermo) && (
													<ProfileMenuIconStyled
														src={
															worksAtSermo
																? SermoMiniSVG
																: VerifiedCheckSVG
														}
														hovered={
															hovered
																? 1
																: 0
														}
														width={
															hovered
																? 16
																: 12
														}
													/>
												)
											}
											{creator && <Styles.Creator>creator</Styles.Creator>}
											{partner && <Styles.Partner>partner</Styles.Partner>}
										</Styles.NameContainer>
										{
											postDate && (
												<>
													{
														showProfilePic
														&& <Divider/>
													}
													<Styles.Posted $kind="bodyShortLegacy00">
														{formatPostDateForMobile()}
														{isEdited && <Styles.Edited $kind="bodyShortLegacy01"/>}
													</Styles.Posted>
												</>
											)
										}
									</Styles.NameAndPosted>
									<div>
										{
											showSpecialties
											&& !sponsored
											&& specialties
											&& specialties.length > 0
											&& !brandAdministrator && (
												<Styles.Specialty>
													{
														worksAtSermo
															? getTranslation("system.feeds.worksAtSermoName")
															: specialties[0].name
													}
												</Styles.Specialty>
											)
										}
										{
											showSpecialties && sponsored && !brandAdministrator && (
												<Styles.Specialty>
													Sponsored
												</Styles.Specialty>
											)
										}
										{
											showSpecialties && !sponsored && brandAdministrator && (
												<Styles.Specialty>
													{getTranslation("frontend.generics.publicPage", true)}
												</Styles.Specialty>
											)
										}
									</div>
								</Styles.Details>
							</Styles.MiniProfile>
						</Styles.FixedPosition>
					</ModuleWrapper>
				</MobileAndTabletPortrait>
				<TabletLandscapeAndDesktop>
					<ModuleWrapper
						contextClass="mini-profile"
						mouseEnterHandler={handleEnter}
						mouseLeaveHandler={handleLeave}
						clickHandler={clickHandler}
					>
						<Styles.FixedPosition
							className={
								classNames(
									hovered
										? "hovered"
										: " "
								)
							}
							ref={wrapperRef}
							style={{ height: height }}
						>
							<Styles.MiniProfile className={classNames(getClasses())}>
								{
									showProfilePic
									&& <Styles.ProfilePicContainer>
										<ProfilePic
											user={user}
											contextClass="card-header"
											sponsored={sponsored}
											size={
												hovered
													? "large"
													: "medium"
											}
										/>
									</Styles.ProfilePicContainer>
								}
								<Styles.Details>
									<Styles.NameAndPosted>
										<Styles.NameContainer
											$kind={
												hovered
													? "heading02"
													: "headingLegacy01"
											}
										>
											{getUserNameComponent()}
										</Styles.NameContainer>
										{
											(verified || worksAtSermo) && (
												<ProfileMenuIconStyled
													src={
														worksAtSermo
															? SermoMiniSVG
															: VerifiedCheckSVG
													}
													hovered={
														hovered
															? 1
															: 0
													}
													width={
														hovered
															? 16
															: 12
													}
												/>
											)
										}
										{creator && <Styles.Creator>creator</Styles.Creator>}
										{partner && <Styles.Partner>partner</Styles.Partner>}
										{
											!hovered && postDate && (
												<>
													{
														showProfilePic
														&& <Divider/>
													}
													<Styles.Posted $kind="bodyShortLegacy00">
														{postDate}
														{isEdited && <Styles.Edited $kind="bodyShortLegacy01"/>}
													</Styles.Posted>
												</>
											)
										}
									</Styles.NameAndPosted>
									<div>
										{
											!anonymous
											&& showSpecialties
											&& !sponsored
											&& specialties
											&& specialties.length > 0
											&& !brandAdministrator && (
												<Styles.Specialty>
													{
														worksAtSermo
															? getTranslation("system.feeds.worksAtSermoName")
															: specialties[0].name
													}
												</Styles.Specialty>
											)
										}
										{
											showSpecialties && sponsored && !brandAdministrator && (
												<Styles.Specialty>
													Sponsored
												</Styles.Specialty>
											)
										}
										{
											showSpecialties && !sponsored && brandAdministrator && (
												<Styles.Specialty>
													{getTranslation("frontend.generics.publicPage", true)}
												</Styles.Specialty>
											)
										}
									</div>
								</Styles.Details>
								{
									hovered
									&& !anonymous
									&& <MicroProfile user={user} />
								}
							</Styles.MiniProfile>
						</Styles.FixedPosition>
					</ModuleWrapper>
				</TabletLandscapeAndDesktop>
			</Styles.MainContainer>
		);
	}, [
		anonymous,
		contextClass,
		hovered,
		nameMaxLength,
		nameUsed,
		sponsored,
	]);
};

MiniProfile.propTypes = {
	user: PropTypes.shape(UserProps),
	postDate: PropTypes.string,
	contextClass: PropTypes.string,
	sponsored: PropTypes.bool,
	creator: PropTypes.bool,
	showSpecialties: PropTypes.bool,
	isEdited: PropTypes.bool.isRequired,
};

MiniProfile.defaultProps = {
	user: {},
	creator: false,
	showSpecialties: true,
	isEdited: false,
};

export default MiniProfile;
