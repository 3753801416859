import {
	doFocusVisible,
	DrugRatingSVG,
	HashTagSVG,
	Icon,
	ProcessingSpinner,
	SearchSVG,
	StarOfLifeSVG,
	useArrowNavigation,
} from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import Button from "@components/Button/Button";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { isRatePage } from "@components/Search/SearchInput";
import { useCloseMenu } from "@contexts/UI";
import { useApiEndpoint, useFetch, useSearchParams } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./SearchResultsPreview.scss";

const ResultIcon = ({ result }) => {
	const { type } = result;
	
	// all possible result types
	// not all types have icons
	const iconMap = {
		// AdEvent,
		// Comment,
		DrugRating: DrugRatingSVG,
		Indication: DrugRatingSVG,
		// Member,
		// Page,
		// Post,
		Search: SearchSVG,
		// Segment
		Specialty: StarOfLifeSVG,
		// System,
		// Test,
		Topic: HashTagSVG,
		// User,
	};

	if (["Member", "User"].includes(type)) {
		return <ProfilePic
			user={result}
			size="small"
		/>;
	}

	if (iconMap[type]) {
		return <Icon src={iconMap[type]} />;
	}

	return null;
};

ResultIcon.propTypes = {
	result: PropTypes.object,
};

const Result = ({ result }) => {
	const label = result.name
		? result.name
		: result.label;

	return (
		<div styleName="styles.result">
			<div styleName="styles.result-left">
				<ResultIcon result={result} />
				<div styleName={classNames("styles.result-content", "typography.heading-brand-02")}>{label}</div>
				{
					result.worksAtSermo && (
						<div styleName={classNames("styles.specialty", "typography.body-short-brand-02")}>
							<Icon src={SermoMiniSVG} />
							{getTranslation("system.feeds.worksAtSermoName")}
						</div>
					)
				}
				{
					!result.worksAtSermo && result.specialties && !result.brandAdministrator && (
						<div styleName={classNames("styles.specialty", "typography.body-short-brand-02")}>
							{result.specialties[0].name}
						</div>
					)
				}
				{
					!result.worksAtSermo && result.brandAdministrator && (
						<div styleName={classNames("styles.specialty", "typography.body-short-brand-02")}>
							{getTranslation("frontend.generics.publicPage", true)}
						</div>
					)
				}
				{
					!result.worksAtSermo && result.genericName && (
						<div styleName={classNames("styles.specialty", "typography.body-short-brand-02")}>
							{`(${result.genericName})`}
						</div>
					)
				}
			</div>
			{/*
			TODO: When adding this in, remember to add tracking to the clicks:
				Follow:   tracking={{
					category: "search-nav",
					action: "click", label:
					"follow-member",
					value: memberId
				}}
			{"Member" === result.type && <div styleName={classNames()}>{followingText}</div>}
			*/}
		</div>
	);
};

Result.propTypes = {
	result: PropTypes.object,
};

const SpinnerWrap = styled.div`
	width: 540px;
	height: 128px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const Loading = () => (
	<SpinnerWrap>
		<ProcessingSpinner
			height={64}
			width={64}
		/>
	</SpinnerWrap>
);

const SearchPreviewRecent = ({ onSearchSelect, refs }) => {
	const [data, loading] = useFetch("api/search/recentsearches");
	const { pathname } = useLocation();

	// Filter only drugs if rate page.
	const items = useMemo(() => {
		if (isRatePage(pathname) && data?.items) {
			return data.items.filter(item => ["DrugBrand", "DrugRating", "Indication"].includes(item.type));
		}
		return data?.items || [];
	}, [pathname, data]);

	if (loading) {
		return <Loading />;
	}

	return <SearchPreview
		data={{ items }}
		recent={true}
		trackingLabel="recent"
		onSearchSelect={onSearchSelect}
		refs={refs}
	/>;
};

SearchPreviewRecent.propTypes = {
	onSearchSelect: PropTypes.func,
};

SearchPreviewRecent.defaultProps = {
	onSearchSelect: () => {},
};

export { SearchPreviewRecent };

const StyledUL = styled.ul`
	margin: 0;
	list-style: none;

	& > li {
	 	${doFocusVisible()}
	}
`;

const SearchPreview = ({ data, recent, onSearchSelect, query, trackingLabel, refs }) => {
	const apiEndpoint = useApiEndpoint();
	const closeMenu = useCloseMenu();
	const searchParams = useSearchParams();
	const [localResults, setLocalResults] = useState(data?.items);
	const itemNavigation = useArrowNavigation(refs.current);
	const { pathname } = useLocation();
	const seeAllResultsUrl = useMemo(() => {
		if (isRatePage(pathname)) {
			return `/search/rate${searchParams.stringify(query)}`;
		}
		return `/search/all${searchParams.stringify(query)}`;
	}, [pathname, query]); // eslint-disable-line react-hooks/exhaustive-deps

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			if (e.target.id === "see-all-list-item") {
				e.target.firstElementChild.click();
			}
			else {
				e.target.click();
				closeMenu();
			}
		}
		else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			itemNavigation(e);
		}
	};

	const clear = () => {
		apiEndpoint("search/clearsearches");
		setLocalResults([]);
	};

	return (
		<div styleName="styles.search-preview">
			{
				recent && (
					<div
						styleName="styles.header"
						key="header"
					>
						<div styleName={classNames("styles.recent", "typography.heading-brand-02")}>
							{getTranslation("frontend.search.recent")}
						</div>
						<Button
							style="flat"
							size="small"
							clickHandler={clear}
							key="clear"
							tracking={
								{
									category: "search-nav",
									action: "click",
									label: "clear-recent",
								}
							}
						>
							{getTranslation("frontend.generics.clear")}
						</Button>
					</div>
				)
			}

			{
				localResults && (
					<StyledUL onKeyDown={onKeyDown}>
						{
							localResults.map((result, i) => {
								result.trackingLabel = trackingLabel
									? trackingLabel
									: "preview";

								return (
									<li
										key={`${result.type}-${i}`}
										onClick={() => onSearchSelect(result)}
										ref={el => refs.current[i] = el}
										tabIndex={0}
									>
										<Result result={result} />
									</li>
								);
							})
						}
						{
							query.length > 0 && (
								<li
									id="see-all-list-item"
									ref={el => refs.current[localResults.length] = el}
									tabIndex={0}
								>
									<NavLink
										to={seeAllResultsUrl}
										styleName={classNames("styles.see-all", "typography.body-long-brand-02")}
										onClick={closeMenu}
									>
										{getTranslation("frontend.search.seeAllResults", false, query)}
									</NavLink>
								</li>
							)
						}
					</StyledUL>
				)
			}
		</div>
	);
};

SearchPreview.propTypes = {
	data: PropTypes.object,
	onSearchSelect: PropTypes.func,
	query: PropTypes.string,
	recent: PropTypes.bool,
	trackingLabel: PropTypes.string,
};

SearchPreview.defaultProps = {
	onSearchSelect: () => {},
	query: "",
	recent: false,
};

export { SearchPreview };

const SearchResultsPreview = ({ query, onSearchSelect, refs }) => {
	const [data, loading] = useFetch("api/search/predict", { query });
	const { pathname } = useLocation();

	// Filter only drugs if rate page.
	const items = useMemo(() => {
		if (isRatePage(pathname) && data?.items) {
			return data.items.filter(item => ["DrugBrand", "DrugRating", "Indication"].includes(item.type));
		}
		return data?.items || [];
	}, [pathname, data]);

	if (loading) {
		return <Loading />;
	}

	// types  Undefined, Page, Topic, Post, Member, DrugRating, Survey, Comment, System, Test
	if (items?.length > 0) {
		return <SearchPreview
			data={{ items }}
			onSearchSelect={onSearchSelect}
			query={query}
			refs={refs}
		/>;
	}

	return null;
};

SearchResultsPreview.propTypes = {
	query: PropTypes.string,
	onSearchSelect: PropTypes.func,
};

SearchResultsPreview.defaultProps = {
	onSearchSelect: () => {},
	query: "",
};

export default SearchResultsPreview;
