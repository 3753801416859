import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React, { useState, useEffect, useMemo } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { TextField, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import RegistrationStep from "@components/Register/components/Registration/RegistrationStep";
import { DivLink, RegisterStyles, ReverificationWrap } from "@components/Register/Register.styles";
import useRegisterTrackEvent from "@components/Register/RegisterUtils";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const Form = ({ data, updateData, endpoint }) => {
	const registerTrackEvent = useRegisterTrackEvent();
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const [NPIOrAMA, setNPIOrAMA] = useState("npi");

	const [nationalProviderIdentificationNumber, setNationalProviderIdentificationNumber] = useState(data.nationalProviderIdentificationNumber ?? "");
	const [npiNumber, setNpiNumber] = useState(data.providedNPINumber ?? "");
	const [amaNumber, setAmaNumber] = useState(data.providedAMANumber ?? "");

	const [globalValidationError, setGlobalValidationError] = useState("");

	const [nationalProviderIdentificationNumberValidationError, setNationalProviderIdentificationNumberValidationError] = useState("");
	const [npiNumberValidationError, setNpiNumberValidationError] = useState("");
	const [amaNumberValidationError, setAmaNumberValidationError] = useState("");

	const [canSubmit, setCanSubmit] = useState(data.selectedCountryCode !== "US");

	useEffect(() => {
		if (data.selectedCountryCode === "US") {
			if ("npi" === NPIOrAMA) {
				setCanSubmit(npiNumber?.length === 10);
			} else {
				setCanSubmit(amaNumber?.length === 11);
			}
		}
		else {
			setCanSubmit(true);
		}
	}, [npiNumber, amaNumber, nationalProviderIdentificationNumber])

	const nationalProviderIdentificationNumberChangeHandler = event => {
		setNationalProviderIdentificationNumber(event.target.value);
		setNationalProviderIdentificationNumberValidationError("");
	};

	const npiNumberChangeHandler = event => {
		setNpiNumber(event.target.value);
		setNpiNumberValidationError("");
	};

	const amaNumberChangeHandler = event => {
		setAmaNumber(event.target.value);
		setAmaNumberValidationError("");
	};

	const validateForm = () => {
		let valid = true;

		if (data.selectedCountryCode === "US") {
			let hasNpiNumber = false;
			let hasAmaNumber = false;

			if (npiNumber) {
				if (npiNumber.length > 0) {
					hasNpiNumber = true;
					if (npiNumber.length !== 10) {
						valid = false;
						setNpiNumberValidationError(getTranslation("system.validationMessages.validationInvalidNPINumber", true));
					}
					else {
						if (!(/^\d+$/.test(npiNumber.length))) {
							valid = false;
							setNpiNumberValidationError(getTranslation("system.validationMessages.validationNpiNumberMustConsistOnlyOfDigits", true));
						}
						else {
							setNpiNumberValidationError("");
						}
					}
				}
				else {
					setNpiNumberValidationError("");
				}
			}
			else {
				setNpiNumberValidationError("");
			}

			if (amaNumber) {
				if (amaNumber.length > 0) {
					hasAmaNumber = true;
					if (amaNumber.length !== 11) {
						valid = false;
						setAmaNumberValidationError(getTranslation("system.validationMessages.validationInvalidAMMAMENumber", true));
					}
					else {
						if (!(/^\d+$/.test(amaNumber.length))) {
							valid = false;
							setAmaNumberValidationError(getTranslation("system.validationMessages.validationAmaMeNumberMustConsistOnlyOfDigits", true));
						}
						else {
							setAmaNumberValidationError("");
						}
					}
				}
				else {
					setAmaNumberValidationError("");
				}
			}
			else {
				setAmaNumberValidationError("");
			}

			if (!(hasNpiNumber || hasAmaNumber)) {
				valid = false;

				if (NPIOrAMA === "npi") {
					setNpiNumberValidationError(getTranslation("system.validationMessages.validationInvalidNPINumber", true));
				}
				else {
					setAmaNumberValidationError(getTranslation("system.validationMessages.validationInvalidAMMAMENumber", true));
				}
			}
		}
		else {
			setNationalProviderIdentificationNumberValidationError("");
		}

		return valid;
	};

	const getRequestData = () => {
		const requestData = {
			nationalProviderIdentificationNumber: null,
			providedNPINumber: null,
			providedAMANumber: null,
		};

		if (data.selectedCountryCode === "US") {
			if (NPIOrAMA === "ama") {
				requestData.providedAMANumber = amaNumber;
			}
			else {
				requestData.providedNPINumber = npiNumber;
			}
		}
		else {
			requestData.nationalProviderIdentificationNumber = nationalProviderIdentificationNumber;
		}

		return requestData;
	};

	const selectAMAHandler = () => {
		// registration-process-npi-page-enter-ama-click
		registerTrackEvent({
			label: "npi-page-enter-ama",
			action: "click",
			value: NPIOrAMA === "npi"
				? 31 // switches to AMA (31)
				: 30 // switches to NPI (30)
		})

		if (NPIOrAMA === "npi") {
			setNPIOrAMA( "ama" )
			setNpiNumber("");
		} else {
			setNPIOrAMA( "npi")
			setAmaNumber("");
		}
	}

	const formSubmitHandler = (e) => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);

			const requestData = {
				nationalProviderIdentificationNumber: null,
				providedNPINumber: null,
				providedAMANumber: null,
			};

			if (data.selectedCountryCode === "US") {
				if (NPIOrAMA === "ama") {
					requestData.providedAMANumber = amaNumber;
				}
				else {
					requestData.providedNPINumber = npiNumber;
				}
			}
			else {
				requestData.nationalProviderIdentificationNumber = nationalProviderIdentificationNumber;
			}

			// registration-process-npi-page-enter-click
			registerTrackEvent({
				label: "npi-page-enter",
				action: "click",
			})

			apiEndpoint(`${endpoint}/enterproviderinformation`, requestData).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (dataResponse.ok) {
						updateData({
							nationalProviderIdentificationNumber: nationalProviderIdentificationNumber,
							providedNPINumber: npiNumber,
							providedAMANumber: amaNumber,
							...body,
						});
					}
					else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}

						if (body.fields !== null) {
							for (let property in body.fields) {
								switch (property) {
									case "nationalProviderIdentificationNumber":
										setNationalProviderIdentificationNumberValidationError(body.fields[property].join("<br />"));
										break;
									case "providedNPINumber":
										setNpiNumberValidationError(body.fields[property].join("<br />"));
										break;
									case "providedAMANumber":
										setAmaNumberValidationError(body.fields[property].join("<br />"));
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const goBackClientEventHandler = (e) => {
		e.preventDefault();

		registerTrackEvent({
			label: "npi-page-enter-ama-back",
			action: "click",
		})

		if (data?.suggestedProviders?.length > 1) {
			updateData({ currentStep: RegistrationStep.SelectSuggestedProvider });
		}
		else {
			updateData({ currentStep: RegistrationStep.EnterName });
		}
	};

	const stepTitle = useMemo(() => (
		["FR", "GB", "IT"].includes(data.selectedCountryCode)
			? getTranslation("frontend.registration.enterProviderInformationPage.titleEU")
			: getTranslation("frontend.registration.enterProviderInformationPage.title")
	), [data.selectedCountryCode]);

	const stepDescription = useMemo(() => {
		if (data.selectedCountryCode === "FR") {
			return getTranslation("frontend.registration.enterProviderInformationPage.descriptionFR");
		}
		if (data.selectedCountryCode === "GB") {
			return getTranslation("frontend.registration.enterProviderInformationPage.descriptionGB");
		}
		if (data.selectedCountryCode === "IT") {
			return getTranslation("frontend.registration.enterProviderInformationPage.descriptionAlbo");
		}
		if (data.selectedCountryCode === "US") {
			if (NPIOrAMA === "npi") {
				return getTranslation("frontend.registration.enterProviderInformationPage.descriptionNpi");
			}
			else if (NPIOrAMA === "ama") {
				return getTranslation("frontend.registration.enterProviderInformationPage.descriptionAma");
			}
		}
		return getTranslation("frontend.registration.enterProviderInformationPage.description");
	}, [data.selectedCountryCode]);

	const inputLabel = useMemo(() => {
		if (data.selectedCountryCode === "FR") {
			return getTranslation("frontend.registration.enterProviderInformationPage.RppsNumber", true);
		}
		if (data.selectedCountryCode === "GB") {
			return getTranslation("frontend.registration.enterProviderInformationPage.GmcNumber", true);
		}
		if (data.selectedCountryCode === "IT") {
			return getTranslation("frontend.registration.enterProviderInformationPage.AlboNumber", true);
		}
		return getTranslation("frontend.registration.enterProviderInformationPage.inputNationalHcpIdentificationNumberLabel", true)
	}, [data.selectedCountryCode]);

	const inputPlaceholder = useMemo(() => {
		if (data.selectedCountryCode === "FR") {
			return getTranslation("frontend.registration.enterProviderInformationPage.elevenDigit", true);
		}
		if (data.selectedCountryCode === "GB") {
			return getTranslation("frontend.registration.enterProviderInformationPage.sevenDigit", true);
		}
		if (data.selectedCountryCode === "IT") {
			return getTranslation("frontend.registration.enterProviderInformationPage.AlboNumber", true);
		}
		return "";
	}, [data.selectedCountryCode]);

	const skipThisStep = (e) => {
		e.preventDefault();

		// registration-process-npi-page-enter-ama-get-manually-verified-click
		registerTrackEvent({
			label: "npi-page-enter-ama-get-manually-verified",
			action: "click",
			value: NPIOrAMA === "npi"
				? 31 // switches to AMA (31)
				: 30 // switches to NPI (30)
		})

		apiEndpoint(`${endpoint}/enterproviderinformation`, { ...getRequestData(), skipThisStep: true }).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);
				if (dataResponse.ok) {
					updateData({
						...body,
					});
				} else {
					console.error(body)
				}
			});
		});
	};

	return (
		<LoggedOutStyles>
			<RegisterStyles data-component="EnterProviderInformation">
				<TextStyled
					className={classNames(["form-title"])}
					$kind="heading04"
				>
					{stepTitle}
				</TextStyled >
				<TextStyled
					className={classNames(["form-subtitle"])}
					$kind="bodyLong02"
				>
					{stepDescription}
				</TextStyled >

				<form
					onSubmit={formSubmitHandler}
					className={classNames(["form"])}
				>
					{
						data.selectedCountryCode === "US" && (
							<>
								{
									NPIOrAMA === "npi"
								&& <div className={classNames(["form-field-row"])}>
									<TextField
										name="npiNumber"
										type="text"
										label={getTranslation("frontend.registration.enterProviderInformationPage.inputNpiNumberLabel", true)}
										placeholder={getTranslation("frontend.registration.enterProviderInformationPage.inputNpiNumberPlaceholder", true)}
										autoComplete="npi-number"
										validationError={npiNumberValidationError}
										handleChange={npiNumberChangeHandler}
										value={npiNumber}
									/>
								</div>
								}
								{
									NPIOrAMA === "ama"
								&& <div className={classNames(["form-field-row"])}>
									<TextField
										name="amaNumberValidationError"
										type="text"
										label={getTranslation("frontend.registration.enterProviderInformationPage.inputAmaMeNumberLabel", true)}
										placeholder={getTranslation("frontend.registration.enterProviderInformationPage.inputAmaMeNumberPlaceholder", true)}
										autoComplete="ama-number"
										validationError={amaNumberValidationError}
										handleChange={amaNumberChangeHandler}
										value={amaNumber}
									/>
								</div>
								}

							</>
						)
					}
					{
						data.selectedCountryCode !== "US" && (
							<>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="nationalProviderIdentificationNumber"
										type="text"
										label={inputLabel}
										placeholder={inputPlaceholder}
										autoComplete="hcp-number"
										validationError={nationalProviderIdentificationNumberValidationError}
										handleChange={nationalProviderIdentificationNumberChangeHandler}
										value={nationalProviderIdentificationNumber}
									/>
								</div>
							</>
						)
					}

					<GlobalValidationMessage text={globalValidationError} />

					<div className={classNames(["submit-container", "enter-provider-information"])}>
						<div className={classNames("submit-container-side")}>
							{
								data.selectedCountryCode !== "GB"
								&& <div className={classNames(["submit-container--back-button"])}>
									<Button
										name="back"
										style="flatNeutral"
										size="medium"
										disabled={loading}
										clickHandler={goBackClientEventHandler}
									>
										{getTranslation("frontend.generics.back", true)}
									</Button>
								</div>
							}
							<Button
								name="submit"
								size="medium"
								disabled={loading || !canSubmit}
								loading={loading}
								type="submit"
								clickHandler={formSubmitHandler}
							>
								{getTranslation("frontend.generics.next", true)}
							</Button>
						</div>
						{
							data.selectedCountryCode === "US" && (
								<TextStyled
									className={classNames("submit-container--link")}
									$kind="bodyLong00"
								>
									{NPIOrAMA === "npi" && getTranslation("frontend.registration.enterProviderInformationPage.dontHaveNpi")}
									<DivLink
										className={classNames("link")}
										onClick={selectAMAHandler}
									>
										{NPIOrAMA === "npi" && getTranslation("frontend.registration.enterProviderInformationPage.enterAma")}
										{NPIOrAMA === "ama" && getTranslation("frontend.registration.enterProviderInformationPage.enterNpi")}
									</DivLink>
								</TextStyled >
							)
						}

						<TextStyled
							className={classNames("submit-container--link")}
							$kind="bodyLong00"
						>
							<div>
								{getTranslation("frontend.registration.enterProviderInformationPage.skipStep")}
							</div>
							<DivLink
								disabled={loading}
								onClick={skipThisStep}
							>
								{getTranslation("frontend.registration.enterProviderInformationPage.verifyManually")}
							</DivLink>
						</TextStyled >
					</div>

				</form>
			</RegisterStyles>
		</LoggedOutStyles>
	)
}

const EnterProviderInformation = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");
	const registerTrackEvent = useRegisterTrackEvent();

	useEffect(() => {
		// registration-process-npi-page-view
		registerTrackEvent({
			label: "npi-page",
			action: "view",
		})
	}, []);

	return (
		<>
			{
				isRegister
				&& <LoggedOutStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<Form
								data={data}
								updateData={updateData}
								endpoint={endpoint}
							/>
						</div>
					</div>
				</LoggedOutStyles>
			}
			{
				!isRegister
				&& <ReverificationWrap>
					<Form
						data={data}
						updateData={updateData}
						endpoint={endpoint}
					/>
				</ReverificationWrap>
			}
		</>

	);
};

export default EnterProviderInformation;
