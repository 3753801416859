import { ColorGrey40 } from "@sermo/design-tokens";
import { Icon, AngleUpSVG, AngleDownSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { CommentsStylesContainer } from "@components/Card/components/Comments/Comments.styles";
import { useCommentAPI } from "@contexts/Comment";
import { useTrackEvent } from "@frontend/tracking";
import { VotedType } from "@frontend/types/Post/comment";
import { useMemoizedContext } from "@hooks/Hooks";

const Votes = styled(TextStyled)`
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: ${ColorGrey40};
`

const Voting = () => {
	const commentAPI = useCommentAPI();
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isPreviewRoute = !!useMatch("/preview/*");

	const {
		voted = false,
		votes,
	} = useMemoizedContext("comment", [
		"voted",
		"votes",
	]);

	const {
		id,
		resourceCenterItemId,
		type,
	} = useMemoizedContext("postData", [
		"id",
		"resourceCenterItemId",
		"type",
	]);

	const trackEvent = useTrackEvent()

	const track = (label) => {
		trackEvent({
			category: resourceCenterItemId
				? "resourcecenter"
				: "post-" + type.toLowerCase(),
			action: "click",
			label,
			postId: id,
		});
	}
	const voteUp = (e) => {
		e.preventDefault();
		e.stopPropagation();
		commentAPI.voteUp();
		track("vote-up");
	};

	const voteDown = (e) => {
		e.preventDefault();
		e.stopPropagation();
		commentAPI.voteDown();
		track("vote-down");
	};

	return (
		<CommentsStylesContainer className={classNames("voting", { "voting-voted": voted })}>
			<Button
				active={VotedType.UP === voted}
				icon={
					<Icon
						src={AngleUpSVG}
						width={18}
						height={18}
					/>
				}
				style="icon"
				size="medium"
				clickHandler={voteUp}
				contextClass="voting"
				rightsRequired={
					isAdPreview
						? []
						: ["canVoteOnItemsInFrontend"]
				}
			/>
			<Button
				active={VotedType.DOWN === voted}
				icon={
					<Icon
						src={AngleDownSVG}
						width={18}
						height={18}
					/>
				}
				style="icon"
				size="medium"
				clickHandler={voteDown}
				contextClass="voting"
				rightsRequired={
					isAdPreview || isPreviewRoute
						? []
						: ["canVoteOnItemsInFrontend"]
				}
			/>
			<Votes $kind="overline01">{votes}</Votes>
		</CommentsStylesContainer>
	);
};

export default Voting;
