import { TextStyled } from "@sermo/ui-components/unstable";
import React from "react";
import styled from "styled-components";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useMemoizedContext } from "@hooks/Hooks";
const Container = styled(TextStyled).attrs({
    $kind: "bodyShortLegacy02",
}) `
	margin-bottom: 24px;
	font-size: 11px;
	line-height: 16px;

	a {
		color: inherit;
		text-decoration: underline;
		word-break: break-all;

		&:hover {
			color: ${({ theme }) => theme.color.defaultLinkHover};
		}
	}
`;
const AdditionalFooterText = () => {
    const { additionalFooterText, } = useMemoizedContext("postData", [
        "additionalFooterText",
    ]);
    const { dispatch: dispatchPostUIViewing, } = useMemoizedContext("postUIViewing");
    const handleClick = (e) => {
        e.eventSource = "additional-footer-text";
        dispatchPostUIViewing({
            type: PostUIViewingActionTypes.SET_EVENT_SOURCE,
            payload: {
                eventSource: "additional-footer-text",
            },
        });
    };
    if (!additionalFooterText) {
        return null;
    }
    return (<Container dangerouslySetInnerHTML={{ __html: additionalFooterText }} onClick={handleClick}/>);
};
export default AdditionalFooterText;
