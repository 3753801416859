import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import { TextField } from "@components/FormFields/FormFields";
import { useCloseModal } from "@contexts/UI";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalTitle, ModalFooter } from "../ModalComponents";
import styles from "./ConfirmPasswordModal.scss";

const ConfirmPasswordModal = ({
	callbackEventHandler,
}) => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const [password, setPassword] = useState("");
	const [passwordValidationError, setPasswordValidationError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { updateMember } = useMemoizedContext("member", ["updateMember"]);

	const passwordChangeHandler = event => {
		setPassword(event.target.value);
	};

	const submitModalClickHandler = e => {
		setLoading(true);

		let requestData = {
			password: password,
		};

		apiEndpoint("settings/checkmemberpassword", requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					setLoading(false);

					updateMember({
						hasBeenAuthenticatedViaToken: false,
					});

					window.sermo.user.hasBeenAuthenticatedViaToken = false;

					callbackEventHandler();

					closeModal("confirm-password");
				} else {
					setLoading(false);

					if (body.message) {
						setPasswordValidationError(body.message);
					}

					if (body.fields !== null) {
						for (var property in body.fields) {
							switch (property) {
								case "password":
									setPasswordValidationError(body.fields[property].join("<br />"));
									break;
							}
						}
					}
				}
			});
		});
	};

	return (
		<ModalContainer>
			<ModalTitle>
				{getTranslation("frontend.modals.passwordconfirmation.title")}
			</ModalTitle>
			<ModalSection>
				<ModalText>
					{getTranslation("frontend.modals.passwordconfirmation.description")}
				</ModalText>
				<div styleName={classNames(["styles.password-wrapper"])}>
					<TextField
						name="password"
						type="password"
						autoComplete="password"
						label={getTranslation("frontend.modals.passwordconfirmation.confirmpassword.inputLabel", true)}
						validationError={passwordValidationError}
						handleChange={passwordChangeHandler}
						value={password}
						disabled={loading}
						autoFocus={true}
					/>
				</div>
			</ModalSection>
			<ModalFooter
				{...{
					submitText: getTranslation("frontend.generics.done", true),
					cancelModalEventHandler: () => closeModal("confirm-password"),
					submitEventHandler: () => {
						submitModalClickHandler();
					},
				}}
			/>
		</ModalContainer>
	);
}

ConfirmPasswordModal.propTypes = {
	callbackEventHandler: PropTypes.func,
};

ConfirmPasswordModal.defaultProps = {
	callbackEventHandler: () => { },
};

export default ConfirmPasswordModal;
