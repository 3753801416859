import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useMatch } from "react-router-dom";
import InFeed from "@components/AdUnits/InFeed/InFeed";
import InFeedCarousel from "@components/AdUnits/InFeed/InFeedCarousel";
import AdTracker from "@components/Card/components/AdTracker/AdTracker";
import PostEditor from "@components/PostEditor";
import CommentGroup from "@components/PostViewer/components/CommentGroup";
import DiscussionExpanded from "@components/PostViewer/components/DiscussionExpanded";
import DiscussionInFeed from "@components/PostViewer/components/DiscussionInFeed";
import PatientCaseExpanded from "@components/PostViewer/components/PatientCaseExpanded";
import PatientCaseInFeed from "@components/PostViewer/components/PatientCaseInFeed";
import QuestionOpenEnded from "@components/PostViewer/components/QuestionOpenEnded";
import QuestionPoll from "@components/PostViewer/components/QuestionPoll";
import ResourceCenterInFeed from "@components/PostViewer/components/ResourceCenterInFeed";
import ResourceItemExpanded from "@components/PostViewer/components/ResourceItemExpanded";
import ResourceItemInFeed from "@components/PostViewer/components/ResourceItemInFeed";
import SermoContentCardExpanded from "@components/PostViewer/components/SermoContentCardExpanded";
import SermoContentCardInFeed from "@components/PostViewer/components/SermoContentCardInFeed";
import { SponsoredDiscussionExpanded, SponsoredDiscussionInFeed, SponsoredPatientCaseExpanded, SponsoredPatientCaseInFeed, SponsoredQuestionOpenEnded, SponsoredQuestionPoll, } from "@components/PostViewer/components/SponsoredAds";
import { AttachmentsProvider } from "@contexts/Attachments";
import { PollProvider } from "@contexts/Poll";
import { PostDataActionTypes } from "@contexts/PostData";
import PostDataProvider from "@contexts/PostData/PostDataProvider";
import { PostUIActionTypes } from "@contexts/PostUI";
import { AdTypeTypes, CardTypeTypes, PostSubTypeTypes, PostTypeTypes, } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useApiEndpoint, useMemoizedContext, usePrevious, } from "@hooks/Hooks";
const PostViewer = () => {
    const apiEndpoint = useApiEndpoint();
    const isAdPreview = !!useMatch("/ad-preview/*");
    const isPreview = !!useMatch("/preview/*");
    const location = useLocation();
    const { adType, cardType, deleted, dispatch: dispatchPostData, hidden, id: postId, subType, type, } = useMemoizedContext("postData", [
        "adType",
        "cardType",
        "deleted",
        "hidden",
        "id",
        "subType",
        "type",
    ]);
    const { expanded, hasBeenExpanded, } = useMemoizedContext("postUIViewing", [
        "expanded",
        "hasBeenExpanded",
    ]);
    const expandedPrevious = usePrevious(expanded);
    useEffect(() => {
        if (expanded
            && expanded !== expandedPrevious
            && !!postId
            && !isAdPreview
            && !isPreview
            && !hasBeenExpanded) {
            apiEndpoint("posts/expandPost", { id: postId }, false)
                .then((response) => {
                if (response.ok) {
                    response.json().then(({ post }) => {
                        dispatchPostData({
                            type: PostDataActionTypes.SYNC_POST_DATA,
                            payload: {
                                postData: post,
                            },
                        });
                    });
                }
            });
        }
    }, [
        apiEndpoint,
        dispatchPostData,
        expanded,
        expandedPrevious,
        hasBeenExpanded,
        isAdPreview,
        isPreview,
        postId,
    ]);
    const isHiddenPath = location.pathname.includes("hidden");
    const shouldHide = hidden
        ? !isHiddenPath
        : isHiddenPath;
    if (deleted
        || shouldHide) {
        return null;
    }
    switch (cardType) {
        // @ts-expect-error
        case CardTypeTypes.AD: {
            switch (adType) {
                case AdTypeTypes.IN_FEED:
                case AdTypeTypes.IN_FEED_EVENT:
                case AdTypeTypes.LIVE_EVENT: {
                    return <InFeed />;
                }
                case AdTypeTypes.CAROUSEL: {
                    return <InFeedCarousel />;
                }
                case AdTypeTypes.SPONSORED: {
                    switch (type) {
                        case PostTypeTypes.DISCUSSION: {
                            if (expanded) {
                                return <SponsoredDiscussionExpanded />;
                            }
                            return <SponsoredDiscussionInFeed />;
                        }
                        case PostTypeTypes.PATIENT_CASE: {
                            if (expanded) {
                                return <SponsoredPatientCaseExpanded />;
                            }
                            return <SponsoredPatientCaseInFeed />;
                        }
                        case PostTypeTypes.QUESTION: {
                            switch (subType) {
                                case PostSubTypeTypes.OPEN_ENDED: {
                                    return <SponsoredQuestionOpenEnded />;
                                }
                                case PostSubTypeTypes.POLL: {
                                    return <SponsoredQuestionPoll />;
                                }
                            }
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line no-fallthrough
        case CardTypeTypes.COMMENT_GROUP: {
            return <CommentGroup />;
        }
        // @ts-expect-error
        case CardTypeTypes.POST: {
            switch (type) {
                case PostTypeTypes.DISCUSSION: {
                    if (expanded) {
                        return <DiscussionExpanded />;
                    }
                    return <DiscussionInFeed />;
                }
                case PostTypeTypes.PATIENT_CASE: {
                    if (expanded) {
                        return <PatientCaseExpanded />;
                    }
                    return <PatientCaseInFeed />;
                }
                case PostTypeTypes.QUESTION: {
                    switch (subType) {
                        case PostSubTypeTypes.OPEN_ENDED: {
                            return <QuestionOpenEnded />;
                        }
                        case PostSubTypeTypes.POLL: {
                            return <QuestionPoll />;
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line no-fallthrough
        case CardTypeTypes.RESOURCE_CENTER: {
            return <ResourceCenterInFeed />;
        }
        case CardTypeTypes.RESOURCE_CENTER_ITEM: {
            if (expanded) {
                return <ResourceItemExpanded />;
            }
            return <ResourceItemInFeed />;
        }
        case CardTypeTypes.SERMO_CONTENT_CARD: {
            if (expanded) {
                return <SermoContentCardExpanded />;
            }
            return <SermoContentCardInFeed />;
        }
    }
    return null;
};
const CardWithCustomStyle = ({ children, el, }) => {
    const { customStyle = {}, footnoteAdStyle = {}, } = useMemoizedContext("postData", [
        "customStyle",
        "footnoteAdStyle",
    ]);
    const customAndFootnoteStyles = {
        ...customStyle,
        ...footnoteAdStyle,
    };
    const useStyles = createUseStyles({ custom: customAndFootnoteStyles });
    const customClasses = useStyles();
    return (<div className={customClasses.custom} ref={el}>
			{children}
		</div>);
};
const PostViewerOrEditor = () => {
    const cardElement = useRef(null);
    const { cardData, dispatch: dispatchPostUI, draftStatus, } = useMemoizedContext("postUI", [
        "cardData",
        "draftStatus",
    ]);
    useEffect(() => {
        if (cardElement.current) {
            dispatchPostUI({
                type: PostUIActionTypes.SET_CARD_ELEMENT,
                payload: {
                    cardElement: cardElement.current,
                },
            });
        }
    }, [
        cardElement.current,
    ]);
    if (DraftStatusTypes.EDITING === draftStatus) {
        return <PostEditor />;
    }
    if (DraftStatusTypes.PUBLISHED === draftStatus) {
        return (<PostDataProvider cardData={cardData}>
				<PollProvider>
					<AttachmentsProvider>
						<CardWithCustomStyle el={cardElement}>
							<AdTracker el={cardElement}/>
							<PostViewer />
						</CardWithCustomStyle>
					</AttachmentsProvider>
				</PollProvider>
			</PostDataProvider>);
    }
    return null;
};
export default PostViewerOrEditor;
