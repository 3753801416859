import { useState } from "react";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";

export const TYPE_AMZN = "AMZN";
export const TYPE_CHECKS = "CHECKS";
export const TYPE_INTCHECKS = "INTCHECKS";
export const TYPE_CHRTY = "CHRTY";
export const TYPE_JINGDONG = "JINGDONG";
export const TYPE_MASTERCARD = "MASTERCARD";
export const TYPE_MDH = "MDH";
export const TYPE_PAYPAL = "PAYPAL";
export const TYPE_TRANSFERWISE = "TRANSFERWISE";

export const SELECT_REFILL = "0";
export const SELECT_REPLACE = "1";
export const SELECT_NEW = "2";

export const validateWithdrawalAmount = (amount, currency, availableFunds, minimumWithdrawalAmount, limitToWithdrawalAmounts, translationKey) => {
	let isValid = true;
	let validationError = "";
	let parsedAmount = parseInt(amount);

	if (!Number.isInteger(Number(amount))) {
		isValid = false;
		validationError = getTranslation("system.validationMessages.validationAmountMustBeAWholeNumber", true);
	} else if (parsedAmount > availableFunds) {
		isValid = false;
		validationError = getTranslation("system.validationMessages.validationAmountCannotExceedBalance", true);
	} else if (!amount || parsedAmount <= 0) {
		isValid = false;
		validationError = getTranslation("system.validationMessages.validationAmountGreaterThanZero", true);
	}

	if (isValid) {
		// If minimum value,
		if (minimumWithdrawalAmount && minimumWithdrawalAmount > parsedAmount) {
			isValid = false;
			validationError = getTranslation("system.validationMessages.validationMinimumAmountToWithdraw",
				true, getCurrencyTranslation(Math.ceil(minimumWithdrawalAmount), currency, true));
		}
	}

	if (isValid) {
		if (limitToWithdrawalAmounts && limitToWithdrawalAmounts.length > 0) {
			let amount = parsedAmount;
			let validAmount = false;
			let amountString = "";
			let translatedCurrency = getTranslation("system.currencies." + currency, true);
			for (let i = 0; i < limitToWithdrawalAmounts.length; i++) {
				if (amount === parseInt(limitToWithdrawalAmounts[i])) {
					validAmount = true;
					break;
				}
				if (i > 0) {
					amountString += ", ";
				}
				amountString += translatedCurrency + limitToWithdrawalAmounts[i];
			}

			if (!validAmount) {
				isValid = false;

				validationError = getTranslation(
					"system.validationMessages.validationWithdrawalsAreOnlySupportedForTheFollowingAmounts",
					true,
					getTranslation(`frontend.modals.withdrawal.${translationKey}.paymentMethodActual`, true),
					amountString
				);
			}
		}
	}

	if (isValid) {
		validationError = "";
	}

	return [isValid, validationError];
};

export const getWithdrawalAmountValidationTranslation = (limitToWithdrawalAmounts, currency, translationKey) => {
	let amountString = "";
	const translatedCurrency = getTranslation("system.currencies." + currency, true);

	limitToWithdrawalAmounts.forEach((limit, index) => {
		if (index > 0) amountString += ", ";
		amountString += translatedCurrency + limit;
	})

	return getTranslation(
		"system.validationMessages.validationWithdrawalsAreOnlySupportedForTheFollowingAmounts",
		true,
		getTranslation(`frontend.modals.withdrawal.${translationKey}.paymentMethodActual`, true),
		amountString
	);
}

export const withdrawalWillRevokePreferredStatus = (isPreferredMember, preferredStatusThreshold, availableFunds, withdrawAmount) => {
	if (isPreferredMember) {
		if (preferredStatusThreshold > 0) {
			if (availableFunds - parseInt(withdrawAmount) < preferredStatusThreshold) {
				return true;
			}
		}
	}
	return false;
};

export const useModalStepsFramework = (steps, callback) => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const setNextStep = (direction) => {
		// 1 forward, -1 backward.
		if (!direction) {
			// Default direction is forward.
			direction = 1;
		}

		let nextStepIndex = currentStepIndex;

		do {
			nextStepIndex = Math.max(nextStepIndex + direction, 0);
		} while (!steps[nextStepIndex].condition)

		setCurrentStepIndex(nextStepIndex);

		// Firing callback
		if (callback && typeof callback === "function") {
			callback();
		}
	};

	const goToNextStep = () => setNextStep(1);
	const goToPreviousStep = () => setNextStep(-1);
	const currentStepName = () => steps[currentStepIndex].name;

	return [goToNextStep, goToPreviousStep, currentStepName];
};

export const getTranslationSubKey = (withdrawalTypeCode, isForWithdrawalTypes) => {
	if (withdrawalTypeCode.indexOf(TYPE_AMZN) === 0) {
		withdrawalTypeCode = TYPE_AMZN;
	}

	switch (withdrawalTypeCode) {
		case TYPE_AMZN:
			return "amazon";
		case TYPE_CHECKS:
			return "checks";
		case TYPE_INTCHECKS:
			return "intchecks";
		case TYPE_MDH:
			// Inconsistency in translation keys
			return isForWithdrawalTypes
				? "mdhonors"
				: "mdHonors";
		case TYPE_CHRTY:
			return "charity";
		default:
			return withdrawalTypeCode.toLowerCase();
	}
};

export const isRequiredFieldMissing = (requiredFields, profileMissingFields, fieldToCheck) => {
	const arraysToCheck = [requiredFields, profileMissingFields];

	switch (fieldToCheck) {
		case "name":
			return (
				arraysToCheck.every((arr) => arr.includes("first_name"))
				|| arraysToCheck.every((arr) => arr.includes("last_name"))
			)
		case "email":
			return arraysToCheck.every((arr) => arr.includes("email"));
		case "address":
			return (
				arraysToCheck.every((arr) => arr.includes("address_line1"))
				|| arraysToCheck.every((arr) => arr.includes("address_line2"))
				|| arraysToCheck.every((arr) => arr.includes("address_line3"))
				|| arraysToCheck.every((arr) => arr.includes("city"))
				|| arraysToCheck.every((arr) => arr.includes("region"))
				|| arraysToCheck.every((arr) => arr.includes("postal_code"))
				|| arraysToCheck.every((arr) => arr.includes("country"))
			)
		case "phone_number":
			return arraysToCheck.every((arr) => arr.includes("phone_number"));
		case "date_of_birth":
			return arraysToCheck.every((arr) => arr.includes("date_of_birth"));
		default:
			return false;
	}
}