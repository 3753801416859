import {
	ColorGrey10,
	ColorGrey20,
	ColorGrey80,
	ColorGrey100,
	ColorUltraviolet50,
	ColorWhite,
} from "@sermo/design-tokens";
import { Icon, CheckSVG, ArrowRightSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import Onboarding from "@assets/images/onboarding.jpg";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const OnboardingImage = styled.img.attrs({ src: Onboarding })`
	width: 100%;
	height: 120px;
	object-fit: cover;

	${StyledMobileAndTabletPortraitQuery} {
        height: 77px;
    }
`;

export const ModalWrap = styled.div`
	max-height: 61vh;
	margin-top: -2px;
	padding: 24px 24px 0;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;

	${StyledMobileAndTabletPortraitQuery} {
		max-height: unset;
        padding: 16px;
    }

	& > *:not(:first-child) {
		margin-top: 8px;
	}
`;

export const ModalHeader = styled(TextStyled).attrs({
	$kind: "heading04",
	forwardedAs: "h3",
})`
	color: ${ColorGrey100};
	font-size: 20px;
	font-weight: bold;
	letter-spacing: -0.7px;
	line-height: 28px;
`;

export const ModalBody = styled(TextStyled).attrs({
	$kind: "bodyLong00",
	forwardedAs: "p",
})`
	max-width: 480px;
	color: ${ColorGrey80};
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 24px;

	& > div:not(:first-child) {
		margin-top: 8px;
	}

	& > strong {
		font-weight: 500;
	}
`;

export const TaskWrap = styled.button`
	width: 100%;
	padding: 12px 16px;
	border: 1px solid ${ColorGrey10};
	border-radius: 4px;
	display: flex;
	align-items: center;

	${({ $cursor }) => $cursor && `cursor: pointer;`};

	& > *:not(:last-child) {
		margin-right: 16px;
	}

	p {
		margin-top: 4px;
	}
`;

export const TaskLabel = styled(TextStyled).attrs({
	"data-component": "TaskLabel",
	$kind: "heading02",
	forwardedAs: "h5",
})`
	color: ${ColorGrey100};
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.18px;
	line-height: 20px;
	text-align: start;
`;

export const TaskDescription = styled(TextStyled).attrs({
	"data-component": "TaskDescription",
	$kind: "bodyLong00",
	forwardedAs: "p",
})`
	color: ${ColorGrey80};
	font-size: 12px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 20px;
	text-align: start;
`;

export const CheckIcon = styled(Icon).attrs({
	src: CheckSVG,
	width: "20px",
	height: "20px",
})`
	padding: 2px;
	color: ${ColorGrey20};
	border: 2px solid ${ColorGrey20};
	border-radius: 50%;

	${({ $completed }) => $completed && `
		color: ${ColorWhite};
		background-color: ${ColorUltraviolet50};
		border-color: ${ColorUltraviolet50};
	`};
`;

export const ArrowIcon = styled(Icon).attrs({
	src: ArrowRightSVG,
	width: "12px",
	height: "12px",
})`
	margin-left: auto;
`;

export const BarWrap = styled.div`
	&& {
		margin: 26px 0 24px 0;

		${StyledMobileAndTabletPortraitQuery} {
            margin: 8px 0 18px 0;
        }
	}
`;

export const OnboardingBar = styled.div.attrs( { "data-component": "OnboardingBar" } )`
	width: 100%;
	height: 16px;
	border-radius: 8px;
	background-color: ${ColorGrey10};
`;

export const Progress = styled.div`
	width: ${({ $progress }) => `max(calc(100% * ${$progress}), 16%)`};
	height: 16px;
	padding: 0 8px;
	border-radius: 8px;
	background-color: ${ColorUltraviolet50};
	display: flex;
	align-items: center;
`;

export const ProgressText = styled(TextStyled).attrs({
	forwardedAs: "span",
	$kind: "tag01",
})`
	color: ${ColorWhite};
`;