import classNames from "classnames";
import { useMemo } from "react";
import { StyledModalBackground } from "./ModalBackground.styles";
const ModalBackground = ({ card = false, children, clickHandler = () => { }, key, menu = false, overTheTop = false, sponsored = false, tour = false, }) => {
    const className = useMemo(() => classNames("modal-background", {
        card,
        menu,
        "over-the-top": overTheTop,
        sponsored,
        tour,
    }), [
        card,
        menu,
        overTheTop,
        sponsored,
        tour,
    ]);
    return (<StyledModalBackground className={className} data-component={"ModalBackground"} key={key} onClick={clickHandler}>
			{children}
		</StyledModalBackground>);
};
export default ModalBackground;
