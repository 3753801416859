import { Icon, ProcessingSpinner, XIcon } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Button from "@components/Button/Button";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import useAttachmentAPI from "@contexts/Attachments/useAttachmentAPI";
import { useMemoizedContext } from "@hooks/Hooks";
import { AttachmentTypeProps } from "@props/Attachment";
import getTranslation from "@translation/translation";
import layout from "../../../../scss/layout.scss";
import typography from "../../../../scss/typography.scss";
import styles from "./AttachmentManager.scss";

const LoadingSpinner = styled(ProcessingSpinner)`
	width: 100%;
	height: 100%;
	padding: 32px;
	top: 0;
	left: 0;
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	background-color: ${({ theme }) => theme.globals.background.color};

	& > div {
		width: 26px;
		height: 26px;
	}
`;

const AttachmentPreview = (
	{
		comment,
		id,
		loading,
		removeMethod,
		thumbnail,
		title,
		type,
	}
) => {
	let content;

	if (["Image", "Mp4Video"].includes(type)) {
		content = (
			<div styleName="styles.attachment-preview-image-wrapper">
				<img
					src={thumbnail}
					styleName="styles.attachment-preview-image"
				/>
			</div>
		);
	} else {
		content = (
			<div styleName="styles.attachment-preview-container">
				<div styleName="styles.attachment-preview-icon">
					<Icon
						icon="attachmentPDF"
						width={33}
						height={33}
					/>
				</div>
				<div styleName="styles.attachment-preview-icon-name">{title}</div>
			</div>
		);
	}

	const clickHandler = () => {
		removeMethod(id);
	};

	return (
		<div
			styleName={
				classNames("styles.attachment-preview", {
					[`styles.${type.toLowerCase()}`]: type,
					"styles.icon": type !== "Image" || !thumbnail,
					"styles.comment": comment,
				})
			}
			onClick={clickHandler}
		>
			{
				loading
					? <LoadingSpinner />
					: content
			}
			<div styleName="styles.x">
				<Icon src={XIcon} />
			</div>
		</div>
	);
};

AttachmentPreview.propTypes = {
	comment: PropTypes.bool,
	id: PropTypes.number,
	loading: PropTypes.bool,
	removeMethod: PropTypes.func,
	thumbnail: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.oneOf(AttachmentTypeProps),
};

AttachmentPreview.defaultProps = {
	loading: false,
	thumbnail: "",
};

const LIMIT_ATTACHMENTS_IMG = 1;
const LIMIT_ATTACHMENTS_PDF = 1;

const ImagesEditContainer = styled.div`
	margin: 16px 0;
	border-radius: 8px;
	background-color: #f5f7f9;
	color: #d9dade
`;

const AttachmentManager = (
	{
		comment,
		type,
	}
) => {
	const attachmentAPI = useAttachmentAPI();
	const {
		attachments,
	} = useMemoizedContext("attachments", [
		"attachments",
	]);

	const types = {
		Image: ["Image", "Mp4Video"],
		PdfDocument: ["PdfDocument"],
	};
	const filteredAttachments = attachments?.filter((attachment) => types[type].includes(attachment.type));

	let showAdButton = true;

	const addMethod = (e) => {
		console.log("addMethod", e);
	};

	const removeMethod = (id) => {
		attachmentAPI.deleteAttachment({ id });
	};

	const removeAll = () => {
		attachmentAPI.deleteAllGivenAttachments(filteredAttachments);
	};

	if (
		!addMethod
		|| (type === "Image" && filteredAttachments?.length >= LIMIT_ATTACHMENTS_IMG)
		|| (type === "PdfDocument" && filteredAttachments?.length >= LIMIT_ATTACHMENTS_PDF)
	) {
		showAdButton = false;
	}

	if (
		!filteredAttachments
		|| filteredAttachments?.length === 0
	) {
		return null;
	}

	return (
		<ImagesEditContainer>
			<ModuleWrapper
				border={true}
				padded={false}
			>
				<div styleName={classNames("styles.attachment-edit-label")}>
					<Button
						style="flatNeutral"
						clickHandler={removeAll}
					>
						{
							type === "Image"
								? getTranslation("frontend.card.attachmentsManager.removeImages")
								: getTranslation("frontend.card.attachmentsManager.removePdfs")
						}
					</Button>
				</div>

				<div styleName={classNames("styles.attachments-container")}>
					<div styleName="styles.attachment-edit">
						{
							filteredAttachments?.map((attachment, i) => (
								<AttachmentPreview
									{...attachment}
									key={i}
									removeMethod={removeMethod}
									comment={comment}
								/>
							))
						}
						{
							showAdButton && (
								<div
									styleName={
										classNames("styles.add-button", {
											"styles.comment": comment,
										})
									}
									onClick={addMethod}
								>
									<div styleName="styles.attachment-preview-icon-add">
										<Icon
											icon="plus"
											width={40}
											height={40}
										/>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</ModuleWrapper>
		</ImagesEditContainer>
	);
};

AttachmentManager.propTypes = {
	type: PropTypes.oneOf(AttachmentTypeProps),
	addMethod: PropTypes.func,
	removeMethod: PropTypes.func,
	attachments: PropTypes.array,
	comment: PropTypes.bool,
	removeAll: PropTypes.func,
};

AttachmentManager.defaultProps = {
	addMethod: () => {},
	removeMethod: () => {},
	removeAll: () => {},
	comment: false,
};

export default AttachmentManager;
