import { PollAnswerDefaultValues } from "@contexts/PollAnswer";
export const PollQuestionActionTypes = Object.freeze({
    ADD_ANSWER: "ADD_ANSWER",
    DELETE_ANSWER: "DELETE_ANSWER",
    SET_MULTIPLE_CHOICE: "SET_MULTIPLE_CHOICE",
    SET_QUESTION_TEXT: "SET_QUESTION_TEXT",
    SET_SINGLE_CHOICE: "SET_SINGLE_CHOICE",
    SYNC_ANSWER: "SYNC_ANSWER",
    VOTE_QUESTION: "VOTE_QUESTION",
});
const PollQuestionActionTypesValues = Object.values(PollQuestionActionTypes);
const PollQuestionReducer = (state, action) => {
    switch (action.type) {
        case PollQuestionActionTypes.ADD_ANSWER: {
            return {
                ...state,
                answers: [
                    ...state.answers,
                    {
                        ...PollAnswerDefaultValues,
                        ...action.payload.answer,
                    },
                ],
            };
        }
        case PollQuestionActionTypes.DELETE_ANSWER: {
            const { answerId } = action.payload;
            return {
                ...state,
                answers: state.answers.filter((answer) => answer.id !== answerId),
            };
        }
        case PollQuestionActionTypes.SET_MULTIPLE_CHOICE: {
            return {
                ...state,
                isMultipleChoice: true,
            };
        }
        case PollQuestionActionTypes.SET_QUESTION_TEXT: {
            return {
                ...state,
                question: action.payload.text,
            };
        }
        case PollQuestionActionTypes.SET_SINGLE_CHOICE: {
            return {
                ...state,
                isMultipleChoice: false,
            };
        }
        case PollQuestionActionTypes.SYNC_ANSWER: {
            const { answer } = action.payload;
            const newState = { ...state };
            newState.answers = newState.answers.map((existingAnswer) => {
                if (answer.id === existingAnswer.id) {
                    return answer;
                }
                return existingAnswer;
            });
            return newState;
        }
        case PollQuestionActionTypes.VOTE_QUESTION: {
            const { answers } = action.payload;
            const newState = { ...state };
            newState.answers = newState.answers.map((answer) => {
                if (!newState.votes.includes(answer.id)
                    && answers.includes(answer.id)) {
                    answer.votes++;
                    newState.votes = [
                        ...newState.votes,
                        answer.id,
                    ];
                }
                else if (newState.votes.includes(answer.id)
                    && !answers.includes(answer.id)) {
                    answer.votes--;
                    newState.votes = newState.votes.filter((vote) => vote !== answer.id);
                }
                return {
                    ...answer,
                };
            });
            return newState;
        }
        default:
            return state;
    }
};
export default PollQuestionReducer;
