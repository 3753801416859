import classNames from "classnames";
import Button from "@components/Button/Button";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import ReverificationModal from "../ReverificationModal/ReverificationModal";
import styles from "./Reverify.scss";

const Reverify = () => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();

	return (
		<div
			styleName={classNames(["styles.description", "typography.body-long-brand-02"])}
		>
			{getTranslation("frontend.modals.reverification.reverificationBannerText")}

			<div
				styleName={classNames(["styles.button-container"])}
			>
				<Button
					clickHandler={
						() => {
							openModal({
								[EUIKey.MODAL_COMPONENT]: (
									<ReverificationModal
										closeHandler={() => closeModal("reverification")}
									/>
								),
								[EUIKey.MODAL_LABEL]: "reverification",
							});
						}
					}
					theme="dark"
					style="secondary"
					size="small"
					contextClass="banner-error"
				>
					{getTranslation("frontend.modals.reverification.reverificationBannerCta")}
				</Button>
			</div>
		</div>
	);
}

export default Reverify;