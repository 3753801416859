import React from "react";
import { Option } from "@components/FormFields/FormFields";
import usePollAPI from "@components/PostEditor/components/PollEdit/usePollAPI";
import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { PostSubTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const PatientCaseOption = () => {
	const {
		dispatch,
		subType,
	} = useMemoizedContext("postData",[
		"subType",
	]);
	
	const subTypeChange = (value) => {
		dispatch({
			type: PostDataActionTypes.SET_SUBTYPE,
			payload: { 
				subType: value,
			},
		});
	};

	return (
		<>
			<Option
				active={
					(
						!subType
						|| "Undefined" === subType
						|| PostSubTypeTypes.SECOND_OPINION === subType
					)
						? 0
						: 1
				}
				options={
					[
						{
							title: getTranslation("frontend.newPost.secondOpinion", true),
							description: getTranslation("frontend.newPost.secondOpinionDescription", true),
							value: PostSubTypeTypes.SECOND_OPINION,
						},
						{
							title: getTranslation("frontend.newPost.caseStudy", true),
							description: getTranslation("frontend.newPost.caseStudyDescription", true),
							value: PostSubTypeTypes.CASE_STUDY,
						},
					]
				}
				changeHandler={subTypeChange}
			/>
		</>
	);
};

export { PatientCaseOption };

const QuestionOption = () => {
	const pollAPI = usePollAPI();
	const {
		dispatch: dispatchPostData,
		poll,
		subType,
	} = useMemoizedContext("postData",[
		"poll",
		"subType",
	]);

	// TODO: should I delete poll if subType is changed to something else?
	const subTypeChange = (value) => {
		if (
			!poll
			&& PostSubTypeTypes.POLL === value
		) {
			pollAPI.addPoll();
		}

		dispatchPostData({
			type: PostDataActionTypes.SET_SUBTYPE,
			payload: { 
				subType: value,
			},
		});
	};
	
	return (
		<>
			<Option
				active={
					(
						!subType
						|| "Undefined" === subType
						|| PostSubTypeTypes.OPEN_ENDED === subType
					)
						? 0
						: 1
				}
				options={
					[
						{
							title: getTranslation("frontend.newPost.openEnded", true),
							description: getTranslation("frontend.newPost.openEndedDescription", true),
							value: PostSubTypeTypes.OPEN_ENDED,
						},
						{
							title: getTranslation("frontend.newPost.poll", true),
							description: getTranslation("frontend.newPost.pollDescription", true),
							value: PostSubTypeTypes.POLL,
						},
					]
				}
				changeHandler={subTypeChange}
			/>
		</>
	);
};

export { QuestionOption };
