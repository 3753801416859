import getTranslation from "@translation/translation";

const ifFoundThenPush = (data, arr, key) => {
	const found = data?.find((item) => key === item?.adFormFieldType);
	if (found) arr.push(found);
}

const sortNonCustomDataFields = (data) => {
	const keysInOrder = [
		"Name",
		"Specialty",
		"Email",
		"PhoneNumber",
		"MobileNumber",
		"PlaceOfEmployment",
		"Address",
		"City",
		"State",
		"ZipCode",
		"Country",
	];

	const sortedData = [];
	keysInOrder.forEach((key) => ifFoundThenPush(data, sortedData, key));

	return sortedData;
};

export const getAdFormValues = (fields) => {
	const addressFields = [];
	const basicFields = [];
	const sortedFields = sortNonCustomDataFields(fields);
	const addressFieldsTypes = ["Address", "City", "State", "ZipCode", "Country"];
	sortedFields.forEach((field) => addressFieldsTypes.includes(field.adFormFieldType)
		? addressFields.push(field)
		: basicFields.push(field));

	// uses regular fields since the sort function above will not include custom fields
	const customFields = fields?.filter((field) => field.adFormFieldType === "Custom");

	const fieldsWithoutValue = [];
	const fieldsWithValue = [];
	basicFields?.forEach((field) => (field.value)
		? fieldsWithValue.push(field)
		: fieldsWithoutValue.push(field));

	return {
		addressFields,
		basicFields,
		customFields,
		fieldsWithoutValue,
		fieldsWithValue,
	};
};

export const getFieldLabel = (field) => {
	const translation = ("Custom" !== field.adFormFieldType)
		? getTranslation(
			`frontend.forms.adForm.label.${field.adFormFieldType.toLowerCase()}`,
			true
		)
		: field.text;

	const optional = ` (${getTranslation("frontend.generics.optional", true)})`;

	const label = (field.isOptional && field.isEditable)
		? translation + optional.toLocaleLowerCase()
		: translation;

	return label;
};