import { Icon, CheckAltSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "@components/Button/Button";
import FileUpload from "@components/FileUpload/FileUpload";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useApiEndpoint } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalFooter } from "./ModalComponents";

import styles from "./UploadNewProfilePictureModal.scss";

export const UPLOAD_PICTURE_MODAL = "UPLOAD_PICTURE_MODAL";

const UploadNewProfilePictureModal = ({ closeModalEventHandler, profilePictureUpdatedSuccessEventHandler }) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);

	const memberContext = useMemoizedContext("member");

	const [globalValidationError, setGlobalValidationError] = useState("");
	const [profilePictureFileValidationError, setProfilePictureFileValidationError] = useState("");
	const [profilePictureFile, setProfilePictureFile] = useState(null);
	const [profilePicturePredefined, setProfilePicturePredefined] = useState(null);
	const [requestSuccessfullySubmitted, setRequestSuccessfullySubmitted] = useState(false);
	const [photoOrAvatar, setPhotoOrAvatar] = useState("photo");

	const validateForm = () => {
		let valid = true;

		return valid;
	};

	const submitFormClickHandler = () => {
		if (validateForm()) {
			setLoading(true);

			const data = new FormData();
			if (profilePictureFile) {
				data.append("profilePictureFile", profilePictureFile, profilePictureFile.name);
			} else if (profilePicturePredefined) {
				data.append("profilePicturePredefined", `user${profilePicturePredefined}.png`);
			}

			apiEndpoint("settings/updateprofilepicture", data, true).then(
				(dataResponse) => {
					dataResponse.json().then((body) => {
						setLoading(false);
						if (dataResponse.ok) {
							setRequestSuccessfullySubmitted(true);
							memberContext.updateMember({ profilePic: body.profilePictureUrl });

							if (profilePictureUpdatedSuccessEventHandler) {
								profilePictureUpdatedSuccessEventHandler(body.profilePictureUrl);
							} else {
								closeModalEventHandler();
							}
						} else {
							if (body.message) {
								setGlobalValidationError(body.message);
							}

							if (body.fields !== null) {
								for (var property in body.fields) {
									switch (property) {
										case "profilePictureFile":
											setProfilePictureFileValidationError(body.fields[property].join("<br />"));
											break;
									}
								}
							}
						}
					});
				}
			);
		}
	};

	const onProfilePictureFileRemoved = () => {
		setProfilePictureFile(null);
		setProfilePictureFileValidationError("");
	};

	const onProfilePictureFileAdded = (file) => {
		if (file === null) {
			onProfilePictureFileRemoved();
		} else {
			setProfilePictureFile(file);
		}
	};

	useEffect(() => {
		setProfilePictureFile(null);
		setProfilePicturePredefined(null);
	},[photoOrAvatar])

	const closeModalClickHandler = () => {
		closeModalEventHandler(!requestSuccessfullySubmitted);
	};

	return (
		<ModalContainer dataComponent={"ProfilePictureEditor"}>
			<ModalText>
				{
					getTranslation(
						"frontend.modals.uploadProfilePicture.description",
						false,
						window?.sermo?.supportProperties.allowedImageExtensions
					)
				}
			</ModalText>

			<ModalSection>
				<div styleName="styles.tab-buttons-wrapper">
					<Button
						active={photoOrAvatar === "photo"}
						clickHandler={
							() => {
								setPhotoOrAvatar("photo");
							}
						}
						style="tab"
						size="small"
						sidePaddingDisabled={true}
					>{getTranslation("frontend.modals.uploadProfilePicture.uploadTabText")}</Button>
					<Button
						active={photoOrAvatar === "avatar"}
						clickHandler={
							() => {
								setPhotoOrAvatar("avatar");
							}
						}
						style="tab"
						size="small"
						sidePaddingDisabled={true}
					>{getTranslation("frontend.modals.uploadProfilePicture.avatarTabText")}</Button>
				</div>
			</ModalSection>

			{globalValidationError && <GlobalValidationMessage text={globalValidationError} />}

			{
				photoOrAvatar === "photo"
				&& <ModalSection>
					<FileUpload
						buttonText={getTranslation("frontend.modals.uploadProfilePicture.selectPictureButtonText", true)}
						buttonSize="medium"
						buttonLabel={getTranslation("frontend.modals.uploadProfilePicture.buttonLabel", true)}
						renderRoundPreviewImage={true}
						limitToFileTypes={window?.sermo?.supportProperties.allowedImageExtensions}
						validationError={profilePictureFileValidationError}
						onFileAdded={onProfilePictureFileAdded}
						onFileRemoved={onProfilePictureFileRemoved}
					/>
				</ModalSection>
			}

			{
				photoOrAvatar === "avatar"
				&& <ModalSection>
					<AvatarSelection
						selected={profilePicturePredefined}
						onChangeHandler={
							name => {
								setProfilePicturePredefined(name);
							}
						}
					/>
				</ModalSection>
			}

			<ModalFooter
				{...{
					cancelModalEventHandler: closeModalClickHandler,
					submitEventHandler: submitFormClickHandler,
					submitDisabled: loading
						|| profilePictureFileValidationError
						|| (profilePictureFile === null && profilePicturePredefined === null),
					submitText: getTranslation("frontend.generics.save"),
				}}
			/>
		</ModalContainer>
	);
};

UploadNewProfilePictureModal.propTypes = {
	profilePictureUpdatedSuccessEventHandler: PropTypes.func,
	closeModalEventHandler: PropTypes.func,
};

UploadNewProfilePictureModal.defaultProps = {
	closeModalEventHandler: () => {},
};

export default UploadNewProfilePictureModal;

const AvatarSelection = ({ onChangeHandler, selected }) => {
	const avatarCount = 12;

	const avatars = [];

	for ( var i = 1; i <= 12; i++ ) {
		avatars.push(
			<Avatar
				key={i}
				id={i}
				onChangeHandler={onChangeHandler}
				selected={selected}
			/>
		)
	}

	return (
		<>
			<div styleName={classNames(["styles.avatars"])}>
				{avatars}
			</div>
		</>

	)
}

AvatarSelection.propTypes = {
	onChangeHandler: PropTypes.func.isRequired,
}

export { AvatarSelection };

const Avatar = ({ id, onChangeHandler, selected: selectedInitial }) => {
	const [selected, setSelected] = useState(selectedInitial === id);

	useEffect(() => {
		setSelected(selectedInitial === id)
	},[selectedInitial])

	return (
		<div
			key={id}
			styleName={classNames(["styles.avatar", { "styles.selected":selected }])}
			onClick={
				() => {
					setSelected(true);
					onChangeHandler(id);
				}
			}
		>
			<img
				src={`${window.sermo.publicCdnBaseUrl}profileimages/user${id}.png`}
			/>
			{
				selected
				&&<div styleName="styles.check">
					<Icon
						src={CheckAltSVG}
						width={33}
						height={33}
					/>
				</div>
			}
		</div>
	)
}
