import { useMemoizedContext } from "@hooks/Hooks";
const useOnScroll = ({ el }) => {
    const { expanded } = useMemoizedContext("postUIViewing", ["expanded"]);
    const { updateX, updateY, } = useMemoizedContext("elementScroll");
    return (e) => {
        if (expanded) {
            // update cards scroll context
            updateY(el.current.scrollTop);
            updateX(el.current.scrollLeft);
            e.stopPropagation();
        }
    };
};
export default useOnScroll;
