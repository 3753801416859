import React, { useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";

const ZoomEndRedirect = () => {
	useEffect(() => {
		window.parent.postMessage("zoom-end-redirect", window.location.origin)
	}, []);

	return (
		<Loading/>
	);
};

export default ZoomEndRedirect;
