import PropTypes from "prop-types";

const TaxonomyProps = {
	name: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
};

export default TaxonomyProps;

const SpecialtyProps = {
	...TaxonomyProps,
};

export { SpecialtyProps };

const TopicProps = {
	...TaxonomyProps,
};

export { TopicProps };

const TagsProps = {
	...TaxonomyProps,
};

export { TagsProps };
