import { Input } from "@sermo/ui-components";
import React from "react";
import getTranslation from "@translation/translation";
import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { useMemoizedContext } from "@hooks/Hooks";
const Title = () => {
    const { dispatch: dispatchPostData, title, } = useMemoizedContext("postData", [
        "title",
    ]);
    const maxLength = 150;
    const titleChange = (event) => {
        dispatchPostData({
            type: PostDataActionTypes.SET_TITLE,
            payload: {
                title: event.target.value.substring(0, maxLength),
            }
        });
    };
    // TODO: errors
    return (<Input errors={[]} maxLength={maxLength} name="title" onChange={titleChange} placeholder={getTranslation("frontend.card.title", true)} type="text" value={title}/>);
};
export default Title;
