export const toOptions = (member) => {
    return {
        ...member,
        label: member?.label || member.username,
        value: member?.value || member.id
    };
};
export const serializeMember = (node) => {
    if (node.type === "mention") {
        return `<a data-mention="${node.value}" href="/profile/${node.value}">${node.label}</a>`;
    }
    return null;
};
