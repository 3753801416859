import React from "react";
import PostUIViewingContext, { PostUIViewingDefaultValues, } from "@contexts/PostUI/Viewing/PostUIViewingContext";
import PostUIViewingReducer from "@contexts/PostUI/Viewing/PostUIViewingReducer";
const PostUIViewingContextProvider = ({ children, initialState = {}, }) => {
    const initializeState = React.useCallback((init) => {
        return {
            ...PostUIViewingDefaultValues,
            ...init,
        };
    }, []);
    const [state, dispatch] = React.useReducer(PostUIViewingReducer, initialState, initializeState);
    const value = {
        ...state,
        dispatch,
    };
    return (<PostUIViewingContext.Provider value={value}>
			{children}
		</PostUIViewingContext.Provider>);
};
export default PostUIViewingContextProvider;
