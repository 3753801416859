export const UPDATE_HAS_HIT_GOAL = "UPDATE_HAS_HIT_GOAL";
export const INCREMENT_SHOULD_UPDATE = "INCREMENT_SHOULD_UPDATE";

export const RunWithPeterReducer = (state, action) => {
	switch (action.type) {
		case UPDATE_HAS_HIT_GOAL:
			return {
				...state,
				hasHitGoal: action.payload,
			};
		case INCREMENT_SHOULD_UPDATE:
			return {
				...state,
				shouldUpdate: state.shouldUpdate + 1,
			};
		default:
			return state;
	}
}