import { ColorGrey10 } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const VerificationPictogram = styled.img`
	width: 64px;
	height: 64px;
`;

export const TitleText = styled(TextStyled).attrs({ $kind: "heading03" })`
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: -0.2px;
`;

export const BodyText = styled(TextStyled).attrs({ $kind: "bodyLong02" })`
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;

	a {
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
	}
`;

export const HorizontalRule = styled.hr`
	margin-top: 24px;
	color: ${ColorGrey10};
`;

export const FooterWrap = styled(TextStyled).attrs({ $kind: "bodyLong02" })`
	margin-top: 24px;
	display: flex;
	justify-content: space-between;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;
	}

	& > ${BodyText} {
        padding: 0 16px 0 0;

		${StyledMobileAndTabletPortraitQuery} {
            padding: 0;
        }
    }

	& > a {
		${StyledMobileAndTabletPortraitQuery} {
            margin-top: 24px;
			align-self: flex-end;
        }
    }
`;

export const ModalWrap = styled.div`
	max-width: 512px;
	margin-top: -36px;

	${TitleText} {
		margin-top: 24px;
	}

	& > ${BodyText} {
		margin-top: 24px;
	}
`;
