export const CardTypeTypes = Object.freeze({
    AD: "Ad",
    COMMENT_GROUP: "CommentGroup",
    POST: "Post",
    SERMO_CONTENT_CARD: "SermoContentCard",
    RESOURCE_CENTER: "ResourceCenter",
    RESOURCE_CENTER_ITEM: "ResourceCenterItem",
});
const CardTypeTypesValues = Object.values(CardTypeTypes);
export const PostTypeTypes = Object.freeze({
    COMMENT: "Comment",
    DISCUSSION: "Discussion",
    PATIENT_CASE: "PatientCase",
    QUESTION: "Question",
});
const PostTypeTypesValues = Object.values(PostTypeTypes);
export const PostSubTypeTypes = Object.freeze({
    CASE_STUDY: "CaseStudy",
    OPEN_ENDED: "OpenEnded",
    POLL: "Poll",
    SECOND_OPINION: "SecondOpinion",
    UNDEFINED: "Undefined",
});
const PostSubTypeTypesValues = Object.values(PostSubTypeTypes);
// TODO: why is live event and in feed event the same? (copied from Feed.jsx Card Types)
export const AdTypeTypes = Object.freeze({
    CAROUSEL: "InFeedCarousel",
    IN_FEED: "InFeedUnit",
    IN_FEED_EVENT: "InFeedEvent",
    LIVE_EVENT: "InFeedEvent",
    SPONSORED: "SponsoredPost",
});
const AdTypeTypesValues = Object.values(AdTypeTypes);
