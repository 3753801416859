import { createContext } from "react";

export const defaultOnboardingState = {
	associatedEndpoints: [],
	header: "",
	subHeader: "",
	tasks: [],
	setAssociatedEndpoints: () => {},
	setTasks: () => {},
	updateOnboardingApiCall: () => {},
};

export const OnboardingContext = createContext(defaultOnboardingState);