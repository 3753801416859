import { Icon, CheckSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import typography from "../../../../../scss/typography.scss";
import styles from "./CaughtUp.scss";

const CaughtUp = ({ question = false, first = false }) => (
	<div styleName={classNames("styles.caught-up", { "styles.question": question }, { "styles.first": first })}>
		<div styleName="styles.icon">
			<Icon
				src={CheckSVG}
				width={27}
				height={27}
			/>
		</div>
		<div styleName={classNames("styles.content")}>
			<div 
				styleName={classNames("styles.all-caught-up", "typography.heading-brand-02")}
			>
				{`You're all caught up`}</div>
			<div styleName={classNames("styles.text", "typography.body-short-brand-00")}>
				{`You've already seen the comments past this point`}
			</div>
		</div>
	</div>
);

CaughtUp.propTypes = {
	question: PropTypes.bool,
	first: PropTypes.bool,
};

CaughtUp.defaultProps = {
	question: false,
	first: false,
};

export default CaughtUp;
