import React from "react";
import MediaQuery from "react-responsive";
export const mobileTabletBreak = 850;
const tabletDesktopBreak = 1200;
const landscapeBreak = "4/3";
export const MobileAndTabletPortraitQuery = `(min-width: ${mobileTabletBreak}px) and (max-width: ${tabletDesktopBreak}px) and (orientation: portrait), screen and (max-width: ${mobileTabletBreak - 1}px)`;
export const StyledMobileAndTabletPortraitQuery = `@media ${MobileAndTabletPortraitQuery}`;
export const MobileAndTabletPortrait = ({ children }) => <MediaQuery query={MobileAndTabletPortraitQuery}>{children}</MediaQuery>;
export const TabletPortraitQuery = `(min-width: ${mobileTabletBreak}px) and (max-width: ${tabletDesktopBreak}px) and (orientation: portrait)`;
export const StyledTabletQuery = `@media ${TabletPortraitQuery}`;
export const TabletPortrait = ({ children }) => <MediaQuery query={TabletPortraitQuery}>{children}</MediaQuery>;
export const TabletLandscapeAndDesktopQuery = `(min-width: ${mobileTabletBreak}px) and (max-width: ${tabletDesktopBreak}px) and (orientation: landscape), screen and (min-width: ${tabletDesktopBreak - 1}px)`;
export const StyledTabletLandscapeAndDesktopQuery = `@media ${TabletLandscapeAndDesktopQuery}`;
export const TabletLandscapeAndDesktop = ({ children }) => <MediaQuery query={TabletLandscapeAndDesktopQuery}>{children}</MediaQuery>;
export const PointerCoarseQuery = "(pointer: coarse)";
export const StyledPointerCoarseQuery = `@media ${PointerCoarseQuery}`;
export const PointerCoarse = ({ children }) => <MediaQuery query={PointerCoarseQuery}>{children}</MediaQuery>;
export const PointerFineQuery = "(pointer: fine)";
export const StyledPointerFineQuery = `@media ${PointerFineQuery}`;
export const PointerFine = ({ children }) => <MediaQuery query={PointerFineQuery}>{children}</MediaQuery>;
export const Unsupported = ({ children }) => (<MediaQuery maxWidth={mobileTabletBreak} minAspectRatio={landscapeBreak}>
		{children}
	</MediaQuery>);
