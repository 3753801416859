import { CalendarSVG } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useMatch } from "react-router-dom";
import { SizesPropType, StatusPropType } from "@components/LiveEvents/components/EventsContext/EventPropTypes";
import {
	EVENT_LIVE,
	EVENT_ON_DEMAND,
	EVENT_SCHEDULED,
	EVENT_TODAY,
	getTimeParams,
	getTiming,
} from "@components/LiveEvents/LiveEvents.utils";
import getTranslation from "@translation/translation";
import {
	StyledCalendar,
	StyledDateLine,
	StyledLive,
	StyledLiveContainer,
	StyledOnDemand,
	StyledTime,
	StyledToday,
} from "./styled";

const EventTime = ({
	from,
	fromUtc,
	to,
	toUtc,
	size = "small",
	status,
	locale,
}) => {
	const isEventPreview = !!useMatch("/preview/event/:eventId");
	const isAdPreview = !!useMatch("/ad-preview/:adId");
	const isPreview = isEventPreview || isAdPreview;
	const statusFix = isPreview && status !== EVENT_ON_DEMAND
		? EVENT_SCHEDULED
		: status;

	const isFromUtc = from === fromUtc;
	const isToUtc = to === toUtc;

	const fromDateTime = new Date(isFromUtc || isPreview
		? `${fromUtc}Z`
		: from
	);
	const toDateTime = new Date( isToUtc || isPreview
		? `${toUtc}Z`
		: to
	);

	const font = {
		small: "body-short-medium-00",
		medium: "body-short-medium-01",
		large: "body-short-medium-02",
	}

	switch (statusFix || getTiming(fromDateTime, toDateTime)) {
		case EVENT_ON_DEMAND:
			return size === "small"
				? <StyledOnDemand>Watch On Demand</StyledOnDemand>
				: <StyledDateLine><StyledTime
					className={font[size]}
					datetime={fromDateTime?.toLocaleTimeString()}
					// FIXME: Add translation (Watch On Demand) -> Recorded on Friday, July 23, 2021, 12PM (EST)
				>{
						getTranslation(
							"frontend.events.recordedOn", true,
							...getTimeParams(fromDateTime, toDateTime, locale))
					}</StyledTime>
				</StyledDateLine>;
		case EVENT_LIVE:
			return (<StyledLiveContainer>
				<StyledLive>{getTranslation("frontend.events.live")}</StyledLive>
				{
					size !== "small" && <div className={font[size]}>
						{getTranslation("frontend.events.currentlyLive")}
					</div>
				}
			</StyledLiveContainer>);
		case EVENT_TODAY:
			return <StyledToday className="tag-01">{
				getTranslation("frontend.events.todayAt",
					true, fromDateTime.toLocaleString("en-US", {
						hour: "numeric",
						minute: "numeric",
						hour12: true,
					}))
			}</StyledToday>;
		case EVENT_SCHEDULED:
			return size === "small"
				? <StyledTime
					datetime={fromDateTime?.toLocaleTimeString()}
					className={font[size]}
				>{getTranslation("frontend.events.eventTime", true, ...getTimeParams(fromDateTime, toDateTime, locale))}</StyledTime>
				: <StyledDateLine><StyledCalendar
					src={CalendarSVG}
					height={24}
					width={24}
				/><StyledTime
					className={font[size]}
					datetime={fromDateTime?.toLocaleTimeString()}
				>{
						getTranslation("frontend.events.eventTime", true,
							...getTimeParams(fromDateTime, toDateTime, locale))
					}</StyledTime>
				</StyledDateLine>;
	}

	return null
}

EventTime.propTypes = {
	from: PropTypes.string,
	fromUtc: PropTypes.string,
	to: PropTypes.string,
	toUtc: PropTypes.string,
	size: SizesPropType,
	status: StatusPropType,
	locale: PropTypes.string,
}

export default EventTime;
