import { MinusIcon, PlusIcon } from "@sermo/ui-components";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { DEFAULT_HEIGHT } from "@components/DetachedFooter/utils";
import { TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { AdTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import { getVariableFromCSS } from "@styled/variables";
import { CollapseButton, Container } from "../../DetachedFooter.styles";
const EXPAND_BOTTOM_HEIGHT_DESKTOP = 370;
const EXPAND_BOTTOM_HEIGHT_MOBILE = 275;
const ExpandableFooter = ({ children, customStyle, expandBottom, initialHeight: overrideHeight, isInline, onClick, passToParent, type, }) => {
    const uuid = useRef(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    const [initialHeight, setInitialHeight] = useState(overrideHeight || DEFAULT_HEIGHT);
    const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
    const [windowDimensions, setWindowDimensions] = useState({});
    const scrollRef = useRef(null);
    const location = useLocation();
    const { adType } = useMemoizedContext("postData", ["adType"]);
    const { expandedPost, headerHeight, } = useMemoizedContext("ui", [
        "expandedPost",
        "headerHeight",
    ]);
    const { footerTotalHeight, footerOpen, overrideOpenHeight, setFooterHeight, setFooterOpen, setFooterClose, } = useMemoizedContext("detachedFooter", [
        "footerTotalHeight",
        "footerOpen",
        "overrideOpenHeight",
    ]);
    const { mainSectionHeight, topSectionHeight, } = useMemoizedContext("postUIViewing", [
        "expanded",
        "mainSectionHeight",
        "topSectionHeight",
    ]);
    const isOpen = useMemo(() => footerOpen === type, [
        footerOpen,
        type,
    ]);
    const makeFooterDisappear = useMemo(() => "footnote" === type && !isOpen && !!footerOpen && initialHeight > headerHeight, [
        footerOpen,
        headerHeight,
        initialHeight,
        isOpen,
        type,
    ]);
    const isFeedCard = useMemo(() => !!(mainSectionHeight && topSectionHeight) || isInline, [
        isInline,
        mainSectionHeight,
        topSectionHeight,
    ]);
    const openHeight = useMemo(() => {
        let neighborHeight = 0;
        if (type === "footnote"
            && footerTotalHeight > initialHeight) {
            // Footnote is always the top footer according ModalsWrapper.jsx layout,
            // so we need to subtract the height of the ISI.
            neighborHeight = footerTotalHeight - initialHeight;
        }
        if (overrideOpenHeight) {
            return overrideOpenHeight - neighborHeight;
        }
        // Is an InFeed Card
        if (isFeedCard) {
            if (expandBottom) {
                return (isDesktop
                    ? EXPAND_BOTTOM_HEIGHT_DESKTOP
                    : EXPAND_BOTTOM_HEIGHT_MOBILE) + (type === "isi"
                    // In such case the ISI footer grows over the space of the Footnote if exists.
                    ? footerTotalHeight - initialHeight
                    : 0) + 2;
            }
            return mainSectionHeight - topSectionHeight - neighborHeight
                - Number(adType !== AdTypeTypes.CAROUSEL && parseInt(getVariableFromCSS("--cards-spacing")));
        }
        return document.documentElement.offsetHeight - headerHeight - neighborHeight;
    }, [
        expandBottom,
        footerTotalHeight,
        headerHeight,
        initialHeight,
        isDesktop,
        isFeedCard,
        mainSectionHeight,
        overrideOpenHeight,
        topSectionHeight,
        type,
    ]);
    const getInitialHeightFromCustomStyles = (customStyles) => {
        let ih;
        if (isDesktop) {
            ih = customStyles?.expandedInFeedInitialHeight?.replace("px", "") || overrideHeight || initialHeight;
        }
        else {
            ih = customStyles?.expandedInFeedInitialHeightMobile?.replace("px", "") || overrideHeight || initialHeight;
        }
        return +ih;
    };
    const toggleCollapse = (event) => {
        event?.stopPropagation();
        isOpen
            ? setFooterClose()
            : setFooterOpen(type);
    };
    useLayoutEffect(() => {
        function updateSize() {
            // We just need to know that width or height changed,
            // and we don't care which one,
            // just for responsiveness with custom styles and desktop/mobile views.
            setWindowDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        if (!mainSectionHeight && !topSectionHeight) {
            window.addEventListener("resize", updateSize);
            updateSize();
        }
        return () => {
            window.removeEventListener("resize", updateSize);
            setFooterHeight(0, uuid.current, type);
            setFooterClose();
        };
    }, []);
    useEffect(() => {
        setFooterHeight(initialHeight, uuid.current, type);
        if (passToParent) {
            passToParent({ initialHeight });
        }
    }, [initialHeight, type]);
    useEffect(() => {
        if (!isOpen) {
            scrollRef.current?.scrollTo(0, 0);
        }
    }, [footerOpen]);
    useEffect(() => {
        if (customStyle) {
            setInitialHeight(Number(getInitialHeightFromCustomStyles(customStyle)));
        }
    }, [customStyle, windowDimensions]);
    useEffect(() => {
        // Close the footer when the post is expanded
        setFooterClose();
    }, [
        expandedPost,
        location.pathname,
    ]);
    useEffect(() => {
        if (passToParent) {
            passToParent({ openHeight });
        }
    }, [openHeight]);
    useEffect(() => {
        if (overrideHeight) {
            setInitialHeight(overrideHeight);
        }
    }, [overrideHeight]);
    return (<Container className={isOpen
            ? "open"
            : "close"} $closeHeight={initialHeight} $makeFooterDisappear={makeFooterDisappear} onClick={onClick} $openHeight={openHeight} ref={scrollRef}>
			<CollapseButton icon={isOpen
            ? MinusIcon
            : PlusIcon} kind="flatNeutral" onClick={toggleCollapse} size="xsmall"/>
			{children}
		</Container>);
};
export default ExpandableFooter;
