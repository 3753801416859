import { useLayoutEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { maxLength, useTrackingDefaults } from "@components/PostContent";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useTrackEvent } from "@frontend/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./ReadMore.styles";
const ReadMore = () => {
    const trackEvent = useTrackEvent();
    const trackingDefaults = useTrackingDefaults();
    const [portalElement, setPortalElement] = useState(null);
    const { dispatch: dispatchPostUIViewing, showIntroduction, } = useMemoizedContext("postUIViewing", [
        "showIntroduction",
    ]);
    const { adType, body, cardType, clickTags, contentHtml, id: postId, introduction, introText, postReadMorePosition, postReadMoreText, resourceCenterItemId, type, } = useMemoizedContext("postData", [
        "adType",
        "body",
        "cardType",
        "clickTags",
        "contentHtml",
        "id",
        "introduction",
        "introText",
        "postReadMorePosition",
        "postReadMoreText",
        "resourceCenterItemId",
        "type",
    ]);
    useLayoutEffect(() => {
        const portal = document.getElementById(`read-more-portal-${resourceCenterItemId || postId}`);
        if (portal) {
            setPortalElement(portal);
        }
    }, [
        postId,
        resourceCenterItemId,
        showIntroduction,
    ]);
    const shouldShowReadMore = useMemo(() => {
        if (CardTypeTypes.AD === cardType) {
            if (["InFeedUnit", "InFeedEvent"].includes(adType)) {
                const intro = introText || introduction;
                if (intro) {
                    if (!(intro === "" || intro === "<p></p>")) {
                        const editorContent = body || contentHtml;
                        if (editorContent) {
                            return !(editorContent === "" || editorContent === "<p></p>");
                        }
                    }
                    return false;
                }
                return false;
            }
            else {
                return "Question" !== type;
            }
        }
        // User Generate Card Read More
        if (["PatientCase", "Discussion"].includes(type)
            && CardTypeTypes.SERMO_CONTENT_CARD !== cardType) {
            return contentHtml.length > maxLength;
        }
        // Sermo Card Read More
        if (CardTypeTypes.SERMO_CONTENT_CARD === cardType) {
            return body.length > maxLength;
        }
        // Resource Center Read More
        if (CardTypeTypes.RESOURCE_CENTER_ITEM === cardType) {
            return !!introduction;
        }
    }, [
        adType,
        body,
        cardType,
        contentHtml,
        introduction,
        introText,
        type,
    ]);
    const readMoreProps = useMemo(() => ({
        $inPortal: !!portalElement,
        $kind: "bodyShort02",
        $newline: "NewLine" === postReadMorePosition,
        $showLess: !showIntroduction,
    }), [
        portalElement,
        postReadMorePosition,
        showIntroduction,
    ]);
    const readMoreText = useMemo(() => {
        if (CardTypeTypes.AD === cardType
            && ["InFeedUnit", "InFeedEvent"].includes(adType)) {
            return showIntroduction
                ? getTranslation("frontend.card.showMore")
                : getTranslation("frontend.card.showLess");
        }
        else if ("LearnMore" === postReadMoreText
            && (CardTypeTypes.SERMO_CONTENT_CARD === cardType
                || (CardTypeTypes.AD === cardType
                    && "SponsoredPost" === adType))) {
            return getTranslation("frontend.card.learnMore");
        }
        return showIntroduction
            ? getTranslation("frontend.card.readMore")
            : getTranslation("frontend.card.readLess");
    }, [
        adType,
        cardType,
        postReadMoreText,
        showIntroduction,
    ]);
    if (!shouldShowReadMore) {
        return null;
    }
    const readMoreClickEventHandler = (e) => {
        const isInFeedUnit = CardTypeTypes.AD === cardType
            && ["InFeedUnit", "InFeedEvent"].includes(adType);
        let label = "read-more";
        if ("LearnMore" === postReadMoreText) {
            label = "learn-more";
        }
        if (isInFeedUnit
            && !showIntroduction) {
            label = "read-less";
        }
        e.eventSource = label;
        trackEvent({
            ...trackingDefaults,
            action: "click",
            area: label,
            label
        });
        if (clickTags?.readMore) {
            clickTags.readMore.forEach((url) => {
                fetchUrl(url);
            });
        }
        if (isInFeedUnit
            || CardTypeTypes.RESOURCE_CENTER_ITEM === cardType) {
            e.stopPropagation();
            dispatchPostUIViewing({
                type: PostUIViewingActionTypes.TOGGLE_SHOW_INTRODUCTION,
            });
        }
    };
    if (portalElement) {
        return createPortal(<Styles.ReadMorePortal {...readMoreProps}>
				<div onClick={readMoreClickEventHandler}>
					{readMoreText}
				</div>
			</Styles.ReadMorePortal>, portalElement);
    }
    else {
        return (<Styles.ReadMoreContainer {...readMoreProps}>
				<div onClick={readMoreClickEventHandler}>
					{readMoreText}
				</div>
			</Styles.ReadMoreContainer>);
    }
};
export default ReadMore;
