import PropTypes from "prop-types";
import React, { useState } from "react";
import { ToggleButtonList } from "@components/FormFields/FormFields";
import { useTrackEvent } from "@frontend/tracking/tracking";
import getTranslation from "@translation/translation";
import { ModalSection, ModalFooter } from "./../ModalComponents";

const SelectWithdrawalMethod = ({
	stepBackClickHandler,
	withdrawalTypes,
	selectedWithdrawalType,
	withdrawalTypeSelectedEventHandler,
}) => {
	const trackEvent = useTrackEvent();
	const [selectedWithdrawalTypeInternal, setSelectedWithdrawalTypeInternal] = useState(selectedWithdrawalType);

	const continueClickHandler = () => {
		if (withdrawalTypeSelectedEventHandler) {
			for (let i = 0; i < withdrawalTypes.length; i++) {
				let type = withdrawalTypes[i];
				if (type.value === selectedWithdrawalTypeInternal) {
					withdrawalTypeSelectedEventHandler(
						selectedWithdrawalTypeInternal,
						type.minimumWithdrawalAmount,
						type.feeAmount,
						type.feePercentage,
						type.targetCurrency,
						type.targetCurrencyMultiplier,
						type.limitToWithdrawalAmounts,
						type.requiredFields
					);

					trackEvent({
						category: "withdrawal",
						action: "click",
						label: "begin-withdrawal-" + selectedWithdrawalTypeInternal.toLowerCase(),
					});

					break;
				}
			}
		}
	};

	const withdrawalTypesChangeHandler = (selectedValue) => {
		setSelectedWithdrawalTypeInternal(selectedValue);
	};

	return (
		<>
			{
				withdrawalTypes.length > 0 && (
					<ModalSection>
						<ToggleButtonList
							className="full-width"
							name="withdrawalMethods"
							contextClass="withdrawal-options"
							value={selectedWithdrawalTypeInternal}
							options={withdrawalTypes}
							handleChange={withdrawalTypesChangeHandler}
						/>
					</ModalSection>
				)
			}

			<ModalFooter
				{...{
					cancelTracking: {
						category: "withdrawal",
						action: "click",
						label: "cancel-withdraw",
					},
					cancelModalEventHandler: stepBackClickHandler,
					submitEventHandler: continueClickHandler,
					submitText: getTranslation("frontend.generics.next", true),
				}}
			/>
		</>
	);
};

SelectWithdrawalMethod.propTypes = {
	stepBackClickHandler: PropTypes.func,
	withdrawalTypes: PropTypes.array,
	selectedWithdrawalType: PropTypes.string,
	withdrawalTypeSelectedEventHandler: PropTypes.func,
};

SelectWithdrawalMethod.defaultProps = {
	withdrawalTypes: [],
};

export default SelectWithdrawalMethod;
