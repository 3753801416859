import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { css } from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import {
	applyBorder,
	applyModuleTop,
	applyShadow,
	applyZIndex,
} from "@styled";

export const MainContainer = styled.div`
	flex: 1 1 0;
	min-width: 0;
	max-width: 100%;
`;

export const ProfilePicContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const Details = styled.div`
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const NameAndPosted = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.color.icon03};
`;

export const NameContainer = styled(TextStyled)`
	white-space: nowrap;
	display: flex;
	align-items: center;
	min-width: 0;
	font-size: 12px;
	line-height: 16px;
	color: ${({ theme }) => theme.color.text01};

	a {
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		color: ${({ theme }) => theme.color.text01};

		&:hover {
			text-decoration: none;
			color: ${({ theme }) => theme.color.text01};
		}
	}

	path {
		color: ${({ theme }) => theme.color.interactive01};
	}
`;

export const Name = styled.div`
	max-width: 100%;
	display: block;
	white-space: normal;

	p {
		margin: 0;
	}
`;

export const PartnerAndCreator = styled.div`
	font-size: 10px;
	line-height: 12px;
	display: inline-block;
	font-family: "Graphik";
	font-weight: 500;
	margin-left: 8px;
	text-transform: uppercase;
	padding: 0 3px;
`;

export const Creator = styled(PartnerAndCreator)`
	${applyBorder("top right bottom left")};
	color: ${({ theme }) => theme.color.text03};
`;

export const Partner = styled(PartnerAndCreator)`
	background-color: ${({ theme }) => theme.color.partnerTagColor};
	color: ${({ theme }) => theme.color.text05};
`;

export const Posted = styled(TextStyled)`
	white-space: normal;
	color: ${({ theme }) => theme.color.text03};
`;

export const Specialty = styled(TextStyled).attrs({ $kind: "bodyShortLegacy00" })`
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	color: ${({ theme }) => theme.color.text03};

	&:after {
		content: "\\00a0";
	}

	&:last-of-type {
		&:after {
			content: "";
		}
	}
`;

export const MicroItem = styled(TextStyled)`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	padding-left: 3px;
	color: ${({ theme }) => theme.color.text03};

	path {
		fill: ${({ theme }) => theme.color.icon03};
	}
`;

export const Buttons = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr auto;
	grid-template-rows: 1fr;
	grid-column-gap: 8px;
	margin-top: 16px;

	span,
	button {
		align-self: center;
		width: 100%;
	}
`;

export const Edited = styled(TextStyled).attrs({ as: "span" })`
	&:after {
		content: "(edited)";
		padding-left: 8px;
		color: ${({ theme }) => theme.color.text04};
	}
`;

export const Hovered = styled.div`
	&.hovered {
        top: -2px;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        position: absolute;
        padding: 16px 16px 0;
        min-height: 80px;
        height: 80px;
        min-width: 300px;
        ${applyBorder("top right left")};
        ${applyModuleTop("default")};
        ${applyShadow("01")};
        ${applyZIndex("mini-profile")};
        background-color: ${({ theme }) => theme.color.uiBackground};
		margin-bottom: -46px; // Adjust height of the card to match the height of the mini-profile when hovered

        &.anonymous {
            padding-bottom: 16px;
        }

        ${StyledMobileAndTabletPortraitQuery} {
            padding: 0;
        }
    }
`;

export const MiniProfile = styled.div`
	height: 100%;
	display: flex;

	${({ $sponsored }) => $sponsored && css`
		${StyledMobileAndTabletPortraitQuery} {
			padding-top: 8px;
		}
	`};
`;

export const FixedPosition = styled(Hovered)`
	height: 100%;
	border-radius: 8px 8px 0 0;
	position: relative;

	&.hovered {
		&.context-card-header {
			top: -2px;
			left: -2px;
		}

		.context-card-header {
			padding: 24px 0 0 24px;

			${StyledMobileAndTabletPortraitQuery} {
				padding: 0 0 0 16px;
			}
		}

		&.context-card-header-expanded {
			top: 10px;
			left: 40px;
		}

		.name {
			max-width: 300px;
		}
	}
`;

export const MicroProfile = styled.div`
	position: absolute;
	top: 100%;
	left: -1px;
	right: -1px;
	${applyZIndex("mini-profile")};
	${applyShadow("01")};

	&& > div {
		border-radius: 0 0 8px 8px;
	}
`;