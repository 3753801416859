import Footer from "@components/Footer/Footer";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import FollowMenu from "@components/Menus/FollowMenu";
import SidePanel, { Body, Footer as SidePanelFooter } from "@components/SidePanel";
import { useMemoizedContext } from "@hooks/Hooks";
const LeftMobileSidePanel = () => {
    const { loggedIn } = useMemoizedContext("member", ["loggedIn"]);
    if (!loggedIn) {
        return null;
    }
    return (<MobileAndTabletPortrait>
			<SidePanel label={"LeftMobileSidePanel"} side={"left"}>
				<Body>
					<FollowMenu />
				</Body>
				<SidePanelFooter>
					<Footer />
				</SidePanelFooter>
			</SidePanel>
		</MobileAndTabletPortrait>);
};
export default LeftMobileSidePanel;
