import React from "react";
import Images from "@components/Card/components/Images/Images";
import PDFs from "@components/Card/components/PDFs/PDFs";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import UserGeneratedContentInFeed from "@components/PostContent/components/UserGeneratedContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import SolvedIndicator from "@components/PostViewer/components/PatientCaseInFeed/SolvedIndicator";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const PatientCaseInFeed = () => (<CardContainer>
		<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
			<SharedStyles.SpacingContainer>
				<CardHeader />
				<SharedStyles.TitleContainer>
					<SolvedIndicator />
					<Title />
				</SharedStyles.TitleContainer>
				<UserGeneratedContentInFeed />
				<LinkPreview />
				<Images />
				<PDFs />
				<PollDisplay />
			</SharedStyles.SpacingContainer>
		</ModuleWrapper>
		<CardFooter />
	</CardContainer>);
export default PatientCaseInFeed;
