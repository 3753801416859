import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import FileUploadDropZone from "@components/FileUploadDropZone/FileUploadDropZone";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import RegistrationStep from "@components/Register/components/Registration/RegistrationStep";
import { RegisterStyles, ReverificationWrap } from "@components/Register/Register.styles";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const Form = ({ data, updateData, endpoint }) => {
	const registerTrackEvent = useRegisterTrack();
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const [globalValidationError, setGlobalValidationError] = useState("");

	const skipLocalId = data.identityVerified;
	const skipMedicalId = data.credentialsVerified || data.hasBeenAssignedProviderAutomatically;

	const [medicalIdFile, setMedicalIdFile] = useState(null);
	const [localIdFile, setLocalIdFile] = useState(null);
	const [medicalIdFileName, setMedicalIdFileName] = useState("");
	const [localIdFileName, setLocalIdFileName] = useState("");

	const [medicalIdFileValidationError, setMedicalIdFileValidationError] = useState("");
	const [localIdFileValidationError, setLocalIdFileValidationError] = useState("");

	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		let can = true;
		// if we cant skip the medical id make sure its uploaded
		if (!skipMedicalId) {
			can = medicalIdFileName !== "" && medicalIdFile !== null;
		}
		// if the first test passed and we cant skip the local id make sure its uplaoded
		if (!skipLocalId) {
			can = localIdFileName !== "" && localIdFile !== null
		}
		setCanSubmit(can);
	},[skipMedicalId, skipLocalId, medicalIdFile, localIdFile, medicalIdFileName, localIdFileName])

	const onMedicalIdFileRemoved = () => {
		setMedicalIdFile(null);
		setMedicalIdFileName("");
		setMedicalIdFileValidationError("");
	};

	const onLocalIdFileRemoved = () => {
		setLocalIdFile(null);
		setLocalIdFileName("");
		setLocalIdFileValidationError("");
	};

	const onMedicalIdFileAdded = file => {
		if (!file) {
			onMedicalIdFileRemoved();
		} else {
			setMedicalIdFile(file);
			setMedicalIdFileName(file.name);
			setMedicalIdFileValidationError("");
		}
	};

	const onLocalIdFileAdded = file => {
		if (!file) {
			onLocalIdFileRemoved();
		} else {
			setLocalIdFile(file);
			setLocalIdFileName(file.name);
			setLocalIdFileValidationError("");
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!skipLocalId) {
			if (!localIdFile) {
				setLocalIdFileValidationError(
					getTranslation("system.validationMessages.validationACopyOfYourLocalIdIsRequired", true)
				);
				valid = false;
			} else {
				setLocalIdFileValidationError("");
			}
		}

		if (!skipMedicalId) {
			if (!medicalIdFile) {
				setMedicalIdFileValidationError(
					getTranslation("system.validationMessages.validationACopyOfYourMedicalCredentialsIsRequired", true)
				);
				valid = false;
			} else {
				setMedicalIdFileValidationError("");
			}
		}

		return valid;
	};

	const formSubmitHandler = e => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);

			const requestData = new FormData();

			if (!skipMedicalId) {
				requestData.append("medicalIdFile", medicalIdFile, medicalIdFileName);
			}

			if (!skipLocalId) {
				requestData.append("localIdFile", localIdFile, localIdFileName);
			}

			// registration-process-more-information-uploaded-info-click
			registerTrackEvent({
				label: "more-information-uploaded-info",
				action: "click",
			});

			apiEndpoint(`${endpoint}/uploaddocuments`, requestData, true).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (dataResponse.ok) {
						updateData(body);
					} else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}

						if (body?.fields) {
							for (let property in body.fields) {
								switch (property) {
									case "medicalIdFile":
										setMedicalIdFileValidationError(body.fields[property].join("<br />"));
										break;
									case "localIdFile":
										setLocalIdFileValidationError(body.fields[property].join("<br />"));
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const skipStepClientEventHandler = e => {
		e.preventDefault();
		if (loading) {
			return;
		}

		const requestData = new FormData();
		requestData.append("skipThisStep", true);
		setLoading(true);

		// registration-process-more-information-missing-credentials-click
		registerTrackEvent({
			label: "more-information-missing-credentials",
			action: "click",
		});

		apiEndpoint(`${endpoint}/uploaddocuments`, requestData, true).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);

				if (dataResponse.ok) {
					updateData(body);
				} else {
					if (body.message) {
						setGlobalValidationError(body.message);
					}
				}
			});
		});
	};

	const goBackClientEventHandler = e => {
		e.preventDefault();

		// registration-process-more-information-page-back-click
		registerTrackEvent({
			label: "more-information-page-back",
			action: "click",
		});

		if (data.identityVerifiedThroughMobileNumber) {
			updateData({ currentStep: RegistrationStep.EnterName });
		} else if (data.isIdentityAutoVerificationEnabled ) {
			updateData({ currentStep: RegistrationStep.SelectIdentityVerificationMode });
		} else {
			updateData({ currentStep: RegistrationStep.IdentityVerification });
		}
	};

	return (
		<LoggedOutStyles>
			<RegisterStyles>
				<TextStyled
					className={classNames(["form-title"])}
					$kind="heading04"
				>
					{getTranslation("frontend.registration.uploadDocumentationPage.title")}
				</TextStyled>
				<TextStyled
					className={classNames(["form-subtitle", "subtitle-help-text"])}
					$kind="bodyShort02"
				>
					{getTranslation("frontend.registration.uploadDocumentationPage.description")}
				</TextStyled>

				<form
					onSubmit={formSubmitHandler}
					className={classNames(["form"])}
				>
					<div className={classNames("form-field-row","dropZones")}>
						{
							!skipMedicalId && (
								<FileUploadDropZone
									title={
										getTranslation(
											"frontend.registration.uploadDocumentationPage.medicalIdFileTitle",
											true
										)
									}
									description={
										getTranslation(
											"frontend.registration.uploadDocumentationPage.medicalIdFileDescription",
											true
										)
									}
									validationError={medicalIdFileValidationError}
									onFileAdded={onMedicalIdFileAdded}
									onFileRemoved={onMedicalIdFileRemoved}
								/>
							)
						}

						{
							!skipLocalId && (
								<FileUploadDropZone
									title={
										getTranslation(
											"frontend.registration.uploadDocumentationPage.localIdFileTitle",
											true
										)
									}
									description={
										getTranslation(
											"frontend.registration.uploadDocumentationPage.localIdFileDescription",
											true
										)
									}
									validationError={localIdFileValidationError}
									onFileAdded={onLocalIdFileAdded}
									onFileRemoved={onLocalIdFileRemoved}
								/>
							)
						}

						<GlobalValidationMessage text={globalValidationError} />
					</div>

					<div className={classNames(["submit-container", "upload-verification-documents"])}>
						<div className={classNames(["submit-container-side"])}>
							<div className={classNames(["submit-container--back-button"])}>
								<Button
									name="back"
									style="flatNeutral"
									size="medium"
									disabled={loading}
									clickHandler={goBackClientEventHandler}
								>
									{getTranslation("frontend.generics.back", true)}
								</Button>
							</div>
							<Button
								name="submit"
								size="medium"
								disabled={!canSubmit || loading}
								loading={loading}
								type="submit"
								clickHandler={formSubmitHandler}
							>
								{getTranslation("frontend.generics.next", true)}
							</Button></div>
						<TextStyled
							className={classNames("submit-container--skip-button")}
							$kind="bodyShort01"
						>
							<span
								className={classNames(["cannot-see-your-profile--link"])}
								onClick={skipStepClientEventHandler}
							>
								<a>	{getTranslation("frontend.registration.uploadDocumentationPage.skipText", true)}</a>
							</span>
						</TextStyled>
					</div>
				</form>
			</RegisterStyles>
		</LoggedOutStyles>
	)
}

const UploadVerificationDocuments = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");
	const registerTrackEvent = useRegisterTrack();

	useEffect(() => {
		// registration-process-more-information-page-view
		registerTrackEvent({
			label: "more-information-page",
			action: "view",
		});
	}, []);

	return (
		<>
			{
				isRegister
				&& <LoggedOutStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<Form
								data={data}
								updateData={updateData }
								endpoint={endpoint}
							/>
						</div>
					</div>
				</LoggedOutStyles>
			}
			{
				!isRegister
				&& <ReverificationWrap>
					<Form
						data={data}
						updateData={updateData }
						endpoint={endpoint}
					/>
				</ReverificationWrap>
			}
		</>

	);
};

export default UploadVerificationDocuments;
