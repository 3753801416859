import {
	Icon,
	InfoSVG,
	QuickFind,
	SelectTrigger,
	ToggleButton,
	Tooltip,
} from "@sermo/ui-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import Loading from "@components/NewPost/components/Loading/Loading";
import {
	OtherContainer,
	TagToggleGroup,
	TopicSelectionContainer
} from "@components/PostEditor/components/TopicSelection/TopicSelection.styled";
import { PostDataActionTypes } from "@contexts/PostData";
import { PostUIEditingActionTypes } from "@contexts/PostUI/Editing/PostUIEditingReducer";
import { useTrackEvent } from "@frontend/tracking";
import { useFetch, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { DraftStatusTypes } from "@types/Post/postUI";

const otherTopic = {
	"topicId": 0,
	"name": getTranslation("frontend.card.otherTopic", true),
	"count": 1
}

const ENG_LOCALE = "en-US";

// TODO: fix bug for editing topic of pre-existing post
const TopicSelection = () => {
	const trackEvent = useTrackEvent();
	// TODO: we should probably store this in a context
	const [data] = useFetch("api/topics/listtopics", {
		orderBy: "Name"
	});

	const { locale } = useMemoizedContext("member", ["locale"]);

	const {
		dispatch: dispatchPostUIEditing,
		saving,
		suggestedTopics,
	} = useMemoizedContext("postUIEditing", [
		"saving",
		"suggestedTopics",
	]);

	const {
		draftStatus,
		publishing,
	} = useMemoizedContext("postUI", [
		"draftStatus",
		"publishing",
	]);

	const {
		dispatch: dispatchPostData,
		topic,
		topicId,
	} = useMemoizedContext("postData", [
		"topic",
		"topicId",
	]);

	const [selectedTopicId, setSelectedTopicId] = useState(topicId || topic?.id || 0);

	useEffect(() => {
		if (
			!topicId
			&& selectedTopicId > 0
		) {
			setSelectedTopicId(0);
		}
	}, [
		selectedTopicId,
		topicId,
	]);

	// keeps track of previous running publishing
	const [busy, setBusy] = useState(publishing || saving);

	const changeSelection = useCallback((value) => {
		setSelectedTopicId(value);
		dispatchPostData({
			type: PostDataActionTypes.SET_TOPIC_ID,
			payload: {
				topicId: value,
			},
		});
	}, [
		dispatchPostData,
	]);

	// controls the visibility of the other topics dropdown
	const [showOtherTopics, setShowOtherTopics] = useState(true);

	// the list of all the topics
	const [topics, setTopics] = useState([]);

	// the list of topics that are not suggested
	const [otherTopics, setOtherTopics] = useState([]);

	// controls the visibility of the suggested topics buttons
	const [showSuggestedTopicButtons, setShowSuggestedTopicButtons] = useState(false);

	// when the data is loaded, set the topics
	// this is a list of all the topics
	useEffect(() => {
		if ( data?.topics?.length > 0 ) {
			setTopics(data.topics.map(topic => {
				return { value: topic.id, label: topic.name }
			}))
		}
	},[data])

	// when there are suggested topics, set the other topics
	useEffect(() => {
		setOtherTopics(topics.filter(topic => !suggestedTopics.find(suggestedTopic => suggestedTopic.topicId === topic.value)));
	},[suggestedTopics, topics, topics.length])

	useEffect(() => {
		if (busy && (!publishing && !saving)) {
			setBusy(false);
		}

		return () => {
			if (publishing) {
				// Cleanup before the user open again the editor if publishing is on going
				dispatchPostUIEditing({
					type: PostUIEditingActionTypes.CLEAR_SUGGESTED_TOPICS,
				});
			}
		}
	}, [publishing, saving]);

	const findTopicSlugNameFromId = (id) => (
		[...(data?.topics || [])].find((topic) => id === topic.id)?.slug
		|| otherTopic.name.toLowerCase().replaceAll(" ", "-")
	);

	const selectedNotSuggestedTopic = () => selectedTopicId && !suggestedTopics?.find(topic => topic.topicId === selectedTopicId);

	const topicButtonClickHandler = (event, value) => {
		if (value === 0 && selectedNotSuggestedTopic()) {
			return;
		}
		trackEvent({
			category: "suggested-topic",
			action: `click-${findTopicSlugNameFromId(value)}`,
			label: "selected",
			topicId: value,
		});

		suggestedTopics.forEach((topic) => {
			value !== topic.topicId && trackEvent({
				category: "suggested-topic",
				action: `view-${findTopicSlugNameFromId(topic.topicId)}`,
				label: "not-selected",
				topicId: topic.topicId,
			})
		});

		changeSelection(value);
	};

	const handleOtherTopicSelectChange = useCallback((event, value) => {
		trackEvent({
			category: "suggested-topic",
			action: `click-${findTopicSlugNameFromId(value)}`,
			label: "other-topic-selection",
			topicId: value,
		});

		changeSelection(value);
	}, [data]);

	const handleOtherTopicOpen = useCallback(() => {
		trackEvent({
			category: "suggested-topic",
			action: "click",
			label: "dropdown",
		});
	}, []);

	// automatically select the first suggested topic
	// when the suggested topics change
	const autoSelectFirstSuggestedTopic = () => {
		if ( suggestedTopics?.length > 0 ) {
			changeSelection(suggestedTopics[0].topicId);

			trackEvent({
				category: "suggested-topic",
				action: `${suggestedTopics[0].name.toLowerCase().replaceAll(" ", "-")}`,
				label: "default-selected",
				topicId: suggestedTopics[0].topicId,
			})
		}
	}

	const updateSuggestedTopicButtonsVisibility = () => {
		setShowSuggestedTopicButtons(suggestedTopics?.length > 0 && !busy);
	}

	// This is needed because the memoizedContext hook doesn't actually work right
	const previousSuggestedTopics = usePrevious(suggestedTopics);

	// all logic to handle showing/hiding the suggested topics buttons
	useEffect(() => {
		if (
			previousSuggestedTopics
			&& JSON.stringify(suggestedTopics) !== JSON.stringify(previousSuggestedTopics)
		) {
			if ( suggestedTopics?.length > 0 ) {
				autoSelectFirstSuggestedTopic();
			}
			else {
				changeSelection(0);
			}
		}
		updateSuggestedTopicButtonsVisibility();
	}, [suggestedTopics, previousSuggestedTopics, busy]);

	// all logic to handle showing/hiding the other topics dropdown
	useEffect(() => {
		let shouldShow = false;

		// there are suggested topics
		// and the user has selected "other topic"
		if (
			suggestedTopics?.length > 0
			&& selectedTopicId === 0
		) {
			shouldShow = true;
		}

		// if the user has selected a topic that is not suggested
		if (selectedNotSuggestedTopic()) {
			shouldShow = true;
		}

		// No suggestions were returned this time
		if (!suggestedTopics?.length) {
			shouldShow = true;
		}

		// The user is non english and won't receive any suggestions
		if ( locale !== ENG_LOCALE ) {
			shouldShow = true;
		}

		setShowOtherTopics(shouldShow);
	}, [
		selectedTopicId,
		suggestedTopics,
	]);

	// Build this list of topic buttons
	// made up of the suggested topics and the other topic button
	const topicButtons = useMemo(() => {
		const suggestedTopicAndOtherButtons = [...suggestedTopics, otherTopic];

		return suggestedTopicAndOtherButtons.map((tag) => (
			<ToggleButton
				key={tag.topicId}
				text={tag.name}
				value={tag.topicId}
			/>
		))
	}, [suggestedTopics]);

	// When selected from dropdown the value should be 0 to keep "Other topic" button selected
	const selectedTopicButton = useMemo(() => {
		const selectedFromButton = !!suggestedTopics.find(suggestedTopic => suggestedTopic.topicId === selectedTopicId);
		if (!selectedTopicId && selectedTopicId !== 0) {
			return undefined;
		}
		return selectedFromButton
			? selectedTopicId
			: 0;
	}, [selectedTopicId]);

	if (DraftStatusTypes.COMPOSING !== draftStatus) {
		return null;
	}

	return (
		<TopicSelectionContainer data-component={"TopicSelection"}>
			<div className="headLine"><span className="heading-01">{getTranslation("frontend.card.selectTopic", true)}</span>
				<span className="infoIcon">
					<Tooltip message={
						getTranslation(locale === ENG_LOCALE
							? "frontend.card.tooltip"
							: "frontend.card.tooltipNotEng", true)
					}
					>
						<Icon src={InfoSVG} />
					</Tooltip>
				</span>
			</div>
			<div className="editTags">
				{
					showSuggestedTopicButtons
					&& <div className="tags">
						<TagToggleGroup
							value={+selectedTopicButton}
							onChange={topicButtonClickHandler}
						>
							{topicButtons}
						</TagToggleGroup>
					</div>
				}
				{
					saving && locale === ENG_LOCALE
					&& <Loading/>
				}
			</div>
			{
				showOtherTopics
				&& (
					<OtherContainer>
						<QuickFind
							options={otherTopics}
							onSelect={handleOtherTopicSelectChange}
							maxHeight={300}
							onOpen={handleOtherTopicOpen}
						>
							<SelectTrigger
								options={otherTopics}
								placeholder={getTranslation("frontend.card.selectOtherTopic", true)}
								value={selectedTopicId}
							/>
						</QuickFind>
					</OtherContainer>
				)
			}
		</TopicSelectionContainer>
	)}

export default TopicSelection;