import { ColorGrey10, ColorWhite } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const TitleText = styled(TextStyled).attrs({ $kind: "heading06" })`
	font-weight: 700;
	letter-spacing: -0.9px;

	${StyledMobileAndTabletPortraitQuery} {
		font-size: 16px;
		line-height: 22px;
	}
`;

export const BodyText = styled(TextStyled).attrs({ $kind: "bodyLong03" })`
	a {
		font-size: 16px;
		line-height: 26px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		font-size: 14px;
		line-height: 24px;

		a {
            font-size: 14px;
            line-height: 24px;
        }
	}
`;

export const HorizontalRule = styled.hr`
	margin: 48px 0;
	color: ${ColorGrey10};

	${StyledMobileAndTabletPortraitQuery} {
		margin: 24px 0;
	}
`;

export const FooterWrap = styled(TextStyled).attrs({ $kind: "bodyLong02" })`
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;
	}

	& > ${BodyText} {
        padding: 0 16px 0 0;

		${StyledMobileAndTabletPortraitQuery} {
            padding: 0;
        }
    }

	& > a {
		${StyledMobileAndTabletPortraitQuery} {
            margin-top: 24px;
			align-self: flex-end;
        }
    }
`;

export const MeatWrap = styled.div`
	max-width: 840px;
	padding: 48px;
	border-radius: 8px;
	background-color: ${ColorWhite};

	${TitleText} {
		margin-top: 24px;
	}

	& > ${BodyText} {
		margin-top: 24px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
		height: 100%;
        padding: 96px 16px 16px;
    }
`;

export const PageWrap = styled.div`
	width: 100%;
	height: calc(100vh - ${p => p.theme.layout.topNavHeight});
	display: flex;
	justify-content: center;
	align-items: center;

	${StyledMobileAndTabletPortraitQuery} {
		${p => p.$loggedIn && `height: 100vh;`}
	}
`;