import { useEffect } from "react";
import { useSaving } from "@contexts/PostUI/Editing";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
import { useDebounce, useApiEndpoint } from "@hooks/Hooks";

export const PollAnswerEditAPISync = () => {
	const apiEndpoint = useApiEndpoint();
	const debounceDelay = 2000;
	const save = useSaving();

	const {
		publishing,
	} = useMemoizedContext("postUI", [
		"publishing",
	]);

	const pollAnswer = useMemoizedContext("pollAnswer", [
		"id",
		"answer",
	]);

	const {
		id: answerId,
		answer: answerText,
	} = pollAnswer;

	const apiCall = options => {
		const defaults = {
			endpoint: "posts",
			body: {},
			formData: false,
		};

		const { endpoint, action, body, formData } = {
			...defaults,
			...options,
		};

		return apiEndpoint(`${endpoint}/${action}`, body, formData);
	};

	const [answerTextDebounced] = useDebounce(answerText, debounceDelay);
	const answerTextPrevious = usePrevious(answerTextDebounced);

	useEffect(() => {
		if (
			!publishing
			&& answerTextDebounced
			&& (answerTextDebounced !== answerTextPrevious)
		) {
			apiCall({
				action: "updateAnswer",
				body: {
					id: answerId,
					answer: answerText,
				},
			}).then(() => {
				save.stop(`updateAnswer${answerId}`);
			});
		} else if (
			answerText === answerTextPrevious
			&& answerTextDebounced === answerTextPrevious
		) {
			save.stop(`updateAnswer${answerId}`);
		}
	}, [
		answerText,
		answerTextDebounced,
	]);

	return null;
};