import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";

export const ButtonContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const MenuOptionTitle = styled(TextStyled).attrs({
	$kind: "heading02",
})`
	padding: 3px 4px;
	color: ${({ theme }) => theme.color.text02};
	font-weight: 500;
`;

export const MenuOptionDescription = styled(TextStyled).attrs({
	$kind: "bodyShort00",
})`
	padding: 0 4px 3px;
	color: ${({ theme }) => theme.color.text03};
`;

export const Border = styled.div`
	width: 100%;
	height: 0;
	border-bottom: 1px solid ${({ theme }) => theme.color.ui02};
`;