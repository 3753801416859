import React from "react";

export const eventsDefaultValues = {
	// member
	emailAddress: null,
	firstName: null,
	lastName: null,
	isRegistered: null,
	// event
	brandStyleId: null,
	description: null,
	startDateTime: null,
	startDateTimeUtc: null,
	endDateTime: null,
	endDateTimeUtc: null,
	id: null,
	eventToken: null,
	imageUrl: null,
	isISIAutoSized: null,
	isiStyleId: null,
	isiText: null,
	name: null,
	speakers: null,
	sponsors: null,
	status: null,
	totalRegistrants: null,
	zoomWebinarId: null,
	zoomRegistrantToken: null,
	eventId: null,
	isPreRecorded: null,
	onDemandPreRecordedThumbnailImageUrl: null,
	autoplayVideo: null,
	updateRegistrationStatus: null,
};

const EventsContext = React.createContext(eventsDefaultValues);

export default EventsContext;
