import React from "react";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { StyledDropdown } from "../AddressInputs/AddressInputs.styled";
import AdFormInput from "../AdFormInput";

const SpecialtyDropdown = ({ data, form }) => {
	const apiEndpoint = useApiEndpoint();
	const { adForm } = useMemoizedContext("postData", ["adForm"]);
	const [specialtyOptions, setSpecialtyOptions] = React.useState([]);

	React.useEffect(() => {
		if (!data.value) {
			apiEndpoint("form/getdata", { AdFormId: adForm.id })
				.then((res) => res.json())
				.then(({ availableSpecialties }) => {
					setSpecialtyOptions(availableSpecialties.map((s) => {return {
						value: s.specialtyId.toString(),
						label: s.name,
					}}).sort((a, b) => a.label.localeCompare(b.label)));
				});
		}
	}, [adForm.id]);

	if (data.value) {
		return (
			<AdFormInput
				data={data}
				form={form}
			/>
		);
	}

	const label = getTranslation(`frontend.forms.adForm.label.specialty`, true);
	const handleChange = (e, value) => form.setFieldValue(data.adFormFieldType, value);

	return (
		<StyledDropdown
			errors={form.errors[data.adFormFieldType]}
			label={label}
			options={specialtyOptions}
			onChange={handleChange}
			required={!data.isOptional}
			value={form.values[data.adFormFieldType]}
		/>
	)
}

SpecialtyDropdown.defaultProps = {
	data: {},
	form: {},
}

export default SpecialtyDropdown;