import React from "react";
import { FeedLayoutContainer } from "@components/FeedLayout/FeedLayout.styles";
import { useMemoizedContext } from "@hooks/Hooks";
import { AuthenticatedLayout, UnauthenticatedLayout } from "./AdPreview.styles";
import AdPreviewFeed from "./AdPreviewFeed";
import LeftRail from "./AdPreviewLeftRail";
import RightRail from "./AdPreviewRightRail";
import SignIn from "./AdPreviewSignIn";

const AdPreviewLayout = () => {
	const {
		hasAccessToAdPreview,
		loggedIn,
	} = useMemoizedContext("member", [
		"hasAccessToAdPreview",
		"loggedIn",
	]);

	if ( !hasAccessToAdPreview ) {
		return (
			<UnauthenticatedLayout $loggedIn={loggedIn}>
				<SignIn/>
			</UnauthenticatedLayout>
		)
	}

	return (
		<AuthenticatedLayout $loggedIn={loggedIn}>
			<FeedLayoutContainer>
				<LeftRail/>
				<AdPreviewFeed/>
				<RightRail/>
			</FeedLayoutContainer>
		</AuthenticatedLayout>
	);
}

export default AdPreviewLayout;
