import { PopupMenu, ProfileSVG, GearSVG, HelpSVG, LogOutSVG, Icon } from "@sermo/ui-components";
import { useNavigate } from "react-router-dom";
import ConfirmPasswordModal from "@components/Modal/Modals/ConfirmPasswordModal/ConfirmPasswordModal";
import { EUIKey, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { usePreconditionModal } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { loggedOut } from "../../mobile-app";
import * as Styles from "./ProfileMenu.styles";
const ProfileMenu = ({ triggerRef, }) => {
    const menuOptions = [];
    const navigate = useNavigate();
    const openModal = useOpenModal();
    const trackEvent = useTrackEvent();
    const { affiliateMember, hasAccess, hasBeenAuthenticatedViaToken, id, locale, } = useMemoizedContext("member", [
        "affiliateMember",
        "hasAccess",
        "hasBeenAuthenticatedViaToken",
        "id",
        "locale",
    ]);
    const logout = (e) => {
        e.preventDefault();
        fetch(`/${locale}/api/login/signout`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            response.json().then(() => {
                if (response.ok) {
                    trackEvent({
                        category: "top-navigation",
                        action: "click",
                        label: "log-out",
                    }, () => {
                        // If running in mobile app, signal native code to stop push notifications.
                        loggedOut();
                        window.location.href = "/login";
                    });
                }
                else {
                    console.log("Error logging out!");
                }
            });
        });
    };
    if (hasAccess("canViewOwnProfile")) {
        menuOptions.push({
            label: getTranslation("frontend.navMenu.profileMenu.myProfile", true),
            startIcon: ProfileSVG,
            onClick: () => {
                trackEvent({
                    category: "top-navigation",
                    action: "click",
                    label: "my-profile",
                });
                navigate(`/profile/${id}/overview`);
            },
        });
    }
    if (hasAccess("canAccessFrontendAccountSettings") && !hasBeenAuthenticatedViaToken) {
        menuOptions.push({
            label: getTranslation("frontend.navMenu.profileMenu.accountSettings", true),
            startIcon: GearSVG,
            onClick: () => {
                trackEvent({
                    category: "top-navigation",
                    action: "click",
                    label: "user-settings",
                });
                navigate("/settings/accountsettings");
            },
        });
    }
    const callbackOnSuccess = () => navigate("/settings/accountsettings");
    const showConfirmPasswordModalClick = e => {
        openModal({
            [EUIKey.MODAL_COMPONENT]: (<ConfirmPasswordModal callbackEventHandler={callbackOnSuccess}/>),
            [EUIKey.MODAL_LABEL]: "confirm-password",
        });
    };
    const { openPreconditionModal } = usePreconditionModal(() => {
        trackEvent({
            category: "top-navigation",
            action: "click",
            label: "user-settings",
        });
        navigate("/settings/accountsettings");
    });
    if (hasAccess("canAccessFrontendAccountSettings") && hasBeenAuthenticatedViaToken) {
        menuOptions.push({
            label: getTranslation("frontend.navMenu.profileMenu.accountSettings", true),
            startIcon: GearSVG,
            onClick: (e) => {
                trackEvent({
                    category: "top-navigation",
                    action: "click",
                    label: "user-settings",
                });
                if (affiliateMember) {
                    openPreconditionModal();
                }
                else {
                    showConfirmPasswordModalClick(e);
                }
            },
        });
    }
    menuOptions.push({
        label: getTranslation("frontend.navMenu.profileMenu.help", true),
        startIcon: HelpSVG,
        onClick: () => {
            trackEvent({
                category: "top-navigation",
                action: "click",
                label: "help-center",
            });
            window.open("https://support.sermo.com/", "_blank");
        },
    });
    menuOptions.push({
        label: getTranslation("frontend.navMenu.profileMenu.logout", true),
        startIcon: LogOutSVG,
        onClick: logout,
    });
    if (triggerRef) {
        return (<PopupMenu data-component={"ProfileMenu"} defaultOpen triggerRef={triggerRef} items={menuOptions} position="fixed" placement="bottom-end"/>);
    }
    else {
        return (<Styles.Container>
				{menuOptions.map(({ label, onClick, startIcon, }) => (<Styles.Item data-component={"menu-item"} // using the same label as component above
             key={`${label}-menu-item`} onClick={onClick}>
							<Icon height={24} src={startIcon} width={24}/>
							<Styles.Text $kind={"heading02"} as={"span"}>
								{label}
							</Styles.Text>
						</Styles.Item>))}
			</Styles.Container>);
    }
};
export default ProfileMenu;
