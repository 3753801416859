import PropTypes from "prop-types";
import React from "react";
import Button from "@components/Button/Button";
import getTranslation from "@translation/translation";
import styles from "./ModalComponents.scss";

const ModalFooter = ({
	cancelModalEventHandler,
	cancelText,
	cancelTracking,
	submitDisabled,
	submitEventHandler,
	submitLoading,
	submitText,
	submitTracking,
}) => cancelModalEventHandler || submitEventHandler
	? (
		<div
			data-component="ModalFooter"
			styleName="styles.modal-footer"
		>
			{
				cancelModalEventHandler && (
					<Button
						tracking={cancelTracking}
						style="flatNeutral"
						size="medium"
						clickHandler={cancelModalEventHandler}
					>
						{cancelText}
					</Button>
				)
			}
			{
				submitEventHandler && (
					<Button
						tracking={submitTracking}
						disabled={submitDisabled}
						size="medium"
						clickHandler={submitEventHandler}
						loading={submitLoading}
					>
						{submitText}
					</Button>
				)
			}
		</div>
	)
	: null;

ModalFooter.propTypes = {
	cancelModalEventHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	cancelTracking: PropTypes.object,
	submitDisabled: PropTypes.bool,
	submitEventHandler: PropTypes.func,
	submitLoading: PropTypes.bool,
	submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	submitTracking: PropTypes.object,
};

ModalFooter.defaultProps = {
	cancelModalEventHandler: false,
	cancelText: getTranslation("frontend.generics.cancel", true),
	cancelTracking: null,
	submitDisabled: false,
	submitEventHandler: () => {},
	submitLoading: false,
	submitText: getTranslation("frontend.generics.submit", true),
	submitTracking: null,
};

export default ModalFooter;
