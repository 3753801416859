import styled, { keyframes } from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { animationDuration } from "@components/Modal/Modal.styles";
import { applyZIndex } from "@frontend/styled";
export const fadeIn = keyframes `
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
export const fadeOut = keyframes `
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;
export const StyledModalBackground = styled.div `
	position: fixed;
	${applyZIndex("modal-background")};
	top: ${p => p.theme.layout.topNavHeight};
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	animation: ${fadeIn} ${animationDuration}ms ease-out;

	${StyledMobileAndTabletPortraitQuery} {
		background-color: ${p => p.theme.color.uiBackground});
		top: ${p => p.theme.layout.topNavHeightMobile};
	}

	&.card {
		${applyZIndex("card-background")};
	}

	&.fade-out {
		animation: ${fadeOut} 0.3s ease-out;
	}

	&.menu {
		background-color: transparent;
		${applyZIndex("menu-background")};
	}

	&.over-the-top {
		${applyZIndex("over-the-top")};
		top: 0;
	}

	&.sponsored {
		background-color: rgba(0, 0, 0, 0.96);
	}

	&.tour {
		mix-blend-mode: hard-light;
		top: 0;
		${applyZIndex("tour")};
	}
`;
