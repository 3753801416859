import { Button, Checkbox, useForm, ChevronDownSmallSVG } from "@sermo/ui-components";
import React, { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { PostDataActionTypes } from "@contexts/PostData";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { fetchUrl } from "../../../Utils";
import {
	ButtonWrap,
	CheckboxText,
	CheckboxWrap,
	ExpandWrap,
	Header,
	InfoHeader,
	InfoText,
	InputsWrap,
	Subheader,
	Wrap,
} from "./AdForm.styled";
import { getAdFormValues } from "./AdForm.utils";
import { setupForm } from "./AdForm.validation";
import AddressInputs from "./components/AddressInputs";
import AdFormConfirmation from "./components/AdFormConfirmation";
import AdFormInput from "./components/AdFormInput";
import AdFormPhone from "./components/AdFormPhone";
import SpecialtyDropdown from "./components/SpecialtyDropdown";

const AdForm = () => {
	const apiEndpoint = useApiEndpoint();
	const trackEvent = useTrackEvent();
	const isPreview = useMatch("/ad-preview/:adId/*");

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adForm,
		adId,
		dispatch: dispatchPostData,
		clickTags,
	} = useMemoizedContext("postData", [
		"adForm",
		"adId",
		"clickTags",
	]);

	const {
		fields = [],
		id: adFormId,
		isSubmitted,
		preChecked,
		header,
		subHeader,
		consentText,
	} = adForm;

	const form = useForm(setupForm(fields));
	const [isEditing, setIsEditing] = useState(false);
	const [isChecked, setIsChecked] = useState(preChecked ?? false);
	const [isExpanded, setIsExpanded] = useState(expanded ?? false);
	const [isLoading, setIsLoading] = useState(false);
	const [allowSubmit, setAllowSubmit] = useState(!isSubmitted && isChecked);

	const checkForNoErrors = () => {
		const errors = Object.values(form.errors);
		return errors.every((error) => error.length === 0);
	}

	useEffect(() => {
		if (!isSubmitted && isChecked && checkForNoErrors()) setAllowSubmit(true);
		else setAllowSubmit(false);
	}, [isSubmitted, isChecked, form.errors, checkForNoErrors]);

	const {
		addressFields,
		basicFields,
		customFields,
		fieldsWithoutValue,
		fieldsWithValue,
	} = getAdFormValues(fields);

	useEffect(() => {
		if (fieldsWithValue.length === 0) setIsEditing(true);
	}, []);

	if (isSubmitted) {
		return <AdFormConfirmation/>;
	}

	const getCountryFieldString = () => addressFields?.find((field) => field.text === "Country")?.value;
	const getJoinedAddressFieldsString = () => addressFields?.reduce((acc,cur) => {
		if (cur.text !== "Country") acc.push(cur.value);
		return acc;
	}, []).join(", ");

	const showAddressFieldsInputs = !addressFields?.every((field) => field.value);
	const showExpandButton = !isExpanded
		&& !isEditing
		&& (fieldsWithoutValue?.length + customFields?.length > 1 || showAddressFieldsInputs);

	const adFormTrack = (label, action = "click") => {
		trackEvent({
			category: "lead-gen",
			label,
			action,
			area: label,
			adId,
		});
	}

	const handleEditClick = () => {
		setIsEditing(!isEditing);
		adFormTrack("edit");
	}
	const handleCheckboxClick = (e) => {
		setIsChecked(e.target.checked);
		adFormTrack("terms", e.target.checked
			? "accept-click"
			: "uncheck-click");
	}
	const handleExpandClick = () => {
		setIsExpanded(true);
		adFormTrack("expand");
	}
	const handleSubmitClick = () => {
		if (
			!isPreview
			&& form.validate()
		) {
			setIsLoading(true);
			adFormTrack("submit");
			if (clickTags?.formSubmit) {
				clickTags.formSubmit?.forEach((url) => {
					if (url) {
						fetchUrl(url);
					}
				})
			}
			const values = { adFormId, adId };

			Object.keys(form.values)?.forEach((key) => {
				if (
					form.values?.[key]
					&& form.values?.[key] !== fields.find((field) => field.adFormFieldType === key)?.value
					// ^prevent sending the original value of the field, the BE already has it
					&& "boolean" !== typeof form.values?.[key]
					// ^prevent sending the boolean value of the phone number is valid field
				) {
					values[key] = form.values?.[key]?.trim();
					const fieldSignifier = key?.toLowerCase();
					trackEvent({
						category: "lead-gen",
						label: fieldSignifier,
						action: "edit",
						area: fieldSignifier,
						adId,
					});
				}
			})

			apiEndpoint("form/saveanswer", values)
				.then((dataResponse) => {
					dataResponse.json().then((data) => {
						setIsLoading(false);
						dispatchPostData({
							type: PostDataActionTypes.SET_AD_FORM,
							payload: {
								adForm: {
									...adForm,
									...data,
									isSubmitted: true,
								},
							},
						});
					})
				});
		} else if (
			isPreview
			&& form.validate()
		) {
			dispatchPostData({
				type: PostDataActionTypes.SET_AD_FORM,
				payload: {
					adForm: {
						...adForm,
						isSubmitted: true,
					},
				},
			});
		}

		setAllowSubmit(false);
	}

	const renderInputs = (fields) => fields?.map((field, index) => {
		if ("Specialty" === field.adFormFieldType) {
			return (
				<SpecialtyDropdown
					data={field}
					form={form}
					key={index}
				/>
			);
		}

		if (["PhoneNumber", "MobileNumber"].includes(field.adFormFieldType)) {
			return (
				<AdFormPhone
					data={field}
					form={form}
					key={index}
				/>
			);
		}

		return (
			<AdFormInput
				data={field}
				form={form}
				key={index}
			/>
		);
	})

	return (
		<Wrap
			expanded={!showExpandButton}
			onClick={(e) => e.stopPropagation()}
		>
			<Header>{header}</Header>
			{subHeader && <Subheader>{subHeader}</Subheader>}
			{
				!isEditing && (
					<>
						<InfoHeader>
							{getTranslation("frontend.generics.yourInformation", true)}
							<Button
								kind="flat"
								onClick={handleEditClick}
								disabled={isSubmitted}
							>
								{getTranslation("frontend.generics.edit", true)}
							</Button>
						</InfoHeader>
						{fieldsWithValue?.map((field, index) => <InfoText key={index}>{field.value}</InfoText>)}
						{
							!showAddressFieldsInputs
							&& addressFields.length > 0
							&& (
								<>
									<InfoText>{getJoinedAddressFieldsString()}</InfoText>
									<InfoText>{getCountryFieldString()}</InfoText>
								</>
							)
						}
					</>
				)
			}
			{
				!isEditing
				&& (
					<InputsWrap>
						{renderInputs(fieldsWithoutValue)}
						{
							showAddressFieldsInputs && (
								<AddressInputs
									data={addressFields}
									form={form}
								/>
							)
						}
						{renderInputs(customFields)}
					</InputsWrap>
				)
			}
			{
				isEditing && (
					<InputsWrap>
						{renderInputs(basicFields)}
						<AddressInputs
							data={addressFields}
							form={form}
						/>
						{renderInputs(customFields)}
					</InputsWrap>
				)
			}
			<CheckboxWrap>
				<Checkbox
					checked={isChecked}
					onClick={handleCheckboxClick}
				/>
				<CheckboxText dangerouslySetInnerHTML={{ __html: consentText }}/>
			</CheckboxWrap>
			<ButtonWrap>
				<Button
					onClick={handleSubmitClick}
					disabled={!allowSubmit || isSubmitted}
					processing={isLoading}
				>
					{getTranslation("frontend.generics.submit", true)}
				</Button>
			</ButtonWrap>
			{
				showExpandButton && (
					<ExpandWrap>
						<Button
							kind="flat"
							icon={ChevronDownSmallSVG}
							iconPosition="right"
							onClick={handleExpandClick}
						>
							{getTranslation("frontend.generics.expandToSubmit", true)}
						</Button>
					</ExpandWrap>
				)
			}
		</Wrap>
	);
}

export default AdForm;