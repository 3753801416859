import { Icon, LoadingSpinnerSVG } from "@sermo/ui-components";
import classNames from "classnames"
import React, { useEffect,useRef, useState, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { useMemoizedContext } from "@hooks/Hooks";
import styles from "./PullToRefresh.scss";

const iconSize = 60;
const maxDragDistance = iconSize * 3;

const PullToRefresh = () => {
	const { setScrollLocked } = useMemoizedContext("scroll",[]);
	const isDesktop = useMediaQuery({ query:TabletLandscapeAndDesktopQuery });
	const [offset, setOffset] = useState(0);
	const [percentage, setPercentage] = useState(0);
	const [initialTouchY, setInitialTouchY] = useState(false);
	const [currentTouchY, setCurrentTouchY] = useState(false);
	const [rotating, setRotating] = useState(false);
	const [isPulling, setIsPulling] = useState(false);
	const offsetRef = useRef(offset);

	useEffect(() => {
		offsetRef.current = offset;
	},[offset])

	// handle touchStart
	useEffect(() => {
		const touchStartListener = (e) => {
			// only set the initial touch value at the top of the page
			// to avoid running the rest of this logic unnecessarily
			if ( window.scrollY === 0 ){
				setInitialTouchY(e.changedTouches[0].clientY);
			}
		}

		window.addEventListener("touchstart", touchStartListener);

		return () => {
			window.removeEventListener("touchstart", touchStartListener)
		}
	},[]);

	// handle touchMove
	useEffect(() => {
		const touchMoveListener = (e) => {
			if ( window.scrollY === 0 ){
				setCurrentTouchY( e.changedTouches[0].clientY);
			}
		}

		window.addEventListener("touchmove", touchMoveListener);

		return () => {
			window.removeEventListener("touchMove", touchMoveListener)
		}
	},[]);

	useEffect(() => {
		if ( rotating ) {
			window.location.reload();
		}
	},[rotating])

	// handle touchEnd
	useEffect(() => {
		const touchEndListener = (e) => {
			if ( offsetRef.current > maxDragDistance - 5 ) {
				setRotating(true);
			} else {
				if (isPulling) {
					setScrollLocked(false);
				}
				setInitialTouchY(false);
				setCurrentTouchY(false);
				setOffset(0);
				setPercentage(0);
			}
			setIsPulling(false);
		}
		window.addEventListener("touchend",touchEndListener);

		return () => {
			window.removeEventListener("touchend", touchEndListener)
		}
	},[]);

	const setValues = (newOffset, newPercentage) => {
		setOffset(newOffset);
		setPercentage(newPercentage);
	}

	useEffect(() => {
		// handle scrollLocking
		// only lock if init and cur are set (meaning the user has touched and dragged)
		// and the user is draging up
		// initial touch is only set if the window scrollY is 0 so this only happens at the top of the page
		if ( initialTouchY && currentTouchY && currentTouchY > initialTouchY ) {
			setScrollLocked(true);
			setIsPulling(true);
		}

		// update indicator position
		if ( initialTouchY !== false && currentTouchY !== false ){
			if ( offset <= maxDragDistance) {
				const newOffset = (() => {
					const o = currentTouchY - initialTouchY;
					if ( o <= maxDragDistance ) {
						return o;
					}
					return maxDragDistance;
				})();

				let newPercentage = percentage;

				if ( newOffset <= maxDragDistance ) {
					let cur = currentTouchY;
					if ( cur < initialTouchY ) {
						cur = initialTouchY;
					}
					if ( cur > initialTouchY + maxDragDistance ) {
						cur = initialTouchY + maxDragDistance;
					}
					const percentOfMaxLength = ((cur - initialTouchY) / maxDragDistance) ;
					newPercentage = percentOfMaxLength;
				}

				requestAnimationFrame(() => {setValues(newOffset, newPercentage)});
			}
		}
	},[initialTouchY, currentTouchY])

	return (useMemo(() => (
		!isDesktop
			&&			<div
				styleName={classNames({ "styles.dragging":initialTouchY })}
			>
				<div
					styleName="styles.event-capture"
					style={
						{
							opacity: percentage / 2,
						}
					}
				/>
				<div
					styleName={classNames("styles.pull-to-refresh")}
					style={
						{
							width:`${iconSize}px`,
							height:`${iconSize}px`,
						}
					}
				>
					<div
						styleName={classNames("styles.indicator",{ "styles.dragging":initialTouchY })}
						style={
							{
								width: `${iconSize}px`,
								height: `${iconSize}px`,
								transform: `translateY(${offset}px)`,
							}
						}
					>
						<div
							styleName={classNames("styles.rotator",{ "styles.rotating":rotating })}
							style={
								{
									transform:`rotate(${percentage * 360}deg)`,
								}
							}
						>
							<Icon
								src={LoadingSpinnerSVG}
							/>
						</div>
					</div>
				</div>
			</div>
	),[offset])
	);
}

export default PullToRefresh;
