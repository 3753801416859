import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useExpand } from "@components/PostViewer/components/CardContainer/utils/useExpandEffect";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useTrackEvent } from "@frontend/tracking";
import { AdTypeTypes, CardTypeTypes, PostTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useGetPostTrackingCategory, useMemoizedContext, usePrevious } from "@hooks/Hooks";
const useHandleCardClick = ({ onClick }) => {
    const adPreviewMatch = useMatch("/ad-preview/:routeAdId/*");
    const category = useGetPostTrackingCategory();
    const expand = useExpand();
    const location = useLocation();
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();
    const { draftStatus } = useMemoizedContext("postUI", ["draftStatus"]);
    const editing = DraftStatusTypes.EDITING === draftStatus;
    const { dispatch: dispatchPostUIViewing, eventSource, expanded, } = useMemoizedContext("postUIViewing", [
        "eventSource",
        "expanded",
    ]);
    const expandedPrevious = usePrevious(expanded);
    const { adFrequency, adId, adType, cardType, id: postId, sermoContentCardId, type, } = useMemoizedContext("postData", [
        "adFrequency",
        "adId",
        "adType",
        "cardType",
        "id",
        "sermoContentCardId",
        "type",
    ]);
    const trackLinkClick = (e) => {
        if ("A" === e.target.tagName) {
            if (e.target.hasAttribute("href")) {
                const href = e.target.getAttribute("href");
                let area = e.eventSource || eventSource;
                let action = "click";
                let label = "link";
                const title = e.target.textContent || e.target.temporalDivElement;
                if (area) {
                    if (typeof area !== "string") {
                        action = "download";
                        label = area.document;
                        area = area.area;
                    }
                }
                trackEvent({
                    category,
                    action,
                    label,
                    area,
                    url: href,
                    urlTitle: title,
                    postId,
                    adId,
                    adFrequency,
                    expanded,
                    sermoContentCardId,
                });
            }
        }
    };
    return (e) => {
        // TODO: look into resetting the event source, at some point
        dispatchPostUIViewing({
            type: PostUIViewingActionTypes.SET_EVENT_SOURCE,
            payload: {
                eventSource: e.eventSource,
            },
        });
        trackLinkClick(e);
        if (!expanded
            && "A" !== e.target.tagName) {
            if (editing
                || AdTypeTypes.LIVE_EVENT === adType
                || PostTypeTypes.QUESTION === type
                || [
                    CardTypeTypes.COMMENT_GROUP,
                    CardTypeTypes.RESOURCE_CENTER,
                    CardTypeTypes.RESOURCE_CENTER_ITEM,
                ].includes(cardType)) {
                onClick && onClick(e);
                return;
            }
            const canExpandPost = location.pathname.match(/^\/feed|\/profile|\/search/);
            const pathnameWithoutPost = location.pathname.replace(/\/post\/\d+(\/.+)?/, "");
            const navState = { state: { background: location } };
            if (adPreviewMatch) {
                navigate(`/ad-preview/${adId}/expanded${location.search}`, navState);
            }
            else if (canExpandPost) {
                navigate(`${pathnameWithoutPost}/post/${postId}/expanded${location.search}`, navState);
            }
            else {
                navigate(`feed/all-sermo/post/${postId}/expanded`, navState);
            }
            const commentsClicked = (e.target.getAttribute("data-id") === "commentsButton");
            expand({
                commentsClicked,
            });
            let area = e.eventSource || eventSource || "expand-post";
            // Only track expand if the post was previously not expanded
            // this prevents expand events when deep linking to a post.
            if (!expandedPrevious) {
                trackEvent({
                    category,
                    action: "click",
                    label: "in-feed",
                    postId,
                    adId,
                    adFrequency,
                    expanded: true,
                    area,
                    sermoContentCardId,
                });
            }
            onClick && onClick(e);
        }
    };
};
export default useHandleCardClick;
