import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import Footer from "@components/Footer/Footer";
import { CustomDropDownList } from "@components/FormFields/FormFields";
import { ErrorBoundary } from "@components/Helpers/Helpers";
import Rail from "@components/Layout/components/Rail";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import SurveysMenu from "@components/Menus/SurveysMenu";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import MdHonorsTeaser from "@components/RailCards/components/MdHonorsTeaser/MdHonorsTeaser";
import MemberBalance from "@components/RailCards/components/MemberBalance/MemberBalance";
import ResourceCenter from "@components/RailCards/components/ResourceCenter/ResourceCenter";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import AvailableSurveys from "../Surveys/components/AvailableSurveys/AvailableSurveys";
import SurveyHistory from "../Surveys/components/SurveyHistory/SurveyHistory";
import WithdrawalHistory from "../Surveys/components/WithdrawalHistory/WithdrawalHistory";
import * as Styles from "./SurveysLayout.styles";

const EmptyLoading = () => <></>;

const LoadableEvents = lazy(() => import(
	/* webpackChunkName: "Events" */
	"@components/RailCards/components/Events/Events"
));

const LoadableInviteColleagues = lazy(() => import(
	/* webpackChunkName: "InviteColleagues" */
	"@components/RailCards/components/InviteColleagues/InviteColleagues"
));

export const feedFilterPossibleOptions = [
	{
		claim: "canAccessFrontendSurveys",
		name: getTranslation("frontend.surveys.navigation.availableSurveys"),
		value: "availablesurveys",
		tracking: {
			category: "surveys-left-rail",
			action: "click",
			label: "available-surveys",
		},
		rightsRequired: ["canAccessFrontendSurveys"],
	},
	{
		claim: "canAccessFrontendSurveys",
		name: getTranslation("frontend.surveys.navigation.surveyHistory"),
		value: "surveyhistory",
		tracking: {
			category: "surveys-left-rail",
			action: "click",
			label: "survey-history",
		},
		rightsRequired: ["canAccessFrontendSurveys"],
	},
	{
		claim: "canAccessFrontendSurveys",
		name: getTranslation("frontend.surveys.navigation.withdrawalHistory"),
		value: "withdrawalhistory",
		tracking: {
			category: "surveys-left-rail",
			action: "click",
			label: "withdrawal-history",
		},
		rightsRequired: ["canAccessFrontendSurveys"],
	},
];

export const DropNav = () => {
	const [redirect, setRedirect] = useState(false);
	const { section } = useParams();
	const { hasAccess } = useMemoizedContext("member", ["hasAccess"]);
	const feedFilterOptions = feedFilterPossibleOptions.filter(option => hasAccess(option.claim));

	const onChangeFilter = (value) => {
		setRedirect(`/surveys/${value}`);
	};

	useEffect(() => {
		if (redirect) {
			setRedirect(false);
		}
	}, [redirect]);

	return (
		<MobileAndTabletPortrait>
			<Styles.SurveysDropNavContainer>
				{redirect && <Navigate to={redirect} />}
				<ModuleWrapper
					padded={false}
					solid={true}
				>
					{/* TODO: In the near future, replace CustomDropDownList with <Select /> component */}
					<CustomDropDownList
						value={section}
						options={feedFilterOptions}
						handleChange={onChangeFilter}
						name="survey-mobile-nav"
						style="flatNeutral"
					/>
				</ModuleWrapper>
			</Styles.SurveysDropNavContainer>
		</MobileAndTabletPortrait>
	);
};

const SurveysLayout = () => {
	const { hasAccess } = useMemoizedContext("member", ["hasAccess"]);

	return (
		<Styles.SurveysLayoutWrap data-component={"SurveysLayout"}>
			<Rail side={"left"}>
				<SurveysMenu/>
			</Rail>
			<Routes>
				<Route
					path="availablesurveys"
					element={
						<>
							<Styles.SurveysPageWithRightRailWrap>
								<MobileAndTabletPortrait>
									<ErrorBoundary>
										{!hasAccess("canAccessFrontendPulseFeed") && <ResourceCenter/>}
									</ErrorBoundary>
									<ErrorBoundary>
										{!hasAccess("canAccessFrontendPulseFeed") && <LoadableInviteColleagues/>}
									</ErrorBoundary>
								</MobileAndTabletPortrait>
								<AvailableSurveys/>
							</Styles.SurveysPageWithRightRailWrap>
							<Rail side={"right"}>
								<ErrorBoundary>
									{!hasAccess("canAccessFrontendPulseFeed") && <ResourceCenter/>}
								</ErrorBoundary>
								<ErrorBoundary>
									<MemberBalance trackingCategory="surveys"/>
								</ErrorBoundary>
								<ErrorBoundary>
									<MdHonorsTeaser trackingCategory="surveys"/>
								</ErrorBoundary>
								<Suspense fallback={<EmptyLoading/>}>
									<ErrorBoundary>
										{!hasAccess("canAccessFrontendPulseFeed") && <LoadableEvents/>}
									</ErrorBoundary>
								</Suspense>
								{
									!hasAccess("canAccessFrontendPulseFeed")
									&& <ErrorBoundary>
										<LoadableInviteColleagues trackingCategory="feed"/>
									</ErrorBoundary>
								}
							</Rail>
						</>
					}
				/>
				<Route
					path="surveyhistory"
					element={
						<Styles.SurveysPageWrap>
							<SurveyHistory/>
						</Styles.SurveysPageWrap>
					}
				/>
				<Route
					path="withdrawalhistory"
					element={
						<Styles.SurveysPageWrap>
							<WithdrawalHistory/>
						</Styles.SurveysPageWrap>
					}
				/>
			</Routes>
		</Styles.SurveysLayoutWrap>
	);
}

export default SurveysLayout;
