import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const EnrolledProgramContainer = styled.div`
	padding: 16px 0 0;
	display: flex;
	align-items: center;

	& > * {
		color: ${({ theme }) => theme.color.text03};
	}

	& > svg {
		margin-right: 4px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		padding: 4px 0 0;
	}
`;