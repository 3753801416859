import { Input, Modal, Button, ButtonGroup } from "@sermo/ui-components";
import React, { useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { isUrl } from "@frontend/Utils";
import getTranslation from "@translation/translation";
import { ModalContainer } from "./Editor.styles";
import { getActiveNodeLink, getSelection } from "./helper";
export const EditorLinkModal = ({ onRequestClose, onSubmit }) => {
    const editor = useSlate();
    const [display, setDisplay] = useState(() => getSelection());
    const [url, setUrl] = useState("");
    const [validUrl, setValidUrl] = useState(false);
    useEffect(() => {
        if (url) {
            setValidUrl(isUrl(url));
        }
    }, [url]);
    useEffect(() => {
        const activeNodeLink = getActiveNodeLink(editor);
        if (!activeNodeLink) {
            return;
        }
        const { children, url } = activeNodeLink;
        setUrl(url);
        setDisplay(children[0].text);
    }, [editor]);
    const handleUrlChange = (e) => {
        const url = e.target.value;
        setUrl(url);
    };
    const handleSubmit = () => {
        let protocol = "";
        if (url.indexOf("http") === -1) {
            protocol = "https://";
        }
        if (onSubmit) {
            onSubmit({
                url: `${protocol}${url}`,
                display,
            });
        }
    };
    return (<Modal open onRequestClose={onRequestClose} header="Hyperlink" footer={<ButtonGroup align="end">
					<Button kind="flat" onClick={onRequestClose}>
						{getTranslation("frontend.generics.cancel", true)}
					</Button>
					<Button kind="primary" onClick={handleSubmit} disabled={!display || !validUrl}>
						{getTranslation("frontend.generics.submit", true)}
					</Button>
				</ButtonGroup>}>
			<ModalContainer>
				<Input name="display-text" label={getTranslation("frontend.modals.insertLink.displayText", true)} value={display} onChange={(e) => setDisplay(e.target.value)} autoFocus/>
				<Input name="url" type="url" value={url} label={getTranslation("frontend.generics.url", true)} onChange={handleUrlChange} errors={url.length && !validUrl
            ? ["Invalid URL"]
            : undefined}/>
			</ModalContainer>
		</Modal>);
};
