import { Icon, IconButton, Select } from "@sermo/ui-components";
import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookOpenCoverRegular from "@assets/images/book-open-cover-regular.svg";
import Bookmark from "@assets/images/bookmark.svg";
import BullseyeArrow from "@assets/images/bullseye-arrow.svg";
import { default as FiltersSVG } from "@assets/images/Filters.svg";
import Globe from "@assets/images/globe.svg";
import RankingStar from "@assets/images/ranking_star.svg";
import { CustomizeContainer, FeedHeaderContainer, FeedHeaderContent, OptionContainer, SelectContainer, SelectorIcon, SelectorLabel } from "@components/Feed/Feed.styles";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { useTrackEvent } from "@frontend/tracking";
import getTranslation from "@translation/translation";
import FilterMenu from "./FilterMenu";
export const capitalizeFirstLetterOfEachWordExceptTheFirstWord = (string) => string.split("-")
    .map((word, index) => {
    if (0 === index) {
        return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
})
    .join("");
export const feedFilterOptions = [
    {
        value: "allposts",
        label: getTranslation("frontend.generics.allPosts", true),
        description: getTranslation("frontend.generics.allPostsDescription", true),
        icon: Globe,
    },
    /* TODO: Implement customize functionality to uncomment */
    /* {
        value: "foryou",
        label: getTranslation("frontend.generics.forYou", true),
        description: getTranslation("frontend.generics.forYouDescription", true),
        icon: BullseyeArrow,
    },
    {
        value: "recaps",
        label: getTranslation("frontend.generics.recaps", true),
        description: getTranslation("frontend.generics.recapsDescription", true),
        icon: BullseyeArrow,
    },*/
    {
        value: "topposts",
        label: getTranslation("frontend.generics.topPosts", true),
        description: getTranslation("frontend.generics.topPostsDescription", true),
        icon: RankingStar,
    },
];
const FeedHeader = ({ filter, filterChangeHandler = () => { }, }) => {
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();
    const filterButtonRef = useRef();
    const [menuOpen, setMenuOpen] = useState(false);
    const { topic } = useParams();
    // only all posts and top posts show the filters
    const shouldShowFilters = useMemo(() => (!topic
        && (filter === "allposts"
            || filter === "topposts")), [
        filter,
        topic,
    ]);
    const selectedIcon = useMemo(() => {
        if (topic) {
            if (topic === "saved") {
                return Bookmark;
            }
            return BookOpenCoverRegular;
        }
        return feedFilterOptions.filter((option) => option.value === filter)[0].icon || BullseyeArrow;
    }, [topic, filter]);
    const onChangeFilter = (event) => {
        filterChangeHandler(event.target.value);
        const labels = { "allposts": "all-posts", "topposts": "top-posts" };
        trackEvent({
            category: "post-feed-change-feed",
            action: "click",
            label: labels[event.target.value]
        });
        if (topic) {
            navigate("/feed/all-sermo");
        }
    };
    const renderOption = (option) => (<OptionContainer $isSelected={option.value === filter}>
			<SelectorIcon src={option.icon} height={24} width={24} $isSelected={!topic && option.value === filter}/>
			<SelectorLabel $isSelected={!topic && option.value === filter}>
				<span className={"title heading-02"}>{option.label}</span>
				<span className={"description body-long-00"}>{option.description}</span>
			</SelectorLabel>
		</OptionContainer>);
    const renderValue = () => {
        if (topic) {
            const topicLabel = capitalizeFirstLetterOfEachWordExceptTheFirstWord(topic);
            return getTranslation(`system.topics.${topicLabel}`, true);
        }
        return feedFilterOptions.filter((option) => option.value === filter)[0].label;
    };
    return (<TabletLandscapeAndDesktop>
			<FeedHeaderContainer data-component={"Feeds"}>
				<FeedHeaderContent>
					{<SelectContainer>
							<Icon src={selectedIcon} height={24} width={24}/>
							<Select inline size={"small"} value={topic || filter} onChange={onChangeFilter} placeholder={getTranslation("frontend.generics.forYou", true)} options={feedFilterOptions} renderOption={renderOption} renderValue={renderValue} autoWidth/>
						</SelectContainer>}
					<CustomizeContainer>
						{/* TODO: Implement customize functionality to uncomment */}
						{/*
        <TextStyled
            $kind="bodyShort00"
            className="comment-wrapper"
        >Customize</TextStyled>
        */}
					</CustomizeContainer>
					{shouldShowFilters
            && <>
							<IconButton kind="flatNeutral" size="xsmall" icon={FiltersSVG} ref={filterButtonRef} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMenuOpen(!menuOpen);
                }}/>
							<FilterMenu triggerRef={filterButtonRef} open={menuOpen} setMenuOpen={setMenuOpen}/>
						</>}
				</FeedHeaderContent>
			</FeedHeaderContainer>
		</TabletLandscapeAndDesktop>);
};
export default FeedHeader;
