import { ColorGrey30 } from "@sermo/design-tokens"
import { Icon, DiscussionSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { CommentsStylesContainer } from "@components/Card/components/Comments/Comments.styles";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { PostTypeProps } from "@props/Post";

const NoCommentIcon = styled(Icon)`
		width: 50px;
		height: 50px;
		margin-bottom: 16px;

		svg {
			fill: ${ColorGrey30};
		}
`;

const NoAnswers = ({ expanded, type }) => (
	<ModuleWrapper
		solid={true}
		padded={false}
		paddedWider={!expanded}
		paddedWidest={expanded}
		contextClass={
			expanded
				? "post-creation-footer"
				: ""
		}
	>
		<CommentsStylesContainer>
			<TextStyled
				className="no-comments"
				$kind="heading03"
			>
				<NoCommentIcon src={DiscussionSVG}/>
				{type === "Question" && <span>No one has answered yet</span>}
				{type !== "Question" && <span>No one has commented yet</span>}
			</TextStyled>
		</CommentsStylesContainer>
	</ModuleWrapper>
);

NoAnswers.propTypes = {
	expanded: PropTypes.bool,
	type: PropTypes.oneOf(PostTypeProps),
};

export default NoAnswers;
