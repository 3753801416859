import { Button } from "@sermo/ui-components";
import styled from "styled-components";
export const CustomButton = styled(Button) `
	&&& {
		margin-bottom: 8px;
		border-radius: 8px;
		padding: 8px 20px 8px 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		align-self: stretch;
		svg {
			margin: 0;
		}
		span {
			width: auto;
		}
	}
`;
