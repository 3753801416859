import React from "react";

// these default states are just to describe the shape of the data
// and will all be overwritten when the Provider is created

export const Member = React.createContext({
	loggedIn: false,
	active: true,
	logoutMember: () => {},
	updateMember: () => {},
	hasAccess: () => {},
	formatMemberForFeed: () => {},
	memberTopicFollow: () => {},
	memberTopicUnfollow: () => {},
	memberFollow: () => {},
	memberUnfollow: () => {},
	id: 0,
	profilePic: "",
	username: "",
	firstName: "",
	lastName: "",
	email: "",
	locale: "en-US",
	countryCode: "",
	countryName: "",
	joinDate: "",
	displayName: "",
	primarySpecialtyId: 0,
	primarySpecialtyName: "",
	secondarySpecialtyId: 0,
	secondarySpecialtyName: "",
	hasPreferredStatus: false,
	userType: "",
	hasPendingDeactivationRequest: false,
	daysUntilDeactivation: 0,
	anonymous: false,
	hasConfiguredProfile: false,
	hasSetPassword: false,
	surveyOutcome: "",
	surveyOutcomeIncentiveAmount: 0,
	surveyOutcomeIncentiveCurrency: "",
	legacyUser: false,
	showTourGuide: false,
	brandAdministrator: false,
	trialMember: false,
	affiliateMember: false,
	hasSeenTrialMemberPopup: false,
	hasSeenReadOnlyMemberPopup: false,
	hasSeenPostSurveyReferralPopup: false,
	hasSeenReverificationPopup: false,
	hasAcceptedTermsAndConditions: false,
	hasAccessToAdPreview: false,
	hasAccessToPreview: false,
	icdCodes: "",
	m2h: false,
	numberOfUnreadMessages: 0,
	affiliation: "",
	followedTopics: [],
	showLeadLandingPage: false,
	rights: [],
	followedMembers: [],
	verified: false,
	hasAccessToCovid19Barometer: false,
	contactType: "unknown",
	contactSource: null,
	isReverificationRequired: false,
	readOnlyMember: false,
	isPostSurveyReferral: false,
	wasPostSurveyReferralVerified: false,
	memberSessionId: "",
	hasBeenAuthenticatedViaToken: false,
	shouldRefreshMemberNotifications: false,
	placeOfEmployment: "",
	isTemporarilyAuthenticated: false,
	isLoyaltyProgramAccepted: undefined,
	onboardingCompleted: true,
	isFirstPageLoad: false,
	privacyPolicy: undefined,
	showFirstSurveyPendingVerificationPage: false,
	worksAtSermo: false,
});

export const ScrollContext = React.createContext({
	el: false,
	scrollLocked: false,
	leftRailHeight: 0,
	rightRailHeight: 0,
	contentMinHeight: 0,
	x: 0,
	y: 0,
	setLeftRailHeight: () => {},
	setRightRailHeight: () => {},
	setElement: () => {},
	setScrollLocked: () => {},
	updateX: () => {},
	updateY: () => {},
});

export const MessagesContext = React.createContext({
	memberIdState: null,
	aIdState: null,
	messageListThreads: [],
	setMemberIdState: () => {},
	setAIdState: () => {},
	setMessageListThreads : () => {},
	setIdByType: () => {},
})
