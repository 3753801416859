import { Icon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const StyledAttendees = styled(TextStyled)`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.color.text03};
	margin-top: 4px;

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 8px;
	}
`;

export const StyledCheck = styled(Icon)`
  color: #6200ea;
  margin-right: 8px;
`;
