import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import useExpandEffect from "@components/PostViewer/components/CardContainer/utils/useExpandEffect";
import useHandleCardClick from "@components/PostViewer/components/CardContainer/utils/useHandleCardClick";
import useOnScroll from "@components/PostViewer/components/CardContainer/utils/useOnScroll";
import useShowPublishedAlertEffect from "@components/PostViewer/components/CardContainer/utils/useShowPublishedAlertEffect";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import { StylesContainer } from "./CardContainer.styles";
const CardContainer = ({ children, onClick, }) => {
    const el = useRef(null);
    const handleCardClick = useHandleCardClick({ onClick });
    const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
    const onScroll = useOnScroll({ el });
    const { draftStatus } = useMemoizedContext("postUI", ["draftStatus"]);
    const composing = DraftStatusTypes.COMPOSING === draftStatus;
    const editing = DraftStatusTypes.EDITING === draftStatus;
    const { footerHeight, headerHeight, } = useMemoizedContext("ui", [
        "footerHeight",
        "headerHeight",
    ]);
    const { expanded, isSingleCard, } = useMemoizedContext("postUIViewing", [
        "expanded",
        "isSingleCard",
    ]);
    const { brandStyle, cardType, id: postId, } = useMemoizedContext("postData", [
        "brandStyle",
        "cardType",
        "id",
    ]);
    const className = useMemo(() => classNames("card", {
        [`card-${postId}`]: postId,
        "expanded-card-container": expanded && CardTypeTypes.RESOURCE_CENTER_ITEM !== cardType,
        editing,
        [brandStyle]: brandStyle,
        mobile: isMobileOrTabletPortrait,
    }), [
        brandStyle,
        cardType,
        editing,
        expanded,
        isMobileOrTabletPortrait,
        postId,
    ]);
    // hooks that implement useEffect logic, thus trimming down the logic in the component
    useShowPublishedAlertEffect({ el });
    useExpandEffect();
    if (composing) {
        return <>{children}</>;
    }
    return (<StylesContainer $footerHeight={footerHeight} $headerHeight={headerHeight}>
			<div className={className} data-component={"Card"} onClick={handleCardClick} onScroll={onScroll} onTouchMove={onScroll} ref={el}>
				<ModuleWrapper solid={true} border={true} padded={false} contextClass={isSingleCard
            ? "card-wrapper-single"
            : "card-wrapper"}>
					{children}
				</ModuleWrapper>
			</div>
		</StylesContainer>);
};
export default CardContainer;
