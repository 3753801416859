import { ColorGrey10, ColorWhite } from "@sermo/design-tokens";
import { IconButton } from "@sermo/ui-components";
import styled, { css } from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorderDarker, applyZIndex } from "@frontend/styled";
export const Container = styled.div `
	${props => props.$closeHeight && css `
		height: ${props.$closeHeight}px;
	`};
	overflow-y: hidden;
	margin: 0 auto;
	padding: 0;
	position: relative;

	& > div {
		// Default background color can be overridden by passing a customStyle prop
		background-color: ${ColorWhite};
	}

	&.open {
		${props => props.$openHeight && css `
			height: ${props.$openHeight}px;
		`};

		> div {
			height: 100%;
			> div {
				overflow: auto;
			}
		}
	}

	${props => props.$makeFooterDisappear && css `
		display: none;
	`};
`;
export const CollapseButton = styled(IconButton) `
	position: absolute;
	top: 4px;
	right: 12px;
`;
export const CustomStyled = styled.div `
	display: flex;
	flex-direction: column;
	height: 100%;
	// default and largerWithoutBackground styles.
	${(props) => !props.customStyle && css `
		& > .default {
			font-family: Graphik, serif;
			font-size: 11px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px;
			background-color: ${ColorGrey10};
		}

		& > .largerWithoutBackground {
			background-color: ${({ theme }) => theme.color.uiBackground};
			color: ${({ theme }) => theme.color.text01};
			max-height: 96px;
			${applyBorderDarker("top bottom")};
			padding: 16px;
			border-left-width: 0;
			border-right-width: 0;

			sup {
				top: -0.7em;
			}

			&.in-feed {
				max-height: 96px;
			}

			&.expanded {
				max-height: 400px;
				margin: 40px 0 25px;
				padding: 16px;
				border-left-width: 1px;
				border-right-width: 1px;

				&.above-poll {
					margin-top: 25px !important;
					margin-bottom: 40px !important;
				}

				&.on-top {
					margin-top: 25px;
					margin-bottom: 40px;
				}
			}
		}
	`};

	// Custom styles
	${props => props.customStyle && { ...props.customStyle }}
`;
export const CustomStyledExpandable = styled(CustomStyled) `
	&> div {
		// Default Styles for expandable footer
		padding: var(--cards-spacing);
		&.footnote, &.isi {
			// Avoids issues with custom styles
			margin: 0;
			max-height: none;
			flex: 1;
		}
	}
`;
export const ResourceCenterWrapper = styled(Container) `
	${StyledTabletLandscapeAndDesktopQuery} {
		padding: 0 16px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;
export const DirectMessageAdWrapper = styled(Container) `
	display: flex;
	justify-content: center;

	& strong, & b {
		font-weight: 600;
	}

	${props => "isi" === props.footerOpen && css `
	 	// Hides the closed Footnote under the Message Window Header when the ISI is open
	 	// to avoid seeing it with iOS/OSX scrolling bounce effect.
		& .close {
			display: none;
		}
	`};

	${StyledTabletLandscapeAndDesktopQuery} {
		& > div {
			width: calc(100% - var(--messages-list-width));
			max-width: calc(var(--site-max-width) - var(--messages-list-width));
			margin: 0 0 0 calc(var(--messages-list-width));
		}
	}
`;
export const ExpandedCardWrapper = styled(Container) `
	position: relative;
	width: var(--cards-width-expanded);
	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;
export const InFeedCardWrapper = styled(Container) `
	position: relative;
	${applyZIndex("stickyISI")};
	width: auto;
	margin: 0;
	${props => props.expandBottom && props.marginTopFix && `margin-top: ${props.marginTopFix};`}
	height: ${props => props.expandBottom && props.footerOpen
    ? "auto"
    : `${props.initialHeight}px`};
	overflow: visible;
	${props => props.isPadded && css `
		margin-left: calc(-1 * var(--cards-spacing));
    	margin-right: calc(-1 * var(--cards-spacing));
    	margin-bottom: 0 !important;
	`};

	> div {
		position: ${props => props.expandBottom
    ? "static"
    : "absolute"};
		width: 100%;
		bottom: 0;

		> div {
			height: 100%;
			overflow: hidden;
			> div {
				height: 100%;
			}
		}

		&.open {
			> div {
				height: 100%;
			}
			> button {
				display: ${props => props.expandBottom
    ? "block"
    : "none"};
			}
		}
	}

	> button {
		display: ${props => props.footerOpen && !props.expandBottom
    ? "block"
    : "none"};
		${applyZIndex("stickyISI")};
		top: -${props => props.openHeight - props.initialHeight - 4}px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: auto;
	}
`;
export const EventWrapper = styled(Container) `
	${StyledTabletLandscapeAndDesktopQuery} {
		margin-right: calc(var(--right-rail-width) + 16px);
		width: calc(var(--site-max-width) - var(--right-rail-width) - 16px);
		padding: 0 16px;
	}
`;
