import linkifyHtml from "linkifyjs/lib/linkify-html";
import { useCallback, useMemo } from "react";
import { deserialize, Editor, serialize } from "@components/Editor";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { MentionMemberOption, serializeMember, toOptions, useMentionMember } from "@components/MentionMember";
import useAttachmentAPI from "@contexts/Attachments/useAttachmentAPI";
import { PostDataActionTypes } from "@contexts/PostData";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
const EditorPortion = (props) => {
    const attachmentAPI = useAttachmentAPI();
    const { handleSearchMention, members, } = useMentionMember();
    const { publishing, } = useMemoizedContext("postUI", [
        "publishing",
    ]);
    const { attachments, contentHtml, dispatch: dispatchPostData, mentions, } = useMemoizedContext("postData", [
        "attachments",
        "contentHtml",
        "mentions",
    ]);
    const onChange = useCallback((value) => {
        if (value) {
            let html = serialize(value, serializeMember);
            // HANDLE TYPED LINKS
            html = linkifyHtml(html, {
                defaultProtocol: "https",
                target: "_blank",
                rel: "noreferrer",
            });
            dispatchPostData({
                type: PostDataActionTypes.SET_CONTENT_HTML,
                payload: {
                    contentHtml: html,
                }
            });
        }
    }, [dispatchPostData]);
    const publishingPrevious = usePrevious(publishing);
    // TODO: this may need a revision with post refactor
    const onLinkPaste = useCallback((url) => {
        // see if there are any links already attached
        const links = attachments.filter(attachment => ["WebPage", "YouTubeVideo"].includes(attachment.type));
        // if we just published prevent link preview since the editor hasnt cleared
        // if there are already links attached don't fetch meta for any more
        if (!publishing
            && !publishingPrevious
            && url
            && links.length === 0) {
            attachmentAPI.addLink(url);
        }
    }, [
        attachmentAPI,
        attachments,
        publishing,
        publishingPrevious,
    ]);
    const commonEditorProps = useMemo(() => {
        return {
            ...props,
            ariaLabel: "Post text editor",
            autoFocus: true,
            mentionsOptions: members.map(toOptions),
            mentionValue: mentions.map(toOptions),
            onChange,
            onInsertLink: onLinkPaste,
            onSearchMention: handleSearchMention,
            renderMentionOption: MentionMemberOption,
            value: deserialize(contentHtml),
        };
    }, [
        contentHtml,
        handleSearchMention,
        members,
        mentions,
        onChange,
        onLinkPaste,
        props,
    ]);
    const desktopMemoizedEditor = useMemo(() => (<Editor {...commonEditorProps}/>), [commonEditorProps]);
    const mobileMemoizedEditor = useMemo(() => (<Editor {...commonEditorProps} toolbar={false}/>), [commonEditorProps]);
    return (<>
			<TabletLandscapeAndDesktop>
				{desktopMemoizedEditor}
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				{mobileMemoizedEditor}
			</MobileAndTabletPortrait>
		</>);
};
export default EditorPortion;
