import { ListType, withLists } from "@prezly/slate-lists";
import { BoldSVG, IndentSVG, ItalicSVG, LinkSVG, ListOLSVG, ListULSVG, OutdentSVG, StrikeThroughSVG } from "@sermo/ui-components";
import escapeHtml from "escape-html";
import isHotkey from "is-hotkey";
import { Editor, Element, Transforms, Range, Text } from "slate";
import { ReactEditor } from "slate-react";
import { isUrl } from "@frontend/Utils";
import * as EditorHTMLRules from "./EditorHtmlRules";
import { EditorActions, EditorElementType, } from "./model/EditorTypes";
export const ActionMap = {
    [EditorActions.Bold]: {
        icon: BoldSVG,
        label: "Bold (Ctrl/⌘ + B)",
        shortcut: "mod+b",
        type: "mark",
    },
    [EditorActions.Italic]: {
        icon: ItalicSVG,
        label: "Italic (Ctrl/⌘ + I)",
        shortcut: "mod+i",
        type: "mark",
    },
    [EditorActions.Strike]: {
        icon: StrikeThroughSVG,
        label: "Strikethrough (Ctrl/⌘ + Shift + X)",
        shortcut: "mod+shift+x",
        type: "mark",
    },
    [EditorActions.Link]: {
        icon: LinkSVG,
        label: "Insert link (Ctrl/⌘ + K)",
        shortcut: "mod+k",
        type: "link",
    },
    [EditorActions.Ul]: {
        icon: ListULSVG,
        label: "Bulleted list (Ctrl/⌘ + Shift + 8)",
        shortcut: "mod+shift+8",
        type: "block"
    },
    [EditorActions.Ol]: {
        icon: ListOLSVG,
        label: "Numbered list (Ctrl/⌘ + Shift + 7)",
        shortcut: "mod+shift+7",
        type: "block"
    },
    [EditorActions.Indent]: {
        icon: IndentSVG,
        label: "Increase indent (Ctrl/⌘ + ])",
        shortcut: "mod+]",
        type: "indentation"
    },
    [EditorActions.Outdent]: {
        icon: OutdentSVG,
        label: "Increase indent (Ctrl/⌘ + [)",
        shortcut: "mod+[",
        type: "indentation"
    }
};
export const parseHtml = (html) => {
    const parsed = new DOMParser().parseFromString(html, "text/html");
    const body = parsed.body;
    return body || window.document.createElement("body");
};
const serializeNode = (node, custom) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.code) {
            string = `<code>${string}</code>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        if (node.strikethrough) {
            string = `<s>${string}</s>`;
        }
        return string;
    }
    const children = (node.children || []).map((n) => serializeNode(n, custom)).join("");
    return EditorHTMLRules.doSerialize(node, children, custom);
};
/**
 * Set the custom callback function to customize the serialization
 */
export const serialize = (nodes, custom) => nodes.map(n => serializeNode(n, custom)).join("");
export const deserialize = (el) => {
    let element = el;
    if (!element
        || (typeof element === "string"
            && (!element.trim().length
                || !element.startsWith("<")))) {
        element = "<p></p>";
    }
    const html = typeof element === "string"
        ? parseHtml(element)
        : element;
    return EditorHTMLRules.doDeserialize(html);
};
export const getActionByShortcut = (event) => Object.entries(ActionMap).find(([, value]) => isHotkey(value.shortcut, event));
const isListType = (action) => action === EditorActions.Ul || action === EditorActions.Ol;
export const toggleBlock = (editor, action) => {
    const isActive = isBlockActive(editor, action, "type");
    const isList = isListType(action);
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n)
            && Element.isElement(n)
            && isListType(n.type),
        split: true,
    });
    const newProperties = {
        type: isActive
            ? "paragraph"
            : isList
                ? "list-item"
                : action,
    };
    Transforms.setNodes(editor, newProperties);
    if (!isActive && isList) {
        const block = {
            type: action,
            children: []
        };
        Transforms.wrapNodes(editor, block);
    }
};
export const toggleMark = (editor, mark) => {
    const isActive = isMarkActive(editor, mark);
    if (isActive) {
        Editor.removeMark(editor, mark);
    }
    else {
        Editor.addMark(editor, mark, true);
    }
};
const isBlockActive = (editor, mark, blockType = "type") => {
    const { selection } = editor;
    if (!selection) {
        return false;
    }
    const [match] = Array.from(Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n => !Editor.isEditor(n)
            && Element.isElement(n)
            && n[blockType] === mark,
    }));
    return !!match;
};
export const isMarkActive = (editor, mark) => {
    const marks = Editor.marks(editor);
    return marks
        ? marks[mark] === true
        : false;
};
export const getRemainingCharCount = (value, max) => {
    const serializedValue = serialize(value);
    const parsed = parseHtml(serializedValue)?.textContent;
    if (!parsed?.trim().length) {
        return max || 0;
    }
    const length = parsed?.length || 0;
    if (!max) {
        return length;
    }
    return (max - length);
};
export const withListsPlugin = withLists({
    isConvertibleToListTextNode(node) {
        return Element.isElementType(node, EditorElementType.p);
    },
    isDefaultTextNode(node) {
        return Element.isElementType(node, EditorElementType.p);
    },
    isListNode(node, type) {
        if (type) {
            const nodeType = type === ListType.ORDERED
                ? EditorElementType.ol
                : EditorElementType.ul;
            return Element.isElementType(node, nodeType);
        }
        return (Element.isElementType(node, EditorElementType.ol)
            || Element.isElementType(node, EditorElementType.ul));
    },
    isListItemNode(node) {
        return Element.isElementType(node, EditorElementType.li);
    },
    isListItemTextNode(node) {
        return Element.isElementType(node, EditorElementType.lit);
    },
    createDefaultTextNode(props = {}) {
        return { children: [{ text: "" }], ...props, type: EditorElementType.p };
    },
    createListNode(type = ListType.UNORDERED, props = {}) {
        const nodeType = type === ListType.ORDERED
            ? EditorElementType.ol
            : EditorElementType.ul;
        return { children: [{ text: "" }], ...props, type: nodeType };
    },
    createListItemNode(props = {}) {
        return { children: [{ text: "" }], ...props, type: EditorElementType.li };
    },
    createListItemTextNode(props = {}) {
        return { children: [{ text: "" }], ...props, type: EditorElementType.lit };
    },
});
export const withInlines = (editor) => {
    const { insertData, insertText, isInline, } = editor;
    editor.isInline = element => isInline(element);
    editor.insertText = text => insertText(text);
    editor.insertData = data => insertData(data);
    return editor;
};
export const withLinksAndMentions = (editor) => {
    const voidElementTypes = ["link", "mention"];
    const { insertData, isInline, isVoid, markableVoid, } = editor;
    editor.insertData = data => {
        const text = data.getData("text/plain");
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        }
        else {
            insertData(data);
        }
    };
    editor.isInline = (element) => voidElementTypes.includes(element.type) || isInline(element);
    editor.isVoid = (element) => voidElementTypes.includes(element.type) || isVoid(element);
    editor.markableVoid = (element) => voidElementTypes.includes(element.type) || markableVoid(element);
    return editor;
};
export const getSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.type === "Range" && selection.rangeCount === 1) {
        const { anchorOffset, focusOffset, anchorNode } = selection;
        if (anchorOffset < focusOffset) {
            return anchorNode?.nodeValue?.slice(anchorOffset, focusOffset) || "";
        }
        if (focusOffset < anchorOffset) {
            return anchorNode?.nodeValue?.slice(focusOffset, anchorOffset) || "";
        }
    }
    return "";
};
export const getActiveNodeLink = (editor) => {
    const [nodeEntry] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
    });
    return nodeEntry
        ? nodeEntry[0]
        : undefined;
};
export const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
    });
};
export const deleteLink = (editor) => {
    Transforms.removeNodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
    });
};
const repetitiveLinkHandling = (editor) => {
    ReactEditor.focus(editor);
    Transforms.move(editor);
    Transforms.insertText(editor, " ");
};
const wrapLink = (editor, url) => {
    const currentLink = getActiveNodeLink(editor);
    if (currentLink) {
        unwrapLink(editor);
    }
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: "link",
        label: url,
        url,
        children: [{ text: "" }],
    };
    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
        repetitiveLinkHandling(editor);
    }
    else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
        repetitiveLinkHandling(editor);
    }
};
export const replaceLink = (editor, url, display) => {
    const currentLink = getActiveNodeLink(editor);
    if (currentLink) {
        deleteLink(editor);
    }
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: "link",
        label: display || url,
        url,
        children: [{ text: "" }],
    };
    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
        repetitiveLinkHandling(editor);
    }
    else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
        repetitiveLinkHandling(editor);
    }
};
export const getActiveNodeMention = (editor) => {
    const [nodeEntry] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "mention"
    });
    return nodeEntry
        ? nodeEntry[0]
        : undefined;
};
export const getMention = (editor) => {
    const { selection } = editor;
    let target, mention;
    if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: "word" });
        const before = wordBefore && Editor.before(editor, wordBefore);
        let beforeRange = before && Editor.range(editor, before, start);
        let beforeText = beforeRange && Editor.string(editor, beforeRange);
        if (before && (beforeText?.indexOf(".") !== -1 || beforeText?.indexOf(" ") !== -1)) {
            const [startForWordBefore] = Range.edges({
                anchor: {
                    offset: before.offset - 1,
                    path: before.path
                },
                focus: {
                    offset: before.offset - 1,
                    path: before.path
                },
            });
            const newWordBefore = Editor.before(editor, startForWordBefore, { unit: "word" });
            const newBefore = newWordBefore && Editor.before(editor, newWordBefore);
            beforeRange = newBefore && Editor.range(editor, newBefore, start);
            beforeText = beforeRange && Editor.string(editor, beforeRange);
        }
        const beforeMatch = beforeText && beforeText.match(/@([a-zA-Z0-9_ ]*)$/);
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);
        if (beforeMatch && afterMatch) {
            target = beforeRange;
            mention = beforeMatch[1];
        }
    }
    return {
        target,
        mention
    };
};
export const getMentionTargetPosition = (editor, target) => {
    const defaults = {
        top: "inherit",
        left: "inherit",
        right: "inherit",
        position: "fixed",
    };
    if (!target) {
        return defaults;
    }
    let domRange;
    // not sure why this fails some times but just in case
    try {
        domRange = ReactEditor.toDOMRange(editor, target);
    }
    catch {
        return defaults;
    }
    const rect = domRange.getBoundingClientRect();
    return {
        position: "fixed",
        top: `${rect.top + 24}px`,
        left: `${rect.left}px`,
        right: "auto"
    };
};
export const insertMention = (editor, label, value, target) => {
    if (target) {
        Transforms.select(editor, target);
    }
    const mention = {
        type: "mention",
        label,
        value,
        children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, [mention, { text: " " }]); // space after mention
    ReactEditor.focus(editor);
    Transforms.move(editor);
};
export const getDiff = (a, b) => {
    let i = 0;
    let j = 0;
    let result = "";
    while (j < b.length) {
        if (a[i] !== b[j] || i === a.length)
            result += b[j];
        else
            i++;
        j++;
    }
    return result;
};
