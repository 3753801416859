import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { marginBottomNonAdStyles, postContentStyles, } from "@components/PostContent";
export const Content = styled(TextStyled) `
	${postContentStyles};
	${marginBottomNonAdStyles};

	& > p:has(+ span:last-child) {
		display: inline;
	}
`;
