import { ColorGrey40, ColorGrey60 } from "@sermo/design-tokens";
import { Dropdown } from "@sermo/ui-components";
import styled from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const CityStateZipWrap = styled.div`
	display: flex;

	& > label {
		padding: 12px 0;
		flex-grow: 1;
		flex-basis: auto;
	}

	& > :first-child {
		flex-basis: 42%;
	}

	& > :not(:first-child) {
		padding-left: 16px;
	}

	@media ${MobileAndTabletPortraitQuery} {
		display: block;

		& > :not(:first-child) {
			padding-left: 0;
		}
	}
`;

export const StyledDropdown = styled(Dropdown)`
	span {
		color: ${ColorGrey60};
		font-size: 14px;
		font-family: Sailec;
		font-weight: 400;
		line-height: 20px;
	}

	& > div > div:first-of-type {
		height: 46px;
		min-height: 46px;
		margin-top: 4px;
	}

	[disabled] > div, [disabled] + div > svg {
		color: ${ColorGrey40};
	}

	[disabled] + div {
		cursor: unset;
	}
`;