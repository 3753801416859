import { useMediaQuery } from "react-responsive";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import SponsoredVideoPlayer from "@components/Card/components/VideoPlayer/SponsoredVideoPlayer";
import { StyledTabletLandscapeAndDesktopQuery, TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { base } from "@components/Resource/routes";
import { useMemoizedContext } from "@hooks/Hooks";
const FullWidthMedia = styled.div `
	margin: 0 -16px 24px;

	${({ $expanded }) => $expanded && `
		${StyledTabletLandscapeAndDesktopQuery} {
			margin: 0 -32px 24px;
		}
	`}
`;
const Image = styled.img `
	width: 100%;
	${({ $isSearch }) => $isSearch && `
		object-fit: cover;
		max-height: 316px;
	`}
`;
const AudioWrapper = styled.div `
	display: flex;
	justify-content: center;
	margin: 16px 0;
`;
const Audio = styled.audio `
	width: 100%;
`;
const Media = () => {
    const { media, resourceCenterItemId, } = useMemoizedContext("postData", [
        "media",
        "resourceCenterItemId",
    ]);
    const { expanded, } = useMemoizedContext("postUIViewing", [
        "expanded",
    ]);
    const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
    const isSearch = !!useMatch("/search/*");
    if (!media)
        return null;
    switch (media.attachmentType) {
        case "Image":
            return (<FullWidthMedia $expanded={expanded}>
					<Image src={media.targetUrl} $isSearch={isSearch}/>
				</FullWidthMedia>);
        case "Mp4Video":
            return (<FullWidthMedia $expanded={expanded}>
					<SponsoredVideoPlayer featured={{
                    ...media,
                    autoplayWhenExpanded: media.autoplayWhenExpanded || media.autoplayInFeed,
                    thumbnail: media.autoplayWhenExpanded || media.autoplayInFeed
                        ? null
                        : media.posterImageUrl,
                    trackingArea: `${base}/item/${resourceCenterItemId}`,
                    url: media.targetUrl,
                }}/>
				</FullWidthMedia>);
        case "Audio":
            return (<AudioWrapper>
					<Audio controls={true}>
						<source src={media.targetUrl} type={media.mimeType}/>
					</Audio>
				</AudioWrapper>);
        case "PdfDocument":
            return (<FullWidthMedia $expanded={expanded}>
					<object data={media.targetUrl} type="application/pdf" width="100%" height={isSearch
                    ? "450px"
                    : isDesktop
                        ? "720px"
                        : "410px"}/>
				</FullWidthMedia>);
        default:
            return null;
    }
};
export default Media;
