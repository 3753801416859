import { PhoneInput } from "@sermo/ui-components";
import React from "react";
import { getFieldLabel } from "../../AdForm.utils";

const AdFormPhone = ({ data, form }) => {
	const label = getFieldLabel(data);

	const handleChange = (e, value, additionalValues) => {
		if (value === additionalValues.code) {
			form.setFieldValue(data.adFormFieldType, "");
			form.setFieldValue(`${data.adFormFieldType}IsValid`, undefined);
			return;
		}

		form.setFieldValue(data.adFormFieldType, value);
		form.setFieldValue(`${data.adFormFieldType}IsValid`, additionalValues.isValid);
	};

	return (
		<PhoneInput
			disabled={!data.isEditable}
			errors={form.errors[data.adFormFieldType]}
			label={label}
			name={data.adFormFieldType}
			onChange={handleChange}
			pinnedCountries={["FR", "DE", "IT", "ES", "GB", "US"]}
			pinnedCountriesLabel="Quick List"
			required={!data.isOptional}
			value={form.values[data.adFormFieldType]}
		/>
	);
};

export default AdFormPhone;