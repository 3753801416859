import styled from "styled-components";
import { applyZIndex } from "@frontend/styled";
export const Header = styled.header `
	position: sticky;
	top: 0;
	left: 0;
	${applyZIndex("headerFooter")};

	@media (max-width: 1200px) {
		width: 100vw;
	}
`;
