import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./FollowedIndicator.scss";

const FollowedIndicator = ({ contextClass }) => {
	const {
		following,
	} = useMemoizedContext("postData",[
		"following",
	]);

	if (!following) {
		return null;
	}

	return (
		<div
			styleName={
				classNames([
					"typography.overline-02",
					"styles.followed-indicator",
					{ [`styles.context-${contextClass}`]: contextClass },
				])
			}
		>
			{getTranslation("frontend.generics.saved", true)}
		</div>
	);
};

FollowedIndicator.propTypes = {
	contextClass: PropTypes.string,
};

FollowedIndicator.defaultProps = {
	contextClass: "",
};

export default FollowedIndicator;
