import { useEventListener } from "@sermo/ui-components";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import React, { useRef, useState } from "react";
import { EmojiButton, EditorEmojiRoot } from "./Editor.styles";
const containsNode = (parent, child) => parent === child || (parent.contains && parent.contains(child));
/**
 * Check if target is inside of list elements
 */
const targetWithin = (target, elements) => (elements || []).some((node) => node && containsNode(node, target));
export const EditorEmoji = ({ onSelect, disabled }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const handleEmojiClick = (emoji) => {
        if (onSelect) {
            onSelect(emoji);
        }
    };
    const handleClick = () => {
        setOpen(prev => !prev);
    };
    useEventListener("mousedown", (event) => {
        if (!open || !(event.target instanceof Element)) {
            return;
        }
        if (!targetWithin(event.target, [ref.current])) {
            setOpen(false);
        }
    });
    return (<EditorEmojiRoot ref={ref}>
			<EmojiButton onClick={handleClick} disabled={disabled} aria-label="Emoji Button"/>
			{open && (<EmojiPicker height={455} emojiStyle={EmojiStyle.NATIVE} onEmojiClick={handleEmojiClick} autoFocusSearch={false} skinTonesDisabled previewConfig={{
                showPreview: false
            }}/>)}
		</EditorEmojiRoot>);
};
