import styled from "styled-components";
import { applyZIndex, layoutFoundation } from "@frontend/styled";
export const Footer = styled.footer `
	width: 100dvw;
	position: sticky;
	bottom: 0;
	left: 0;
	${applyZIndex("headerFooter")};
`;
export const FooterContent = styled.div `
	${layoutFoundation};
	max-width: 100dvw;
`;
