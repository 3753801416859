import React from "react";
import { settingsSection } from "@components/Settings/components/AccountSettings/sections";
import getTranslation from "@translation/translation";
import styles from "./ModalWithdrawalComponents.scss";

const InfoChangeCountry = () => (
	<div styleName="styles.address-info">
		{getTranslation("frontend.modals.withdrawal.infoUpdateCountry", false)}
	</div>
);

export default InfoChangeCountry;
