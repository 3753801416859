/**
 * Event type constants
 * @type {string}
 */
export const EVENT_SCHEDULED = "Upcoming";
export const EVENT_LIVE = "Live";
export const EVENT_ON_DEMAND = "OnDemand";
export const EVENT_TODAY = "today";
export const EVENT_CANCELLED = "Cancelled";
export const EVENT_INACTIVE = "Inactive";

/**
 * Event validation error constants
 * @type {string}
 */
export const EVENT_ERROR_ALREADY_REGISTERED = "system.validationMessages.alreadyRegisteredForEvent";
export const EVENT_ERROR_LOGIN_TO_REGISTER = "system.validationMessages.loginToRegisterForEvents";

/**
 * Infers the const value of the event from the passed date times.
 *
 * @param from {Date} Start date time of event
 * @param to {Date} End date time of event
 * @return {string} The Event type const
 */
export const getTiming = (from, to) => {
	const now = new Date();
	const endDay = new Date(now.valueOf());
	endDay.setDate(endDay.getDate() + 1);
	endDay.setSeconds(0);
	endDay.setMinutes(0);
	endDay.setHours(0);
	const delta = endDay - now;
	if (now - to > 0) {
		return EVENT_ON_DEMAND;
	} else if (from - now <= 0) {
		return EVENT_LIVE;
	} else if (from - now > delta) {
		return EVENT_SCHEDULED;
	} else {
		return EVENT_TODAY;
	}
}

/**
 * Check whether the events is today given the passed date times.
 *
 * @param from {Date} Start date time of event
 * @param to {Date} End date time of event
 * @return {boolean} The event is today
 */
export const isToday = (from, to) => {
	const now = new Date();
	const startToday = new Date(now.valueOf());
	startToday.setDate(startToday.getDate());
	startToday.setSeconds(0);
	startToday.setMinutes(0);
	startToday.setHours(0);
	const endToday = new Date(startToday.valueOf() + 24*60*60*1000);
	return (from > startToday && from < endToday) || (to > startToday && to < endToday) ;
}

/**
 * Return the date time parameters to render time a translation.
 * @param from {Date} Start date time of event
 * @param to {Date} End date time of event
 * @param locale {string} Current locale
 * @return {string[]} Items month name, day number, year, start, end, offset
 */
export const getTimeParams = (from, to, locale) => {
	let month, dateNum, year, start, end, offset;
	month = from.toLocaleDateString(locale || "en-US", { month: "long" });
	year = from.toLocaleDateString(locale || "en-US", { year: "numeric" });
	dateNum = from.toLocaleDateString(locale || "en-US", { day: "numeric" });
	end = to.toLocaleString(locale || "en-US", { hour: "numeric",
		minute: "numeric",
		hour12: true });
	start = from.toLocaleString(locale || "en-US", { hour: "numeric",
		minute: "numeric",
		hour12: true });
	offset = (from.toLocaleString(locale || "en-US", { timeZoneName: "short" })).split(" ").pop();
	return [month, dateNum, year, start, end, offset];
}

/**
 * Return the configuration for the current data type: loggedIn or loggedOut user and preview.
 * @param modelType {String} loggedIn|loggedOut|preview
 * @param eventId {Number} The event ID
 * @param eventToken {String} The event token
 * @return {*} The current config object with API endpoint, request body params and data adapter
 */
export const getEventDataConfig = (modelType, eventId, eventToken) => ({
	loggedIn: {
		endpoint: "events/geteventformember",
		params: {
			eventId,
			eventToken,
		},
		adapter: data => {
			return {
				...data.event,
				...data.member,
			}
		},
	},
	loggedOut: {
		endpoint: "events/geteventfornonmember",
		params: {
			eventToken,
		},
		adapter: data => {
			return {
				...data.event,
				isRegistered: data.isRegistered,
				displayName: data.displayName,
				emailAddress: data.emailAddress,
			}
		},
	},
	preview: {
		endpoint: "preview/getpreview",
		params: {
			id: eventId,
			type: "event",
		},
		adapter: data => {
			return {
				...data.content,
				isRegistered: data.content.isMemberRegistered,
			}
		},
	},
}[modelType])