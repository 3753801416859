import { ListsEditor, withListsReact, onKeyDown as onKeyDownLists } from "@prezly/slate-lists";
import { Popup, Options, useId } from "@sermo/ui-components";
import isMobile from "ismobilejs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createEditor, Transforms } from "slate";
import { withHistory } from "slate-history";
import { Slate, withReact, ReactEditor } from "slate-react";
import { PostDataActionTypes } from "@contexts/PostData";
import { useMemoizedContext } from "@hooks/Hooks";
import { CharCount, CornerDisplay, Root, EditableStyled } from "./Editor.styles";
import { EditorElement } from "./EditorElement";
import { EditorEmoji } from "./EditorEmoji";
import { EditorLeaf } from "./EditorLeaf";
import { EditorToolbar } from "./EditorToolbar";
import { getActionByShortcut, getRemainingCharCount, toggleBlock, toggleMark, withListsPlugin, withInlines, replaceLink, withLinksAndMentions, getMention, getMentionTargetPosition, getActiveNodeMention, insertMention, serialize, getDiff, } from "./helper";
import { EditorActions, EditorActionTypes, } from "./model/EditorTypes";
export const emptyValue = [
    {
        type: "paragraph",
        children: [
            {
                text: ""
            }
        ],
    },
];
export const Editor = (props) => {
    const { value = emptyValue, placeholder, onFocus, onChange, onLoad, onSearchMention, onChangeMention, onInsertLink, renderMentionOption, mentionsOptions = [], max, showCharCount, emoji, minHeight = 95, toolbar = false, disabled = false, className, borderless = false, ariaLabel, autoFocus = false, ...rest } = props;
    const { dispatch: dispatchPostData, mentions, } = useMemoizedContext("postData", [
        "mentions",
    ]);
    const handleMentionAdd = useCallback((userId) => {
        dispatchPostData({
            type: PostDataActionTypes.ADD_MENTION,
            payload: { userId },
        });
    }, [dispatchPostData]);
    useEffect(() => {
        if (onChangeMention) {
            onChangeMention(mentions);
        }
    }, [
        mentions,
        onChangeMention,
    ]);
    const handleMentionDelete = useCallback((userId) => {
        dispatchPostData({
            type: PostDataActionTypes.DELETE_MENTION,
            payload: { userId },
        });
    }, [dispatchPostData]);
    const mobile = isMobile().any;
    const editableId = useId();
    const editableElementRef = useRef();
    const [cachedValue, setCachedValue] = useState(value);
    const [mentionTarget, setMentionTarget] = useState();
    const openMentionPopup = useMemo(() => mentionTarget && mentionsOptions.length > 0, [mentionsOptions, mentionTarget]);
    const editor = useMemo(() => withListsReact(withListsPlugin(withLinksAndMentions(withInlines(withReact(withHistory(createEditor())))))), []);
    const charCount = getRemainingCharCount(cachedValue, max);
    useEffect(() => {
        if (autoFocus) {
            ReactEditor.focus(editor);
        }
    }, [
        autoFocus,
        editor,
    ]);
    useEffect(() => {
        if (!editableId) {
            return;
        }
        editableElementRef.current = document.getElementById(editableId);
    }, [editableId]);
    const renderElement = useCallback((props) => <EditorElement {...props}/>, []);
    const renderLeaf = useCallback((props) => <EditorLeaf {...props}/>, []);
    const handleKeyDown = useCallback((event) => {
        onKeyDownLists(editor, event);
        const action = getActionByShortcut(event);
        if (action) {
            event.preventDefault();
            const [mark] = action;
            toggleMark(editor, mark);
        }
    }, [editor]);
    const handleSelectEmoji = useCallback((data) => {
        editor.insertText(data.emoji);
    }, [editor]);
    const handleFocus = useCallback((event) => {
        if (onFocus) {
            onFocus(event);
        }
    }, [onFocus]);
    const handleMentionRemoval = useCallback((nextValue) => {
        const value = serialize(nextValue);
        const previousValue = serialize(cachedValue);
        if (value.length < previousValue.length) {
            const diff = getDiff(value, previousValue);
            const search = diff.search("data-mention");
            if (search > -1) {
                const mentionRegex = /"(.*?)"/;
                const result = mentionRegex.exec(diff);
                if (!result || result.length <= 1) {
                    return;
                }
                handleMentionDelete(Number(result[1]));
            }
        }
    }, [
        cachedValue,
        handleMentionDelete,
    ]);
    const handleReset = useCallback(() => {
        editor.children.map(item => Transforms.delete(editor, { at: [0] }));
        editor.children = emptyValue;
    }, [editor]);
    useEffect(() => {
        if (onLoad) {
            onLoad(editor, handleReset, editableElementRef.current);
        }
    }, [editor, handleReset, onLoad, editableElementRef]);
    const handleChange = useCallback((value) => {
        const { target, mention = "" } = getMention(editor);
        setMentionTarget(target);
        if (getActiveNodeMention(editor)) {
            Transforms.move(editor);
        }
        if (onSearchMention) {
            onSearchMention(mention);
        }
        handleMentionRemoval(value);
        setCachedValue(value);
        if (onChange) {
            onChange(value, editableElementRef.current);
        }
    }, [editor, onSearchMention, handleMentionRemoval, onChange]);
    const handleToolbarClick = useCallback((event, entry) => {
        const [action, value] = entry;
        if (value.type === EditorActionTypes.Indentation) {
            const depth = action === EditorActions.Indent
                ? ListsEditor.increaseDepth
                : ListsEditor.decreaseDepth;
            depth(editor);
            return;
        }
        if (value.type === EditorActionTypes.Block) {
            toggleBlock(editor, action);
            return;
        }
        if (value.type === EditorActionTypes.Mark) {
            toggleMark(editor, action);
            return;
        }
    }, [editor]);
    const handleInsertLink = useCallback((value) => {
        const { url, display } = value;
        if (editor.selection) {
            replaceLink(editor, url, display);
        }
        if (onInsertLink) {
            onInsertLink(url);
        }
    }, [
        editor,
        onInsertLink,
    ]);
    const handleSelectMention = useCallback((e, value) => {
        const mentioned = mentionsOptions.find((option) => option.value === value);
        if (!mentioned) {
            return;
        }
        insertMention(editor, mentioned.label, mentioned.value, mentionTarget);
        setMentionTarget(undefined);
        handleMentionAdd(mentioned["id"]);
    }, [
        editor,
        handleMentionAdd,
        mentionsOptions,
        mentionTarget,
    ]);
    const handleDOMBeforeInput = useCallback((event) => {
        const inputType = event.inputType;
        if (inputType === "insertText" || inputType === "insertParagraph") {
            if (max && charCount <= 0) {
                event.preventDefault();
                return;
            }
        }
    }, [max, charCount]);
    // keep track of the scroll position of the element
    const { y: elementY } = useMemoizedContext("elementScroll", ["y"]);
    // keep track of the scroll position of the window
    const { y } = useMemoizedContext("scroll", ["y"]);
    const { left, position, right, top,
    // eslint-disable-next-line react-hooks/exhaustive-deps
     } = useMemo(() => getMentionTargetPosition(editor, mentionTarget), [
        editor,
        elementY, // update these values if the element is scrolled
        mentionTarget,
        y, // update these values if the window is scrolled
    ]);
    return (<Root borderless={borderless} readOnly={disabled}>
			<Slate editor={editor} initialValue={value} onChange={handleChange}>
				{useMemo(() => ((Array.isArray(toolbar) && toolbar.length) || toolbar) && !mobile && (<EditorToolbar onClick={handleToolbarClick} onInsertLink={handleInsertLink} disabled={disabled} actions={Array.isArray(toolbar)
                ? toolbar
                : undefined}/>), [toolbar, mobile, handleToolbarClick, handleInsertLink, disabled])}
				<EditableStyled {...rest} id={editableId} readOnly={disabled} style={{ minHeight }} onClick={e => e.stopPropagation()} onFocus={handleFocus} onKeyDown={handleKeyDown} placeholder={placeholder} renderElement={renderElement} renderLeaf={renderLeaf} className={className} onDOMBeforeInput={handleDOMBeforeInput} autoComplete="off" aria-multiline="true" aria-placeholder={placeholder} aria-label={ariaLabel} tabIndex={0}/>
				<CornerDisplay>
					{useMemo(() => showCharCount && (<CharCount error={!!(max && charCount < 10)}>
								{charCount}
							</CharCount>), [charCount, max, showCharCount])}
					{useMemo(() => emoji && !mobile && (<EditorEmoji onSelect={handleSelectEmoji} disabled={disabled}/>), [emoji, handleSelectEmoji, mobile, disabled])}
				</CornerDisplay>
				{openMentionPopup && (<Popup open position={position} style={{
                left,
                right,
                top,
            }}>
							<Options options={mentionsOptions} value={null} renderOption={renderMentionOption} onSelect={handleSelectMention}/>
						</Popup>)}
			</Slate>
		</Root>);
};
