import { ColorWhite } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { css } from "styled-components";
import { applyBorder, applyZIndex } from "@frontend/styled";
export const Container = styled.div `
	${({ $open }) => $open && css `
		position: relative;
		${applyZIndex("over-the-top")};
	`};
`;
export const Content = styled.div `
	width: 100%;
	min-height: 48px;
	padding: 12px 16px;
	${applyBorder("bottom")};
	background-color: ${ColorWhite};
	display: flex;
	justify-content: space-between;
`;
export const Clickable = styled.div `
	flex-basis: 100%;
	cursor: pointer;
`;
export const Selected = styled(TextStyled) `
	color: ${({ theme }) => theme.color.text02};
	display: flex;
	align-items: center;

	& > span {
		margin: 0 8px;
	}
`;
export const SelectedSubtitle = styled(TextStyled) `
	margin-top: 4px;
`;
export const CollapseContent = styled.div `
	padding: 16px;
	background-color: ${ColorWhite};
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
