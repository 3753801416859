import React, { useEffect } from "react";
import { usePrevious } from "react-use";
import { useMemoizedContext } from "@frontend/hooks/Hooks";

const ActivityMonitor = () => {
	const { updateMember, queryParams } = useMemoizedContext("member", ["queryParams"]);

	const startTimer = () => {
		clearTimeout(window.timeoutIdRef);
		window.timeoutIdRef = setTimeout(() => {
			updateMember({ "active": false });            
			// allows user to over ride 20 min default for testing
			// TODO: remove this once it's verified to be working well.
		}, queryParams?.activitytimeout
			? queryParams.activitytimeout
			: 1000 * 60 * 20); 
	}
    
	useEffect(() => {
		startTimer();
	}, []);

	const resetTimer = () => {
		clearTimeout(window.timeoutIdRef);
		window.timeoutIdRef = null;
		startTimer();
	}

	const reset = () => {
		updateMember({ "active": true });            
		resetTimer();
	}
    
	useEffect(() => {
		window.addEventListener("touchstart", () => {
			reset();
		});
		window.addEventListener("touchend", () => {
			reset();
		});
		window.addEventListener("touchmove", () => {
			reset();
		});
		window.addEventListener("scroll", () => {
			reset();
		});
		window.addEventListener("click", () => {
			reset();
		});
	}, []);
    
	return null;
}
 
export default React.memo(ActivityMonitor);
