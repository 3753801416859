import Button from "@components/Button/Button";
import PostSurvey from "@components/Register/PostSurvey";
import { EUIKey, useOpenModal } from "@contexts/UI";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import * as Styles from "./PostSurveyBanner.styles";
const textMap = {
    "YouCompletedASurvey": "completed",
    "YouCompletedASurveyNoIncentive": "default",
    "QuotaIsFull": "screenOutQuotaFullWithIncentive",
    "YouScreenedOut": "screenOutQuotaFullWithIncentive",
    "QuotaIsFullNoIncentive": "screenOutQuotaFullWithNoIncentive",
    "YouScreenedOutNoIncentive": "screenOutQuotaFullWithNoIncentive",
};
const texts = {
    completed: {
        title: "frontend.modals.postSurveyReferrals.titleOutcomeComplete",
        subtitle: "frontend.modals.postSurveyReferrals.descriptionOutcomeComplete",
    },
    screenOutQuotaFullWithIncentive: {
        title: "frontend.modals.postSurveyReferrals.titleOutcomeIncentiveScreenOutQuotaFull",
        subtitle: "frontend.modals.postSurveyReferrals.descriptionOutcomeIncentiveScreenOutQuotaFull",
    },
    screenOutQuotaFullWithNoIncentive: {
        title: "frontend.modals.postSurveyReferrals.titleOutcomeNoIncentiveScreenOutQuotaFull",
        subtitle: "frontend.modals.postSurveyReferrals.descriptionOutcomeNoIncentiveScreenOutQuotaFull",
    },
    default: {
        title: "frontend.modals.postSurveyReferrals.titleDefault",
        subtitle: "frontend.modals.postSurveyReferrals.descriptionDefault",
    },
};
const usePostSurveyText = () => {
    const { surveyOutcome, surveyOutcomeIncentiveAmount, surveyOutcomeIncentiveCurrency, } = useMemoizedContext("member", [
        "surveyOutcome",
        "surveyOutcomeIncentiveAmount",
        "surveyOutcomeIncentiveCurrency",
    ]);
    let incentiveTranslation = "";
    if (surveyOutcomeIncentiveAmount && surveyOutcomeIncentiveCurrency) {
        incentiveTranslation = getCurrencyTranslation(surveyOutcomeIncentiveAmount, surveyOutcomeIncentiveCurrency, false, 0);
    }
    let translationKey = texts["default"];
    if (surveyOutcome) {
        translationKey = texts[textMap[surveyOutcome]];
    }
    // In case the outcome code is not found, set it back to default
    if (translationKey === null) {
        console.log("setting default");
        translationKey = texts["default"];
    }
    const title = getTranslation(translationKey.title, false, incentiveTranslation);
    const subtitle = getTranslation(translationKey.subtitle, false, incentiveTranslation);
    return [title, subtitle];
};
export { usePostSurveyText };
const PostSurveyBanner = () => {
    const openModal = useOpenModal();
    const [title, subtitle] = usePostSurveyText();
    const { updateMember } = useMemoizedContext("member");
    const openPostSurveyModal = () => {
        updateMember({ hasSeenPostSurveyReferralPopup: true });
        openModal({
            [EUIKey.MODAL_COMPONENT]: <PostSurvey />,
            [EUIKey.MODAL_LABEL]: "post-survey-referral",
            [EUIKey.MODAL_SUBTYPE]: "information-darker",
        });
    };
    return (<div>
			<Styles.Content $kind={"bodyShortMedium02"}>
				{title} {subtitle}
			</Styles.Content>
			<Button theme="dark" style="secondary" size="small" contextClass="banner-error" clickHandler={openPostSurveyModal}>
				{getTranslation("frontend.generics.continue", true)}
			</Button>
		</div>);
};
export default PostSurveyBanner;
