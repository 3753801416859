// *** if you edit this list, make sure to update the sass z-index helper ***
// it is located in the style-mixins.scss file in the scss folder.
// helper to keep all items stacked correctly.
// order is left = lower, right = higher.
// to add a new item just place it in the correct spot in the list.

const $layers = [
	"left-rail",
	"right-rail",
	"messages",
	"messages-Header",
	"feed",
	"mini-profile",
	"sub-navigator",
	"card-background",
	"card",
	"video-poster",
	"video-playButton",
	"menu-background",
	"menu",
	"stickyISI",
	"alert-banner",
	"modal-background",
	"headerFooter",
	"mobile-nav",
	"expanded-card",
	"modal",
	"mobile-post-creation",
	"top-nav",
	"backdrop",
	"pull-to-refresh",
	"over-the-top",
	"tour",
	"click-stop",
];

// if you edit this list, make sure to read the comment above

export const getZIndex = (name) => $layers.findIndex(layer => layer === name) + 1;

export const applyZIndex = (name) => `z-index: ${getZIndex(name)};`;
