import PropTypes from "prop-types";
import React from "react";
import { TabletLandscapeAndDesktop, MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { EUIKey, useCloseModal, useOpenModal } from "../../contexts/UI";
import {
	BannerButton,
	BannerText,
	BannerWrap,
	OnboardingBar,
	OnboardingProgress,
	OnboardingProgressText,
} from "./OnboardingBanner.styles";
import { OnboardingModal, ONBOARDING_MODAL } from "./OnboardingModal";

export const ONBOARDING_BANNER = "ONBOARDING_BANNER";

export const OnboardingBanner = ({ success }) => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const { tasks } = useMemoizedContext("onboarding", ["tasks"]);

	React.useEffect(() => {
		trackEvent({
			category: "onboarding",
			label: "banner",
			action: "view",
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const completedTasks = tasks?.filter((task) => task.completed);
	const completed = completedTasks?.length;
	const total = tasks?.length;

	if (
		completed === total
		|| success
	) {
		return (
			<BannerWrap>
				<BannerText>
					{getTranslation("frontend.onboarding.bannerSuccessText", true)}
				</BannerText>
			</BannerWrap>
		);
	}

	const handleCloseClick = () => {
		trackEvent({
			category: "onboarding",
			label: "modal-close",
			action: "click",
		});
		closeModal(ONBOARDING_MODAL);
	}

	const handleContinueClick = () => {
		trackEvent({
			category: "onboarding",
			label: "banner-continue",
			action: "click",
		});
		openModal({
			[EUIKey.MODAL_CLOSE_METHOD]: handleCloseClick,
			[EUIKey.MODAL_COMPONENT]: <OnboardingModal/>,
			[EUIKey.MODAL_LABEL]: ONBOARDING_MODAL,
			[EUIKey.MODAL_SUBTYPE]: "onboarding",
		});
	}

	return (
		<BannerWrap>
			<BannerText>
				<span>
					{getTranslation("frontend.onboarding.bannerTextMobile", true)}
				</span>
				<BannerButton
					kind="text"
					size="small"
					onClick={handleContinueClick}
				>
					{getTranslation("frontend.generics.continue", true)}
				</BannerButton>
			</BannerText>
			<OnboardingBar>
				<OnboardingProgress $progress={completed/total}>
					<OnboardingProgressText>{completed}/{total}</OnboardingProgressText>
				</OnboardingProgress>
			</OnboardingBar>
		</BannerWrap>
	);
};

OnboardingBanner.defaultProps = {
	success: false,
};

OnboardingBanner.propTypes = {
	success: PropTypes.bool,
};