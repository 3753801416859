import { apiEndpoint } from "@frontend/Utils";

// These methods can be invoked via: "sermo.mobileApp.registerforpush("", "", "")"
//    See Boot-clinet.js for initialization.

window.sermo.isMobileApp = false;

const registerforpush = (deviceType, deviceToken, endpointArn) => {
	if (!window) {
		return;
	}

	apiEndpoint("api/mobile/registerforpush", {
		deviceType: deviceType,
		deviceToken: deviceToken,
		endpointArn: endpointArn,
	}).then(dataResponse => {
		dataResponse.json().then(body => {
			if (dataResponse.ok) {
				if (window.appInterface !== undefined) {
					window.appInterface.updateEndpointArn(body.endpointArn);
				} else if (window.webkit !== undefined) {
					if (window.webkit.messageHandlers.updateEndpointArn !== undefined) {
						window.webkit.messageHandlers.updateEndpointArn.postMessage(body.endpointArn);
					}
				} else {
					console.log(body.endpointArn);
				}
			}
		});
	});
};

export { registerforpush };

/**
 * This will be invoked by the mobile app once the application is ready.
 * The application will notify the mobile app that the frontend code is ready
 *  via the below method "mainApplicationReady".
 * @param {*} deviceType : enum - "Android", "iOS"
 * @param {*} advertisingId : guid
 */
const setMobileAppInitializationValues = (deviceType, advertisingId) => {
	if (!window) {
		return;
	}

	window.sermo.isMobileApp = true;
	window.sermo.mobileAppDevice = deviceType;
	window.sermo.mobileAppAdvertisingId = advertisingId;

	if (window.sermo.mobileAppDevice === "iOS") {
		// https://jira.sermo.com/browse/MSA-3109
		// window.open won't work in WKWebView,
		// instead a plain link is programmatically created to open the url in Safari.

		window.open = (url, target) => {
			console.log("Calling overridden window.open");
			const el = document.createElement("a");
			el.href = url.toString();
			el.target = target;
			document.body.append(el);
			el.click();
			el.remove();
		}
	} else {
		console.log("Override window.open not required");
	}
};

export { setMobileAppInitializationValues };

/**
 * This method will notify the mobile application that the main frontend application is ready.
 * Once the mobile app knows the application is ready, it can go ahead and invoke the
 * "setMobileAppInitializationValues" method above to give us the device type and advertising ID.
 */
const mainApplicationReady = () => {
	if (!window) {
		return;
	}

	if (window.appInterface !== undefined) {
		window.appInterface.mainApplicationReady();
	} else if (window.webkit !== undefined) {
		if (window.webkit.messageHandlers.mainApplicationReady !== undefined) {
			window.webkit.messageHandlers.mainApplicationReady.postMessage("");
		}
	}
};

export { mainApplicationReady };

const loggedIn = (id) => {
	if (!window) {
		return;
	}

	var messageJson = JSON.stringify({ memberId: id });

	if (window.appInterface !== undefined) {
		window.appInterface.loggedIn(messageJson);
	} else if (window.webkit !== undefined) {
		if (window.webkit.messageHandlers.loggedIn !== undefined) {
			window.webkit.messageHandlers.loggedIn.postMessage(messageJson);
		}
	}
};

export { loggedIn };

const loggedOut = () => {
	if (!window) {
		return;
	}

	if (window.appInterface !== undefined) {
		window.appInterface.loggedOut();
	} else if (window.webkit !== undefined) {
		if (window.webkit.messageHandlers.loggedOut !== undefined) {
			window.webkit.messageHandlers.loggedOut.postMessage("");
		}
	}
};

export { loggedOut };
