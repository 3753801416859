import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
import ScrollWrapper from "@components/ScrollWrapper/ScrollWrapper";
import { useMemoizedContext } from "@hooks/Hooks";
import typography from "../../../scss/typography.scss";
import styles from "./References.scss";

const References = ({ clickHandler }) => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);
	
	const { 
		references, 
	} = useMemoizedContext("postData", [
		"references",
	]);

	if (!references) {
		return null;
	}

	const onClickHandler = (e) => {
		e.eventSource = "references";
		clickHandler(e);
	};

	return (
		<div
			onClick={onClickHandler}
			styleName={
				classNames("styles.references", expanded
					? "styles.expanded"
					: "")
			}
			dangerouslySetInnerHTML={{ __html: references }}
		/>
	);
};

References.propTypes = {
	clickHandler: PropTypes.func,
};

References.defaultProps = {
	clickHandler: () => {},
};

export default References;
