import React from "react";
import Button from "@components/Button/Button";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { Header, SubHeader, SubmittedText, SubmittedWrap, Wrap } from "./AdFormConfirmation.styled";

const AdFormConfirmation = () => {
	const trackEvent = useTrackEvent();
	
	const {
		draftStatus,
	} = useMemoizedContext("postUI", [
		"draftStatus",
	]);
	const composing = DraftStatusTypes.COMPOSING === draftStatus;
	const editing = DraftStatusTypes.EDITING === draftStatus;
	
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);
	
	const {
		adForm,
		adId,
		following,
		// TODO: look into where isCommentingDisabled, isLikingDisabled values are coming from
		isCommentingDisabled,
		isLikingDisabled,
		type,
	} = useMemoizedContext("postData", [
		"adForm",
		"adId",
		"following",
		"isCommentingDisabled",
		"isLikingDisabled",
		"type",
	]);

	if ( !adForm ) { 
		return null;
	}

	const {
		confirmationMessageHeader,
		confirmationMessageSubHeader,
		ctaText,
		ctaUrl,
	} = adForm;

	const willRenderLikesAndComments = (!editing && !composing)
		&& (
			!isLikingDisabled
			|| (
				["Discussion", "PatientCase"].includes(type)
				&& !isCommentingDisabled
			)
			|| expanded
			|| following
		);
	const margin = willRenderLikesAndComments
		? "16px 0 8px 0"
		: "16px 0";

	const handleCtaClick = () => trackEvent({
		category: "lead-gen",
		label: "cta-btn",
		action: "click",
		area: "cta-btn",
		url: ctaUrl,
		adId,
	}, false);

	const ctaBtn = (
		<Button
			clickHandler={handleCtaClick}
			style="secondary"
			to={ctaUrl}
			external
			target="_blank"
			rel="noreferrer"
		>
			{ctaText}
		</Button>
	);

	return (
		<>
			{
				confirmationMessageHeader && (
					<Wrap
						$margin={margin}
						onClick={(e) => e.stopPropagation()}
					>
						<Header>{confirmationMessageHeader}</Header>
						{
							confirmationMessageSubHeader
							&& <SubHeader dangerouslySetInnerHTML={{ __html: confirmationMessageSubHeader }}/>
						}
						{ctaText && ctaUrl && ctaBtn}
					</Wrap>
				)
			}
			{
				!confirmationMessageHeader && (
					<SubmittedWrap
						$margin={margin}
						onClick={(e) => e.stopPropagation()}
					>
						<SubmittedText>{getTranslation("frontend.forms.submitSuccess", true)}</SubmittedText>
						{ctaText && ctaUrl && ctaBtn}
					</SubmittedWrap>
				)
			}
		</>
	)
}

export default AdFormConfirmation;