import React from "react";
import AdFormInput from "../AdFormInput";
import { CityStateZipWrap } from "./AddressInputs.styled";

const AddressInput = ({ data, form }) => {
	const StreetAddressData = data.find((field) => field.adFormFieldType === "Address");
	const CityData = data.find((field) => field.adFormFieldType === "City");
	const RegionData = data.find((field) => field.adFormFieldType === "State");
	const ZipCodeData = data.find((field) => field.adFormFieldType === "ZipCode");
	const CountryData = data.find((field) => field.adFormFieldType === "Country");

	return (
		<>
			{
				StreetAddressData && (
					<AdFormInput
						data={StreetAddressData}
						form={form}
					/>
				)
			}
			<CityStateZipWrap>
				{
					CityData && (
						<AdFormInput
							data={CityData}
							form={form}
						/>
					)
				}
				{
					RegionData && (
						<AdFormInput
							data={RegionData}
							form={form}
						/>
					)
				}
				{
					ZipCodeData && (
						<AdFormInput
							data={ZipCodeData}
							form={form}
						/>
					)
				}
			</CityStateZipWrap>
			{
				CountryData && (
					<AdFormInput
						data={CountryData}
						form={form}
					/>
				)
			}
		</>
	)
};

export default AddressInput;