import { DrugRatingSVG, HomeSVG, MessagesSVG, SurveySVG } from "@sermo/ui-components";
import { SurveysIcon, UnreadMessagesIcon } from "@components/NavMenu/NavMenu";
export const left = [
    {
        claim: "canAccessFrontendPulseFeed",
        icon: HomeSVG,
        label: "Home",
        to: "/feed",
        trackingLabel: "pulse",
    },
    {
        claim: "canAccessFrontendDrugRatings",
        icon: DrugRatingSVG,
        label: "Ratings",
        to: "/rate",
        trackingLabel: "rate",
    },
];
export const right = [
    {
        claim: "canAccessFrontendSurveys",
        component: (<SurveysIcon alternate key={"SurveysIcon"}/>),
        icon: SurveySVG,
        label: "Surveys",
        to: "/surveys",
        trackingLabel: "surveys",
    },
    {
        claim: "canAccessFrontendPrivateMessages",
        component: (<UnreadMessagesIcon alternate key={"UnreadMessagesIcon"}/>),
        icon: MessagesSVG,
        label: "Messages",
        to: "/messages",
        trackingLabel: "messages",
    },
];
export const rightPreview = right.map((props) => {
    return {
        ...props,
        component: undefined,
    };
});
