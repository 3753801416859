import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const initProfileData = ({ locale, availableDobYears, availableDobDays }) => {
	return {
		member: {
			locale: locale,
			username: "",
			isUsingRealName: false,
			firstName: "",
			lastName: "",
			primarySpecialtyId: "",
			secondarySpecialtyId: "",
			biography: "",
			emailAddress: "",
			telephone: "",
			mobileTelephone: "",
			worksAtSermo: false,
			workAddress: {
				addressLine1: "",
				addressLine2: "",
				city: "",
				region: "",
				postalCode: "",
				countryCode: "",
				availableRegionsForSelectedCountry: [],
			},
			residentialAddress: {
				addressLine1: "",
				addressLine2: "",
				city: "",
				region: "",
				postalCode: "",
				countryCode: "",
				availableRegionsForSelectedCountry: [],
			},
			placeOfEmployment: "",
			dateOfBirthYear: "",
			dateOfBirthMonth: "",
			dateOfBirthDay: "",
			profilePictureUrl: "",
			profileMissingFields: [],
		},
		availableSpecialties: [],
		fileIsRequiredForSpecialtyChange: false,
		memberAllowedToChangeUsername: false,
		availableRegions: [],
		availableAddressCountries: [],
		availableDobYears: availableDobYears || [],
		availableDobMonths: [
			{
				key: getTranslation("system.dateTime.january", true),
				value: "01",
			},
			{
				key: getTranslation("system.dateTime.february", true),
				value: "02",
			},
			{
				key: getTranslation("system.dateTime.march", true),
				value: "03",
			},
			{
				key: getTranslation("system.dateTime.april", true),
				value: "04",
			},
			{
				key: getTranslation("system.dateTime.may", true),
				value: "05",
			},
			{
				key: getTranslation("system.dateTime.june", true),
				value: "06",
			},
			{
				key: getTranslation("system.dateTime.july", true),
				value: "07",
			},
			{
				key: getTranslation("system.dateTime.august", true),
				value: "08",
			},
			{
				key: getTranslation("system.dateTime.september", true),
				value: "09",
			},
			{
				key: getTranslation("system.dateTime.october", true),
				value: "10",
			},
			{
				key: getTranslation("system.dateTime.november", true),
				value: "11",
			},
			{
				key: getTranslation("system.dateTime.december", true),
				value: "12",
			},
		],
		availableDobDays: availableDobDays || [],
		currentActiveSection: null,
		verificationCodeMethod: null,
	}
};

export const useAccountSettings = (onFetch, onDone, initData) => {
	const apiEndpoint = useApiEndpoint();
	const fetch = () => apiEndpoint("settings/getaccountsettings").then(dataResponse => {
		dataResponse.json().then(body => {
			onFetch && onFetch();
			if (dataResponse.ok) {
				let state = { ...initProfileData(initData) };

				state.member = {
					...state.member,
					...body.member,
				};
				state.member.workAddress = {
					...state.member.workAddress,
					...body.workAddress,
				};
				state.member.residentialAddress = {
					...state.member.residentialAddress,
					...body.residentialAddress,
				};

				if (!state.member.workAddress.countryCode) {
					state.member.workAddress.countryCode = body.defaultCountryCode;
				}

				if (!state.member.residentialAddress.countryCode) {
					state.member.residentialAddress.countryCode = body.defaultCountryCode;
				}

				state.availableSpecialties.push({
					key: "None",
					value: "none",
				});
				state.fileIsRequiredForSpecialtyChange = body.fileIsRequiredForSpecialtyChange;

				for (let i = 0; i < body.availableSpecialties.length; i++) {
					state.availableSpecialties.push({
						key: body.availableSpecialties[i].name,
						value: body.availableSpecialties[i].id,
					});
				}

				for (let i = 0; i < body.availableRegions.length; i++) {
					state.availableRegions.push({
						key: body.availableRegions[i].name,
						value: body.availableRegions[i].key,
						countryCode: body.availableRegions[i].countryCode,
					});

					if (state.member.workAddress.countryCode) {
						if (body.availableRegions[i].countryCode === state.member.workAddress.countryCode) {
							state.member.workAddress.availableRegionsForSelectedCountry.push({
								key: body.availableRegions[i].name,
								value: body.availableRegions[i].key,
							});
						}
					}

					if (state.member.residentialAddress.countryCode) {
						if (body.availableRegions[i].countryCode === state.member.residentialAddress.countryCode) {
							state.member.residentialAddress.availableRegionsForSelectedCountry.push({
								key: body.availableRegions[i].name,
								value: body.availableRegions[i].key,
							});
						}
					}
				}

				for (let i = 0; i < body.availableCountries.length; i++) {
					state.availableAddressCountries.push({
						key: body.availableCountries[i].name,
						value: body.availableCountries[i].key,
					});
				}
				onDone && onDone(state);
			} else {
				console.log("Error fetching account settings!");
			}
		}).catch(console.log);
	});

	return [fetch]
}
