import styled from "styled-components";

export const FeaturedItemContainer = styled.div`
	margin-left: -16px;
	margin-right: -16px;
	display: block;
	text-align: center;

	&.featured-item-container {
		line-height: 0; // prevents whitespace from being added to the top of the image.
	}

	&.featured-expanded {
		margin: 0 0 16px 0;
	}

	&.headline-and-read-more {
		padding-top: 17px;
	}

	&.is-expanded-to-fit img {
		width: 100%;
	}

	&.remove-top-padding {
		padding-top: 0;
	}

	&.remove-bottom-padding {
		margin-bottom: 0;
		padding-bottom: 0;
	}
`;

export const FeaturedItemAnchor = styled(FeaturedItemContainer).attrs({ as: "a" })``;

export const FeaturedImage = styled.img`
	max-width: 100%;
`;