import { TextStyled } from "@sermo/ui-components/unstable";
import React from "react";
import styled from "styled-components";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const Solved = styled(TextStyled).attrs({
    $kind: "overline02",
}) `
	padding: 3px 6px;
	display: inline-block;
	margin-bottom: 8px;
	border-radius: ${({ theme }) => theme.layout.borderRadius};
	background-color: ${({ theme }) => theme.color.support02};
	color: ${({ theme }) => theme.color.text05};
`;
const Unsolved = styled(Solved) `
	color: ${({ theme }) => theme.color.text03};
	background-color: ${({ theme }) => theme.color.uiBackgroundSecondary};
`;
const SolvedIndicator = () => {
    // TODO: check isSolved in post types
    const { isSolved, } = useMemoizedContext("postData", [
        "isSolved",
    ]);
    if (isSolved) {
        return <Solved>{getTranslation("frontend.card.solved")}</Solved>;
    }
    return <Unsolved>{getTranslation("frontend.card.unsolved")}</Unsolved>;
};
export default SolvedIndicator;
