import { Icon, HyperlinkSVG, XSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { linkHandler } from "@components/Helpers/Helpers";
import useAttachmentAPI from "@contexts/Attachments/useAttachmentAPI";
import { useTrackEvent } from "@frontend/tracking";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useGetPostTrackingCategory, useMemoizedContext } from "@hooks/Hooks";
import PostPropTypes from "@props/Post";
import layout from "../../scss/layout.scss";
import typography from "../../scss/typography.scss";
import styles from "./LinkPreview.scss";

const LinkPreviewContainer = styled.div`
	margin: 16px 0;
`;

const LinkPreview = (
	{
		clientEventHandler,
	}
) => {
	const attachmentAPI = useAttachmentAPI();
	const category = useGetPostTrackingCategory();
	const trackEvent = useTrackEvent();
	const [route, setRoute] = useState(false);

	const {
		draftStatus,
	} = useMemoizedContext("postUI", [
		"draftStatus",
	]);
	const composing = DraftStatusTypes.COMPOSING === draftStatus;
	const editing = DraftStatusTypes.EDITING === draftStatus;

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		id: postId,
		poll,
		sermoContentCardId,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"id",
		"poll",
		"sermoContentCardId",
	]);

	const {
		attachments: usedAttachments,
	} = useMemoizedContext("attachments", [
		"attachments",
	]);

	const webPage = usedAttachments.find((attachment) => ["WebPage", "YouTubeVideo"].includes(attachment.type));

	const remove = (e) => {
		e.preventDefault();
		attachmentAPI.deleteAttachment({ id: webPage.id });
	};

	const previewLinkClickHandler = (e, title, url) => {
		trackEvent({
			category,
			action: "click",
			label: "link",
			area: "content",
			url,
			urlTitle: title,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
		});
	};

	const onClick = (e, { route, internal }) => {
		if (internal) {
			e.preventDefault();
			setRoute(route);
		}

		previewLinkClickHandler(e, webPage.title, webPage.url);

		if (clientEventHandler) {
			clientEventHandler(e, webPage.title, webPage.url);
		}
	};

	const nonLinkAttachments = usedAttachments.filter(a => !["WebPage", "YouTubeVideo"].includes(a?.type));

	if (
		nonLinkAttachments?.length > 0
		|| poll?.questions?.length > 0
	) {
		return null;
	}

	if (webPage) {
		const { title, url, thumbnail, domain } = webPage;
		const link = linkHandler(url);
		const thumbnailUrl = thumbnail;

		let actualDomain = domain;
		if (!actualDomain) {
			actualDomain = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1];
		}

		return (
			<LinkPreviewContainer>
				<a
					styleName={
						classNames(
							"styles.link-container",
							"layout.row-margin-top-bottom",
							composing
								? "layout.row-expanded-content"
								: ""
						)
					}
					target="_blank"
					rel="noreferrer noopener"
					href={link.route && link.route}
					onClick={(e) => onClick(e, link)}
				>
					<div styleName="styles.link-preview">
						<div styleName="styles.image">
							{thumbnailUrl && <img src={thumbnailUrl} />}
							{!thumbnailUrl && <Icon src={HyperlinkSVG} />}
						</div>
						<div styleName="styles.content">
							{
								actualDomain && (
									<div styleName={classNames("styles.src", "typography.overline-02")}>{actualDomain}</div>
								)
							}
							{title && <div styleName={classNames("typography.heading-02", "styles.title")}>{title}</div>}
						</div>
						{
							(
								editing
								|| composing
							)
							&& (
								<div
									styleName="styles.x"
									onClick={remove}
								>
									<Icon src={XSVG} />
								</div>
							)
						}
					</div>
					{
						route && <Navigate
							to={route}
							replace
						/>
					}
				</a>
			</LinkPreviewContainer>
		);
	}

	return null;
};

LinkPreview.propTypes = {
	attachments: PostPropTypes.attachments,
	clientEventHandler: PropTypes.func,
};

LinkPreview.defaultProps = {
	clientEventHandler: () => {},
};

export default LinkPreview;
