import { PostUIEditingDefaultValues } from "./PostUIEditingContext";
export const PostUIEditingActionTypes = Object.freeze({
    CLEAR_SUGGESTED_TOPICS: "CLEAR_SUGGESTED_TOPICS",
    RESET_FILE_UPLOAD_ERROR: "RESET_FILE_UPLOAD_ERROR",
    SET_CAN_POST_FALSE: "SET_CAN_POST_FALSE",
    SET_CAN_POST_TRUE: "SET_CAN_POST_TRUE",
    SET_FILE_UPLOAD_ERROR: "SET_FILE_UPLOAD_ERROR",
    SET_FOCUSED_FALSE: "SET_FOCUSED_FALSE",
    SET_FOCUSED_TRUE: "SET_FOCUSED_TRUE",
    SET_SUGGESTED_TOPICS: "SET_SUGGESTED_TOPICS",
    START_SAVING: "START_SAVING",
    STOP_SAVING: "STOP_SAVING",
});
const PostUIEditingActionTypesValues = Object.values(PostUIEditingActionTypes);
const activeSaves = {};
window["activeSaves"] = activeSaves;
const PostUIEditingReducer = (state, action) => {
    switch (action.type) {
        case PostUIEditingActionTypes.CLEAR_SUGGESTED_TOPICS: {
            return {
                ...state,
                suggestedTopics: PostUIEditingDefaultValues.suggestedTopics,
            };
        }
        case PostUIEditingActionTypes.SET_CAN_POST_FALSE: {
            return {
                ...state,
                canPost: false,
            };
        }
        case PostUIEditingActionTypes.SET_CAN_POST_TRUE: {
            return {
                ...state,
                canPost: true,
            };
        }
        case PostUIEditingActionTypes.SET_FILE_UPLOAD_ERROR: {
            return {
                ...state,
                fileUploadError: action.payload.fileUploadError,
            };
        }
        case PostUIEditingActionTypes.RESET_FILE_UPLOAD_ERROR: {
            return {
                ...state,
                fileUploadError: "",
            };
        }
        case PostUIEditingActionTypes.SET_FOCUSED_FALSE: {
            return {
                ...state,
                focused: false,
            };
        }
        case PostUIEditingActionTypes.SET_FOCUSED_TRUE: {
            return {
                ...state,
                focused: true,
            };
        }
        case PostUIEditingActionTypes.SET_SUGGESTED_TOPICS: {
            return {
                ...state,
                suggestedTopics: action.payload.suggestedTopics,
            };
        }
        case PostUIEditingActionTypes.STOP_SAVING: {
            const { endpoint } = action.payload;
            if (activeSaves[endpoint]) {
                activeSaves[endpoint] = false;
            }
            // console.log("stop saving - activeSaves", activeSaves);
            if (Object.values(activeSaves).some((value) => value)) {
                return state;
            }
            return {
                ...state,
                saving: false,
            };
        }
        case PostUIEditingActionTypes.START_SAVING: {
            const { endpoint } = action.payload;
            if (!activeSaves[endpoint]) {
                activeSaves[endpoint] = true;
            }
            // console.log("start saving - activeSaves", activeSaves);
            if (state.saving) {
                return state;
            }
            return {
                ...state,
                saving: true,
                canPost: false,
            };
        }
        default:
            return state;
    }
};
export default PostUIEditingReducer;
