import { css } from "styled-components";
import {
	StyledMobileAndTabletPortraitQuery,
	StyledTabletLandscapeAndDesktopQuery,
} from "@components/MediaQueries/MediaQueries";
import { applyZIndex } from "./applyZIndex";

export const centerColumn = ({ theme }) => css`
	width: calc(100% - (${theme.layout.sitePadding} * 2));
	max-width: ${theme.layout.siteMaxWidth};
	min-height: 100vh;
	margin: 0 auto;
	overflow: hidden;
	position: relative;

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;

export const constrain = ({ theme }) => css`
	max-width: ${theme.layout.siteMaxWidth};
	margin: 0 auto;
	padding: 0 ${theme.layout.sitePadding};

	${StyledMobileAndTabletPortraitQuery} {
		padding: 0;
	}
`;

export const mobileLayout = ({ theme }) => css`
	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
		padding: 0 0 var(--top-nav-height-mobile);
		top: 0;

		.alert-banner-open-global & {
            top: calc(var(--top-nav-height-mobile) + ${theme.layout.alertModalHeight});
        }

		.mobile-nav-container & {
            top: calc(var(--top-nav-height-mobile) + ${theme.layout.mobileFeedNavHeight}) + ${theme.layout.sitePadding});
        }

		.mobile-nav-container + .alert-banner-open-global & {
            top: calc(var(--top-nav-height-mobile) + ${theme.layout.mobileFeedNavHeight} + ${theme.layout.alertModalHeight});
        }
    }
`;

export const layoutBase = ({ theme }) => css`
	${applyZIndex("feed")};
	top: calc(${theme.layout.contentPaddingTop});
	margin-bottom: calc(${theme.layout.topNavHeight} + ${theme.layout.contentPaddingTop} + 100px);
	position: relative;

	.alert-banner-open-global & {
		top: calc(${theme.layout.topNavHeight} + ${theme.layout.contentPaddingTop} + ${theme.layout.alertModalHeight});
	}

	${mobileLayout};
`;

export const layoutCenter = ({ theme }) => css`
	${layoutBase};
	--rails: calc(${theme.layout.leftRailWidth} + ${theme.layout.rightRailWidth} + (${theme.layout.railPadding} * 2));
	width: calc(100% - var(--rails));
	margin-left: calc(${theme.layout.leftRailWidth} + ${theme.layout.railPadding});
`;

export const layoutCenterExtended = ({ theme }) => css`
	${layoutCenter};
	margin: 0 auto;
	width: 70%;
`;

export const layoutTwoThirdsLeft = ({ theme }) => css`
	${layoutBase};
	margin-left: ${theme.layout.sitePadding};
	margin-right: calc(${theme.layout.rightRailWidth} + ${theme.layout.rightRailMargin} + ${theme.layout.sitePadding});
`;

export const layoutTwoThirdsRight = ({ theme }) => css`
	${layoutBase};
	margin-left: calc(${theme.layout.leftRailWidth} + ${theme.layout.leftRailMargin} + ${theme.layout.sitePadding});
	margin-right: ${theme.layout.sitePadding};
`;

export const layoutFoundation = () => css`
	width: 100%;
	position: relative;

	${StyledTabletLandscapeAndDesktopQuery} {
		width: var(--site-max-width);
		margin: 0 auto;
	}
`;

export const layoutWithRails = () => css`
	${layoutFoundation};
	${StyledTabletLandscapeAndDesktopQuery} {
		padding: 0 16px;
		display: flex;
		justify-content: center;
		gap: var(--cards-spacing);
	}
`;