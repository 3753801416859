import styled, { css } from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { constrain, layoutCenter } from "@styled";

export const FeedContainer = styled.div`
	${layoutCenter};
	${StyledTabletLandscapeAndDesktopQuery} {
		min-width: 560px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		padding-top: ${({ theme }) => theme.layout.cardsSpacing};
		margin-bottom: 116px;
	}
`;

const Layout = styled.div`
	min-height: calc(100vh - ${({ theme }) => theme.layout.topNavHeight});
	position: relative;
	${constrain};

	${({ $loggedIn }) => $loggedIn && css`
		max-width: 592px;
		margin-top: ${({ theme }) => theme.layout.topNavHeight};
	`};
`;

export const AuthenticatedLayout = styled(Layout)`
	padding-bottom: 100px;
	display: flex;
	justify-content: center;

	${StyledMobileAndTabletPortraitQuery} {
		max-width: 100%;
	}
`;

export const DMUnauthenticatedLayout = styled(Layout)`
	min-height: unset;
	display: flex;
`;

export const UnauthenticatedLayout = styled(Layout)`
	padding-top: ${({ theme }) => theme.layout.loggedOutFormPadding};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Messaging = styled.div`
	max-width: ${({ theme }) => theme.layout.siteMaxWidth};
	height: calc(100vh - ${({ theme }) => theme.layout.topNavHeight} - 5px);
	margin: 0 auto;
	position: relative;
	bottom: 0;
	display: flex;
	align-items: flex-start;

	${StyledMobileAndTabletPortraitQuery} {
		height: calc(100vh - 38px);
		top: ${({ theme }) => theme.layout.topNavHeightMobile};
	}
`;

export const SignInCTA = styled.div`
	margin-top: 12px;
	color: ${({ theme }) => theme.color.text02};
`;

