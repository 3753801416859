import React from "react";

export const FeedContext = React.createContext({
	affiliateMember: false,
	biography: "",
	blocked: false,
	brandAdministrator: false,
	commentCount: false,
	feedFiltersQuestions: true,
	feedFiltersDiscussions: true,
	feedFiltersPatientCases: true,
	feedFiltersMemberCountry: false,
	feedFiltersMemberSpecialties: false,
	followedPostCount: false,
	followersCount: false,
	following: false,
	followsCount: false,
	hiddenPostsCount: false,
	id: false,
	joinDate: "",
	likedPostsCount: false,
	location: "",
	page: 0,
	profilePic: "",
	postCount: false,
	showTourCTA: false,
	specialties: [],
	trialMember: false,
	username: "",
	verified: false,
	userType: "",
	update: () => {},
	updateUserFields: () => {},
});
