import { PopupMenu } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React from "react";
import Button from "@components/Button/Button";
import { StyledAlertText } from "@components/Modal/Modal.styles";
import GenericConfirmModal from "@components/Modal/Modals/GenericConfirmModal";
import ReportModal from "@components/Modal/Modals/ReportModal";
import { useCommentAPI } from "@contexts/Comment";
import { EUIKey, useCloseAlert, useCloseModal, useOpenAlert, useOpenModal } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const FollowModal = ({ undo, following }) => {
	const alertText = following
		? getTranslation("frontend.alerts.commentUnfollowed")
		: getTranslation("frontend.alerts.commentFollowed");

	return (
		<>
			<StyledAlertText>{alertText}</StyledAlertText>
			<Button
				clickHandler={undo}
				style="flatNeutral"
				size="small"
			>
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>
	);
};

const CommentMenu = (
	{
		toggleEdit,
		triggerRef,
	}
) => {
	const closeModal = useCloseModal();
	const closeAlert = useCloseAlert();
	const commentAPI = useCommentAPI();
	const member = useMemoizedContext("member");
	const openAlert = useOpenAlert();
	const openModal = useOpenModal();

	const {
		following,
		id: commentId,
		user: {
			id: userId,
		},
	} = useMemoizedContext("comment", [
		"following",
		"id",
		"user",
	]);

	const {
		type,
		user,
	} = useMemoizedContext("postData", [
		"type",
		"user",
	]);

	const undo = () => {
		commentAPI.followComment(!following);
		closeAlert();
	};

	const followToggle = () => {
		const action = following
			? "unfollow"
			: "follow";

		commentAPI.followComment();

		openAlert({
			[EUIKey.ALERT_LABEL]: `comment-${action}-${commentId}`,
			[EUIKey.ALERT_COMPONENT]: <FollowModal
				undo={undo}
				following={following}
			/>,
		});
	};

	const options = [];

	if (member.id === userId) {
		// own comment
		// edit
		options.push({
			label: getTranslation("frontend.generics.edit"),
			onClick: () => toggleEdit()
		});
		// delete
		options.push({
			label: getTranslation("frontend.generics.delete"),
			onClick: () => {
				openModal({
					[EUIKey.MODAL_LABEL]: "comment-delete",
					[EUIKey.MODAL_COMPONENT]: (
						<GenericConfirmModal
							id={commentId}
							cancelModalEventHandler={() => closeModal("comment-delete")}
							submitEventHandler={
								() => {
									commentAPI.deleteComment({ id: commentId });
									closeModal("comment-delete");
									openAlert({
										[EUIKey.ALERT_LABEL]: "comment-deleted",
										[EUIKey.ALERT_COMPONENT]: (
											<StyledAlertText>
												{getTranslation("frontend.alerts.commentDeleted")}
											</StyledAlertText>
										),
									});
								}
							}
							text={getTranslation("frontend.comments.deleteCommentText")}
							submitText={getTranslation("frontend.generics.delete")}
						/>
					),
					textLabel: getTranslation("frontend.comments.deleteComment"),
				});
			}
		});
	} else {
		// someone elses comment
		// and user is the post creator
		// best answer
		if ("PatientCase" === type && member?.id === user?.id) {
			options.push({
				label: getTranslation("frontend.comments.markAsBestAnswer"),
				onClick: () => {
					openModal({
						[EUIKey.MODAL_LABEL]: "mark-best",
						[EUIKey.MODAL_COMPONENT]: (
							<GenericConfirmModal
								id={commentId}
								cancelModalEventHandler={() => closeModal("mark-best")}
								submitEventHandler={
									() => {
										commentAPI.markBest();
										closeModal("mark-best");
										openAlert({
											[EUIKey.ALERT_LABEL]: "best-marked",
											[EUIKey.ALERT_COMPONENT]: (
												<StyledAlertText>
													{getTranslation("frontend.alerts.commentMarkedAsBest")}
												</StyledAlertText>
											),
										});
									}
								}
								text={getTranslation("frontend.comments.confirmMarkAsBest", true)}
								submitText={getTranslation("frontend.comments.markAsBestAnswer")}
							/>
						),
						[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.comments.markAsBestAnswer"),
					});
				}
			});
		}
		// save
		options.push({
			label: following
				? getTranslation("frontend.comments.unfollow")
				: getTranslation("frontend.generics.save"),
			onClick: followToggle,
		});

		options.push({
			label: getTranslation("frontend.generics.report"),
			onClick: () => {
				openModal({
					[EUIKey.MODAL_LABEL]: `report-comment-${commentId}`,
					[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.reportPost.reportComment", true),
					[EUIKey.MODAL_COMPONENT]: (
						<ReportModal
							id={commentId}
							type="comment"
							closeModalEventHandler={() => closeModal(`report-comment-${commentId}`)}
						/>
					),
				});
			}
		});
	}

	return (
		<PopupMenu
			triggerRef={triggerRef}
			position="fixed"
			placement="bottom-start"
			items={options}
		/>
	);
};

CommentMenu.propTypes = {
	toggleEdit: PropTypes.func.isRequired,
	triggerRef: PropTypes.object
};

export default CommentMenu;
