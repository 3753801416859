import ElementScrollContextProvider from "@components/ElementScrollContextProvider/ElementScrollContextProvider";
import PostViewer from "@components/PostViewer";
import { DetachedFooterProvider } from "@contexts/DetachedFooter";
import { PostUIDefaultValues } from "@contexts/PostUI";
import PostUIEditingProvider from "@contexts/PostUI/Editing/PostUIEditingProvider";
import PostUIProvider from "@contexts/PostUI/PostUIProvider";
import { PostUIViewingProvider } from "@contexts/PostUI/Viewing";
const CardWithContext = ({ state = {}, viewState = {}, ...rest }) => {
    const initialUIState = {
        ...PostUIDefaultValues,
        cardData: state,
    };
    const initialUIViewingState = {
        ...viewState,
        hidden: state.hidden,
    };
    return (<ElementScrollContextProvider>
			<PostUIProvider initialState={initialUIState}>
				<PostUIViewingProvider initialState={initialUIViewingState}>
					<PostUIEditingProvider>
						<DetachedFooterProvider>
							<PostViewer {...rest}/>
						</DetachedFooterProvider>
					</PostUIEditingProvider>
				</PostUIViewingProvider>
			</PostUIProvider>
		</ElementScrollContextProvider>);
};
export default CardWithContext;
