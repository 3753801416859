import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import InlineVideoPlayer from "@components/Card/components/VideoPlayer/InlineVideoPlayer";
import { ContentContainer, contentContainerClickHandler, maxLength, sanitizeLinks, useContentAreaClickEventHandler, useInlineVideos, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import ReadMore from "@components/PostContent/components/ReadMore";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./SermoContentInFeed.styles";
const SermoContentInFeed = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const inlineVideos = useInlineVideos();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { body, contentHtml, id, originalContentHtml, } = useMemoizedContext("postData", [
        "body",
        "contentHtml",
        "id",
        "originalContentHtml",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : body || contentHtml;
        // Check for max length of string, and shorten accordingly
        if (editorContent.length > maxLength) {
            // The initial trimmed string
            let trimmedString = editorContent.substring(0, maxLength);
            // Trim again to avoid breaking a word and add ...
            trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            return `${trimmedString}...<span id=read-more-portal-${id}></span>`;
        }
        return sanitizeLinks(editorContent);
    }, [
        body,
        contentHtml,
        id,
        originalContentHtml,
        showOriginalContent,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				{inlineVideos?.map((iv, i) => (<InlineVideoPlayer el={iv} key={i}/>))}
				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}
				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={"bodyLong02"} $legacy={true} onClick={contentAreaClickEventHandler}/>
					<ReadMore />
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentToDisplay,
        inlineVideos,
        id,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
    ]);
};
export default SermoContentInFeed;
