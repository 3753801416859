import PropTypes from "prop-types";
import React, { useState } from "react";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { getUrlParameter } from "@frontend/Utils";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import styles from "./AffiliateMember.scss";
import Modal from "./Modal";

const AffiliateMemberModal = ({ actionHandler }) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);

	const {
		affiliateMember,
		updateMember,
		rights: currentRights,
	} = useMemoizedContext("member", [
		"affiliateMember",
		"hasAcceptedTermsAndConditions",
		"rights",
	]);

	const handleAcceptClick = () => {
		setLoading(true);

		apiEndpoint("settings/completeaffiliatemembership", { acceptTermsAndConditions: true }).then((dataResponse) => {
			setLoading(false);
			dataResponse.json().then(({ rights }) => {
				if (dataResponse.ok) {
					const returnUrl = getUrlParameter("returnUrl");
					const updatedRights = rights || {};
					if ("string" === typeof returnUrl) {
						window.location = returnUrl;
					} else {
						if (!affiliateMember) {
							updateMember({
								hasAcceptedTermsAndConditions: true,
								rights: {
									...currentRights,
									...updatedRights,
								},
							});
							window.location.reload();
						} else {
							updateMember({
								affiliateMember: false,
								hasAcceptedTermsAndConditions: true,
								hasBeenAuthenticatedViaToken: false,
								isTemporarilyAuthenticated: true,
								hasSetPassword: true,
								hasConfiguredProfile: true,
								rights: {
									...currentRights,
									...updatedRights,
								},
							});
						}
					}
					actionHandler && actionHandler();
				}
			});
		});
	};

	return (
		<div styleName="styles.affiliate-member">
			<ModuleWrapper padded={false}>
				<Modal
					actionHandler={handleAcceptClick}
					loading={loading}
				/>
			</ModuleWrapper>
		</div>
	);
};

AffiliateMemberModal.propTypes = {
	actionHandler: PropTypes.func,
};

export default AffiliateMemberModal;
