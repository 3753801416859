import {
	ColorGrey00,
	ColorRed50,
	ColorGrey100,
	ColorWhite,
	ColorUltraviolet50,
} from "@sermo/design-tokens";
import styled from "styled-components";
import {
	StyledMobileAndTabletPortraitQuery,
	StyledTabletLandscapeAndDesktopQuery,
} from "@components/MediaQueries/MediaQueries";

export const LoggedOutStyles = styled.div`
	& a {
		color: ${ColorUltraviolet50};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

    &.logged-out {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.preview {
            min-height: 100%;

            &:not(.has-access-to-preview) {
                padding-top: ${props => props.theme.layout.topNavHeight};
            }
        }

        &:not(.preview) {
            padding-top: ${props => props.theme.layout.topNavHeight};
        }

        ${StyledMobileAndTabletPortraitQuery} {
            width: 100%;
            margin-top: 0;
        }

        .main-content {
            height: 100%;
            flex-shrink: 1;
            min-height: min-content;
            background-color: ${ColorGrey00};
            overflow: auto;

            ${StyledTabletLandscapeAndDesktopQuery} {
                min-width: ${props => props.theme.layout.siteMaxWidth};
            }
        }

        .top-nav {
            height: 62px;
        }
    }

    .form-title,
    .form-title-smaller,
    .form-title-smaller--no-padding,
    .form-subtitle,
    .form-subtitle-smaller {
        color: ${ColorGrey100};
        display: block;
    }

    .form-subtitle {
        margin-top: 4px;

        p:last-child {
            padding-top: 12px;
        }
    }

    .content-box,
    .content-box-wider,
    .content-box-widest {
        width: 480px;
        display: inline-block;
        position: relative;
        background-color: ${ColorWhite};
        text-align: left;

        .content-box-inner-container {
            padding: 40px;

            ${StyledMobileAndTabletPortraitQuery} {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        ${StyledMobileAndTabletPortraitQuery} {
            width: 100%;
        }
    }

    .content-box-wider {
        width: 592px;
        flex-grow: 0;

        .content-box-inner-container,
		.content-box-inner-container-vouched {
            padding: 32px;
            flex-grow: 1;
        }

        ${StyledMobileAndTabletPortraitQuery} {
            width: 100%;

            .content-box-inner-container,
			.content-box-inner-container-vouched {
                padding: 32px 16px;
            }
        }
    }

    .content-box-widest {
        width: 900px;

        .content-box-inner-container {
            padding: 48px 64px;
        }

		.content-box-inner-container-vouched {
			padding: 24px 64px 48px;
		}

        ${StyledMobileAndTabletPortraitQuery} {
            width: 100%;

            .content-box-inner-container {
                padding: 32px 16px;
            }

			.content-box-inner-container-vouched {
				padding: 16px 16px 32px;
			}
        }
    }

    .enter-name-desktop-width {
        width: 952px;

        ${StyledMobileAndTabletPortraitQuery} {
            width: 100%;

            .content-box-inner-container {
                padding: 32px 16px;
            }
        }
    }

    .recaptcha-container {
        margin-bottom: 16px;

        span {
            color: ${ColorRed50};
        }
    }

    .global-validation-error {
        div:first-child {
            font-size: inherit;
            line-height: inherit;
        }
    }

    .showLoading {
        opacity: 0;
    }
`;

export const SubmitContainer = styled.div`
	margin-top: 48px;
	text-align: right;
`;

export const CancelButton = styled.button`
	margin-right: 20px;
	display: inline-block;
`;