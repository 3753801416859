import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorder } from "@frontend/styled";
export const MessageWindow = styled.div `
	height: ${({ $subtractFromHeight }) => `calc(100dvh - ${$subtractFromHeight}px);`};
	max-height: 100%;
	overflow-y: auto;
	${applyBorder("right bottom left")};
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex-shrink: 1;
	justify-content: stretch;
	background-color: ${({ theme }) => theme.color.uiBackground};
`;
export const CTA = styled(TextStyled) `
	background-color: ${({ theme }) => theme.color.ui09};
	min-height: 64px;
	padding: 16px 24px;
	display: flex;
	justify-content: space-between;
	color: ${({ theme }) => theme.color.text01};
	flex-grow: 0;
	flex-shrink: 0;
	align-items: center;
	cursor: pointer;

	& strong, & b {
		font-weight: 600;
	}
`;
export const Content = styled.div `
	padding-top: 24px;
	display: flex;
	position: relative;

	${StyledMobileAndTabletPortraitQuery} {
		overflow-y: unset;
		margin-top: 0;
		flex-direction: column;
		align-items: center;
	}
`;
export const ProfilePic = styled.div `
	position: absolute;
	top: 24px;
	left: 24px;
`;
export const Name = styled(TextStyled) `
	margin-bottom: 4px;
	color: ${({ theme }) => theme.color.text01};

	& strong, & b {
		font-weight: 600;
	}
`;
export const Message = styled.div `
	margin: 0 72px 12px 24px;
	padding-left: 48px;

	a {
		color: ${({ theme }) => theme.color.text01};
		text-decoration: underline;
	}
`;
export const MessageContent = styled(TextStyled) `
	padding-bottom: 24px;

	& strong, & b {
		font-weight: 600;
	}
`;
export const FooterLink = styled(TextStyled) `
	margin-bottom: 24px;
	font-weight: 500;
`;
export const Ad = styled.div `
	text-align: center;

	${StyledTabletLandscapeAndDesktopQuery} {
		--padding-sides: 24px;
		width: calc(300px + (var(--padding-sides) * 2));
		padding: 0 var(--padding-sides) 32px;
		${applyBorder("left")};
		flex-shrink: 0;
	}

	${StyledMobileAndTabletPortraitQuery} {
		--padding-sides: 16px;
		width: 100%;
		max-width: calc(300px + (var(--padding-sides) * 2));
		padding: 16px var(--padding-sides) 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	img {
		width: 100%;
	}
`;
export const Sponsored = styled(TextStyled) `
	margin-bottom: 12px;
	text-align: center;
	color: ${({ theme }) => theme.color.text03};

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 12px;
	}

	& strong, & b {
		font-weight: 600;
	}
`;
export const Footer = styled(TextStyled) `
	height: 264px;
	overflow-y: auto;
	${applyBorder("top")};
	padding: 25px 24px 0;
	flex-shrink: 0;

	a {
		color: ${({ theme }) => theme.color.text01};
		text-decoration: underline;
	}

	& strong, & b {
		font-weight: 600;
	}
`;
export const AdditionalFooterText = styled(Content) `
	${applyBorder("top")};

	${StyledMobileAndTabletPortraitQuery} {
		align-items: start;
		overflow-y: unset;
	}
`;
