import { TextStyled } from "@sermo/ui-components/unstable";
import React from "react";
import styled from "styled-components";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
const ResourceCenterTitle = styled(TextStyled).attrs({
    $kind: "bodyShort01",
}) `
	color: ${({ theme }) => theme.color.text01};
	display: inline;
	font-weight: 500;
	line-height: 32px;
`;
const InFeedTitle = styled(ResourceCenterTitle) `
	color: ${({ theme }) => theme.color.text03};
`;
const ResourceInFeedTitle = () => {
    const { cardType, resourceCenterTitle, } = useMemoizedContext("postData", [
        "cardType",
        "resourceCenterTitle",
    ]);
    if (CardTypeTypes.RESOURCE_CENTER_ITEM === cardType) {
        return (<InFeedTitle>
				Resource page in&nbsp;
				<ResourceCenterTitle>
					{resourceCenterTitle}
				</ResourceCenterTitle>
			</InFeedTitle>);
    }
    if (CardTypeTypes.RESOURCE_CENTER === cardType) {
        return (<InFeedTitle>
				Resource center
			</InFeedTitle>);
    }
    return null;
};
export default ResourceInFeedTitle;
