import ModalBackground from "@components/ModalBackground/ModalBackground";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import Draft from "@components/NewPost/components/Draft";
import { DesktopTypeSelector } from "@components/NewPost/components/TypeSelector/TypeSelector";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useTogglePostEditor } from "@contexts/UI";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./NewPostDesktop.styles";
const NewPostDesktop = ({ onExit, publishCard, }) => {
    const openEditor = useTogglePostEditor();
    const user = useMemoizedContext("member");
    const { postEditorIsOpen, postEditorSelectedType, } = useMemoizedContext("ui", [
        "postEditorIsOpen",
        "postEditorSelectedType",
    ]);
    const setFocusedFalse = () => {
        if (onExit) {
            onExit();
        }
        openEditor(false);
    };
    const onPublish = (card) => {
        setFocusedFalse();
        if (publishCard) {
            publishCard(card);
        }
        window.document.scrollingElement.scrollTo(0, 0);
    };
    const onFocus = () => {
        openEditor(true);
    };
    return (<>
			{postEditorIsOpen && <ModalBackground clickHandler={setFocusedFalse}/>}
			<Styles.NewPostContainer data-component="NewPost" $focused={postEditorIsOpen} onClick={onFocus}>
				<ModuleWrapper border={true} solid={true} padded={false}>
					{!user.brandAdministrator && (<Styles.ProfilePicContainer>
								<ProfilePic user={user} size="large"/>
							</Styles.ProfilePicContainer>)}
					<DesktopTypeSelector />
					{PostTypeTypes.PATIENT_CASE === postEditorSelectedType
            && (<Draft type="PatientCase" publishCard={onPublish}/>)}
					{PostTypeTypes.DISCUSSION === postEditorSelectedType
            && (<Draft type="Discussion" publishCard={onPublish}/>)}
					{PostTypeTypes.QUESTION === postEditorSelectedType
            && (<Draft type="Question" publishCard={onPublish}/>)}
				</ModuleWrapper>
			</Styles.NewPostContainer>
		</>);
};
export default NewPostDesktop;
