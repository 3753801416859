import { ColorUltraviolet20, ColorUltraviolet50, ColorWhite } from "@sermo/design-tokens";
import { Button } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyTextColor } from "@frontend/styled";
import { StyledTabletLandscapeAndDesktopQuery } from "../MediaQueries/MediaQueries";

export const BannerWrap = styled.div`
	display: flex;
	align-items: center;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;
	}

	${StyledTabletLandscapeAndDesktopQuery} {
		& > *:not(:first-child) {
			margin-left: 36px;
		}
	}
`;

export const BannerText = styled(TextStyled).attrs({ $kind: "bodyShortMedium02" })`
	margin-right: 16px;
	${applyTextColor("text-05")};

	& > span {
		margin-right: 4px;
	}
`;

export const BannerButton = styled(Button)`
	${applyTextColor("text-05")};
	flex-shrink: 0;

	&:hover {
        background-color: ${ColorWhite};
        color: ${ColorUltraviolet50};
    }

	${StyledMobileAndTabletPortraitQuery} {
		margin-right: 4px;
        padding: 0;
        text-underline-offset: 2px;

		&:active {
            background-color: unset;
        }
    }
`;

export const OnboardingBar = styled.div`
	width: 264px;
	height: 16px;
	border-radius: 8px;
	background-color: ${ColorUltraviolet20}66; // 66 is 40% opacity

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
		margin: 12px 0 0 0;
	}
`;

export const OnboardingProgress = styled.div`
	width: ${({ $progress }) => `max(calc(100% * ${$progress}), 16%)`};
	height: 16px;
	padding: 0 8px;
	border-radius: 8px;
	background-color: ${ColorWhite};
	display: flex;
	align-items: center;
`;

export const OnboardingProgressText = styled(TextStyled).attrs({
	forwardedAs: "span",
	$kind: "tag01",
})`
	color: ${ColorUltraviolet50};
`;