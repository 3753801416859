import { useContext, useEffect } from "react";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
const ParentContextUpdater = ({ actionType, childContext, parentContext, parentUpdaterFunction, payloadKey, }) => {
    const currentContext = useContext(childContext);
    const previousContext = usePrevious(currentContext);
    const { dispatch } = useMemoizedContext(parentContext);
    const newCurrentContext = { ...currentContext };
    delete newCurrentContext.dispatch;
    const updateParentContext = () => {
        if (previousContext
            && JSON.stringify(currentContext) !== JSON.stringify(previousContext)) {
            if (parentUpdaterFunction) {
                parentUpdaterFunction(newCurrentContext);
            }
            else {
                dispatch({
                    type: actionType,
                    payload: {
                        [payloadKey]: newCurrentContext,
                    },
                });
            }
        }
    };
    useEffect(updateParentContext, [
        currentContext,
        previousContext,
    ]);
    return null;
};
export default ParentContextUpdater;
