import React from "react";
import { NavLink } from "react-router-dom";
import Comments from "@components/Card/components/Comments/Comments";
import CardContainer from "@components/PostViewer/components/CardContainer";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./CommentGroup.styles";
const CommentGroup = () => {
    const { comments, postId, postTitle, } = useMemoizedContext("postData", [
        "comments",
        "postId",
        "postTitle",
    ]);
    return (<CardContainer>
			<Styles.CommentOnContainer>
				<Styles.CommentOnText>
					<NavLink to={`/post/${postId}/expanded/comment/${comments[0].id}`}>
						<Styles.CommentOnPost>
							{getTranslation("frontend.card.comment.commentOn", false)}&nbsp;
							<Styles.CommentOnPostTitle dangerouslySetInnerHTML={{ __html: postTitle }}/>
						</Styles.CommentOnPost>
					</NavLink>
				</Styles.CommentOnText>
			</Styles.CommentOnContainer>
			{/* @ts-ignore*/}
			<Comments />
		</CardContainer>);
};
export default CommentGroup;
