import PropTypes from "prop-types";
import React from "react";
import { CheckBoxList } from "@components/FormFields/FormFields";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const filters = [
	{
		key: getTranslation("frontend.railCards.postedBy.specialty", true),
		value: "MySpecialties",
	},
	{
		key: getTranslation("frontend.railCards.postedBy.follow", true),
		value: "PeopleIFollow",
	},
];

const PostedBy = ({ trackingCategory }) => {
	const trackEvent = useTrackEvent();
	const {
		connection,
		searchUpdate,
	} = useMemoizedContext("search", [
		"connection",
		"searchUpdate",
	]);

	const changeHandler = (values) => {
		const selectedVals = [];
		values.forEach((selected, value) => {
			if (selected && value) {
				selectedVals.push({ value });
			}
		});

		if (selectedVals.length > 0) {
			trackEvent({
				category: trackingCategory,
				action: "selection",
				label: selectedVals
					.map((val) => val.value)
					.join("_")
					.toLowerCase(),
			});
			searchUpdate({ connection: selectedVals });
		} else {
			trackEvent({
				category: trackingCategory
					? trackingCategory
					: "search-results-filter-posted-by",
				action: "selection",
				label: "anyone",
			});
			searchUpdate({ connection: [] });
		}
	};

	return (
		<ModuleWrapper
			label={getTranslation("frontend.railCards.memberFilter.connection.label", true)}
			border={true}
			contextClass="left-rail"
		>
			<CheckBoxList
				className="full-width"
				name="postedBy"
				selectedOptions={connection}
				options={filters}
				contextClass="rail-module"
				handleChange={changeHandler}
				size="small"
			/>
		</ModuleWrapper>
	);
};

PostedBy.propTypes = {
	trackingCategory: PropTypes.string,
};

PostedBy.defaultProps = {
	trackingCategory: "search-results-filter-posted-by",
};

export default PostedBy;
