import { Portal } from "@sermo/ui-components";
import React from "react";
import CustomStyleContent from "@components/DetachedFooter/components/shared/CustomStyleContent";
import ExpandableFooter from "@components/DetachedFooter/components/shared/ExpandableFooter";
import { DirectMessageAdWrapper } from "@components/DetachedFooter/DetachedFooter.styles";
import { getStickyContainer } from "@components/DetachedFooter/utils";
import { useMemoizedContext } from "@hooks/Hooks";
const ResourceCenterFooter = ({ type, text, className, customStyle, }) => {
    const { footerOpen } = useMemoizedContext("detachedFooter", ["footerOpen"]);
    return (<Portal container={getStickyContainer(type)}>
			<DirectMessageAdWrapper footerOpen={footerOpen}>
				<ExpandableFooter type={type} className={className} customStyle={customStyle}>
					<CustomStyleContent type={type} customStyle={customStyle} text={text}/>
				</ExpandableFooter>
			</DirectMessageAdWrapper>
		</Portal>);
};
export default ResourceCenterFooter;
