import { ColorGrey00, ColorGrey60 } from "@sermo/design-tokens";
import styled from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const Wrap = styled.div`
	margin: ${({ $margin }) => $margin};
	border-radius: 8px;
	padding: 32px 16px;
	background: ${ColorGrey00};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > *:not(:first-child) {
		margin-top: 8px;
	}

	button {
		margin-top: 8px;
		padding: 6px 24px;
	}
`;

export const Header = styled.h2`
	margin: 0;
	text-align: center;
	color: rgb(16, 24, 48);
	font-size: 14px;
	font-family: Sailec, sans-serif;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.18px;
`;

export const SubHeader = styled(Header)`
	color: ${ColorGrey60};
	font-size: 13px;
	font-weight: normal;
	line-height: 18px;
	letter-spacing: 0;
`;

export const SubmittedWrap = styled.div`
	margin: ${({ $margin }) => $margin};
	padding: 12px 24px;
	border-radius: 8px;
	background: ${ColorGrey00};
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		padding: 4px 16px;
	}

	@media ${MobileAndTabletPortraitQuery} {
		padding: 8px 12px;

		button {
			padding: 4px 8px;
		}
	}
`;

export const SubmittedText = styled.p`
	color: ${ColorGrey60};
	font-size: 14px;
	font-family: Sailec, sans-serif;
	font-weight: normal;
	letter-spacing: 0;
	line-height: 28px;
`;