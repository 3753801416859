import PropTypes from "prop-types";
import { useEffect, useReducer, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { OnboardingBanner, ONBOARDING_BANNER } from "@components/Onboarding";
import { useTrackEvent } from "@frontend/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import { MobileAndTabletPortraitQuery } from "../../components/MediaQueries/MediaQueries";
import { EUIKey, useCloseBanner, useOpenBanner } from "../UI";
import { OnboardingContext } from "./OnboardingContext";
import {
	OnboardingReducer,
	SET_ASSOCIATED_ENDPOINTS,
	SET_ONBOARDING_TASKS,
	SET_HEADER,
	SET_SUB_HEADER,
} from "./OnboardingReducer";

const excludedPathsRegex = /^\/(runwithpeter|profile|ad-preview|barometer|read-only|firsttimelogin|accepttermsandconditions)/;

export const OnboardingProvider = ({ children, initialState }) => {
	const isMobile = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const closeBanner = useCloseBanner();
	const openBanner = useOpenBanner();
	const trackEvent = useTrackEvent();
	const apiEndpoint = useApiEndpoint();
	const location = useLocation();
	const isExcludedPath = excludedPathsRegex.test(location.pathname);
	const hasBeenClosedRef = useRef(false);
	const hasSeenSuccessRef = useRef(false);
	const [hasUncompletedTasks, setHasUncompletedTasks] = useState(false);
	const [hasCompletedAllTasks, setHasCompletedAllTasks] = useState(false);
	const [hasAlmostCompletedAllTasks, setHasAlmostCompletedAllTasks] = useState(false);
	const [state, dispatch] = useReducer(OnboardingReducer, initialState);
	const {
		bannerIsOpen,
		modalIsOpen,
	} = useMemoizedContext("ui", [
		"bannerIsOpen",
		"modalIsOpen",
	]);
	const {
		isFirstPageLoad,
		loggedIn,
		onboardingCompleted,
		onboardingExpired,
	} = useMemoizedContext("member", [
		"loggedIn",
		"isFirstPageLoad",
		"onboardingCompleted",
		"onboardingExpired",
	]);

	const closeOnboardingBanner = () => {
		trackEvent({
			category: "onboarding",
			label: "banner-close",
			action: "click",
		});
		hasBeenClosedRef.current = true;
		closeBanner(ONBOARDING_BANNER);
	};

	const openOnboardingBanner = (success = false) => {
		openBanner({
			[EUIKey.BANNER_CAN_CLOSE]: !isFirstPageLoad,
			[EUIKey.BANNER_CLOSE_METHOD]: closeOnboardingBanner,
			[EUIKey.BANNER_COMPONENT]: <OnboardingBanner success={success} />,
			[EUIKey.BANNER_LABEL]: ONBOARDING_BANNER,
			[EUIKey.BANNER_SUBTYPE]: "onboarding",
		});
	};

	const setTasks = (tasks) => {
		dispatch({
			type: SET_ONBOARDING_TASKS,
			payload: tasks,
		});
	}

	const setAssociatedEndpoints = (endpoints) => {
		dispatch({
			type: SET_ASSOCIATED_ENDPOINTS,
			payload: endpoints,
		});
	}

	const setHeader = (header) => {
		dispatch({
			type: SET_HEADER,
			payload: header,
		});
	}

	const setSubHeader = (subHeader) => {
		dispatch({
			type: SET_SUB_HEADER,
			payload: subHeader,
		});
	}

	const updateOnboardingApiCall = () => {
		apiEndpoint("onboarding/getmembertasks")
			.then((response) => {
				if (response.ok) {
					response.json().then((data) => {
						setTasks(data.tasks);
						let noOfCompletedTasks = 0;
						const associatedEndpoints = data.tasks.reduce((acc, cur) => {
							if (cur.completed) {
								noOfCompletedTasks++;
								return acc;
							}
							return cur?.associatedEndpoints
								? [...acc, ...cur.associatedEndpoints]
								: acc;
						}, []);
						setAssociatedEndpoints(associatedEndpoints);
						setHeader(data.header);
						setSubHeader(data.subHeader);

						// if they have tasks then they can view the onboarding banner
						setHasUncompletedTasks(data.tasks.length > 0);

						if (noOfCompletedTasks === data.tasks.length) {
							setHasCompletedAllTasks(true);
						}
						else if (noOfCompletedTasks + 1 === data.tasks.length) {
							setHasAlmostCompletedAllTasks(true);
						}
					});
				}
			});
	}

	useEffect(() => {
		if (
			loggedIn
			&& !hasBeenClosedRef.current
		) {
			updateOnboardingApiCall();
		}
	}, [
		hasBeenClosedRef,
		loggedIn,
	]);

	useEffect(() => {
		if (
			!bannerIsOpen
			&& !hasBeenClosedRef.current
			&& !isExcludedPath
			&& hasUncompletedTasks
			&& !onboardingCompleted
			&& !onboardingExpired
			&& isMobile
			&& !modalIsOpen
		) {
			openOnboardingBanner();
		}
	}, [
		bannerIsOpen,
		hasBeenClosedRef?.current,
		isExcludedPath,
		hasUncompletedTasks,
		onboardingCompleted,
		state,
		modalIsOpen,
	]);

	// open success banner once more when all tasks are completed
	useEffect(() => {
		if (
			!bannerIsOpen
			&& hasAlmostCompletedAllTasks
			&& hasCompletedAllTasks
			&& !hasSeenSuccessRef.current
			&& hasUncompletedTasks
			&& isMobile
			&& !modalIsOpen
		) {
			openOnboardingBanner(true);
			hasSeenSuccessRef.current = true;
		}
	}, [
		bannerIsOpen,
		hasAlmostCompletedAllTasks,
		hasCompletedAllTasks,
		hasSeenSuccessRef.current,
		hasUncompletedTasks,
		modalIsOpen,
	]);

	useEffect(() => {
		if (
			bannerIsOpen
			&& isExcludedPath
			&& !hasSeenSuccessRef.current
		) {
			closeBanner(ONBOARDING_BANNER);
		}
	}, [
		bannerIsOpen,
		hasSeenSuccessRef.current,
		isExcludedPath,
	]);

	useEffect(() => {
		if (modalIsOpen) {
			closeBanner(ONBOARDING_BANNER);
		}
	}, [
		modalIsOpen,
	]);

	useEffect(() => {
		if (
			!onboardingCompleted
			&& hasBeenClosedRef.current
			&& hasCompletedAllTasks
		) {
			apiEndpoint("onboarding/updatesettings", { isOnboardingCompleted: true });
		}
	}, [
		hasBeenClosedRef?.current,
		hasCompletedAllTasks,
	]);

	const providerValue = {
		...state,
		setAssociatedEndpoints,
		setTasks,
		updateOnboardingApiCall,
	}

	return (
		<OnboardingContext.Provider value={providerValue}>
			{children}
		</OnboardingContext.Provider>
	);
};

OnboardingProvider.propTypes = {
	children: PropTypes.node,
	initialState: PropTypes.shape({
		associatedEndpoints: PropTypes.arrayOf(PropTypes.string),
		tasks: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string,
			description: PropTypes.string,
			ctaURL: PropTypes.string,
			order: PropTypes.number,
			completed: PropTypes.bool,
			associatedEndpoints: PropTypes.arrayOf(PropTypes.string),
		})),
	}),
};

OnboardingProvider.defaultProps = {
	initialState: {},
};