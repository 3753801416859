import PropTypes from "prop-types";
import { SpecialtyProps } from "./Taxonomies";

const UserProps = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	username: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.number,
	profilePic: PropTypes.string,
	specialities: PropTypes.arrayOf(PropTypes.shape(SpecialtyProps)),
	following: PropTypes.bool,
	location: PropTypes.string,
	verified: PropTypes.bool,
	locale: PropTypes.string,
	loggedIn: PropTypes.bool,
	joinDate: PropTypes.string,
	brandAdministrator: PropTypes.bool,
	worksAtSermo: PropTypes.bool,
	biography: PropTypes.string,
};

export default UserProps;
