import { ColorGrey30 } from "@sermo/design-tokens";
import { Icon, Select } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const AddQuestionButtonContainer = styled.div `
	margin-top: 36px;

	& > div {
		width: 100%;
	}

	&& .icon-wrapper {
		margin: 0;
	}
`;
export const AnswerEditPlusMinus = styled.div `
	display: flex;
	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;
export const ClockIcon = styled(Icon) `
	margin-right: 8px;
	&& {
		path {
			fill: ${ColorGrey30};
		}
	}
`;
export const CustomSelect = styled(Select) `
	width: ${({ $displayWidth }) => $displayWidth || "100%"};
	background-color: white;

	& > div > div {
        font-size: 14px;
        font-family: Sailec, "Helvetica Neue", Helvetica, Arial, sans-serif;
        line-height: 20px;
        font-weight: 400;
    }
`;
export const PollAnswerEdit = styled.div `
	flex-grow: 1;

	& + div {
		margin-left: 16px;
		margin-right: 8px;
	}
`;
export const PollEditContainer = styled.div `
	margin-top: 16px;
	margin-bottom: 0;
	padding: 0;
`;
export const PollQuestionNumber = styled(TextStyled).attrs({
    $kind: "overline02",
}) `
	margin-right: -12px;
	color: ${({ theme }) => theme.color.text03};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const PollRemoveClick = styled(TextStyled).attrs({
    $kind: "bodyShort02",
}) `
	margin-left: auto;
	color: ${({ theme }) => theme.color.text03};
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;
export const QuestionContainer = styled.div `
	${p => p.$moreThanOne && `
		margin-bottom: 24px;
	`}
`;
export const Row = styled.div `
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
`;
export const QuestionTypeRow = styled(Row) `
	display: flex;

	& > * {
		align-self: center;
	}
`;
