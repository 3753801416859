import { Icon, DrugRatingSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import ModuleWraper from "@components/ModuleWrapper/ModuleWrapper";
import typography from "../../scss/typography.scss";
import styles from "./IndicationCard.scss";

const IndicationCard = ({ indication }) => {
	const { name, indicationId } = indication;

	const [redirect, setRedirect] = useState(false);

	const onClick = () => {
		setRedirect(`/rate/indication/${indicationId}`);
	};

	return (
		<div
			styleName="styles.drug"
			onClick={onClick}
		>
			{redirect && <Navigate to={redirect} />}
			<ModuleWraper solid={true}>
				<div styleName="styles.drug-wrapper">
					<div styleName="styles.left">
						<div styleName="styles.pic-wrapper">
							<Icon
								src={DrugRatingSVG}
								width={40}
								height={40}
							/>
						</div>
						<div styleName="styles.name-and-generic">
							<div styleName={classNames("styles.name", "typography.body-short-brand-00")}>{name}</div>
						</div>
					</div>
				</div>
			</ModuleWraper>
		</div>
	);
};

IndicationCard.propTypes = {
	indication: PropTypes.shape({
		name: PropTypes.string,
		indicationId: PropTypes.number,
	}),
};

export default IndicationCard;
