import React from "react";
export const PostUIEditingDefaultValues = {
    canPost: false,
    fileUploadError: "",
    focused: false,
    saving: false,
    suggestedTopics: [],
};
const PostUIEditingContext = React.createContext(PostUIEditingDefaultValues);
export default PostUIEditingContext;
