import { alertDefaultValues, bannerDefaultValues, modalDefaultValues, } from "@contexts/UI/UIContext";
import { expandedPostBannerHeightValue, topNavHeightValue } from "@styled/variables";
import { EUIKey } from "./UIContext.types";
export const UIActionTypes = Object.freeze({
    COLLAPSE_POST: "COLLAPSE_POST",
    COLLAPSE_POST_MOBILE: "COLLAPSE_POST_MOBILE",
    CLOSE_ALERT: "CLOSE_ALERT",
    CLOSE_BACKDROP: "CLOSE_BACKDROP",
    CLOSE_BANNER: "CLOSE_BANNER",
    CLOSE_MENU: "CLOSE_MENU",
    CLOSE_MODAL: "CLOSE_MODAL",
    CLOSE_POST_EDITOR: "CLOSE_POST_EDITOR",
    CLOSE_TOUR: "CLOSE_TOUR",
    EXPAND_POST: "EXPAND_POST",
    EXPAND_POST_MOBILE: "EXPAND_POST_MOBILE",
    OPEN_ALERT: "OPEN_ALERT",
    OPEN_BACKDROP: "OPEN_BACKDROP",
    OPEN_BANNER: "OPEN_BANNER",
    OPEN_MENU: "OPEN_MENU",
    OPEN_MODAL: "OPEN_MODAL",
    OPEN_POST_EDITOR: "OPEN_POST_EDITOR",
    OPEN_TOUR: "OPEN_TOUR",
    SET_FOOTER_HEIGHT: "SET_FOOTER_HEIGHT",
    SET_HEADER_HEIGHT: "SET_HEADER_HEIGHT",
    SET_POST_EDITOR_SELECTED_TYPE: "SET_POST_EDITOR_SELECTED_TYPE",
    SET_RELOAD_TOUR: "SET_RELOAD_TOUR",
    SET_SIDE_PANEL_LABEL: "SET_SIDE_PANEL_LABEL",
    SET_TYPE_SELECTOR_IS_OPEN: "SET_TYPE_SELECTOR_IS_OPEN",
    UPDATE_MODAL_ATTRIBUTES: "UPDATE_MODAL_ATTRIBUTES",
});
const UIActionTypesValues = Object.values(UIActionTypes);
const UIReducer = (state, { payload = {}, type, }) => {
    switch (type) {
        case UIActionTypes.COLLAPSE_POST_MOBILE:
            return {
                ...state,
                [EUIKey.BANNER_IS_OPEN]: null !== state[EUIKey.BANNER_COMPONENT],
                [EUIKey.EXPANDED_POST]: null,
            };
        case UIActionTypes.COLLAPSE_POST:
            return {
                ...state,
                [EUIKey.BANNER_IS_OPEN]: null !== state[EUIKey.BANNER_COMPONENT],
                [EUIKey.EXPANDED_POST]: null,
                [EUIKey.HEADER_HEIGHT]: state[EUIKey.HEADER_HEIGHT_CACHE] || state[EUIKey.HEADER_HEIGHT],
                [EUIKey.HEADER_HEIGHT_CACHE]: 0,
            };
        case UIActionTypes.CLOSE_ALERT:
            return {
                ...state,
                ...alertDefaultValues,
            };
        case UIActionTypes.CLOSE_BACKDROP:
            return {
                ...state,
                [EUIKey.BACKDROP_IS_OPEN]: false,
            };
        case UIActionTypes.CLOSE_BANNER:
            if (state[EUIKey.BANNER_LABEL] === payload[EUIKey.BANNER_LABEL]
                || !payload[EUIKey.BANNER_LABEL]) {
                return {
                    ...state,
                    ...bannerDefaultValues,
                };
            }
            return state;
        case UIActionTypes.CLOSE_MENU:
            return {
                ...state,
                [EUIKey.MENU_NAME]: null,
            };
        case UIActionTypes.CLOSE_MODAL:
            if (state[EUIKey.MODAL_LABEL] === payload[EUIKey.MODAL_LABEL]
                || !payload[EUIKey.MODAL_LABEL]) {
                return {
                    ...state,
                    ...modalDefaultValues,
                };
            }
            return state;
        case UIActionTypes.CLOSE_POST_EDITOR:
            return {
                ...state,
                [EUIKey.POST_EDITOR_IS_OPEN]: false,
            };
        case UIActionTypes.CLOSE_TOUR:
            return {
                ...state,
                [EUIKey.TOUR_IS_OPEN]: false,
            };
        case UIActionTypes.EXPAND_POST:
            return {
                ...state,
                ...payload,
                [EUIKey.BANNER_IS_OPEN]: false,
                [EUIKey.HEADER_HEIGHT]: topNavHeightValue + expandedPostBannerHeightValue,
                [EUIKey.HEADER_HEIGHT_CACHE]: state[EUIKey.HEADER_HEIGHT],
            };
        case UIActionTypes.EXPAND_POST_MOBILE:
            return {
                ...state,
                ...payload,
            };
        case UIActionTypes.OPEN_ALERT:
            return {
                ...state,
                ...alertDefaultValues,
                ...payload,
                [EUIKey.ALERT_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_BACKDROP:
            return {
                ...state,
                [EUIKey.BACKDROP_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_BANNER:
            return {
                ...state,
                ...bannerDefaultValues,
                ...payload,
                [EUIKey.BANNER_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_MODAL:
            return {
                ...state,
                ...modalDefaultValues,
                ...payload,
                [EUIKey.MODAL_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_POST_EDITOR:
            return {
                ...state,
                [EUIKey.POST_EDITOR_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_TOUR:
            return {
                ...state,
                [EUIKey.TOUR_IS_OPEN]: true,
            };
        case UIActionTypes.OPEN_MENU:
        case UIActionTypes.SET_FOOTER_HEIGHT:
        case UIActionTypes.SET_HEADER_HEIGHT:
        case UIActionTypes.SET_POST_EDITOR_SELECTED_TYPE:
        case UIActionTypes.SET_RELOAD_TOUR:
        case UIActionTypes.SET_SIDE_PANEL_LABEL:
        case UIActionTypes.SET_TYPE_SELECTOR_IS_OPEN:
        case UIActionTypes.UPDATE_MODAL_ATTRIBUTES:
        default:
            return {
                ...state,
                ...payload,
            };
    }
};
export default UIReducer;
