import { Icon, PlayCircleFilledSVG, usePrev } from "@sermo/ui-components";
import { Video } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useEffect, useRef, useState, useMemo } from "react";
import { useDebounce } from "react-use";
import { PlayButton, Poster, VideoPlayerContainer } from "@components/Card/components/VideoPlayer/VideoPlayer.styles";
import ScrollWrapper from "@components/ScrollWrapper/ScrollWrapper";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { fetchUrl } from "@frontend/Utils";
import {
	useGetPostTrackingCategory,
	useMemoizedContext,
	useProgressWithPostContext,
} from "@hooks/Hooks";
import { getPlaybackSpeedTrackingData } from "./VideoPlayer.utils";

const SponsoredVideoPlayer = ({
	featured,
	scrollY,
	trackingArea,
}) => {
	const {
		autoplay,
		autoplayWhenExpanded,
		autoplayWithSound,
		captionsUrl,
		disableFullscreen,
		disablePlaybackSpeed,
		disableQuality,
		disableRewindAndForward,
		inFeedAttachmentDisplayType,
		loop,
		thumbnail,
		url,
		videoChapters,
	} = featured;

	const [canPlay, setCanPlay] = useState(false);
	const [volume, setVolume] = useState(1); // default state is full volume but muted
	const previousVolume = useRef(volume);
	const [muted, setMuted] = useState(!autoplayWithSound);
	const prevMuted = usePrev(muted);
	const [autoPlayEnabled, setAutoPlayEnabled] = useState(true);
	const trackEvent = useTrackEvent();
	const { y: cardScrollY } = useMemoizedContext("elementScroll", ["y"]);
	const videoEl = useRef();
	const videoWrapperEl = useRef();
	const playback = useRef();
	const [playing, setPlaying] = useState(false);
	const [played, setPlayed] = useState(false);
	const [userPlayed, setUserPlayed] = useState(false);
	const [userPaused, setUserPaused] = useState(false);
	const codeEvent = useRef(false);
	const { active } = useMemoizedContext("member", ["active"]);
	const { expandedPost } = useMemoizedContext("ui", ["expandedPost"]);
	const category = useGetPostTrackingCategory();

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		adType,
		cardType,
		clickTags,
		id,
		postId: resourcePostId,
		sermoContentCardId,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"adType",
		"cardType",
		"clickTags",
		"postId",
		"id",
		"sermoContentCardId",
	]);
	const postId = resourcePostId || id;

	const [speed, setSpeed] = useState();
	const progressRef = useRef(videoEl.current?.currentTime || 0);
	const timeRef = useRef();

	// variables used to control max amount of loops for ad videos
	const MAX_LOOP = 3;
	const loopCount = useRef(loop
		? MAX_LOOP
		: 0);
	const playedOnce = useRef(false);

	const area = trackingArea
		? trackingArea
		: "featured-video";

	const onProgress = useProgressWithPostContext(area);

	const onPause = () => {
		timeRef.current = undefined; // Avoids triggering fastforward tracking event on pause.
		setPlaying(false);
		if (codeEvent.current) {
			codeEvent.current = false;
			setUserPaused(false);
			setUserPlayed(false);
		} else {
			setUserPaused(true);
		}
		trackEvent({
			category,
			action: "click",
			label: "video-pause",
			value: 2,
			postId,
			adId,
			adFrequency,
			videoEventType: "Pause",
			area,
			expanded,
			sermoContentCardId,
		});
	};

	const onPlay = () => {
		playback.current = new Promise((resolve) => {
			setPlaying(true);
			setPlayed(true);

			if (codeEvent.current) {
				codeEvent.current = false;
				setUserPlayed(false);
				setUserPaused(false);
			} else {
				setUserPlayed(true);
			}
			resolve(true);
		});
		trackEvent({
			category,
			action: "click",
			label: "video-play",
			value: 2,
			postId,
			adId,
			adFrequency,
			videoEventType: "Play",
			area,
			expanded,
			sermoContentCardId,
		});
	};

	const isInView = () => {
		const rect = videoWrapperEl.current.getBoundingClientRect();
		return !(rect.top < 0 - rect.height || rect.top > window.innerHeight);
	}

	// uses various state values to determine if a video should actually play
	const updatePlayState = () => {
		const inView = isInView();
		const visible = (
			(
				!expanded
				&& !expandedPost
			)
			|| (
				adId === expandedPost?.cardData?.adId
				&& expanded
			)
		);

		const shouldAutoPlay = (
			!userPlayed
			&& !userPaused
			&& (
				(
					"InFeedUnit" === adType
					&& autoplay
				)
				|| (
					"SponsoredPost" === adType
                    && autoplay
					&& !expanded
				)
				|| (
					"InFeedUnit" !== adType
                    && autoplayWhenExpanded
					&& expanded
				)
			)
		);

		if (playing) {
			let shouldPause = false;
			// if its visible and playing in view status
			if (visible) {
				// handle user played and play/pause on scroll
				if (!expanded) {
					// it's not expanded so check in view
					if (!inView) {
						// it's not inView so don't play
						shouldPause = true;
					} else if (expandedPost) {
						shouldPause = true;
					}
				}
				// if it's not visible always pause
			} else {
				shouldPause = true;
			}

			// always pause when inactive
			if (!active) {
				shouldPause = true;
			}

			if (shouldPause) {
				// playing: pause
				setPlaying(false);
				codeEvent.current = true;
				if (playback?.current && videoEl?.current) {
					playback.current?.then(()=>{
						videoEl.current?.pause()
					});
				}
			}
		} else {
			// it's not playing should it be?
			let shouldPlay = false;

			if (shouldAutoPlay) {
				// if it's visible we only care about expanded / autoplay / user played
				if (visible) {
					// handle user played and play/pause on scroll
					if (!expanded) {
						// it's not expanded so check in view
						if (inView) {
							// it's in view so play it
							shouldPlay = true;
						}
					} else {
						// it should autoplay and its visible and expanded so don't pause
						shouldPlay = true;
					}
				}
			}

			if (!active) {
				shouldPlay = false;
			}

			if (shouldPlay && canPlay) {
				codeEvent.current = true;
				playback.current = videoEl.current?.play();
				playback.current.then(function(e) {
					// Automatic playback started!
					setPlaying(true)
					setAutoPlayEnabled(true);
					codeEvent.current = false;
				}).catch(function(error) {
					// Automatic playback failed.
					setAutoPlayEnabled(false);
					// eslint-disable-next-line no-console
					console.log(error);
					codeEvent.current = false;
				});
			}
		}
	};

	useDebounce(
		() => {
			if (speed) {
				const playbackSpeedTrackingData = getPlaybackSpeedTrackingData(speed);
				trackEvent({
					category,
					action: "click",
					postId,
					adId,
					adFrequency,
					area,
					expanded,
					sermoContentCardId,
					...playbackSpeedTrackingData,
				});
			}
		},
		1000,
		[speed]
	);

	const VOLUME_DEBOUNCE = 500;
	// handle tracking of volume changes
	useDebounce(() => {
		if (volume < previousVolume.current) {
			trackEvent({
				category,
				action: "click",
				label: "video-change-volume",
				value: 23,
				postId,
				adId,
				adFrequency,
				videoEventType: "VolumeDecrease",
				area,
				expanded,
				sermoContentCardId,
			});
		}

		if (volume > previousVolume.current) {
			trackEvent({
				category,
				action: "click",
				label: "video-change-volume",
				value: 22,
				postId,
				adId,
				adFrequency,
				videoEventType: "VolumeIncrease",
				area,
				expanded,
				sermoContentCardId,
			});
		}

		previousVolume.current = volume;
	}, VOLUME_DEBOUNCE, [volume]);

	useEffect(() => {
		// so it doesn't fire track event on initial render
		if (undefined === prevMuted) {
			return () => {};
		}

		if (muted) {
			trackEvent({
				category,
				action: "click",
				label: "video-mute",
				value: 26,
				postId,
				adId,
				adFrequency,
				videoEventType: "Mute",
				area,
				expanded,
				sermoContentCardId,
			});
		} else {
			trackEvent({
				category,
				action: "click",
				label: "video-unmute",
				value: 10,
				postId,
				adId,
				adFrequency,
				videoEventType: "Unmute",
				area,
				expanded,
				sermoContentCardId,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [muted]);

	const onVolumeChange = (event) => {
		setVolume(event.target?.volume);
		setMuted(event.target?.muted);
	}

	const onCaptionChange = (event, data) => {
		trackEvent({
			category,
			action: "click",
			label: `video-captions-${data.caption}`,
			value: 29,
			postId,
			adId,
			adFrequency,
			videoEventType: "CaptionChange",
			area,
			expanded,
			sermoContentCardId,
		});
	}

	const onChapterSelect = (chapterId) => {
		trackEvent({
			category,
			action: "click",
			label: `video-chapter-select`,
			value: 29,
			postId,
			adId,
			adFrequency,
			videoEventType: "ChapterSelect",
			area,
			expanded,
			sermoContentCardId,
			chapterId: chapterId,
		});
	}

	const onCloseChapterPopup = () => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			label: `video-chapter-list-hide`,
			value: 28,
			videoEventType: "ChapterMenuHide",
			area,
		});
	}

	// TODO: this is fired twice every time the user selects a menu option and is firing too often
	// const onCloseSettingsMenu = () => {
	// 	trackEvent({
	// 		category,
	// 		postId,
	// 		adId,
	// 		adFrequency,
	// 		expanded,
	// 		sermoContentCardId,
	// 		action: "click",
	// 		label: `video-settings-menu-hide`,
	// 		value: 33,
	// 		videoEventType: "SettingsMenuHide",
	// 		videoTime: videoEl.current.currentTime,
	// 		area,
	// 	});
	// }

	const onForwardClick = (event, data) => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			area,
			label: `video-button-fastforward`,
			skipDuration: data.skipDuration || 10,
			value: 12,
			videoEventType: "fastforward",
			videoTime: data.currentTime,
		});
	}

	const onFullScreen = (isFullScreen) => {
		if (isFullScreen) {
			trackEvent({
				category,
				action: "click",
				label: "video-fullscreen",
				value: 13,
				postId,
				adId,
				adFrequency,
				videoEventType: "VideoExpanded",
				area,
				expanded,
				sermoContentCardId,
			});
		}
		else {
			trackEvent({
				category,
				action: "click",
				label: "video-minimize",
				value: 25,
				postId,
				adId,
				adFrequency,
				videoEventType: "VideoMinimized",
				area,
				expanded,
				sermoContentCardId,
			});
		}
	}

	const onOpenChapterPopup = () => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			label: `video-chapter-list-show`,
			value: 27,
			videoEventType: "ChapterMenuShow",
			area,
		});
	}

	const onOpenSettingsMenu = () => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			label: `video-settings-menu-show`,
			value: 32,
			videoEventType: "SettingsMenuShow",
			videoTime: videoEl.current?.currentTime,
			area,
		});
	}

	const onProgressBarDrag = () => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			label: `video-progress-bar-drag`,
			value: 30,
			videoEventType: "ProgressBarDrag",
			videoTime: videoEl.current?.currentTime,
			area,
		});
	}

	// TODO: this is fired every time the user mouse leaves the progress bar and is firing too often
	// const onProgressBarDrop = () => {
	// 	trackEvent({
	// 		category,
	// 		postId,
	// 		adId,
	// 		adFrequency,
	// 		expanded,
	// 		sermoContentCardId,
	// 		action: "click",
	// 		label: `video-progress-bar-drop`,
	// 		value: 31,
	// 		videoEventType: "ProgressBarDrop",
	// 		videoTime: videoEl.current.currentTime,
	// 		area,
	// 	});
	// }

	const onRewindClick = (event, data) => {
		trackEvent({
			category,
			postId,
			adId,
			adFrequency,
			expanded,
			sermoContentCardId,
			action: "click",
			area,
			label: `video-button-rewind`,
			skipDuration: data.skipDuration || 10,
			value: 11,
			videoEventType: "rewind",
			videoTime: data.currentTime,
		});
	}

	const onRateChange = (event) => {
		setSpeed(event.target.playbackRate);
	}

	const onEnded = () => {
		progressRef.current = 0; // Avoids triggering rewind tracking event when restarting video.
		timeRef.current = undefined;
	}

	useEffect(() => {
		updatePlayState();
	},[])

	useEffect(() => {
		updatePlayState();
	}, [expandedPost, active, userPaused, userPlayed, playing, canPlay]);

	useEffect(() => {
		setVolume(videoEl.current?.volume);
		setMuted(videoEl.current?.muted);
	}, [canPlay]);

	// only update play state on scroll if autoplay is enabled
	useEffect(() => {
		if ( autoPlayEnabled ) {
			updatePlayState();
		}
	}, [scrollY, cardScrollY]);

	const onClick = (e) => {
		e.stopPropagation();
		e.eventSource = area;
		// if its paused we are playing it
		setUserPlayed(e.target.paused);
		setUserPaused(!e.target.paused);
	};

	const posterClick = (e) => {
		if (expanded
			|| "InFeedUnit" === adType
			|| "InFeedViewOnly" === inFeedAttachmentDisplayType
		) {
			e.stopPropagation();
			e.preventDefault();
			videoEl.current?.play().then(function() {
				// Automatic playback started!
				setUserPlayed(true);
			}).catch(function(error) {
				// Automatic playback failed.
				console.log(error);
				setAutoPlayEnabled(false);
			});
		} else {
			e.eventSource = area;
			if (clickTags?.imageVideoClickToExpand) {
				clickTags?.imageVideoClickToExpand.forEach((url) => {
					fetchUrl(url);
				})
			}
		}
	};

	const playClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		videoEl.current?.play().then(function() {
			// Automatic playback started!
			setUserPlayed(true);
		}).catch(function(error) {
			// Automatic playback failed.
			console.log(error);
			setAutoPlayEnabled(false);
		});
	};

	const changeLoop = (e) => {
		if (loopCount.current <= 0) {
			// hack to stop looping without reseting the video players state
			// in particular the mute value
			if ( e.target.duration - e.target.currentTime < 0.3 ) {
				videoEl.current?.pause();
			}

			return;
		}

		const percent = (e.target.currentTime / e.target.duration) * 100;

		if (percent > 50 && !playedOnce.current) { // playedOnce makes sure this does not happen >1x per loop
			if (loopCount.current === 1) {
				trackEvent({
					category,
					action: "end",
					label: "video-loop",
					value: 2,
					postId,
					adId,
					adFrequency,
					videoEventType: "Loop-Max-Met",
					area: trackingArea
						? trackingArea
						: "ad-video",
					expanded,
					sermoContentCardId,
				});
			}

			loopCount.current -= 1;
			playedOnce.current = true;
		}

		if (percent < 50 && playedOnce.current) {
			playedOnce.current = false;
		}
	}

	const onTimeUpdate = (e) => {
		// Condition avoids triggering rewind tracking event when restarting video.
		timeRef.current = e.target.currentTime
			? Date.now()
			: undefined;

		// handles time based tracking events
		onProgress(e);

		if (
			[
				CardTypeTypes.AD,
				CardTypeTypes.SERMO_CONTENT_CARD,
				CardTypeTypes.RESOURCE_CENTER_ITEM,
			].includes(cardType)
		) {
			changeLoop(e);
		}
	}

	const SEEK_DEBOUNCE = 200;
	const DELTA_CORRECTION = 0.001; // Ten milliseconds.

	useDebounce(() => {
		const now = Date.now();
		const DELTA_PAUSED = 0;
		const delta = !videoEl?.current?.paused
			? (now - (timeRef.current || now)) / 1000
			: DELTA_PAUSED;
		const deltaDebounce = !videoEl.current?.paused
			? SEEK_DEBOUNCE / 1000 // Secs.
			: 0;
		const currentTime = videoEl.current?.currentTime;
		let seekType;
		if (
			timeRef.current
			&& progressRef.current !== videoEl.current?.duration
			&& progressRef.current > currentTime
		) {
			seekType = "rewind";
		} else if (
			timeRef.current
			&& Math.round((currentTime - DELTA_CORRECTION) - ((progressRef.current + (delta * (speed || 1))) + deltaDebounce)) > 0
		) {
			seekType = "fastforward";
		}

		progressRef.current = currentTime;

		if (seekType) {
			trackEvent({
				category,
				action: "click",
				label: `video-${seekType}`,
				value: seekType === "rewind"
					? 11
					: 12,
				postId,
				adId,
				adFrequency,
				videoEventType: seekType,
				area,
				expanded,
				sermoContentCardId,
				videoTime: currentTime,
			});
		}
	}, SEEK_DEBOUNCE, [timeRef.current]);

	return useMemo(() => (
		<>
			<VideoPlayerContainer
				onClick={onClick}
				ref={videoWrapperEl}
			>
				<Video
					src={url}
					poster={thumbnail}
					ref={videoEl}
					// this SHOULD work but it currently causes the mute value to reset to its initial state
					// when it changes during playback
					// loop={Boolean(loopCount.current)}
					loop={loop}
					controls={played}
					onTimeUpdate={onTimeUpdate}
					onPause={onPause}
					onPlay={onPlay}
					onEnded={onEnded}
					onVolumeChange={onVolumeChange}
					onCanPlay={() => { setCanPlay(true); }}
					onCaptionChange={onCaptionChange}
					onChapterSelect={onChapterSelect}
					onCloseChapterPopup={onCloseChapterPopup}
					// onCloseSettingsMenu={onCloseSettingsMenu}
					onOpenChapterPopup={onOpenChapterPopup}
					onOpenSettingsMenu={onOpenSettingsMenu}
					onProgressBarDrag={onProgressBarDrag}
					// onProgressBarDrop={onProgressBarDrop}
					onForwardClick={onForwardClick}
					onRewindClick={onRewindClick}
					onFullscreen={onFullScreen}
					onPlaybackSpeedChange={onRateChange}
					autoPlay={autoplay}
					playsInline={true}
					muted={muted}
					disablePictureInPicture
					disableFullscreen={disableFullscreen}
					disablePlaybackSpeed={disablePlaybackSpeed}
					disableQuality={disableQuality}
					disableRewindAndForward={disableRewindAndForward}
					chapters={
						videoChapters?.map((chapter) => {
							return {
								chapterId: chapter.id,
								startTime: chapter.startTime,
								thumbnail: chapter.thumbnail,
								title: chapter.title,
							}
						})
					}
					captionSettings={
						captionsUrl
							? {
								defaultValue: "off",
								options: [
									{
										label: "English",
										src: captionsUrl,
										value: "en"
									}
								]
							}
							: undefined
					}
				/>
				{
					!played && (
						<>
							<Poster
								src={thumbnail}
								onClick={posterClick}
							/>
							<PlayButton
								onClick={playClick}
							>
								<Icon
									src={PlayCircleFilledSVG}
									width={64}
									height={64}
								/>
							</PlayButton>
						</>
					)
				}
			</VideoPlayerContainer>
		</>
	), [featured, played]);
};

SponsoredVideoPlayer.propTypes = {
	featured: PropTypes.object,
	scrollY: PropTypes.number,
	trackingArea: PropTypes.string,
};

const SponsoredVideoPlayerWithScroll = (params) => (
	<ScrollWrapper limit={1000 / 30}>
		<SponsoredVideoPlayer {...params} />
	</ScrollWrapper>
);
export default SponsoredVideoPlayerWithScroll;

