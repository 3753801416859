import { ColorWhite } from "@sermo/design-tokens";
import { Icon } from "@sermo/ui-components";
import styled from "styled-components";

export const CheckboxIconStyled = styled(Icon)`
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		path {
			fill: ${ColorWhite};
		}
`;

export const DropdownIconStyled = styled(Icon)`
	display: inline-block;
	margin-left: 8px;
	position: relative;
	top: 1px;
`;

export const WrapToggleGroup = styled.div`
	> div[role="group"] {
		display: flex;
		flex-direction: column;

		> *:not(:first-child) {
			margin-top: 16px;
			margin-left: 0;
		}
	}
`;

export const FormGroupWrap = styled.div`
        .__aaa {
            background: red;
        }

        position: relative;

        &.inline-block {
            display: inline-block;
        }

        .form-label-checkbox,
        .form-label-radiobutton {
        @include body-short-brand-03;
            color: $text-03;

            &.small {
            @include body-short-brand-02;

                .form-label-checkbox-title,
                .form-label-radiobutton-title {
                    padding-left: 32px;
                }
            }
        }

        .form-label {
        @include body-short-brand-01;
            color: $text-03;
            display: inline-block;
            padding: 0 0 4px 0;
        }

        .form-field-validation {
        @include body-short-brand-00;
            color: $text-03;
            padding: 0 0 0 4px;
            position: relative;
            display: block;
            white-space: pre-line;

            &.is-invalid {
                color: red;
                white-space: break-spaces;
            }
        }

        &.has-focus {
            .form-label {
                color: $interactive-01;
            }

            .form-dropdownlist,
            .form-textarea,
            .form-input {
                border-color: $interactive-01;
            }
        }

        textarea:focus::placeholder,
        input:focus::placeholder {
            color: transparent;
        }

        &.is-invalid {
            .form-field-validation {
                color: $support-01;
            }

            .form-dropdownlist,
            .form-textarea,
            .form-input {
                border-color: $support-01;
            }
        }

        .form-dropdownlist,
        .form-textarea,
        .form-input {
            /* Remove the input shadow on iOS. */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

        @include body-short-brand-02;
            color: $text-03;
        @include borderDarker(top right bottom left);
            border-radius: 4px;
            width: 100%;
            padding: 13px 12px 13px 12px;

        @include mobile-and-tablet-portrait {
        @include body-short-brand-03;
        }

            &::placeholder {
                color: $form-placeholder-color;
            }

            &:focus {
                outline: none;
            }

            path {
                fill: $icon-02;
            }
        }

        .form-dropdownlist {
            padding: 13px 34px 13px 12px;
        }

        select::-ms-expand {
            display: none;
        }

        .form-dropdownlist {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 0.01px;
            text-overflow: "";
        @include z-index(menu);
        }

        .form-dropdownlist-wrapper {
            position: relative;
        }

        .icon-wrapper {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            pointer-events: none;
        }

        &.has-content {
        }

        /* START : Check boxes */

        .form-label-checkbox,
        .form-label-radiobutton {
            box-sizing: content-box;
            display: block;
            width: auto;
            height: auto;
            min-height: 20px;
            min-width: 20px;
            position: relative;
            cursor: pointer;
            user-select: none;
            color: $ui-06;
        }

        .form-label-radiobutton {
            min-height: 20px;
            min-width: 20px;
        }

        .form-label-checkbox-title,
        .form-label-radiobutton-title {
            padding-left: 32px;
            line-height: 20px;
            display: inline-block;
        }

        .form-label-radiobutton-title {
            line-height: 20px;
        }

        .form-label-checkbox input,
        .form-label-radiobutton input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .form-checkbox-checkmark {
            height: 20px;
            width: 20px;
            border: 1px solid $ui-03;
            border-radius: 2px;
        }

        .form-checkmark-wrapper {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        .form-radiobutton-selected {
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% + 2px));
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid $active-ui-01;
            border-radius: 50%;
            margin-top: -2px;
        }

        .form-checkbox-subtitle,
        .form-radiobutton-subtitle {
            margin-top: 0;
            display: block;
            padding-left: 28px;

            &:empty {
                display: none;
            }
        }

        .form-label-checkbox:hover input ~ .form-checkbox-checkmark {
            border-color: $hover-ui-02;

        @include mobile-and-tablet-portrait {
            background-color: transparent;
        }
        }

        .form-label-radiobutton:hover input ~ .form-radiobutton-selected {
            border-color: $hover-interactive-01;
        }

        .form-label-checkbox input:checked ~ .form-checkbox-checkmark,
        .form-label-radiobutton input:checked ~ .form-radiobutton-selected {
            background-color: $interactive-01;
        }

        .form-radiobutton-selected:after {
            content: "";
            position: absolute;
            display: none;
        }

        .form-label-radiobutton input:checked ~ .form-radiobutton-selected:after {
            display: block;
        }

        .form-label-radiobutton .form-radiobutton-selected:after {
            left: 1px;
            top: 1px;
            height: 12px;
            width: 12px;
            border: 2px solid $ui-background;
            border-radius: 50%;
        }

        /* END : Check boxes */

        &.style--default {
        }

        &.style--flat {
            cursor: pointer;

            .form-dropdownlist,
            .form-textarea,
            .form-input {
                border: 1px solid transparent;
                padding-top: 0;
                padding-bottom: 0;
            }

            .form-dropdownlist {
                cursor: pointer;
                background-color: transparent;
            }
        }

        &.style--mobile-header {
            width: 200px;
        }

        &.style--rtl {
            select {
                padding-right: 27px;
            }

            &.context-feed {
                select {
                    font-weight: 500;
                }

                .icon-wrapper {
                    right: 0;
                }
            }
        }
`;