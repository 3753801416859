export const runWithPeterURL = "/runwithpeter";
export const runWithPeterExternalURL = "https://www.beyourpossible.com/runforresearch";
export const sermoSignUp = `https://www.sermo.com/RWP?utm_medium=registration_page&utm_source=sermo&utm_campaign=rwp`;
export const sermoSuccessSignUp = `/register/start?utm_medium=registration_page&utm_source=sermo&utm_campaign=rwp`;
export const mnowSuccessSignUp = `https://mnow.com/?utm_medium=registration_page&utm_source=sermo&utm_campaign=rwp`;
export const beYourPossibleUrl = "https://www.beyourpossible.com/";
export const noOfDoctorsParticipatingGoal = 5000;
export const stringOfDoctorsParticipatingGoal = noOfDoctorsParticipatingGoal.toLocaleString("en-US");
export const donationAmount = "$500,000";
export const dayOfEvent = "9";
export const isPostEvent = window.location.search.includes("isPostEvent=true") || false; // first part is for QA testing
