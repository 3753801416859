import { PostDataDefaultValues } from "./PostDataContext";
// TODO: use defaults from poll context ???
const pollDefaults = {
    duration: "Forever",
    id: 0,
    open: true,
    questions: [],
};
export const PostDataActionTypes = {
    ADD_COMMENT: "ADD_COMMENT",
    ADD_LINK: "ADD_LINK",
    ADD_MENTION: "ADD_MENTION",
    ADD_POLL: "ADD_POLL",
    ADD_REPLY: "ADD_REPLY",
    DELETE_COMMENT: "DELETE_COMMENT",
    DELETE_MENTION: "DELETE_MENTION",
    DELETE_POLL: "DELETE_POLL",
    DELETE_REPLY: "DELETE_REPLY",
    LOAD_DRAFT: "LOAD_DRAFT",
    LOAD_MORE_COMMENTS: "LOAD_MORE_COMMENTS",
    LOAD_MORE_REPLIES: "LOAD_MORE_REPLIES",
    SET_AD_FORM: "SET_AD_FORM",
    SET_COMMENTS: "SET_COMMENTS",
    SET_CONTENT_HTML: "SET_CONTENT_HTML",
    SET_DELETED_TRUE: "SET_DELETED_TRUE",
    SET_HAS_ANSWERED_TRUE: "SET_HAS_ANSWERED_TRUE",
    SET_MENTIONS: "SET_MENTIONS",
    SET_SUBTYPE: "SET_SUBTYPE",
    SET_TITLE: "SET_TITLE",
    SET_TOPIC: "SET_TOPIC",
    SET_TOPIC_ID: "SET_TOPIC_ID",
    SYNC_ATTACHMENTS: "SYNC_ATTACHMENTS",
    SYNC_COMMENT: "SYNC_COMMENT",
    SYNC_POLL: "SYNC_POLL",
    SYNC_POST_DATA: "SYNC_POST_DATA",
    TOGGLE_ANONYMOUS: "TOGGLE_ANONYMOUS",
    TOGGLE_FOLLOWING: "TOGGLE_FOLLOWING",
    TOGGLE_HIDDEN: "TOGGLE_HIDDEN",
    TOGGLE_IS_SOLVED: "TOGGLE_IS_SOLVED",
    TOGGLE_LIKED: "TOGGLE_LIKED",
    TOGGLE_USER_FOLLOWING: "TOGGLE_USER_FOLLOWING",
};
const PostDataReducer = (state, action) => {
    switch (action.type) {
        case PostDataActionTypes.ADD_COMMENT: {
            return {
                ...state,
                comments: [
                    action.payload.comment,
                    ...state.comments,
                ],
                commentsCount: state.commentsCount + 1,
            };
        }
        case PostDataActionTypes.ADD_LINK: {
            return {
                ...state,
                attachments: [
                    ...state.attachments,
                    {
                        type: "WebPage",
                        url: action.payload.url,
                    },
                ],
            };
        }
        case PostDataActionTypes.ADD_MENTION: {
            return {
                ...state,
                mentions: [
                    ...state.mentions,
                    action.payload.userId,
                ],
            };
        }
        case PostDataActionTypes.ADD_POLL: {
            return {
                ...state,
                poll: {
                    ...pollDefaults,
                    questions: [
                        action.payload.question,
                    ],
                    id: action.payload.question.pollId,
                },
            };
        }
        case PostDataActionTypes.ADD_REPLY: {
            return {
                ...state,
                comments: state.comments.map((comment) => {
                    if (comment.id === action.payload.commentId) {
                        return {
                            ...comment,
                            replies: [
                                action.payload.reply,
                                ...comment.replies,
                            ],
                        };
                    }
                    return comment;
                }),
            };
        }
        case PostDataActionTypes.DELETE_COMMENT: {
            return {
                ...state,
                comments: state.comments.filter((comment) => comment.id !== action.payload.comment.id),
                commentsCount: state.commentsCount - 1,
            };
        }
        case PostDataActionTypes.DELETE_MENTION: {
            return {
                ...state,
                mentions: state.mentions.filter((mention) => mention !== action.payload.userId),
            };
        }
        case PostDataActionTypes.DELETE_POLL: {
            return {
                ...state,
                poll: null,
            };
        }
        case PostDataActionTypes.LOAD_MORE_COMMENTS: {
            return {
                ...state,
                comments: [
                    ...state.comments,
                    ...action.payload.comments,
                ],
            };
        }
        case PostDataActionTypes.LOAD_DRAFT: {
            return {
                ...PostDataDefaultValues,
                ...action.payload.draft,
            };
        }
        case PostDataActionTypes.SET_AD_FORM: {
            return {
                ...state,
                adForm: {
                    ...state.adForm,
                    ...action.payload.adForm,
                },
            };
        }
        case PostDataActionTypes.SET_COMMENTS: {
            return {
                ...state,
                comments: action.payload.comments,
            };
        }
        case PostDataActionTypes.SET_CONTENT_HTML: {
            return {
                ...state,
                contentHtml: action.payload.contentHtml,
            };
        }
        case PostDataActionTypes.SET_DELETED_TRUE: {
            return {
                ...state,
                deleted: true,
            };
        }
        case PostDataActionTypes.SET_HAS_ANSWERED_TRUE: {
            return {
                ...state,
                hasAnswered: true,
            };
        }
        case PostDataActionTypes.SET_MENTIONS: {
            return {
                ...state,
                mentions: action.payload.mentions,
            };
        }
        case PostDataActionTypes.SET_SUBTYPE: {
            return {
                ...state,
                subType: action.payload.subType,
            };
        }
        case PostDataActionTypes.SET_TITLE: {
            return {
                ...state,
                title: action.payload.title,
            };
        }
        case PostDataActionTypes.SET_TOPIC: {
            return {
                ...state,
                topic: action.payload.topic,
                topicId: action.payload.topic.id,
            };
        }
        case PostDataActionTypes.SET_TOPIC_ID: {
            return {
                ...state,
                topicId: action.payload.topicId,
            };
        }
        case PostDataActionTypes.SYNC_ATTACHMENTS: {
            return {
                ...state,
                attachments: [
                    ...action.payload.context.attachments,
                ],
            };
        }
        case PostDataActionTypes.SYNC_COMMENT: {
            const findComment = state.comments.find((comment) => comment.id === action.payload.comment.id);
            if (!findComment) {
                return {
                    ...state,
                    comments: [
                        action.payload.comment,
                        ...state.comments,
                    ],
                    commentsCount: state.commentsCount + 1,
                };
            }
            return {
                ...state,
                comments: state.comments.map((comment) => {
                    if (comment.id === action.payload.comment.id) {
                        return {
                            ...comment,
                            ...action.payload.comment,
                        };
                    }
                    return comment;
                }),
            };
        }
        case PostDataActionTypes.SYNC_POLL: {
            return {
                ...state,
                poll: {
                    ...action.payload.poll,
                },
            };
        }
        case PostDataActionTypes.SYNC_POST_DATA: {
            return {
                ...state,
                ...action.payload.postData,
            };
        }
        case PostDataActionTypes.TOGGLE_ANONYMOUS: {
            return {
                ...state,
                isAnonymous: !state.isAnonymous,
            };
        }
        case PostDataActionTypes.TOGGLE_FOLLOWING: {
            if (action.payload?.isResourceCenterItem) {
                return {
                    ...state,
                    resourceCenterFollowing: !state.resourceCenterFollowing,
                };
            }
            return {
                ...state,
                following: !state.following,
            };
        }
        case PostDataActionTypes.TOGGLE_HIDDEN: {
            return {
                ...state,
                hidden: !state.hidden,
            };
        }
        case PostDataActionTypes.TOGGLE_IS_SOLVED: {
            return {
                ...state,
                isSolved: !state.isSolved,
            };
        }
        case PostDataActionTypes.TOGGLE_LIKED: {
            if (state.liked) {
                return {
                    ...state,
                    liked: false,
                    likes: state.likes - 1,
                };
            }
            return {
                ...state,
                liked: true,
                likes: state.likes + 1,
            };
        }
        case PostDataActionTypes.TOGGLE_USER_FOLLOWING: {
            return {
                ...state,
                user: {
                    ...state.user,
                    following: !state.user.following,
                },
            };
        }
        default:
            return state;
    }
};
export default PostDataReducer;
