import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { applyBorder, applyZIndex } from "@frontend/styled";
export const TabsContainer = styled.div `
	margin-top: 14px;
  	button {
	  padding: 10px;
	  text-align: left;
	}
`;
export const MobileContainer = styled.div `
    border: 4px solid ${({ theme }) => theme.color.uiBackground};
    border-radius: 50%;
    position: fixed;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    ${applyZIndex("mobile-post-creation")};
`;
export const MobileTypeSelectorContainer = styled.div `
	width: 100%;
	position: fixed;
	background-color: ${({ theme }) => theme.color.uiBackground};
	color: ${({ theme }) => theme.color.interactive01};
	${applyZIndex("mobile-post-creation")};
	top: 50px;
	bottom: 48px;
`;
export const SelectorHeader = styled(TextStyled) `
	height: 48px;
	padding: 8px 8px 8px 12px;
	${applyBorder("top bottom")};
	display: flex;
	align-items: center;

	& svg {
		margin-right: 16px;
	}
`;
export const SelectionsContainer = styled.div `
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
export const Selection = styled.div `
	border-radius: 8px;
	padding: 16px;
	border: 1px solid ${({ theme }) => theme.color.ui02};
	display: flex;
	gap: 16px;
	cursor: pointer;
`;
export const IconContainer = styled.div ``;
export const TextContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
export const Subtitle = styled(TextStyled) `
	color: ${({ theme }) => theme.color.text03};
`;
export const CreatePostButton = styled.button `
	height: 52px;
	width: 52px;
	background-color: ${({ theme }) => theme.color.interactive01};
	border-radius: 50%;
	padding-top: 3px;
	cursor: pointer;

	& svg {
		color: white;
	}
`;
