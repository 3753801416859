import classNames from "classnames";
import React from "react";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";

import styles from "./Search.scss";

const NoResults = ({ query }) => (
	<div styleName="styles.no-results">
		<div styleName={classNames("typography.heading-brand-04", "styles.nothing-to-see")}>
			{getTranslation("frontend.search.nothingToSee")}
		</div>
		{
			query && (
				<div styleName={classNames("typography.heading-brand-02", "styles.query-text")}>
					{getTranslation("frontend.search.queryTextNoResults", true, query)}
				</div>
			)
		}
		{/* <Button style="secondary">{getTranslation("frontend.search.tryAgain")}</Button> */}
	</div>
);

export default NoResults;
