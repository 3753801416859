import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Footer from "@components/Footer/Footer";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./Rail.styles";
const Rail = ({ children, className, hideRailFooter = false, notCoveredByLayoutFooter = false, side, }) => {
    const railContentRef = useRef(null);
    const [railContentBottom, setRailContentBottom] = useState("unset");
    const [railContentHeight, setRailContentHeight] = useState(0);
    const [spacerMarginTop, setSpacerMarginTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState("none");
    const [scrollPosition, setScrollPosition] = useState(0);
    const { footerHeight, headerHeight, } = useMemoizedContext("ui", [
        "footerHeight",
        "headerHeight",
    ]);
    const calculatedFooterHeight = useMemo(() => {
        if (notCoveredByLayoutFooter) {
            return 0;
        }
        return footerHeight;
    }, [
        notCoveredByLayoutFooter,
        footerHeight,
    ]);
    const [railContentTop, setRailContentTop] = useState(headerHeight + "px");
    const onRailContentResize = () => {
        if (railContentRef.current) {
            setRailContentHeight(railContentRef.current.clientHeight);
        }
    };
    useEffect(() => {
        const resizeObserver = new ResizeObserver(onRailContentResize);
        if (railContentRef.current) {
            resizeObserver.observe(railContentRef.current);
        }
        return () => {
            if (railContentRef.current) {
                resizeObserver.unobserve(railContentRef.current);
            }
            else {
                resizeObserver.disconnect();
            }
        };
    }, []);
    const calculateMarginTop = useCallback((scrollDirection) => {
        const scrollY = window.scrollY;
        // If the user is scrolling up and the bottom of the rail content is visible
        if ("up" === scrollDirection
            && window.innerHeight + scrollY > spacerMarginTop + railContentHeight) {
            // @ts-ignore
            const scrollMaxY = window.scrollMaxY || document.body.scrollHeight - window.innerHeight || 0;
            const maxYMinusScrollY = scrollMaxY - scrollY;
            let railViewableHeight = window.innerHeight - headerHeight;
            /*
            * the logic below is intended to handle the case where the main content has been completely scrolled,
            * in this scenario the rail content has been scrolled to the top of the footer, past the bottom of the
            * viewport, and needs to be handled differently so that the rail scroll does not jump around
            *
            * if the calculateFooterHeight is not zero, then the footer covers the rail content and no calculations
            * are needed, otherwise the rail content is not covered by the footer and the rail content needs to be
            * adjusted to the bottom of the viewport
            * */
            if (!calculatedFooterHeight
                && maxYMinusScrollY < footerHeight) {
                railViewableHeight -= (footerHeight - maxYMinusScrollY);
            }
            else {
                railViewableHeight -= calculatedFooterHeight;
            }
            const railExtraHeight = railContentHeight - railViewableHeight;
            const scrolledMinusExtra = scrollY - (railExtraHeight);
            return scrolledMinusExtra > 0
                ? scrolledMinusExtra
                : 0;
        }
        else if ( // If the user is scrolling down and the top of the rail content is visible
        "down" === scrollDirection
            && scrollY <= spacerMarginTop) {
            return scrollY;
        }
        return spacerMarginTop;
    }, [
        calculatedFooterHeight,
        footerHeight,
        headerHeight,
        railContentHeight,
        spacerMarginTop,
    ]);
    const handleScroll = useCallback(() => {
        if (railContentHeight + headerHeight + calculatedFooterHeight <= window.innerHeight) {
            setRailContentTop(headerHeight + "px");
            setRailContentBottom("unset");
            setSpacerMarginTop(0);
            return;
        }
        let tempScrollPosition = window.scrollY;
        let tempScrollDirection = scrollDirection;
        if (tempScrollPosition < scrollPosition) {
            tempScrollDirection = "up";
        }
        else if (tempScrollPosition > scrollPosition) {
            tempScrollDirection = "down";
        }
        if (tempScrollDirection !== scrollDirection) {
            if ("down" === tempScrollDirection) {
                setRailContentTop(`${window.innerHeight - (railContentHeight + calculatedFooterHeight)}px`);
                setRailContentBottom("unset");
                setSpacerMarginTop(calculateMarginTop(tempScrollDirection));
            }
            else if ("up" === tempScrollDirection) {
                setRailContentTop("unset");
                setRailContentBottom(`${window.innerHeight - (railContentHeight + headerHeight)}px`);
                setSpacerMarginTop(calculateMarginTop(tempScrollDirection));
            }
        }
        setScrollDirection(tempScrollDirection);
        setScrollPosition(tempScrollPosition);
    }, [
        calculatedFooterHeight,
        calculateMarginTop,
        headerHeight,
        railContentHeight,
        scrollDirection,
        scrollPosition,
    ]);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [
        handleScroll,
    ]);
    return (<TabletLandscapeAndDesktop>
			<Styles.RailContainer data-component={`${side}Rail`} $side={side}>
				<Styles.RailSpacer $marginTop={spacerMarginTop}/>
				<Styles.RailContent $bottom={railContentBottom} className={className} ref={railContentRef} $top={railContentTop}>
					{children}
					{"right" === side
            && !hideRailFooter
            && (<Footer />)}
				</Styles.RailContent>
			</Styles.RailContainer>
		</TabletLandscapeAndDesktop>);
};
export default Rail;
