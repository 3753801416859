import { ColorGrey30, ColorGrey60, ColorWhite } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { css, keyframes } from "styled-components";
import {
	StyledMobileAndTabletPortraitQuery,
	StyledTabletLandscapeAndDesktopQuery,
} from "@components/MediaQueries/MediaQueries";
import { applyShadow, applyTextColor, applyZIndex } from "@frontend/styled";

export const animationDuration = 300;

export const StyledModal = styled.div`
	&.modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		${applyZIndex("modal")};
		max-height: calc(100vh - (${p => p.theme.layout.topNavHeight} * 2) - 20px);

		.label {
			z-index: 0;
		}

		.x {
			background-color: ${ColorWhite};
			border-radius: 8px;
			z-index: 2;
		}

		.context-modal > div:not(.label, .x) {
			position: relative;
			z-index: 1;
		}

		${StyledTabletLandscapeAndDesktopQuery} {
			.context-modal {
				max-height: calc(100vh - (${p => p.theme.layout.topNavHeight} * 2 + 24px));
			}
		}

		${StyledMobileAndTabletPortraitQuery} {
			max-height: unset;
			position: fixed;
			transform: none;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			${(p) => css`
				top: ${({ theme }) => theme.layout.topNavHeightMobile};
				bottom: ${p.$footerHeight}px;
			`};

			& > div {
				height: 100%;
			}
		}
	}

	&.default {
		.x {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			position: absolute;
			top: 18px;
			right: 12px;
			cursor: pointer;

			${StyledMobileAndTabletPortraitQuery} {
				top: 22px;
				${applyZIndex("modal")};
			}
		}
	}

	&.over-the-top {
		${applyZIndex("over-the-top")};
	}

	&.new-message {
		max-height: 70vh;

		${StyledMobileAndTabletPortraitQuery} {
			max-height: 100vh;
		}
	}

	&.iframe {
		${StyledTabletLandscapeAndDesktopQuery} {
			top: ${p => p.theme.layout.topNavHeight};
			bottom: 0;
			transform: translate(-50%, 0);
			height: calc(100vh - ${p => p.theme.layout.topNavHeight});
			max-height: calc(100vh - ${p => p.theme.layout.topNavHeight});
			width: 100%;
			max-width: ${p => p.theme.layout.siteMaxWidth};
		}

		${StyledMobileAndTabletPortraitQuery} {
			top: ${p => p.theme.layout.topNavHeightMobile};
			bottom: 0;
			height: calc(100vh - ${p => p.theme.layout.topNavHeightMobile});
		}
	}

	&.onboarding {
		${StyledTabletLandscapeAndDesktopQuery} {
			max-width: 560px;
			border-radius: 8px;
			overflow: hidden;
		}

		${StyledMobileAndTabletPortraitQuery} {
			.x {
                top: 12px;
                right: 12px;
            }
        }
	}

	&.trial-member {
		& > div.module-wrapper {
            padding: 0;
			border: none;
        }
		.x {
			width: 24px;
			height: 24px;

			svg {
                color: ${ColorGrey60};
            }
		}
	}

	&.run-with-peter {
		border-radius: 8px;
		overflow: hidden;

		& > div.module-wrapper {
            padding: 0;
            border: none;
			border-radius: 10px 10px 8px 8px;
        }

		${StyledMobileAndTabletPortraitQuery} {
            border-radius: 0;

            & > div.module-wrapper {
                border-radius: 0;
            }
        }
	}

	&.has-top-image {
		& > div.module-wrapper {
			border: 0;
			padding: 0;
		}
	}

	&.feed-tour {
		${StyledTabletLandscapeAndDesktopQuery} {
			max-width: 600px;
		}

		${StyledMobileAndTabletPortraitQuery} {
			width: 85%;
			max-width: 600px;
			top: 50vh;
			bottom: unset;
			left: 50vw;
			right: unset;
			transform: translate(-50%, -50%);
			border-radius: 8px;
			overflow: hidden;
		}
	}
`;

export const StyledBanner = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	left: 0;
	padding-top: 14px;
	padding-bottom: 14px;
	height: ${p => p.theme.layout.alertModalHeight};
	${applyZIndex("alert-banner")};

	${StyledMobileAndTabletPortraitQuery} {
		height: auto;
		line-height: 1.5;
	}

	&.error,
	&.warning,
	&.information {
		.content {
			width: 100%;
			max-width: ${p => p.theme.layout.siteMaxWidth};
			padding: 0 ${p => p.theme.layout.sitePadding};

			& > div:not(.x) {
				width: 100%;
			}

			div.x {
				margin-left: 32px;
				width: auto;
			}
		}
	}

	.content {
		${StyledMobileAndTabletPortraitQuery} {
			padding: 0 16px;
		}
	}

	&.banner {
		background-color: ${p => p.theme.color.ui07};
	}

	&.error {
		background-color: ${p => p.theme.color.alertBannerErrorBackground};
		color: ${p => p.theme.color.text05};
	}

	&.warning {
		background-color: ${p => p.theme.color.alertBannerWarningBackground};
	}

	&.information, &.onboarding {
		background-color: ${p => p.theme.color.alertBannerInformationBackground};
		color: ${p => p.theme.color.text05};

		.x {
			svg {
				color: ${p => p.theme.color.text05};
			}
		}
	}

	&.information-darker {
		background-color: ${p => p.theme.color.alertBannerInformationDarkerBackground};
		color: ${p => p.theme.color.text05};
	}

	&.expanded-post-banner {
		background-color: ${p => p.theme.color.ui05};
		${applyZIndex("expanded-card")};

		${StyledTabletLandscapeAndDesktopQuery} {
			.content {
				max-width: calc(100vw - 40px);
				width: 840px;
			}
		}

		${StyledMobileAndTabletPortraitQuery} {
			padding: 0;
			background-color: ${(props) => props.theme.color.uiBackground};
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
			display: flex;

			.content {
				width: 100%;
				max-width: 100%;
				padding: 0 16px 0 0;
			}
		}
	}

	.x {
		position: relative;
		cursor: pointer;
	}

	&.onboarding {
		.content {
			width: 100%;
			max-width: ${p => p.theme.layout.siteMaxWidth};
			padding: 0 ${p => p.theme.layout.sitePadding};

			${StyledMobileAndTabletPortraitQuery} {
				align-items: flex-start;
			}

			& > div:not(.x) {
				width: 100%;

				${StyledMobileAndTabletPortraitQuery} {
                    align-items: flex-start;
                }
			}

			div.x {
				width: auto;
				margin-left: 32px;

                ${StyledMobileAndTabletPortraitQuery} {
                    margin-left: 0;
                    position: absolute;
                    right: 16px;
                }
            }
		}
	}
`;

export const alertSlideIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

export const StyledAlert = styled.div.attrs({
	dataComponent: "AlertModal"
})`
	position: fixed;
	bottom: 32px;
	left: 32px;
	${applyZIndex("modal")};
	animation: 1s ease-out 0s 1 ${alertSlideIn};
	${applyShadow("01")};

	${StyledMobileAndTabletPortraitQuery} {
		bottom: 82px;
	}

	&.fade-out {
		transition: opacity 500ms;
		opacity: 0;
	}
`;

export const StyledLabel = styled(TextStyled ).attrs({
	"data-component": "ModalTitle",
	className: "label",
	$kind: "heading02",
	forwardedAs: "div",
})`
	position: absolute;
	top: 24px;
	left: 24px;
	${applyTextColor("text-02")};
	letter-spacing: -0.15px;

	${StyledMobileAndTabletPortraitQuery} {
		max-width: 80%;
		top: 28px;
		left: 16px;
	}
`;

export const BannerContent = styled.div.attrs({
	className: "content",
})`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	max-width: var(--site-max-width);

  	> div {
	  display: flex;
	}

	a {
		cursor: pointer;
	}
	.information & {
     	 a {
        	color: ${p => p.theme.color.text05};
			text-decoration: underline;
      	}
    }
`;

export const StyledX = styled.div.attrs({
	className: "x",
})`
	color: ${ColorGrey30};
`;

export const StyledAlertX = styled.div.attrs({
	className: "x",
})`
	padding: 4px;
	margin-left: 8px;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: ${p => p.theme.color.hoverUi01};
	}

	svg {
		color: ${p => p.theme.color.icon02};
	}
`;

export const StyledAlertText = styled(TextStyled).attrs({
	$kind: "bodyShort02",
})`
	margin-right: 50px;
	${applyTextColor("text-01")};
`;

export const ModalContent = styled.div.attrs({
	className: "content",
})`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	a {
		cursor: pointer;
	}
`;