import { Popup } from "@sermo/ui-components";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import Button from "@components/Button/Button";
import { useFetch, useMemoizedContext, useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import ActivityItem from "../ActivityItem/ActivityItem";
import ActivityTabs from "../ActivityTabs/ActivityTabs";
import styles from "./ActivityMenu.scss";

const ActivityMenu = ({ triggerRef }) => {
	const apiEndpoint = useApiEndpoint();
	const el = useRef();
	const [filterBy, setFilterBy] = useState("general");
	const [height, setHeight] = useState("auto");
	const [lastHeight, setLastHeight] = useState(0);
	const { updateMember } = useMemoizedContext("member");

	const [data, loading] = useFetch("api/activity/getactivityfeed", {
		page: 0,
		filterby: filterBy,
	});

	useEffect(() => {
		if (!loading) {
			// when loading is finished save the new height for next time
			setLastHeight(el.current.getBoundingClientRect().height);
			// then remove the custom height
			setHeight("auto");

			// only mark all read on general tab
			if ("general" === filterBy) {
				apiEndpoint("activity/marknotificationsread", { filterBy: "general" }).then(() => {
					// setting variable to true so the NavMenu can use it to trigger a refresh
					window.sermo.user.shouldRefreshMemberNotifications = true;
					updateMember({
						shouldRefreshMemberNotifications: true,
					});
				});
			}
		}
	}, [loading]);

	// when the filter changes if last height is set use it for the transition
	useEffect(() => {
		if (lastHeight > 0) {
			setHeight(lastHeight);
		}
	}, [filterBy]);

	return (
		<Popup
			open
			triggerRef={triggerRef}
			position="fixed"
			placement="bottom-end"
		>
			<div styleName="styles.activity-menu">
				<div styleName={classNames("typography.heading-brand-03", "styles.header")}>
					{getTranslation("frontend.navMenu.yourNotifications")}
				</div>
				<ActivityTabs
					setFilterBy={setFilterBy}
					filterBy={filterBy}
				/>
				<div
					styleName="styles.notifications"
					ref={el}
					style={{ height }}
				>
					{
						!loading
							&& (data?.notifications || []).map((activity, i) => (
								<ActivityItem
									activity={activity}
									key={i}
									borderBottom={data.notifications.length - 1 !== i}
									isFollowedPosts={filterBy === "followedPosts"}
								/>
							))
					}
				</div>
				<Button
					to={`/activity/${filterBy}`}
					contextClass="notifications-menu"
					theme="light"
					style="flatNeutral"
					size="small"
				>
					{getTranslation("frontend.navMenu.seeAll")}
				</Button>
			</div>
		</Popup>
	);
};

export default ActivityMenu;
