import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PartnerModal from "@components/Modal/Modals/PartnerModal/PartnerModal";
import { EUIKey, useOpenModal } from "@contexts/UI";

const LinkStyle = styled.span`
	text-decoration: underline;
	cursor: pointer;
	display: inline;
`;

const PartnerLink = ({ el }) => {
	const openModal = useOpenModal();
	// clearing element contents before createPortal.
	const [innerHtmlEmptied, setInnerHtmlEmptied] = useState(false);
	const [text, setText] = useState("")

	const {
		modalName,
		url,
	} = el.dataset;

	useEffect(() => {
		if (!innerHtmlEmptied) {
			// save the text to render
			setText(el.innerText);
			// clear the existing element(s)
			el.innerHTML = "";
			setInnerHtmlEmptied(true)
		}
	}, [innerHtmlEmptied])

	const clickHandler = () => {
		openModal({
			[EUIKey.MODAL_BACKGROUND_DARKENED]: true,
			[EUIKey.MODAL_COMPONENT]: (
				<PartnerModal
					url={url}
				/>
			),
			[EUIKey.MODAL_LABEL]: `partner-modal-${modalName}`,
			[EUIKey.MODAL_SUBTYPE]: "iframe",
		})
	}

	if (!innerHtmlEmptied) {
		return null;
	}

	return ReactDOM.createPortal(
		<LinkStyle onClick={clickHandler}>
			{text}
		</LinkStyle>,
		el
	);
}

PartnerLink.propTypes = {
	el: PropTypes.instanceOf(Element),
	url: PropTypes.string,
	modalName: PropTypes.string,
	text: PropTypes.string,
}

export default PartnerLink;