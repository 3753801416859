import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const AutoTranslatedContent = styled(TextStyled) `
	cursor: pointer;

	&& {
		font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	span:hover {
		text-decoration: underline;
	}
`;
export const Toggle = styled.span `
	color: ${({ theme }) => theme.color.interactive01};
	cursor: pointer;
`;
