export const SET_ASSOCIATED_ENDPOINTS = "SET_ASSOCIATED_ENDPOINTS";
export const SET_ONBOARDING_TASKS = "SET_ONBOARDING_TASKS";
export const SET_HEADER = "SET_HEADER";
export const SET_SUB_HEADER = "SET_SUB_HEADER";

export const OnboardingReducer = (state, action) => {
	switch (action.type) {
		case SET_ASSOCIATED_ENDPOINTS:
			return {
				...state,
				associatedEndpoints: action.payload,
			};
		case SET_ONBOARDING_TASKS:
			return {
				...state,
				tasks: action.payload,
			};
		case SET_HEADER:
			return {
				...state,
				header: action.payload,
			};
		case SET_SUB_HEADER:
			return {
				...state,
				subHeader: action.payload,
			};
		default:
			return state;
	}
};