import PropTypes from "prop-types";
import React from "react";
import { CheckBoxList } from "@components/FormFields/FormFields";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const filters = [
	{
		key: getTranslation("frontend.railCards.generics.questions", true),
		value: "Question",
	},
	{
		key: getTranslation("frontend.railCards.generics.discussions", true),
		value: "Discussion",
	},
	{
		key: getTranslation("frontend.railCards.generics.patientCases", true),
		value: "PatientCase",
	},
	{
		key: getTranslation("frontend.railCards.generics.comments", true),
		value: "Comment",
	},
];

const PostType = ({ trackingCategory }) => {
	const trackEvent = useTrackEvent();
	const { postType, searchUpdate } = useMemoizedContext("search", ["postType"]);

	const changeHandler = (values) => {
		const selectedVals = [];
		values.forEach((selected, value) => {
			if (selected && value) {
				selectedVals.push({ value });
			}
		});

		if (selectedVals.length > 0) {
			trackEvent({
				category: trackingCategory,
				action: "selection",
				label: selectedVals
					.map((val) => val.value)
					.join("_")
					.toLowerCase(),
			});
		}

		searchUpdate({ postType: selectedVals });
	};

	return (
		<ModuleWrapper
			label={getTranslation("frontend.railCards.generics.type", true)}
			border={true}
			contextClass="left-rail"
		>
			<CheckBoxList
				className="full-width"
				name="postType"
				selectedOptions={postType}
				options={filters}
				contextClass="rail-module"
				handleChange={changeHandler}
				size="small"
			/>
		</ModuleWrapper>
	);
};

PostType.propTypes = {
	trackingCategory: PropTypes.string,
};

PostType.defaultProps = {
	trackingCategory: "search-results-filter-post-type",
};

export default PostType;
