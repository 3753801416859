import { SaveSVG, GearSVG, Icon, VerifiedCheckSVG, CameraSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Lightbulb from "@assets/images/lightbulb.svg";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import UserGroup from "@assets/images/user-group.svg";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import InviteColleaguesModal from "@components/Modal/Modals/InviteColleagues/InviteColleaguesModal";
import RequestSpecialtyChangeModal, { REQUEST_SPECIALTY_CHANGE_MODAL } from "@components/Modal/Modals/RequestSpecialtyChangeModal";
import UploadNewProfilePictureModal, { UPLOAD_PICTURE_MODAL } from "@components/Modal/Modals/UploadNewProfilePictureModal";
import { ONBOARDING_MODAL, OnboardingModal } from "@components/Onboarding";
import { ProfileInfo, GearIconStyled, InfoContainer, ProfileIconStyled, Specialty, AddLink, ProfileContainer, SubItem, Onboarding, Bar, Progress, ProgressText, FullName, IconHint } from "@components/ProfileOverview/ProfileOverview.styles";
import * as Styles from "@components/ProfileOverview/ProfileOverview.styles";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useAccountSettings } from "@components/Settings/components/AccountSettings/useAccountSettings.hook";
import { EUIKey, useCloseModal, useOpenModal, useOpenTour } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const ProfileOverview = () => {
    const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
    const navigate = useNavigate();
    const closeModal = useCloseModal();
    const openModal = useOpenModal();
    const openTour = useOpenTour();
    const trackEvent = useTrackEvent();
    const [accountSettingsState, setAccountSettingsState] = useState(null);
    const user = useMemoizedContext("member", [
        "biography",
        "brandAdministrator",
        "following",
        "id",
        "joinDate",
        "locale",
        "location",
        "specialties",
        "username",
        "verified",
        "worksAtSermo",
        "countryCode",
        "hasAccess",
        "onboardingCompleted",
        "primarySpecialtyId",
        "secondarySpecialtyId",
    ]);
    const [fetch] = useAccountSettings(null, setAccountSettingsState, {
        locale: user.locale,
    });
    const { tasks } = useMemoizedContext("onboarding", ["tasks"]);
    const { showTourCTA } = useMemoizedContext("feed", ["showTourCTA"]);
    const completedTasks = tasks?.filter((task) => task.completed);
    const completed = completedTasks?.length;
    const total = tasks?.length;
    useEffect(() => {
        if (user.hasAccess("canAccessFrontendAccountSettings")) {
            fetch();
        }
    }, []);
    const showInviteColleaguesModal = () => {
        const label = "invite-colleagues";
        trackEvent({
            category: "feed",
            label,
            action: "click",
        });
        openModal({
            [EUIKey.MODAL_COMPONENT]: <InviteColleaguesModal closeModalEventHandler={() => closeModal(label)}/>,
            [EUIKey.MODAL_LABEL]: label,
            [EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.inviteColleagues.title"),
        });
    };
    const goToSavedPosts = () => {
        if (user.hasAccess("canViewOwnProfile")) {
            trackEvent({
                category: "left-navigation-profile-card",
                label: "change-feed-saved-posts",
                action: "click",
            });
            navigate(`/feed/filter/saved`);
        }
    };
    const handleCloseOnBoardingClick = () => {
        trackEvent({
            category: "onboarding",
            label: "modal-close",
            action: "click",
        });
        closeModal(ONBOARDING_MODAL);
    };
    const showOnBoardingModal = () => {
        trackEvent({
            category: "onboarding",
            label: "profile-module-continue",
            action: "click",
        });
        openModal({
            [EUIKey.MODAL_LABEL]: ONBOARDING_MODAL,
            [EUIKey.MODAL_CLOSE_METHOD]: handleCloseOnBoardingClick,
            [EUIKey.MODAL_COMPONENT]: <OnboardingModal />,
            [EUIKey.MODAL_SUBTYPE]: "onboarding",
        });
    };
    const handleCloseSpecialtyClick = () => {
        closeModal(REQUEST_SPECIALTY_CHANGE_MODAL);
    };
    const showSpecialtyModal = (event) => {
        event.preventDefault();
        trackEvent({
            category: "left-navigation-profile-card",
            label: "change-specialty",
            action: "click",
        });
        openModal({
            [EUIKey.MODAL_LABEL]: REQUEST_SPECIALTY_CHANGE_MODAL,
            [EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.requestSpecialtyChange.title", true),
            [EUIKey.MODAL_CLOSE_METHOD]: handleCloseSpecialtyClick,
            [EUIKey.MODAL_COMPONENT]: (<RequestSpecialtyChangeModal initialPrimarySpecialty={user.primarySpecialtyId} initialSecondarySpecialty={user.secondarySpecialtyId} availableSpecialties={accountSettingsState.availableSpecialties} fileIsRequiredForSpecialtyChange={accountSettingsState.fileIsRequiredForSpecialtyChange} closeModalEventHandler={handleCloseSpecialtyClick}/>),
        });
    };
    const handleClosePictureClick = () => {
        closeModal(UPLOAD_PICTURE_MODAL);
    };
    const showPictureModal = (event) => {
        event.preventDefault();
        trackEvent({
            category: "left-navigation-profile-card",
            label: "change-profile-picture",
            action: "click",
        });
        openModal({
            [EUIKey.MODAL_CLOSE_METHOD]: handleClosePictureClick,
            [EUIKey.MODAL_COMPONENT]: (<UploadNewProfilePictureModal closeModalEventHandler={handleClosePictureClick}/>),
            [EUIKey.MODAL_LABEL]: UPLOAD_PICTURE_MODAL,
            [EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.uploadProfilePicture.title", true),
        });
    };
    if (user.brandAdministrator
        || "RU" === user.countryCode
        || (isMobileOrTabletPortrait
            && !user.hasAccess("canViewOwnProfile"))) {
        return null;
    }
    return (<ProfileContainer data-component={"ProfileOverview"}>
			<InfoContainer>
				<div className={"picture-container"} data-component={"ProfilePicture"}>
					<ProfilePic user={user} size="large" isLink={true} border={true}/>
					{user.hasAccess("canUpdateProfilePictureInFrontend") && (<div className={"camera-icon"} data-component={"EditPicture"}>
								<Icon src={CameraSVG} width={isMobileOrTabletPortrait
                ? 16
                : 18} height={isMobileOrTabletPortrait
                ? 16
                : 18} onClick={showPictureModal}/>
							</div>)}
				</div>
				<ProfileInfo>
					<FullName title={user.displayName} $kind={isMobileOrTabletPortrait
            ? "heading02"
            : "heading00"}>
						{user.displayName}
						{user.worksAtSermo
            && (<ProfileIconStyled height={isMobileOrTabletPortrait
                    ? 20
                    : 16} src={SermoMiniSVG} width={isMobileOrTabletPortrait
                    ? 20
                    : 16}/>)}
						{user.verified
            && !user.worksAtSermo
            && (<ProfileIconStyled height={isMobileOrTabletPortrait
                    ? 20
                    : 16} src={VerifiedCheckSVG} width={isMobileOrTabletPortrait
                    ? 20
                    : 16}/>)}
					</FullName>
					<Specialty data-component={"Specialty"}>
						<div>
							<div>
								<Styles.MainSpecialty>
									{user.brandAdministrator
            && (<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
													{getTranslation("frontend.generics.publicPage")}
												</TextStyled>)}
									{!user.brandAdministrator
            && user.worksAtSermo
            && (<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
													{getTranslation("system.feeds.worksAtSermoName")}
												</TextStyled>)}
									{!user.brandAdministrator
            && user.primarySpecialtyName
            && !user.worksAtSermo
            && (<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
													{user.primarySpecialtyName}
												</TextStyled>)}
									<Styles.GearIconContainer data-component={"ChangeSpecialties"} onClick={showSpecialtyModal}>
										<GearIconStyled height={isMobileOrTabletPortrait
            ? 20
            : 14} src={GearSVG} width={isMobileOrTabletPortrait
            ? 20
            : 14}/>
									</Styles.GearIconContainer>
								</Styles.MainSpecialty>
								{!user.brandAdministrator
            && user.secondarySpecialtyName
            && !user.worksAtSermo
            && (<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
												{user.secondarySpecialtyName}
											</TextStyled>)}
							</div>
						</div>
						{!user.secondarySpecialtyName && (<AddLink $kind={isMobileOrTabletPortrait
                ? "bodyShort02"
                : "bodyShort00"} onClick={showSpecialtyModal}>
									{getTranslation("frontend.profileOverview.addAnother")}
								</AddLink>)}
					</Specialty>
				</ProfileInfo>
			</InfoContainer>
			{user.hasAccess("canInviteColleaguesInFrontend")
            && (<SubItem data-component={"Refer"} onClick={showInviteColleaguesModal}>
							<IconHint height={isMobileOrTabletPortrait
                    ? 20
                    : 16} src={UserGroup} width={isMobileOrTabletPortrait
                    ? 20
                    : 16}/>
							<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
								{getTranslation("frontend.profileOverview.refer")}
							</TextStyled>
						</SubItem>)}
			<SubItem data-component={"SavedPosts"} onClick={goToSavedPosts}>
				<IconHint height={isMobileOrTabletPortrait
            ? 20
            : 16} src={SaveSVG} width={isMobileOrTabletPortrait
            ? 20
            : 16}/>
				<TextStyled $kind={isMobileOrTabletPortrait
            ? "bodyShort02"
            : "bodyShort00"}>
					{getTranslation("frontend.profileOverview.savedPosts")}
				</TextStyled>
			</SubItem>
			{showTourCTA
            && (completed === total
                || user.onboardingCompleted
                || user.onboardingExpired)
            && !isMobileOrTabletPortrait
            && (<SubItem data-component={"TakeTour"} onClick={openTour}>
							<Icon src={Lightbulb}/>
							<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
								{getTranslation("frontend.profileOverview.takeTour")}
							</TextStyled>
						</SubItem>)}
			{!user.onboardingCompleted
            && !user.onboardingExpired
            && !isMobileOrTabletPortrait
            && (<Onboarding data-component={"OnboardingProgress"} onClick={showOnBoardingModal}>
							<TextStyled $kind={isMobileOrTabletPortrait
                    ? "bodyShort02"
                    : "bodyShort00"}>
								{getTranslation("frontend.profileOverview.onboardingProgress")}
							</TextStyled>
							<Bar data-component={"ProgressBar"}>
								<Progress $progress={completed / total}>
									<ProgressText>{completed}/{total}</ProgressText>
								</Progress>
							</Bar>
						</Onboarding>)}
		</ProfileContainer>);
};
export { ProfileOverview };
