import { TextStyled } from "@sermo/ui-components/unstable";
import classNames from "classnames";
import { createUseStyles } from "react-jss"
import { useMediaQuery } from "react-responsive";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import AdForm from "@components/AdUnits/AdForm";
import SPFooter from "@components/AdUnits/Footer/SPFooter";
import Button from "@components/Button/Button";
import Featured from "@components/Card/components/Featured/Featured";
import Attendees from "@components/LiveEvents/components/Attendees/Attendees";
import EventTime from "@components/LiveEvents/components/EventTime/EventTime";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { useOpenPartnerModal } from "@components/Modal/Modals/PartnerModal/PartnerModal";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SponsoredContentInFeed from "@components/PostContent/components/SponsoredContentInFeed";
import CardHeader from "@components/PostViewer/components/CardHeader";
import { useMainAndTopResizeAware } from "@components/PostViewer/PostViewer.hooks";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import layout from "../../../scss/layout.scss";
import {
	TopSection,
	MainSection,
	Infeed,
	Footer,
	Headline,
	TimeWrapper,
	FooterContentContainer,
	HeadlineDescriptionContainer,
	ButtonContainer,
} from "./Infeed.styles";

const CardHeaderContainer = styled.div`
	margin: ${({ theme }) => theme.layout.cardsSpacing};
`;

const PartnerModalCTA = ({ ctaText, ctaUrl })=>{
	const pathParamsRegex = /(?:partner\/)(.*)/
	const partnerName = ctaUrl.match(pathParamsRegex)[1];

	const [openPartnerModal] = useOpenPartnerModal(partnerName);
	if ( !openPartnerModal ) {
		return null;
	}

	return (
		<Button
			style="secondary"
			size="small"
			clickHandler={openPartnerModal}
			wrap={false}
		>
			{ctaText}
		</Button>
	)
}

const InFeed = () => {
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const trackEvent = useTrackEvent();

	const {
		mainResizeListener,
		topResizeListener,
	} = useMainAndTopResizeAware();

	const {
		contentHeight,
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"contentHeight",
		"expanded",
	]);

	const {
		adForm,
		ctaText,
		ctaUrl,
		id,
		additionalFooterText,
		title,
		adId,
		clickTags,
		attachments,
		showBlackBoxText,
		brandStyle,
		adFrequency,
		adType,
		eventId,
		eventEndDateTime,
		eventEndDateTimeUtc,
		eventStartDateTime,
		eventStartDateTimeUtc,
		eventStatus,
		eventTotalRegistrants,
		eventIsMemberRegistered,
		eventPreviewId,
	} = useMemoizedContext("postData", [
		"adForm",
		"ctaText",
		"ctaUrl",
		"id",
		"additionalFooterText",
		"title",
		"adId",
		"clickTags",
		"attachments",
		"showBlackBoxText",
		"brandStyle",
		"adFrequency",
		"adType",
		"eventId",
		"eventEndDateTime",
		"eventEndDateTimeUtc",
		"eventStartDateTime",
		"eventStartDateTimeUtc",
		"eventStatus",
		"eventTotalRegistrants",
		"eventIsMemberRegistered",
		"eventPreviewId",
	]);
	const isPartnerModalCTA = ctaUrl?.includes("/partner/");

	const {
		locale,
	} = useMemoizedContext("member", [
		"locale",
	]);

	const onCtaClick = e => {
		if (!expanded) {
			e.stopPropagation();
			trackEvent({
				category: "infeed" + (expanded
					? "-expanded"
					: ""),
				action: "click",
				label: "cta-clicked",
				url: ctaUrl,
				postId: id,
				adId: adId,
				adFrequency: adFrequency,
				expanded: expanded,
				area: "cta_button",
			});
		}
	};

	const trackLinkClick = (e, area) => {
		const targetElement = "A" === e.target.tagName
			? e.target
			: "A" === e.target.parentElement.tagName
				? e.target.parentElement
				: false;
		if (targetElement) {
			let href = targetElement.getAttribute("href");

			let category = "infeed" + (expanded
				? "-expanded"
				: "");
			let action = "click";
			let label = "link";
			let title = targetElement.textContent || targetElement.temporalDivElement;

			if (area) {
				if (typeof area !== "string") {
					if (e.eventSource) {
						area = e.eventSource.area;
						action = "download";
						label = e.eventSource.document;
					}
				}
			}

			if ("InFeedEvent" !== adType && area !== "footer") {
				trackEvent({
					category,
					action,
					label,
					area,
					url: href,
					urlTitle: title,
					postId: id,
					adId: adId,
					adFrequency: adFrequency,
					expanded,
				});
			}
		}

		if ("InFeedEvent" === adType) {
			const imgElement = "IMG" === e.target.tagName
				? e.target
				: "IMG" === e.target.parentElement.tagName
					? e.target.parentElement
					: false;
			if (imgElement) {
				let category = "infeedevent";
				let action = "click";
				let label = "image";
				area = "feature-image";

				trackEvent({
					category,
					action,
					label,
					area,
					postId: id,
					adId: adId,
					eventId: eventId,
					adFrequency: adFrequency,
					expanded,
				});
			}
		}
	};

	const featuredImageOrVideo = attachments.find(attachment => Object.prototype.hasOwnProperty.call(attachment, "featured") && attachment.featured);

	const onHeaderAreaClick = e => {
		trackLinkClick(e, "header");
	};

	const onContentAreaClick = e => {
		if (clickTags?.introTextClick) {
			clickTags.introTextClick.forEach((url) => {
				fetchUrl(url);
			})
		}
		trackLinkClick(e, "content");
	};

	const onFeaturedAreaClick = e => {
		if (featuredImageOrVideo) {
			trackLinkClick(e, "image");
		}
		else {
			trackLinkClick(e, "featured");
		}
	};

	const onISIAreaClick = e => {
		trackLinkClick(e, "isi");
	};

	const onFootnoteAreaClick = e => {
		trackLinkClick(e, "footnote");
	};

	const onFooterAreaClick = e => {
		trackLinkClick(e, "footer");
	};

	return (
		<Infeed
			className={
				`${brandStyle
					? brandStyle
					: ""} ${isMobileOrTabletPortrait
					? "mobile"
					: ""}`
			}
		>
			<ModuleWrapper
				solid={true}
				padded={false}
				border={true}
			>
				<MainSection>
					{mainResizeListener}
					<TopSection>
						{topResizeListener}
						<CardHeaderContainer>
							<CardHeader onClick={onHeaderAreaClick} />
						</CardHeaderContainer>

						<ModuleWrapper
							solid={true}
							padded={true}
							notPaddedY={true}
						>
							<div
								styleName={classNames("layout.row-no-padding")}
								onClick={onContentAreaClick}
							>
								<SponsoredContentInFeed/>
							</div>
						</ModuleWrapper>
					</TopSection>

					<ModuleWrapper
						solid={true}
						padded={true}
						notPaddedY={true}
					>
						<Featured clickHandler={onFeaturedAreaClick} />
					</ModuleWrapper>

					{
						adForm && (
							<ModuleWrapper
								solid={true}
								padded={true}
							>
								<AdForm />
							</ModuleWrapper>
						)
					}
					<SPFooter
						isPadded={false}
						clickHandler={onFootnoteAreaClick}
						contentHeight={contentHeight}
						type={"footnote"}
					/>
					<SPFooter
						isPadded={false}
						clickHandler={onISIAreaClick}
						contentHeight={contentHeight}
						type={"isi"}
					/>
				</MainSection>
				<Footer
					$paddingTop={!showBlackBoxText}
					onClick={onFooterAreaClick}
				>
					{
						"InFeedEvent" === adType
						&& <TimeWrapper>
							<EventTime
								from={eventStartDateTime}
								fromUtc={eventStartDateTimeUtc}
								to={eventEndDateTime}
								toUtc={eventEndDateTimeUtc}
								status={eventStatus}
								size={"medium"}
								locale={locale}
							/>
						</TimeWrapper>
					}
					<FooterContentContainer>
						<HeadlineDescriptionContainer>
							<Headline
								$kind="heading03"
								dangerouslySetInnerHTML={{ __html: title }}
							/>
							{
								additionalFooterText && (
									<TextStyled
										$kind="bodyShortLegacy02"
										dangerouslySetInnerHTML={{ __html: additionalFooterText }}
									/>
								)
							}
						</HeadlineDescriptionContainer>
						{
							"InFeedUnit" === adType
							&& <ButtonContainer
								onClick={onCtaClick}
							>
								{
									isPartnerModalCTA && ctaText
									&& <PartnerModalCTA
										ctaText={ctaText}
										ctaUrl={ctaUrl}
									/>
								}
								{
									!isPartnerModalCTA && ctaText
									&& <Button
										style="secondary"
										size="small"
										to={ctaUrl}
										external={true}
										target={
											ctaUrl?.includes(window?.location?.host)
											&& !ctaUrl?.includes("/ccta/")
												? "_self"
												: "_blank"
										}
										wrap={false}
									>
										{ctaText}
									</Button>
								}
							</ButtonContainer>
						}
						{
							"InFeedEvent" === adType
							&& <ButtonContainer >
								<Button
									style="secondary"
									size="small"
									external={true}
									wrap={false}
									to={
										isAdPreview && eventPreviewId
											? `/preview/event/${eventPreviewId}`
											: `/event/${eventId}`
									}
									tracking={
										{
											category: "infeedevent",
											action: "click",
											label: "register",
											area: "register-start",
											adId: adId,
											eventId: eventId,
											url: isAdPreview && eventPreviewId
												? `/preview/event/${eventPreviewId}`
												: `/event/${eventId}`,
											urlTitle: "Register",
										}
									}
								>
									Register
								</Button>
							</ButtonContainer>
						}
					</FooterContentContainer>
					{
						"InFeedEvent" === adType
						&& !!eventTotalRegistrants
						&& <Attendees
							attendees={eventTotalRegistrants}
							registered={eventIsMemberRegistered}
						/>
					}
				</Footer>
			</ModuleWrapper>
		</Infeed>
	);
};

const InFeedWithCustomStyle = ({ customStyle }) => {
	const useStyles = createUseStyles({ custom: customStyle });

	const customClasses = useStyles()
	return (
		<div className={customClasses.custom}>
			<InFeed />
		</div>
	)
}

const InFeedStyleCheck = () => {
	const {
		customStyle = {},
		footnoteAdStyle = {},
	} = useMemoizedContext("postData", [
		"customStyle",
		"footnoteAdStyle",
	]);

	if (
		customStyle
		|| footnoteAdStyle
	) {
		return <InFeedWithCustomStyle
			customStyle={{ ...customStyle, ...footnoteAdStyle }}
		/>
	}

	return <InFeed />;
}

export default InFeedStyleCheck;
