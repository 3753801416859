import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import surveys from "../../Surveys.scss";

const WithdrawalHistoryCard = ({
	activity,
	balanceAmount,
	confirmationCode,
	currency,
	index,
	locale,
	shouldHighlightStatus,
	status,
	withdrawalAmount,
	withdrawalDate,
}) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpandCollapseClickHandler = () => {
		setExpanded(!expanded);
	};

	let withdrawalDateObject = new Date(Date.parse(withdrawalDate));
	let withdrawalDateLocaleString = withdrawalDateObject.toLocaleDateString(locale, {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	});

	return (
		<div
			key={index}
			styleName={classNames(["surveys.mobile-card", { "surveys.expanded": expanded }])}
			onClick={toggleExpandCollapseClickHandler}
		>
			<div styleName={classNames(["surveys.mobile-card-date", "typography.body-short-brand-00"])}>
				{withdrawalDateLocaleString}
			</div>
			<div
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"surveys.short-top-padding",
						"typography.body-short-brand-02",
					])
				}
			>
				{getTranslation(activity)}
			</div>
			<div styleName={classNames(["surveys.mobile-card-row", "typography.body-short-brand-02"])}>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderAmount", true)}:{" "}
				</span>
				{getCurrencyTranslation(withdrawalAmount, currency)}
			</div>
			<div
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"typography.body-short-brand-02",
						"surveys.hidden-when-collapsed",
						{
							"surveys.highlight-status": shouldHighlightStatus,
						},
					])
				}
			>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderStatus", true)}:{" "}
				</span>
				{status}
			</div>
			<div
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"typography.body-short-brand-02",
						"surveys.hidden-when-collapsed",
					])
				}
			>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderConfirmation", true)}:{" "}
				</span>
				{confirmationCode}
			</div>
			<div
				styleName={
					classNames([
						"surveys.mobile-card-row",
						"typography.body-short-brand-02",
						"surveys.hidden-when-collapsed",
					])
				}
			>
				<span styleName={classNames(["surveys.mobile-card-inner-text"])}>
					{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderBalance", true)}:{" "}
				</span>
				{getCurrencyTranslation(balanceAmount, currency)}
			</div>
		</div>
	);
};

WithdrawalHistoryCard.propTypes = {
	activity: PropTypes.string,
	balanceAmount: PropTypes.number,
	confirmationCode: PropTypes.string,
	currency: PropTypes.string,
	index: PropTypes.number,
	locale: PropTypes.string,
	shouldHighlightStatus: PropTypes.bool,
	status: PropTypes.string,
	withdrawalAmount: PropTypes.number,
	withdrawalDate: PropTypes.string,
};

export default WithdrawalHistoryCard;
