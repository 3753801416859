import { ChevronUpSVG, ChevronDownSVG, ChevronRightSVG, Collapse, Icon } from "@sermo/ui-components";
import { useMemo, useState } from "react";
import { useTrackEvent } from "@frontend/tracking";
import * as Styles from "./MobileRailCard.styles";
const MobileRailCard = ({ bottomBorder = true, content, onClick, title, trackingLabel = "missing-label", }) => {
    const [open, setOpen] = useState(false);
    const trackEvent = useTrackEvent();
    const iconSrc = useMemo(() => {
        if (!content) {
            return ChevronRightSVG;
        }
        return open
            ? ChevronUpSVG
            : ChevronDownSVG;
    }, [
        content,
        open,
    ]);
    const renderContent = useMemo(() => content?.map((item, index) => (<Styles.Item key={index}>
			{item}
		</Styles.Item>)), [content]);
    const handleClickableClick = () => {
        if (content) {
            if (open) {
                trackEvent({
                    category: "right-rail",
                    label: `${trackingLabel}-list-collapse`,
                    action: "click",
                });
            }
            else {
                trackEvent({
                    category: "right-rail",
                    label: `${trackingLabel}-list-expand`,
                    action: "click",
                });
            }
            setOpen(!open);
        }
        else {
            onClick?.();
        }
    };
    return (<Styles.Container $bottomBorder={bottomBorder}>
			<Styles.Clickable onClick={handleClickableClick}>
				<Styles.Text $kind={"heading00"}>
					{title}
				</Styles.Text>
				<Icon src={iconSrc} height={12} width={12}/>
			</Styles.Clickable>
			{!!content
            && (<Collapse open={open}>
						<Styles.CollapseContent>
							{renderContent}
						</Styles.CollapseContent>
					</Collapse>)}
		</Styles.Container>);
};
export default MobileRailCard;
