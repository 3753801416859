import classNames from "classnames";
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { useLocation } from "react-use";
import Button from "@components/Button/Button";
import {
	TextField,
	GlobalValidationMessage,
} from "@components/FormFields/FormFields";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import loginStyles from "../LoggedOut/components/Login/Login.scss";
import loggedOutStyles from "../LoggedOut/LoggedOut.scss";
import { SignInCTA } from "./AdPreview.styles";

const SignIn = () => {
	const apiEndpoint = useApiEndpoint();
	const trackEvent = useTrackEvent();
	const match = useMatch("/ad-preview/:adId");

	const isDMAPreview = !!useMatch("/dma-preview/*");
	const matchDMA = useMatch("/dma-preview/:adId");

	// eslint-disable-next-line no-unsafe-optional-chaining
	const { adId } = match?.params ?? matchDMA?.params;
	const { search } = useLocation();

	// cant render server side without captcha info
	// should probably break out the captch into its own component

	const [password, setPassword] = useState("");
	const [passwordValidationError, setPasswordValidationError] = useState("");
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [loading, setLoading] = useState(false);

	const handleLogin = e => {
		if (e) e.preventDefault();
		setLoading(true);

		setGlobalValidationError("");

		const data = {
			password: password,
			adId: adId,
		};

		// TODO: edge is dumb and wants the data to be a string. may need to update api to support
		// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/18775440/
		apiEndpoint("creativepreview/authorize", data).then(dataResponse => {
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					trackEvent(
						{
							category: "login",
							action: "event",
							label: "login-success",
							userType: "ad-preview-member",
						},
						() => {
							if (isDMAPreview)
								window.location = `/dma-preview/${adId}`
							else
								window.location = `/ad-preview/${adId}${search}`
						}
					);
				} else {
					setLoading(false);

					trackEvent({
						category: "login",
						action: "event",
						label: "login-failure",
					});

					setGlobalValidationError(body.message);
					setPasswordValidationError(" ");

					if (body.fields !== null) {
						for (let property in body.fields) {
							switch (property) {
								case "password":
									setPasswordValidationError(body.fields[property].join("<br />"));
									break;
							}
						}
					}
				}
			});
		});
	};

	const onPasswordChange = (e) => {
		setPassword(e.target.value);
		setPasswordValidationError("");
	}

	return (
		<>
			<div styleName={classNames(["loggedOutStyles.content-box-wider"])}>
				<div styleName={classNames(["loggedOutStyles.content-box-inner-container"])}>
					<div styleName={classNames(["loggedOutStyles.form-title", "typography.heading-brand-05"])}>
						{getTranslation("frontend.adPreviewPage.title")}
					</div>
					<SignInCTA styleName={classNames("typography.body-short-03")}>
						{getTranslation("frontend.adPreviewPage.cta")}
					</SignInCTA>
					<form
						onSubmit={handleLogin}
						styleName={classNames(["loginStyles.login-form"])}
					>
						<div styleName={classNames(["loginStyles.form-field-row"])}>
							<TextField
								name="password"
								type="password"
								autoComplete="current-password"
								placeholder={
									getTranslation(
										"frontend.generics.password",
										true
									)
								}
								validationError={passwordValidationError}
								handleChange={onPasswordChange}
								value={password}
							/>
						</div>

						<div
							styleName={
								classNames([
									"loggedOutStyles.global-validation-error",
									"typography.body-short-02",
								])
							}
						>
							<GlobalValidationMessage text={globalValidationError} />
						</div>

						<div styleName={classNames(["loginStyles.submit-button"])}>
							<Button
								name="submit"
								disabled={loading}
								loading={loading}
								type="submit"
								clickHandler={handleLogin}
							>
								{getTranslation("frontend.login.signInPage.loginSubmitButtonText", true)}
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default SignIn;
