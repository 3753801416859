import PropTypes from "prop-types";
import React, { useState } from "react";
import { DropDownList } from "@components/FormFields/FormFields";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalFooter } from "./ModalComponents";

const reasons = [
	"spamOrAdvertisement",
	"patientCanBeIdentified",
	"sermoMemberCanBeIdentified",
	"intellectualPropertyViolation",
	"hateSpeechOrSymbol",
	"memberHarassment",
	"personalAttacksOrThreats",
	"trolling",
	"postHijacking",
	"nudityOrPornography",
	"graphicViolence",
	"misinformation",
	"plagiarism",
	"duplicated",
];

const ReportModal = ({ id, type, closeModalEventHandler }) => {
	const apiEndpoint = useApiEndpoint();
	const [reportReason, setReportReason] = useState(reasons[0]);
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);

	const reasonChange = (e) => {
		setReportReason(e.target.value);
	};

	const reportPost = () => {
		const body = {
			reason: reportReason,
		};
		if ("post" === type) {
			body.postId = id;
		}
		if ("comment" === type) {
			body.commentId = id;
		}
		setLoading(true);
		apiEndpoint("posts/requestmoderation", body).then((response) => {
			setStep(2);
			setLoading(false);
			// TODO display error message if user already reported this item

			response.json().then((data) => {});
		});
	};

	const options = [];

	reasons.forEach((reason, i) => {
		options.push({
			value: reason,
			name: getTranslation("frontend.modals.reportPost.reasons." + reason, true),
		});
	});

	return (
		<ModalContainer>
			{loading && <ModalText>{getTranslation("frontend.modals.generic.loading")}</ModalText>}
			{
				!loading && 1 === step && (
					<>
						<ModalText>{getTranslation("frontend.modals.reportPost.copy", true)}</ModalText>

						<ModalSection>
							<DropDownList
								name="report reason"
								handleChange={reasonChange}
								placeholder={(getTranslation("frontend.modal.reportPost.selectAReason"), true)}
								options={options}
								value={reportReason}
							/>
						</ModalSection>

						<ModalFooter
							{...{
								cancelModalEventHandler: closeModalEventHandler,
								submitEventHandler: reportPost,
								submitText: getTranslation("frontend.generics.report", true),
							}}
						/>
					</>
				)
			}
			{
				!loading && 2 === step && (
					<>
						<ModalText>{getTranslation("frontend.modals.reportPost.thankYou", true)}</ModalText>

						<ModalFooter
							{...{
								submitEventHandler: closeModalEventHandler,
								submitText: getTranslation("frontend.generics.done", true),
							}}
						/>
					</>
				)
			}
		</ModalContainer>
	);
};

ReportModal.propTypes = {
	id: PropTypes.number,
	type: PropTypes.oneOf(["post", "comment"]),
	closeModalEventHandler: PropTypes.func,
};

ReportModal.defaultProps = {
	type: "post",
	closeModalEventHandler: () => {},
};

export default ReportModal;
