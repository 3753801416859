import { Button, InlineDropdown } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { snoozeOptions } from "@components/LoggedOut/components/Unsubscribe/utils";
import { ModalStyled, Snooze } from "@components/Modal/Modals/Unsubscribe/styled";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import getTranslation from "@translation/translation";
import { NotificationSubscriptionType, SourceTexts } from "./config";
import styles from "./UnsubscribeModal.scss";

const UnsubscribeModal = ({ type = 0, acceptHandler, cancelHandler, snoozeHandler, app }) => {
	const [loading, setLoading] = useState(false);
	const [snooze, setSnooze] = useState("7");
	const texts = SourceTexts[type];

	const handleSnoozeSelector = (event, value) => {
		setSnooze(value);
	};

	const handleSnoozeSubmit = () => {
		setLoading(true);
		snoozeHandler(snooze);
	};

	const handleAcceptSubmit = () => {
		setLoading(true);
		acceptHandler();
	};

	const showSnooze = useCallback(() => (
		(app === "mnow"
			&& [NotificationSubscriptionType.NewsFeaturedContentMemberBenefits].includes(type))
		|| (app === "membership"
			&& [
				NotificationSubscriptionType.NewsFeaturedContentMemberBenefits,
				NotificationSubscriptionType.CommunityDigest,
			].includes(type))
	), [type, app]);

	return (
		<div styleName="styles.unsubscribe">
			<ModuleWrapper padded={false}>
				<ModalStyled $app={app}>
					<header/>
					<h3 className={"heading-04"}>{getTranslation(texts.title, true)}</h3>
					<p className={"body-long-03"}>
						{getTranslation(texts.description)}
					</p>
					<Button
						className={"okButton"}
						kind="primaryWhite"
						onClick={handleAcceptSubmit}
						size={"medium"}
						disabled={loading}
					>{getTranslation("frontend.unsubscribe.modalConfirm", true)}</Button>
					<Button
						className={"cancelButton"}
						kind="flat"
						onClick={cancelHandler}
						size={"medium"}
						disabled={loading}
					>{getTranslation("frontend.unsubscribe.modalCancel", true)}</Button>
					{
						showSnooze() && <Snooze>
							<div className={"body-short-01"}>
								<span>{getTranslation("frontend.unsubscribe.modalSnooze", true)}</span>
								{/* TODO: In the near future, replace this with <Select /> component */}
								<InlineDropdown
									onChange={handleSnoozeSelector}
									value={snooze}
									options={snoozeOptions}
								/>
							</div>
							<Button
								onClick={handleSnoozeSubmit}
								size={"medium"}
								disabled={loading}
							>{getTranslation("frontend.unsubscribe.snooze", true)}</Button>
						</Snooze>
					}
				</ModalStyled>
			</ModuleWrapper>
		</div>
	);
};

UnsubscribeModal.propTypes = {
	type: PropTypes.string,
	app: PropTypes.string,
	acceptHandler: PropTypes.func,
	cancelHandler: PropTypes.func,
	snoozeHandler: PropTypes.func,
};

export default UnsubscribeModal;
