import { ColorGrey60, ColorUltraviolet60 } from "@sermo/design-tokens";
import {
	DiscussionIcon,
	HeartFillIcon,
	HeartLineIcon,
	Icon,
	IconButton,
	LinkIcon,
	OverflowHorizontalIcon,
	Popup,
} from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { StyledAlertText } from "@components/Modal/Modal.styles"
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import CardHeaderMenu from "@components/PostViewer/components/CardHeader/components/CardHeaderMenu/CardHeaderMenu";
import { PostDataActionTypes } from "@contexts/PostData";
import { EUIKey, useOpenAlert } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { fetchUrl } from "@frontend/Utils";
import {
	useApiEndpoint,
	useMemoizedContext,
	useGetPostTrackingCategory,
} from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import FollowedIndicator from "../FollowedIndicator/FollowedIndicator";
import styles from "./CardFooter.scss";

const CardFooterIcon = styled(Icon)`
	path {
		fill: ${({ $liked }) => $liked
		? `${ColorUltraviolet60}`
		: `${ColorGrey60}`};
	}
	height: 24px;
	width: 24px;
`;

const CardFooterViews = styled(TextStyled).attrs({
	$kind: "bodyShort02",
})`
	color: ${({ theme }) => theme.color.text03};
	font-weight: 500;
`;

const CardFooterPopup = styled(Popup)`
	border: none;

	&& > div {
		position: initial;
	}
`;

const CardFooter = () => {
	const apiEndpoint = useApiEndpoint();
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isPreviewRoute = !!useMatch("/preview/*");
	const openAlert = useOpenAlert();
	const trackEvent = useTrackEvent();
	const [open, setOpen] = useState(false);
	const triggerMenuRef = React.useRef(null);

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		adType,
		cardType,
		clickTags,
		commentsCount,
		dispatch: dispatchPostData,
		following,
		id,
		postId,
		isCommentingDisabled,
		isLikingDisabled,
		isShareDisabled,
		liked,
		likes,
		sermoContentCardId,
		type,
		viewsCount,
	} = useMemoizedContext("postData",[
		"adFrequency",
		"adId",
		"adType",
		"cardType",
		"clickTags",
		"commentsCount",
		"following",
		"id",
		"postId",
		"isCommentingDisabled",
		"isLikingDisabled",
		"isShareDisabled",
		"liked",
		"likes",
		"sermoContentCardId",
		"type",
		"viewsCount",
	]);

	const { cardData } = useMemoizedContext("postUI", [
		"cardData",
	]);

	const disableLiking = cardData?.disableLiking || isLikingDisabled;
	const disableCommenting = cardData?.disableCommenting || isCommentingDisabled;

	const postIdUsed = id || postId;
	const category = useGetPostTrackingCategory();

	const iconLike = liked
		? HeartFillIcon
		: HeartLineIcon;

	const getLikeText = () => {
		if (likes > 1) {
			return `${likes} ${getTranslation("frontend.card.footer.likePlural", true)}`;
		} else {
			if (likes === 1) {
				return "1 " + getTranslation("frontend.card.footer.likeSingular", true);
			} else {
				return getTranslation("frontend.card.footer.likeSingular", true);
			}
		}
	}

	const [likeText, setLikeText] = useState(getLikeText());

	useEffect(() => {
		setLikeText(getLikeText());
	}, [likes])

	const getCommentText = () => {
		if (commentsCount > 1) {
			return `${commentsCount} ${getTranslation("frontend.card.footer.commentPlural", true)}`;
		} else {
			if (1 === commentsCount) {
				return "1 " + getTranslation("frontend.card.footer.commentSingular", true);
			} else {
				return getTranslation("frontend.card.footer.commentSingular", true);
			}
		}
	}

	const [commentText, setCommentText] = useState(getCommentText())

	useEffect(() => {
		setCommentText(getCommentText());
	}, [commentsCount])

	let showCommentButton = false;

	if (["Discussion", "PatientCase"].includes(type)
		|| CardTypeTypes.RESOURCE_CENTER_ITEM === cardType
		|| CardTypeTypes.SERMO_CONTENT_CARD) {
		showCommentButton = !disableCommenting;
	}

	const handleLikeClick = (e) => {
		e.stopPropagation();

		if (clickTags?.like) {
			clickTags?.like.forEach((url) => {
				fetchUrl(url);
			})
		}

		if (liked) {
			trackEvent({
				category,
				action: "click",
				label: "unlike",
				postId: postIdUsed,
				adId: adId,
				adFrequency: adFrequency,
				area: "footer",
				sermoContentCardId,
			});
		} else {
			trackEvent({
				category,
				action: "click",
				label: "like",
				postId: postIdUsed,
				adId: adId,
				adFrequency: adFrequency,
				area: "footer",
				sermoContentCardId,
			});
		}

		dispatchPostData({
			type: PostDataActionTypes.TOGGLE_LIKED,
		});

		apiEndpoint("posts/likePost", { postId: postIdUsed }, false).then((response) => {
			if (!response.ok) {
				dispatchPostData({
					type: PostDataActionTypes.TOGGLE_LIKED,
				});
			}
		});
	};

	const copyLink = async () => {
		if (clickTags?.copyLinkShareButton) {
			clickTags?.copyLinkShareButton.forEach((url) => {
				fetchUrl(url);
			})
		}

		const canBeExpanded = type !== "Question" && !adType?.includes("InFeed");
		const urlForPost = `${window.location.origin}/post/${postIdUsed}${canBeExpanded
			? "/expanded"
			: ""}`;

		await navigator.clipboard.writeText(urlForPost);

		openAlert({
			[EUIKey.ALERT_LABEL]: "link-copied",
			[EUIKey.ALERT_COMPONENT]: <StyledAlertText>{getTranslation("frontend.alerts.copyLink")}</StyledAlertText>,
		});
	};

	const CopyLinkButton = () => (
		<Button
			icon={<CardFooterIcon src={LinkIcon} />}
			labelPosition="right"
			contextClass="card-footer"
			clickHandler={copyLink}
			theme="light"
			style="flatNeutral"
			size="small"
			tracking={
				{
					category,
					action: "click",
					label: "copy-link",
					postId: postIdUsed,
					adId: adId,
					adFrequency: adFrequency,
					area: "footer",
					sermoContentCardId,
				}
			}
		>
			{getTranslation("frontend.card.footer.copyLink")}
		</Button>
	);

	const footerClick = (e) => {
		e.eventSource = "footer";
	};

	const shouldRender = (
		!disableLiking
		|| (
			showCommentButton
			&& !disableCommenting
		)
		|| expanded
		|| following
	);

	if (!shouldRender) {
		return null;
	}

	return (
		<ModuleWrapper
			data-component={"CardFooter"}
			notPaddedX={CardTypeTypes.RESOURCE_CENTER_ITEM === cardType}
			notPaddedY={CardTypeTypes.RESOURCE_CENTER_ITEM === cardType}
			padded={expanded && CardTypeTypes.RESOURCE_CENTER_ITEM === cardType}
			paddedWidest={expanded && CardTypeTypes.RESOURCE_CENTER_ITEM !== cardType}
			solid={true}
		>
			<div styleName={
				expanded
					? "styles.footer-expanded"
					: "styles.footer"
			}
			>
				<div
					styleName={classNames("styles.card-footer", { "styles.expanded": expanded })}
					onClick={footerClick}
				>
					<div>
						{
							!disableLiking && (
								<Button
									icon={
										<CardFooterIcon
											src={iconLike}
											$liked={
												liked
													? 1
													: 0
											}
										/>
									}
									labelPosition="right"
									contextClass="card-footer"
									clickHandler={handleLikeClick}
									rightsRequired={
										isAdPreview || isPreviewRoute
											? []
											: ["canLikeItemsInFrontend"]
									}
									theme="light"
									style="flatNeutral"
									size="small"
								>
									{likeText}
								</Button>
							)
						}

						{
							showCommentButton
							&& !disableCommenting
							&& (
								<Button
									icon={<CardFooterIcon src={DiscussionIcon} />}
									labelPosition="right"
									contextClass="card-footer"
									dataId="commentsButton"
									theme="light"
									style="flatNeutral"
									size="small"
									tracking={
										{
											category,
											action: "click",
											label: "comments",
											postId: postIdUsed,
											adId: adId,
											adFrequency: adFrequency,
											area: "footer",
											sermoContentCardId,
										}
									}
								>
									{commentText}
								</Button>
							)
						}

						{
							expanded
							&& ["Ad"].includes(cardType)
							&& !isShareDisabled
							&& (
								<CopyLinkButton />
							)
						}
						{
							expanded
							&& ![CardTypeTypes.AD, CardTypeTypes.RESOURCE_CENTER_ITEM].includes(cardType)
							&& !isShareDisabled
							&& (
								<TabletLandscapeAndDesktop>
									<CopyLinkButton />
								</TabletLandscapeAndDesktop>
							)
						}
					</div>
					{
						expanded
						&& ![CardTypeTypes.AD, CardTypeTypes.RESOURCE_CENTER_ITEM].includes(cardType)
						&& (
							<>
								<IconButton
									icon={OverflowHorizontalIcon}
									onClick={() => setOpen(!open)}
									ref={triggerMenuRef}
									size="small"
								/>
								<CardFooterPopup
									disablePortal
									open={open}
									triggerRef={triggerMenuRef}
								>
									<CardHeaderMenu footer={true} />
								</CardFooterPopup>
							</>
						)
					}
					{!expanded && !viewsCount && <FollowedIndicator />}
					{
						!!viewsCount
						&& (
							<CardFooterViews>
								{
									viewsCount > 1
										? getTranslation("frontend.generics.someViews", true, viewsCount)
										: getTranslation("frontend.generics.oneView", true)
								}
							</CardFooterViews>
						)
					}
				</div>
			</div>
		</ModuleWrapper>
	);
};

export default CardFooter;