import { createContext } from "react";
import { EUIKey, } from "@contexts/UI/UIContext.types";
import { PostTypeTypes } from "@frontend/types/Post/postData";
export const alertDefaultValues = {
    [EUIKey.ALERT_CAN_CLOSE]: true,
    [EUIKey.ALERT_CLOSE_METHOD]: null,
    [EUIKey.ALERT_COMPONENT]: null,
    [EUIKey.ALERT_IS_OPEN]: false,
    [EUIKey.ALERT_LABEL]: null,
    [EUIKey.ALERT_PERSIST]: false,
    [EUIKey.ALERT_SUBTYPE]: null,
    [EUIKey.ALERT_TEXT_LABEL]: null,
};
export const bannerDefaultValues = {
    [EUIKey.BANNER_CAN_CLOSE]: true,
    [EUIKey.BANNER_CLOSE_METHOD]: null,
    [EUIKey.BANNER_COMPONENT]: null,
    [EUIKey.BANNER_IS_OPEN]: false,
    [EUIKey.BANNER_LABEL]: null,
    [EUIKey.BANNER_SUBTYPE]: null,
    [EUIKey.BANNER_TEXT_LABEL]: null,
};
export const modalDefaultValues = {
    [EUIKey.MODAL_BACKGROUND_DARKENED]: false,
    [EUIKey.MODAL_CAN_CLOSE]: true,
    [EUIKey.MODAL_CLOSE_METHOD]: null,
    [EUIKey.MODAL_COMPONENT]: null,
    [EUIKey.MODAL_IS_OPEN]: false,
    [EUIKey.MODAL_LABEL]: null,
    [EUIKey.MODAL_OVER_THE_TOP]: false,
    [EUIKey.MODAL_SUBTYPE]: null,
    [EUIKey.MODAL_TEXT_LABEL]: null,
};
export const UIDefaultValues = {
    ...alertDefaultValues,
    ...bannerDefaultValues,
    ...modalDefaultValues,
    dispatch: () => { },
    [EUIKey.BACKDROP_IS_OPEN]: false,
    [EUIKey.EXPANDED_POST]: null,
    [EUIKey.FOOTER_HEIGHT]: 0,
    [EUIKey.HEADER_HEIGHT]: 0,
    [EUIKey.HEADER_HEIGHT_CACHE]: 0,
    [EUIKey.MENU_NAME]: null,
    [EUIKey.POST_EDITOR_IS_OPEN]: null,
    [EUIKey.POST_EDITOR_SELECTED_TYPE]: PostTypeTypes.PATIENT_CASE,
    [EUIKey.POST_TITLE]: null,
    [EUIKey.POST_TOPIC]: null,
    [EUIKey.RELOAD_TOUR]: false,
    [EUIKey.SIDE_PANEL_LABEL]: null,
    [EUIKey.TOUR_IS_OPEN]: false,
    [EUIKey.TYPE_SELECTOR_IS_OPEN]: false,
};
const UIContext = createContext(UIDefaultValues);
export default UIContext;
