import { Icon, VerifiedCheckSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import ModuleWraper from "@components/ModuleWrapper/ModuleWrapper";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./MemberCard.scss";

const VerificationCheckIconStyled = styled(Icon)`
	display: inline-block;
	width: 13px;
	height: 13px;
	margin-left: 6px;
	top: -1px;
`;

const MemberCard = ({ user }) => {
	const [redirect, setRedirect] = useState(false);

	const { id, username, verified, brandAdministrator, specialties, following, worksAtSermo } = user;

	const clickHandler = () => {
		setRedirect(`/profile/${id}`);
	};

	return (
		<div
			styleName="styles.member"
			onClick={clickHandler}
		>
			{redirect && <Navigate to={redirect} />}
			<ModuleWraper solid={true}>
				<div styleName="styles.member-wrapper">
					<div styleName="styles.left">
						<div styleName="styles.pic-wrapper">
							<ProfilePic user={user} />
						</div>
						<div styleName="styles.name-and-specialty">
							<div styleName={classNames("styles.name", "typography.heading-brand-02")}>
								{username}
								{
									verified && !worksAtSermo && <VerificationCheckIconStyled
										src={VerifiedCheckSVG}
									/>
								}
								{
									worksAtSermo && <VerificationCheckIconStyled
										src={SermoMiniSVG}
									/>
								}
							</div>
							{
								specialties && !brandAdministrator && (
									<div styleName={classNames("styles.specialty", "typography.body-short-brand-00")}>
										{
											worksAtSermo
												? getTranslation("system.feeds.worksAtSermoName")
												: specialties[0].name
										}
									</div>
								)
							}
							{
								brandAdministrator && (
									<div styleName={classNames("styles.specialty", "typography.body-short-brand-00")}>
										{getTranslation("frontend.generics.publicPage", true)}
									</div>
								)
							}
						</div>
					</div>
					<div>
						{
							following && (
								<div styleName={classNames("styles.following", "typography.body-short-brand-02")}>
									{getTranslation("frontend.generics.following")}
								</div>
							)
						}
					</div>
				</div>
			</ModuleWraper>
		</div>
	);
};

MemberCard.propTypes = {
	user: PropTypes.shape(UserProps),
};

export default MemberCard;
