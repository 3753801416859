import {
	MenuContextProvider as UIMenuContextProvider,
	StackProvider,
} from "@sermo/ui-components";
import { useReducer, useState, useEffect, useMemo } from "react";
import { useThrottle } from "react-use";
import ActivityMonitor from "@components/Layout/ActivityMonitor";
import ScrollManager from "@components/Layout/ScrollManager";
import { SearchContextProvider } from "@components/Search/SearchContext";
import { OnboardingProvider } from "@contexts/Onboarding";
import { RunWithPeterContextProvider } from "@contexts/RunWithPeter";
import { UIProvider } from "@contexts/UI";
import { useFetch, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import Automation from "./Automation";
import {
	Member as MemberContext,
	ScrollContext,
	MessagesContext,
} from "./contexts";
import {
	member as memberReducer,
	MEMBER_LOGOUT,
	MEMBER_UPDATE,
	Scroll as scrollReducer,
	SET_ELEMENT,
	SET_SCROLL_LOCKED,
	SET_LEFT_RAIL_HEIGHT,
	SET_RIGHT_RAIL_HEIGHT,
	SET_CONTENT_MIN_HEIGHT,
	SET_SCROLL_X,
	SET_SCROLL_Y,
	MEMBER_PRIVACY_POLICY_ACCEPTED,
	MEMBER_TOPIC_FOLLOW,
	MEMBER_TOPIC_UNFOLLOW,
	MEMBER_FOLLOW,
	MEMBER_UNFOLLOW,
	Messages as MessagesReducer,
	SET_AID,
	SET_MEMBER_ID,
	SET_THREADS,
	SET_ID_BY_TYPE,
} from "./reducers";

const ScrollContentMinHeightUpdater = () => {
	const {
		leftRailHeight,
		rightRailHeight,
		setContentMinHeight,
	} = useMemoizedContext("scroll", [
		"leftRailHeight",
		"rightRailHeight",
		"setContentMinHeight",
	]);

	useEffect(() => {
		setContentMinHeight();
	}, [leftRailHeight, rightRailHeight])

	return null;
}

// Updates the scroll context with the current window scroll position
// throttled to 200ms to minimize the number of updates
const WindowScrollUpdater = () => {
	const { updateX, updateY } = useMemoizedContext("scroll", ["updateX", "updateY"]);

	const [y, setY] = useState(window.scrollY);
	const throttledY = useThrottle(y, 200);

	const [x, setX] = useState(window.scrollX);
	const throttledX = useThrottle(x, 200);

	useEffect(() => {
		updateX(throttledX);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [throttledX]);

	useMemo(() => {
		updateY(throttledY);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [throttledY]);

	const handleScroll = () => {
		setX(window.scrollX);
		setY(window.scrollY);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
}

export const MessagesContextProvider = ({ children }) => {
	const [messagesState, messagesDispatch] = useReducer(MessagesReducer, {
		memberIdState: null,
		aIdState: null,
		messageListThreads: [],
	});

	const setMemberIdState = memberId => messagesDispatch({
		type: SET_MEMBER_ID,
		payload: { memberId },
	});

	const setAIdState = aId => messagesDispatch({
		type: SET_AID,
		payload: { aId },
	})

	const setMessageListThreads = messageListThreads => messagesDispatch({
		type: SET_THREADS,
		payload: { messageListThreads },
	})

	const setIdByType = (id, type) => messagesDispatch({
		type: SET_ID_BY_TYPE,
		payload: {
			id,
			type,
		},
	})

	return (
		<MessagesContext.Provider
			value={
				{
					messagesState,
					setMemberIdState,
					setAIdState,
					setMessageListThreads,
					setIdByType,
				}
			}
		>
			{children}
		</MessagesContext.Provider>
	)
}

const ScrollContextProvider = ({ children }) => {
	const [state, scrollDispatch] = useReducer(scrollReducer, {
		el: false,
		id: false,
		x: 0,
		y: 0,
		scrollLocked: false,
		leftRailHeight: 0,
		rightRailHeight: 0,
		contentMinHeight: 0,
	});

	const setLeftRailHeight = height => {
		scrollDispatch({
			type: SET_LEFT_RAIL_HEIGHT,
			leftRailHeight: height,
		});
	};

	const setRightRailHeight = height => {
		scrollDispatch({
			type: SET_RIGHT_RAIL_HEIGHT,
			rightRailHeight: height,
		});
	};

	const setContentMinHeight = () => {
		scrollDispatch({
			type: SET_CONTENT_MIN_HEIGHT,
			contentMinHeight: Math.max(state.leftRailHeight, state.rightRailHeight),
		})
	}

	const setElement = el => {
		scrollDispatch({
			type: SET_ELEMENT,
			el: el,
		});
	};

	const setScrollLocked = val => {
		scrollDispatch({
			type: SET_SCROLL_LOCKED,
			val: val,
		})
	}

	const updateX = x => {
		scrollDispatch({
			type: SET_SCROLL_X,
			x,
		});
	}

	const updateY = y => {
		scrollDispatch({
			type: SET_SCROLL_Y,
			y,
		});
	}

	return (
		<ScrollContext.Provider
			value={
				{
					el: state.el,
					id: state.id,
					scrollLocked: state.scrollLocked,
					leftRailHeight: state.leftRailHeight,
					rightRailHeight: state.rightRailHeight,
					contentMinHeight: state.contentMinHeight,
					x: state.x,
					y: state.y,
					setLeftRailHeight,
					setRightRailHeight,
					setContentMinHeight,
					setElement,
					setScrollLocked,
					updateX,
					updateY,
				}
			}
		>
			<ScrollContentMinHeightUpdater/>
			<WindowScrollUpdater/>
			{children}
		</ScrollContext.Provider>
	);
};

export const MemberContextProvider = ({ children }) => {
	const [loaded, setLoaded] = useState(false);

	// defaults for member state
	// used for logged out state
	let isAuthenticated = false;
	let active = true;
	let id = 0;
	let profilePic = null;
	let username = null;
	let firstName = null;
	let lastName = null;
	let email = null;
	let locale = "en-US";
	let countryCode = "";
	let countryName = "";
	let joinDate = "";
	let initialReferral = null;
	let initialQueryParameters = null;
	let queryParams = {};
	let displayName = null;
	let primarySpecialtyId = 0;
	let primarySpecialtyName = null;
	let secondarySpecialtyId = 0;
	let secondarySpecialtyName = null;
	let hasPreferredStatus = false;
	let userType = "";
	let hasPendingDeactivationRequest = false;
	let daysUntilDeactivation = 0;
	let anonymous = false;
	let hasConfiguredProfile = false;
	let hasSetPassword = false;
	let surveyOutcome = "";
	let surveyOutcomeIncentiveAmount = 0;
	let surveyOutcomeIncentiveCurrency = "";
	let legacyUser = false;
	let showTourGuide = false;
	let brandAdministrator = false;
	let trialMember = false;
	let affiliateMember = false;
	let readOnlyMember = false;
	let hasSeenTrialMemberPopup = false;
	let hasSeenReadOnlyMemberPopup = false;
	let hasSeenPostSurveyReferralPopup = false;
	let hasSeenReverificationPopup = false;
	let hasAcceptedTermsAndConditions = false;
	let hasAccessToAdPreview = false;
	let hasAccessToPreview = false;
	let icdCodes = "";
	let m2h = false;
	let numberOfUnreadMessages = 0;
	let affiliation = "";
	let showLeadLandingPage = false;
	let rights = [];
	let followedMembers = [];
	let followedTopics = [];
	let verified = false;
	let hasAccessToCovid19Barometer = false;
	let contactType = "unknown";
	let contactSource = null;
	let isReverificationRequired = false;
	let isPostSurveyReferral = false;
	let wasPostSurveyReferralVerified = false;
	let memberSessionId = "";
	let hasBeenAuthenticatedViaToken = false;
	let shouldRefreshMemberNotifications = false;
	let shouldRefreshSurveysBadge = false;
	let placeOfEmployment = "";
	let isTemporarilyAuthenticated = false;
	let isLoyaltyProgramAccepted = undefined;
	let onboardingCompleted = true;
	let onboardingExpired = true;
	let isFirstPageLoad = false;
	let privacyPolicy = undefined;
	let referralLink = "";
	let showDoNotSellLink = false;
	let showFirstSurveyPendingVerificationPage = false;
	let worksAtSermo = false;

	if (!loaded) {
		// The first time the app loads, check the global window object for the
		// initial user object that was rendered out directly in the HTML from
		// the server (if user is already authenticated).
		if ("undefined" !== typeof window) {
			setLoaded(true);

			locale = window.sermo.locale;
			countryCode = window.sermo.countryCode;
			countryName = window.sermo.countryName;
			joinDate = window.sermo.user.joinDate;
			isAuthenticated = window.sermo.user.isAuthenticated;
			id = window.sermo.user.memberId;
			username = window.sermo.user.username;
			profilePic = window.sermo.user.profilePictureUrl;
			firstName = window.sermo.user.firstName;
			lastName = window.sermo.user.lastName;
			email = window.sermo.user.email;
			displayName = window.sermo.user.displayName;
			primarySpecialtyId = window.sermo.user.primarySpecialtyId;
			primarySpecialtyName = window.sermo.user.primarySpecialtyName;
			secondarySpecialtyId = window.sermo.user.secondarySpecialtyId;
			secondarySpecialtyName = window.sermo.user.secondarySpecialtyName;
			hasPreferredStatus = window.sermo.user.hasPreferredStatus;
			userType = window.sermo.user.userType;
			trialMember = window.sermo.user.trialMember;
			affiliateMember = window.sermo.user.affiliateMember;
			hasSeenTrialMemberPopup = false;
			readOnlyMember = window.sermo.user.readOnlyMember;
			hasSeenReadOnlyMemberPopup = false;
			hasSeenPostSurveyReferralPopup = false;
			hasSeenReverificationPopup = false;
			hasPendingDeactivationRequest = window.sermo.user.hasPendingDeactivationRequest;
			daysUntilDeactivation = window.sermo.user.daysUntilDeactivation;
			anonymous = window.sermo.user.anonymous;
			hasConfiguredProfile = window.sermo.user.hasConfiguredProfile;
			hasSetPassword = window.sermo.user.hasSetPassword;
			surveyOutcome = window.sermo.user.surveyOutcome;
			surveyOutcomeIncentiveAmount = window.sermo.user.surveyOutcomeIncentiveAmount;
			surveyOutcomeIncentiveCurrency = window.sermo.user.surveyOutcomeIncentiveCurrency;
			legacyUser = window.sermo.user.legacyUser;
			showTourGuide = window.sermo.user.showTourGuide;
			brandAdministrator = window.sermo.user.brandAdministrator;
			hasAcceptedTermsAndConditions = window.sermo.user.hasAcceptedTermsAndConditions;
			hasAccessToAdPreview = window.sermo.user.hasAccessToAdPreview;
			hasAccessToPreview = window.sermo.user.hasAccessToPreview;
			icdCodes = window.sermo.user.icdCodes;
			initialReferral = document.referrer;
			initialQueryParameters = window.location.search;
			queryParams = window?.location?.search
				? Object.fromEntries(new URLSearchParams(window.location.search).entries())
				: {};
			m2h = window.sermo.user.m2h;
			numberOfUnreadMessages = 0;
			affiliation = window.sermo.affiliation;
			showLeadLandingPage = window.sermo.user.showLeadLandingPage;
			rights = window.sermo.user.rights;
			verified = window.sermo.user.verified;
			hasAccessToCovid19Barometer = window.sermo.user.hasAccessToCovid19Barometer;
			contactType = window.sermo.user.contactType;
			contactSource = window.sermo.user.contactSource;
			isReverificationRequired = window.sermo.user.isReverificationRequired;
			isPostSurveyReferral = window.sermo.user.isPostSurveyReferral;
			wasPostSurveyReferralVerified = window.sermo.user.wasPostSurveyReferralVerified;
			memberSessionId = window.sermo.user.memberSessionId;
			hasBeenAuthenticatedViaToken = window.sermo.user.hasBeenAuthenticatedViaToken;
			shouldRefreshMemberNotifications = window.sermo.user.shouldRefreshMemberNotifications;
			shouldRefreshSurveysBadge = false;
			placeOfEmployment = window.sermo.user.placeOfEmployment;
			isLoyaltyProgramAccepted = window.sermo.user.isLoyaltyProgramAccepted ?? undefined;
			onboardingCompleted = window.sermo.user.onboardingCompleted;
			onboardingExpired = window.sermo.user.onboardingExpired;
			isFirstPageLoad = window.sermo.user.isFirstPageLoad;
			privacyPolicy = window.sermo.user.privacyPolicy;
			referralLink = window.sermo.user.referralLink;
			showDoNotSellLink = window.sermo.user.showDoNotSellLink;
			showFirstSurveyPendingVerificationPage = window.sermo.user.showFirstSurveyPendingVerificationPage;
			worksAtSermo = window.sermo.user.worksAtSermo;
		}
	}

	const [memberState, memberDispatch] = useReducer(memberReducer, {
		loggedIn: isAuthenticated,
		active: active,
		locale: locale,
		countryCode: countryCode,
		countryName: countryName,
		joinDate: joinDate,
		id: id,
		profilePic: profilePic,
		username: username,
		firstName: firstName,
		lastName: lastName,
		email: email,
		displayName: displayName,
		primarySpecialtyId: primarySpecialtyId,
		primarySpecialtyName: primarySpecialtyName,
		secondarySpecialtyId: secondarySpecialtyId,
		secondarySpecialtyName: secondarySpecialtyName,
		hasPreferredStatus: hasPreferredStatus,
		userType: userType,
		initialReferral: initialReferral,
		initialQueryParameters: initialQueryParameters,
		queryParams: queryParams,
		hasPendingDeactivationRequest: hasPendingDeactivationRequest,
		daysUntilDeactivation: daysUntilDeactivation,
		anonymous: anonymous,
		hasConfiguredProfile: hasConfiguredProfile,
		hasSetPassword: hasSetPassword,
		surveyOutcome: surveyOutcome,
		surveyOutcomeIncentiveAmount: surveyOutcomeIncentiveAmount,
		surveyOutcomeIncentiveCurrency: surveyOutcomeIncentiveCurrency,
		hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions,
		hasAccessToAdPreview: hasAccessToAdPreview,
		hasAccessToPreview: hasAccessToPreview,
		legacyUser: legacyUser,
		showTourGuide: showTourGuide,
		brandAdministrator: brandAdministrator,
		trialMember: trialMember,
		affiliateMember: affiliateMember,
		hasSeenTrialMemberPopup: hasSeenTrialMemberPopup,
		readOnlyMember: readOnlyMember,
		hasSeenReadOnlyMemberPopup: hasSeenReadOnlyMemberPopup,
		hasSeenPostSurveyReferralPopup: hasSeenPostSurveyReferralPopup,
		hasSeenReverificationPopup: hasSeenReverificationPopup,
		icdCodes: icdCodes,
		m2h: m2h,
		numberOfUnreadMessages: numberOfUnreadMessages,
		affiliation: affiliation,
		showLeadLandingPage: showLeadLandingPage,
		rights: rights,
		followedMembers: followedMembers,
		followedTopics: followedTopics,
		verified: verified,
		hasAccessToCovid19Barometer: hasAccessToCovid19Barometer,
		contactType: contactType,
		contactSource: contactSource,
		isReverificationRequired: isReverificationRequired,
		isPostSurveyReferral: isPostSurveyReferral,
		wasPostSurveyReferralVerified: wasPostSurveyReferralVerified,
		memberSessionId: memberSessionId,
		hasBeenAuthenticatedViaToken: hasBeenAuthenticatedViaToken,
		shouldRefreshMemberNotifications: shouldRefreshMemberNotifications,
		shouldRefreshSurveysBadge: shouldRefreshSurveysBadge,
		placeOfEmployment: placeOfEmployment,
		isTemporarilyAuthenticated: isTemporarilyAuthenticated,
		isLoyaltyProgramAccepted: isLoyaltyProgramAccepted,
		onboardingCompleted: onboardingCompleted,
		onboardingExpired: onboardingExpired,
		isFirstPageLoad: isFirstPageLoad,
		privacyPolicy: privacyPolicy,
		referralLink: referralLink,
		showDoNotSellLink: showDoNotSellLink,
		showFirstSurveyPendingVerificationPage: showFirstSurveyPendingVerificationPage,
		worksAtSermo: worksAtSermo,
	});

	const logoutMember = id => {
		memberDispatch({
			type: MEMBER_LOGOUT,
			id: id,
		});
	};

	const updateMember = data => {
		memberDispatch({
			type: MEMBER_UPDATE,
			data: data,
		});
	};

	/**
	 * Checks whether this member has access to a given right.
	 * @param {string} right - the name of the right to check.
	 * @param {boolean} ignoreIfTrialOrAffiliateMember - true to ignore right check if the member
	 * is a trial or affiliate member. This is used as some rights should be
	 * ignored specifically for trial/affiliate members.
	 * @returns true if the member has access to the right
	 * (or if ignored if trial or affiliate member); otherwise returns false.
	 */
	const hasAccess = (right, ignoreIfTrialOrAffiliateMember = true) => {
		if (memberState) {
			if (ignoreIfTrialOrAffiliateMember && (memberState.trialMember || memberState.affiliateMember)) {
				return true;
			}

			if (memberState.rights) {
				return memberState.rights[right];
			}
		}

		return false;
	};

	const memberPrivacyPolicyAccepted = () => {
		memberDispatch({
			type: MEMBER_PRIVACY_POLICY_ACCEPTED,
		});
	};

	const memberTopicFollow = topic => {
		memberDispatch({
			type: MEMBER_TOPIC_FOLLOW,
			topic,
		});
	};

	const memberTopicUnfollow = topic => {
		memberDispatch({
			type: MEMBER_TOPIC_UNFOLLOW,
			topic,
		});
	};

	const memberFollow = member => {
		memberDispatch({
			type: MEMBER_FOLLOW,
			member,
		});
	};

	const memberUnfollow = member => {
		memberDispatch({
			type: MEMBER_UNFOLLOW,
			member,
		});
	};

	const memberStateForContextProvider = {
		...memberState,
	};

	const formatMemberForFeed = (user, isAnonymous, partnerMemberId) => {
		let userObject = {
			anonymous: isAnonymous,
			following: false,
			blocked: false,
			specialties: [],
		};

		if (isAnonymous) {
			userObject.id = user.id;
			userObject.username = getTranslation("system.feeds.anonymousUser", true);

			if ("undefined" !== typeof window) {
				userObject.profilePic = window.sermo.publicCdnBaseUrl + "profileimages/anonymous.png";
			} else {
				userObject.profilePic = "/assets/images/anonymous.png";
			}
		} else {
			userObject.id = user.id;
			userObject.username = user.displayName
				? user.displayName
				: user.username; // when a user answers their own question right after publishing
			userObject.profilePic = user.profilePic;
			userObject.location = user.countryName;
			userObject.verified = user.verified;
			userObject.joinDate = user.joinDate;
			userObject.userType = user.userType;
			userObject.brandAdministrator = user.brandAdministrator;
			userObject.trialMember = user.trialMember;
			userObject.affiliateMember = user.affiliateMember;
			userObject.readOnlyMember = user.readOnlyMember;
			userObject.isReverificationRequired = user.isReverificationRequired;
			userObject.worksAtSermo = user.worksAtSermo;

			if (partnerMemberId) {
				if (partnerMemberId === user.id) {
					userObject.partner = true;
				}
			}
		}

		if (user.primarySpecialtyId) {
			if (user.primarySpecialtyId > 0) {
				userObject.specialties.push({
					id: user.primarySpecialtyId,
					name: user.primarySpecialtyName,
				});
			}
		}
		if (user.secondarySpecialtyId) {
			if (user.secondarySpecialtyId > 0) {
				userObject.specialties.push({
					id: user.secondarySpecialtyId,
					name: user.secondarySpecialtyName,
				});
			}
		}

		return userObject;
	};

	if (!Object.prototype.hasOwnProperty.call(memberState, "logoutMember")) {
		memberStateForContextProvider.logoutMember = logoutMember;
	}
	if (!Object.prototype.hasOwnProperty.call("updateMember")) {
		memberStateForContextProvider.updateMember = updateMember;
	}
	if (!Object.prototype.hasOwnProperty.call("formatMemberForFeed")) {
		memberStateForContextProvider.formatMemberForFeed = formatMemberForFeed;
	}
	if (!Object.prototype.hasOwnProperty.call("hasAccess")) {
		memberStateForContextProvider.hasAccess = hasAccess;
	}
	if (!Object.prototype.hasOwnProperty.call("memberPrivacyPolicyAccepted")) {
		memberStateForContextProvider.memberPrivacyPolicyAccepted = memberPrivacyPolicyAccepted;
	}
	if (!Object.prototype.hasOwnProperty.call("memberTopicFollow")) {
		memberStateForContextProvider.memberTopicFollow = memberTopicFollow;
	}
	if (!Object.prototype.hasOwnProperty.call("memberTopicUnfollow")) {
		memberStateForContextProvider.memberTopicUnfollow = memberTopicUnfollow;
	}
	if (!Object.prototype.hasOwnProperty.call("memberFollow")) {
		memberStateForContextProvider.memberFollow = memberFollow;
	}

	if (!Object.prototype.hasOwnProperty.call("memberUnfollow")) {
		memberStateForContextProvider.memberUnfollow = memberUnfollow;
	}

	return <MemberContext.Provider value={memberStateForContextProvider}>{children}</MemberContext.Provider>;
};

// Fetches the users followed topics and loads them into the member context for use app wide
const FollowedTopicsInitializer = () => {
	const { hasAccess, updateMember } = useMemoizedContext("member");
	const [topicData, loading, error] = useFetch("api/topics/listfollowed");

	useEffect(() => {
		if (
			hasAccess("canFollowItemsInFrontend")
			&& !loading
			&& !error
			&& Object.prototype.hasOwnProperty.call(topicData, "topics")
		) {
			updateMember({ followedTopics: topicData.topics });
		}
	}, [loading]);

	return null;
};

const GlobalState = ({ children }) => (
	<UIProvider>
		<StackProvider>
			<UIMenuContextProvider>
				<MemberContextProvider>
					{
						"undefined" !== typeof window && window.sermo.user.isAuthenticated && (
							<FollowedTopicsInitializer />
						)
					}
					<MessagesContextProvider>
						<SearchContextProvider>
							<OnboardingProvider>
								<RunWithPeterContextProvider>
									<ScrollContextProvider>
										<ScrollManager>
											<ActivityMonitor/>
											<Automation />
											{children}
										</ScrollManager>
									</ScrollContextProvider>
								</RunWithPeterContextProvider>
							</OnboardingProvider>
						</SearchContextProvider>
					</MessagesContextProvider>
				</MemberContextProvider>
			</UIMenuContextProvider>
		</StackProvider>
	</UIProvider>
);

export default GlobalState;
