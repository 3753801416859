import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { DropDownList, TextField } from "@components/FormFields/FormFields";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import formFieldStyles from "../../FormFields001.scss";
import styles from "./Address.scss";

const Address = props => {
	const {
		addressLine1,
		addressLine2,
		city,
		region,
		postalCode,
		countryCode,
		allRegions,
		allCountries,
		className,
		forceValidation,
		addressTypeChangedEventHandler,
		addressChangedEventHandler,
		formValidationErrors,
		disableCountrySelect,
		currentAddressType,
		availableAddressTypes,
		readOnly,
	} = props;

	/* Takes an ISO 2 country-code as input, and returns an array of regions that matches that country code. */
	const getAvailableRegionsForCountry = countryCode => {
		let availableRegions = [];

		if (countryCode) {
			for (let i = 0; i < allRegions.length; i++) {
				if (allRegions[i].countryCode === countryCode) {
					availableRegions.push({
						key: allRegions[i].key,
						value: allRegions[i].value,
					});
				}
			}
		}

		return availableRegions;
	};

	const [addressLine1Internal, setAddressLine1Internal] = useState(addressLine1);
	const [addressLine2Internal, setAddressLine2Internal] = useState(addressLine2);
	const [cityInternal, setCityInternal] = useState(city);
	const [regionInternal, setRegionInternal] = useState(region);
	const [postalCodeInternal, setPostalCodeInternal] = useState(postalCode);
	const [countryCodeInternal, setCountryCodeInternal] = useState(countryCode);
	const [availableRegionsInternal, setAvailableRegionsInternal]
		= useState(getAvailableRegionsForCountry(countryCode));

	const [addressLine1InternalValidationError, setAddressLine1InternalValidationError] = useState("");
	const [addressLine2InternalValidationError, setAddressLine2InternalValidationError] = useState("");
	const [cityInternalValidationError, setCityInternalValidationError] = useState("");
	const [regionInternalValidationError, setRegionInternalValidationError] = useState("");
	const [postalCodeInternalValidationError, setPostalCodeInternalValidationError] = useState("");
	const [countryCodeInternalValidationError, setCountryCodeInternalValidationError] = useState("");

	useEffect(() => {
		setAddressLine1Internal(addressLine1);
		setAddressLine2Internal(addressLine2);
		setCityInternal(city);
		setRegionInternal(region);
		setPostalCodeInternal(postalCode);
		setCountryCodeInternal(countryCode);
	}, [currentAddressType]);

	const validateCountry = () => {
		if (!countryCodeInternal) {
			setCountryCodeInternalValidationError(
				getTranslation("system.validationMessages.validationCountryIsRequired", true)
			);
			return false;
		}

		setCountryCodeInternalValidationError("");
		return true;
	};

	const validateAddress = () => {
		if (!addressLine1Internal) {
			setAddressLine1InternalValidationError(
				getTranslation("system.validationMessages.validationStreetAddressIsRequired", true)
			);
			return false;
		}

		setAddressLine1InternalValidationError("");
		return true;
	};

	const validateCity = () => {
		if (!cityInternal) {
			setCityInternalValidationError(
				getTranslation("system.validationMessages.validationCityIsRequired", true)
			);
			return false;
		}

		setCityInternalValidationError("");
		return true;
	};

	const validatePostalCode = () => {
		if (!postalCodeInternal) {
			setPostalCodeInternalValidationError(
				getTranslation("system.validationMessages.validationPostalCodeIsRequired", true)
			);
			return false;
		}

		setPostalCodeInternalValidationError("");
		return true;
	};

	const validateRegion = () => {
		if (availableRegionsInternal && availableRegionsInternal.length > 0) {
			if (!regionInternal) {
				setRegionInternalValidationError(
					getTranslation("system.validationMessages.validationStateIsRequired", true)
				);
				return false;
			}
		}

		setRegionInternalValidationError("");
		return true;
	};

	const validateForm = () => {
		const validateList = [validateCountry, validateAddress, validateCity, validateRegion, validatePostalCode];
		const isValid = validateList.filter(val => val()).length === validateList.length;

		if (addressChangedEventHandler) {
			if (isValid) {
				let countryName = "";

				for (let i = 0; i < allCountries.length; i++) {
					if (allCountries[i].value === countryCodeInternal) {
						countryName = allCountries[i].key;
					}
				}

				addressChangedEventHandler({
					addressLine1: addressLine1Internal,
					addressLine2: addressLine2Internal,
					city: cityInternal,
					region: regionInternal,
					postalCode: postalCodeInternal,
					countryCode: countryCodeInternal,
					country: countryName,
				});
			} else {
				addressChangedEventHandler(null);
			}
		}

		return isValid;
	};

	const addressTypeInternalChangeHandler = event => {
		addressTypeChangedEventHandler(event.target.value);
	}

	const addressLine1InternalChangeHandler = event => {
		setAddressLine1Internal(event.target.value);
	};

	const addressLine2InternalChangeHandler = event => {
		setAddressLine2Internal(event.target.value);
		validateAddress();
	};

	const cityInternalChangeHandler = event => {
		setCityInternal(event.target.value);
	};

	const regionInternalChangeHandler = event => {
		setRegionInternal(event.target.value);
	};

	const postalCodeInternalChangeHandler = event => {
		setPostalCodeInternal(event.target.value);
	};

	const countryCodeInternalChangeHandler = event => {
		setCountryCodeInternal(event.target.value);
		setRegionInternal("");
		setAvailableRegionsInternal(getAvailableRegionsForCountry(event.target.value));
	};

	useEffect(() => {
		if (forceValidation) {
			validateForm();
		}
	}, [forceValidation]);

	useEffect(() => {
		validateRegion();
	}, [regionInternal]);

	useEffect(() => {
		validateCountry();
	}, [countryCodeInternal]);

	useEffect(() => {
		let hasErrors = false;
		if (formValidationErrors) {
			for (let property in formValidationErrors) {
				switch (property) {
					case "addressLine1":
						hasErrors = true;
						setAddressLine1InternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "addressLine2":
						hasErrors = true;
						setAddressLine2InternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "city":
						hasErrors = true;
						setCityInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "region":
						hasErrors = true;
						setRegionInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "postalCode":
						hasErrors = true;
						setPostalCodeInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "countryCode":
						hasErrors = true;
						setCountryCodeInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
				}
			}
		}

		if (!hasErrors) {
			setAddressLine1InternalValidationError("");
			setAddressLine2InternalValidationError("");
			setCityInternalValidationError("");
			setRegionInternalValidationError("");
			setPostalCodeInternalValidationError("");
			setCountryCodeInternalValidationError("");
		}
	}, [formValidationErrors]);

	return (
		<div styleName={classNames([{ [`${className}`]: className }, "styles.address"])}>
			{
				!!currentAddressType && <div styleName="styles.form-line">
					<DropDownList
						name="addressType"
						label={"Type of address"}
						handleChange={addressTypeInternalChangeHandler}
						options={availableAddressTypes}
						value={currentAddressType}
						readOnly={readOnly}
					/>
				</div>
			}
			<div styleName="styles.form-line">
				<TextField
					name="addressLine1Internal"
					label={getTranslation("frontend.forms.address.addressLine1Label", true)}
					type="text"
					autoComplete="address-line1"
					validationError={addressLine1InternalValidationError}
					handleChange={addressLine1InternalChangeHandler}
					value={addressLine1Internal}
					handleBlur={validateAddress}
					autoFocus={!currentAddressType}
					readOnly={readOnly}
				/>
			</div>

			<div styleName="styles.form-line">
				<TextField
					name="addressLine2Internal"
					label={getTranslation("frontend.forms.address.addressLine2Label", true)}
					type="text"
					autoComplete="address-line2"
					validationError={addressLine2InternalValidationError}
					handleChange={addressLine2InternalChangeHandler}
					value={addressLine2Internal}
					handleBlur={validateAddress}
					readOnly={readOnly}
				/>
			</div>

			<div styleName="styles.form-line">
				<div styleName="styles.one-third-width styles.first">
					<TextField
						name="cityInternal"
						label={getTranslation("frontend.forms.address.cityLabel", true)}
						type="text"
						autoComplete="address-level2"
						validationError={cityInternalValidationError}
						handleChange={cityInternalChangeHandler}
						value={cityInternal}
						handleBlur={validateCity}
						readOnly={readOnly}
					/>
				</div>
				<div styleName="styles.one-third-width">
					{
						availableRegionsInternal.length > 0 && (
							<DropDownList
								name="regionInternal"
								label={getTranslation("frontend.forms.address.stateOrRegionLabel", true)}
								autoComplete="address-level1"
								handleChange={regionInternalChangeHandler}
								validationError={regionInternalValidationError}
								options={availableRegionsInternal}
								value={regionInternal}
								readOnly={readOnly}
								maxHeight={200}
							/>
						)
					}
					{
						!availableRegionsInternal.length && (
							<TextField
								name="regionInternal"
								label={getTranslation("frontend.forms.address.stateOrRegionLabel", true)}
								type="text"
								autoComplete="address-level1"
								validationError={regionInternalValidationError}
								handleChange={regionInternalChangeHandler}
								value={regionInternal}
								handleBlur={validateRegion}
								readOnly={readOnly}
							/>
						)
					}
				</div>
				<div styleName="styles.one-third-width styles.last">
					<TextField
						name="postalCodeInternal"
						label={getTranslation("frontend.forms.address.zipCodeLabel", true)}
						type="text"
						autoComplete="postal-code"
						validationError={postalCodeInternalValidationError}
						handleChange={postalCodeInternalChangeHandler}
						value={postalCodeInternal}
						handleBlur={validatePostalCode}
						readOnly={readOnly}
					/>
				</div>
			</div>
			{
				!disableCountrySelect && <div styleName="styles.form-line">
					<DropDownList
						name="countryCodeInternal"
						label={getTranslation("frontend.forms.address.countryLabel", true)}
						autoComplete="country"
						validationError={countryCodeInternalValidationError}
						handleChange={countryCodeInternalChangeHandler}
						options={allCountries}
						value={countryCodeInternal}
						disabled={disableCountrySelect}
						readOnly={readOnly}
					/>
				</div>
			}
			{
				disableCountrySelect && <div styleName="styles.form-line">
					<div styleName="styles.half-width styles.first">
						<label
							styleName="styles.readonly-label"
						>
							{getTranslation("frontend.forms.address.countryLabel", true)}
						</label>
						<div>{allCountries.find(country => country.value === countryCodeInternal)?.key || ""}</div>
					</div>
				</div>
			}
		</div>
	);
};

Address.propTypes = {
	addressLine1: PropTypes.string,
	city: PropTypes.string,
	region: PropTypes.string,
	postalCode: PropTypes.string,
	countryCode: PropTypes.string,
	allRegions: PropTypes.array.isRequired,
	allCountries: PropTypes.array.isRequired,
	className: PropTypes.string,
	currentAddressType: PropTypes.string,
	availableAddressTypes: PropTypes.array,
	forceValidation: PropTypes.bool,
	addressTypeChangedEventHandler: PropTypes.func,
	addressChangedEventHandler: PropTypes.func,
	formValidationErrors: PropTypes.object,
	disableCountrySelect: PropTypes.bool,
	readOnly: PropTypes.bool,
};

Address.defaultProps = {
	addressLine1: "",
	city: "",
	region: "",
	postalCode: "",
	countryCode: "",
	allRegions: [],
	allCountries: [],
	availableAddressTypes: [],
	className: null,
	currentAddressType: null,
	forceValidation: false,
	addressTypeChangedEventHandler: () => {},
	addressChangedEventHandler: () => {},
	formValidationErrors: null,
	disableCountrySelect: true,
	readOnly: false,
};

export default Address;
