import { getSVGIconsObject, Icon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import classNames from "classnames";
import React, { useEffect } from "react";
import Button from "@components/Button/Button";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import Events from "@components/RailCards/components/Events/Events";
import MemberBalance from "@components/RailCards/components/MemberBalance/MemberBalance";
import { useCloseModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { usePreconditionModal } from "@frontend/Utils";
import { useFetch, useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import { Loading } from "../../../Helpers/Helpers";
import surveys from "../../Surveys.scss";
import styles from "./AvailableSurveys.scss";
import * as Styles from "./AvailableSurveys.styles";

const AvailableSurveys = () => {
	const closeModal = useCloseModal();
	const trackEvent = useTrackEvent();

	const {
		hasAccess,
		trialMember,
		affiliateMember,
		locale,
		updateMember,
		queryParams,
		isPostSurveyReferral,
	} = useMemoizedContext("member", [
		"hasAccess",
		"trialMember",
		"affiliateMember",
		"locale",
		"queryParams",
		"isPostSurveyReferral",
	]);

	let isMnowApp = false;
	if (
		"undefined" !== typeof window
		&& window.sermo.affiliation
		&& window.sermo.affiliation === "mnow"
	) {
		isMnowApp = true;
	}

	const closeTrialMemberModalEventHandler = () => {
		trackEvent({
			category: "trialmembermodal",
			label: "surveys",
			locale: locale,
			action: "close",
			queryParams: queryParams,
		});

		closeModal("trial-member");
	};

	const { openPreconditionModal } = usePreconditionModal(closeTrialMemberModalEventHandler);

	const onShowTrialMemberPopupClick = e => {
		if (trialMember || affiliateMember) {
			const trackingObject = {
				label: "surveys",
				locale: locale,
				queryParams: queryParams,
			};
			openPreconditionModal(trackingObject);

			updateMember({ hasSeenTrialMemberPopup: true });

			e.preventDefault();
			e.stopPropagation();
		}
	}

	if (!hasAccess("canAccessFrontendSurveys", false)) {
		if (isMnowApp) {
			return (
				<div styleName={classNames(["styles.available-surveys"])}>
					<ModuleWrapper
						border={true}
						solid={true}
					>
						{
							!trialMember && !isPostSurveyReferral && !affiliateMember && (
								<div
									styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}
								>
									{getTranslation("frontend.surveys.availableSurveys.noAvailableSurveys")}
								</div>
							)
						}

						{
							(trialMember || affiliateMember) && (
								<>
									<div
										styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}
									>
										{
											getTranslation(
												"frontend.surveys.availableSurveys.completeYourTrialMembershipTextMnow"
											)
										}
									</div>
									<div
										styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}
									>
										<a
											href="#"
											onClick={onShowTrialMemberPopupClick}
										>
											{
												getTranslation(
												// eslint-disable-next-line max-len
													"frontend.surveys.availableSurveys.clickHereToCompleteYourTrialMembershipLinkMnow",
													true
												)
											}
										</a>
									</div>
								</>
							)
						}
					</ModuleWrapper>
				</div>
			);
		}

		return <span>&nbsp;</span>; // returning null for some reason gives very large gap between header and content
	}

	return <AvailableSurveysWrapped />
}

const SurveyTitleTag = ({ survey }) => {
	let styles = ["styles.tag", "typography.overline-brand-02"];
	let text = "";

	if (survey.inProgress) {
		styles.push("styles.tag--in-progress");
		text = getTranslation("frontend.surveys.availableSurveyCards.surveyInProgress", true);
	} else if (survey.almostFull) {
		styles.push("styles.tag--almost-full");
		text = getTranslation("frontend.surveys.availableSurveyCards.surveyAlmostFull", true);
	} else {
		text = getTranslation("frontend.surveys.availableSurveyCards.earnTitle", true);
	}

	return <div styleName={classNames(styles)}>{text}</div>;
};

const PreQualifiedCallout = ({ survey }) => {
	if (!survey.preQualified) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.survey-callout", "typography.body-short-brand-02"])}>
			<div styleName={classNames(["styles.survey-callout--icon"])}>&nbsp;</div>
			<span styleName={classNames(["styles.survey-callout--text"])}>
				{getTranslation("frontend.surveys.availableSurveyCards.preQualifiedForSurvey", true)}
			</span>
		</div>
	);
};

const LimitedIncentivePercentCallout = ({ survey }) => {
	if (!survey.limitedIncentivePercent) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.survey-callout", "typography.body-short-brand-02"])}>
			<div styleName={classNames(["styles.survey-callout--icon"])}>&nbsp;</div>
			<span styleName={classNames(["styles.survey-callout--text"])}>
				{
					getTranslation(
						"frontend.surveys.availableSurveyCards.limitedTimeEarnMorePercent",
						true,
						survey.limitedIncentivePercent
					)
				}
			</span>
		</div>
	);
};

const LimitedIncentiveAmountCallout = ({ survey }) => {
	if (!survey.limitedIncentiveAmount) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.survey-callout", "typography.body-short-brand-02"])}>
			<div styleName={classNames(["styles.survey-callout--icon"])}>&nbsp;</div>
			<span styleName={classNames(["styles.survey-callout--text"])}>
				{
					getTranslation(
						"frontend.surveys.availableSurveyCards.limitedTimeEarnMoreMoney",
						true,
						survey.limitedIncentiveAmount
					)
				}
			</span>
		</div>
	);
};

const MinimumPayoutAmountCallout = ({ survey }) => {
	if (!survey.minimumPayoutAmount) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.survey-callout", "typography.body-short-brand-02"])}>
			<div styleName={classNames(["styles.survey-callout--icon"])}>&nbsp;</div>
			<span styleName={classNames(["styles.survey-callout--text"])}>
				{
					getTranslation(
						"frontend.surveys.availableSurveyCards.minimumPayoutGuaranteedMoney",
						true,
						survey.minimumPayoutAmount
					)
				}
			</span>
		</div>
	);
};

const DurationInMinutesCallout = ({ survey }) => {
	if (!survey.durationInMinutes) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.inner-card"])}>
			<span
				styleName={classNames(["styles.inner-card--title", "typography.body-short-brand-02"])}
			>
				Duration
			</span>
			<span styleName={classNames(["styles.inner-card--value", "typography.heading-brand-03"])}>
				{
					getTranslation(
						"frontend.surveys.availableSurveyCards.surveyDuration",
						true,
						survey.durationInMinutes
					)
				}
			</span>
		</div>
	);
};

const RemainingSeatsCallout = ({ survey }) => {
	if (!survey.remainingSeats) {
		return null;
	}

	return (
		<div styleName={classNames(["styles.inner-card"])}>
			<span styleName={classNames(["styles.inner-card--title", "typography.body-short-brand-02"])}>
				{getTranslation("frontend.surveys.availableSurveyCards.seatsRemainingTitle", true)}
			</span>
			{
				survey.almostFull && (
					<span
						styleName={
							classNames([
								"styles.inner-card--value",
								"styles.inner-card--value--limited",
								"typography.heading-brand-03",
							])
						}
					>
						{
							survey.remainingSeats !== 1
								? getTranslation(
									"frontend.surveys.availableSurveyCards.onlyXSeatsLeftPlural",
									true,
									survey.remainingSeats
								)
								: getTranslation(
									"frontend.surveys.availableSurveyCards.onlyXSeatsLeftSingular",
									true,
									survey.remainingSeats
								)
						}
					</span>
				)
			}
			{
				!survey.almostFull && (
					<span styleName={classNames(["styles.inner-card--value", "typography.heading-brand-03"])}>
						{
							survey.remainingSeats !== 1
								? getTranslation(
									"frontend.surveys.availableSurveyCards.seatsRemainingTotalPlural",
									true,
									survey.remainingSeats
								)
								: getTranslation(
									"frontend.surveys.availableSurveyCards.seatsRemainingTotalSingular",
									true,
									survey.remainingSeats
								)
						}
					</span>
				)
			}
		</div>
	);
};

const HonorariumCallout = ({ survey }) => {
	if (
		!survey.honorariumAmount
		|| survey.honorariumAmount <= 0
		|| !!survey.programsEnrolled
	) {
		return null;
	}

	const honorarium = getCurrencyTranslation(
		survey.honorariumAmount,
		survey.currency,
		false,
		0
	);

	return <Styles.EarnAmount>{honorarium}</Styles.EarnAmount>;
};

const EnrolledProgramsCallout = ({ survey }) => {
	if (!survey.programsEnrolled) {
		return null;
	}

	const enrolledProgram = survey.programsEnrolled[0];
	const icons = getSVGIconsObject();

	return (
		<Styles.EnrolledProgramContainer>
			<Icon
				height={24}
				width={24}
				src={icons[enrolledProgram.icon]}
			/>
			<TextStyled $kind="bodyShort02">
				{enrolledProgram.honorariumText}
			</TextStyled>
		</Styles.EnrolledProgramContainer>
	);
};

const AllSurveyCallouts = ({ button, survey }) => (
	<Styles.AvailableSurveyCard>
		<Styles.TopRow>
			<Styles.LeftColumn>
				<Styles.SurveyName>
					<TextStyled $kind="heading03">
						{survey.name}
					</TextStyled>
				</Styles.SurveyName>
				<Styles.SurveyDescription>
					<TextStyled $kind="bodyShort02">
						{survey.description}
					</TextStyled>
				</Styles.SurveyDescription>
			</Styles.LeftColumn>
			<Styles.RightColumn>
				<HonorariumCallout survey={survey} />
			</Styles.RightColumn>
		</Styles.TopRow>
		<Styles.BottomRow>
			<Styles.LeftColumn>
				<PreQualifiedCallout survey={survey} />

				<LimitedIncentivePercentCallout survey={survey} />

				<LimitedIncentiveAmountCallout survey={survey} />

				<MinimumPayoutAmountCallout survey={survey} />

				<DurationInMinutesCallout survey={survey} />

				<RemainingSeatsCallout survey={survey} />
			</Styles.LeftColumn>
			<Styles.RightColumnWithButton>
				{button}
			</Styles.RightColumnWithButton>
		</Styles.BottomRow>
		<EnrolledProgramsCallout survey={survey} />
	</Styles.AvailableSurveyCard>
);

const AvailableSurveysWrapped = () => {
	const apiEndpoint = useApiEndpoint();
	const [data, loading, error] = useFetch("api/surveys/getavailablesurveys");
	const { updateMember, userType } = useMemoizedContext("member", ["userType"]);
	const isMnow = "mnow" === userType;

	useEffect(() => {
		if (!loading) {
			updateMember({ shouldRefreshSurveysBadge: true });
		}
	}, [loading]);

	if (loading) {
		return (
			<div styleName={classNames(["styles.available-surveys"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<Loading contextClass="surveys" />
				</ModuleWrapper>
			</div>
		);
	}

	if (error) {
		return (
			<div styleName={classNames(["styles.available-surveys"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<div styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}>
						{getTranslation("frontend.surveys.availableSurveys.noAvailableSurveys")}
					</div>
				</ModuleWrapper>
			</div>
		);
	}

	const TakeSurveyButton = ({ survey }) => {
		const buttonText = survey.inProgress
			? getTranslation("frontend.railCards.featuredSurvey.buttonContinueSurveyText", true)
			: getTranslation("frontend.surveys.availableSurveyCards.startSurveyButtonText", true);

		return (
			<div styleName={classNames(["styles.action-button"])}>
				<Button
					style="primary"
					size="medium"
					to={survey.surveyLink}
					external={true}
					target="_blank"
					disabled={loading}
					tracking={
						{
							category: "available-survey",
							action: "click",
							label: "start-survey",
							surveyId: survey.surveyId,
						}
					}
					clickHandler={
						e => {
						// changing button text to "In Progress" after click
							e.target.innerText = getTranslation(
								"frontend.railCards.featuredSurvey.buttonContinueSurveyText",
								true
							);
							apiEndpoint(
								"surveys/updateSurveyProgressStatus",
								{ projectId: survey.surveyId },
								false,
								"PUT"
							);
						}
					}
					rightsRequired={["canTakeFrontendSurveys"]}
				>
					{buttonText}
				</Button>
			</div>
		);
	};

	let hasSurveys = false;

	if (data.availableSurveys) {
		if (data.availableSurveys.length > 0) {
			hasSurveys = true;
		}
	}

	return (
		<Styles.AvailableSurveys>
			<MobileAndTabletPortrait>
				{isMnow && <Events />}
				<MemberBalance />
			</MobileAndTabletPortrait>
			{
				hasSurveys && (
					<>
						{
							data.availableSurveys.map((survey, index) => {
								let honorarium = (<span>&nbsp;</span>);

								if (
									survey.honorariumAmount
									&& survey.honorariumAmount > 0
								) {
									honorarium = getCurrencyTranslation(
										survey.honorariumAmount,
										survey.currency,
										false,
										0
									);
								}

								return (
									<Styles.AvailableSurveyCardContainer key={index}>
										<ModuleWrapper
											border={true}
											solid={true}
											paddedWider={true}
											contextClass="survey-card"
										>
											<SurveyTitleTag survey={survey} />
											<AllSurveyCallouts
												button={<TakeSurveyButton survey={survey} />}
												survey={survey}
											/>
										</ModuleWrapper>
									</Styles.AvailableSurveyCardContainer>
								);
							})
						}
					</>
				)
			}
			{
				!hasSurveys && (
					<ModuleWrapper
						border={true}
						solid={true}
					>
						<div styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}>
							{getTranslation("frontend.surveys.availableSurveys.noAvailableSurveys")}
						</div>
					</ModuleWrapper>
				)
			}
		</Styles.AvailableSurveys>
	);
};

export default AvailableSurveys;
