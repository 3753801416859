import React from "react";
import EditorPortion from "@components/Card/components/PostContentEditor/EditorPortion";
import { QuestionOption } from "@components/PostEditor/components/Options";
import PollEdit from "@components/PostEditor/components/PollEdit";
import PostEditorFooter from "@components/PostEditor/components/PostEditorFooter";
import TopicSelection from "@components/PostEditor/components/TopicSelection";
import * as SharedStyles from "@components/PostEditor/PostEditor.styles";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardModal from "@components/PostViewer/components/CardModal";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const QuestionEditor = () => {
    const { draftStatus, } = useMemoizedContext("postUI", [
        "draftStatus",
    ]);
    const composing = DraftStatusTypes.COMPOSING === draftStatus;
    const { postEditorIsOpen } = useMemoizedContext("ui", ["postEditorIsOpen"]);
    if (composing
        && !postEditorIsOpen) {
        return (<SharedStyles.TopLevelContainer>
				<div>
					<EditorPortion borderless max={280} placeholder={getTranslation("frontend.newPost.questionPlaceholder", true)} showCharCount/>
				</div>
			</SharedStyles.TopLevelContainer>);
    }
    return (<>
			{!composing && <CardModal isEditing={true}/>}
			<CardContainer>
				<SharedStyles.TopLevelContainer data-component="QuestionEditor">
					<CardHeader />
					<SharedStyles.MainContent>
						<div className="mobile-flex-container">
							<EditorPortion borderless emoji max={280} placeholder={getTranslation("frontend.newPost.questionPlaceholder", true)} showCharCount/>
							<SharedStyles.MarginBottom>
								<hr />
							</SharedStyles.MarginBottom>
							<QuestionOption />
						</div>
						<PollEdit />
						<TopicSelection />
					</SharedStyles.MainContent>
					<PostEditorFooter />
				</SharedStyles.TopLevelContainer>
			</CardContainer>
		</>);
};
export default QuestionEditor;
