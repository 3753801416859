import { ColorGrey100 } from "@sermo/design-tokens";
import { Icon, XSVG, AttachmentImageSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Button, { buttonPropTypes } from "@components/Button/Button";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./FileUpload.scss";

const FileUploadRemovalIconStyled = styled(Icon)`
	fill: ${ColorGrey100};
`;

const FileUpload = ({
	buttonText,
	size,
	buttonLabel,
	validationError,
	limitToFileTypes,
	renderRoundPreviewImage,
	onFileAdded,
	onFileRemoved,
}) => {
	const [selectedFileName, setSelectedFileName] = useState("");
	const [validationErrorInternal, setValidationErrorInternal] = useState(validationError);

	const [previewImageStyle, setPreviewImageStyle] = useState({});

	let fileInputRef = React.createRef();

	const [previewImageSource, setPreviewImageSource] = useState("");

	const openFileDialog = () => {
		fileInputRef.current.click();
	};

	const isFileExtensionAllowed = (fileName) => {
		if (limitToFileTypes) {
			if (limitToFileTypes.length > 0) {
				let a = fileName.split(".");
				let extension = a[a.length - 1].toLowerCase().trim();

				let s = limitToFileTypes.split(",");
				for (let i = 0; i < s.length; i++) {
					let h = s[i].toLowerCase().trim().replace(".", "");
					if (h.length > 0) {
						if (extension === h) {
							return true;
						}
					}
				}
				return false;
			}
		}
		return true;
	};

	const onFileAddedEvent = (event) => {
		setPreviewImageSource("");
		let file = event.target.files.item(0);
		if (file) {
			if (isFileExtensionAllowed(file.name)) {
				setSelectedFileName(file.name);

				if (renderRoundPreviewImage) {
					let reader = new FileReader();

					reader.onload = (e) => {
						const img = new Image();

						img.onload = () => handleValidateImagePreviewLoaded(img.naturalWidth, img.naturalHeight, e.target.result);
						img.src = e.target.result;
					};

					reader.readAsDataURL(file);
				}

				onFileAdded(file);
			} else {
				setValidationErrorInternal(
					getTranslation(
						"system.validationMessages.validationOnlySpecifiedFileTypesAreAllowed",
						true,
						limitToFileTypes
					)
				);
			}
		} else {
			setValidationErrorInternal(getTranslation("system.validationMessages.validationPleaseSelectAFile", true));
		}
	};

	const onFileRemovedEvent = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setPreviewImageSource("");
		setSelectedFileName("");

		fileInputRef.current.value = null;

		onFileRemoved();
	};

	const handleValidateImagePreviewLoaded = (imageWidth, imageHeight, result) => {
		let maxWidth = 80;
		let maxHeight = 80;

		if (imageWidth >= maxWidth && imageHeight >= maxHeight) {
			let previewImageWidth = maxWidth;
			let previewImageHeight = maxHeight;
			let previewImageTop = 0;
			let previewImageLeft = 0;

			if (imageHeight > imageWidth) {
				previewImageHeight = Math.round((imageHeight / imageWidth) * maxHeight);
				previewImageTop = Math.round((maxHeight - previewImageHeight) / 2.0);
			} else if (imageHeight < imageWidth) {
				previewImageWidth = Math.round((imageWidth / imageHeight) * maxWidth);
				previewImageLeft = Math.round((maxWidth - previewImageWidth) / 2.0);
			}

			setPreviewImageStyle({
				width: previewImageWidth + "px",
				height: previewImageHeight + "px",
				left: previewImageLeft + "px",
				top: previewImageTop + "px",
			});

			setPreviewImageSource(result);
			setValidationErrorInternal("");
		} else {
			setValidationErrorInternal(getTranslation("system.validationMessages.validationImageTooSmall", true));
			setSelectedFileName("");

			if (fileInputRef.current) {
				fileInputRef.current.value = null;
			}

			onFileRemoved();
		}
	};

	return (
		<div styleName={classNames(["styles.file-upload"])}>
			{
				(selectedFileName.length === 0 || validationError || validationErrorInternal) && (
					<div styleName={classNames(["styles.file-upload--button-container"])}>
						<div styleName={classNames("styles.button-and-label")}>
							<Button
								name="buttonSelectFile"
								size={size}
								formName="SomeRandomNameToPreventParentFormSubmitOnClick"
								clickHandler={openFileDialog}
							>
								{buttonText}
							</Button>
							{
								buttonLabel
							&& <div styleName={classNames("styles.button-label", "typography.body-long-brand-02")}>
								{buttonLabel}
							</div>
							}
						</div>
						<div
							styleName={classNames(["styles.file-upload--validation-message", "typography.body-short-brand-02"])}
						>
							{validationError} {validationErrorInternal}
						</div>
					</div>
				)
			}
			{
				selectedFileName.length > 0 && !validationError && !validationErrorInternal && (
					<div styleName={classNames(["styles.file-upload--file-container"])}>
						{
							renderRoundPreviewImage && (
								<div styleName={classNames(["styles.file-upload--file-preview"])}>
									<div
										styleName={classNames(["styles.file-upload--image-close"])}
										onClick={onFileRemovedEvent}
									>
										<FileUploadRemovalIconStyled
											src={XSVG}
											width={14}
											height={14}
										/>
									</div>
									<div styleName={classNames(["styles.file-upload--file-preview-wrapper"])}>
										<img
											src={previewImageSource}
											styleName={classNames(["styles.file-upload--file-preview-image"])}
											style={previewImageStyle}
										/>
									</div>
									<div
										styleName={classNames(["styles.file-upload--file-name", "typography.body-short-brand-00"])}
									>
										{selectedFileName}
									</div>
								</div>
							)
						}

						{
							!renderRoundPreviewImage && (
								<div styleName={classNames(["styles.file-upload--file-box"])}>
									<div
										styleName={classNames(["styles.file-upload--file-close"])}
										onClick={onFileRemovedEvent}
									>
										<Icon
											src={XSVG}
											contextClass="file-upload-remove"
										/>
									</div>
									<div styleName={classNames(["styles.file-upload--file-icon"])}>
										<Icon
											src={AttachmentImageSVG}
											width={30}
											height={30}
											contextClass="file-upload-image"
										/>
									</div>
									<div
										styleName={classNames(["styles.file-upload--file-name", "typography.body-short-brand-00"])}
									>
										{selectedFileName}
									</div>
								</div>
							)
						}
					</div>
				)
			}
			<input
				ref={fileInputRef}
				styleName="styles.hidden"
				type="file"
				accept={limitToFileTypes}
				onChange={onFileAddedEvent}
			/>
		</div>
	);
};

FileUpload.propTypes = {
	buttonText: PropTypes.string,
	size: buttonPropTypes.size,
	buttonLabel: PropTypes.string,
	validationError: PropTypes.string,
	limitToFileTypes: PropTypes.string,
	renderRoundPreviewImage: PropTypes.bool,
	onFileAdded: PropTypes.func,
	onFileRemoved: PropTypes.func,
};

FileUpload.defaultProps = {
	size: "large",
	onFileAdded: () => { },
	onFileRemoved: () => { },
};

export default FileUpload;
