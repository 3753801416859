import { ChevronRightIcon } from "@sermo/ui-components";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Divider } from "@components/Helpers/Helpers";
import {
	MobileAndTabletPortrait, MobileAndTabletPortraitQuery,
	TabletLandscapeAndDesktop,
	TabletPortraitQuery
} from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import MobileRailCard from "@components/RailCards/components/MobileRailCard";
import { truncate } from "@components/Resource/utils";
import { useTrackEvent } from "@frontend/tracking";
import { useFetch } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./ResourceCenter.styles";

const truncatedTitleLengths = {
	desktop: 75,
	tablet: 115,
	mobile: 80,
};

const getResourceStatusText = (state, newItemsCount) => {
	switch (state) {
		case "NewResourceCenter":
			return "All new!";
		case "NewItemsAdded":
			if (newItemsCount === 1) {
				return "1 new resource added";
			}
			return `${newItemsCount} new resources added`;
		case "Popular":
			return "Popular among your peers";
		default:
			return null;
	}
};

const ResourceCenter = () => {
	const trackEvent = useTrackEvent();
	const navigate = useNavigate();
	const isTablet = useMediaQuery({ query: TabletPortraitQuery });
	const isMobile = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const device = useMemo(() => isTablet
		? "tablet"
		: isMobile
			? "mobile"
			: "desktop", [isTablet, isMobile]);
	const [data, loading] = useFetch("api/resourcecenters/getrightrail");

	const trackHandler = (resourceCenterId, action, labelAddOn = "") => {
		const attributes = {
			category: "right-rail",
			action: action,
			label: "resourcecenter",
		}

		if (labelAddOn) {
			attributes.label += `-${labelAddOn}`;
		}

		if ( resourceCenterId ) {
			attributes.value = resourceCenterId;
			attributes.resourceCenterId = resourceCenterId;
		}

		trackEvent(attributes);
	};

	useEffect(() => {
		if (!loading && data?.items?.length > 0) {
			trackHandler(null, "view");
		}
	}, [data, loading]);

	const resourcesTitle = useMemo(() => {
		if (1 === data?.items?.length) {
			return getTranslation("frontend.railCards.resourceCenter.oneNum", true);
		} else {
			return getTranslation("frontend.railCards.resourceCenter.multiple", true, data?.items?.length);
		}
	}, [data]);

	const resourcesContent = useMemo(() => data?.items?.map((item, i) => {
		const {
			title,
			sponsorLabel,
			state,
			following,
			newItemsCount,
			resourceCenterId,
		} = item;
		const truncatedTitle = truncate(title, truncatedTitleLengths[device]);
		const statusText = getResourceStatusText(state, newItemsCount);

		return (
			<Styles.Item
				key={i}
				onClick={
					() => {
						trackHandler(resourceCenterId, "click")
						navigate(`/resource/${resourceCenterId}`);
					}
				}
			>
				<Styles.Left>
					{
						!!sponsorLabel && (
							<Styles.SponsorLabel
								dangerouslySetInnerHTML={{ __html: sponsorLabel }}
								$kind={"bodyShort00"}
							/>
						)
					}
					<Styles.Title
						$kind={
							isMobile
								? "bodyShortMedium01"
								: "bodyShortMedium02"
						}
					>
						{truncatedTitle}
					</Styles.Title>
					<div>
						{
							following
							&& (
								<Styles.Status
									as={"span"}
									$kind={
										isMobile
											? "bodyShort00"
											: "bodyShort01"
									}
								>
									Following
								</Styles.Status>
							)
						}
						{
							following
								&& state
								&& !!statusText
								&& <Divider />
						}
						{
							!!statusText
							&& (
								<Styles.Status
									as={"span"}
									$kind={
										isMobile
											? "bodyShort00"
											: "bodyShort01"
									}
								>
									{statusText}
								</Styles.Status>
							)
						}
					</div>
				</Styles.Left>
				<TabletLandscapeAndDesktop>
					<Styles.Right>
						<Styles.IconStyled
							width={12}
							height={12}
							src={ChevronRightIcon}
						/>
					</Styles.Right>
				</TabletLandscapeAndDesktop>
			</Styles.Item>
		);
	}), [
		data,
		device,
	]);

	if (
		!data
		|| loading
		|| !data.items
		|| data.items.length === 0
	) {
		return null;
	}

	return (
		<div data-component={"ResourceCenters"}>
			<TabletLandscapeAndDesktop>
				<ModuleWrapper
					border={true}
					solid={true}
					customPadding="16px 16px 8px"
					contextClass="right-rail"
					label={getTranslation("frontend.railCards.resourceCenter.title", true)}
				>
					{resourcesContent}
				</ModuleWrapper>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<MobileRailCard
					bottomBorder={false}
					content={resourcesContent}
					title={resourcesTitle}
					trackingLabel={"resourcecenter"}
				/>
			</MobileAndTabletPortrait>
		</div>
	)
}

export default ResourceCenter;