import Card from "@components/Card/Card";
import { useMemoizedContext } from "@hooks/Hooks";
const ExpandedPost = () => {
    const { expandedPost, } = useMemoizedContext("ui", [
        "expandedPost",
    ]);
    if (expandedPost) {
        return (<Card state={{
                ...expandedPost.cardData,
                syncPostDataFromExpanded: expandedPost.syncPostDataFromExpanded,
            }} viewState={{
                expanded: true,
                commentsClicked: expandedPost.commentsClicked ?? false,
            }}/>);
    }
    return null;
};
export default ExpandedPost;
