import { ColorGrey10, ColorGrey20 } from "@sermo/design-tokens";

const applyBorderDefault = (color) => (sides) => {
	const borderStyles = `1px solid ${color}`;
	return `
		border-top: ${sides.includes("top")
		? borderStyles
		: "none"};
		border-right: ${sides.includes("right")
		? borderStyles
		: "none"};
		border-bottom: ${sides.includes("bottom")
		? borderStyles
		: "none"};
		border-left: ${sides.includes("left")
		? borderStyles
		: "none"};
	`;
}

export const applyBorder = applyBorderDefault(ColorGrey10);
export const applyBorderDarker = applyBorderDefault(ColorGrey20);
export const applyBorderPlaceholder = applyBorderDefault("transparent");
