import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import getTranslation from "@translation/translation";
import styles from "./ModalWithdrawalComponents.scss";

const AddressCard = ({
	addressType,
	addressLine1,
	addressLine2,
	region,
	city,
	postalCode,
	country,
}) => (
	<div styleName={"styles.address-card"}>
		<div styleName="styles.type">
			{
				addressType.toLowerCase() === "residential"
					? getTranslation("frontend.modals.withdrawal.residentialAddress", true)
					: getTranslation("frontend.modals.withdrawal.workAddress", true)
			}
		</div>
		<p styleName="actual">
			<span>{addressLine1}</span>

			{addressLine2 && <span>{addressLine2}</span>}

			{
				city && (
					<span>
						{city}
						{
							region
								? ", " + region
								: ""
						}
					</span>
				)
			}

			{postalCode && <span>{postalCode}</span>}
			{country && <span>{country}</span>}
		</p>
	</div>
);

AddressCard.propTypes = {
	addressType: PropTypes.string,
	addressLine1: PropTypes.string,
	addressLine2: PropTypes.string,
	region: PropTypes.string,
	city: PropTypes.string,
	postalCode: PropTypes.string,
	country: PropTypes.string,
};

export default AddressCard;
