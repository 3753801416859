export const settingsSection = {
	FullName: "fullname",
	Username: "username",
	Specialties: "specialties",
	Biography: "biography",
	Password: "password",
	Email: "email",
	Telephone: "telephone",
	TelephoneMobile: "mobile",
	WorkAddress: "workaddress",
	ResidentialAddress: "homeaddress",
	PlaceOfEmployment: "workplace",
	DateOfBirth: "dob",
	AccountDeactivation: "deactivate",
};
