import { Icon, ActivitySVG, MessagesSVG, SurveySVG, DrugRatingSVG, HomeSVG } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ScreenOut from "@assets/images/screenout.svg";
import TimeOut from "@assets/images/timeout.svg";
import ActivityMenu from "@components/Activity/components/ActivityMenu/ActivityMenu";
import { TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { EUIKey, useCloseBanner, useCloseModal, useOpenBanner, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useFetch } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import Button, { ButtonWithMenu } from "../Button/Button";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import * as Styles from "./NavMenu.styles.js";

const iconSize = 24;

const Popuper = ({ notification }) => {
	const apiEndpoint = useApiEndpoint();
	const closeBanner = useCloseBanner();
	const closeModal = useCloseModal();
	const openBanner = useOpenBanner();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const {
		cta,
		ctaCopy,
		notificationId,
		surveyOutcomeMessageId,
		text,
		type,
	} = notification;

	let typeCategory = "complete";
	let ctaModalIcon = null;
	if (type.includes("YouScreenedOut")) {
		typeCategory = "screenout";
		ctaModalIcon = ScreenOut;
	}
	else if (type.includes("QuotaIsFull")) {
		typeCategory = "quotafull";
		ctaModalIcon = TimeOut;
	}

	const ctaModalLabel = "notification-cta-modal";

	const handleCtaModalClose = () => {
		trackEvent({
			category: `${typeCategory}-modal`,
			label: "close",
			action: "click",
			area: surveyOutcomeMessageId?.toString(),
		});

		closeModal(ctaModalLabel);
	};

	const handleCtaClick = () => {
		trackEvent({
			category: `${typeCategory}-modal`,
			label: cta.split(/\s|\W/gm).join("").toLowerCase(),
			action: "click",
			area: surveyOutcomeMessageId?.toString(),
		});

		openModal({
			[EUIKey.MODAL_CLOSE_METHOD]: handleCtaModalClose,
			[EUIKey.MODAL_COMPONENT]: (
				<Styles.CtaWrap>
					{
						ctaModalIcon
						&& (
							<Icon
								src={ctaModalIcon}
								width={64}
								height={64}
							/>
						)
					}
					<Styles.CtaCopyText dangerouslySetInnerHTML={{ __html: ctaCopy }}/>
				</Styles.CtaWrap>
			),
			[EUIKey.MODAL_LABEL]: ctaModalLabel,
			[EUIKey.MODAL_SUBTYPE]: "without-title",
		});
	};

	const notificationBannerLabel = "notification-banner";

	const handleBannerClose = () => {
		trackEvent({
			category: `${typeCategory}-banner`,
			label: "close",
			action: "click",
			area: surveyOutcomeMessageId?.toString(),
		});

		apiEndpoint("activity/marknotificationread", { notificationId });
		closeBanner(notificationBannerLabel);
	};

	useEffect(() => {
		openBanner({
			[EUIKey.BANNER_CLOSE_METHOD]: handleBannerClose,
			[EUIKey.BANNER_COMPONENT]: (
				<Styles.ModalContentWrap>
					<Styles.NotificationText dangerouslySetInnerHTML={{ __html: text }} />
					{cta && <Styles.CtaText onClick={handleCtaClick}>{cta}</Styles.CtaText>}
				</Styles.ModalContentWrap>
			),
			[EUIKey.BANNER_LABEL]: notificationBannerLabel,
			[EUIKey.BANNER_SUBTYPE]: "information",
		});
	}, []);

	return null;
};

Popuper.propTypes = {
	notification: PropTypes.object,
};

export const ActivityIcon = (
	{
		alternate = false,
	}
) => {
	const apiEndpoint = useApiEndpoint();
	const [notifications, setNotifications] = useState([]);
	const [numUnreadNotifications, setNumUnreadNotifications] = useState(0);
	const [data, loading, error] = useFetch("api/activity/getactivityfeed", {
		page: 0,
		filterBy: "MustBeUnread",
	});
	const [unreadData, unreadLoading, unreadError] = useFetch("api/activity/checkfornewnotifications");
	const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
	const {
		active,
		shouldRefreshMemberNotifications,
		updateMember,
	} = useMemoizedContext("member", [
		"active",
		"shouldRefreshMemberNotifications",
		"updateMember",
	]);
	const interval = useRef();
	let location = useLocation();
	const [path, setPath] = useState(location.pathname);

	const checkNotifications = () => {
		apiEndpoint("activity/getactivityfeed", {
			page: 0,
			filterBy: "MustBeUnread",
		}).then(
			(dataResponse) => {
				if (dataResponse.ok) {
					dataResponse.json().then((body) => {
						if (body.notifications) {
							setNotifications(body.notifications);
						}
					});
				}
			}
		);
		apiEndpoint("activity/checkfornewnotifications").then(
			(dataResponse) => {
				if (dataResponse.ok) {
					dataResponse.json().then((body) => {
						setNumUnreadNotifications(body.numberOfUnreadNotifications ?? 0);
					});
				}
			}
		);
	};

	useEffect(() => {
		interval.current = window.setInterval(() => {
			if ( active ) {
				checkNotifications();
			}
		}, 60000);

		return () => {
			window.clearInterval(interval.current);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			if (data && data.notifications && !error) {
				setNotifications(data.notifications);
			}
		}
	}, [loading]);

	useEffect(() => {
		if (!unreadLoading) {
			if (unreadData && unreadData.numberOfUnreadNotifications && !unreadError) {
				setNumUnreadNotifications(unreadData.numberOfUnreadNotifications);
			}
		}
	}, [unreadLoading, unreadData, unreadError]);

	// when the user clicks a notification the route will change
	// wait 2 sec for the notifications to load and then update
	// Checking previous path, since location is triggered twice at first render
	useEffect(() => {
		if(path !== location.pathname) {
			setTimeout(() => {
				checkNotifications();
			}, 2000);
			setPath(location);
		}
	}, [location]);

	// checking variable in order to trigger a refresh
	useEffect(() => {
		if (shouldRefreshMemberNotifications) {
			window.sermo.user.shouldRefreshMemberNotifications = false;
			updateMember({
				shouldRefreshMemberNotifications: false,
			});
			checkNotifications();
		}
	}, [shouldRefreshMemberNotifications]);

	const showNotification = notifications.find((notification) => notification.popup && notification.new);

	const badgeText = isNaN(+numUnreadNotifications)
		? numUnreadNotifications
		: +numUnreadNotifications;

	if (alternate) {
		return (
			<>
				{showNotification && <Popuper notification={showNotification}/>}
				<Icon
					height={24}
					src={ActivitySVG}
					width={24}
				/>
				{
					Boolean(badgeText)
						&& (
							<Styles.BadgeAlternate
								$left={"18px"}
								$top={"10px"}
							>
								{badgeText}
							</Styles.BadgeAlternate>
						)
				}
			</>
		);
	}

	return (
		<Styles.IconWrap>
			{showNotification && <Popuper notification={showNotification} />}
			<Icon
				src={ActivitySVG}
				width={iconSize}
				height={iconSize}
			/>
			{
				Boolean(badgeText) && (
					<Styles.Badge
						digits={badgeText.toString().length}
						iconSize={iconSize}
						isMobile={!isDesktop}
					>
						{badgeText}
					</Styles.Badge>
				)
			}
		</Styles.IconWrap>
	);
};

ActivityIcon.propTypes = {
	alternate: PropTypes.bool,
};

const UnreadMessagesIconTrialMembers = () => (
	<Styles.IconWrap>
		<Icon
			src={MessagesSVG}
			width={iconSize}
			height={iconSize}
		/>
		<Styles.Dot />
	</Styles.IconWrap>
);

export const UnreadMessagesIcon = (
	{
		alternate = false,
	}
) => {
	const apiEndpoint = useApiEndpoint();
	const member = useMemoizedContext("member", ["numberOfUnreadMessages"]);
	const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });

	let [dataNewMessages, loadingNewMessages] = useFetch("api/messages/checkfornewmessages");

	const checkForUnreadMessages = () => {
		if (member.hasAccess("canAccessFrontendPrivateMessages", false)) {
			apiEndpoint("messages/checkfornewmessages").then((dataResponse) => {
				if (dataResponse.ok) {
					dataResponse.json().then((body) => {
						member.updateMember({ numberOfUnreadMessages: body.numberOfUnreadMessages });
					});
				}
			});
		}
	};

	const interval = useRef();
	useEffect(() => {
		interval.current = window.setInterval(() => {
			checkForUnreadMessages();
		}, 59000);

		return () => {
			window.clearInterval(interval.current);
		};
	}, []);

	useEffect(() => {
		if (!loadingNewMessages) {
			if (dataNewMessages) {
				member.updateMember({ numberOfUnreadMessages: dataNewMessages.numberOfUnreadMessages });
			}
		}
	}, [loadingNewMessages]);

	const badgeText = (member?.numberOfUnreadMessages > 99)
		? "99+"
		: member?.numberOfUnreadMessages;

	if (alternate) {
		return (
			<>
				<Icon
					height={24}
					src={MessagesSVG}
					width={24}
				/>
				{
					Boolean(badgeText)
					&& (
						<Styles.BadgeAlternate>{badgeText}</Styles.BadgeAlternate>
					)
				}
			</>
		);
	}

	return (
		<Styles.IconWrap>
			<Icon
				src={MessagesSVG}
				width={iconSize}
				height={iconSize}
			/>
			{
				Boolean(badgeText) && (
					<Styles.Badge
						digits={badgeText.toString().length}
						iconSize={iconSize}
						isMobile={!isDesktop}
					>{badgeText}</Styles.Badge>
				)
			}
		</Styles.IconWrap>
	);
};

UnreadMessagesIcon.propTypes = {
	alternate: PropTypes.bool,
};

export const SurveysIcon = (
	{
		alternate = false,
	}
) => {
	const apiEndpoint = useApiEndpoint();
	const [numNewSurveys, setNumNewSurveys] = useState(0);
	const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
	const {
		active,
		shouldRefreshSurveysBadge,
		updateMember,
	} = useMemoizedContext("member", [
		"active",
		"shouldRefreshSurveysBadge",
	]);

	let [data, loading] = useFetch("api/surveys/checkfornewsurveys");

	const checkForNewSurveys = () => {
		apiEndpoint("surveys/checkfornewsurveys").then((dataResponse) => {
			if (dataResponse.ok) {
				dataResponse.json().then((body) => {
					setNumNewSurveys(body.numberOfUnseenSurveys ?? 0);
				});
			}
		});
	};

	const interval = useRef();
	useEffect(() => {
		interval.current = window.setInterval(() => {
			if ( active ){
				checkForNewSurveys();
			}
		}, 59000);

		return () => {
			window.clearInterval(interval.current);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			if (data) {
				setNumNewSurveys(data.numberOfUnseenSurveys ?? 0);
			}
		}
	}, [loading]);

	useEffect(() => {
		if (shouldRefreshSurveysBadge) {
			updateMember({ shouldRefreshSurveysBadge: false });
			checkForNewSurveys();
		}
	}, [shouldRefreshSurveysBadge]);

	const badgeText = (numNewSurveys > 99)
		? "99+"
		: numNewSurveys;

	if (alternate) {
		return (
			<>
				<Icon
					height={24}
					src={SurveySVG}
					width={24}
				/>
				{
					Boolean(badgeText)
					&& (
						<Styles.BadgeAlternate>{badgeText}</Styles.BadgeAlternate>
					)
				}
			</>
		);
	}

	return (
		<Styles.IconWrap>
			<Icon
				src={SurveySVG}
				width={iconSize}
				height={iconSize}
			/>
			{
				Boolean(badgeText)
				&& (
					<Styles.Badge
						digits={badgeText.toString().length}
						iconSize={iconSize}
						isMobile={!isDesktop}
					>{badgeText}</Styles.Badge>
				)
			}
		</Styles.IconWrap>
	);
}

SurveysIcon.propTypes = {
	alternate: PropTypes.bool,
};

const NavMenu = () => {
	const user = useMemoizedContext("member");
	const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
	const navItems = [];

	if (user.hasAccess("canAccessFrontendPulseFeed", false)) {
		navItems.push({
			name: getTranslation("frontend.navMenu.home"),
			icon: <Styles.IconWrap>
				<Icon
					src={HomeSVG}
					width={iconSize}
					height={iconSize}
				/>
			</Styles.IconWrap>,
			to: `/feed/all-sermo`,
			exact: false,
			rightsRequired: ["canAccessFrontendPulseFeed"],
			tracking: {
				category: "top-navigation",
				action: "click",
				label: "pulse",
			},
			tourId: "nav-menu-pulse",
		});
	}

	if (user.hasAccess("canAccessFrontendDrugRatings", false)) {
		navItems.push({
			name: getTranslation("frontend.navMenu.rate"),
			icon: <Styles.IconWrap>
				<Icon
					src={DrugRatingSVG}
					width={iconSize}
					height={iconSize}
				/>
			</Styles.IconWrap>,
			to: "/rate",
			rightsRequired: ["canAccessFrontendDrugRatings"],
			tracking: {
				category: "top-navigation",
				action: "click",
				label: "rate",
			},
			tourId: "nav-menu-rate",
		});
	}

	if (user.hasAccess("canAccessFrontendSurveys")) {
		navItems.push({
			name: getTranslation("frontend.navMenu.surveys"),
			icon: <SurveysIcon />,
			to: "/surveys",
			rightsRequired: ["canAccessFrontendSurveys"],
			tracking: {
				category: "top-navigation",
				action: "click",
				label: "surveys",
			},
			tourId: "nav-menu-surveys",
		});
	}

	if (user.hasAccess("canAccessFrontendActivityNotifications", false)) {
		const item = {
			name: getTranslation("frontend.navMenu.activity"),
			icon: <ActivityIcon />,
			to: "/activity",
			rightsRequired: ["canAccessFrontendActivityNotifications"],
			tracking: {
				category: "top-navigation",
				action: "click",
				label: "activity",
			},
			tourId: "nav-menu-activity",
		};

		if (isDesktop) {
			item.menu = {
				component: ActivityMenu,
				name: "activity",
			};
		}

		navItems.push(item);
	}

	if (user.hasAccess("canAccessFrontendPrivateMessages", false)) {
		if (user.trialMember || user.affiliateMember) {
			navItems.push({
				name: getTranslation("frontend.navMenu.messages"),
				icon: <UnreadMessagesIconTrialMembers />,
				to: "/messages",
				rightsRequired: ["canAccessFrontendPrivateMessages"],
				tracking: {
					category: "top-navigation",
					action: "click",
					label: "messages",
				},
				tourId: "nav-menu-messages",
			});
		} else {
			navItems.push({
				name: getTranslation("frontend.navMenu.messages"),
				icon: <UnreadMessagesIcon />,
				to: "/messages",
				rightsRequired: ["canAccessFrontendPrivateMessages"],
				tracking: {
					category: "top-navigation",
					action: "click",
					label: "messages",
				},
				tourId: "nav-menu-messages",
			});
		}
	}

	navItems.push({
		name: getTranslation("frontend.navMenu.me"),
		icon: <ProfilePic
			user={user}
			size="small"
			contextClass="top-nav-menu"
		/>,
		tracking: {
			category: "top-navigation",
			action: "click",
			label: "me",
		},
		menu: {
			component: ProfileMenu,
			name: "profile",
		},
		withArrow: true,
		tourId: "nav-menu-me",
	});

	if (!user.hasConfiguredProfile && !user.trialMember && !user.affiliateMember) {
		return <Styles.NavMenuHidden />;
	}

	return (
		<Styles.NavMenu>
			{
				navItems.map((navItem, i) => {
					const { name } = navItem;

					return (
						<Styles.NavItem
							data-component={navItem.tourId}
							key={i}
						>
							{
								navItem["menu"] && (
									<ButtonWithMenu
										activeUnderline={true}
										{...navItem}
										labelPosition="bottom"
										theme="light"
										style="tab"
										size="small"
										contextClass="top-nav-menu"
									>
										{ name }
									</ButtonWithMenu>
								)
							}
							{
								!navItem["menu"] && (
									<Button
										activeUnderline={true}
										{...navItem}
										labelPosition="bottom"
										theme="light"
										style="tab"
										size="small"
										contextClass="top-nav-menu"
									>
										{ name }
									</Button>
								)
							}
						</Styles.NavItem>
					);
				})
			}
		</Styles.NavMenu>
	);
};

export default NavMenu;
