import { useCallback } from "react";
import { useMatch } from "react-router-dom";
import { CommentActionTypes } from "@contexts/Comment/CommentReducer";
import { PostDataActionTypes } from "@contexts/PostData";
import { VotedType } from "@frontend/types/Post/comment";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
const useCommentAPI = () => {
    const apiEndpoint = useApiEndpoint();
    const isAdPreview = !!useMatch("/ad-preview/*");
    const isPreviewRoute = !!useMatch("/preview/*");
    const isPreview = isAdPreview || isPreviewRoute;
    const { dispatch: dispatchPostData, id: postId, type: postType, } = useMemoizedContext("postData", [
        "id",
        "type",
    ]);
    const { dispatch: dispatchComment, following, id: commentId, parentCommentId, voted, } = useMemoizedContext("comment", [
        "following",
        "id",
        "parentCommentId",
        "voted",
    ]);
    const reuseDispatchComment = useCallback((type, passedData = {}, responseData = {}) => {
        dispatchComment({
            type,
            payload: {
                comment: {
                    ...passedData,
                    ...responseData,
                },
            },
        });
    }, [dispatchComment]);
    const onAddComment = (passedData, responseData) => {
        if (!parentCommentId) {
            reuseDispatchComment(CommentActionTypes.ADD_COMMENT, passedData, responseData);
            if (PostTypeTypes.QUESTION === postType) {
                dispatchPostData({
                    type: PostDataActionTypes.SET_HAS_ANSWERED_TRUE,
                });
            }
        }
        else {
            reuseDispatchComment(CommentActionTypes.ADD_REPLY, passedData, responseData);
        }
    };
    const addComment = ({ passedData, requestBody, }) => {
        if (isPreview) {
            const id = Math.round(Math.random() * 1000000);
            onAddComment(passedData, { id });
            return;
        }
        apiEndpoint("posts/addComment", requestBody, false).then((response) => {
            if (response.ok) {
                response.json().then((responseData) => {
                    onAddComment(passedData, responseData);
                });
            }
        });
    };
    const onDeleteComment = ({ id, }) => {
        if (!parentCommentId) {
            dispatchPostData({
                type: PostDataActionTypes.DELETE_COMMENT,
                payload: {
                    comment: {
                        id,
                    },
                },
            });
        }
        else {
            reuseDispatchComment(CommentActionTypes.DELETE_REPLY, { id });
        }
    };
    const deleteComment = ({ id, }) => {
        const endpoint = "posts/deleteComment";
        const requestBody = {
            id,
        };
        if (isPreview) {
            onDeleteComment(requestBody);
            return;
        }
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            if (response.ok) {
                onDeleteComment(requestBody);
            }
        });
    };
    const followComment = (shouldFollow) => {
        if (isPreview) {
            reuseDispatchComment(CommentActionTypes.TOGGLE_FOLLOW);
            return;
        }
        const endpoint = (shouldFollow ?? following)
            ? "posts/unfollowComment"
            : "posts/followComment";
        const requestBody = {
            id: commentId,
        };
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            if (response.ok) {
                reuseDispatchComment(CommentActionTypes.TOGGLE_FOLLOW);
            }
        });
    };
    const markBest = () => {
        if (isPreview) {
            reuseDispatchComment(CommentActionTypes.MARK_BEST);
            return;
        }
        const endpoint = "posts/selectBestAnswer";
        const requestBody = {
            id: commentId,
            postId,
        };
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            if (response.ok) {
                reuseDispatchComment(CommentActionTypes.MARK_BEST);
            }
        });
    };
    const updateComment = ({ callback, passedData, requestBody, }) => {
        if (isPreview) {
            reuseDispatchComment(CommentActionTypes.UPDATE_COMMENT, passedData, {});
            if (callback) {
                callback();
            }
            return;
        }
        apiEndpoint("posts/updateComment", requestBody, false).then((response) => {
            if (response.ok) {
                reuseDispatchComment(CommentActionTypes.UPDATE_COMMENT, passedData, {});
                if (callback) {
                    callback();
                }
            }
        });
    };
    const voteDown = () => {
        if (isPreview) {
            reuseDispatchComment(CommentActionTypes.VOTE_DOWN);
            return;
        }
        const endpoint = VotedType.DOWN === voted
            ? "posts/clearCommentVote"
            : "posts/voteComment";
        const requestBody = {
            id: commentId,
            type: VotedType.DOWN,
        };
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            if (response.ok) {
                reuseDispatchComment(CommentActionTypes.VOTE_DOWN);
            }
        });
    };
    const voteUp = () => {
        if (isPreview) {
            reuseDispatchComment(CommentActionTypes.VOTE_UP);
            return;
        }
        const endpoint = VotedType.UP === voted
            ? "posts/clearCommentVote"
            : "posts/voteComment";
        const requestBody = {
            id: commentId,
            type: VotedType.UP,
        };
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            if (response.ok) {
                reuseDispatchComment(CommentActionTypes.VOTE_UP);
            }
        });
    };
    return {
        addComment,
        deleteComment,
        followComment,
        markBest,
        updateComment,
        voteDown,
        voteUp,
    };
};
export default useCommentAPI;
