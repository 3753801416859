import { Icon, ChevronDownSVG, CheckAltSVG, Select, Radio, RadioGroup, ToggleButton, ToggleButtonGroup } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { ButtonWithMenu } from "@components/Button/Button";
import { ToolTip } from "@components/Helpers/Helpers";
import Menu from "@components/Menu/Menu";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import typography from "../../scss/typography.scss";
import { FormFieldLabel } from "../Helpers/Helpers";
import { CheckboxIconStyled, DropdownIconStyled, WrapToggleGroup } from "./FormFields.styles";
import styles from "./FormFields001.scss";

const TextField = (props) => {
	const {
		forwardRef,
		name,
		placeholder,
		type,
		inputmode,
		pattern,
		autoComplete,
		required,
		disabled,
		autoFocus,
		value,
		className,
		validationError,
		label,
		handleFocus,
		handleBlur,
		handleChange,
		handleKeyDown,
		borderless,
		maxLength,
		contextClass,
	} = props;

	const [hasFocus, setHasFocus] = useState(false);

	function onFocus(e) {
		setHasFocus(true);

		if (handleFocus) {
			handleFocus(e);
		}
	}

	function onBlur(e) {
		setHasFocus(false);

		if (handleBlur) {
			handleBlur(e);
		}
	}

	function onChange(e) {
		if (handleChange) {
			handleChange(e);
		}
	}

	const onKeyDown = (e) => {
		if (handleKeyDown) {
			handleKeyDown(e);
		}
	};

	return (
		<div
			styleName={
				classNames([
					"styles.form-group",
					{ [`${className}`]: className },
					{ [`styles.context-${contextClass}`]: contextClass },
					{ [`styles.has-content`]: value },
					{ "styles.has-focus": hasFocus },
					{ "styles.is-invalid": validationError },
					{ "styles.borderless": borderless },
				])
			}
		>
			{
				label && (
					<label
						htmlFor={name}
						styleName="styles.form-label"
					>
						{label}
					</label>
				)
			}
			<input
				ref={forwardRef}
				styleName="styles.form-input"
				id={name}
				name={name}
				type={type}
				pattern={pattern}
				inputMode={inputmode}
				value={value}
				autoComplete={autoComplete}
				onChange={onChange}
				onPaste={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				required={required}
				disabled={disabled}
				autoFocus={autoFocus}
				maxLength={maxLength}
			/>
			{validationError && <FieldValidationMessage text={validationError} />}
		</div>
	);
};

TextField.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.oneOf(["text", "search", "url", "tel", "email", "password", "number"]).isRequired,
	autoComplete: PropTypes.string,
	required: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	autoFocus: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	className: PropTypes.string,
	validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
	borderless: PropTypes.bool.isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleFocus: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	maxLength: PropTypes.number,
	contextClass: PropTypes.string,
};

TextField.defaultProps = {
	type: "text",
	required: false,
	disabled: false,
	autoFocus: false,
	value: "",
	validationError: false,
	label: false,
	borderless: false,
	handleBlur: () => {},
	handleFocus: () => {},
	handleKeyDown: () => {},
	handleChange: () => {},
};

export { TextField };

const ValidationMessage = ({ classNames: classes, text }) => (
	<div
		styleName={
			classNames([
				"styles.form-validation-message",
				{ [`styles.${classes}`]: classes },
				{ "styles.visible": text },
			])
		}
	>
		{text}
	</div>
);

export { ValidationMessage };

const GlobalValidationMessage = ({ text, className }) => {
	if (text && text !== "") {
		return (
			<div
				styleName={
					classNames([
						"styles.global-validation-message",
						{ [`styles.${className}`]: className },
						{ "styles.visible": text },
					])
				}
			>
				{text}
			</div>
		);
	}

	return <></>;
};

export { GlobalValidationMessage };

const TextArea = (props) => {
	const {
		forwardRef,
		handleChange,
		className,
		value,
		validationError,
		name,
		placeholder,
		required,
		disabled,
		label,
		handleBlur,
	} = props;

	const [hasFocus, setHasFocus] = useState(false);

	function onFocus() {
		setHasFocus(true);
	}

	function onBlur() {
		setHasFocus(false);

		if (handleBlur) {
			handleBlur();
		}
	}

	function onChange(e) {
		if (props.handleChange) {
			props.handleChange(e);
		}
	}

	return (
		<div
			styleName={
				classNames([
					"styles.form-group",
					{ [`styles.${className}`]: className },
					{ "styles.has-content": value },
					{ "styles.has-focus": hasFocus },
					{ "styles.is-invalid": validationError },
				])
			}
		>
			<textarea
				ref={forwardRef}
				styleName="styles.form-textarea"
				id={name}
				name={name}
				autoComplete={props.autoComplete}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				placeholder={placeholder}
				required={required}
				disabled={disabled}
				autoFocus={props.autoFocus}
				maxLength={props.maxLength}
				value={value}
			/>
			{
				label && (
					<label
						htmlFor={name}
						styleName="styles.form-label"
					>
						{label}
					</label>
				)
			}
			<FieldValidationMessage text={validationError} />
		</div>
	);
};

export { TextArea };

const FieldValidationMessage = ({ className, text }) => (
	<span styleName={classNames(["styles.form-field-validation", "typography.body-short-brand-00", { [`styles.${className}`]: className }])}>
		{text}
	</span>
);

export { FieldValidationMessage };

const CustomDropDownListMenu = ({ options, clickHandler, contextClass, dropDownButtonStyle }) => (
	<ModuleWrapper
		contextClass={
			contextClass
				? `custom-dropdown-${contextClass}`
				: "custom-dropdown"
		}
		solid={true}
		padded={
			dropDownButtonStyle === "inline"
				? false
				: true
		}
	>
		{/* TODO: in the future, replace this menu with the <Select /> component  */}
		<Menu
			dataComponent="DropDownMenu">
			{
				options.map((option, i) => {
					const { name, value, tracking = false } = option;
					return (
						<Button
							alignment="start"
							key={i}
							style={"flatNeutral"}
							size="small"
							clickHandler={
								() => {
									clickHandler(option.value);
								}
							}
							tracking={tracking}
						>
							{option.name}
						</Button>
					);
				})
			}
		</Menu>
	</ModuleWrapper>
);

const CustomDropDownList = (props) => {
	const {
		contextClass,
		handleChange,
		iconSize = 16,
		name,
		options,
		size = "large",
		style = "flat",
		value,
	} = props;

	// gives each custom dropdown list a unique id within in global context
	const uid = useRef(Math.random() * 1000);

	const onClick = (value) => {
		if (handleChange) {
			handleChange(value);
		}
	}

	const getLabel = () => {
		let item = options.filter((option) => option.value === value);

		if (item && item.length) {
			return item[0].name;
		}

		return "";
	};

	const styleNames = ["styles.custom-dropdown-list", { [`styles.context-${contextClass}`]: contextClass }];

	return (
		<div
			styleName={classNames(styleNames)}
			data-component={props.dataComponent}
		>
			<ButtonWithMenu
				style={style}
				size={size}
				menu={
					{
						component: CustomDropDownListMenu,
						componentProps: {
							clickHandler: onClick,
							options: options,
							contextClass,
							dropDownButtonStyle: style,
						},
						name: `custom-dropdown-list-menu-${name}-${uid.current}`,
					}
				}
			>
				{getLabel()}
				<DropdownIconStyled
					src={ChevronDownSVG}
					width={iconSize}
				/>
			</ButtonWithMenu>
		</div>
	)
};

export { CustomDropDownList };

const DropdownStyled = styled(Select)`
	& > div:not(:first-of-type) {
		// set max-height on dropdown to make sure it does not exceed the viewport
		max-height: 38vh;
	}

	& > div > div:first-of-type {
		margin-block: 6px;
	}
`;

const DropDownList = (props) => {
	const {
		handleChange,
		name,
		value,
		validationError,
		options,
		handleBlur,
	} = props;
	const [mockTarget] = useState(document.createElement("input"));
	// Maps the default option item type (<{key: string, value: string}>) for the option type for
	// Dropdown component (<{label: string, value: string}>)
	const [optionsFix, setOptionsFix] = useState([]);

	useEffect(() => {
		setOptionsFix(options.map(
			(option) => {
				const { label, key, value, name, id } = option
				return(
					{
						label: label || key || name,
						value: (value || id)?.toString(),
					}
				)
			}))
	}, [options])

	const onChange = (event, value) => {
		if (handleChange) {
			mockTarget.value = value;
			mockTarget.name = name;
			event = new Event("change");
			Object.defineProperty(event, "target", { writable: false, value: mockTarget });
			handleChange(event);
		}
	}

	return (
		<DropdownStyled
			{...props}
			errors={[validationError].filter(Boolean)}
			onBlur={handleBlur}
			onChange={onChange}
			options={optionsFix}
			value={value?.toString()}
		/>
	);
};

DropDownList.propTypes = {
	/**
	 * Name given to the form field
	 */
	name: PropTypes.string.isRequired,
	/**
	 * String to show as placeholder
	 */
	placeholder: PropTypes.string,
	/**
	 * Callback for onChange event handling event and value params
	 */
	handleChange: PropTypes.func.isRequired,
	/**
	 * String to show as field label
	 */
	label: PropTypes.string,
	/**
	 * Initial value
	 */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Message error to show
	 */
	validationError: PropTypes.string,
	/**
	 * List of options
	 */
	options: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Boolean for disabled state
	 */
	disabled: PropTypes.bool,
	/**
	 * Callback for onBlur event
	 */
	handleBlur: PropTypes.func,
	/**
	 * value for max-height css property
	 */
	maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { DropDownList };

const CheckBoxList = (props) => {
	const { className, options, handleChange, selectedOptions, size, contextClass } = props;

	// convert selected items to a map for easier state manipulation
	let co = new Map();

	selectedOptions.forEach((obj) => {
		co.set(obj.value, true);
	});

	const [checkedItems, setCheckedItems] = useState(co);

	const handleChangeInternal = (e) => {
		const item = e.target.name;
		const isChecked = e.target.checked;

		setCheckedItems(checkedItems.set(item, isChecked));

		if (handleChange) {
			handleChange(checkedItems);
		}
	};

	return (
		<div
			styleName={
				classNames([
					"styles.form-group",
					"styles.checkbox-list",
					{ [`styles.${className}`]: className },
					{ [`styles.context-${contextClass}`]: contextClass },
				])
			}
		>
			<React.Fragment>
				{
					options.map((item) => (
						<CheckBox
							name={item.value}
							className="checkbox-item"
							title={item.key}
							key={item.value}
							size={size}
							handleChange={handleChangeInternal}
							checked={checkedItems.get(item.value) || false}
						/>
					))
				}
			</React.Fragment>
		</div>
	);
};

export { CheckBoxList };

const CheckBox = (props) => {
	const {
		className,
		name,
		title,
		handleChange,
		option,
		checked,
		disabled,
		defaultChecked,
		subTitle,
		size,
		forwardRef,
	} = props;

	const actualSize = size === "small"
		? "small"
		: null;

	const changeHandler = (e) => {
		e.stopPropagation();
		handleChange && handleChange(e);
	};

	return (
		<div
			styleName={classNames(["styles.form-group", { [`styles.${className}`]: className }])}
			onClick={(e) => e.stopPropagation()}
		>
			<label
				htmlFor={name + option}
				styleName={classNames(["styles.form-label-checkbox", { "styles.small": actualSize }])}
			>
				{title && <span styleName="styles.form-label-checkbox-title">{title}</span>}
				<div styleName="styles.form-checkmark-wrapper">
					<input
						id={name + option}
						name={name}
						onChange={changeHandler}
						value={option}
						defaultChecked={defaultChecked}
						checked={checked}
						type="checkbox"
						disabled={disabled}
						ref={forwardRef}
					/>
					<div styleName="styles.form-checkbox-checkmark" />
					{
						checked && <CheckboxIconStyled
							src={CheckAltSVG}
						/>
					}
				</div>

				<span styleName={classNames(["styles.form-checkbox-subtitle", "typography.body-short-brand-00"])}>
					{subTitle}
				</span>
			</label>
		</div>
	);
};

export { CheckBox };

const TermsAndConditions = (props) => {
	const { name, text, handleChange, checked, disabled, size } = props;

	const actualSize = size === "small"
		? "small"
		: null;

	return (
		<div data-component={"TermsAndConditions"} styleName={classNames(["styles.form-group", "styles.terms-and-conditions"])}>
			<label
				htmlFor={name}
				styleName={classNames(["styles.form-label-checkbox", { "styles.small": actualSize }])}
			>
				<div styleName="styles.form-checkmark-wrapper">
					<input
						id={name}
						name={name}
						onChange={handleChange}
						checked={checked}
						type="checkbox"
						disabled={disabled}
					/>
					<div styleName="styles.form-checkbox-checkmark" />
					{
						checked && <CheckboxIconStyled
							src={CheckAltSVG}
						/>
					}
				</div>

				<div styleName={classNames(["styles.form-checkbox-text", "typography.body-long-brand-00"])}>{text}</div>
			</label>
		</div>
	);
};

export { TermsAndConditions };

const RadioButtonList = (props) => {
	const { className, name, options, handleChange, value, contextClass, label } = props;

	const [selectedItem, setSelectedItem] = useState(value);

	const handleChangeInternal = (e) => {
		const value = e.target.value?.toString();

		setSelectedItem(value);

		if (handleChange) {
			handleChange(value);
		}
	};
	return (
		<div
			styleName={
				classNames([
					"styles.form-group",
					"styles.radiobutton-list",
					{ [`styles.${className}`]: className },
					{ [`styles.context-${contextClass}`]: contextClass },
				])
			}
		>
			{label && <FormFieldLabel label={label} />}
			<RadioGroup
				onChange={handleChangeInternal}
				value={value}
			>
				{
					options.map((item, index) => (
						<Radio
							name={name}
							disabled={item.disabled}
							label={item.name}
							index={index}
							key={item.value}
							value={item.value?.toString()}
							className={"radiobutton-item"}
						/>
					))
				}
			</RadioGroup>
		</div>
	);
};

export { RadioButtonList };

const ToggleButtonList = (props) => {
	const { options, handleChange, value } = props;

	const [selectedItem, setSelectedItem] = useState(value);

	const handleChangeInternal = (event, value) => {
		setSelectedItem(value);
		handleChange && handleChange(value);
	};

	return (
		<WrapToggleGroup>
			<ToggleButtonGroup
				onChange={handleChangeInternal}
				value={selectedItem}
			>
				{
					options.map((item, index) => (
						<ToggleButton
							disabled={item.disabled}
							index={index}
							key={item.value}
							value={item.value}
							text={item.name}
						/>
					))
				}
			</ToggleButtonGroup>
		</WrapToggleGroup>
	);
};

export { ToggleButtonList };

const Option = ({ active, options, changeHandler }) => {
	const onClick = (value) => {
		changeHandler(options[value].value);
	};

	return (
		<div styleName="styles.options">
			{
				options.map((option, i) => {
					const isActive = active === i;
					return (
						<div
							styleName={
								classNames("styles.option", {
									"styles.active": isActive,
								})
							}
							key={i}
							onClick={() => onClick(i)}
							role="button"
						>
							<div styleName={classNames("styles.title", "typography.heading-brand-02")}>{option.title}</div>
							{
								isActive && (
									<div styleName="styles.check">
										<Icon
											src={CheckAltSVG}
											width={18}
											height={18}
										/>
									</div>
								)
							}
							<div styleName={classNames("styles.description", "typography.body-short-brand-00")}>
								{option.description}
							</div>
						</div>
					);
				})
			}
		</div>
	);
};

export { Option };

const Toggle = (props) => {
	const { state, label, changeHandler, contextClass } = props;

	const onClick = () => {
		changeHandler();
	};
	return (
		<div styleName={classNames(["styles.toggle-wrapper", { [`styles.context-${contextClass}`]: contextClass }])}>
			<label styleName="styles.toggle-label typography.body-short-brand-00">{label}</label>
			<div
				onClick={onClick}
				styleName={classNames("styles.toggle", { "styles.on": state })}
			/>
		</div>
	);
};

export { Toggle };

const FileUpload = ({ changeHandler, triggerRef, type }) => {
	const accept = {
		image: ".jpg, .jpeg, .png, .gif", // "image/*",
		imageAndVideo: "image/*, video/*",
		pdf: ".pdf",
	};

	return (
		<input
			ref={triggerRef}
			style={{ display: "none" }}
			type="file"
			onChange={changeHandler}
			accept={accept[type]}
		/>
	);
};

export { FileUpload };

const RangeSlider = ({
	changeHandler,
	values,
	value,
	step,
	minLabel,
	maxLabel,
	reversed,
	contextClass,
	forwardRef,
	showToolTip = true,
	showMarkers = true,
}) => {
	const el = useRef();
	const [toolTipShown, setToolTipShown] = useState(false);
	const [x, setX] = useState(0);
	const [content, setContent] = useState("");
	const [markers, setMarkers] = useState(false);

	useEffect(() => {
		const m = [];
		for (let i = 1; i < values.length - 1; i++) {
			if ((!reversed && i !== value) || (reversed && value !== values.length - i - 1)) {
				m.push(
					<div
						key={i}
						styleName="styles.marker"
						// calc((((100% - (7 * 16px)) / 5) + 16px) * 1)
						// take the full width of the slider: 100%
						// subtract the width of all of the handles: length * 16px
						// the difference is how much space would be between them all
						// given a width of 100px and 5 waves thats 20px
						// 100 - (5 * 16 = 80) = 20px
						// 20 is the space to be devided between them all
						// assuming 7 waves there are 6 "in betweens" so devide by that
						// 20px / 6 = 3.333
						// so the left edge of each circle should be 16px (its width) + the calculated value: 3.33
						// 19.333
						// multiple this value by i for each
						// add 8px (half the handle width) to center them
						style={
							{
								left: `calc(((((100% - (${values.length} * 16px)) / ${
									values.length - 1
								}) + 16px) * ${i}) + 8px)`,
							}
						}
					/>
				);
			}
		}
		setMarkers(m);
	}, [value]);

	// handle rounding errors
	const limit = (val) => {
		if (val > values.length - 1) {
			return values.length - 1;
		}
		if (val < 0) {
			return 0;
		}
		return val;
	};
	const handleMouseMove = (e) => {
		const { pageX } = e;

		const rect = el.current.getBoundingClientRect();
		const { left, width } = rect;
		const localX = pageX - left;

		const sectionWidth = width / values.length;

		let valueHovered = Math.floor(localX / sectionWidth);

		if (reversed) {
			valueHovered = values.length - 1 - valueHovered;
		}

		setX(localX > width
			? width
			: localX < 0
				? 0
				: localX);
		setContent(values[limit(valueHovered)]);
	};

	const mouseEnter = () => {
		setToolTipShown(true);
	};

	const mouseLeave = () => {
		setToolTipShown(false);
	};

	return (
		<div styleName="styles.range-wrapper">
			<div
				styleName={
					classNames(
						"styles.range",
						{ "styles.reversed": reversed },
						{ [`styles.context-${contextClass}`]: contextClass }
					)
				}
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
			>
				{
					minLabel && (
						<div styleName={classNames("styles.range-label", "typography.body-short-brand-00")}>{minLabel}</div>
					)
				}
				<div
					styleName="styles.range-input-wrapper"
					ref={el}
					onMouseMove={handleMouseMove}
				>
					<input
						type="range"
						value={value}
						min={0}
						max={values.length - 1}
						onChange={changeHandler}
					/>
					{markers}
					{
						showToolTip && toolTipShown && <ToolTip
							x={x}
							y={10}
							content={content}
						/>
					}
				</div>
				{
					maxLabel && (
						<div styleName={classNames("styles.range-label", "typography.body-short-brand-00")}> {maxLabel}</div>
					)
				}
			</div>
		</div>
	);
};

export { RangeSlider };
