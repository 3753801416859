import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useInterval } from "react-use";
import ScrollWrapper from "@components/ScrollWrapper/ScrollWrapper";
import { useTrackEvent } from "@frontend/tracking";
import { fetchUrl } from "@frontend/Utils";
import {
	FULL_INVIEW,
	useApiEndpoint,
	useGetPostTrackingCategory,
	useIsElementInView,
	useMemoizedContext,
	usePrevious,
} from "@hooks/Hooks";

const AdTracker = ({ scrollY, el }) => {
	const apiEndpoint = useApiEndpoint();
	const category = useGetPostTrackingCategory();
	const isElementInView = useIsElementInView();
	const { pathname } = useLocation();
	const { postId } = useParams();
	const [tracked, setTracked] = useState([]);
	const [inView, setInView] = useState(0);
	const [eventId, setEventId] = useState(false);
	const trackEvent = useTrackEvent();

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		adType,
		clickTags,
		id,
		sermoContentCardId,
		resourceCenterId,
		resourceCenterItemId,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"adType",
		"clickTags",
		"id",
		"sermoContentCardId",
		"resourceCenterId",
		"resourceCenterItemId",
	]);

	const {
		active,
	} = useMemoizedContext("member", [
		"active",
	]);

	const {
		expandedPost,
	} = useMemoizedContext("ui", [
		"expandedPost",
	]);

	useEffect(() => {
		if (!active) {
			setEventId(false);
		}
	},[active])

	const startDurationTracker = (data) => {
		if (data) {
			if (data.eventId) {
				setEventId(data.eventId);
			}
		}
	};

	const trackViews = () => {
		// Impressions in the feed.
		if (tracked.includes(inView)) {
			return;
		}

		setTracked([...tracked, inView]);

		if (0 === inView) {
			return;
		}

		let label = expanded
			? "view-expanded-post"
			: "view-post";
		let area = adType
			? adType.toLowerCase().replace("infeed", "")
			: "post";

		const viewBlocked = !expanded && (
			!!expandedPost
			|| pathname.includes("/expanded")
		);

		if (
			expanded
			|| !viewBlocked
		) {
			trackEvent(
				{
					category,
					action: expanded && expandedPost
						? "expanded-view"
						: "view-" + inView,
					label,
					postId: id,
					adId,
					adFrequency,
					expanded,
					area,
					sermoContentCardId,
					occuredOnPath: pathname,
				},
				startDurationTracker
			);
		}

		// These views will only be invoked for regular/infeed views.
		if (!expanded) {
			if (clickTags?.view && inView === 50) {
				clickTags?.view.forEach((url) => {
					fetchUrl(url);
				});
			}
		}

		// These views will only be invoked for expanded views.
		if (expanded) {
			if (clickTags?.expandedView) {
				clickTags?.expandedView.forEach((url) => {
					fetchUrl(url);
				});
			}
		}
	};

	const activePrevious = usePrevious(active);

	// only track if
	// we have an eventID
	// it's in view
	// it used to not be
	// AND nothing is expanded
	// OR something is expanded, and it is the expanded ad
	useInterval(
		() => {
			if (eventId) {
				apiEndpoint("sc/update", {
					adId,
					eventId,
					sermoContentCardId,
					resourceCenterId,
					resourceCenterItemId,
				});
			} else if ( active && activePrevious === false ) {
				// when a user goes inactive and comes back reset tracking
				setTracked([]);
			}
		},
		inView
		&& (
			!expandedPost
			|| (
				expandedPost
				&& expanded
			)
		)
			? 5000
			: null
	);

	// If a post is linked to directly but is not expanded, then track it anyway
	useEffect(() => {
		if (id === parseInt(postId) && !expandedPost) {
			trackViews();
		}
	},[])

	useEffect(() => {
		if (expanded) {
			setInView(FULL_INVIEW);
		}
	}, [expanded]);

	// use scrollY to determine if the ad is in view
	useEffect(() => {
		// if it's an expanded post we don't have to check in view as that is set automatically above
		if (expandedPost || expanded) {
			return;
		}

		// prevent accidental fire on render
		if (window.innerHeight === 0 || (expandedPost && expandedPost?.cardData?.id !== id)) {
			return;
		}

		let inViewValue = isElementInView(el?.current);

		// update inView status
		if (inViewValue !== inView) {
			setInView(inViewValue);
		}
	}, [scrollY, expandedPost, expanded]);

	// handle tracking timing when this ad comes into view
	useEffect(() => {
		if (active && inView) {
			trackViews();
		}
	}, [active, inView]);

	return null;
};

AdTracker.propTypes = {
	scrollY: PropTypes.number,
	el: PropTypes.object.isRequired,
};

const AdTrackerWithScroll = ({ el }) => (
	<ScrollWrapper limit={1000 / 30}>
		<AdTracker el={el} />
	</ScrollWrapper>
);

export default AdTrackerWithScroll;
