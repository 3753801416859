import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalWithdrawalComponents.scss";

const Table = ({ children }) => <table styleName="styles.table">{children}</table>;

Table.propTypes = {
	children: PropTypes.node,
};

export default Table;
