import React from "react";
import { useMatch } from "react-router-dom";
import { Loading } from "@components/Helpers/Helpers";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { PreviewMessagesList } from "@components/Messaging/components/MessagesList";
import SharedDirectMessageAd from "@components/Messaging/components/SharedDirectMessageAd";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useFetch } from "@hooks/Hooks";
import { Messaging } from "./AdPreview.styles";

const AdPreviewDirectMessage = () => {
	const route = useMatch({
		path: "/dma-preview/:adId",
	});

	let adId = route?.params?.adId;

	const { queryParams } = useMemoizedContext("member");
	let sender = queryParams?.sender;
	if (isNaN(sender)) {
		sender = 1;
	}
	const [data, loading] = useFetch("api/creativepreview/loadcreativepreviewdirectmessage", {
		adId: parseInt(adId),
		senderIndex: sender,
	});

	if (loading || !data) {
		return (
			<div>
				{loading && <Loading />}
			</div>
		)
	}

	return (
		<>
			<MobileAndTabletPortrait>
				<SharedDirectMessageAd
					{...data}
				/>
			</MobileAndTabletPortrait>
			<TabletLandscapeAndDesktop>
				<PreviewMessagesList
					{...data}
				/>
				<SharedDirectMessageAd
					{...data}
				/>
			</TabletLandscapeAndDesktop>
		</>
	);
};

export default AdPreviewDirectMessage;
