import styled, { css } from "styled-components";
export const RailContainer = styled.div `
	display: flex;
	flex-direction: column;

	${(props) => props.$side && css `
		width: ${props.theme.layout[`${props.$side}RailWidth`]};
		max-width: ${props.theme.layout[`${props.$side}RailWidth`]};
		min-width: ${props.theme.layout[`${props.$side}RailWidth`]};
	`};
`;
export const RailContent = styled.div `
	padding: var(--rail-padding) 0;
	position: sticky;
	will-change: top, bottom;

	${(props) => css `
		top: ${props.$top};
		bottom: ${props.$bottom};
	`};
`;
export const RailSpacer = styled.div `
	will-change: margin-top;

	${(props) => css `
		margin-top: ${props.$marginTop}px;
	`};
`;
