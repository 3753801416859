import { Button, Checkbox } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ContentStyled } from "./styled";

const Content = ({ actionHandler, loading, children }) => {
	const [checked, setChecked] = useState(false);

	const onChangeHandler = (evt) => {
		setChecked(evt.target.checked);
	}

	return (
		<ContentStyled>
			<h3 className={"heading-04"}>Welcome to Sermo</h3>
			<p className={"body-long-02"}>
			Click ‘Get started’ to access our physicians-only community.
			</p>
			<br/>
			<p className={"body-long-02"}>
			Get practice advice, improve patient outcomes, unwind with peers, earn money—it‘s all here <em>for you</em>.
			Earn your first $20 today for adding a bio in your profile (under account settings in the top-right menu).
			</p>
			{ children }
			<p>
				<Checkbox
					checked={checked}
					label={
						<p className={"body-long-02"}>
					I confirm that I have read and I agree to
							<a
								href={"https://www.sermo.com/privacy-policy/"}
								target={"_blank"}
								rel="noreferrer"
							> Sermo’s Privacy Policy,
							</a>
							<a
								href={"https://www.sermo.com/terms-of-service/"}
								target={"_blank"}
								rel="noreferrer"
							> Terms of Use,
							</a>
							<a
								href={"https://www.sermo.com/cookie-policy/"}
								target={"_blank"}
								rel="noreferrer"
							> Cookie Policy
							</a>,
					and consent to the collection, processing,
					and retention of my data in accordance with those policies.
						</p>
					}
					onChange={onChangeHandler}
				/>
			</p>
			<Button
				onClick={actionHandler}
				size={"medium"}
				disabled={loading || !checked}
			>Get started</Button>
		</ContentStyled>
	)};

Content.propTypes = {
	actionHandler: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	children: PropTypes.node,
};

export default Content;
