import { onMobileOrTabletPortrait } from "@sermo/ui-components";
import styled from "styled-components";
import ADesk from "./assets/modal-img-A_desk.png";
import AMob from "./assets/modal-img-A_mob.png";

export const ModalStyled = styled.div`
	display: flex;
	flex-direction: column;

	header {
		height: 120px;
		background-image: url(${ADesk});
		background-size: cover;
		margin-left: -24px;
		margin-right: -24px;
		margin-top: -60px;
	}

	${onMobileOrTabletPortrait(`
		header {
			height: 152px;
			background-image: url(${AMob});
		}
	`)}
`;

export const ContentStyled = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	h3 {
		margin: 24px 0 8px 0;
		font-weight: bold;
	}
	p {
		em {
			font-style: italic;
		}
	}
	label {
		margin-top: 24px;
		> span:first-child {
			margin-top: 4px;
			align-self: start;
		}
	}
	button {
		margin-top: 24px;
		align-self: flex-end;
	}
	${onMobileOrTabletPortrait(`
		button {
			margin-bottom: 16px;
		}
	`)}
`;
