const config = {
	// your webhook for POST verification processing
	// callbackURL: "https://www.vouched.id/",
	// theme
	theme: {
		name: "avant",
		// logo: {
		// 	src: "https://www.vouched.id/wp-content/uploads/2021/01/vouched-logo.svg",
		// 	width: "200px",
		// },
		baseColor: "#6200ea",
		font: "Sailec",
		progressIndicatorTextColor: "#6200ea",
	},
};

const loadVouched = ( vouchedJsPluginConfiguration ) => {
	const existingScript = document.getElementById("vouched");
	if (!existingScript) {
		const script = document.createElement("script");
		script.src = "https://static.vouched.id/plugin/releases/latest/index.js";
		script.id = "vouched";
		script.async = true;
		document.head.appendChild(script);
		script.onload = () => {
			var vouched = window["Vouched"]({ ...vouchedJsPluginConfiguration, ...config });
			console.log("mount vouched-element");
			vouched.mount("#vouched-element");
		};
	}
};

export default loadVouched;
