import { ColorGrey100 } from "@sermo/design-tokens";
import { Icon, Button as UIButton, ArrowDownSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useRef } from "react";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Loading } from "@components/Helpers/Helpers";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ReadOnlyModal from "@components/Modal/Modals/ReadOnlyModal/ReadOnlyModal";
import ReverificationModal from "@components/Modal/Modals/ReverificationModal/ReverificationModal";
import PostSurvey from "@components/Register/PostSurvey";
import { EUIKey, useCloseMenu, useCloseModal, useOpenMenu, useOpenModal } from "@contexts/UI";
import { usePreconditionModal } from "@frontend/Utils";
import { findTrackingLabelFromRights } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import { useTrackEvent } from "../../tracking/tracking";
import styles from "./Button.scss";

const ARROW_SIZE = 12;

const ButtonContentIconStyled = styled(Icon)`
	display: inline-block;
	margin-left: 4px;
	&& path {
		color: ${ColorGrey100};
		fill: ${ColorGrey100};
	}
`;

export const buttonPropTypes = {
	children: PropTypes.node,
	clickHandler: PropTypes.func,
	context: PropTypes.object,
	contextClass: PropTypes.string,
	dataComponent: PropTypes.string,
	disabled: PropTypes.bool,
	download: PropTypes.bool,
	exact: PropTypes.bool,
	wrap: PropTypes.bool,
	icon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	image: PropTypes.string,
	labelPosition: PropTypes.string,
	menu: PropTypes.shape({
		component: PropTypes.func,
		name: PropTypes.string,
		componentProps: PropTypes.object,
	}),
	activeUnderline: PropTypes.bool,
	sidePaddingDisabled: PropTypes.bool,
	withArrow: PropTypes.bool,
	to: PropTypes.string,
	theme: PropTypes.oneOf(["dark", "light", "dark-link"]),
	alignment: PropTypes.oneOf(["center", "start", "end"]),
	style: PropTypes.oneOf([
		"primary",
		"primaryWhite",
		"secondary",
		"secondary-inline",
		"flatNeutral",
		"flat",
		"flat-no-round",
		"tab",
		"icon",
		"round",
		"round-no-shadow",
		"dashoutline",
		"inline",
	]),
	air: PropTypes.oneOf(["large", "medium", "small"]),
	size: PropTypes.oneOf(["inline", "xsmall", "small", "mediumsmall", "medium", "large"]),
	loading: PropTypes.bool,
	fluid: PropTypes.bool,
	trialMemberTrackingObject: PropTypes.object,
	readOnlyMemberTrackingObject: PropTypes.object,
	rightsRequired: PropTypes.arrayOf(
		PropTypes.oneOf([
			"canAccessFrontendAccountSettings",
			"canAccessFrontendActivityNotifications",
			"canAccessFrontendBlockedMemberSettings",
			"canAccessFrontendDrugRatings",
			"canAccessFrontendEmailNotificationSettings",
			"canAccessFrontendLanguagePreferenceSettings",
			"canAccessFrontendPrivateMessages",
			"canAccessFrontendPulseFeed",
			"canAccessFrontendSearch",
			"canAccessFrontendSurveys",
			"canTakeFrontendSurveys",
			"canCommentOnPostsInFrontend",
			"canCreateEditAndDeletePostsInFrontend",
			"canFollowItemsInFrontend",
			"canHideItemsInFrontend",
			"canLikeItemsInFrontend",
			"canMuteItemsInFrontend",
			"canViewOwnProfile",
			"canViewPostsInFrontend",
			"canVoteOnItemsInFrontend",
			"canWithdrawMoneyFromOwnAccount",
			"canInviteColleaguesInFrontend",
			"canBlockMembersInFrontend",
			"canRequestEmailChangeInFrontend",
			"canRequestNameChangeInFrontend",
			"canRequestSpecialtyChangeInFrontend",
			"canSwitchDisplayNameInFrontend",
			"canUpdateAddressInFrontend",
			"canUpdateBiographyInFrontend",
			"canUpdateContentLanguagesInFrontend",
			"canUpdateDateOfBirthInFrontend",
			"canUpdateNotificationSettingsInFrontend",
			"canUpdatePasswordInFrontend",
			"canUpdatePlaceOfEmploymentInFrontend",
			"canUpdateProfilePictureInFrontend",
			"canUpdateTelephoneInFrontend",
			"canUpdateUILanguageInFrontend",
			"canUpdateUsernameInFrontend",
			"nonExistingRightDoNotDelete",
		])
	),
	type: PropTypes.oneOf(["button", "submit"]),
	overlay: PropTypes.bool,
	processingText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	formName: PropTypes.string,
	target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
	external: PropTypes.bool,
	tracking: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	postSurveyReferralTrackingObject: PropTypes.object,
	reverificationRequiredTrackingObject: PropTypes.object,
	kind: PropTypes.string,
	role: PropTypes.string,
};

const getStyles = (props) => {
	const {
		labelPosition,
		active,
		disabled,
		theme,
		style,
		size,
		wrap,
		activeUnderline,
		sidePaddingDisabled,
		overlay,
	} = props;

	return [
		"styles.button",
		{ [`styles.label-${labelPosition}`]: labelPosition },
		{ "styles.active": active },
		{ "styles.disabled": disabled },
		{ [`styles.${theme}`]: theme },
		{ [`styles.${style}-style`]: style },
		{ [`styles.${size}-block`]: size },
		{ "styles.no-wrap": !wrap },
		{ "styles.underline": activeUnderline },
		{ "styles.sidePaddingDisabled": sidePaddingDisabled },
		{ "styles.overlay": overlay },
	];
};

const Contents = (props) => {
	const {
		alignment,
		children,
		dataId,
		icon,
		image,
		labelPosition,
		loading = false,
		processingText,
		withArrow,
	} = props;

	const alignments = {
		center: {
			alignItems: "center",
			textAlign: "center",
		},
		start: {
			justifyContent: "flex-start",
			textAlign: "left",
		},
		end: {
			justifyContent: "flex-end",
			textAlign: "right",
		},
	};

	const style = {
		...alignments[alignment],
	};

	const content = loading
		? (
			<>
				{" "}
				<Loading contextClass="button" />{" "}
				<span styleName={classNames("styles.loading-submitting-text")}>
					{
						"string" === typeof processingText
							? processingText
							: getTranslation("frontend.generics.submitting", true)
					}
				</span>{" "}
			</>
		)
		: (
			children
		);

	const hasImageOrIcon = image || icon;
	//
	return hasImageOrIcon
		? (
			<>
				{
					icon
						? (
							<div
								className="icon-wrapper"
								styleName="styles.icon-wrapper"
							>
								{icon}
							</div>
						)
						: <img
							styleName={"styles.image"}
							src={image}
						/>
				}
				{/* if there is an icon dont render the text on mobile */}
				{
					["left", "right"].includes(labelPosition) && (
						<div
							style={style}
							styleName={classNames("styles.children-wrapper")}
							data-id={dataId}
						>
							{content}
							{
								withArrow && <ButtonContentIconStyled
									src={ArrowDownSVG}
									width={ARROW_SIZE}
									height={ARROW_SIZE}
								/>
							}
						</div>
					)
				}
				{
					["top", "bottom"].includes(labelPosition) && (
						<TabletLandscapeAndDesktop>
							<div
								style={style}
								styleName={classNames("styles.children-wrapper")}
							>
								{content}
								{
									withArrow && <ButtonContentIconStyled
										src={ArrowDownSVG}
										width={ARROW_SIZE}
										height={ARROW_SIZE}
									/>
								}
							</div>
						</TabletLandscapeAndDesktop>
					)
				}
			</>
		)
		: (
			<div
				style={style}
				styleName={classNames("styles.children-wrapper")}
			>
				{content}
			</div>
		);
};

const ContentsWithoutLoading = (props) => {
	const { image, icon, children, alignment, withArrow, labelPosition } = props;

	const alignments = {
		center: {
			alignItems: "center",
			textAlign: "center",
		},
		start: {
			justifyContent: "flex-start",
			textAlign: "left",
		},
		end: {
			justifyContent: "flex-end",
			textAlign: "right",
		},
	};

	const style = {
		...alignments[alignment],
	};

	const hasImageOrIcon = image || icon;
	//
	return hasImageOrIcon
		? (
			<>
				{
					icon
						? (
							<div
								className="icon-wrapper"
								styleName="styles.icon-wrapper"
							>
								{icon}
							</div>
						)
						: <img
							styleName={"styles.image"}
							src={image}
						/>
				}
				{/* if there is an icon dont render the text on mobile */}
				{
					["left", "right"].includes(labelPosition) && (
						<div
							style={style}
							styleName={classNames("styles.children-wrapper")}
						>
							{children}
							{
								withArrow && <ButtonContentIconStyled
									src={ArrowDownSVG}
									width={ARROW_SIZE}
									height={ARROW_SIZE}
								/>
							}
						</div>
					)
				}
				{
					["top", "bottom"].includes(labelPosition) && (
						<TabletLandscapeAndDesktop>
							<div
								style={style}
								styleName={classNames("styles.children-wrapper")}
							>
								{children}
								{
									withArrow && <ButtonContentIconStyled
										src={ArrowDownSVG}
										width={ARROW_SIZE}
										height={ARROW_SIZE}
									/>
								}
							</div>
						</TabletLandscapeAndDesktop>
					)
				}
			</>
		)
		: (
			<div
				style={style}
				styleName={classNames("styles.children-wrapper")}
			>
				{children}
			</div>
		);
};

const getMenu = ({ menu, context, triggerRef }) => {
	if (menu && context) {
		const { menu: contextMenu } = context;

		if (contextMenu === menu.name) {
			const { component: Component } = menu;
			return <Component
				{...menu.componentProps}
				triggerRef={triggerRef}
			/>;
		}

		return null;
	}

	return null;
};

/**
 * generic button component to be used site wide
 * if passed a "to" prop will use NavLink and cause routing
 * else will just behave as a button
 */
const Button = (props) => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const triggerRef = useRef();
	const trackEvent = useTrackEvent();
	const {
		locale,
		queryParams,
		rights,
		trialMember,
		affiliateMember,
		readOnlyMember,
		updateMember,
		isReverificationRequired,
		isPostSurveyReferral,
	} = useMemoizedContext("member", [
		"locale",
		"queryParams",
		"rights",
		"trialMember",
		"affiliateMember",
		"readOnlyMember",
		"isReverificationRequired",
		"isPostSurveyReferral",
	]);

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		id,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"id",
	]);

	const {
		clickHandler,
		context,
		contextClass,
		dataComponent,
		disabled,
		download,
		exact,
		icon,
		image,
		menu,
		to,
		formName,
		target,
		external,
		alignment,
		tracking,
		loading,
		rightsRequired,
		trialMemberTrackingObject,
		readOnlyMemberTrackingObject,
		postSurveyReferralTrackingObject,
		reverificationRequiredTrackingObject,
		type,
		style,
		kind,
		size,
		air,
		fluid = false,
		role
	} = props;

	const match = useMatch(to)

	const navigate = useNavigate();

	const styleNames = getStyles(props);

	const menuComponent = getMenu({ ...props, triggerRef });

	const trialMemberModalInitialTrackingObject = {
		category: affiliateMember
			? "affiliatemembermodal"
			: "trialmembermodal",
		locale: locale,
		postId: id,
		adId: adId,
		adFrequency: adFrequency,
		expanded: expanded,
	};

	const readOnlyMemberModalInitialTrackingObject = {
		category: "readonlymodal",
		locale: locale,
		postId: id,
		adId: adId,
		adFrequency: adFrequency,
		expanded: expanded,
	};

	const postSurveyReferralModalInitialTrackingObject = {
		category: "postsurveyreferralmodal",
		locale: locale,
		postId: id,
		adId: adId,
		adFrequency: adFrequency,
		expanded: expanded,
	};

	const reverificationRequiredModalInitialTrackingObject = {
		category: "reverificationmodal",
		locale: locale,
		postId: id,
		adId: adId,
		adFrequency: adFrequency,
		expanded: expanded,
	};

	const determineTrialMemberTrackingLabelFromRights = () => {
		let label = null;

		if (trialMemberTrackingObject !== null) {
			if (trialMemberTrackingObject.label) {
				label = trialMemberTrackingObject.label;
			}
		}

		return label || findTrackingLabelFromRights(rightsRequired);
	};

	const determineReadOnlyMemberTrackingLabelFromRights = () => {
		let label = null;

		if (readOnlyMemberTrackingObject?.label) {
			label = readOnlyMemberTrackingObject.label;
		}

		if (label === null && rightsRequired && rightsRequired.length) {
			label = rightsRequired[0];
		}

		return label;
	};

	const generateTrialMemberTrackingObject = () => {
		return {
			...trialMemberModalInitialTrackingObject,
			...trialMemberTrackingObject,
			queryParams: queryParams,
			locale: locale,
			label: determineTrialMemberTrackingLabelFromRights(),
		};
	};

	const generateReadOnlyMemberTrackingObject = () => {
		return {
			...readOnlyMemberModalInitialTrackingObject,
			...readOnlyMemberTrackingObject,
			queryParams: queryParams,
			locale: locale,
			label: determineReadOnlyMemberTrackingLabelFromRights(),
		};
	};

	const generatePostSurveyReferralModalTrackingObject = () => {
		return {
			...postSurveyReferralModalInitialTrackingObject,
			...postSurveyReferralTrackingObject,
			queryParams: queryParams,
			locale: locale,
			label: determineReadOnlyMemberTrackingLabelFromRights(),
		};
	};

	const generateReverificationRequiredTrackingObject = () => {
		return {
			...reverificationRequiredModalInitialTrackingObject,
			...reverificationRequiredTrackingObject,
			queryParams: queryParams,
			locale: locale,
			label: determineReadOnlyMemberTrackingLabelFromRights(),
		};
	};

	const closeTrialMemberModalEventHandler = () => {
		let trackingObject = generateTrialMemberTrackingObject();
		trackingObject.action = "close";

		trackEvent(trackingObject);

		closeModal("trial-member");
	};

	const closeReadOnlyMemberModalEventHandler = () => {
		let trackingObject = generateReadOnlyMemberTrackingObject();
		trackingObject.action = "close";

		trackEvent(trackingObject);

		closeModal("readonly");
	};

	const closePostSurveyReferralModalEventHandler = () => {
		let trackingObject = generatePostSurveyReferralModalTrackingObject();
		trackingObject.action = "close";

		trackEvent(trackingObject);

		closeModal("post-survey-referral");
	};

	const closeReverificationModalEventHandler = () => {
		let trackingObject = generateReverificationRequiredTrackingObject();
		trackingObject.action = "close";

		trackEvent(trackingObject);

		closeModal("reverification");
	};

	const { openPreconditionModal } = usePreconditionModal(() => {
		if(!affiliateMember) {
			closeTrialMemberModalEventHandler();
		}
	});

	const onClick = (e) => {
		// If this button has required rights check them
		if (rightsRequired.length) {
			let canClick = true;

			// Check all the required rights.
			rightsRequired.forEach((right) => {
				// If any of them are false prevent click.
				if (!rights[right]) {
					canClick = false;
				}
			});

			// If clicking is prevented show the affiliate or trial member modal.
			if (!canClick) {
				if (trialMember || affiliateMember) {
					openPreconditionModal(generateTrialMemberTrackingObject());
					updateMember({ hasSeenTrialMemberPopup: true });
				} else if (isReverificationRequired) {
					openModal({
						[EUIKey.MODAL_LABEL]: "reverification",
						[EUIKey.MODAL_CLOSE_METHOD]: closeReverificationModalEventHandler,
						[EUIKey.MODAL_COMPONENT]: (
							<ReverificationModal trackingObject={generateReverificationRequiredTrackingObject()} />
						),
					});

					updateMember({ hasSeenReverificationPopup: true });
				} else if (isPostSurveyReferral) {
					openModal({
						[EUIKey.MODAL_CLOSE_METHOD]: closePostSurveyReferralModalEventHandler,
						[EUIKey.MODAL_COMPONENT]: <PostSurvey/>,
						[EUIKey.MODAL_LABEL]: "post-survey-referral",
					});

					updateMember({ hasSeenPostSurveyReferralPopup: true });
				} else if (readOnlyMember) {
					openModal({
						[EUIKey.MODAL_LABEL]: "readonly",
						[EUIKey.MODAL_CLOSE_METHOD]: closeReadOnlyMemberModalEventHandler,
						[EUIKey.MODAL_COMPONENT]: <ReadOnlyModal trackingObject={generateReadOnlyMemberTrackingObject()} />,
					});
				}

				e.preventDefault();
				e.stopPropagation();
				return;
			}
		}

		// Invoke any tracking events.
		if (tracking) {
			trackEvent({
				...tracking,
				locale,
				queryParams,
			});
		}

		// Context comes from ButtonWithMenu and is the menu context
		if (context) {
			const { menu: menuName, openMenu, closeMenu } = context;

			e.stopPropagation();
			e.preventDefault();

			if (menu !== null) {
				if (menu.name !== menuName) {
					openMenu(menu.name);
				} else {
					closeMenu();
				}
			}
		}

		// Call the passed in click handler.
		if (clickHandler) {
			clickHandler(e);
		}
	};

	// When an item within the menu is clicked we want to stop it from bubling up any further.
	// We also want to close the menu that was open.
	const clickStop = (e) => {
		if (to === "" && !external && !download) {
			if (menuComponent && context) {
				context.closeMenu();
				e.preventDefault();
				e.stopPropagation();
			}
		}
	};

	const handleOnClick = (e) => {
		onClick(e);
		window.open(to, target);
	}

	if (disabled) {
		return (
			<div styleName={classNames(styleNames)}>
				<Contents {...props} />
			</div>
		);
	}

	if (to !== "") {
		if (external || download) {
			return (
				<div styleName={classNames("styles.wrapper", { [`styles.context-${contextClass}`]: contextClass })}>
					<UIButton
						role={role}
						size={size || "medium"}
						kind={kind || style}
						fluid={fluid}
						air={fluid}
						onClick={handleOnClick}
					>
						<div styleName="styles.contents-wrapper">
							<ContentsWithoutLoading {...props} />
						</div>
					</UIButton>
				</div>
			);
		} else {
			return (
				<div
					onClick={clickStop}
					styleName={classNames("styles.wrapper", { [`styles.context-${contextClass}`]: contextClass })}
					title={props.tooltip}
				>
					{
						// TODO: Button + Menu dropdown cases to refactor
						!["top-nav-menu", "search-nav"].includes(contextClass)
							? (
								<UIButton
									role={role}
									ref={triggerRef}
									size={size}
									kind={kind || style}
									disabled={disabled}
									fluid={fluid}
									air={fluid}
									onClick={
										(e) => {
											onClick(e);
											navigate(to);
										}
									}
									processing={loading}
									processingText={getTranslation("frontend.generics.submitting", true)}
									className={
										match?.pathname === to
											? "active"
											: null
									}
								>
									<div styleName="styles.contents-wrapper">
										<ContentsWithoutLoading {...props} />
									</div>
								</UIButton>
							)
							: (
								<>
									<NavLink
										role={role}
										ref={triggerRef}
										to={to}
										target={target}
										exact={
											exact
												? exact
												: undefined
										} // solves the warning from react in console
										onClick={onClick}
										styleName={classNames([...styleNames])}
									>
										<Contents {...props} />
									</NavLink>
									{menuComponent}
								</>
							)
					}
				</div>
			);
		}
	}

	const getContent = () => {
		if (typeof props.children?.props?.children === "string") {
			return <span>{props.children?.props?.children}</span>
		}
		return <ContentsWithoutLoading {...props} />;
	};

	/**
	 * Infers from props whether the button should be rendered as new UI component.
	 * TODO: Conditions should be wider as the new components are improved until render any occurrence.
	 * @return {boolean}
	 */
	const isNewUI = () => [
		"primary",
		"primaryWhite",
		"secondary",
		"flat",
		"flatNeutral",
		"text",
	].includes(style)
		&& !icon
		&& !image;

	if (isNewUI()) {
		return (
			<div
				onClick={clickStop}
				styleName={classNames("styles.wrapper", { [`styles.context-${contextClass}`]: contextClass })}
			>
				<UIButton
					role={role}
					ref={triggerRef}
					kind={style}
					size={size}
					disabled={disabled}
					air={air}
					fluid={fluid}
					onClick={onClick.bind(this)}
					processing={loading}
					processingText={"Loading..."}
					icon={icon}
					type={type}
				>
					<div styleName="styles.contents-wrapper">
						{getContent()}
					</div>
				</UIButton>
				{menuComponent}
			</div>
		);
	} else {
		return (
			<div
				onClick={clickStop}
				styleName={classNames("styles.wrapper", { [`styles.context-${contextClass}`]: contextClass })}
			>
				<button
					role={role}
					ref={triggerRef}
					onClick={onClick.bind(this)}
					form={
						formName
							? formName
							: undefined
					}
					style={{ alignment }}
					type={type}
					data-component={dataComponent}
				>
					{/* span is necessary here because some browsers wont let buttons be grid/flex containers */}
					<div styleName={classNames([...styleNames])}>
						<Contents {...props} />
					</div>
				</button>
				{menuComponent}
			</div>
		);
	}
};

Button.propTypes = buttonPropTypes;

Button.defaultProps = {
	clickHandler: () => {},
	disabled: false,
	icon: false,
	theme: "light",
	style: "primary",
	size: "large",
	alignment: "center",
	wrap: true,
	withArrow: false,
	rightsRequired: [],
	loading: false,
	activeUnderline: false,
	sidePaddingDisabled: false,
	to: "",
	download: false,
	tracking: false,
	trialMemberTrackingObject: {},
	type: "button",
	overlay: false,
	processingText: false,
};

export default Button;

const ButtonWithMenu = (props) => {
	const closeMenu = useCloseMenu();
	const openMenu = useOpenMenu();
	const {
		menuName: menu,
	} = useMemoizedContext("ui", [
		"menuName",
	]);

	return <Button
		{...props}
		context={
			{
				closeMenu,
				menu,
				openMenu,
			}
		}
	/>;
};

export { ButtonWithMenu };
