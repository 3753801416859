import { Icon, ChevronLeftSVG } from "@sermo/ui-components";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Routes } from "react-router-dom";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { DropNav as SurveyDropNav } from "@components/SurveysLayout/SurveysLayout";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import styles from "./MobileNav.scss"; // TODO: create mobile nav container so i can remove this
import ProfileMobileNavDropDown from "./Navs/ProfileMobileNavDropDown";
import SettingsMobileNavDropDown from "./Navs/SettingsMobileNavDropDown";

const MobileNav = () => {
	const el = useRef(null);
	const isPreview = !!useMatch("/preview/*");
	const { hasAccessToPreview, hasAccessToAdPreview } = useMemoizedContext("member", ["hasAccessToPreview"])
	const {
		expandedPost,
		postEditorIsOpen,
		typeSelectorIsOpen,
	} = useMemoizedContext("ui", [
		"expandedPost",
		"postEditorIsOpen",
		"typeSelectorIsOpen",
	]);

	const onTouchMove = (e) => {
		if (expandedPost) {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	useEffect(() => {
		if (el.current) {
			// @ts-ignore
			el.current.addEventListener("touchmove", onTouchMove, { passive: false });
		}

		return () => {
			if (el.current) {
				// @ts-ignore
				el.current.removeEventListener("touchmove", onTouchMove, { passive: false });
			}
		};
	}, []);

	if (
		useMediaQuery({ query: TabletLandscapeAndDesktopQuery })
		|| !!expandedPost
		|| postEditorIsOpen
		|| typeSelectorIsOpen
		|| (
			isPreview
			&& !hasAccessToPreview
		)
	) {
		return null;
	}

	return (
		<Routes>
			<Route
				path="/settings/:section"
				element={
					<div
						className="mobile-nav-container"
						styleName="styles.mobile-nav-container"
						ref={el}
					>
						<SettingsMobileNavDropDown />
					</div>
				}
			/>
			<Route
				path="/profile/:memberId/:section/*"
				element={
					<div
						className="mobile-nav-container"
						styleName="styles.mobile-nav-container"
						ref={el}
					>
						<ProfileMobileNavDropDown />
					</div>
				}
			/>
			<Route
				path="/video"
				element={
					<div
						className="mobile-nav-container"
						styleName="styles.mobile-nav-container"
						ref={el}
					>
						<Button
							size="medium"
							icon={<Icon src={ChevronLeftSVG} />}
							style="flatNeutral"
							labelPosition="right"
							to="/feed"
						>
							{getTranslation("frontend.generics.backToFeed")}
						</Button>
					</div>
				}
			/>
			<Route
				path="/preview/video"
				element={
					<div
						className="mobile-nav-container"
						styleName="styles.mobile-nav-container"
						ref={el}
					>
						<Button
							size="medium"
							icon={<Icon src={ChevronLeftSVG} />}
							style="flatNeutral"
							labelPosition="right"
							to="/feed"
						>
							{getTranslation("frontend.generics.backToFeed")}
						</Button>
					</div>
				}
			/>
			<Route
				path="/surveys/:section"
				element={<SurveyDropNav/>}
			/>
		</Routes>
	);
};

export default MobileNav;
