import { ColorGrey100, ColorUltraviolet40 } from "@sermo/design-tokens";
import { Icon, CheckSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import { useEffect } from "react";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { RegisterStyles, ReverificationWrap } from "@components/Register/Register.styles";
import { useCloseModal } from "@contexts/UI";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import { applyTextColor } from "@styled";
import getTranslation from "@translation/translation";

const Complete = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	${applyTextColor("text-01")};
`;

const IconWrap = styled.div`
	border: 2px solid ${ColorGrey100};
	border-radius: 50%;
	width: 58px;
	height: 58px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg path {
		fill: ${ColorUltraviolet40};
	}
`;

const Completed = () => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const { updateMember } = useMemoizedContext("member", []);

	useEffect(() => {
		apiEndpoint("reverification/completeReverification").then((dataResponse) => {
			dataResponse.json().then((body) => {
				if (dataResponse.ok) {
					updateMember({
						...body,
					});
				}
			});
		});
	}, []);

	return (
		<RegisterStyles>
			<ReverificationWrap>
				<Complete>
					<div className={classNames("form-field-row")}>
						<IconWrap>
							<Icon
								src={CheckSVG}
								size="large"
							/>
						</IconWrap>
					</div>
					<TextStyled
						className={classNames("form-field-row")}
						$kind="heading04"
					>
						<strong>
							{getTranslation("frontend.modals.reverification.completed.success")}
						</strong>
					</TextStyled>
				</Complete>
				<div className={classNames(["submit-container"])}>
					<Button
						size="medium"
						clickHandler={() => closeModal("reverification")}
					>
						{getTranslation("frontend.generics.done", true)}
					</Button>
				</div>
			</ReverificationWrap>
		</RegisterStyles>
	);
}

export default Completed;
