import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyZIndex } from "@frontend/styled";
export const StylesContainer = styled.div `
	.card {
		margin-bottom: ${({ theme }) => theme.layout.cardsSpacing};
		position: relative;

		${StyledMobileAndTabletPortraitQuery} {
			width: 100%;
			margin-bottom: 4px;
		}
	}

	.header-wrapper {
		${applyZIndex("card")};
	}

	.expanded-card-container {
		${applyZIndex("expanded-card")};
		height: ${(props) => `calc(100vh - ${props.$headerHeight}px - ${props.$footerHeight}px)`};
		overflow-y: auto;
		position: absolute;
		top: 112px;
		left: 50%;
		transform: translateX(-50%);
		max-width: calc(100vw - 40px);
		width: 840px;
		margin: 0 auto;
		-webkit-overflow-scrolling: touch;

		${StyledMobileAndTabletPortraitQuery} {
			max-width: 100%;
			height: ${(props) => `calc(100dvh - ${props.$headerHeight}px - ${props.$footerHeight}px)`};
			top: ${({ $headerHeight }) => `${$headerHeight}px;`};
			left: 0;
			transform: none;
		}
	}

	.editing {
		${applyZIndex("modal")};
	}

	.editing-profile-pic {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);

		.expanded-card-container & {
			transform: translate(-50%, 0);
		}
	}

	.banner {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

	.banner-topic {
		font-family: "Graphik", Arial, sans-serif;
		font-weight: 500;
		font-size: 11px;
		line-height: 14px;
		color: ${({ theme }) => theme.color.text05};
	}

	.banner-title {
		margin-left: 16px;
		font-family: "Graphik", Arial, sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: ${({ theme }) => theme.color.text05};

		a {
			color: ${({ theme }) => theme.color.text05};
			text-decoration: underline;
		}

		p {
			margin: 0;
		}
	}

	.followed-indicator {
		color: ${({ theme }) => theme.color.text05};
		flex-grow: 1;
		text-align: right;
		margin-right: 20px;
	}
`;
