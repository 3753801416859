import styled from "styled-components";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";

const detokenizeUrl = (url, tokens) => {
	for (const key in tokens) {
		url = url.replace(`{${key}}`, tokens[key]);
	}

	return url;
}

const supportedTokens = {
	memberid: window?.sermo?.user?.memberId,
	membersessionid: window?.sermo?.user?.memberSessionId,
}

export const getPartnerModalUrl = (partnerName) => {
	let url = window?.sermo?.partnerModals?.find(partner => partner.slug === partnerName)?.tokenizedUrl;

	if ( !url ) {
		return null;
	}

	const partnerModalUrl = detokenizeUrl(url, supportedTokens)

	return partnerModalUrl;
}

export const useOpenPartnerModal = (partnerName) => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const partnerModalUrl = getPartnerModalUrl(partnerName);
	const partnerModalLabel = `partner-modal-${partnerName}`;

	const closeMethod = () => {
		closeModal(partnerModalLabel);
	}

	const openPartnerModal = () => {
		openModal({
			[EUIKey.MODAL_BACKGROUND_DARKENED]: true,
			[EUIKey.MODAL_CLOSE_METHOD]: closeMethod,
			[EUIKey.MODAL_COMPONENT]: (
				<PartnerModal
					url={partnerModalUrl}
				/>
			),
			[EUIKey.MODAL_LABEL]: partnerModalLabel,
			[EUIKey.MODAL_SUBTYPE]: "iframe",
		})
	}

	return [openPartnerModal, closeMethod];
}

const StyledIframe = styled.iframe`
	width: 100%;
	height: 100%;
`

const PartnerModal = ({
	url
}) => (
	<>
		<StyledIframe
			src={url}
		/>
	</>
)

export default PartnerModal