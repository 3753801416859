export default Object.freeze({
	SelectSuggestedProvider: "SelectSuggestedProvider",
	EnterProviderInformation: "EnterProviderInformation",
	IdentityVerification: "IdentityVerification",
	UploadIdentityDocuments: "UploadIdentityDocuments",
	SelectVerificationCodeMethod: "SelectVerificationCodeMethod",
	EnterVerificationCode: "EnterVerificationCode",
	PendingManualVerification: "PendingManualVerification",
	FinishAccount: "FinishAccount",
	AccountAlreadyExists: "AccountAlreadyExists",
	EnterName: "EnterName",
	SelectIdentityVerificationMode: "SelectIdentityVerificationMode",
});