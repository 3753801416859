import React from "react";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import UserGeneratedContentInFeed from "@components/PostContent/components/UserGeneratedContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader/CardHeader";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const QuestionPoll = () => (<>
		<CardContainer>
			<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
				<SharedStyles.SpacingContainer>
					<CardHeader />
					<UserGeneratedContentInFeed />
					<PollDisplay />
				</SharedStyles.SpacingContainer>
			</ModuleWrapper>
			<CardFooter />
		</CardContainer>
	</>);
export default QuestionPoll;
