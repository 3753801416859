import styled, { css } from "styled-components";
import { StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const LayoutContainer = styled.div.attrs({
    className: "layout",
}) `
	width: 100%;
	max-width: var(--site-max-width);
	min-width: 100%;
	min-height: 100%;
	background-color: ${({ theme }) => theme.color.uiBackgroundSecondary};

	${StyledTabletLandscapeAndDesktopQuery} {
		width: fit-content;
	}

	${(props) => props.$mousedNav && css `
		*:focus {
			outline: none;
		}
	`};

	${(props) => props.$scrollLocked && css `
		height: 100dvh;
		overflow: hidden;
	`};
`;
