import { Button, ChevronLeftSVG, DiscussionSVG, Icon, NewPostSVG, PatientCaseSVG, QuestionSVG, XSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import * as Styles from "@components/NewPost/components/TypeSelector/TypeSelector.styles";
import NewPost from "@components/NewPost/NewPost";
import { navRestrictedRoutesRegex } from "@components/TopNav/TopNav";
import { useSetPostEditorSelectedType, useTogglePostEditor, useToggleTypeSelector } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const acceptedRoutesRegex = /\/(feed|profile)\/.*/;
const MobileTypeSelectorButtonMenu = () => {
    const { pathname } = useLocation();
    const isAcceptedRoute = acceptedRoutesRegex.test(pathname);
    const setPostEditorSelectedType = useSetPostEditorSelectedType();
    const toggleEditor = useTogglePostEditor();
    const toggleTypeSelector = useToggleTypeSelector();
    const trackEvent = useTrackEvent();
    const { postEditorIsOpen, typeSelectorIsOpen, } = useMemoizedContext("ui", [
        "postEditorIsOpen",
        "typeSelectorIsOpen",
    ]);
    const { hasAccess, loggedIn, } = useMemoizedContext("member", [
        "hasAccess",
        "loggedIn",
    ]);
    useEffect(() => {
        // close the post type selector if the user clicks on navigation
        toggleTypeSelector(false);
    }, [pathname]);
    if (!hasAccess("canCreateEditAndDeletePostsInFrontend")
        || !loggedIn) {
        return null;
    }
    const handleCreateToggle = () => {
        trackEvent({
            category: "create-post-button",
            label: typeSelectorIsOpen
                || postEditorIsOpen
                ? "close"
                : "open",
            action: "click",
        });
        if (postEditorIsOpen) {
            toggleTypeSelector(false);
            toggleEditor(false);
        }
        else {
            toggleTypeSelector(!typeSelectorIsOpen);
        }
    };
    const handlePostTypeSelection = (type) => {
        trackEvent({
            category: "post-type-selector",
            label: type,
            action: "click",
        });
        setPostEditorSelectedType(type);
        toggleTypeSelector(false);
        toggleEditor(true);
    };
    return (<MobileAndTabletPortrait>
			{!isAcceptedRoute && <NewPost />}
			{typeSelectorIsOpen && (<Styles.MobileTypeSelectorContainer>
						<Styles.SelectorHeader $kind={"bodyShortMedium02"}>
							<Button icon={<Icon height={12} src={ChevronLeftSVG} width={12}/>} kind={"flatNeutral"} onClick={handleCreateToggle} size={"xsmall"}/>
							Create a post
						</Styles.SelectorHeader>
						<Styles.SelectionsContainer>
							<Styles.Selection onClick={() => handlePostTypeSelection(PostTypeTypes.PATIENT_CASE)}>
								<Styles.IconContainer>
									<Icon src={PatientCaseSVG} width={24} height={24}/>
								</Styles.IconContainer>
								<Styles.TextContainer>
									<TextStyled $kind={"heading02"}>
										{getTranslation("frontend.newPost.patientCaseTabLabel")}
									</TextStyled>
									<Styles.Subtitle $kind={"bodyShort01"}>
										{getTranslation("frontend.card.unfocusedLabelPatientCase")}
									</Styles.Subtitle>
								</Styles.TextContainer>
							</Styles.Selection>
							<Styles.Selection onClick={() => handlePostTypeSelection(PostTypeTypes.DISCUSSION)}>
								<Styles.IconContainer>
									<Icon src={DiscussionSVG} width={24} height={24}/>
								</Styles.IconContainer>
								<Styles.TextContainer>
									<TextStyled $kind={"heading02"}>
										{getTranslation("frontend.newPost.discussionTabLabel")}
									</TextStyled>
									<Styles.Subtitle $kind={"bodyShort01"}>
										{getTranslation("frontend.card.unfocusedLabelDiscussion")}
									</Styles.Subtitle>
								</Styles.TextContainer>
							</Styles.Selection>
							<Styles.Selection onClick={() => handlePostTypeSelection(PostTypeTypes.QUESTION)}>
								<Styles.IconContainer>
									<Icon src={QuestionSVG} width={24} height={24}/>
								</Styles.IconContainer>
								<Styles.TextContainer>
									<TextStyled $kind={"heading02"}>
										{getTranslation("frontend.newPost.questionTabLabel")}
									</TextStyled>
									<Styles.Subtitle $kind={"bodyShort01"}>
										{getTranslation("frontend.newPost.questionPlaceholder")}
									</Styles.Subtitle>
								</Styles.TextContainer>
							</Styles.Selection>
						</Styles.SelectionsContainer>
					</Styles.MobileTypeSelectorContainer>)}
			<Styles.MobileContainer>
				<Styles.CreatePostButton data-component={"CreatePostButton"} onClick={handleCreateToggle}>
					<Icon src={typeSelectorIsOpen
            || postEditorIsOpen
            ? XSVG
            : NewPostSVG} width={28} height={28}/>
				</Styles.CreatePostButton>
			</Styles.MobileContainer>
		</MobileAndTabletPortrait>);
};
const MobileTypeSelector = () => {
    const showNav = !navRestrictedRoutesRegex.test(useLocation().pathname);
    const { loggedIn, hasAcceptedTermsAndConditions, trialMember, affiliateMember, readOnlyMember, showLeadLandingPage, isPostSurveyReferral, wasPostSurveyReferralVerified, showFirstSurveyPendingVerificationPage, } = useMemoizedContext("member", [
        "loggedIn",
        "hasAcceptedTermsAndConditions",
        "trialMember",
        "affiliateMember",
        "readOnlyMember",
        "showLeadLandingPage",
        "isPostSurveyReferral",
        "wasPostSurveyReferralVerified",
        "showFirstSurveyPendingVerificationPage",
    ]);
    if ((hasAcceptedTermsAndConditions
        || trialMember
        || affiliateMember
        || readOnlyMember
        || isPostSurveyReferral
        || wasPostSurveyReferralVerified)
        && loggedIn
        && !showLeadLandingPage
        && !showFirstSurveyPendingVerificationPage
        && showNav) {
        return <MobileTypeSelectorButtonMenu />;
    }
    return null;
};
export default MobileTypeSelector;
