import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { layoutCenterExtended } from "@styled/mixins";
export const Container = styled.div `
	${layoutCenterExtended};
`;
export const Header = styled(TextStyled) `
	padding: 24px;
	background-color: ${({ theme }) => theme.color.uiBackground};

	${StyledMobileAndTabletPortraitQuery} {
		padding: 14px 12px;
		border: 0;
	}
`;
