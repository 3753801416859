import React, { useReducer } from "react";

const ElementScrollContext = React.createContext({
	x: 0,
	y: 0,
});

export { ElementScrollContext };

const X_UPDATE = "X_UPDATE";
const Y_UPDATE = "Y_UPDATE";

const ElementScrollReducer = (state, action) => {
	switch (action.type) {
		case X_UPDATE:
			return { ...state, x: action.x };
		case Y_UPDATE:
			return { ...state, y: action.y };
		default:
			return state;
	}
};

export { ElementScrollReducer };

const ElementScrollContextProvider = ({ children }) => {
	const [{ x, y }, dispatch] = useReducer(ElementScrollReducer, { x: 0, y: 0 });

	const updateX = x => {
		dispatch({ type: X_UPDATE, x });
	};

	const updateY = y => {
		dispatch({ type: Y_UPDATE, y });
	};

	return (
		<ElementScrollContext.Provider
			value={
				{
					x,
					y,
					updateX,
					updateY,
				}
			}
		>
			{children}
		</ElementScrollContext.Provider>
	);
};

export default ElementScrollContextProvider;
