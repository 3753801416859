import React from "react";
import { StyledAlertText } from "@components/Modal/Modal.styles";
import { AttachmentsActionTypes } from "@contexts/Attachments";
import { PostUIEditingActionTypes, useSaving } from "@contexts/PostUI/Editing";
import { EUIKey, useOpenAlert } from "@contexts/UI";
import { AttachmentType } from "@frontend/types/Post/attachment";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { getAttachmentType } from "@frontend/Utils";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const useAttachmentAPI = () => {
    const apiEndpoint = useApiEndpoint();
    const openAlert = useOpenAlert();
    const save = useSaving();
    const { draftStatus, } = useMemoizedContext("postUI", [
        "draftStatus",
    ]);
    const composing = DraftStatusTypes.COMPOSING === draftStatus;
    const { dispatch: dispatchPostUIEditing, } = useMemoizedContext("postUIEditing", []);
    const { id: postId, } = useMemoizedContext("postData", [
        "id",
    ]);
    const { attachments, dispatch: dispatchAttachments, } = useMemoizedContext("attachments", [
        "attachments",
    ]);
    const openFileUploadFailedModal = (isLink, message) => {
        let messageToDisplay = getTranslation("frontend.alerts.noURLPreview");
        if (!isLink) {
            messageToDisplay = message || getTranslation("frontend.alerts.uploadSizeExceed", true, "30mb");
        }
        openAlert({
            [EUIKey.ALERT_COMPONENT]: (<StyledAlertText>
					{messageToDisplay}
				</StyledAlertText>),
            [EUIKey.ALERT_LABEL]: "upload-failed",
        });
    };
    const addLink = (url, target = "Post") => {
        const endpoint = "posts/addLink";
        const requestBody = {
            url,
            target,
        };
        if ("Post" === target) {
            requestBody["postId"] = postId;
        }
        save.start(endpoint);
        apiEndpoint(endpoint, requestBody, false).then((response) => {
            save.stop(endpoint);
            if (response.ok) {
                response.json().then((responseData) => {
                    if (200 === response.status) {
                        dispatchAttachments({
                            type: AttachmentsActionTypes.ADD_ATTACHMENT,
                            payload: {
                                attachment: {
                                    ...requestBody,
                                    ...responseData,
                                    type: AttachmentType.WEB_PAGE,
                                },
                            },
                        });
                    }
                    else {
                        openFileUploadFailedModal(true);
                    }
                });
            }
        });
    };
    const uploadFile = (file, target = "Post") => {
        const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const endpoint = "posts/upload";
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("target", target);
        if ("Post" === target) {
            formData.append("postId", postId);
        }
        if (file.size
            && file.size / Math.pow(1024, 2) > 30) {
            dispatchPostUIEditing({
                type: PostUIEditingActionTypes.SET_FILE_UPLOAD_ERROR,
                payload: {
                    fileUploadError: getTranslation("frontend.alerts.uploadSizeExceed", true, "30mb"),
                },
            });
            return;
        }
        save.start(endpoint);
        dispatchAttachments({
            type: AttachmentsActionTypes.ADD_ATTACHMENT,
            payload: {
                attachment: {
                    file: null,
                    id: null,
                    loading: true,
                    type: getAttachmentType(file.type),
                    uuid,
                },
            },
        });
        apiEndpoint(endpoint, formData, true).then((response) => {
            save.stop(endpoint);
            if (response.ok) {
                response.json().then((data) => {
                    if (200 === response.status) {
                        dispatchAttachments({
                            type: AttachmentsActionTypes.UPDATE_ATTACHMENT,
                            payload: {
                                attachment: {
                                    ...file,
                                    file,
                                    ...data,
                                    loading: false,
                                    uuid,
                                },
                            },
                        });
                    }
                    else {
                        openFileUploadFailedModal(false, data.message);
                    }
                });
            }
            else {
                dispatchPostUIEditing({
                    type: PostUIEditingActionTypes.SET_FILE_UPLOAD_ERROR,
                    payload: {
                        fileUploadError: getTranslation("frontend.alerts.uploadFailed", true),
                    },
                });
                dispatchAttachments({
                    type: AttachmentsActionTypes.DELETE_ATTACHMENT,
                    payload: {
                        uuid,
                    },
                });
            }
        });
    };
    const deleteAttachment = ({ id }) => {
        if (composing) {
            const endpoint = "posts/deleteAttachment";
            const data = {
                id,
            };
            save.start(endpoint);
            apiEndpoint(endpoint, data, false).then(() => {
                save.stop(endpoint);
            });
        }
        dispatchAttachments({
            type: AttachmentsActionTypes.DELETE_ATTACHMENT,
            payload: {
                composing,
                id,
            },
        });
    };
    const deleteAllAttachments = () => {
        attachments.forEach(deleteAttachment);
    };
    const deleteAllGivenAttachments = (givenAttachments) => {
        givenAttachments.forEach(deleteAttachment);
    };
    return {
        addLink,
        deleteAllAttachments,
        deleteAllGivenAttachments,
        deleteAttachment,
        uploadFile,
    };
};
export default useAttachmentAPI;
