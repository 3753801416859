import React from "react";
// @ts-ignore
export const CommentDefaultValues = {
    attachments: [],
    comment: "",
    replies: [],
    votes: 0,
};
const CommentContext = React.createContext(CommentDefaultValues);
export default CommentContext;
