import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const FooterContainer = styled.div`
	padding: 16px;
	display: flex;
    justify-content: space-between;
    align-items: center;
	background-color: ${({ theme }) => theme.color.uiBackgroundSecondary};
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	${StyledMobileAndTabletPortraitQuery} {
		padding: 12px 16px;
	}

	&& {
		margin: 0;
	}
`;

export const FooterLeft = styled.div`
    display: flex;

    & > * {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const FooterRight = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const AnonymousToggleMobile = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.ui02};
    padding: 16px;
`;