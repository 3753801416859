import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";
const BLOCK_TAGS = {
    p: "paragraph",
    li: "list-item",
    ul: "unordered-list",
    ol: "ordered-list",
    blockquote: "quote",
    pre: "code",
    h1: "heading-one",
    h2: "heading-two",
    h3: "heading-three",
    h4: "heading-four",
    h5: "heading-five",
    h6: "heading-six",
};
const MARK_TAGS = {
    strong: "bold",
    em: "italic",
    u: "underline",
    s: "strikethrough",
    code: "code",
};
const baseSerialization = (node, children) => {
    switch (node.type) {
        case "paragraph":
            return `<p>${children}</p>`;
        case "list-item":
            return `<li>${children}</li>`;
        case "unordered-list":
            return `<ul>${children}</ul>`;
        case "ordered-list":
            return `<ol>${children}</ol>`;
        case "blockquote":
            return `<blockquote>${children}</blockquote>`;
        case "ordered-code":
            return `<pre>${children}</pre>`;
        case "heading-one":
            return `<h1>${children}</h1>`;
        case "heading-two":
            return `<h2>${children}</h2>`;
        case "heading-three":
            return `<h3>${children}</h3>`;
        case "heading-four":
            return `<h4>${children}</h4>`;
        case "heading-five":
            return `<h5>${children}</h5>`;
        case "heading-size":
            return `<h6>${children}</h6>`;
        case "bold":
            return `<strong>${children}</strong>`;
        case "italic":
            return `<em>${children}</em>`;
        case "strikethrough":
            return `<s>${children}</s>`;
        case "code":
            return `<pre>${children}</pre>`;
        case "img":
            return `<img src="${escapeHtml(node.url)}" />`;
        case "link":
            return `<a target="_blank" href="${escapeHtml(node.url)}" rel="noreferrer" contenteditable="false">${node.label}</a>`;
        case "mention":
            return `<a data-mention="${node.value}" contenteditable="false">${node.label}</a>`;
        case "emoji":
            return `<>${children}</>`;
        default:
            return `<span>${children}</span>`;
    }
};
export const doSerialize = (node, children, custom) => {
    const customSerializeReturn = custom
        ? custom(node, children)
        : null;
    if (customSerializeReturn) {
        return customSerializeReturn;
    }
    return baseSerialization(node, children);
};
export const doDeserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx("text", markAttributes, el.textContent);
    }
    else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }
    const tagName = el.tagName.toLowerCase();
    const block = BLOCK_TAGS[tagName];
    const mark = MARK_TAGS[tagName];
    const nodeAttributes = { ...markAttributes };
    // define attributes for text nodes
    if (mark) {
        nodeAttributes[mark] = true;
    }
    const children = Array.from(el.childNodes)
        .map(node => doDeserialize(node, nodeAttributes))
        .flat();
    if (children.length === 0) {
        children.push(jsx("text", nodeAttributes, ""));
    }
    if (block) {
        return jsx("element", {
            type: block,
        }, children);
    }
    switch (el.nodeName) {
        case "BODY":
            return jsx("fragment", {}, children);
        case "BR":
            return "\n";
        case "BLOCKQUOTE":
            return jsx("element", { type: "quote" }, children);
        case "P":
            return jsx("element", { type: "paragraph" }, children);
        case "IMG":
            return jsx("element", { type: "image", src: el.getAttribute("src") }, children);
        case "A":
            if (el.hasAttribute("data-mention")) {
                return jsx("element", {
                    type: "mention",
                    value: el.getAttribute("data-mention"),
                    label: el.textContent
                }, children);
            }
            return jsx("element", {
                type: "link",
                label: el.textContent,
                url: el.getAttribute("href"),
            }, children);
        default:
            return children;
    }
};
