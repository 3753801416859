import { ArrowRightSVG, getSVGIconsObject, Icon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import * as Styles from "@components/RailCards/components/FeaturedSurvey/FeaturedSurvey.styles";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./AdPreviewFeaturedSurvey.scss";

const MobileWrap = styled.div`
	display: flex;
	align-items: center;

	&>div:first-child {
		flex-basis: 100%;
	}

	&>div:not(:first-child) {
		padding-top: 4px;
		padding-right: 16px;
	}
`;

const IconWrap = styled.div`
	button {
		padding: 0;
	}
`;

const survey = {
	name: "Telemedicine Software Study",
	honorariumAmount: 50,
	currency: "USD",
	durationInMinutes: 30,
	remainingSeats: 29,
	almostFull: false,
	inProgress: false,
	cardType: "default",
	programsEnrolled: [],
	surveyLink: "https://www.google.com",
	surveyId: 1,
};

const AdPreviewFeaturedSurvey = ({ trackingCategory }) => {
	const apiEndpoint = useApiEndpoint();
	const icons = getSVGIconsObject();

	let honorarium;
	if (
		survey.honorariumAmount
		&& survey.honorariumAmount > 0
	) {
		honorarium = getCurrencyTranslation(survey.honorariumAmount, survey.currency, false, 0);
	}

	let enrolledProgram;
	if (survey.programsEnrolled?.length > 0) {
		enrolledProgram = survey.programsEnrolled[0];
	}

	const title = getTranslation(
		"frontend.railCards.featuredSurvey.title" + (survey.almostFull && !survey.inProgress
			? "Callout"
			: "Default"),
		true
	);

	if ( survey.remainingSeats === 0 ) {
		return null;
	}

	const InfoLine = ({ text, value, highlightType }) => (
		<div styleName={classNames(["styles.info-line"])}>
			<div styleName={classNames(["styles.info-line--text", "typography.body-short-brand-00"])}>{text}</div>
			<div
				styleName={
					classNames([
						"styles.info-line--value",
						"typography.heading-brand-02",
						"styles.info-line--" + highlightType,
					])
				}
			>
				{value}
			</div>
		</div>
	);

	return (
		<ModuleWrapper
			border={true}
			data-component="Surveys"
			solid={true}
			padded={false}
			contextClass={
				survey.cardType === "limited-seats"
					? "rail-module-alert"
					: survey.cardType === "in-progress"
						? "rail-module-warning"
						: "rail-default"
			}
		>
			<TabletLandscapeAndDesktop>
				<ModuleWrapper
					label={title}
					contextClass="survey-rail-card"
					type="survey"
				>
					<div styleName={classNames(["styles.survey-title-and-amount-container"])}>
						<div styleName={classNames(["styles.survey-title", "typography.heading-brand-03"])}>
							{survey.name}
						</div>
						{
							honorarium
							&& !enrolledProgram
							&& (
								<div styleName={classNames(["styles.survey-amount", "typography.heading-brand-05"])}>
									{honorarium}
								</div>
							)
						}
					</div>
					<div styleName={classNames(["styles.info-lines-container"])}>
						{
							!!survey.durationInMinutes && (
								<InfoLine
									text={getTranslation("frontend.railCards.featuredSurvey.durationTitle", true)}
									value={
										getTranslation(
											"frontend.railCards.featuredSurvey.durationValue",
											true,
											survey.durationInMinutes
										)
									}
									highlightType="default"
								/>
							)
						}

						{
							(!survey.almostFull || survey.inProgress) && (
								<InfoLine
									text={getTranslation("frontend.railCards.featuredSurvey.seatsTitle", true)}
									value={
										getTranslation(
											"frontend.railCards.featuredSurvey."
										+ (survey.remainingSeats === 1
											? "seatsValueSingular"
											: "seatsValuePlural"),
											true,
											survey.remainingSeats
										)
									}
									highlightType="default"
								/>
							)
						}

						{
							survey.almostFull
							&& !survey.inProgress
							&& (
								<InfoLine
									text={getTranslation("frontend.railCards.featuredSurvey.seatsTitle", true)}
									value={
										getTranslation(
											"frontend.railCards.featuredSurvey."
										+ (survey.remainingSeats === 1
											? "seatsValueSingularLimited"
											: "seatsValuePluralLimited"),
											true,
											survey.remainingSeats
										)
									}
									highlightType={survey.cardType}
								/>
							)
						}

						{
							survey.inProgress && (
								<InfoLine
									text={getTranslation("frontend.railCards.featuredSurvey.statusTitle", true)}
									value={getTranslation("frontend.railCards.featuredSurvey.statusValueInProgress", true)}
									highlightType={survey.cardType}
								/>
							)
						}
					</div>
					<div styleName={classNames(["styles.start-survey-button-container"])}>
						<Button
							style="secondary"
							size="small"
							target="_blank"
							external={true}
							to={survey.surveyLink}
							tracking={
								{
									category: `featured-survey-${trackingCategory}`,
									action: "click",
									label: "start-survey",
									surveyId: survey.surveyId,
								}
							}
							clickHandler={
								(e) => {
								// changing button text to "In Progress" after click
									e.target.innerText = getTranslation(
										"frontend.railCards.featuredSurvey.buttonContinueSurveyText",
										true
									);
									apiEndpoint(
										"surveys/updateSurveyProgressStatus",
										{ projectId: survey.surveyId },
										false,
										"PUT"
									);
								}
							}
							rightsRequired={["canTakeFrontendSurveys"]}
						>
							{
								getTranslation(
									"frontend.railCards.featuredSurvey."
									+ (survey.inProgress
										? "buttonContinueSurveyText"
										: "buttonStartSurveyText"),
									true
								)
							}
						</Button>
					</div>
					{
						enrolledProgram
						&& (
							<Styles.EnrolledProgramContainer>
								<Icon src={icons[enrolledProgram.icon]}/>
								<TextStyled $kind="bodyLong00">
									{enrolledProgram.honorariumText}
								</TextStyled>
							</Styles.EnrolledProgramContainer>
						)
					}
				</ModuleWrapper>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<MobileWrap>
					<ModuleWrapper
						label={title}
						contextClass="survey-rail-card"
						type="survey"
					>
						<div styleName={classNames(["styles.survey-title", "typography.heading-brand-03"])}>
							{survey.name}
						</div>
						{
							enrolledProgram
							&& (
								<Styles.EnrolledProgramContainer>
									<Icon src={icons[enrolledProgram.icon]}/>
									<TextStyled $kind="bodyLong00">
										{enrolledProgram.honorariumText}
									</TextStyled>
								</Styles.EnrolledProgramContainer>
							)
						}
					</ModuleWrapper>
					{
						honorarium
						&& !enrolledProgram
						&& (
							<TextStyled $kind="heading05">
								{honorarium}
							</TextStyled>
						)
					}
					<IconWrap styleName={classNames(["typography.heading-brand-05"])}>
						<Button
							style="flatNeutral"
							size="small"
							target="_blank"
							external={true}
							to={survey.surveyLink}
							tracking={
								{
									category: `featured-survey-${trackingCategory}`,
									action: "click",
									label: "start-survey",
									surveyId: survey.surveyId,
								}
							}
							clickHandler={
								(e) => {
									apiEndpoint(
										"surveys/updateSurveyProgressStatus",
										{ projectId: survey.surveyId },
										false,
										"PUT"
									);
								}
							}
							rightsRequired={["canTakeFrontendSurveys"]}
						>
							<Icon src={ArrowRightSVG}/>
						</Button>
					</IconWrap>
				</MobileWrap>
			</MobileAndTabletPortrait>
		</ModuleWrapper>
	);
};

AdPreviewFeaturedSurvey.propTypes = {
	trackingCategory: PropTypes.string,
};

AdPreviewFeaturedSurvey.defaultProps = {
	trackingCategory: "right-rail",
};

export default AdPreviewFeaturedSurvey;
