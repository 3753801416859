import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { DropDownList, GlobalValidationMessage } from "@components/FormFields/FormFields";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import formFieldStyles from "../../FormFields001.scss";
import styles from "./DateInput.scss";

const DateInput = ({
	year,
	month,
	day,
	yearsFrom,
	yearsTo,
	className,
	forceValidation,
	dateChangedEventHandler,
	formValidationErrors,
	hideLabels,
	addEmptyValues,
}) => {
	if (!addEmptyValues) {
		if (!year){
			year = new Date().getFullYear() - 18;
		}
		if (!month){
			month = "01";
		}
		if (!day){
			day = "01";
		}
	}

	const getAvailableYears = (yearsFrom, yearsTo) => {
		const years = [];

		if (addEmptyValues) {
			years.push({ key: getTranslation("system.dateTime.yearShort", true),
				value: "" });
		}

		for (let i = yearsTo; i > yearsFrom; i--) {
			years.push({
				key: i,
				value: i.toString(),
			});
		}

		return years;
	};

	const getAvailableMonths = () => {
		let months = [];

		if (addEmptyValues) {
			months.push({ key: getTranslation("system.dateTime.monthShort", true),
				value: "" });
		}

		months.push({ key: getTranslation("system.dateTime.january", true),
			value: "01" });
		months.push({ key: getTranslation("system.dateTime.february", true),
			value: "02" });
		months.push({ key: getTranslation("system.dateTime.march", true),
			value: "03" });
		months.push({ key: getTranslation("system.dateTime.april", true),
			value: "04" });
		months.push({ key: getTranslation("system.dateTime.may", true),
			value: "05" });
		months.push({ key: getTranslation("system.dateTime.june", true),
			value: "06" });
		months.push({ key: getTranslation("system.dateTime.july", true),
			value: "07" });
		months.push({ key: getTranslation("system.dateTime.august", true),
			value: "08" });
		months.push({ key: getTranslation("system.dateTime.september", true),
			value: "09" });
		months.push({ key: getTranslation("system.dateTime.october", true),
			value: "10" });
		months.push({ key: getTranslation("system.dateTime.november", true),
			value: "11" });
		months.push({ key: getTranslation("system.dateTime.december", true),
			value: "12" });

		return months;
	};

	const getAvailableDays = () => {
		const days = [];

		if (addEmptyValues) {
			days.push({ key: getTranslation("system.dateTime.dayShort", true),
				value: "" });
		}

		for (let i = 1; i <= 31; i++) {
			if (i < 10) {
				days.push({
					key: i,
					value: "0" + i,
				});
			} else {
				days.push({
					key: i,
					value: i.toString(),
				});
			}
		}

		return days;
	};

	const [yearInternal, setYearInternal] = useState(year);
	const [monthInternal, setMonthInternal] = useState(month);
	const [dayInternal, setDayInternal] = useState(day);
	
	const [availableYears, setAvailableYears] = useState(getAvailableYears(yearsFrom, yearsTo));
	const [availableMonths, setAvailableMonths] = useState(getAvailableMonths());
	const [availableDays, setAvailableDays] = useState(getAvailableDays());
	
	const [dateInternalValidationError, setDateInternalValidationError] = useState("");
	const [yearInternalValidationError, setYearInternalValidationError] = useState("");
	const [monthInternalValidationError, setMonthInternalValidationError] = useState("");
	const [dayInternalValidationError, setDayInternalValidationError] = useState("");

	const validateForm = () => {
		let isValid = true;

		setDateInternalValidationError("");

		if (yearInternal === "") {
			setYearInternalValidationError(" ");
			isValid = false;
		} else {
			setYearInternalValidationError("");
		}

		if (monthInternal === "") {
			setMonthInternalValidationError(" ");
			isValid = false;
		} else {
			setMonthInternalValidationError("");
		}

		if (dayInternal === "") {
			setDayInternalValidationError(" ");
			isValid = false;
		} else {
			setDayInternalValidationError("");
		}

		if (isValid) {
			let testDate = new Date(Number(yearInternal), Number(monthInternal) - 1, Number(dayInternal), 0, 0, 0);

			if (!(Boolean(+testDate) && testDate.getDate() === parseInt(dayInternal))) {
				// The reason we do the above check is that JavaScript will convert an invalid date like 2019-02-30 into 2019-03-01 automatically. We do not really want that...
				setDateInternalValidationError(
					getTranslation("system.validationMessages.validationInvalidDateOfBirth", true)
				);
				setYearInternalValidationError(" ");
				setMonthInternalValidationError(" ");
				setDayInternalValidationError(" ");

				isValid = false;
			} else {
				setDateInternalValidationError("");
				setYearInternalValidationError("");
				setMonthInternalValidationError("");
				setDayInternalValidationError("");
			}
		}

		if (dateChangedEventHandler) {
			if (isValid) {
				dateChangedEventHandler({
					year: yearInternal,
					month: monthInternal,
					day: dayInternal,
				});
			} else {
				dateChangedEventHandler(null);
			}
		}

		return isValid;
	};

	useEffect(() => {
		if (forceValidation) {
			validateForm();
		}
	}, [forceValidation]);

	useEffect(() => {
		let hasErrors = false;
		if (formValidationErrors) {
			for (let property in formValidationErrors) {
				switch (property) {
					case "date":
						hasErrors = true;
						setDateInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "year":
						hasErrors = true;
						setYearInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "month":
						hasErrors = true;
						setMonthInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
					case "day":
						hasErrors = true;
						setDayInternalValidationError(formValidationErrors[property].join("<br />"));
						break;
				}
			}
		}

		if (!hasErrors) {
			setDateInternalValidationError("");
			setYearInternalValidationError("");
			setMonthInternalValidationError("");
			setDayInternalValidationError("");
		}
	}, [formValidationErrors]);

	const yearInternalChangeHandler = (event) => {
		setYearInternal(event.target.value);
		validateForm();
	};

	const monthInternalChangeHandler = (event) => {
		setMonthInternal(event.target.value);
		validateForm();
	};

	const dayInternalChangeHandler = (event) => {
		setDayInternal(event.target.value);
		validateForm();
	};

	let monthInternalLabel = "";
	let dayInternalLabel = "";
	let yearInternalLabel = "";

	if (!hideLabels) {
		monthInternalLabel = getTranslation("frontend.settings.accountSettings.dateOfBirth.monthInputFieldTitle", true);
		dayInternalLabel = getTranslation("frontend.settings.accountSettings.dateOfBirth.dayInputFieldTitle", true);
		yearInternalLabel = getTranslation("frontend.settings.accountSettings.dateOfBirth.yearInputFieldTitle", true);
	}

	return (
		<div styleName={classNames([{ [`${className}`]: className }, "styles.date-input"])}>
			<div styleName="styles.one-third-width styles.first">
				<DropDownList
					name="monthInternal"
					label={monthInternalLabel}
					handleChange={monthInternalChangeHandler}
					handleBlur={validateForm}
					validationError={monthInternalValidationError}
					options={availableMonths}
					value={monthInternal}
				/>
			</div>
			<div styleName="styles.one-third-width">
				<DropDownList
					name="dayInternal"
					label={dayInternalLabel}
					handleChange={dayInternalChangeHandler}
					handleBlur={validateForm}
					validationError={dayInternalValidationError}
					options={availableDays}
					value={dayInternal}
				/>
			</div>
			<div styleName="styles.one-third-width styles.last">
				<DropDownList
					name="yearInternal"
					label={yearInternalLabel}
					handleChange={yearInternalChangeHandler}
					handleBlur={validateForm}
					validationError={yearInternalValidationError}
					options={availableYears}
					value={yearInternal}
				/>
			</div>

			<GlobalValidationMessage text={dateInternalValidationError} />
		</div>
	);
};

DateInput.propTypes = {
	year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	day: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	yearsFrom: PropTypes.number,
	yearsTo: PropTypes.number,
	className: PropTypes.string,
	forceValidation: PropTypes.bool,
	hideLabels: PropTypes.bool,
	dateChangedEventHandler: PropTypes.func,
	formValidationErrors: PropTypes.object,
	addEmptyValues: PropTypes.bool,
};

DateInput.defaultProps = {
	year: "",
	month: "",
	day: "",
	yearsFrom: new Date().getFullYear() - 100,
	yearsTo: new Date().getFullYear() - 18,
	className: null,
	forceValidation: false,
	dateChangedEventHandler: () => {},
	formValidationErrors: null,
	addEmptyValues: false,
};

export default DateInput;
