import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalWithdrawalComponents.scss";

const SubText = ({ children }) => <div styleName="styles.sub-text">{children}</div>;

SubText.propTypes = {
	children: PropTypes.node,
};

export default SubText;
