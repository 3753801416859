import {
	ColorGrey60,
	TypographyBs01FontSize,
	TypographyBs01FontWeight,
	TypographyBs01LineHeight,
	TypographyBs01FontFamily,
} from "@sermo/design-tokens";
import { Input, Radio, RadioGroup, useForm } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import getTranslation from "@translation/translation";

const Wrap = styled.div`
	padding-top: 8px;
	padding-bottom: 24px;
	&>div {
		padding-top: 16px;
		padding-bottom: 24px;
	}
`;
const bs01 = {
	fontSize: TypographyBs01FontSize,
	fontFamily: TypographyBs01FontFamily,
	lineHeight: TypographyBs01LineHeight,
	fontWeight: TypographyBs01FontWeight,
	color: ColorGrey60,
	opacity: 0.7,
}
const RadioLabel = styled.label({
	...bs01,
});
const StyledInput = styled(Input)({
	...bs01,
	"&>div": {
		marginTop: "8px",
	},
});

const PhoneNumber = props => {
	const {
		forceValidation,
		formValidationErrors,
		phoneNumberChangedEventHandler,
		value,
	} = props;

	const formConfig = {
		initialValues: {
			telephone: value ?? "",
			telephoneType: "mobileTelephone",
		},
		validationRules: {
			telephone: (value) => {
				if (value.trim().length > 0) {
					return [];
				}
				return [
					getTranslation("system.validationMessages.validationTelephoneNumberIsRequired", true),
				];
			},
		},
	};
	const form = useForm(formConfig);

	const validateForm = () => {
		let isValid = form.validate();

		if (phoneNumberChangedEventHandler) {
			if (isValid) {
				phoneNumberChangedEventHandler(form.getFieldValue("telephone"), form.getFieldValue("telephoneType"));
			} else {
				phoneNumberChangedEventHandler(false);
			}
		}

		return isValid;
	};

	const formChangeHandler = (field) => (event) => {
		form.setFieldValue(field, event.target.value);
		form.validateField(field);
	};

	useEffect(() => {
		if (forceValidation) {
			validateForm();
		}
	}, [forceValidation]);

	useEffect(() => {
		let hasErrors = false;
		if (formValidationErrors) {
			for (let property in formValidationErrors) {
				switch (property) {
					case "telephone":
						hasErrors = true;
						form.setFieldError("telephone", getTranslation(formValidationErrors[property][0], true));
						break;
					case "mobileTelephone":
						hasErrors = true;
						form.setFieldError("telephone", getTranslation(formValidationErrors[property][0], true));
						break;
				}
			}
		}

		if (!hasErrors) {
			form.resetErrors();
		}
	}, [formValidationErrors]);

	return (
		<Wrap>
			<RadioLabel htmlFor="telephoneType">Type</RadioLabel>
			<RadioGroup
				value={form.getFieldValue("telephoneType")}
				name="telephoneType"
				onChange={formChangeHandler("telephoneType")}
			>
				<Radio
					label={getTranslation("frontend.forms.adForm.label.mobilenumber", true)}
					value="mobileTelephone"
				/>
				<Radio
					label={getTranslation("frontend.forms.adForm.label.phonenumber", true)}
					value="telephone"
				/>
			</RadioGroup>
			<StyledInput
				errors={form.getFieldErrors("telephone")}
				label="Phone number"
				onChange={formChangeHandler("telephone")}
				value={form.getFieldValue("telephone")}
			/>
		</Wrap>
	);
};

PhoneNumber.propTypes = {
	className: PropTypes.string,
	forceValidation: PropTypes.bool,
	formValidationErrors: PropTypes.object,
	phoneNumberChangedEventHandler: PropTypes.func,
	value: PropTypes.string,
};

PhoneNumber.defaultProps = {
	className: null,
	forceValidation: false,
	formValidationErrors: null,
	phoneNumberChangedEventHandler: () => {},
	value: "",
};

export default PhoneNumber;
