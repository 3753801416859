import React from "react";
import { PollDurationTypes } from "@frontend/types/Post/poll";
// @ts-ignore
export const PollDefaultValues = {
    duration: PollDurationTypes.FOREVER,
    open: false,
    questions: [],
};
const PollContext = React.createContext(PollDefaultValues);
export default PollContext;
