// @ts-nocheck
import { Icon, CollapseSVG, ExpandSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import BasicVideoPlayer from "@components/Card/components/VideoPlayer/BasicVideoPlayer";
import SponsoredVideoPlayer from "@components/Card/components/VideoPlayer/SponsoredVideoPlayer";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import layout from "../../../../scss/layout.scss";
import typography from "../../../../scss/typography.scss";
import styles from "./Images.scss";

const InFeedVideoWrap = styled.div`
	margin-left: -16px;
	margin-right: -16px;
	display: block;
`;

const SeeMore = ({ index, attachments }) => {
	if (index === 2 && attachments.length > 3) {
		return (
			<div styleName={classNames("styles.see-more", "typography.heading-brand-06")}>
				+{attachments.length - 3}
			</div>
		);
	}
	return null;
};

SeeMore.propTypes = {
	index: PropTypes.number,
	attachment: PropTypes.array,
};

const ImagesDisplay = () => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		attachments,
		attachmentsView,
		cardType,
		displayOption,
	} = useMemoizedContext("postData", [
		"attachments",
		"attachmentsView",
		"cardType",
		"displayOption",
	]);

	const {
		questions: pollQuestions,
	} = useMemoizedContext("poll", [
		"questions",
	]);

	const [selectedImage, setSelectedImage] = useState(0);
	const [images, setImages] = useState([]);
	const [imageStyle, setImageStyle] = useState("");
	const videoEl = useRef();

	// Effect: Extract images from attachment
	useEffect(() => {
		const imgs = attachments.filter(attachment => {
			if (Object.prototype.hasOwnProperty.call(attachment,"featured") && attachment.featured === true) {
				return false;
			}
			return ["Image", "Mp4Video"].includes(attachment.type);
		});

		// Set images state
		setImages(imgs);
	}, [attachments]);

	// Effect: Set image wrapper style based on number of images
	useEffect(() => {
		if (images.length > 0) {
			switch (images.length) {
				case 1:
					// Special case as we need to differentiate between horizontal and vertical images
					const singleImg = new Image();
					// this isn't an arrow function for a reason
					singleImg.onload = function() {
						const { width, height } = this;
						if (width > height) {
							setImageStyle("styles.layout-horizontal");
						} else {
							setImageStyle("styles.layout-vertical");
						}
					};
					singleImg.src = images[0].url;
					break;
				case 2:
					setImageStyle("styles.layout-two");
					break;
				default:
					setImageStyle("styles.layout-three");
					break;
			}
		}
	}, [images]);

	// if its open close it else open whatever was clicked
	const selectImage = (num) => {
		// don't do this when expanding
		if (!expanded) {
			return;
		}
		if (num === selectedImage) {
			setSelectedImage(false);
		} else {
			setSelectedImage(num);
		}
	};

	const getLayout = () => {
		if (expanded) {
			return "styles.layout-expanded";
		}
	};

	const shouldReturnNullBasedOnCardType = React.useMemo(() => {
		if (expanded) {
			return false;
		}

		switch (cardType) {
			case CardTypeTypes.POST:
				return (
					pollQuestions?.length > 0
				);
			case CardTypeTypes.AD:
				return (
					attachmentsView
					&& (
						attachmentsView === "Poll"
						|| attachmentsView === "FeatureImage"
						|| attachmentsView === "FeatureImageAndISI"
					)
				);
			case CardTypeTypes.SERMO_CONTENT_CARD:
				return (
					displayOption
					&& (
						displayOption === "Poll"
						|| displayOption === "FeatureImage"
						|| displayOption === "None"
					)
				);
			default:
				return false;
		}
	}, [
		attachmentsView,
		cardType,
		displayOption,
		expanded,
		pollQuestions,
	]);

	if (
		!attachments?.length
		|| images.length === 0
		|| shouldReturnNullBasedOnCardType
	) {
		return null;
	}

	if (
		!expanded
		&& images.length === 1
		&& "Mp4Video" === images[0].type
	) {
		return (
			<InFeedVideoWrap>
				<SponsoredVideoPlayer featured={images[0]}/>
			</InFeedVideoWrap>
		)
	}

	return (
		<div styleName="layout.row-no-padding-no-margin">
			<div styleName={classNames("styles.images", getLayout(), imageStyle)}>
				{
					(!expanded || images.length > 1) && (
						<div styleName="styles.images-wrapper">
							{
								images.map((image, i) => {
									if (!expanded && i > 2) {
										return null;
									}
									return (
										<div
											styleName="styles.attachment-image-wrapper"
											key={i}
											onClick={
												() => {
													selectImage(i);
												}
											}
										>
											<img
												styleName="styles.attachment-image"
												src={image.thumbnail}
											/>
											{
												!expanded && <SeeMore
													index={i}
													attachments={images}
												/>
											}
											{
												expanded && (
													<div styleName="styles.expand-icon">
														<Icon
															src={
																selectedImage === i
																	? CollapseSVG
																	: ExpandSVG
															}
															width={18}
															height={18}
														/>
													</div>
												)
											}
										</div>
									);
								})
							}
						</div>
					)
				}
				{
					expanded && images.length > 0 && selectedImage !== false && (
						<div styleName="styles.expanded-image">
							{
								"Image" === images[selectedImage].type
								&& (
									<img
										alt={images[selectedImage].id}
										src={images[selectedImage].url}
									/>
								)
							}
							{
								"Mp4Video" === images[selectedImage].type && (
									<BasicVideoPlayer
										{...images[selectedImage]}
										autoplay={images[selectedImage].autoplayWhenExpanded}
										key={images[selectedImage].id}
										ref={videoEl}
										trackEventObject={{ videoId: images[selectedImage].id }}
									/>
								)
							}
						</div>
					)
				}
			</div>
		</div>
	);
};

export default ImagesDisplay;
