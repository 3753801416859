import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useThrottle, useWindowScroll } from "react-use";

// defaults speed is 30 fps
const ScrollWrapper = ({ limit, children }) => {
	const { y } = useWindowScroll();
	const throttledScrollY = useThrottle(y, limit);

	return useMemo(() => <>{React.cloneElement(children, { scrollY: throttledScrollY })}</>, [throttledScrollY, children]);
};

ScrollWrapper.propTypes = {
	limit: PropTypes.number,
	children: PropTypes.node,
};

ScrollWrapper.defaultProps = {
	limit: 1000 / 30,
};

export default ScrollWrapper;
