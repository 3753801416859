import { useCallback, useEffect, useRef } from "react";
import { UIActionTypes, EUIKey } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./Header.styles";
const Header = ({ children, }) => {
    const headerRef = useRef(null);
    const { dispatch: dispatchLayout, expandedPost, } = useMemoizedContext("ui", [
        "expandedPost",
    ]);
    const onHeaderResize = useCallback(() => {
        if (headerRef.current) {
            dispatchLayout({
                type: UIActionTypes.SET_HEADER_HEIGHT,
                payload: {
                    [EUIKey.HEADER_HEIGHT]: headerRef.current?.offsetHeight || 0,
                },
            });
        }
    }, [
        dispatchLayout,
        expandedPost,
    ]);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(onHeaderResize);
        if (headerRef.current) {
            resizeObserver.observe(headerRef.current);
        }
        return () => {
            if (headerRef.current) {
                resizeObserver.unobserve(headerRef.current);
            }
            else {
                resizeObserver.disconnect();
            }
        };
    }, [
        onHeaderResize,
    ]);
    return (<Styles.Header ref={headerRef} data-component={"Header"}>
			{children}
		</Styles.Header>);
};
export default Header;
