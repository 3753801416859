import { ProcessingSpinner } from "@sermo/ui-components";
import DiscussionEditor from "@components/PostEditor/components/DiscussionEditor";
import PatientCaseEditor from "@components/PostEditor/components/PatientCaseEditor";
import PostEditorAPISync from "@components/PostEditor/components/PostEditorAPISync";
import QuestionEditor from "@components/PostEditor/components/QuestionEditor";
import { AttachmentsProvider } from "@contexts/Attachments";
import { PollProvider } from "@contexts/Poll";
import PostDataProvider from "@contexts/PostData/PostDataProvider";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
const PostEditor = () => {
    const { type, } = useMemoizedContext("postData", [
        "type",
    ]);
    return (<>
			{PostTypeTypes.DISCUSSION === type && <DiscussionEditor />}
			{PostTypeTypes.PATIENT_CASE === type && <PatientCaseEditor />}
			{PostTypeTypes.QUESTION === type && <QuestionEditor />}
		</>);
};
const PostEditorWithContext = ({ publishCard, }) => {
    const { cardDraftData, } = useMemoizedContext("postUI", [
        "cardDraftData",
    ]);
    if (!cardDraftData) {
        console.error("No cardDraftData found in PostEditorWithContext");
        return <ProcessingSpinner height={32} width={32}/>;
    }
    return (<PostDataProvider cardData={cardDraftData} data-component={"PostEditor"} isEditing={true}>
			<PollProvider>
				<AttachmentsProvider>
					<PostEditor />
					<PostEditorAPISync publishCard={publishCard}/>
				</AttachmentsProvider>
			</PollProvider>
		</PostDataProvider>);
};
export default PostEditorWithContext;
