import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
export const TopLevelContainer = styled.div `
	--card-spacing: ${({ theme }) => theme.layout.cardsSpacing};
	border-radius: 8px;

	& > * {
		margin: var(--card-spacing);
	}

	${StyledMobileAndTabletPortraitQuery} {
		height: 100%;
		display: flex;
		flex-direction: column;
		
		.Frontend-components-NewPost-NewPost__mobile-editor-wrapper & {
			padding-top: 105px;
		}
	}
`;
export const MainContent = styled.div `
	${StyledMobileAndTabletPortraitQuery} {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
	}
`;
export const MarginBottom = styled.div `
	margin-bottom: var(--card-spacing);

	& > hr {
		border-top: 1px solid #ecedef;
	}
`;
export const NotFocusedLabel = styled(TextStyled).attrs({
    $kind: "bodyShort02",
}) `
	padding: 16px;
	color: #9fa3ac;
`;
