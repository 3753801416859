import {
	ColorGrey60,
	TypographyBl01FontFamily,
	TypographyBl01FontSize,
	TypographyBl01FontWeight,
	TypographyBl01LineHeight,
} from "@sermo/design-tokens";
import React from "react";
import styled from "styled-components";
import { settingsSection } from "@components/Settings/components/AccountSettings/sections";
import getTranslation from "@translation/translation";

const Wrap = styled.div`
	margin-top: 16px;
	color: ${ColorGrey60};
	font-family: ${TypographyBl01FontFamily};
	font-size: ${TypographyBl01FontSize};
	font-weight: ${TypographyBl01FontWeight};
	line-height: ${TypographyBl01LineHeight};
`;

const InfoChangeName = () => (
	<Wrap>
		{
			getTranslation(
				"frontend.modals.withdrawal.infoUpdateFullName",
				false,
				`/settings/accountsettings?edit=${settingsSection.FullName}`
			)
		}
	</Wrap>
);

export default InfoChangeName;
