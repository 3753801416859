import classNames from "classnames";
import React, { useState } from "react";
import Button from "@components/Button/Button";
import { ValidationMessage } from "@components/FormFields/FormFields";
import { useMemoizedContext, useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import { getUrlParameter } from "../../Utils";
import styles from "./AcceptTermsAndConditions.scss";

const AcceptTermsAndConditions = () => {
	const apiEndpoint = useApiEndpoint();
	const [declined, setDeclined] = useState(false);
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [loading, setLoading] = useState(false);
	const { updateMember } = useMemoizedContext("member", []);

	const handleAcceptClick = e => {
		setLoading(true);
		e.preventDefault();

		setGlobalValidationError("");

		apiEndpoint("settings/accepttermsandconditions", {}).then(dataResponse => {
			setLoading(false);
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					const returnUrl = getUrlParameter("returnUrl");

					if (returnUrl) {
						window.location = returnUrl;
					} else {
						updateMember({ hasAcceptedTermsAndConditions: true });
					}
				} else {
					setGlobalValidationError(body.message);
				}
			});
		});
	};

	const declineConfirmClickHandler = e => {
		setLoading(true);
		e.preventDefault();

		setGlobalValidationError("");

		apiEndpoint("settings/declinetermsandconditions", {}).then(dataResponse => {
			setLoading(false);
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					window.location = "/register/start?utm_campaign=sermoapp";
				} else {
					setGlobalValidationError(body.message);
				}
			});
		});
	};

	const declineClickHandler = e => {
		e.preventDefault();
		setDeclined(true);
	};

	const cancelDeclineClickHandler = e => {
		e.preventDefault();
		setDeclined(false);
	};

	if (declined) {
		return (
			<div styleName={classNames(["styles.accept-terms-and-conditions"])}>
				<div styleName={classNames(["styles.main-content"])}>
					<div styleName={classNames(["styles.content-box"])}>
						<div styleName={classNames(["styles.content-box-inner-container"])}>
							<div styleName={classNames(["styles.form-title", "typography.heading-brand-05"])}>
								{getTranslation("frontend.acceptTermsAndConditions.titleDecline")}
							</div>
							<div styleName={classNames(["styles.form-subtitle", "typography.body-long-brand-03"])}>
								{getTranslation("frontend.acceptTermsAndConditions.descriptionDecline")}
							</div>

							<form styleName={classNames(["styles.login-form"])}>
								<ValidationMessage text={globalValidationError} />

								<div styleName="styles.submit-container">
									<div styleName={classNames(["styles.cancel-button"])}>
										<Button
											name="cancel"
											style="flatNeutral"
											size="medium"
											contextClass="modal-cancel"
											theme="dark-link"
											clickHandler={cancelDeclineClickHandler}
										>
											{getTranslation("frontend.generics.back", true)}
										</Button>
									</div>
									<Button
										name="submit"
										size="medium"
										loading={loading}
										disabled={loading}
										clickHandler={declineConfirmClickHandler}
									>
										{getTranslation("frontend.acceptTermsAndConditions.buttonDeactivate")}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div styleName={classNames(["styles.accept-terms-and-conditions"])} data-component={"TermsAndConditionsForm"}>
				<div styleName={classNames(["styles.main-content"])}>
					<div styleName={classNames(["styles.content-box"])}>
						<div styleName={classNames(["styles.content-box-inner-container"])}>
							<div styleName={classNames(["styles.form-title", "typography.heading-brand-05"])} data-component={"Title"}>
								{getTranslation("frontend.acceptTermsAndConditions.title")}
							</div>
							<div styleName={classNames(["styles.form-subtitle", "typography.body-long-brand-03"])} data-component={"Description"}>
								{getTranslation("frontend.acceptTermsAndConditions.description")}
							</div>
							<form styleName={classNames(["styles.login-form"])}>
								<ValidationMessage text={globalValidationError} />

								<div styleName="styles.submit-container">
									<div styleName={classNames(["styles.cancel-button"])}>
										<Button
											name="cancel"
											style="flatNeutral"
											size="medium"
											contextClass="modal-cancel"
											theme="dark-link"
											clickHandler={declineClickHandler}
										>
											{getTranslation("frontend.acceptTermsAndConditions.buttonDecline", true)}
										</Button>
									</div>
									<Button
										name="submit"
										size="medium"
										loading={loading}
										disabled={loading}
										clickHandler={handleAcceptClick}
									>
										{getTranslation("frontend.acceptTermsAndConditions.buttonAccept")}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default AcceptTermsAndConditions;
