import {
	ClockIcon,
	Icon,
	PlusIcon,
} from "@sermo/ui-components";
import React from "react";
import ParentContextUpdater from "@components//ParentContextUpdater";
import Button from "@components/Button/Button";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import PollQuestionEdit, { PollQuestionEditAPISync } from "@components/PostEditor/components/PollEdit/components/PollQuestionEdit";
import { PollActionTypes } from "@contexts/Poll";
import { PollQuestionContext, PollQuestionProvider } from "@contexts/PollQuestion";
import { PostTypeTypes, PostSubTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./PollEdit.styles";
import usePollAPI from "./usePollAPI";

const PollEdit = () => {
	const pollAPI = usePollAPI();

	const {
		duration,
		questions,
	} = useMemoizedContext("poll", [
		"duration",
		"questions",
	]);

	const {
		draftStatus,
	} = useMemoizedContext("postUI", [
		"draftStatus",
	]);

	const {
		poll,
		subType,
		type,
	} = useMemoizedContext("postData",[
		"poll",
		"subType",
		"type",
	]);

	if (
		!poll
		|| (
			PostTypeTypes.QUESTION === type
			&& PostSubTypeTypes.POLL !== subType
		)
		|| DraftStatusTypes.PUBLISHED === draftStatus
		|| !questions?.length
	) {
		return null;
	}

	const handleAddQuestion = () => {
		pollAPI.addQuestion();
	};

	const handleChangePollDuration = (e, duration) => {
		pollAPI.updatePollDuration({ duration });
	};

	const handlePollRemove = () => {
		pollAPI.deletePoll();
	};

	const durationOptions = [
		{
			value: "Forever",
			name: getTranslation("frontend.poll.lengths.forever", true),
			label: getTranslation("frontend.poll.lengths.forever", true),
		},
		{
			value: "Day",
			name: getTranslation("frontend.poll.lengths.oneDay", true),
			label: getTranslation("frontend.poll.lengths.oneDay", true),
		},
		{
			value: "Week",
			name: getTranslation("frontend.poll.lengths.oneWeek", true),
			label: getTranslation("frontend.poll.lengths.oneWeek", true),
		},
		{
			value: "Month",
			name: getTranslation("frontend.poll.lengths.oneMonth", true),
			label: getTranslation("frontend.poll.lengths.oneMonth", true),
		},
	];

	return (
		<Styles.PollEditContainer>
			<ModuleWrapper
				solid={true}
				borderDarker={true}
				nested={true}
			>
				<Styles.QuestionTypeRow>
					<Styles.ClockIcon
						src={ClockIcon}
						width={24}
						height={24}
					/>
					<Styles.CustomSelect
						autoWidth
						name="question-type"
						onChange={handleChangePollDuration}
						options={durationOptions}
						value={duration || "Forever"}
						$displayWidth="140px"
					/>
					{
						"Question" !== type && (
							<Styles.PollRemoveClick onClick={handlePollRemove}>
								{getTranslation("frontend.poll.removePoll")}
							</Styles.PollRemoveClick>
						)
					}
				</Styles.QuestionTypeRow>
				{
					questions?.map((question, i) => (
						<PollQuestionProvider
							key={i}
							question={question}
						>
							<ParentContextUpdater
								actionType={PollActionTypes.SYNC_QUESTION}
								childContext={PollQuestionContext}
								parentContext="poll"
								payloadKey="question"
							/>
							<PollQuestionEditAPISync />
							<PollQuestionEdit index={i} />
						</PollQuestionProvider>
					))
				}
				{
					"Question" !== type && questions.length < 5 && (
						<Styles.AddQuestionButtonContainer>
							<Button
								clickHandler={handleAddQuestion}
								icon={<Icon src={PlusIcon} />}
								labelPosition="right"
								size="small"
								style="dashoutline"
							>
                                Add another question
							</Button>
						</Styles.AddQuestionButtonContainer>
					)
				}
			</ModuleWrapper>
		</Styles.PollEditContainer>
	);
};

export default PollEdit;