export const truncate = (str = "", length) => {
    if (str.length > length) {
        return `${str.substring(0, length)}...`;
    }
    return str;
};
export const formatLocaleDate = (locale, dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};
