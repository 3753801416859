import { Icon, StarOfLifeSVG, HashTagSVG } from "@sermo/ui-components";
import React from "react";
import { useParams } from "react-router-dom";
import Button from "@components/Button/Button";
import { Loading } from "@components/Helpers/Helpers";
import Menu from "@components/Menu/Menu";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useFetch, useGetSearchParamsObject } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const FollowMenu = () => {
    const { adId } = useParams();
    const { memberId, segmentId } = useGetSearchParamsObject();
    const [data, loading] = useFetch("api/creativepreview/loadcreativepreviewfeed", { adId: parseInt(adId), memberId, segmentId });
    if (loading) {
        return <Loading contextClass={""}/>;
    }
    return (<ModuleWrapper padded={false} label={getTranslation("frontend.followingMenu.label", true)} contextClass="follow-menu">
			<Menu contextClass="left-rail" inline={true}>
				{data.followedTopics?.map((option, i) => {
            const { name, slug, type } = option;
            const icon = type === "Specialty"
                ? StarOfLifeSVG
                : HashTagSVG;
            return (<Button key={i} to={`/feed/topic/${slug}`} labelPosition="right" icon={<Icon src={icon}/>} contextClass="left-rail" theme="dark" style="flatNeutral" size="small" alignment="start" exact={true} tracking={{
                    category: "left-navigation-feed",
                    action: "click",
                    label: "feed-" + slug,
                }}>
								{name}
							</Button>);
        })}
			</Menu>
		</ModuleWrapper>);
};
export default FollowMenu;
