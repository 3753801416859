import PropTypes from "prop-types";
import React, { useState } from "react";
import { DropDownList } from "@components/FormFields/FormFields";
import { Loading } from "@components/Helpers/Helpers";
import { useTrackEvent } from "@frontend/tracking";
import { useFetch } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalFooter } from "./ModalComponents";

const ActualModal = ({ closeModalEventHandler, topics, topicChange }) => {
	const trackEvent = useTrackEvent();
	const [currentTopic, setCurrentTopic] = useState(false);

	let selectedTopic = 0;
	if (currentTopic) {
		selectedTopic = currentTopic;
	} else {
		let selected = topics.find((topic) => topic.selected);

		if (selected) {
			selectedTopic = selected.id;
		}
	}

	const findTopicSlugNameFromId = (id) => (
		topics.find((topic) => id === topic.id)?.slug || id
	);

	const onChange = (e) => {
		trackEvent({
			category: "suggested-post",
			label: "change-tag-selection",
			action: `click-${findTopicSlugNameFromId(+e.target.value)}`,
			topicId: +e.target.value
		});
		setCurrentTopic(e.target.value);
	};

	const changeTopic = () => {
		trackEvent({
			category: "suggested-post",
			label: "wrong-tag-update",
			action: "click",
		});

		const topicObject = topics.find((topic) => topic.id === +currentTopic);

		topicChange(topicObject);
	};

	const cancelChange = () => {
		trackEvent({
			category: "suggested-post",
			label: "wrong-tag-cancel",
			action: "click",
		});

		closeModalEventHandler();
	};

	return (
		<ModalContainer>
			<ModalText>{getTranslation("frontend.modals.changeTag.copy", true)}</ModalText>

			<ModalSection>
				<DropDownList
					name="report reason"
					handleChange={onChange}
					placeholder={(getTranslation("frontend.modal.changeTag.selectATopic"), true)}
					value={selectedTopic}
					options={
						topics.map((topic) => {
							return {
								name: topic.name,
								value: topic.id,
							};
						})
					}
				/>
			</ModalSection>

			<ModalFooter
				{...{
					cancelModalEventHandler: cancelChange,
					submitEventHandler: changeTopic,
					submitText: getTranslation("frontend.generics.update", true),
					submitDisabled: !currentTopic,
				}}
			/>
		</ModalContainer>
	);
};

ActualModal.propTypes = {
	topics: PropTypes.array,
	topicChange: PropTypes.func,
	closeModalEventHandler: PropTypes.func,
};

const ModalWithData = ({ closeModalEventHandler, topicChange, postId }) => {
	const [data, loading] = useFetch("api/posts/gettopics", { postId });

	return (
		<>
			{loading && <Loading />}
			{
				!loading && (
					<ActualModal
						topics={data.topics}
						closeModalEventHandler={closeModalEventHandler}
						topicChange={topicChange}
					/>
				)
			}
		</>
	);
};

ModalWithData.propTypes = {
	postId: PropTypes.number,
	topicChange: PropTypes.func,
	closeModalEventHandler: PropTypes.func,
};

const TagChangeModal = ({ closeModalEventHandler, topics, topicChange, postId }) => {
	if (topics) {
		return (
			<ActualModal
				topics={topics}
				closeModalEventHandler={closeModalEventHandler}
				topicChange={topicChange}
			/>
		);
	}

	return <ModalWithData
		closeModalEventHandler={closeModalEventHandler}
		topicChange={topicChange}
		postId={postId}
	/>;
};

TagChangeModal.propTypes = {
	postId: PropTypes.number,
	topics: PropTypes.array,
	topicChange: PropTypes.func,
	closeModalEventHandler: PropTypes.func,
};

TagChangeModal.defaultProps = {
	topics: null,
	topicChange: () => {},
	closeModalEventHandler: () => {},
};

export default TagChangeModal;
