import { ColorGrey00 } from "@sermo/design-tokens";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorder } from "@styled/applyBorder";

const itemSize = 230;

export const Carousel = styled.div`
    overflow: hidden;
    position: relative;

	${StyledMobileAndTabletPortraitQuery} {
		overflow: auto;
	}

	.button {
		position: absolute;
		top: 100px;

		&.left {
			left:4px;
		}

		&.right {
			right: 4px;
		}
	}

	.item {
		width: ${itemSize}px;
		margin: 0 4px;
		display: flex;
		flex-direction: column;
		transition: transform 0.2s;
		will-change: transform;

		.transition-disabled & {
			transition: none;
		}

		&:first-of-type {
			margin-left: 16px;

			${StyledMobileAndTabletPortraitQuery} {
				margin-left: 0;
			}
		}

		.image {
			width: ${itemSize}px;
			height: ${itemSize}px;
			overflow: hidden;
			flex-shrink: 0;

			img {
				height: ${itemSize}px;
				width: ${itemSize}px;
				object-fit: cover;
			}
		}
		.footer {
			${applyBorder("right bottom left")}
			height: 100%;
			padding: 8px;
			display: flex;
			align-items: center;
		}

		.footer-content-container {
			flex-direction: row;
		}

		.headline {
			/* z legacy/legacy-heading-00 */
			font-family: Graphik;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 133.333% */
		}

		.button-container {
			margin-left: 16px;
		}
	}

	.footer-padding-top {
		padding-top: 12px;

		${StyledMobileAndTabletPortraitQuery} {
			padding-top: 24px;
		}
	}
`;

export const Wrapper = styled.div.attrs(props => props)`
	display: flex;
	padding-bottom: var(--cards-spacing);
	overflow: ${props => props.$isPointerFine
		? "hidden"
		: "auto"};
`;
