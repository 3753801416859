import { ColorGrey50, ColorUltraviolet10, ColorUltraviolet30, ColorUltraviolet50 } from "@sermo/design-tokens";
import { Alert, ToggleButtonGroup } from "@sermo/ui-components";
import React from "react";
import styled from "styled-components";

export const TagToggleGroup = styled(ToggleButtonGroup)`
	&& {
		& > button {
		  padding: 1px 8px 2px 8px;
          width: auto;
          margin-left: 8px;
			& > span {
				font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
				font-size: 12px;
				line-height: 20px;
				font-weight: 500;
			}
			&:first-child {
				margin-left: 0;
			}
        }
    }
`;

export const SermoAlert = styled(Alert)`
	&& {
		margin-top: -8px;
		margin-bottom: 16px;
		background-color: ${ColorUltraviolet10};
		> div:first-child {
			background: linear-gradient(0deg, ${ColorUltraviolet50} 0%, ${ColorUltraviolet30} 100%);
		}
    }
`;

export const TopicSelectionContainer = styled.div`
	margin-top: 24px;
	
	.headLine {
		display: flex;
	}
	
	.editTags {
		display: flex;
		align-items: center;
		margin-top: 16px;
		&:empty {
			display: none;
		}
		& > * {
			display: inline-block;
		}
		.tags {
			margin-right: 16px;
			& > span {
				margin-right: 8px;
			}
		}
	}
	
	.infoIcon {
		margin-left: 5px;
		display: flex;
		align-items: center;
		color: ${ColorGrey50}
	}
`;

export const OtherContainer = styled.div`
	width: 232px;
	margin-top: 16px;
`;
