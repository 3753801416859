import { ColorGrey60 } from "@sermo/design-tokens";
import { Icon, EmojiSVG } from "@sermo/ui-components";
import classNames from "classnames";
import emoji from "emoji.json";
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import getTranslation from "@translation/translation";
import styles from "./EmojiMenu.scss";

const EmojiIconStyled = styled(Icon)`
	fill: ${ColorGrey60}
`;

// assync break emojis into categories for easier use
// TODO: make this a build script rather than running it on the users machine
let categories = {};

const getCategories = async () => {
	const categories = emoji.reduce((filtered, emoj, i) => {
		if (i === 0) {
			filtered[emoj.category] = [emoj];
		} else {
			// leave out components for now
			if ("Component" !== emoj.category) {
				if (emoj.category !== emoji[i - 1].category) {
					filtered[emoj.category] = [emoj];
				} else {
					filtered[emoj.category].push(emoj);
				}
			}
		}

		return filtered;
	}, {});

	return categories;
};

getCategories().then((response) => {
	categories = response;
});

const Menu = ({ position, inlineStyles, clickHandler }) => {
	const onClick = (char) => {
		clickHandler(char);
	};

	const [currentCategory, setCurrentCategory] = useState();

	useEffect(() => {
		setCurrentCategory(Object.keys(categories)[0]);
	}, [categories]);

	const selectCategory = (category) => {
		setCurrentCategory(category);
	};

	return (
		<div
			styleName={classNames("styles.menu", `styles.${position}`)}
			style={{ ...inlineStyles }}
		>
			{!currentCategory && <span>{getTranslation("frontend.modals.generic.loading")}</span>}
			{
				currentCategory && (
					<ModuleWrapper
						solid={true}
						border={true}
						contextClass="emoji-icons"
					>
						<ModuleWrapper padded={false}>
							<div styleName="styles.category-buttons">
								{
									Object.keys(categories).map((category, i) => (
										<Button
											key={i}
											size="medium"
											style="tab"
											contextClass="emoji-icon-tab"
											active={category === currentCategory}
											clickHandler={
												() => {
													selectCategory(category);
												}
											}
										>
											<div styleName="styles.tab">
												{categories[Object.keys(categories)[i]][0].char}
											</div>
										</Button>
									))
								}
							</div>
						</ModuleWrapper>
						<div styleName="styles.emojis">
							{
								categories[currentCategory].map((emoj, i) => (
									<div
										styleName="styles.emoji"
										key={i}
									>
										<Button
											style="icon"
											size="small"
											clickHandler={() => onClick(emoj.char)}
										>
											<div styleName="styles.emoji-inner">{emoj.char}</div>
										</Button>
									</div>
								))
							}
						</div>
					</ModuleWrapper>
				)
			}
		</div>
	);
};

Menu.propTypes = {
	position: PropTypes.string,
	inlineStyles: PropTypes.object,
	clickHandler: PropTypes.func,
};

Menu.defaultProps = {
	position: "top",
	inlineStyles: {},
	clickHandler: () => {},
};

const EmojiMenu = ({ clickHandler, disabled }) => {
	const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const [menuOpen, setMenuOpen] = useState(false);
	const menuEl = useRef(null);

	const onClick = () => {
		setMenuOpen(!menuOpen);
	};

	const onEmojiClick = (char) => {
		clickHandler(char);
		setMenuOpen(false);
	};

	const getPos = () => {
		if (menuEl.current) {
			if ("undefined" !== typeof window && menuEl.current.getBoundingClientRect().top < window.innerHeight / 2) {
				return "bottom";
			}
			return "top";
		}

		return "top";
	};

	const getHorizontal = () => {
		if (menuEl.current) {
			if ("undefined" !== typeof window) {
				if ( isMobileOrTabletPortrait ) {	
					return { right: `-${window.innerWidth - menuEl.current.getBoundingClientRect().right}px` };
				}
				if ( menuEl.current.getBoundingClientRect().left < window.innerWidth / 2 ) {
					return { 
						left: 0,
						right: "auto",
					};
				} else {
					return {
						right: 0,
						left: "auto",
					};
				}
			}
		}

		return {};
	};

	return (
		<div
			styleName={classNames("styles.emoji-menu")}
			ref={menuEl}
		>
			<MobileAndTabletPortrait>
				{
					menuOpen && <Menu
						position={getPos()}
						inlineStyles={getHorizontal()}
						clickHandler={onEmojiClick}
					/>
				}
			</MobileAndTabletPortrait>
			<TabletLandscapeAndDesktop>
				{
					menuOpen && <Menu
						position={getPos()}
						inlineStyles={getHorizontal()}
						clickHandler={onEmojiClick}
					/>
				}
			</TabletLandscapeAndDesktop>
			<Button
				style="icon"
				size="large"
				clickHandler={onClick}
				disabled={disabled}
			>
				<EmojiIconStyled
					src={EmojiSVG}
					width={24}
					height={24}
				/>
			</Button>
		</div>
	);
};

EmojiMenu.propTypes = {
	clickHandler: PropTypes.func,
	disabled: PropTypes.bool,
};

EmojiMenu.defaultProps = {
	clickHandler: () => {},
	disabled: false,
};

export default EmojiMenu;
