import { ColorGrey60 } from "@sermo/design-tokens";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery, } from "@components/MediaQueries/MediaQueries";
import { applyBorder, applyBorderDarker, applyModuleTop, applyShadow, applyZIndex, } from "@frontend/styled";
export const Container = styled.div `
	${({ $customPadding }) => $customPadding && `
		&&& {
			padding: ${$customPadding};
		}
	`};

	&.module-wrapper {
		width: auto;
		border-radius: ${p => p.theme.layout.moduleWrapperBorderRadius};

		${StyledMobileAndTabletPortraitQuery} {
			border-radius: 0;
		}
	}

	&.border {
		${applyBorder("top right bottom left")};

		${StyledMobileAndTabletPortraitQuery} {
			border-left: 0;
			border-right: 0;
		}
	}

	&.border-darker {
		${applyBorderDarker("top right bottom left")};

		${StyledMobileAndTabletPortraitQuery} {
			border-left: 0;
			border-right: 0;
		}
	}

	&.solid {
		background-color: ${p => p.theme.color.uiBackground};
	}

	&.solid.nested {
		background-color: ${p => p.theme.color.uiBackgroundSecondary};
	}

	&.padded {
		padding: 16px;
	}

	&.padded-wider {
		padding-left: 32px;
		padding-right: 32px;

		${StyledMobileAndTabletPortraitQuery} {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&.not-padded-x {
		padding-left: 0;
		padding-right: 0;
	}

	&.not-padded-y {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.padded-widest {
		padding-left: 64px;
		padding-right: 64px;

		${StyledMobileAndTabletPortraitQuery} {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&.not-padded-y {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.not-padded-x {
		padding-left: 0;
		padding-right: 0;
	}

	&.context-mobile-header {
		width: 100%;
		background-color: ${p => p.theme.color.uiBackground};
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	}

	&.context-mobile-settings-profile-preview {
		margin-bottom: 16px;
	}

	&.context-feed-menu-mobile,
	&.context-top-nav,
	&.context-profile-menu,
	&.context-card-header-menu,
	&.context-card-header-menu-footer {
		position: absolute;
		top: calc(100% + 1px);
		right: 0;
		min-width: 300px;
		${applyShadow("01")};
		${applyZIndex("menu")};

		${StyledMobileAndTabletPortraitQuery} {
			max-width: calc(100vw - (${p => p.theme.layout.cardsPaddingMobile} * 2));
			// TODO: refactor this hacky fix to weird css issue from 6 months ago
			// NOT ALL MODULE WRAPPERS ARE CARDS!!
			${applyBorder("left right")};
		}
	}

	&.context-feed-menu-mobile {
		max-height: calc(100vh - 170px);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		left: 0;
		right: auto;
		width: auto;
	}

	&.context-custom-dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 128px;
		white-space: nowrap;
		display: flex;
		flex-direction: column;
		${applyZIndex("menu")};
		${applyShadow("03")};

		${StyledMobileAndTabletPortraitQuery} {
			max-height: calc(100vh - 170px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			min-width: auto;
			left: 0;
			right: auto;
			width: calc(100vw - 40px);
		}
	}

	&.context-custom-dropdown-flat-padded {
		position: absolute;
		top: calc(100% + 5px);
		min-width: 128px;
		display: flex;
		flex-direction: column;
		left: 0;

		box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.24);
		border: 1px solid ${p => p.theme.color.uiBorder01};
		overflow: hidden;
		padding-top: 8px;
		padding-bottom: 8px;

		${applyZIndex("menu")};

		${StyledMobileAndTabletPortraitQuery} {
			max-height: calc(100vh - 170px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			min-width: auto;
			left: 0;
			right: auto;
			width: calc(100vw - 50px);
		}
	}

	&.context-profile-menu {
		min-width: 180px;
		padding: 4px 0;
	}

	&.context-comments-reply {
		flex-grow: 1;
	}

	&.context-top-nav {
		border-radius: ${p => p.theme.layout.borderRadius};
		overflow: hidden;
	}

	&.context-messages-header {
		${applyShadow("01")};

		position: absolute;
		top: 100%;
		right: 0;

		${StyledMobileAndTabletPortraitQuery} {
			${applyShadow("03")};
			position: fixed;
			top: calc(${p => p.theme.layout.topNavHeightMobile} + 70px);
			left: 0;
			right: 0;
		}
	}

	&.context-comment-menu {
		position: absolute;
		top: 100%;
		left: 0;
		${applyZIndex("modal")};
	}

	&.context-card-header-menu {
		max-width: 92vw;
		min-width: 300px;
		text-align: left;
	}

	&.context-card-wrapper,
	&.context-card-wrapper-single {
		min-height: 100%;
	}

	&.context-card-wrapper-single {
		border: 1px solid ${p => p.theme.color.interactive01};
		overflow: visible;
	}

	&.context-modal {
		padding: calc(24px + 16px + 20px) 24px 24px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		${StyledMobileAndTabletPortraitQuery} {
			-webkit-overflow-scrolling: touch;
			border-radius: 0;
			padding: calc(24px + 16px + 20px) 16px 79px;
		}
	}

	&.context-modal-with-icon {
		padding: 16px 24px 24px 24px;
	}

	&.context-modal-without-title {
		padding: 24px;

		${StyledMobileAndTabletPortraitQuery} {
			padding: 24px 16px 16px;
		}
	}

	&.context-modal-new-message {
		padding: calc(24px + 16px + 20px) 24px 0;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		${StyledMobileAndTabletPortraitQuery} {
			-webkit-overflow-scrolling: touch;
			border-radius: 0;
			padding: calc(24px + 16px + 20px) 0 16px;
		}
	}

	&.context-modal-information-darker {
		padding: calc(24px + 16px + 20px) 24px 24px;

		${StyledMobileAndTabletPortraitQuery} {
			border-radius: 0;
		}
	}

	&.context-modal-browseTopics {
		padding: 40px 0 24px 0;

		${StyledMobileAndTabletPortraitQuery} {
			padding: 40px 16px 0;
			border-radius: 0;
		}
	}

	&.context-left-rail,
	&.context-right-rail {
		margin-bottom: 8px;

		${StyledMobileAndTabletPortraitQuery} {
			border: none;
		}

		header {
			margin: 0 0 8px 0;
		}
	}

	&.available-surveys {
		padding: 0;
	}

	&.context-new-post-draft {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.context-new-post-editor {
		padding: 0;
	}

	&.context-feed-info {
		padding: 0;
	}

	&.context-rail-default {
		${applyModuleTop("default")};
		margin-bottom: 16px;

		${StyledMobileAndTabletPortraitQuery} {
			margin-bottom: 4px;
		}
	}

	&.context-rail-module-warning {
		${applyModuleTop("warning")};
		margin-bottom: 16px;

		${StyledMobileAndTabletPortraitQuery} {
			margin-bottom: 4px;
		}
	}

	&.context-rail-module-alert {
		${applyModuleTop("alert")};
		margin-bottom: 16px;

		${StyledMobileAndTabletPortraitQuery} {
			margin-bottom: 4px;
		}
	}

	&.context-alert {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 8px 8px 20px;
		position: relative;

		&:before {
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: -1px;
			content: "";
			background: linear-gradient(0, #852dff 0%, #bb59ff 100%);
			border-radius: 4px 0 0 4px;
			width: 8px;
		}
	}

	&.context-mini-profile {
		padding: 0 16px 0 0;
		position: relative;
		min-width: 280px;

		${StyledMobileAndTabletPortraitQuery} {
			max-width: 100%;
			min-height: auto;
			padding-right: 0;
		}
	}

	&.context-micro-profile {
		border-top: none;
		padding-top: 10px;
	}

	&.context-mini-profile-menu {
		position: absolute;
		right: 100%;
		top: 50%;
		transform: translateY(-50%);
		${applyShadow("01")};
	}

	&.context-comment-reply {
		display: flex;
		align-items: stretch;
	}

	&.context-rail-card--mdhonors-teaser {
		padding-left: 0;
		padding-right: 0;
	}

	&.context-feed-menu,
	&.context-follow-menu {
		padding-top: 21px;
	}

	&.context-follow-menu {
		& > header {
			margin: 0 0 8px 8px;

			${StyledMobileAndTabletPortraitQuery} {
				margin: 0 0 8px 12px;
			}
		}
	}

	&.context-emoji-icons {
		${applyBorderDarker("top right bottom left")};

		${StyledMobileAndTabletPortraitQuery} {
			padding: 8px;
		}
	}

	&.context-footer {
		padding-top: 0;
	}

	&.context-settings-menu {
		padding: 16px 0 0 0;
	}

	&.context-surveys-menu {
	}

	&.context-settings {
		padding: 32px;

		${StyledMobileAndTabletPortraitQuery} {
			padding: 16px;
		}
	}

	&.context-post-creation-footer {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&.context-mobile-survey-dropdown {
		${applyZIndex("menu")};
		position: absolute;
		top: 100%;
		left: 12px;
		width: auto;
		white-space: nowrap;
		${applyShadow("01")};
		${applyBorder("top right bottom left")};
		padding: 8px 24px 8px 8px;
	}

	&.context-post-create-type-comment,
	&.context-post-create-type-question,
	&.context-post-create-type-patientcase,
	&.context-post-create-type-discussion,
	&.context-post-create-type-drugrating,
	&.context-post-create-type-indication,
	&.context-post-create-type-ad-preview,
	&.context-post-create-type-polloftheweek {
		${StyledMobileAndTabletPortraitQuery} {
			flex-grow: 1;
			margin-top: 105px;
		}
	}

	&.context-survey-card {
		${StyledMobileAndTabletPortraitQuery} {
			border: 0;
		}
	}

	&.context-survey-rail-card {
		margin-top: 4px;

		& > div:first-child {
			margin: 0 0 8px 0;
		}
	}

	&.context-covid19-section,
	&.context-events-section {
		padding: 24px 48px;
		margin-bottom: ${p => p.theme.layout.cardsSpacing};
		display: flex;
		flex-direction: column;

		${StyledMobileAndTabletPortraitQuery} {
			padding: 24px 16px;
		}
	}

	&.context-covid19-nav-collapsed {
		padding: 12px 48px;
	}

	&.context-covid19-nav {
		padding: 30px 48px 40px;
		margin-bottom: ${p => p.theme.layout.cardsSpacing};

		${StyledMobileAndTabletPortraitQuery} {
			padding: 40px 16px;
		}
	}

	&.context-covid19-header {
		margin-bottom: ${p => p.theme.layout.cardsSpacing};
	}

	&.context-register {
		width: auto;
		display: inline-flex;
		flex-direction: column;

		${StyledMobileAndTabletPortraitQuery} {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
	}

	&.context-comment-editor-footer {
		border-radius: 0 0 4px 4px;
	}

	&.context-modal-iframe {
		padding: ${p => p.theme.layout.alertModalHeight} 0 0 0;
		background-color: ${p => p.theme.color.ui05};
		overflow: hidden;
		border: none;

		${StyledTabletLandscapeAndDesktopQuery} {
			height: 100%; //calc(100vh - (#{$top-nav-height * 2}) - 20px);
			width: 100%;
			max-width: ${p => p.theme.layout.siteMaxWidth};
			border-radius: 0;
		}

		${StyledMobileAndTabletPortraitQuery} {
			height: 100vh;
		}
	}

	&.context-modal-onboarding {
		padding: 0 0 24px 0;
		border: none;
		> div.x {
			background-color: transparent;
			color: ${ColorGrey60};
		}

		${StyledMobileAndTabletPortraitQuery} {
			overflow-y: auto;
			padding: 0;
		}
	}
`;
