import React from "react";
import ParentContextUpdater from "@components/ParentContextUpdater";
import AttachmentsContext, { AttachmentsDefaultValues } from "@contexts/Attachments/AttachmentsContext";
import AttachmentsReducer from "@contexts/Attachments/AttachmentsReducer";
import { CommentActionTypes } from "@contexts/Comment";
import { PostDataActionTypes } from "@contexts/PostData";
import { useMemoizedContext } from "@hooks/Hooks";
const AttachmentsProvider = ({ children, attachments = [] }) => {
    const initializeAttachments = React.useCallback((attachments) => {
        return {
            ...AttachmentsDefaultValues,
            attachments: [...attachments],
        };
    }, []);
    const [state, dispatch] = React.useReducer(AttachmentsReducer, attachments, initializeAttachments);
    const value = {
        ...state,
        dispatch,
    };
    return (<AttachmentsContext.Provider value={value}>
			{children}
		</AttachmentsContext.Provider>);
};
const AttachmentsProviderWithContext = ({ children, parentContext = "postData", }) => {
    const { attachments, } = useMemoizedContext(parentContext, [
        "attachments",
    ]);
    const contextActionTypes = parentContext === "comment"
        ? CommentActionTypes
        : PostDataActionTypes;
    return (<AttachmentsProvider attachments={attachments}>
			<ParentContextUpdater actionType={contextActionTypes.SYNC_ATTACHMENTS} childContext={AttachmentsContext} parentContext={parentContext} payloadKey="context"/>
			{children}
		</AttachmentsProvider>);
};
export default AttachmentsProviderWithContext;
