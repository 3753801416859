import { ColorGrey10, ColorWhite } from "@sermo/design-tokens";
import { Icon, onMobileOrTabletPortrait, AttentionSVG, AttachmentImageSVG } from "@sermo/ui-components";
import classNames from "classnames";
import isMobile from "ismobilejs";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import styled, { css } from "styled-components";
import Button from "@components/Button/Button";
import { Editor, deserialize, serialize, emptyValue } from "@components/Editor";
import { FileUpload } from "@components/FormFields/FormFields";
import { Loading } from "@components/Helpers/Helpers";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import { MentionMemberOption, serializeMember, toOptions, useMentionMember } from "@components/MentionMember";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import AttachmentManager from "@components/PostEditor/components/AttachmentManager";
import { AttachmentsActionTypes, AttachmentsProvider } from "@contexts/Attachments";
import useAttachmentAPI from "@contexts/Attachments/useAttachmentAPI";
import { CommentActionTypes, useCommentAPI } from "@contexts/Comment";
import { useTrackEvent } from "@frontend/tracking";
import { useGetPostTrackingCategory, useMemoizedContext, usePrevious } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import layout from "../../../../scss/layout.scss";
import modalStyles from "../../../Modal/Modal.scss";
import styles from "./CommentEditor.scss";

const EditorStyled = styled(Editor)`
  ${() => isMobile(navigator.userAgent).any && css`
		max-height: 35dvh;
		overflow-x: hidden;
		overflow-y: auto;
	`};
`;

const NudgeAlert = styled.div`
	padding: 8px 16px;
	border-top: 1px solid ${ColorGrey10};
	background-color: ${ColorWhite};
	display: flex;
	align-items: center;
	color: #404659;
	svg {
		margin-right: 8px;
	}
	svg > g {
		transform: scale(1.33);
		circle, polygon {
			fill: #404659;
		}
		circle:first-of-type {
			fill: #ffba00;
		}
	}
	${onMobileOrTabletPortrait(`
		padding: 8px;
		svg {
			align-self: start;
		}
	`)}
`;

const CommentEditorFooter = ({
	addComment,
	images,
	canSubmit,
	cancel,
	editing,
	updateComment,
	nudge,
}) => {
	const attachmentAPI = useAttachmentAPI();
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isPreviewRoute = !!useMatch("/preview/*");
	const imageUploadRef = useRef(null);

	const openImageUploadDialog = () => {
		imageUploadRef.current.click();
	};

	const onImageChange = (e) => {
		attachmentAPI.uploadFile(e.target.files[0], "Comment");
	};

	return (
		<ModuleWrapper
			nested={true}
			solid={true}
			padded={false}
			contextClass="comment-editor-footer"
		>
			{
				nudge && <NudgeAlert className={"body-long-00"}>
					<Icon
						src={AttentionSVG}
					/>{getTranslation("frontend.comments.nudgeAlert")}
				</NudgeAlert>
			}
			<div styleName="styles.comment-editor-footer">
				<div styleName="styles.footer-buttons">
					{
						cancel && (
							<div styleName="styles.cancel-button">
								<Button
									size="medium"
									style="flatNeutral"
									theme="light"
									clickHandler={cancel}
								>
									{getTranslation("frontend.generics.cancel")}
								</Button>
							</div>
						)
					}
					{
						!editing && (
							<Button
								size="medium"
								style="primary"
								theme="light"
								clickHandler={addComment}
								disabled={!canSubmit}
								rightsRequired={
									isAdPreview || isPreviewRoute
										? []
										: ["canCommentOnPostsInFrontend"]
								}
							>
								{
									getTranslation(nudge
										? "frontend.comments.yesComment"
										: "frontend.comments.comment")
								}
							</Button>
						)
					}
					{
						editing && (
							<Button
								size="medium"
								style="primary"
								theme="light"
								clickHandler={updateComment}
								disabled={!canSubmit}
							>
								{getTranslation("frontend.comments.saveEdits")}
							</Button>
						)
					}
				</div>
				{
					images.length < 1 && (
						<Button
							style="icon"
							size="large"
							icon={
								<Icon
									src={AttachmentImageSVG}
									width={27}
									height={27}
								/>
							}
							clickHandler={openImageUploadDialog}
						/>
					)
				}
			</div>
			{
				images.length < 1 && (
					<FileUpload
						triggerRef={imageUploadRef}
						type="image"
						changeHandler={onImageChange}
						limitToFileTypes=".jpg, .jpeg, .png, .gif"
					/>
				)
			}
		</ModuleWrapper>
	);
};

CommentEditorFooter.propTypes = {
	addComment: PropTypes.func,
	attachments: PropTypes.array,
	images: PropTypes.array,
	setAttachments: PropTypes.func,
	canSubmit: PropTypes.bool,
	cancel: PropTypes.func,
	editing: PropTypes.bool,
	updateComment: PropTypes.func,
	nudge: PropTypes.bool,
};

const commentMaxLength = 8000;

const nudgeCharsLimit = 28;

const CommentEditor = (
	{
		cancel,
		editing,
		onUpdate,
		replyToUser,
	}
) => {
	const attachmentAPI = useAttachmentAPI();
	const category = useGetPostTrackingCategory();
	const commentAPI = useCommentAPI();
	const trackEvent = useTrackEvent();

	const { saving } = useMemoizedContext("postUIEditing", ["saving"]);

	const {
		comment: commentHtml,
		dispatch: dispatchComment,
		id: commentId,
		parentCommentId,
	} = useMemoizedContext("comment", [
		"comment",
		"id",
		"parentCommentId",
	]);

	const {
		adFrequency,
		adId,
		comments,
		id,
		postId,
		partnerMemberId,
		sermoContentCardId,
		user,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"comments",
		"id",
		"partnerMemberId",
		"sermoContentCardId",
		"user",
	]);

	const {
		attachments,
		dispatch: dispatchAttachments,
	} = useMemoizedContext("attachments", [
		"attachments",
	]);

	const idUsed = id || postId;
	const member = useMemoizedContext("member");
	const [mentions, setMentions] = useState([]);

	const { members, handleSearchMention } = useMentionMember();

	const handleMentionChange = (mentions) => {
		setMentions(mentions);
	};

	let anonymous = false;

	if (user) {
		if (user.id === member.id) {
			anonymous = user.anonymous;
		}
	}

	const getInitialCommentValue = useCallback(() => {
		if (commentHtml) {
			return deserialize(commentHtml);
		}
		else if (
			replyToUser?.id > 0
			&& !replyToUser.anonymous
		) {
			return [
				{
					type: "paragraph",
					children: [
						{
							// tells the renderer which method to use
							type: "mention",
							label: replyToUser.username,
							value: replyToUser.id,
							children: [{ text: "" }],
						},
						{ text: " " } // space after mention
					],
				},
			]
		}
		return emptyValue;
	}, [commentHtml, replyToUser]);

	const editorHandleReset = useRef();
	const [loading, setLoading] = useState(saving);
	const [commentValue, setCommentValue] = useState(getInitialCommentValue());
	const [editorElement, setEditorElement] = useState(null);
	const [nudge, setNudge] = useState(false);

	const getCharactersRemaining = useCallback(() => commentMaxLength - (editorElement
		? editorElement.textContent.length
		: 0), [editorElement]);

	const [charactersRemaining, setCharactersRemaining] = useState(getCharactersRemaining());

	useEffect(() => {
		setCharactersRemaining(getCharactersRemaining());
	},[commentValue, getCharactersRemaining]);

	const getImages = useCallback(() => attachments.filter((attachment) => attachment.type === "Image"), [attachments]);
	const [images, setImages] = useState(getImages());

	useEffect(() => {
		setImages(getImages());
	}, [attachments, getImages]);

	const previousComment = usePrevious(commentHtml);

	useEffect(() => {
		if ("undefined" === typeof previousComment) {
			return;
		}
		if (
			attachments.length === 0
			&& "undefined" !== typeof previousComment
			&& commentHtml.length - previousComment.length > 1
		) {
			const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

			const links = urlRegex.exec(commentHtml);
			if (links && links.length > 0) {
				attachmentAPI.addLink(links[0], "Comment");
			}
		}
	}, [commentHtml]);

	const onLoadEditor = useCallback((editor, handleReset) => {
		editorHandleReset.current = handleReset;
	}, []);

	const handleContentChange = useCallback((value, element) => {
		setEditorElement(element);

		if (
			value
			&& value.length > 0
		) {
			let html = serialize(value, serializeMember);
			setCommentValue(value);
			dispatchComment({
				type: CommentActionTypes.SET_COMMENT_HTML,
				payload: {
					commentHtml: html,
				},
			});
		}
		else {
			setCommentValue(getInitialCommentValue());
		}
	}, [dispatchComment, getInitialCommentValue]);

	const getRequestBody = useCallback(() => {
		const allAttachments = attachments.map((attachment) => attachment.id);
		const commentBody = {
			text: commentHtml,
			postId: idUsed,
			mentions,
		};

		if (allAttachments && allAttachments.length > 0) {
			commentBody.attachmentIds = allAttachments;
		}

		if (
			commentId
			|| parentCommentId
		) {
			commentBody.commentId = commentId || parentCommentId;
		}

		return commentBody;
	}, [
		attachments,
		commentHtml,
		commentId,
		idUsed,
		mentions,
		parentCommentId,
	]);

	const updateComment = useCallback(() => {
		trackEvent({
			category,
			action: "click",
			label: nudge
				? "yes-comment-update"
				: "comment-update",
			postId: idUsed,
			adId,
			adFrequency,
			sermoContentCardId,
		});

		setLoading(true);

		commentAPI.updateComment(
			{
				callback: () => {
					setLoading(false);
					if (onUpdate) {
						onUpdate()
					}
				},
				passedData: {
					attachments,
					commentHtml,
					id: commentId,
				},
				requestBody: getRequestBody(),
			}
		);
	}, [
		adFrequency,
		adId,
		attachments,
		category,
		commentAPI,
		commentHtml,
		commentId,
		getRequestBody,
		idUsed,
		nudge,
		onUpdate,
		sermoContentCardId,
		trackEvent,
	]);

	const onFocusEditor = useCallback(() => {
		if (nudge) {
			setNudge(false);
		}
	}, [nudge]);

	const getDataToBePassedToDispatch = useCallback(() => {
		return {
			postId: idUsed,
			comment: commentHtml,
			attachments,
			user: member.formatMemberForFeed(member, anonymous, partnerMemberId),
			posted: "just now",
			votes: 0,
			replies: [],
			voted: false,
		};
	}, [
		anonymous,
		attachments,
		commentHtml,
		idUsed,
		member,
		partnerMemberId,
	]);

	const resetState = useCallback(() => {
		setNudge(false);
		setLoading(false);
		dispatchComment({ type: CommentActionTypes.RESET_COMMENT });
		setCommentValue(getInitialCommentValue());
		editorHandleReset.current();
		dispatchAttachments({ type: AttachmentsActionTypes.RESET_ATTACHMENTS });

		if (cancel) {
			cancel();
		}
	}, [cancel, dispatchAttachments, dispatchComment, getInitialCommentValue]);

	const addComment = useCallback(() => {
		if(editorElement.textContent.length < nudgeCharsLimit && !nudge) {
			trackEvent({
				category,
				action: "view",
				label:"comment-nudge",
				postId: idUsed,
				adId,
				adFrequency,
				sermoContentCardId,
			});

			setNudge(true);

			return;
		}

		trackEvent({
			category,
			action: "click",
			label: nudge
				? "yes-comment-add"
				: "comment-add",
			postId: idUsed,
			adId,
			adFrequency,
			area: "add-comment",
			sermoContentCardId,
		});

		setLoading(true);

		commentAPI.addComment(
			{
				passedData: getDataToBePassedToDispatch(),
				requestBody: getRequestBody(),
			}
		);
	},[adFrequency, adId, category, commentAPI, editorElement?.textContent?.length, getDataToBePassedToDispatch, getRequestBody, idUsed, nudge, sermoContentCardId, trackEvent]);

	const previousCommentsLength = usePrevious(comments.length);
	useEffect(() => {
		if ( "undefined" !== typeof previousCommentsLength && comments.length === previousCommentsLength + 1){
			resetState();
		}
	}, [comments?.length]);

	useEffect(() => {
		if (saving) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [saving]);

	const [canSubmit, setCanSubmit] = useState(commentHtml.length > 7 && charactersRemaining >= 0 && !loading);

	useEffect(() => {
		setCanSubmit(commentHtml.length > 7 && charactersRemaining >= 0 && !loading);
	}, [charactersRemaining, commentHtml, loading]);

	const editorMemoized = useMemo(() => (
		<EditorStyled
			autoFocus={!!replyToUser?.id}
			value={commentValue}
			onChange={handleContentChange}
			onLoad={onLoadEditor}
			onSearchMention={handleSearchMention}
			mentionsOptions={members.map(toOptions)}
			mentionValue={mentions.map(toOptions)}
			emoji
			placeholder={getTranslation("frontend.comments.commentPlaceholder", true)}
			max={commentMaxLength}
			onChangeMention={handleMentionChange}
			renderMentionOption={MentionMemberOption}
			showCharCount
			mentions={mentions}
			disabled={loading}
			onFocus={onFocusEditor}
			borderless
		/>
	), [
		commentValue,
		handleContentChange,
		handleSearchMention,
		loading,
		members,
		mentions,
		onFocusEditor,
		onLoadEditor,
	]);

	return (
		<>
			<div
				data-component="CommentEditor"
				styleName={classNames("layout.row-no-padding")}>
				<div styleName={classNames("styles.editor")}>
					{editorMemoized}
					{(attachments.length > 0 || images.length > 0) && <div styleName={classNames("layout.row")} />}
					<AttachmentManager
						comment={true}
						type="Image"
					/>
					{
						images.length === 0 && (
							<div styleName={classNames("layout.row-no-padding")}>
								<LinkPreview />
							</div>
						)
					}
					<CommentEditorFooter
						addComment={addComment}
						updateComment={updateComment}
						user={user}
						attachments={attachments}
						images={images}
						canSubmit={canSubmit}
						cancel={cancel}
						editing={editing}
						nudge={nudge}
					/>
					{loading && <Loading contextClass="editor" />}
				</div>
			</div>
		</>
	);
};

CommentEditor.propTypes = {
	replyToUser: PropTypes.object,
	cancel: PropTypes.func,
	editing: PropTypes.bool,
	onUpdate: PropTypes.func,
	existingAttachments: PropTypes.array,
};

CommentEditor.defaultProps = {
	replyToUser: null,
	editing: false,
	existingAttachments: [],
};

const CommentEditorWithProvider = (props) => {
	const {
		children,
		...restOfProps
	} = props;

	return (
		<AttachmentsProvider parentContext={"comment"}>
			<CommentEditor {...restOfProps}>
				{children}
			</CommentEditor>
		</AttachmentsProvider>
	);
};

CommentEditorWithProvider.propTypes = {
	children: PropTypes.node,
	...CommentEditor.propTypes,
};

export default CommentEditorWithProvider;
