import React from "react";
import UIContext from "@contexts/UI/UIContext";
import { UIDefaultValues } from "@contexts/UI/UIContext";
import UIReducer from "@contexts/UI/UIReducer";
const UIProvider = ({ children, initialState = { ...UIDefaultValues }, }) => {
    const [state, dispatch] = React.useReducer(UIReducer, initialState);
    return (<UIContext.Provider value={{ ...state, dispatch }}>
			{children}
		</UIContext.Provider>);
};
export default UIProvider;
