import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const TitleContainer = styled.div `
	position: relative;
`;
export const Title = styled(TextStyled) `
	color: ${({ theme }) => theme.color.text01};
	word-break: break-word;

	sup {
		top: -0.7em;
	}

	a {
		color: ${({ theme }) => theme.color.text01};
		text-decoration: underline;
	}

	${({ $expanded }) => $expanded && `
		sup {
			top: -1.1em;
		}
	`}
`;
export const ReadMoreButton = styled(TextStyled) `
	color: ${({ theme }) => theme.color.interactive01};
	display: inline;
	padding-right: 0;
	background-color: ${({ theme }) => theme.layout.uiBackground};
	font-size: 13px;
	font-weight: 500;

	& > div {
		cursor: pointer;
		display: inline;
	}

	&:before {
		content: "";
		display: inline;
		background: linear-gradient( to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 30%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100% );
	}

	&:hover {
		text-decoration: underline;
	}
`;
