import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { css } from "styled-components";
const readMoreStyles = css `
	color: ${({ theme }) => theme.color.interactive01};
	display: inline;
	padding-right: 0;
	background-color: ${({ theme }) => theme.color.uiBackground};
	font-size: 13px;
	font-weight: 500;
	white-space: nowrap;

	& > div {
		cursor: pointer;
		display: inline;
	}

	&:before {
		content: "";
		display: inline;
		background: linear-gradient(
			to left,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.75) 30%,
			rgba(255, 255, 255, 0) 70%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&:hover {
		text-decoration: underline;
	}

	${({ $showLess }) => $showLess && css `
		cursor: default;
		display: inline;
	`};

	${({ $newline }) => $newline && css `
		display: block;
		padding-left: 0;

		& > div {
			margin-left: 0;
		}
	`};

	${({ $inPortal }) => $inPortal && css `
		padding-left: 8px;
		display: inline;

		li + span > & {
			padding-left: 0;
		}
	`};
`;
export const ReadMoreContainer = styled(TextStyled) `
	${readMoreStyles};
	display: flex;
`;
export const ReadMorePortal = styled(TextStyled) `
	${readMoreStyles};
`;
