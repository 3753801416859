import React from "react";
import styled from "styled-components";
import Comments from "@components/Card/components/Comments/Comments";
import Featured from "@components/Card/components/Featured/Featured";
import Images from "@components/Card/components/Images/Images";
import PDFs from "@components/Card/components/PDFs/PDFs";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SermoContentExpanded from "@components/PostContent/components/SermoContentExpanded";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardModal from "@components/PostViewer/components/CardModal";
import { ImageBar } from "@components/PostViewer/components/SermoContentCardInFeed/SermoContentCardInFeed";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useMemoizedContext } from "@hooks/Hooks";
const ImageBarWhenExpanded = styled(ImageBar) `
	@media ${MobileAndTabletPortraitQuery} {
		top: ${({ theme }) => `calc(${theme.layout.topNavHeightMobile} + ${theme.layout.mobileFeedNavHeight})`};
	}
`;
const SermoContentCardExpanded = () => {
    const { theme, } = useMemoizedContext("postData", [
        "theme",
    ]);
    return (<>
			<CardModal />
			<CardContainer>
				<ModuleWrapper solid={true} paddedWidest={true} notPaddedY={true}>
					{"TopBar" === theme?.type && <ImageBarWhenExpanded $url={theme?.imageUrl}/>}
					<SharedStyles.ExpandedSpacingContainer>
						<CardHeader />
						<Title />
						<Featured />
						<SermoContentExpanded />
						<LinkPreview />
						<Images />
						<PDFs />
						<PollDisplay />
					</SharedStyles.ExpandedSpacingContainer>
				</ModuleWrapper>
				<CardFooter />
				{/* @ts-ignore*/}
				<Comments />
			</CardContainer>
		</>);
};
export default SermoContentCardExpanded;
