import { usePrev } from "@sermo/ui-components";
import { Video } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDebounce } from "react-use";
import { VideoPlayerContainer } from "@components/Card/components/VideoPlayer/VideoPlayer.styles";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useGetPostTrackingCategory, useMemoizedContext, useProgressWithPostContext, } from "@hooks/Hooks";
import { getPlaybackSpeedTrackingData } from "./VideoPlayer.utils";

const BasicVideoPlayer = (
	{
		autoplay,
		captionsUrl,
		disableFullscreen,
		disablePlaybackSpeed,
		disableQuality,
		disableRewindAndForward,
		loop,
		muted: mutedProp,
		thumbnail,
		trackEventObject,
		trackingArea,
		url,
		videoChapters,
	}
) => {
	const trackEvent = useTrackEvent();
	const videoEl = useRef();
	const [playing, setPlaying] = useState(false);
	const [played, setPlayed] = useState(false);
	const [userPlayed, setUserPlayed] = useState(false);
	const [userPaused, setUserPaused] = useState(false);
	const codeEvent = useRef(false);
	const { active } = useMemoizedContext("member", ["active"]);
	const area = trackingArea;
	const progressRef = useRef(videoEl.current?.currentTime || 0);
	const timeRef = useRef();
	const [speed, setSpeed] = useState();
	const [volume, setVolume] = useState(1); // default state is full volume but muted
	const previousVolume = useRef(volume);
	const [muted, setMuted] = useState(mutedProp);
	const prevMuted = usePrev(muted);
	const category = useGetPostTrackingCategory();
	const onProgress = useProgressWithPostContext(area, trackEventObject);

	const onTimeUpdate = (e) => {
		// Condition avoids triggering rewind tracking event when restarting video.
		timeRef.current = e.target.currentTime
			? Date.now()
			: undefined;
		onProgress(e);
	}

	const SEEK_DEBOUNCE = 200;
	const DELTA_CORRECTION = 0.001; // Ten milliseconds.

	useDebounce(() => {
		const now = Date.now();
		const DELTA_PAUSED = 0;
		const delta = !videoEl.current.paused
			? (now - (timeRef.current || now)) / 1000
			: DELTA_PAUSED;
		const deltaDebounce = !videoEl.current.paused
			? SEEK_DEBOUNCE / 1000 // Secs.
			: 0;
		const currentTime = videoEl.current.currentTime;
		let seekType;
		if (
			timeRef.current
			&& progressRef.current !== videoEl.current.duration
			&& progressRef.current > currentTime
		) {
			seekType = "rewind";
		} else if (
			timeRef.current
			&& Math.round((currentTime - DELTA_CORRECTION) - ((progressRef.current + (delta * (speed || 1))) + deltaDebounce)) > 0
		) {
			seekType = "fastforward";
		}

		progressRef.current = currentTime;

		if (seekType) {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: `video-${seekType}`,
				videoEventType: seekType,
				value: seekType === "rewind"
					? 11
					: 12,
				videoTime: currentTime,
			});
		}
	}, SEEK_DEBOUNCE, [timeRef.current]);

	const onPause = () => {
		timeRef.current = undefined; // Avoids triggering fastforward tracking event on pause.
		setPlaying(false);
		if (codeEvent.current) {
			codeEvent.current = false;
			setUserPaused(false);
			setUserPlayed(false);
		} else {
			setUserPaused(true);
		}
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			label: "video-pause",
			videoEventType: "Pause",
			value: 2,
		});
	};

	const onPlay = () => {
		setPlaying(true);
		setPlayed(true);

		if (codeEvent.current) {
			codeEvent.current = false;
			setUserPlayed(false);
			setUserPaused(false);
		} else {
			setUserPlayed(true);
		}
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			label: "video-play",
			videoEventType: "Play",
			value: 1,
		});
	};

	useDebounce(
		() => {
			if (speed) {
				const playbackSpeedTrackingData = getPlaybackSpeedTrackingData(speed);
				trackEvent({
					category,
					action: "click",
					area,
					...trackEventObject,
					...playbackSpeedTrackingData,
				});
			}
		},
		1000,
		[speed]
	);

	const VOLUME_DEBOUNCE = 500;
	// handle tracking of volume changes
	useDebounce(() => {
		if (volume < previousVolume.current) {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-change-volume",
				value: 23,
				videoEventType: "VolumeDecrease",
				newVolume: volume,
				oldVolume: previousVolume.current,
			});
		}

		if (volume > previousVolume.current) {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-change-volume",
				value: 22,
				videoEventType: "VolumeIncrease",
				newVolume: volume,
				oldVolume: previousVolume.current,
			});
		}

		previousVolume.current = volume;
	}, VOLUME_DEBOUNCE, [volume]);

	useEffect(() => {
		// so it doesn't fire track event on initial render
		if (undefined === prevMuted) {
			return () => {};
		}

		if (muted) {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-mute",
				value: 26,
				videoEventType: "Mute",
			});
		} else {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-unmute",
				value: 10,
				videoEventType: "Unmute",
			});
		}
	}, [muted]);

	const onVolumeChange = (event) => {
		setVolume(event.target?.volume);
		setMuted(event.target?.muted);
	}

	const onCaptionChange = (event, data) => {
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			label: `video-captions-${data.caption}`,
			value: 29,
			videoEventType: "CaptionChange",
		});
	}

	const onChapterSelect = (chapter) => {
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			chapterId: chapter?.id,
			label: `video-chapter-select`,
			value: 29,
			videoEventType: "ChapterSelect",
		});
	}

	const onCloseChapterPopup = () => {
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			label: `video-chapter-list-hide`,
			value: 28,
			videoEventType: "ChapterMenuHide",
		});
	}

	// TODO: this is fired twice every time the user selects a menu option and is firing too often
	// const onCloseSettingsMenu = () => {
	// 	trackEvent({
	//      category,
	// 		...trackEventObject,
	// 		action: "click",
	// 		label: `video-settings-menu-hide`,
	// 		value: 33,
	// 		videoEventType: "SettingsMenuHide",
	// 		videoTime: videoEl.current.currentTime,
	// 		area,
	// 	});
	// }

	const onForwardClick = (event, data) => {
		trackEvent({
			category,
			area,
			...trackEventObject,
			action: "click",
			label: `video-button-fastforward`,
			skipDuration: data.skipDuration || 10,
			value: 12,
			videoEventType: "fastforward",
			videoTime: data.currentTime,
		});
	}

	const onFullScreen = (isFullScreen) => {
		if (isFullScreen) {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-fullscreen",
				videoEventType: "Videoexpanded",
				value: 13,
			});
		}
		else {
			trackEvent({
				category,
				area,
				...trackEventObject,
				action: "click",
				label: "video-minimize",
				value: 25,
				videoEventType: "VideoMinimized",
			});
		}
	}

	const onOpenChapterPopup = () => {
		trackEvent({
			category,
			...trackEventObject,
			action: "click",
			label: `video-chapter-list-show`,
			value: 27,
			videoEventType: "ChapterMenuShow",
			area,
		});
	}

	const onOpenSettingsMenu = () => {
		trackEvent({
			category,
			...trackEventObject,
			action: "click",
			label: `video-settings-menu-show`,
			value: 32,
			videoEventType: "SettingsMenuShow",
			videoTime: videoEl.current.currentTime,
			area,
		});
	}

	const onProgressBarDrag = () => {
		trackEvent({
			category,
			...trackEventObject,
			action: "click",
			label: `video-progress-bar-drag`,
			value: 30,
			videoEventType: "",
			videoTime: videoEl.current.currentTime,
			area,
		});
	}

	// TODO: this is fired every time the user mouse leaves the progress bar and is firing too often
	// const onProgressBarDrop = () => {
	// 	trackEvent({
	// 		...trackEventObject,
	//      category,
	// 		action: "click",
	// 		label: `video-progress-bar-drop`,
	// 		value: 31,
	// 		videoEventType: "",
	// 		videoTime: videoEl.current.currentTime,
	// 		area,
	// 	});
	// }

	const onRewindClick = (event, data) => {
		trackEvent({
			category,
			...trackEventObject,
			action: "click",
			area,
			label: `video-button-rewind`,
			skipDuration: data.skipDuration || 10,
			value: 11,
			videoEventType: "rewind",
			videoTime: data.currentTime,
		});
	}

	// uses various state values to determine if a video should actually play
	const updatePlayState = () => {
		if (playing) {
			let shouldPause = false;

			// always pause when inactive
			if (!active) {
				shouldPause = true;
			}

			if (shouldPause) {
				setPlaying(false);
				codeEvent.current = true;
			}
		} else {
			// it's not playing should it be?
			let shouldPlay = false;

			if (!active) {
				shouldPlay = false;
			}

			if (shouldPlay) {
				videoEl.current?.play().then(function() {
					// Automatic playback started!
					setPlaying(true)
					codeEvent.current = true;
				}).catch(function(error) {
					// Automatic playback failed.
					// eslint-disable-next-line no-console
					console.log(error);
				});
			}
		}
	};

	const onRateChange = (event) => {
		setSpeed(event.target.playbackRate);
	}

	const onEnded = () => {
		progressRef.current = 0; // Avoids triggering rewind tracking event when restarting video.
		timeRef.current = undefined;
	}

	useEffect(() => {
		// const videoRef = videoEl;
		updatePlayState();
	},[])

	useEffect(() => {
		updatePlayState();
	}, [active, userPaused, userPlayed, playing]);

	const onClick = (e) => {
		e.stopPropagation();
		e.eventSource = area;
		// if its paused we are playing it
		setUserPlayed(e.target.paused);
		setUserPaused(!e.target.paused);
	};

	return useMemo(() => (
		<VideoPlayerContainer onClick={onClick}>
			<Video
				src={url}
				ref={videoEl}
				poster={thumbnail}
				loop={loop}
				controls={played}
				onTimeUpdate={onTimeUpdate}
				onPause={onPause}
				onPlay={onPlay}
				onEnded={onEnded}
				onVolumeChange={onVolumeChange}
				onFullscreen={onFullScreen}
				onPlaybackSpeedChange={onRateChange}
				onCaptionChange={onCaptionChange}
				onChapterSelect={onChapterSelect}
				onCloseChapterPopup={onCloseChapterPopup}
				// onCloseSettingsMenu={onCloseSettingsMenu}
				onOpenChapterPopup={onOpenChapterPopup}
				onOpenSettingsMenu={onOpenSettingsMenu}
				onProgressBarDrag={onProgressBarDrag}
				// onProgressBarDrop={onProgressBarDrop}
				onForwardClick={onForwardClick}
				onRewindClick={onRewindClick}
				autoPlay={autoplay}
				playsInline={true}
				muted={muted}
				controlsList="nodownload"
				disablePictureInPicture
				disableFullscreen={disableFullscreen}
				disablePlaybackSpeed={disablePlaybackSpeed}
				disableQuality={disableQuality}
				disableRewindAndForward={disableRewindAndForward}
				chapters={videoChapters}
				captionSettings={
					{
						defaultValue: "off",
						options: [
							{
								label: "English",
								src: captionsUrl,
								value: "en"
							}
						]
					}
				}
			/>
		</VideoPlayerContainer>
	), [played]);
}

const trackingObjectPropTypes = {
	category: PropTypes.string,
	value: PropTypes.number,
	postId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	adId: PropTypes.number,
	adFrequency: PropTypes.string,
	area: PropTypes.string,
}

BasicVideoPlayer.propTypes = {
	trackEventObject: PropTypes.shape(trackingObjectPropTypes).isRequired,
	thumbnail: PropTypes.string.isRequired,
	autoplay: PropTypes.bool.isRequired,
	muted: PropTypes.bool.isRequired,
	loop: PropTypes.bool.isRequired,
	captionsUrl: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]).isRequired,
	trackingArea: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]).isRequired,
	videoChapters: PropTypes.arrayOf(
		PropTypes.shape({
			startTime: PropTypes.number,
			title: PropTypes.string,
			thumbnail: PropTypes.string,
		})
	),
};

BasicVideoPlayer.defaultProps = {
	trackEventObject: {},
	autoplay: false,
	muted: true,
	loop: false,
	captionsUrl: false,
	trackingArea: false,
	videoChapters: [],
}

export default BasicVideoPlayer;