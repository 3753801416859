import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import InlineVideoPLayer from "@components/Card/components/VideoPlayer/InlineVideoPlayer";
import { ContentContainer, contentContainerClickHandler, sanitizeLinks, useContentAreaClickEventHandler, useInlineVideos, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./SponsoredContentExpanded.styles";
const ContentSponsored = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const inlineVideos = useInlineVideos();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { contentHtml, id, originalContentHtml, } = useMemoizedContext("postData", [
        "contentHtml",
        "id",
        "originalContentHtml",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : contentHtml;
        return sanitizeLinks(editorContent);
    }, [
        contentHtml,
        originalContentHtml,
        showOriginalContent,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				{inlineVideos?.map((iv, i) => (<InlineVideoPLayer el={iv} key={i}/>))}

				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}

				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={"bodyLong03"} onClick={contentAreaClickEventHandler}/>
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentToDisplay,
        id,
        inlineVideos,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
    ]);
};
export default ContentSponsored;
