import {
	AttachmentImageIcon,
	AttachmentPDFIcon,
	AttachmentPollIcon,
	Icon,
} from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import Button from "@components/Button/Button";
import { FileUpload } from "@components/FormFields";
import { Toggle } from "@components/FormFields/FormFields";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import usePollAPI from "@components/PostEditor/components/PollEdit/usePollAPI";
import useAttachmentAPI from "@contexts/Attachments/useAttachmentAPI";
import { PostDataActionTypes } from "@contexts/PostData";
import { PostUIActionTypes } from "@contexts/PostUI";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import usePostUIAPI from "../../../../contexts/PostUI/usePostUIAPI";
import { useTogglePostEditor } from "../../../../contexts/UI";
import * as Styles from "./PostEditorFooter.styles";

const PostEditorFooter = () => {
	const attachmentAPI = useAttachmentAPI();
	const pollAPI = usePollAPI();
	const postUIAPI = usePostUIAPI();
	const toggleEditor = useTogglePostEditor();

	const {
		dispatch: dispatchPostData,
		isAnonymous,
		type,
	} = useMemoizedContext("postData", [
		"isAnonymous",
		"type",
	]);

	const {
		draftStatus,
		dispatch: dispatchPostUI,
		publishing,
	} = useMemoizedContext("postUI", [
		"draftStatus",
		"publishing",
	]);
	const composing = DraftStatusTypes.COMPOSING === draftStatus;
	const editing = DraftStatusTypes.EDITING === draftStatus;

	const {
		canPost,
		dispatch: dispatchPostUIEditing,
		saving,
	} = useMemoizedContext("postUIEditing", [
		"canPost",
		"saving",
	]);

	const { brandAdministrator } = useMemoizedContext("member", ["brandAdministrator"]);

	const canPostAnonymously = !brandAdministrator;

	const trialMemberTrackingObject = { label: "post" + type?.toLowerCase() };

	const handleAddPollClick = () => {
		pollAPI.addPoll();
	};

	const anonToggle = () => {
		dispatchPostData({
			type: PostDataActionTypes.TOGGLE_ANONYMOUS,
		});
	};

	const cancel = () => {
		if (composing) {
			toggleEditor(false);
		}
		else if (editing) {
			postUIAPI.cancelEdit();
		}
	};

	const publish = () => {
		dispatchPostUI({
			type: PostUIActionTypes.START_PUBLISHING,
		})
	}

	// const isUploading = (attachment) => attachment.id === null;

	const imageUploadRef = useRef(null);
	const openImageUploadDialog = () => {
		imageUploadRef.current.click();
	};
	const onImageChange = (e) => {
		attachmentAPI.uploadFile(e.target.files[0]);
	};
	// const onImageRemove = () => {
	// 	imageUploadRef.current.value = "";
	// }

	const pdfUploadRef = useRef(null);
	const openPdfUploadDialog = () => {
		pdfUploadRef.current.click();
	};
	const onPdfChange = (e) => {
		attachmentAPI.uploadFile(e.target.files[0]);
	};
	// const onPdfRemove = () => {
	// 	pdfUploadRef.current.value = "";
	// }

	return (
		<>
			<FileUpload
				triggerRef={imageUploadRef}
				type="imageAndVideo"
				changeHandler={onImageChange}
			/>
			<FileUpload
				triggerRef={pdfUploadRef}
				type="pdf"
				changeHandler={onPdfChange}
			/>
			<TabletLandscapeAndDesktop>
				<Styles.FooterContainer>
					<Styles.FooterLeft>
						{
							[PostTypeTypes.DISCUSSION, PostTypeTypes.PATIENT_CASE].includes(type)
								&& (
									<>
										<Button
											style="icon"
											size="medium"
											icon={
												<Icon
													src={AttachmentImageIcon}
													width={24}
													height={24}
												/>
											}
											clickHandler={openImageUploadDialog}
										/>
										<Button
											style="icon"
											size="medium"
											icon={
												<Icon
													src={AttachmentPDFIcon}
													width={24}
													height={24}
												/>
											}
											clickHandler={openPdfUploadDialog}
										/>
										{
											composing && (
												<Button
													style="icon"
													size="medium"
													icon={
														<Icon
															src={AttachmentPollIcon}
															width={24}
															height={24}
														/>
													}
													clickHandler={handleAddPollClick}
												/>
											)
										}
									</>
								)
						}
					</Styles.FooterLeft>
					<Styles.FooterRight>
						{
							composing
								&& canPostAnonymously
								&& (
									<Toggle
										label={getTranslation("frontend.newPost.footer.postAnonymously")}
										changeHandler={anonToggle}
										state={isAnonymous}
									/>
								)
						}
						{
							editing
							&& (
								<Button
									style="secondary"
									size="medium"
									clickHandler={cancel}
								>
									{getTranslation("frontend.generics.cancel")}
								</Button>
							)
						}
						<Button
							style="primary"
							disabled={!canPost}
							size="medium"
							clickHandler={publish}
							trialMemberTrackingObject={trialMemberTrackingObject}
							rightsRequired={["canCreateEditAndDeletePostsInFrontend"]}
							loading={publishing || saving}
						>
							{
								composing
									? getTranslation("frontend.generics.post")
									: getTranslation("frontend.generics.saveChanges")
							}
						</Button>

					</Styles.FooterRight>
				</Styles.FooterContainer>
			</TabletLandscapeAndDesktop>

			<MobileAndTabletPortrait>
				{
					composing && (
						<Styles.AnonymousToggleMobile>
							<Toggle
								label={getTranslation("frontend.newPost.footer.postAnonymously")}
								changeHandler={anonToggle}
								state={isAnonymous}
								contextClass="mobile-post-anonymous"
							/>
						</Styles.AnonymousToggleMobile>
					)
				}
				<>
					<Styles.FooterContainer>
						<Styles.FooterLeft>
							{
								["Discussion", "PatientCase"].includes(type) && (
									<>
										<Button
											style="icon"
											size="medium"
											icon={
												<Icon
													src={AttachmentImageIcon}
													width={24}
													height={24}
												/>
											}
											clickHandler={openImageUploadDialog}
										/>
										<Button
											style="icon"
											size="medium"
											icon={
												<Icon
													src={AttachmentPDFIcon}
													width={24}
													height={24}
												/>
											}
											clickHandler={openPdfUploadDialog}
										/>
										<Button
											style="icon"
											size="medium"
											icon={
												<Icon
													src={AttachmentPollIcon}
													width={24}
													height={24}
												/>
											}
											clickHandler={handleAddPollClick}
										/>
									</>
								)
							}
						</Styles.FooterLeft>
						<Styles.FooterRight>
							<Button
								style="flatNeutral"
								size="small"
								clickHandler={cancel}
							>
								{getTranslation("frontend.generics.cancel")}
							</Button>
							<Button
								style="primary"
								disabled={!canPost}
								size="small"
								clickHandler={publish}
								trialMemberTrackingObject={trialMemberTrackingObject}
								rightsRequired={["canCreateEditAndDeletePostsInFrontend"]}
								loading={publishing || saving}
							>
								{
									composing
										? getTranslation("frontend.generics.post")
										: getTranslation("frontend.generics.saveChanges")
								}
							</Button>
						</Styles.FooterRight>
					</Styles.FooterContainer>
				</>
			</MobileAndTabletPortrait>
		</>
	);
};

PostEditorFooter.propTypes = {
	openImageUploadDialog: PropTypes.func,
	openPdfUploadDialog: PropTypes.func,
	draftFooterCancel: PropTypes.func,
};

PostEditorFooter.defaultProps = {
	openImageUploadDialog: () => {},
	openPdfUploadDialog: () => {},
	draftFooterCancel: () => {},
};

export default PostEditorFooter;
