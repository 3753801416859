export const videoPlaybackSpeedTrackingEventValues = Object.freeze({
	"025": 14,
	"05": 15,
	"075": 16,
	"1": 17,
	"125": 18,
	"15": 19,
	"175": 20,
	"2": 21,
});

export const getPlaybackSpeedTrackingData = (rate) => {
	const formattedRate = rate.toString().replace(".", "");
	const label = `video-playbackspeed${formattedRate}`;
	const value = videoPlaybackSpeedTrackingEventValues[formattedRate];
	const videoEventType = `VideoPlaybackSpeed${formattedRate}`;

	return {
		label,
		value,
		videoEventType,
	};
};

// Safe Safari detection: https://stackoverflow.com/a/9851769/2086460
export const isSafari = /constructor/i.test(window.HTMLElement)
	// eslint-disable-next-line max-len
	|| (function (p) {return p.toString() === "[object SafariRemoteNotification]"; })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification))
	|| /iP(hone|od|ad)/.test(navigator.userAgent)
	|| ((navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1) && !window.MSStream);