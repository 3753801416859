import ConfirmManualVerification from "../../ConfirmManualVerification";
import Complete from "../Reverification/Complete/Complete";
import EnterName from "../Reverification/EnterName";
import EnterProviderInformation from "../Shared/EnterProviderInformation";
import EnterVerificationCode from "../Shared/EnterVerificationCode";
import IdentityVerification from "../Shared/IdentityVerification";
import SelectIdentityVerificationMode from "../Shared/SelectIdentityVerificationMode";
import SelectSuggestedProvider from "../Shared/SelectSuggestedProvider";
import SelectVerificationCodeMethod from "../Shared/SelectVerificationCodeMethod";
import UploadVerificationDocuments from "../Shared/UploadVerificationDocuments";

// capital "C" component to easily get the value and use it in JSX
const config = {
	"Complete": {
		"Component": Complete,
	},
	"SelectSuggestedProvider" : {
		"Component": SelectSuggestedProvider,
	},
	"EnterProviderInformation" : {
		"Component": EnterProviderInformation,
	},
	"IdentityVerification" : {
		"Component": IdentityVerification,
	},
	"UploadIdentityDocuments" : {
		"Component": UploadVerificationDocuments,
	},
	"SelectVerificationCodeMethod" : {
		"Component": SelectVerificationCodeMethod,
	},
	"EnterVerificationCode" : {
		"Component": EnterVerificationCode,
	},
	"PendingManualVerification" : {
		"Component": ConfirmManualVerification,
	},
	"SelectIdentityVerificationMode": {
		"Component": SelectIdentityVerificationMode,
	},
	"EnterName" : {
		"Component": EnterName,
		"default": true,
	},
}

export default config;