import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { expandedStyles, legacyStyles, marginBottomAdStyles, postContentStyles, } from "@components/PostContent";
export const Content = styled(TextStyled) `
	${postContentStyles};
	${legacyStyles};
	${expandedStyles};
	${marginBottomAdStyles};
	margin-bottom: 0;
`;
