import { useMediaQuery } from "react-responsive";
import { Route, Routes, useMatch } from "react-router-dom";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import SearchNav from "@components/Search/SearchNav";
import { useMemoizedContext } from "@hooks/Hooks";
const DesktopNav = () => {
    const isPreview = !!useMatch("/preview/*");
    const { hasAccessToPreview } = useMemoizedContext("member", ["hasAccessToPreview"]);
    if (useMediaQuery({ query: MobileAndTabletPortraitQuery })) {
        return null;
    }
    if (isPreview
        && !hasAccessToPreview) {
        return null;
    }
    return (<Routes>
			<Route path="/search/*" 
    // @ts-ignore
    element={<SearchNav section="all"/>}/>
		</Routes>);
};
export default DesktopNav;
