import PropTypes from "prop-types";
import { useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import Pagination from "@components/Pagination/Pagination";
import { useFetch } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import ActivityItem from "../ActivityItem/ActivityItem";
import ActivityTabs from "../ActivityTabs/ActivityTabs";
import * as Styles from "./ActivityFeed.styles";

export const activityFeedDefaultProps = {
	filterBy: "general",
};

export const activityFeedPropTypes = {
	filterBy: PropTypes.oneOf(["general", "followedPosts"]),
};

const ActivityFeed = ({ filterBy: filterByProp }) => {
	const params = useParams();
	const filterBy = params?.filterBy || filterByProp;

	return (
		<Styles.Container data-component={"ActivityFeed"}>
			<ModuleWrapper
				solid={false}
				padded={false}
			>
				<Styles.Header
					$kind={"bodyShortMedium02"}
				>
					{getTranslation("frontend.navMenu.yourNotifications")}
				</Styles.Header>
				<ActivityTabs filterBy={filterBy} />
				<ActivityFeedPaginated filterBy={filterBy} />
			</ModuleWrapper>
		</Styles.Container>
	);
};

const ActivityFeedWithData = ({ page, onLoadHandler, filterBy }) => {
	const [data, loading] = useFetch("api/activity/getactivityfeed", {
		page: page,
		filterBy,
	});

	useEffect(() => {
		if (!loading && (!data || data.notifications?.length > 0)) {
			onLoadHandler(data.notifications.length);
		}
	}, [loading]);

	return (
		<>
			{
				!loading
				&& (data?.notifications || []).map((activity, i) => (
					<ActivityItem
						activity={activity}
						key={i}
						borderBottom={data.notifications.length - 1 !== i}
						isFollowedPosts={filterBy === "followedPosts"}
					/>
				))
			}
		</>
	);
};

ActivityFeedWithData.propTypes = {
	page: PropTypes.number.isRequired,
	onLoadHandler: PropTypes.func.isRequired,
	...activityFeedPropTypes,
};
ActivityFeedWithData.defaultProps = {
	...activityFeedDefaultProps,
};

const ActivityFeedPaginated = (props) => (
	<Pagination props={props}>
		<ActivityFeedWithData />
	</Pagination>
);

const ActivityFeedWithRoute = () => (
	<Routes>
		<Route
			path=":filterBy"
			element={<ActivityFeed />}
		/>
		<Route
			path="/"
			exact
			element={
				<Navigate
					to="/activity/general"
					replace
				/>
			}
		/>
	</Routes>
);

export default ActivityFeedWithRoute;
