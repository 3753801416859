import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
import ScrollWrapper from "@components/ScrollWrapper/ScrollWrapper";
import { useMemoizedContext } from "@hooks/Hooks";
import { InfoScrollerContainer } from "./InfoScroller.styles";

// Magic number that's roughly the header height on mobile and desktop
const headerHeight = 60;
const InfoScroller = ({
	text,
	clickHandler,
	autoScrollDelay,
	classNamesArray,
	style,
	variant,
	customStyles,
}) => {
	const scrollerEl = useRef();
	const scrollInterval = useRef();
	const scrollTimer = useRef();
	const [autoScroll, setAutoScroll] = useState(false);
	const [scrollStarted, setScrollStarted] = useState(false);
	const { expanded } = useMemoizedContext("postUIViewing", ["expanded"]);

	const {
		isFootnoteAutoScrollEnabled,
		isISIAutoScrollEnabled,
	} = useMemoizedContext("postData", [
		"isFootnoteAutoScrollEnabled",
		"isISIAutoScrollEnabled",
	]);

	const cleanUp = () => {
		window.clearInterval(scrollInterval.current);
		window.clearTimeout(scrollTimer.current);
	};

	const disableAuto = () => {
		setAutoScroll(false);
		cleanUp();

		if (!scrollerEl.current) {
			return;
		}

		scrollerEl.current?.removeEventListener("wheel", disableAuto);
		scrollerEl.current?.removeEventListener("touchmove", disableAuto);
		scrollerEl.current?.removeEventListener("mouseup", disableAuto);
	};

	const enableAuto = () => {
		setAutoScroll(true);
		if (!scrollerEl.current) {
			return;
		}

		scrollerEl.current?.addEventListener("wheel", disableAuto, { passive: true });
		scrollerEl.current?.addEventListener("touchmove", disableAuto, { passive: true });
		scrollerEl.current?.addEventListener("mouseup", disableAuto, { passive: true });
	};

	const checkShouldScrollAndIfTrueStartScroll = () => {
		const rect = scrollerEl.current?.getBoundingClientRect();

		if (
			rect.bottom <= window.innerHeight
			&& rect.top > headerHeight
			&& !scrollStarted
		) {
			window.removeEventListener("scroll", checkShouldScrollAndIfTrueStartScroll);
			scrollTimer.current = window.setTimeout(() => {
				scrollInterval.current = window.setInterval(() => {
					const stm = scrollerEl.current.scrollHeight - scrollerEl.current.clientHeight - 1;
					if (
						scrollerEl.current
						&& stm > scrollerEl.current.scrollTop
					) {
						scrollerEl.current.scrollBy({
							top: 1,
							behavior: "smooth",
						});
					} else {
						disableAuto();
					}
				}, 150);
			}, autoScrollDelay);
			setScrollStarted(true);
		}
	};

	useEffect(() => {
		if (
			(
				"number" === typeof autoScrollDelay
				|| (
					"footnote" === variant
					&& isFootnoteAutoScrollEnabled
				)
				|| (
					"isi" === variant
					&& isISIAutoScrollEnabled
				)
			)
			&& scrollerEl.current
			&& !!window
		) {
			enableAuto();
		}

		return () => {
			disableAuto();
		}
	}, [
		autoScrollDelay,
		isFootnoteAutoScrollEnabled,
		isISIAutoScrollEnabled,
		scrollerEl.current,
		variant,
	]);

	useEffect(() => {
		if (!scrollerEl.current) {
			return;
		}

		scrollerEl.current?.addEventListener("wheel", disableAuto, { passive: true });
		scrollerEl.current?.addEventListener("touchmove", disableAuto, { passive: true });
		scrollerEl.current?.addEventListener("mouseup", disableAuto, { passive: true });

		return () => {
			if (scrollerEl && scrollerEl.current) {
				scrollerEl.current?.removeEventListener("wheel", disableAuto);
				scrollerEl.current?.removeEventListener("touchmove", disableAuto);
				scrollerEl.current?.removeEventListener("mouseup", disableAuto);
			}
		};
	}, []);

	useEffect(() => {
		if (
			autoScroll
			&& window
			&& scrollerEl.current
			&& !scrollStarted
		) {
			checkShouldScrollAndIfTrueStartScroll();
			window.addEventListener("scroll", checkShouldScrollAndIfTrueStartScroll);
		}

		return () => {
			window.removeEventListener("scroll", checkShouldScrollAndIfTrueStartScroll);
		}
	}, [
		autoScroll,
		scrollerEl.current,
		scrollStarted,
	]);

	// cleanup when closed
	useEffect(() => cleanUp, []);

	const onClickHandler = (e) => {
		e.eventSource = variant;
		clickHandler(e);
	};

	const getLocalClassNames = () => [
		{ "largerWithoutBackground": "LargerWithoutBackground" === style },
	]

	const [localClassNames, setLocalClassNames] = useState(getLocalClassNames);

	useEffect(() => {
		setLocalClassNames(getLocalClassNames());
	},[style]);

	return useMemo(() => {
		if (!text) {
			return null;
		}
		return (
			<InfoScrollerContainer
				$expanded={expanded}
				$kind={"bodyShortLegacy02"}
				className={classNames(...classNamesArray, ...localClassNames, variant)}
				dangerouslySetInnerHTML={{ __html: text }}
				onClick={onClickHandler}
				ref={scrollerEl}
				style={customStyles}
			/>
		);
	}, [
		text,
		classNamesArray,
		localClassNames,
	]);
};

InfoScroller.propTypes = {
	clickHandler: PropTypes.func.isRequired,
	text: PropTypes.string,
	autoScrollDelay: PropTypes.oneOfType( [PropTypes.bool, PropTypes.number]),
	classNamesArray: PropTypes.array,
	style: PropTypes.oneOf(["Default", "LargerWithoutBackground"]),
	variant: PropTypes.oneOf(["isi", "footnote"]),
};

InfoScroller.defaultProps = {
	clickHandler: () => {},
	classNamesArray: [],
};

const InfoScrollWrapper = (props) => (
	<ScrollWrapper>
		<InfoScroller {...props} />
	</ScrollWrapper>
);

export default InfoScrollWrapper;
