import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React from "react";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import { RegisterStyles } from "@components/Register/Register.styles";
import getTranslation from "@translation/translation";
import EnterNameForm from "../Shared/EnterNameForm";

const EnterName = ({ data, updateData, endpoint }) => (
	<LoggedOutStyles>
		<RegisterStyles>
			<div className={classNames("reverification")}>
				<TextStyled
					className={classNames(["form-title"])}
					$kind="heading04"
				>
					<strong>
						{getTranslation("frontend.modals.reverification.startPage.title")}
					</strong>
				</TextStyled >
				<TextStyled
					className={classNames(["form-subtitle"])}
					$kind="bodyLong02"
				>
					{getTranslation("frontend.modals.reverification.startPage.description")}
				</TextStyled >
				<EnterNameForm
					data={data}
					updateData={updateData}
					endpoint={endpoint}
				/>
			</div>
		</RegisterStyles>
	</LoggedOutStyles>
);

export default EnterName;
