import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyZIndex } from "@frontend/styled";
export const CardContainer = styled.div `
	.card {
		position: relative;
		margin-bottom: ${({ theme }) => theme.layout.cardsSpacing};

		${StyledMobileAndTabletPortraitQuery} {
			margin-bottom: 4px;
		}
	}

	.header-wrapper {
		${applyZIndex("card")};
	}

	.expanded {
		${applyZIndex("card")};
		position: fixed;
		max-width: calc(100vw - 40px);
		width: 840px;
		left: calc(50%);
		transform: translateX(-50%);
		top: ${({ theme }) => `calc(${theme.layout.topNavHeight} + ${theme.layout.alertModalHeight})`};
		bottom: 0;
		overflow: auto;
		margin-bottom: 0;
		-webkit-overflow-scrolling: touch;

		${StyledMobileAndTabletPortraitQuery} {
			position: fixed;
			top: ${({ theme }) => `calc(${theme.layout.topNavHeightMobile} + ${theme.layout.mobileFeedNavHeight})`};
			right: 0;
			bottom: 0;
			left: 0;
			width: auto;
			max-width: 100vw;
			height: auto;
			max-height: 100vh;
			transform: none;
			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: scroll;
		}
	}

	.editing {
		${applyZIndex("modal")};
	}

	.editing-profile-pic {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);

		.expanded & {
			transform: translate(-50%, 0);
		}
	}

	.banner {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

	.banner-topic {
		font-family: "Graphik", Arial, sans-serif;
		font-weight: 500;
		font-size: 11px;
		line-height: 14px;
		color: ${({ theme }) => theme.color.text05};
	}

	.banner-title {
		margin-left: 16px;
		font-family: "Graphik", Arial, sans-serif;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: ${({ theme }) => theme.color.text05};

		a {
			color: ${({ theme }) => theme.color.text05};
			text-decoration: underline;
		}

		p {
			margin: 0;
		}
	}

	.followed-indicator {
		color: ${({ theme }) => theme.color.text05};
		flex-grow: 1;
		text-align: right;
		margin-right: 20px;
	}

`;
export const SpacingContainer = styled.div `
	margin-top: 16px;
	position: relative;

	& > *, & > .top-section > * {
		margin-bottom: ${({ theme }) => theme.layout.cardsSpacing};
	}

	.top-section {
		position: relative;
		margin-bottom: 0;
	}

	& > .title-container {
		margin-bottom: 4px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 0;
		padding-top: 8px;

		& > *:last-child {
			margin-bottom: 4px;
		}
	}
`;
export const AdSpacingContainer = styled(SpacingContainer) `
	${StyledMobileAndTabletPortraitQuery} {
		padding-top: 16px;
	}
`;
export const ExpandedSpacingContainer = styled(SpacingContainer) `
	margin-top: 32px;

	& > .header-container {
		margin-bottom: 20px;
	}

	& > .title-container {
		margin-bottom: 8px;
	}

	& > .content-container {
		margin-bottom: 32px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 0;
	}
`;
export const HeaderContainer = styled.div.attrs({
    className: "header-container"
}) ``;
export const TitleContainer = styled.div.attrs({
    className: "title-container"
}) ``;
export const ContentContainer = styled.div.attrs({
    className: "content-container"
}) ``;
