import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Address from "@components/FormFields/components/Address/Address";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import InfoChangeCountry from "@components/Modal/Modals/ModalComponents/Withdrawal/InfoChangeCountry";
import { useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalText, ModalFooter, ModalSection } from "./../ModalComponents";

const AddNewAddress = ({
	cancelEventHandler,
	newAddressCompleteEventHandler,
	allRegions,
	allCountries,
	description,
	value,
}) => {
	const apiEndpoint = useApiEndpoint();
	const { locale } = useMemoizedContext("member", ["locale"]);

	if (!value) {
		value = {}; // Setting default value in case null was passed
	}

	if (!value.countryCode) {
		value.countryCode = locale.substring(3);
	}

	const [loading, setLoading] = useState(false);

	const [didClickSubmit, setDidClickSubmit] = useState(false);

	const [shouldValidateAddress, setShouldValidateAddress] = useState(false);
	const [addressValidationErrors, setAddressValidationErrors] = useState(null);

	const [globalValidationError, setGlobalValidationError] = useState("");

	useEffect(() => {
		if (shouldValidateAddress) {
			setShouldValidateAddress(false);
		}
	}, [shouldValidateAddress]);

	const formSubmitHandler = () => {
		setDidClickSubmit(true);
		setShouldValidateAddress(true);
	};

	const cancelClickEventHandler = () => {
		cancelEventHandler(true);
	};

	const addressChangedHandler = (newAddress) => {
		if (newAddress) {
			if (didClickSubmit) {
				setLoading(true);

				// Not resaving if data haven't changed
				if (Object.keys(newAddress).every((propName) => newAddress[propName] === value[propName])) {
					newAddressCompleteEventHandler({ ...value });
					return;
				}

				const data = {
					...newAddress,
					addressType: "residential",
				};

				apiEndpoint("settings/updateaddress", data).then((dataResponse) => {
					dataResponse.json().then((body) => {
						setLoading(false);

						if (dataResponse.ok) {
							if (newAddressCompleteEventHandler) {
								newAddressCompleteEventHandler({
									...data,
									addressId: body.addressId, 
								});
							}
						} else {
							setGlobalValidationError(body.message);

							if (body.fields) {
								setAddressValidationErrors(body.fields);
							}
						}
					});
				});
			}
		} else {
			setDidClickSubmit(false);
		}
	};

	return (
		<>
			<ModalSection>
				<ModalText>{description}</ModalText>
			</ModalSection>

			<ModalSection>
				<Address
					allCountries={allCountries}
					allRegions={allRegions}
					forceValidation={shouldValidateAddress}
					addressChangedEventHandler={addressChangedHandler}
					formValidationErrors={addressValidationErrors}
					{...value}
				/>
			</ModalSection>
			<GlobalValidationMessage text={globalValidationError} />
			<InfoChangeCountry />
			<ModalFooter
				{...{
					cancelModalEventHandler: cancelClickEventHandler,
					cancelText: getTranslation("frontend.generics.back", true),
					cancelTracking: {
						category: "withdrawal",
						action: "click",
						label: "cancel-add-new-address", 
					},
					submitEventHandler: formSubmitHandler,
					submitTracking: {
						category: "withdrawal",
						action: "click",
						label: "submit-add-new-address", 
					},
					submitDisabled: loading,
					submitText: getTranslation("frontend.generics.next", true),
				}}
			/>
		</>
	);
};

AddNewAddress.propTypes = {
	cancelEventHandler: PropTypes.func,
	newAddressCompleteEventHandler: PropTypes.func,
	allRegions: PropTypes.array,
	allCountries: PropTypes.array,
	description: PropTypes.object,
	value: PropTypes.object,
};

AddNewAddress.defaultProps = {
	cancelEventHandler: () => {},
	allRegions: [],
	allCountries: [],
	value: {},
};

export default AddNewAddress;
