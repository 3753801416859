import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { TextField, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { getLastFourDigitsOfTelephoneNumber, getObfuscateEmail } from "@components/Helpers/Helpers";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import RegistrationStep from "@components/Register/components/Registration/RegistrationStep";
import { METHOD_EMAIL, METHOD_SMS } from "@components/Register/components/Shared/SelectVerificationCodeMethod";
import { RegisterStyles, ReverificationWrap } from "@components/Register/Register.styles";
import useRegisterTrackEvent from "@components/Register/RegisterUtils";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const Form = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);
	const registerTrackEvent = useRegisterTrackEvent();

	const [shouldShowResendVerificationCodeLink, setShouldShowResendVerificationCodeLink] = useState(true);
	const [verificationCode, setVerificationCode] = useState("");
	const [
		showMobileVerificationResentConfirmationMessage,
		setShowVerificationResentConfirmationMessage,
	] = useState(false);

	const [globalValidationError, setGlobalValidationError] = useState("");

	const [verificationCodeValidationError, setVerificationCodeValidationError] = useState("");

	const [canSubmit, setCanSubmit] = useState(false);
	useEffect(() => {
		setCanSubmit(verificationCode?.length > 0);
	},[verificationCode])

	const verificationCodeChangeHandler = event => {
		setVerificationCode(event.target.value);
		setVerificationCodeValidationError("");
	};

	const validateForm = () => {
		let valid = true;

		if (verificationCode !== null && verificationCode.length === 6) {
			setVerificationCodeValidationError("");
		}
		else {
			setVerificationCodeValidationError(
				getTranslation("system.validationMessages.validationInvalidVerificationCode", true)
			);
			valid = false;
		}

		return valid;
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();

		if (e.name !== "back") {
			if (validateForm()) {
				setLoading(true);

				const requestData = {
					verificationCode,
				};

				let eventRegistrantToken = null;
				let eventToken = null;
				if ("undefined" !== typeof window) {
					if (window?.sermo?.temp) {
						registerTrackEvent({
							label: "verification",
							area: "verify-signup",
							action: "click",
						}, true);
					}
				}

				// registration-process-verification-code-send
				registerTrackEvent({
					label: "verification-code",
					action: "send",
				});

				apiEndpoint(`${endpoint}/verifycode`, requestData).then(dataResponse => {
					dataResponse.json().then(body => {
						setLoading(false);

						if (dataResponse.ok && body.verificationCodeAccepted) {
							if (window?.sermo?.temp?.eventToken) {
								eventToken = window.sermo.temp.eventToken;
							}

							registerTrackEvent({
								label: "verification",
								area: "verify-code",
								action: "success",
							}, true);

							updateData({
								...body,
							});
						}
						else {
							if (body.message) {
								setGlobalValidationError(body.message);
							}

							if (body.fields) {
								for (let property in body.fields) {
									switch (property) {
										case "verificationCode":
											setVerificationCodeValidationError(
												body.fields[property].join("<br />")
											);
											setVerificationCode("");
											break;
									}
								}
							}
						}
					});
				});
			}
		}
	};

	const goBackClientEventHandler = (e) => {
		e.preventDefault();
		updateData({ currentStep: RegistrationStep.EnterName });

		// registration-process-verify-back-click
		registerTrackEvent({
			label: "verify-back",
			action: "click",
		});

		if ("undefined" !== typeof window) {
			if (window.sermo?.temp) {
				registerTrackEvent({
					label: "verification",
					area: "cancel-signup",
					action: "click",
				}, true);
			}
		}
	};

	const resendVerificationCodeClientEventHandler = (e) => {
		e.preventDefault();

		setLoading(true);

		// registration-process-verify-resend-click
		registerTrackEvent({
			label: "verification-code",
			action: "resend",
		});

		if ("undefined" !== typeof window) {
			if (window.sermo.temp) {
				registerTrackEvent({
					label: "verification",
					area: "resend-code",
					action: "click",
				}, true);
			}
		}

		// registration-process-verification-code-send
		registerTrackEvent({
			label: "verification-code",
			action: "send",
		});

		apiEndpoint(`${endpoint}/resendverificationcode`, { method: data.verificationCodeMethod }).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);

				if (dataResponse.ok) {
					setShouldShowResendVerificationCodeLink(body.canResendMoreVerificationCodes);
					setShowVerificationResentConfirmationMessage(body.verificationCodeResent);
				}
				else {
					if (body.message) {
						// TODO: handle body.failedVerificationCodeAttempts ?
						setGlobalValidationError(body.message);
					}

					if (body.fields !== null) {
						for (let property in body.fields) {
							switch (property) {
								case "verificationCode":
									setVerificationCodeValidationError(body.fields[property].join("<br />"));
									break;
							}
						}
					}
				}
			});
		});
	};

	const sendMessage = useMemo(() => (
		data.verificationCodeMethod === METHOD_SMS
			? getTranslation("frontend.registration.sentTo", true, getLastFourDigitsOfTelephoneNumber(data.mobileNumber, data.selectedCountryCode, data.availableCountries))
			: getTranslation("frontend.registration.sentTo", true, getObfuscateEmail(data.emailAddress))
	), [data.verificationCodeMethod]);

	const switchVerificationMethodText = useMemo(() => (
		data.verificationCodeMethod === METHOD_SMS
			? getTranslation("frontend.registration.emailInstead", true)
			: getTranslation("frontend.registration.textInstead", true)
	), [data.verificationCodeMethod]);

	const switchVerificationMethod = (e) => {
		e.preventDefault();

		const newMethod = data.verificationCodeMethod === METHOD_SMS
			? METHOD_EMAIL
			: METHOD_SMS;

		setLoading(true);

		// registration-process-verify-email-instead-click
		// registration-process-verify-text-instead-click
		registerTrackEvent({
			label: "verify",
			action: `${newMethod === METHOD_SMS
				? "text"
				: "email" }-instead-click`,
		});

		apiEndpoint(`${endpoint}/resendverificationcode`, { method: newMethod }).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);

				if (dataResponse.ok) {
					registerTrackEvent({
						label: "verification",
						area: "switch-verification-method",
						action: "success",
					}, true);

					updateData({
						...body,
						verificationCodeMethod: newMethod,
					});
				}
				else {
					if (body.message) {
						setGlobalValidationError(body.message);
					}
				}
			});
		});
	}

	return (
		<LoggedOutStyles>
			<RegisterStyles>
				<TextStyled
					className={classNames(["form-title"])}
					$kind="heading04"
				>
					{getTranslation("frontend.registration.mobileVerificationCodePage.title")}
				</TextStyled>
				<TextStyled
					className={classNames(["form-code-sent-to"])}
					$kind="bodyLong02"
				>
					{sendMessage}
				</TextStyled>

				{
					showMobileVerificationResentConfirmationMessage && (
						<TextStyled
							className={classNames(["form-code-resent-confirm-message"])}
							$kind="bodyShort02"
						>
							{getTranslation("frontend.registration.mobileVerificationCodePage.resentVerificationCodeSuccess")}
						</TextStyled>
					)
				}

				<form
					onSubmit={formSubmitHandler}
					className={classNames(["form"])}
				>
					<div className={classNames(["form-field-row"])}>
						<TextField
							name={"verificationCode"}
							type="text"
							autoComplete="off"
							placeholder={
								getTranslation(
									"frontend.registration.mobileVerificationCodePage.inputMobileVerificationCodeLabel",
									true
								)
							}
							validationError={verificationCodeValidationError}
							handleChange={verificationCodeChangeHandler}
							value={verificationCode}
						/>
					</div>

					<GlobalValidationMessage text={globalValidationError} />

					<div className={classNames(["submit-container"])}>
						<div className={classNames(["submit-container-side"])}>
							<div className={classNames(["submit-container--back-button"])}>
								<Button
									name="back"
									size="medium"
									style="flatNeutral"
									disabled={loading}
									clickHandler={goBackClientEventHandler}
								>
									{getTranslation("frontend.generics.back", true)}
								</Button>
							</div>
							<Button
								name="submit"
								size="medium"
								type="submit"
								disabled={!canSubmit || loading}
								loading={loading}
								clickHandler={formSubmitHandler}
							>
								{getTranslation("frontend.generics.next", true)}
							</Button>
						</div>

						<TextStyled
							className={classNames("submit-container--link")}
							$kind="bodyLong00"
						>

							{
								shouldShowResendVerificationCodeLink && (
									<div>
										{
											getTranslation(
												"frontend.registration.mobileVerificationCodePage.didNotReceiveCode", false
											)
										}&nbsp;
										<a
											href=""
											disabled={loading}
											onClick={resendVerificationCodeClientEventHandler}
										>
											{
												getTranslation(
													"frontend.registration.mobileVerificationCodePage.resendCodeLinkText", true
												)
											}
										</a>
									</div>
								)
							}
							{
								data.allowedVerificationMethods?.length > 1
								&& <a
									href=""
									disabled={loading}
									onClick={switchVerificationMethod}
								>
									{switchVerificationMethodText}
								</a>
							}
						</TextStyled>
					</div>
				</form>
			</RegisterStyles>
		</LoggedOutStyles>
	)
}
// TODO: take care of empty anchors that should be buttons

const EnterVerificationCode = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");
	const trackEvent = useTrackEvent();
	const registerTrackEvent = useRegisterTrackEvent();

	useEffect(() => {
		// registration-process-verification-page-view
		registerTrackEvent({
			label: "verification-page",
			action: "view",
		});
	},[])

	return (
		<>
			{
				isRegister
				&& <LoggedOutStyles data-component="EnterVerificationCode">
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<Form
								data={data}
								updateData={updateData}
								endpoint={endpoint}
							/>
						</div>
					</div>
				</LoggedOutStyles>
			}
			{
				!isRegister
				&& <ReverificationWrap data-component="EnterVerificationCode">
					<Form
						data={data}
						updateData={updateData}
						endpoint={endpoint}
					/>
				</ReverificationWrap>
			}
		</>
	);
};

Form.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

EnterVerificationCode.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

export default EnterVerificationCode;
