import React, { useEffect, useState, useMemo, useRef } from "react";
import useResizeAware from "react-resize-aware";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import useFooterContext from "@components/AdUnits/Footer/useFooterContext";
import { InFeedCardFooter } from "@components/DetachedFooter";
import { DEFAULT_HEIGHT } from "@components/DetachedFooter/utils";
import InfoScroller from "@components/InfoScroller/InfoScroller";
import { STICKY_LOCATION } from "@components/Layout";
import { TabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { AdTypeTypes, CardTypeTypes } from "@frontend/types/Post/postData";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
const DimensionsWrapper = styled.div `
	&& {
        position: relative;
        padding: 0;
        margin: 0;
    }
`;
const SPFooter = ({ clickHandler, contentHeight, inFeed, type, }) => {
    const { customStyle, footnoteAdStyle, } = useMemoizedContext("postData", [
        "customStyle",
        "footnoteAdStyle",
    ]);
    const uuid = useRef(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    const [styles, setStyles] = useState({});
    const [classNamesArray, setClassNamesArray] = useState([]);
    const [render, setRender] = useState(false);
    const [initialHeight, setInitialHeight] = useState(DEFAULT_HEIGHT);
    const isDesktop = useMediaQuery({ query: TabletLandscapeAndDesktopQuery });
    const [dimensionsListener, sizes] = useResizeAware();
    const isMounted = useRef(false);
    const { setFooterHeight } = useMemoizedContext("detachedFooter", []);
    const { expanded, } = useMemoizedContext("postUIViewing", [
        "expanded",
    ]);
    const contextValues = useFooterContext(type);
    const getInitialHeightFromCustomStyles = (customStyles) => {
        let ih;
        if (isDesktop) {
            ih = customStyles?.stickyInitialHeight?.replace("px", "")
                || (type === "isi"
                    ? customStyle
                    : footnoteAdStyle)?.stickyInitialHeight?.replace("px", "") || initialHeight;
        }
        else {
            ih = customStyles?.stickyInitialHeightMobile?.replace("px", "")
                || (type === "isi"
                    ? customStyle
                    : footnoteAdStyle)?.stickyInitialHeightMobile?.replace("px", "") || initialHeight;
        }
        return +ih;
    };
    const hasFeatured = contextValues.attachments.find((attachment) => Object.prototype.hasOwnProperty.call(attachment, "featured") && attachment.featured);
    const shouldRemovePadding = CardTypeTypes.AD === contextValues.cardType
        && "SponsoredPost" === contextValues.adType
        && !contextValues.expanded;
    const getClassNamesArray = () => [
        "info-scroller",
        { "above-poll": "AbovePoll" === contextValues.location },
        { "expanded": contextValues.expanded },
        { "has-featured": hasFeatured },
        { "in-feed": inFeed },
        { "on-top": "Top" === contextValues.location },
        { "suppress-padding": shouldRemovePadding },
        { "isi-only": "ISIOnly" === contextValues.attachmentsView && "HeadlineOnly" === contextValues.fieldsView && !contextValues.expanded },
        { "headline-and-read-more": "HeadlineAndReadMore" === contextValues.fieldsView && contextValues.attachmentsView !== "FeatureImageAndISI" && !contextValues.expanded,
        },
    ];
    const onClickHandler = (e) => {
        if (type === "isi" && contextValues.clickTags?.isiClick) {
            contextValues.clickTags.isiClick.forEach((url) => {
                fetchUrl(url);
            });
        }
        if (clickHandler) {
            clickHandler(e);
        }
    };
    const shouldRender = useMemo(() => {
        if (!contextValues.text) {
            return false;
        }
        if (CardTypeTypes.RESOURCE_CENTER_ITEM === contextValues.cardType) {
            return true;
        }
        if (CardTypeTypes.AD === contextValues.cardType) {
            if (!contextValues.expanded
                && contextValues.attachmentsView
                && ["FeatureImageAndISI", "ISIOnly"].includes(contextValues.attachmentsView)) {
                return true;
            }
            if (AdTypeTypes.SPONSORED === contextValues.adType
                && !contextValues.showBlackBoxText) {
                return contextValues.expanded;
            }
            if ([
                AdTypeTypes.CAROUSEL,
                AdTypeTypes.IN_FEED,
                AdTypeTypes.LIVE_EVENT,
                // @ts-ignore
            ].includes(contextValues.adType)) {
                return true;
            }
            return contextValues.showBlackBoxText;
        }
    }, [
        contextValues.adType,
        contextValues.attachmentsView,
        contextValues.cardType,
        contextValues.expanded,
        contextValues.text,
        contextValues.showBlackBoxText,
    ]);
    const isExpandable = useMemo(() => (STICKY_LOCATION === contextValues.location
        || contextValues.isExpandable
        || CardTypeTypes.RESOURCE_CENTER_ITEM === contextValues.cardType), [
        contextValues.cardType,
        contextValues.isExpandable,
        contextValues.location
    ]);
    useEffect(() => {
        setRender(shouldRender);
    }, [contextValues.expanded]);
    useEffect(() => {
        setClassNamesArray(getClassNamesArray());
    }, [
        contextValues.expanded,
        contextValues.location,
        hasFeatured,
        inFeed,
        contextValues.attachmentsView,
        contextValues.fieldsView,
        contextValues.adType,
        shouldRemovePadding,
    ]);
    useEffect(() => {
        if (render && (isExpandable || inFeed || !expanded)) {
            // Count the height of the footer only when it is expandable or inFeed on order to:
            //   - inFeed: calculate the available space to expand the other footer in the card.
            //   - Other: calculate required space to avoid footer overlapping.
            setFooterHeight(initialHeight, uuid.current, type);
        }
        return () => {
            setFooterHeight(0, uuid.current, type);
        };
    }, [expanded, initialHeight, inFeed, isExpandable, render, type]);
    useEffect(() => {
        const styleHeight = styles
            ? Number(getInitialHeightFromCustomStyles(styles))
            : 0;
        if (!isMounted.current && sizes.height) {
            setInitialHeight(styleHeight || sizes.height);
            isMounted.current = true;
        }
    }, [sizes.height, styles]);
    useEffect(() => {
        if (contextValues.isAutoSized && !contextValues.expanded) {
            setStyles({
                height: "auto",
                maxHeight: "none",
            });
        }
    }, [contentHeight, contextValues.isAutoSized, contextValues.expanded]);
    return useMemo(() => {
        if (!render) {
            return null;
        }
        return (<DimensionsWrapper>
				{dimensionsListener}
				{isExpandable && (<InFeedCardFooter type={type} text={contextValues.text} className={contextValues.style} customStyle={styles} onClick={onClickHandler} isPadded={inFeed} initialHeight={initialHeight}/>)}
				{!isExpandable && (<InfoScroller text={contextValues.text} autoScrollDelay={contextValues.expanded
                    ? false
                    : contextValues.autoScrollDelay} classNamesArray={classNamesArray} customStyles={styles} clickHandler={onClickHandler} style={contextValues.style} variant={type}/>)}
			</DimensionsWrapper>);
    }, [
        contextValues.autoScrollDelay,
        classNamesArray,
        contextValues.expanded,
        inFeed,
        render,
        styles,
        type,
        initialHeight,
    ]);
};
export default SPFooter;
