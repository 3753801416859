import { css } from "styled-components";

export const getVariableFromCSS = (key) => getComputedStyle(document.documentElement).getPropertyValue(key);

// Values for raw calculations (styled-components)
export const topNavHeightValue = 57 + 1; // 1px for border & 57px is the min height of the top nav
export const topNavHeightMobileValue = 50;
export const mobileFeedNavHeightValue = 50;
export const expandedPostBannerHeightValue = 54;

export const MembershipGlobalVariables = css`
    :root {
        /* Site Wide */
        --site-max-width: 1200px;
        --site-padding: 20px;
        --rail-padding: 16px;
        --icon-size: 24px;
        --border-radius: 4px;
        --module-wrapper-border-radius: 8px;
        --menu-spacing: 10px;
        --text-spacing: 8px;
        --cards-spacing: 16px;

        /* Top Nav */
        --logo-width: 77px;
        --logo-margin: 33px;
        --top-nav-margin-bottom: 40px;
        --top-nav-line-height: 16px;
        --top-nav-padding: 8px;

        /* Alert Modal */
        --alert-modal-height: 54px;
        --alert-modal-height-mobile: 100px;

        /* Content */
        --content-padding-top: 40px;

        /* Cards */
        --cards-width: 560px;
        --cards-width-expanded: 840px;
        --cards-padding-mobile: 16px;
        --cards-padding-secondary-topbar: 12px;
        --cards-padding-secondary-icon: 8px;
        --cards-padding-topbar: 24px;
        --cards-padding-desktop: 32px;

        /* Search */
        --search-width: 327px;
        --search-padding: 12px;
        --search-icon-size: 17px;

        /* Left Rail */
        --left-rail-width: 256px;
        --left-rail-margin: 16px;
        --left-rail-button-padding: 4px;
        --left-rail-button-margin: 4px;
        --left-rail-button-height: 32px;

        /* Menu */
        --menu-margin: 24px;
        --menu-padding: 6px;
        --menu-heading-margin: 8px;
        --menu-icon-margin: 12px;

        /* Right Rail */
        --right-rail-width: 312px;
        --right-rail-margin: 16px;

        /* Messages */
        --messages-list-width: 283px;
        --messages-spacing: 16px;
        --messages-header-padding-mobile: var(--messages-spacing);
        --messages-header-line-height-total: calc(20px + 16px);
        --messages-header-total: calc(var(--messages-header-line-height-total) + (var(--messages-header-padding-mobile) * 2) + 1px);
        --messages-padding: var(--messages-spacing);
        --messages-footer-padding: var(--messages-spacing);

        /* Feed */

        /* Buttons */
        --button-icon-margin-horizontal: 7px;
        --button-icon-margin-vertical: 4px;
        --button-tab-padding-top-bottom: 6px;
        --button-tab-padding-left-right: 12px;

        /* Heading */
        --heading-height: 16px;
        --heading-font-size: 11px;
        --heading-font-weight: 600;
        --heading-letter-spacing: 0.5px;

	  	/* Events */
	  	--video-block-width: 848px;
	  	--no-video-block-width: 832px;

        /* Useful calculations */
        /* this is used to determine site padding behind the top nav */
        --top-nav-height: ${topNavHeightValue}px;
        --top-nav-height-mobile: ${topNavHeightMobileValue}px;
        --mobile-feed-nav-height: ${mobileFeedNavHeightValue}px;
        --expanded-post-banner-height: ${expandedPostBannerHeightValue}px;

        /* Logged out header. */
        --logged-out-header-height: 104px;
        --logged-out-form-padding: 47px;
    }
`;