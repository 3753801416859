import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import getTranslation from "@translation/translation";
import styles from "../ModalComponents/Withdrawal/ModalWithdrawalComponents.scss";
import { ModalText, ModalFooter, ModalSeparator, ModalSection, Withdrawal, ModalTitle } from "./../ModalComponents";
import { getTranslationSubKey, TYPE_MASTERCARD } from "./withdrawalUtils";

const WithdrawalComplete = ({
	confirmationCode,
	currency,
	emailAddress,
	fixedFeeAmount,
	variableFeePercentage,
	variableFeeAmount,
	targetCurrency,
	targetCurrencyMultiplier,
	withdrawalCode,
	withdrawalCompleteEventHandler,
	withdrawalModalTextLabelChangeHandler,
	withdrawAmount,
	customCompleteStepDescription,
	issueNewCard,
}) => {
	const translationKey = useMemo(() => getTranslationSubKey(withdrawalCode), []);

	// Clearing modal title (textLabel)
	useEffect(() => {
		withdrawalModalTextLabelChangeHandler(
			<strong>{getTranslation(`frontend.modals.withdrawal.stepCompleteTitle`)}</strong>
		);
	}, []);

	const withdrawalCompleteClickHandler = () => {
		withdrawalCompleteEventHandler();
	};

	return (
		<>
			<ModalSection>
				<ModalText>
					<div styleName="styles.modal-text">
						{customCompleteStepDescription || getTranslation(`frontend.modals.withdrawal.${translationKey}.stepCompleteDescription`)}
					</div>
				</ModalText>
			</ModalSection>

			<ModalSection>
				<ModalText>
					<div styleName="styles.modal-text">
						{getTranslation(`frontend.modals.withdrawal.contactSupport`)}
					</div>
				</ModalText>
			</ModalSection>

			<ModalSection>
				<ModalSeparator />

				<Withdrawal.DetailPair
					{...{
						title: getTranslation(`frontend.modals.withdrawal.confirmationNumber`),
						value: confirmationCode,
					}}
				/>

				<ModalSeparator />

				<Withdrawal.DetailPair
					{...{
						title: getTranslation(`frontend.modals.withdrawal.paymentMethodTitle`),
						value: getTranslation(`frontend.modals.withdrawal.${translationKey}.paymentMethodActual`),
					}}
				/>

				{
					emailAddress && (
						<>
							<ModalSeparator />

							<Withdrawal.DetailPair
								{...{
									title: getTranslation(`frontend.modals.withdrawal.emailAddress`),
									value: emailAddress,
								}}
							/>
						</>
					)
				}

				<ModalSeparator />

				<Withdrawal.AmountOverview
					{...{
						currency: currency,
						fixedFeeAmount: withdrawalCode !== TYPE_MASTERCARD || issueNewCard
							? fixedFeeAmount
							: 0,
						variableFeePercentage: variableFeePercentage,
						variableFeeAmount: variableFeeAmount,
						withdrawAmount: Number(withdrawAmount),
						showOriginalAmount: true,
						showFee: true,
						targetCurrency: targetCurrency,
						targetCurrencyMultiplier: targetCurrencyMultiplier,
					}}
				/>
			</ModalSection>

			<ModalFooter
				{...{
					submitEventHandler: withdrawalCompleteClickHandler,
					submitText: getTranslation("frontend.generics.backToFeed", true),
				}}
			/>
		</>
	);
};

WithdrawalComplete.propTypes = {
	confirmationCode: PropTypes.string,
	currency: PropTypes.string,
	address: PropTypes.object,
	emailAddress: PropTypes.string,
	fixedFeeAmount: PropTypes.number,
	variableFeePercentage: PropTypes.number,
	variableFeeAmount: PropTypes.number,
	targetCurrency: PropTypes.string,
	targetCurrencyMultiplier: PropTypes.number,
	withdrawalCode: PropTypes.string,
	withdrawalCompleteEventHandler: PropTypes.func,
	withdrawalModalTextLabelChangeHandler: PropTypes.func,
	withdrawAmount: PropTypes.number,
	customCompleteStepDescription: PropTypes.string,
	issueNewCard: PropTypes.bool,
};

WithdrawalComplete.defaultProps = {
	targetCurrency: "",
	targetCurrencyMultiplier: 0,
};

export default WithdrawalComplete;
