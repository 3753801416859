import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import useResizeAware from "react-resize-aware";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useTrackEvent } from "@frontend/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { fetchUrl } from "@frontend/Utils";
import { useGetPostTrackingCategory, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./Title.styles";

const Title = () => {
	const category = useGetPostTrackingCategory();
	const trackEvent = useTrackEvent();
	const [resizeListener, sizes] = useResizeAware();

	const {
		dispatch: dispatchPostUIViewing,
		expanded,
		showOriginalContent,
		titleHeight,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
		"showOriginalContent",
		"titleHeight",
	]);

	const {
		adFrequency,
		adId,
		adType,
		cardType,
		clickTags,
		fieldsView,
		header,
		id,
		originalTitle,
		postReadMoreText,
		sermoContentCardId,
		title,
		type,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"adType",
		"cardType",
		"clickTags",
		"fieldsView",
		"header",
		"id",
		"originalTitle",
		"postReadMoreText",
		"sermoContentCardId",
		"title",
		"type",
	]);

	const {
		locale,
		queryParams,
	} = useMemoizedContext("member", [
		"queryParams",
		"readOnlyMember",
	]);

	// TODO: move this logic to content portion so that all read more logic is in one place ???
	const shouldShowReadMore = () => ["PatientCase", "Discussion", "ContentCard", "ResourceCenterItem"].includes(type)
			&& "HeadlineAndReadMore" === fieldsView
			&& !expanded;

	const [readMore, setReadMore] = useState(shouldShowReadMore());

	const getReadMoreText = () => {
		if ("Ad" === cardType && "SponsoredPost" === adType) {
			if (readMore && "LearnMore" === postReadMoreText) {
				return getTranslation("frontend.card.learnMore");
			}
		}
		return getTranslation("frontend.card.readMore");
	};

	const readMoreClickEventHandler = e => {
		let label = "read-more";

		if ("LearnMore" === postReadMoreText) {
			label = "learn-more";
		}

		e.eventSource = label;

		trackEvent({
			category,
			action: "click",
			area: label,
			label: label,
			postId: id,
			adId: adId,
			adFrequency: adFrequency,
			locale,
			queryParams,
			sermoContentCardId,
		});

		if (clickTags?.readMore) {
			clickTags.readMore.forEach((url) => {
				fetchUrl(url);
			})
		}
	};

	useEffect(() => {
		if (
			sizes.height
			&& sizes.height !== titleHeight
		) {
			dispatchPostUIViewing({
				type: PostUIViewingActionTypes.SET_TITLE_HEIGHT,
				payload: {
					titleHeight: sizes.height,
				},
			});
		}
	},[
		dispatchPostUIViewing,
		sizes.height,
		titleHeight,
	]);

	// const maxLength = 150;

	// const titleChange = value => {
	// 	if ("Ad" !== cardType) {
	// 		updateValues({ title: value.substring(0, maxLength) });
	// 	}
	// };

	// useEffect(() => {
	// 	titleChange(header || title);
	// }, [])

	useEffect(() => {
		setReadMore(shouldShowReadMore());
	}, []);

	const addEventSource = e => {
		e.eventSource = "headline";
	};

	const getSponsoredTitle = () => showOriginalContent
		? originalTitle
		: title;

	const getTitle = () => showOriginalContent
		? originalTitle
		: header || title;

	if (
		"NoText" === fieldsView
		&& !expanded
	) {
		return null;
	}

	if (
		(
			adType
			&& adType === "SponsoredPost"
		)
		|| CardTypeTypes.RESOURCE_CENTER_ITEM === cardType
	) {
		return (
			<>
				<Styles.TitleContainer>
					{resizeListener}
					<Styles.Title
						dangerouslySetInnerHTML={{ __html: getSponsoredTitle() }}
						$kind={
							expanded
								? "heading04"
								: "heading03"
						}
						onClick={addEventSource}
						$expanded={expanded}
					/>
					{
						readMore && (
							<>
								<Styles.ReadMoreButton $kind="bodyShort02">
									<div onClick={readMoreClickEventHandler}>
										{getReadMoreText()}
									</div>
								</Styles.ReadMoreButton>
							</>
						)
					}
				</Styles.TitleContainer>
			</>
		);
	}

	return (
		<Styles.Title
			$kind={
				expanded
					? "heading04"
					: "heading03"
			}
			onClick={addEventSource}
			$expanded={expanded}
		>
			{getTitle()}
		</Styles.Title>
	);
};

Title.propTypes = {
	setTitleHeight: PropTypes.func.isRequired,
}

Title.defaultProps = {
	setTitleHeight: () => {},
}

export default Title;
