
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "abortcontroller-polyfill";
import * as mobileApp from "./mobile-app";
// const whyDidYouRender = require("@welldone-software/why-did-you-render");

// whyDidYouRender(React, {
// 	trackAllPureComponents: true,
// 	trackHooks: true,
// });

// Element.closest polyfill from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const element = document.getElementById("root");
// don't bother with hydrate in dev
// TODO: fix this properly but this avoids the error in dev
// if ( module.hot ) {
// 	hydrateRoot(
// 		element,
// 		<BrowserRouter
// 			basename={baseUrl}
// 		>
// 			<App {...window.data} />
// 		</BrowserRouter>
// 	);
// } else {
const root = createRoot(element)
root.render(
	<BrowserRouter
		basename={baseUrl}
	>
		<App {...window.data} />
	</BrowserRouter>
);
// }

if (module["hot"]) {
	module["hot"].accept();
}

if (window?.sermo !== undefined) {
	window.sermo.mobileApp = mobileApp;
}
