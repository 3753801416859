import { Icon, OverflowHorizontalIcon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonWithMenu } from "@components/Button/Button";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import MiniProfile from "@components/MiniProfile/MiniProfile";
import FollowedIndicator from "@components/PostViewer/components/FollowedIndicator/FollowedIndicator";
import { formatLocaleDate } from "@components/Resource/utils";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./CardHeader.styles";
import CardHeaderMenu from "./components/CardHeaderMenu/CardHeaderMenu";

const CardHeader = () => {
	let navigate = useNavigate();

	const {
		draftStatus,
	} = useMemoizedContext("postUI", [
		"draftStatus",
	]);
	const editing = DraftStatusTypes.EDITING === draftStatus;
	const composing = DraftStatusTypes.COMPOSING === draftStatus;

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		brand,
		cardType,
		following,
		hidePublishDate,
		id: postId,
		isEdited,
		postDate,
		theme,
		topic,
		type,
		user,
	} = useMemoizedContext("postData", [
		"brand",
		"cardType",
		"following",
		"hidePublishDate",
		"id",
		"isEdited",
		"postDate",
		"theme",
		"topic",
		"type",
		"user",
	]);

	const { locale } = useMemoizedContext("member", ["locale"]);

	const getUserUsed = useCallback(() => {
		if (theme?.type) {
			// Adapt Theme data to Miniprofile component
			return {
				name: theme.header,
				specialties: [{ name: theme.subheader }],
				...(theme.type === "Icon" && { profilePic: theme.imageUrl } )
			};
		} else if (brand) {
			return brand;
		} else {
			return user;
		}
	}, [
		brand,
		theme,
		user,
	]);

	const [userUsed, setUserUsed] = useState(getUserUsed());
	const isSponsored = Boolean(brand);
	const isTopBar = theme?.type === "TopBar";
	const isResourceRelated = [
		CardTypeTypes.RESOURCE_CENTER,
		CardTypeTypes.RESOURCE_CENTER_ITEM,
	].includes(cardType);

	React.useEffect(() => {
		setUserUsed(getUserUsed());
	}, [getUserUsed]);

	const dateValue = useMemo(() => {
		if (postDate?.includes("T")) {
			return formatLocaleDate(locale, postDate);
		}

		return postDate;
	}, [
		locale,
		postDate,
	]);

	if (
		composing
		|| ["Comment"].includes(type)
		|| (
			isResourceRelated
			&& !userUsed
			&& (
				!postDate
				|| hidePublishDate
			)
		)
	) {
		return null;
	}

	return (
		<Styles.WrapCardHeader
			data-component={"CardHeader"}
			expanded={expanded}
			themeType={theme?.type}
		>
			{
				isTopBar && (
					<Styles.Header>
						{theme.header}
						{postDate && <Styles.Date>{postDate}</Styles.Date>}
					</Styles.Header>
				)
			}
			{
				!isTopBar && (
					<MiniProfile
						user={userUsed}
						postDate={dateValue}
						sponsored={isSponsored}
						isEdited={isEdited}
					/>
				)
			}
			{
				!expanded
				&& !isSponsored
				&& !isResourceRelated
				&& (
					<Styles.TopicAndMenu>
						{
							!composing
							&& !editing
							&& (
								<ButtonWithMenu
									activeClassName="active"
									activeUnderline={false}
									dataComponent="CardOverflowButton"
									icon={
										<Icon
											src={OverflowHorizontalIcon}
											width={24}
											height={24}
										/>
									}
									menu={
										{
											component: CardHeaderMenu,
											name: `card-header-${postId}`,
										}
									}
									theme="light"
									style="icon"
									size="large"
								/>
							)
						}
						{
							topic && (
								<Styles.TopicIndicator>
									<TextStyled
										$kind={"overlineLegacy01"}
										onClick={() => navigate(`/feed/topic/${topic.slug}`)}
									>
										{topic.name}
									</TextStyled>
								</Styles.TopicIndicator>
							)
						}
					</Styles.TopicAndMenu>
				)
			}
			{
				expanded
				&& following
				&& (
					<MobileAndTabletPortrait>
						<Styles.WrapFollowedIndicator>
							<FollowedIndicator />
						</Styles.WrapFollowedIndicator>
					</MobileAndTabletPortrait>
				)
			}
		</Styles.WrapCardHeader>
	);
};

export default CardHeader;
