import { Icon, StarOfLifeSVG, HashTagSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { Loading } from "@components/Helpers/Helpers";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useCloseModal } from "@contexts/UI";
import { useFetch } from "@hooks/Hooks";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./BrowseTopics.scss";

const Topic = ({ topic }) => {
	const { name, slug, type, following, followers } = topic;
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();

	const {
		memberTopicFollow,
		memberTopicUnfollow,
	} = useMemoizedContext("member", [
		"memberTopicFollow",
		"memberTopicUnfollow",
	]);

	const [numFollowers, setNumFollowers] = useState(followers);
	const [isFollowing, setIsFollowing] = useState(following);
	const [redirect, setRedirect] = useState(false);

	const clickHandler = () => {
		setRedirect(`/feed/topic/${slug}`);
	};

	useEffect(() => {
		if (redirect) {
			closeModal("browse-topics");
		}
	}, [redirect]);

	const toggleFollowing = (e) => {
		e.stopPropagation();
		if (isFollowing) {
			apiEndpoint("topics/unfollow", { topicId: topic.id });
			memberTopicUnfollow(topic);
			setNumFollowers(numFollowers - 1);
		} else {
			apiEndpoint("topics/follow", { topicId: topic.id });
			memberTopicFollow(topic);
			setNumFollowers(numFollowers + 1);
		}

		setIsFollowing(!isFollowing);
	};

	const followingText = isFollowing
		? getTranslation("frontend.generics.following", true)
		: `+ ${getTranslation("frontend.generics.follow", true)}`;

	return (
		<div
			data-component="Topic"
			styleName="styles.topic"
			onClick={clickHandler}
		>
			{redirect && <Navigate to={redirect} />}
			<div styleName="styles.icon">
				<Icon
					src={
						type === "Specialty"
							? StarOfLifeSVG
							: HashTagSVG
					}
					width={27}
					height={27}
				/>
			</div>
			<div styleName="styles.content">
				<div styleName="styles.content-container">
					<div styleName={classNames("typography.heading-brand-02", "styles.name")}>{name}</div>
					<div styleName={classNames("typography.body-short-brand-00", "styles.followers")}>
						{numFollowers.toLocaleString()} {
							numFollowers === 1
								? getTranslation("frontend.browsetopics.follower", true)
								: getTranslation("frontend.browsetopics.followers", true)
						}
					</div>
				</div>
			</div>
			<div styleName="styles.following-button">
				<Button
					style="flatNeutral"
					size="small"
					clickHandler={toggleFollowing}
				>
					{followingText}
				</Button>
			</div>
		</div>
	);
};

Topic.propTypes = {
	topic: PropTypes.shape({
		name: PropTypes.string,
		slug: PropTypes.string,
		type: PropTypes.string,
		following: PropTypes.bool,
		followers: PropTypes.number,
	}),
};

const LoadingWrap = styled.div`
	width: 624px;
	margin-top: 20px;

	& > * {
		max-height: 500px;
	}
`;

const BrowseTopics = () => {
	const [data, loading] = useFetch("api/topics/listtopics", {
		orderBy: "Name"
	});

	if (loading) {
		return <LoadingWrap><Loading /></LoadingWrap>;
	}

	return (
		<div
			data-component="BrowseTopicsModal"
			styleName="styles.browse-topics">
			<ModuleWrapper padded={false}>
				<div styleName="styles.topics-container">
					{
						data.topics.map((topic, i) => <Topic
							topic={topic}
							key={i}
						/>)
					}
				</div>
			</ModuleWrapper>
		</div>
	);
};

export default BrowseTopics;
