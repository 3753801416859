import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { css } from "styled-components";
import { expandedStyles, legacyStyles, marginBottomNonAdStyles, postContentStyles, } from "@components/PostContent";
const customLinks = css `
	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			color: ${({ theme }) => theme.color.defaultLinkHover};
		}
	}
`;
export const Body = styled(TextStyled) `
	${postContentStyles};
	${expandedStyles};
	${marginBottomNonAdStyles};
	${legacyStyles};
	${customLinks};
`;
export const FinePrintHeader = styled(TextStyled) `
	${customLinks};
`;
export const FinePrintText = styled(TextStyled) `
	${marginBottomNonAdStyles};
	${legacyStyles};
	${customLinks};
`;
export const IntroText = styled(TextStyled) `
	${marginBottomNonAdStyles};
	${legacyStyles};
	${customLinks};
`;
