import React from "react";
import Button from "@components/Button/Button";
import { TextField } from "@components/FormFields/FormFields";
import ParentContextUpdater from "@components/ParentContextUpdater";
import PollAnswerEdit, { PollAnswerEditAPISync } from "@components/PostEditor/components/PollEdit/components/PollAnswerEdit";
import * as Styles from "@components/PostEditor/components/PollEdit/PollEdit.styles";
import usePollAPI from "@components/PostEditor/components/PollEdit/usePollAPI";
import { PollAnswerContext, PollAnswerProvider } from "@contexts/PollAnswer";
import { PollQuestionActionTypes } from "@contexts/PollQuestion";
import { useSaving } from "@contexts/PostUI/Editing";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const questionTypeOptions = [
    {
        value: "multi",
        name: getTranslation("frontend.poll.typeMulti", true),
        label: getTranslation("frontend.poll.typeMulti", true),
        id: "multi",
    },
    {
        value: "single",
        name: getTranslation("frontend.poll.typeSingle", true),
        label: getTranslation("frontend.poll.typeSingle", true),
        id: "single",
    },
];
const PollQuestionEdit = ({ index }) => {
    const pollAPI = usePollAPI();
    const save = useSaving();
    const { type, } = useMemoizedContext("postData", [
        "type",
    ]);
    const { questions, } = useMemoizedContext("poll", [
        "questions",
    ]);
    const isOneOfMany = questions.length > 1;
    const { answers, dispatch: dispatchPollQuestion, id: questionId, isMultipleChoice, question, } = useMemoizedContext("pollQuestion", [
        "answers",
        "id",
        "isMultipleChoice",
        "question",
    ]);
    const handleChangeQuestion = (e) => {
        save.start(`updateQuestion${questionId}`);
        dispatchPollQuestion({
            type: PollQuestionActionTypes.SET_QUESTION_TEXT,
            payload: {
                text: e.target.value,
            },
        });
    };
    const handleChangeQuestionChoiceType = (e) => {
        pollAPI.updateQuestionIsMultipleChoice({
            id: questionId,
            isMultipleChoice: e.target.value === "multi",
        });
    };
    const handleRemoveQuestion = () => {
        pollAPI.deleteQuestion({ questionId });
    };
    const answersTest = answers.map((answer, i) => (<PollAnswerProvider answer={answer} key={answer.id}>
			<ParentContextUpdater actionType={PollQuestionActionTypes.SYNC_ANSWER} childContext={PollAnswerContext} parentContext="pollQuestion" payloadKey="answer"/>
			<PollAnswerEditAPISync />
			<PollAnswerEdit index={i}/>
		</PollAnswerProvider>));
    return (<Styles.QuestionContainer $moreThanOne={isOneOfMany}>
			{"Question" !== type && (<Styles.PollQuestionNumber>
						{isOneOfMany
                && <label>question {index + 1}</label>}
						{isOneOfMany
                && (<Button size="small" style="flatNeutral" clickHandler={handleRemoveQuestion}>
									{getTranslation("frontend.generics.remove")}
								</Button>)}
					</Styles.PollQuestionNumber>)}
			<Styles.Row>
				<Styles.CustomSelect name="question-type" onChange={handleChangeQuestionChoiceType} options={questionTypeOptions} value={isMultipleChoice
            ? "multi"
            : "single"}/>
			</Styles.Row>
			{"Question" !== type && (<Styles.Row>
						<TextField name={`question-${index}`} placeholder={getTranslation("frontend.poll.questionPlaceholder", true)} handleChange={handleChangeQuestion} value={question} contextClass="solid-background"/>
					</Styles.Row>)}
			<Styles.Row key={index}>
				{answersTest}
			</Styles.Row>
		</Styles.QuestionContainer>);
};
export default PollQuestionEdit;
