import { ColorWhite } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled, { createGlobalStyle } from "styled-components";
import { DesktopMinWidth } from "./components/MediaQueries/MediaQueries";
const MobileMaxWidth = DesktopMinWidth - 1;
export const topNavHeight = 36;
const topNavTopBottomPadding = 24;
const topNavTopBottomPaddingMobile = 16;
const mobileNavHeight = 48;
export const RunWithPeterGlobalStyle = createGlobalStyle `
	body {
		--top-nav-height: ${topNavHeight}px;
		--top-nav-top-and-bottom-padding: ${topNavTopBottomPadding}px;
		--top-nav-top-and-bottom-padding-mobile: ${topNavTopBottomPaddingMobile}px;
		--top-nav-total-height: calc(${topNavHeight}px + (${topNavTopBottomPadding}px * 2));
		--top-nav-total-height-mobile: calc(${topNavHeight}px + (${topNavTopBottomPaddingMobile}px * 2));
		--mobile-nav-height: ${mobileNavHeight}px;
		--top-and-mobile-nav-height: calc(var(--top-nav-total-height-mobile) + var(--mobile-nav-height));
		--logged-out-padding: 57px;
		--logged-out-top-displacement: calc(var(--top-nav-total-height) - var(--logged-out-padding));
		--logged-out-mobile-top-displacement: calc(var(--top-and-mobile-nav-height) - var(--logged-out-padding));
		--page-max-width: 1336px;
		--page-min-width: 904px;
		--page-min-height: 100vh;
		--content-max-width: 1400px;
		--mobile-min-width: 328px;
		--logo-height: ${topNavHeight}px;
	}
`;
export const RunWithPeter = styled.div `
	background-color: ${ColorWhite};
`;
export const Page = styled.div `
	min-height: calc(var(--page-min-height) - var(--top-nav-total-height));
	margin: 0 auto;
	position: relative;
	padding: 0 32px;

	// positions the anchors so links work correctly
	&>a {
		position: absolute;
		top: calc(var(--top-nav-total-height) * -1);
	}

	@media (max-width: ${MobileMaxWidth}px) {
		max-width: 100vw;
		min-width: var(--mobile-min-width);
		padding-left: 16px;
		padding-right: 16px;
	}
`;
export const SuccessBar = styled.div `
	width: 100%;
	margin: 0 auto 48px auto;
	padding: 10px 48px;
	background-color: rgb(248, 244, 240);
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const SuccessMessage = styled(TextStyled).attrs({ $kind: "bodyShort01" }) `
	padding: 0 24px;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.18px;
	line-height: 20px;
`;
