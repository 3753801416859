import { Button, ChevronLeftSVG, Icon } from "@sermo/ui-components";
import { useSetPostEditorSelectedType, useTogglePostEditor } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./MobileNewPostNav.styles";

const MobileNewPostSubNav = () => {
	const openEditor = useTogglePostEditor();
	const setPostEditorSelectedType = useSetPostEditorSelectedType();
	const { postEditorSelectedType } = useMemoizedContext("ui", ["postEditorSelectedType"]);

	const handleChange = (_event, value) => {
		setPostEditorSelectedType(value);
	};

	const handleBackClick = () => {
		openEditor(false)
	};

	return (
		<Styles.SubNav>
			<Button
				icon={
					<Icon
						height={12}
						src={ChevronLeftSVG}
						width={12}
					/>
				}
				kind={"flatNeutral"}
				onClick={handleBackClick}
				size={"xsmall"}
			/>
			<Styles.StyledSelect
				autoWidth
				inline
				onChange={handleChange}
				options={
					[
						{
							value: "PatientCase",
							label: getTranslation("frontend.newPost.patientCaseTabLabel", true),
						},
						{
							value: "Discussion",
							label: getTranslation("frontend.newPost.discussionTabLabel", true),
						},
						{
							value: "Question",
							label: getTranslation("frontend.newPost.questionTabLabel", true),
						},
					]
				}
				value={postEditorSelectedType}
			/>
		</Styles.SubNav>
	);
};

export default MobileNewPostSubNav;
