import AdForm from "@components/AdUnits/AdForm";
import SPFooter from "@components/AdUnits/Footer/SPFooter";
import Featured from "@components/Card/components/Featured/Featured";
import Images from "@components/Card/components/Images/Images";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SponsoredContentInFeed from "@components/PostContent/components/SponsoredContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import SolvedIndicator from "@components/PostViewer/components/PatientCaseInFeed/SolvedIndicator";
import { useGetAdUnitProps, useMainAndTopResizeAware } from "@components/PostViewer/PostViewer.hooks";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useMemoizedContext } from "@hooks/Hooks";
const SponsoredPatientCaseInFeed = () => {
    const adUnitProps = useGetAdUnitProps(true);
    const { mainResizeListener, topResizeListener, } = useMainAndTopResizeAware();
    const { attachmentsView, poll, } = useMemoizedContext("postData", [
        "attachmentsView",
        "poll",
    ]);
    return (<CardContainer>
			<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
				<SharedStyles.AdSpacingContainer>
					{mainResizeListener}
					<div className={"top-section"}>
						{topResizeListener}
						<CardHeader />
						<div>
							<SolvedIndicator />
							<Title />
							<SponsoredContentInFeed />
						</div>
					</div>
					{(!attachmentsView
            || attachmentsView === "Undefined")
            && (!poll?.questions
                && poll.questions.length > 0)
            && <Images />}
					{["FeatureImage", "FeatureImageAndISI"].includes(attachmentsView) && <Featured />}
					{"Form" === attachmentsView && <AdForm />}
					{"Poll" === attachmentsView && <PollDisplay />}
					<SPFooter {...adUnitProps} type={"footnote"}/>
					<SPFooter {...adUnitProps} type={"isi"}/>
				</SharedStyles.AdSpacingContainer>
			</ModuleWrapper>
			<CardFooter />
		</CardContainer>);
};
export default SponsoredPatientCaseInFeed;
