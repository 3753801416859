import {
	ArrowLeftSVG,
	DiscussionSVG,
	Icon,
	QuestionSVG,
	NewPostSVG,
	PatientCaseSVG,
	TabButton,
	TabList,
	Tabs,
	XSVG, IconButton,
} from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { useSetPostEditorSelectedType, useTogglePostEditor } from "@contexts/UI";
import { PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./TypeSelector.scss";
import * as Styles from "./TypeSelector.styles";

const DesktopTypeSelectorContainer = styled.div`
	margin: 32px 16px 0;
`;

const TypeSelectorProps = {
	type: PropTypes.oneOf(["Question", "Discussion", "PatientCase"]),
	typeChange: PropTypes.func,
};

const DesktopTypeSelector = () => {
	const setPostEditorSelectedType = useSetPostEditorSelectedType();
	const {	postEditorSelectedType } = useMemoizedContext("ui", ["postEditorSelectedType"]);
	const select = (ev, type) => {
		setPostEditorSelectedType(type)
	}

	return (
		<DesktopTypeSelectorContainer>
			<Styles.TabsContainer>
				<Tabs
					defaultValue={postEditorSelectedType}
					onChange={select}
					full
					divider
				>
					<TabList>
						<TabButton
							icon={
								<Icon
									src={PatientCaseSVG}
									width={24}
									height={24}
								/>
							}
							value={"PatientCase"}
						>{getTranslation("frontend.newPost.patientCaseTabLabel")}</TabButton>
						<TabButton
							icon={
								<Icon
									src={DiscussionSVG}
									width={24}
									height={24}
								/>
							}
							value={"Discussion"}
						>{getTranslation("frontend.newPost.discussionTabLabel")}</TabButton>
						<TabButton
							icon={
								<Icon
									src={QuestionSVG}
									width={24}
									height={24}
								/>
							}
							value={"Question"}
						>{getTranslation("frontend.newPost.questionTabLabel")}</TabButton>
					</TabList>
				</Tabs>
			</Styles.TabsContainer>
		</DesktopTypeSelectorContainer>
	);
}

DesktopTypeSelector.propTypes = {
	...TypeSelectorProps,
};

DesktopTypeSelector.defaultProps = {
	typeChange: () => {},
};

export { DesktopTypeSelector };
