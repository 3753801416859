import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import typography from "../../scss/typography.scss";
import styles from "./Heading.scss";

const getTypography = (type) => {
	switch (type) {
		case "followed":
			return ["typography.body-short-brand-01"];
		case "attachments":
		case "survey":
			return ["typography.overline-01", "styles.heading-light"];
		case "default":
		default:
			return ["typography.body-short-brand-03"];
	}
}

const Heading = ({ children, context, type }) => (
	<header
		styleName={
			classNames([
				"styles.heading",
				{ [`styles.context-${context}`]: context },
				...getTypography(type),
			])
		}
		// TODO: This component should be refactored to simply use the className prop and use UI lib classnames instead SASS
		className={
			["followed", "default"].includes(type)
				? "heading-02"
				: undefined
		}
	>
		{children}
	</header>
)

export const HeadingType = PropTypes.oneOf([
	"followed",
	"attachments",
	"survey",
	"default",
])

Heading.propTypes = {
	children: PropTypes.node,
	context: PropTypes.string,
	type: HeadingType
};

Heading.defaultProps = {
	context: "",
};

export default Heading;
