import { Icon, OverflowHorizontalIcon } from "@sermo/ui-components";
import React from "react";
import { ButtonWithMenu } from "@components/Button/Button";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./CardHeaderCustom.styles";

const CardHeaderCustom = (
	{
		main,
		menu,
	}
) => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);
	
	const {
		id: postId,
		theme,
	} = useMemoizedContext("postData", [
		"id",
		"theme",
	]);

	return (
		<Styles.CardHeaderContainer
			$expanded={expanded}
			$themeType={theme?.type}
		>
			{!!main && main}
			{
				!!menu
				&& (
					<Styles.MenuAndMore>
						<ButtonWithMenu
							activeClassName="active"
							activeUnderline={false}
							icon={
								<Icon
									src={OverflowHorizontalIcon}
									width={24}
									height={24}
								/>
							}
							menu={
								{
									component: menu,
									name: `card-header-${postId}`,
								}
							}
							theme="light"
							style="icon"
							size="large"
						/>
					</Styles.MenuAndMore>
				)
			}
		</Styles.CardHeaderContainer>
	);
};

export default CardHeaderCustom;
