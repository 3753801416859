import { useMatch } from "react-router-dom";
import PenIcon from "@assets/images/pencreatepost.svg";
import { useTogglePostEditor } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { CustomButton } from "./CreateButton.styles";
const CreatePostButton = () => {
    const isAdPreview = useMatch("/ad-preview/*");
    const openEditor = useTogglePostEditor();
    const trackEvent = useTrackEvent();
    const user = useMemoizedContext("member");
    const createPost = () => {
        trackEvent({
            category: "left-navigation-profile-card",
            label: "create-post",
            action: "click",
        });
        openEditor(true);
    };
    if (!user.hasAccess("canCreateEditAndDeletePostsInFrontend")
        && !isAdPreview) {
        return null;
    }
    return (<CustomButton data-component="CreatePostButton" size={"large"} icon={<PenIcon />} fluid={true} onClick={createPost}>
			{getTranslation("frontend.generics.createPostLowercase")}
		</CustomButton>);
};
export { CreatePostButton };
