import React, { useEffect, useState } from "react";
import Button from "@components/Button/Button";
import Menu from "@components/Menu/Menu";
import { StyledAlertText } from "@components/Modal/Modal.styles";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import * as Styles from "@components/PostViewer/components/CardHeader/components/CardHeaderMenu/CardHeaderMenu.styles";
import { PostDataActionTypes } from "@contexts/PostData";
import { EUIKey, useCloseAlert, useOpenAlert } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const ResourceInFeedMenu = () => {
    const apiEndpoint = useApiEndpoint();
    const closeAlert = useCloseAlert();
    const openAlert = useOpenAlert();
    const trackEvent = useTrackEvent();
    const [isBrandAdmin, setIsBrandAdmin] = useState(false);
    const [userUsed, setUserUsed] = useState(false);
    const { brandAdministrator, id: memberId, } = useMemoizedContext("member", [
        "brandAdministrator",
        "id",
    ]);
    const { adFrequency, adId, brand, cardType, disableFollows, dispatch: dispatchPostData, following, id: postId, resourceCenterDisableFollows, resourceCenterFollowing, resourceCenterId, resourceCenterItemId, resourceCenterPostId, user, } = useMemoizedContext("postData", [
        "adFrequency",
        "adId",
        "brand",
        "cardType",
        "disableFollows",
        "following",
        "id",
        "resourceCenterDisableFollows",
        "resourceCenterFollowing",
        "resourceCenterId",
        "resourceCenterItemId",
        "resourceCenterPostId",
        "user",
    ]);
    const [isResourceCenter] = useState(CardTypeTypes.RESOURCE_CENTER === cardType);
    const [isResourceCenterItem] = useState(CardTypeTypes.RESOURCE_CENTER_ITEM === cardType);
    const [isFollowing, setIsFollowing] = useState(isResourceCenterItem
        ? resourceCenterFollowing
        : following);
    useEffect(() => {
        if (!userUsed) {
            setUserUsed([CardTypeTypes.POST, CardTypeTypes.SERMO_CONTENT_CARD].includes(cardType)
                ? user
                : brand);
        }
    }, [
        cardType,
        user,
        brand,
    ]);
    useEffect(() => {
        if (userUsed) {
            setIsBrandAdmin(brandAdministrator && memberId === userUsed["id"]);
        }
    }, [
        brandAdministrator,
        memberId,
        userUsed,
    ]);
    // COPY LINK
    const copyLink = async () => {
        let urlForPost = "";
        if (isResourceCenterItem) {
            urlForPost = `${window.location.origin}/resource/item/${resourceCenterItemId}`;
        }
        else if (isResourceCenter) {
            urlForPost = `${window.location.origin}/resource/${resourceCenterId}`;
        }
        await navigator.clipboard.writeText(urlForPost);
        trackEvent({
            category: `overflow-resourcecenter${isResourceCenterItem
                ? "item"
                : ""}`,
            action: "click",
            label: "copy-link",
            postId,
            adId: adId,
            adFrequency: adFrequency,
        });
        openAlert({
            [EUIKey.ALERT_COMPONENT]: (<StyledAlertText>
					{getTranslation("frontend.alerts.copyLink")}
				</StyledAlertText>),
            [EUIKey.ALERT_LABEL]: "link-copied",
        });
    };
    // FOLLOW / UNFOLLOW RESOURCE CENTER
    const followResourceCenterToggle = (open) => {
        closeAlert("resourcecenter-followed");
        if (isFollowing) {
            trackEvent({
                category: "overflow-resourcecenter",
                label: "unfollow-resourcecenter",
                action: "click",
                id: resourceCenterPostId || postId,
                adId: adId,
                adFrequency: adFrequency,
            });
        }
        else {
            trackEvent({
                category: "overflow-resourcecenter",
                label: "follow-resourcecenter",
                action: "click",
                id: resourceCenterPostId || postId,
                adId: adId,
                adFrequency: adFrequency,
            });
        }
        setIsFollowing(!isFollowing);
        dispatchPostData({
            type: PostDataActionTypes.TOGGLE_FOLLOWING,
            payload: {
                isResourceCenterItem,
            },
        });
        const endpoint = isFollowing
            ? "posts/unfollowPost"
            : "posts/followPost";
        const requestBody = {
            id: resourceCenterPostId,
        };
        apiEndpoint(endpoint, requestBody);
        if (open) {
            openAlert({
                [EUIKey.ALERT_COMPONENT]: <FollowPostAlert />,
                [EUIKey.ALERT_LABEL]: "resourcecenter-followed",
            });
        }
    };
    const FollowPostAlert = () => (<>
			<StyledAlertText>
				{isFollowing
            ? getTranslation("frontend.alerts.postUnfollowed")
            : getTranslation("frontend.alerts.postFollowed")}
			</StyledAlertText>
			<Button clickHandler={() => {
            followResourceCenterToggle(false);
        }} style="flatNeutral" size="small">
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>);
    const followLabel = isFollowing
        ? "Unfollow"
        : "Follow";
    const followDescription = isFollowing
        ? "Stop getting notified when there's new activity"
        : "Get notified when there's new activity";
    const menuOptions = [
        // copy
        {
            label: `Copy link to resource ${isResourceCenterItem
                ? "item"
                : "center"}`,
            props: {
                clickHandler: copyLink,
            },
            shouldShow: () => true,
        },
        // follow
        {
            label: `${followLabel} resource center`,
            description: followDescription,
            props: {
                clickHandler: () => {
                    followResourceCenterToggle(true);
                },
                rightsRequired: ["canFollowItemsInFrontend"],
            },
            shouldShow: () => {
                if (isBrandAdmin) {
                    return false;
                }
                else if (isResourceCenterItem) {
                    return !resourceCenterDisableFollows
                        && resourceCenterPostId > 0;
                }
                else if (isResourceCenter) {
                    return !disableFollows
                        && postId > 0;
                }
                return true;
            },
        },
    ];
    return (<ModuleWrapper padded={false} solid={true} border={true} contextClass={"card-header-menu"}>
			<Menu>
				{menuOptions.map((menuOption, i) => {
            if (menuOption.shouldShow(menuOption)) {
                return (<React.Fragment key={i}>
									{!menuOption.border && (<Button theme="light" style="flatNeutral" alignment="start" size="small" {...menuOption.props}>
												<Styles.ButtonContent>
													<Styles.MenuOptionTitle>
														{menuOption.label}
													</Styles.MenuOptionTitle>
													{menuOption.description && (<Styles.MenuOptionDescription>
																{menuOption.description}
															</Styles.MenuOptionDescription>)}
												</Styles.ButtonContent>
											</Button>)}
									{menuOption.border && <Styles.Border />}
								</React.Fragment>);
            }
            return false;
        })}
			</Menu>
		</ModuleWrapper>);
};
export default ResourceInFeedMenu;
