import PropTypes from "prop-types";
import React from "react";
import { ModalStyled } from "@components/Modal/Modals/AffiliateMember/styled";
import Content from "./Content";

const Modal = ({ actionHandler, loading }) => (
	<ModalStyled>
		<header/>
		<Content
			actionHandler={actionHandler}
			loading={loading}
		/>
	</ModalStyled>
);

Modal.propTypes = {
	actionHandler: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};

export default Modal;