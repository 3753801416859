import { Button, Icon } from "@sermo/ui-components";
import React from "react";
import VerificationPictogram from "@assets/images/verification-pictogram.svg";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styled from "./ReadOnlyPage.styles";

export const ReadOnlyPage = () => {
	const trackEvent = useTrackEvent();
	const { loggedIn } = useMemoizedContext("member", ["loggedIn"]);

	React.useEffect(() => {
		trackEvent({
			category: "read-only-page",
			label: "on-load",
			action: "view",
		});
	}, [trackEvent]);

	const handleButtonClick = () => {
		trackEvent({
			category: "read-only-page",
			label: "view-resources-button",
			action: "click",
		});
	};

	return (
		<Styled.PageWrap $loggedIn={loggedIn}>
			<Styled.MeatWrap>
				<Icon
					src={VerificationPictogram}
					width={64}
					height={64}
				/>
				<Styled.TitleText>
					{getTranslation("frontend.loggedOut.readOnly.headerText", true)}
				</Styled.TitleText>
				<Styled.BodyText>
					{getTranslation("frontend.loggedOut.readOnly.bodyText01")}
				</Styled.BodyText>
				<Styled.BodyText>
					{getTranslation("frontend.loggedOut.readOnly.bodyText02")}
				</Styled.BodyText>
				<Styled.HorizontalRule />
				<Styled.FooterWrap>
					<Styled.BodyText>
						{getTranslation("frontend.loggedOut.readOnly.bodyText03", true)}
					</Styled.BodyText>
					<a href="https://www.sermo.com/resources/category/physician-resources/">
						<Button
							onClick={handleButtonClick}
							size="medium"
						>
							{getTranslation("frontend.loggedOut.readOnly.buttonText", true)}
						</Button>
					</a>
				</Styled.FooterWrap>
			</Styled.MeatWrap>
		</Styled.PageWrap>
	);
};

export default ReadOnlyPage;