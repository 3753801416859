import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React, { useState, useEffect, useMemo } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { TextField, DropDownList, ValidationMessage, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import RegistrationStep from "@components/Register/components/Registration/RegistrationStep";
import { RegisterStyles, ReverificationWrap } from "@components/Register/Register.styles";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const Form = ({ data, updateData, endpoint }) => {
	const registerTrackEvent = useRegisterTrack();
	const isRegister = !!useMatch("/register/*");
	const apiEndpoint = useApiEndpoint();
	let isRunningOnProductionEnvironment = true;
	const { isPostSurveyReferral } = useMemoizedContext("member",["isPostSurveyReferral"]);

	if ("undefined" !== typeof window) {
		if (window.sermo.env !== "production") {
			isRunningOnProductionEnvironment = false;
		}
	}

	let debugForceSuccessVerification = false;

	const [loading, setLoading] = useState(false);

	const { availableDobYears, availableDobMonths, availableDobDays } = data;

	const [city, setCity] = useState(data.city);
	const [addressLine1, setAddressLine1] = useState(data.addressLine1);
	const [addressLine2, setAddressLine2] = useState(data.addressLine2);
	const [postalCode, setPostalCode] = useState(data.postalCode);
	const [dobYear, setDobYear] = useState(data.dobYear);
	const [dobMonth, setDobMonth] = useState(data.dobMonth);
	const [dobDay, setDobDay] = useState(data.dobDay);
	const [nationalIdentityNumber, setNationalIdentityNumber] = useState("");
	const [placeOfEmployment, setPlaceOfEmployment] = useState(data.placeOfEmployment);

	let nationalIdentityNumberMaxLength = 15;

	const [globalValidationError, setGlobalValidationError] = useState("");

	const [addressLine1ValidationError, setAddressLine1ValidationError] = useState("");
	const [addressLine2ValidationError, setAddressLine2ValidationError] = useState("");
	const [postalCodeValidationError, setPostalCodeValidationError] = useState("");
	const [dobYearValidationError, setDobYearValidationError] = useState("");
	const [dobMonthValidationError, setDobMonthValidationError] = useState("");
	const [dobDayValidationError, setDobDayValidationError] = useState("");
	const [dobValidationError, setDobValidationError] = useState("");
	const [nationalIdentityNumberValidationError, setNationalIdentityNumberValidationError] = useState("");
	const [placeOfEmploymentValidationError, setPlaceOfEmploymentValidationError] = useState("");

	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		if (["GB", "IT"].includes(data.selectedCountryCode)) {
			setCanSubmit(addressLine1?.length > 0 && postalCode?.length > 0 && city?.length > 0);
		} else {
			setCanSubmit(addressLine1?.length > 0 && postalCode?.length > 0 && dobMonth !== "-1" && dobDay !== "-1" && dobYear !== "-1" && nationalIdentityNumber?.length > 0);
		}
	},[addressLine1, postalCode, dobMonth, dobDay, dobYear, nationalIdentityNumber, city])

	if (data !== null) {
		if (data.selectedCountryCode === "US") {
			nationalIdentityNumberMaxLength = 4;
		}
		else if (data.selectedCountryCode === "DK") {
			nationalIdentityNumberMaxLength = 10;
		}
	}

	const addressLine1ChangeHandler = event => {
		setAddressLine1(event.target.value);
	};

	const addressLine2ChangeHandler = event => {
		setAddressLine2(event.target.value);
	};

	const cityChangeHandler = event => {
		setCity(event.target.value);
	};

	const postalCodeChangeHandler = event => {
		setPostalCode(event.target.value);
	};

	const dobYearChangeHandler = event => {
		setDobYear(event.target.value);
	};

	const dobMonthChangeHandler = event => {
		setDobMonth(event.target.value);
	};

	const dobDayChangeHandler = event => {
		setDobDay(event.target.value);
	};

	const nationalIdentityNumberChangeHandler = event => {
		setNationalIdentityNumber(event.target.value);
	};

	const placeOfEmploymentChangeHandler = event => {
		setPlaceOfEmployment(event.target.value);
		setPlaceOfEmploymentValidationError("");
	};

	const validateForm = () => {
		let valid = true;

		if (addressLine1 && addressLine1.length >= 3) {
			setAddressLine1ValidationError("");
		}
		else {
			setAddressLine1ValidationError(getTranslation("system.validationMessages.validationYourResidentialAddressIsRequired", true));
			valid = false;
		}

		if (postalCode && postalCode.length >= 3) {
			setPostalCodeValidationError("");
		}
		else {
			setPostalCodeValidationError(getTranslation("system.validationMessages.validationPostalCodeIsRequired", true));
			valid = false;
		}

		if (!["GB", "IT"].includes(data.selectedCountryCode)) {
			let testDate = new Date(Number(dobYear), Number(dobMonth) - 1, Number(dobDay), 0, 0, 0);

			if (!(Boolean(+testDate) && testDate.getDate() === parseInt(dobDay))) {
				setDobValidationError(getTranslation("system.validationMessages.validationInvalidDateOfBirth", true))
				valid = false;
			}
			else {
				setDobValidationError("");
			}
		}

		if (data.selectedCountryCode === "US") {
			if (nationalIdentityNumber !== null && nationalIdentityNumber.length === 4) {
				if (!(/^\d+$/.test(nationalIdentityNumber.length))) {
					valid = false;
					setNationalIdentityNumberValidationError(getTranslation("system.validationMessages.validationInvalidInputOnlyDigitsAreAllowed", true));
				}
				else {
					setNationalIdentityNumberValidationError("");
				}
			}
			else {
				setNationalIdentityNumberValidationError(getTranslation("system.validationMessages.validationEnterLastFourDigitsOfSsn", true));
				valid = false;
			}
		}
		else if (!["GB", "IT"].includes(data.selectedCountryCode)) {
			if (nationalIdentityNumber !== null && nationalIdentityNumber.length >= 4) {
				setNationalIdentityNumberValidationError("");
			}
			else {
				setNationalIdentityNumberValidationError(getTranslation("system.validationMessages.validationPleaseEnterAValidNationalIdentityNumber", true));
				valid = false;
			}
		}

		return valid;
	};

	const formSubmitHandler = (e) => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);

			const requestData = {
				addressLine1,
				addressLine2,
				postalCode,
				dobYear,
				dobMonth,
				dobDay,
				nationalIdentityNumber,
				placeOfEmployment,
				city,
				addressType: ["GB", "IT"].includes(data.selectedCountryCode)
					? "Office"
					: "Residential",
				debugBypassIdentityVerificationAndSuccessfullyVerifyIdentity: debugForceSuccessVerification,
			};

			// registration-process-enter-address-click
			// psr-verify-identity-module-submit-click
			registerTrackEvent({
				label: "enter-address",
				psrLabel: "verify-identity-module-submit",
				action: "click",
			})

			apiEndpoint(`${endpoint}/verifyidentity`, requestData).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (dataResponse.ok) {
						updateData({
							dobYear,
							dobMonth,
							dobDay,
							addressLine1,
							addressLine2,
							postalCode,
							placeOfEmployment,
							city,
							addressType: ["GB", "IT"].includes(data.selectedCountryCode)
								? "Office"
								: "Residential",
							...body,
						});
					}
					else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}

						if (body.fields !== null) {
							for (let property in body.fields) {
								switch (property) {
									case "addressLine1":
										setAddressLine1ValidationError(body.fields[property].join("<br />"));
										break;
									case "addressLine2":
										setAddressLine2ValidationError(body.fields[property].join("<br />"));
										break;
									case "postalCode":
										setPostalCodeValidationError(body.fields[property].join("<br />"));
										break;
									case "dobYear":
										setDobValidationError(body.fields[property].join("<br />"));
										break;
									case "dobMonth":
										setDobValidationError(body.fields[property].join("<br />"));
										break;
									case "dobDay":
										setDobValidationError(body.fields[property].join("<br />"));
										break;
									case "nationalIdentityNumber":
										setNationalIdentityNumberValidationError(body.fields[property].join("<br />"));
										break;
									case "placeOfEmployment":
										setPlaceOfEmploymentValidationError(body.fields[property].join("<br />"));
										break;
									case "city":
										setPlaceOfEmploymentValidationError(body.fields[property].join("<br />"));
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const goBackClientEventHandler = (e) => {
		e.preventDefault();
		// registration-process-verify-back-click
		registerTrackEvent({
			label: "verify-address-back",
			action: "click",
		})
		
		if (data.hasBeenAssignedProviderAutomatically) {
			if (data.suggestedProviders && data.suggestedProviders.length > 1) {
				updateData({ currentStep: RegistrationStep.SelectSuggestedProvider });
			}
			else {
				updateData({ currentStep: RegistrationStep.EnterName });
			}
		}
		else {
			if (data.suggestedProviders && data.suggestedProviders.length > 1) {
				updateData({ currentStep: RegistrationStep.SelectSuggestedProvider });
			}
			else {
				updateData({ currentStep: RegistrationStep.EnterProviderInformation });
			}
		}
	};

	const forceSuccessVerificationClickHandler = (e) => {
		if (!isRunningOnProductionEnvironment) {
			debugForceSuccessVerification = true;
			formSubmitHandler(e);
		}
	};

	let nationalIdentityNumberPlaceholderTranslationKey = "frontend.registration.identityVerificationPage.inputNationalIdentityNumberPlaceholder";

	if (data.selectedCountryCode !== "US") {
		nationalIdentityNumberPlaceholderTranslationKey = "frontend.registration.identityVerificationPage.inputNationalIdentityNumberNonUsPlaceholder";
	}

	const stepTitle = useMemo(() => (
		["GB", "IT"].includes(data.selectedCountryCode)
			? getTranslation("frontend.registration.identityVerificationPage.titleUK")
			: getTranslation("frontend.registration.identityVerificationPage.title")
	), [data.selectedCountryCode]);

	const skipThisStep = (e) => {
		e.preventDefault();

		// registration-process-verify-address-get-manually-verified-click
		// psr-verify-identity-module-get-manually-verified-click
		registerTrackEvent({
			label: "verify-address-get-manually-verified",
			psrLabel: "verify-identity-module-get-manually-verified",
			action: "click",
		})

		const requestData = {
			addressLine1,
			addressLine2,
			postalCode,
			nationalIdentityNumber,
			placeOfEmployment,
			city,
			addressType: ["GB", "IT"].includes(data.selectedCountryCode)
				? "Office"
				: "Residential",
			skipThisStep: true,
		};
		apiEndpoint(`${endpoint}/verifyidentity`, requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);
				if (dataResponse.ok) {
					updateData({
						...body,
					});
				} else {
					console.error(body)
				}
			});
		});
	};

	return (
		<LoggedOutStyles>
			<RegisterStyles>
				<TextStyled
					className={classNames(["form-title"])}
					$kind="heading04"
				>
					{stepTitle}
				</TextStyled>

				<form
					onSubmit={formSubmitHandler}
					className={classNames(["form"])}
				>
					{
						["GB", "IT"].includes(data.selectedCountryCode) && (
							<>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="placeOfEmployment"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputEmployerLabel", true)}
										autoComplete="place-of-employment"
										placeholder={"Name or website"}
										validationError={placeOfEmploymentValidationError}
										handleChange={placeOfEmploymentChangeHandler}
										value={placeOfEmployment}
									/>
								</div>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="addressLine1"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputStreetAddressLabel", true)}
										autoComplete="address-line1"
										validationError={addressLine1ValidationError}
										handleChange={addressLine1ChangeHandler}
										value={addressLine1}
									/>
								</div>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="addressLine2"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputAptLabel", true)}
										autoComplete="address-line2"
										validationError={addressLine2ValidationError}
										handleChange={addressLine2ChangeHandler}
										value={addressLine2}
									/>
								</div>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="city"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputTownLabel", true)}
										autoComplete="city"
										handleChange={cityChangeHandler}
										value={city}
									/>
								</div>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="postalCode"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputPostalCodeLabel", true)}
										autoComplete="postal-code"
										validationError={postalCodeValidationError}
										handleChange={postalCodeChangeHandler}
										value={postalCode}
									/>
								</div>
							</>
						)
					}
					{
						!["GB", "IT"].includes(data.selectedCountryCode) && (
							<>
								<TextStyled
									className={classNames(["form-section-title"])}
									$kind="heading02"
								>
									<strong>
										{getTranslation("frontend.registration.identityVerificationPage.residentialAddressTitle")}
									</strong>
								</TextStyled>
								<div className={classNames(["form-field-row"])}>
									<TextField
										name="addressLine1"
										type="text"
										label={getTranslation("frontend.registration.identityVerificationPage.inputStreetAddressLabel", true)}
										autoComplete="address-line1"
										validationError={addressLine1ValidationError}
										handleChange={addressLine1ChangeHandler}
										value={addressLine1}
									/>
								</div>
								<div className={classNames(["form-field-row"])}>
									<div className={classNames(["half-width", "first"])}>
										<TextField
											name="addressLine2"
											type="text"
											label={getTranslation("frontend.registration.identityVerificationPage.inputApartmentBuildingNumberLabel", true)}
											placeholder={getTranslation("frontend.registration.identityVerificationPage.inputApartmentBuildingNumberPlaceholder", true)}
											autoComplete="address-line2"
											validationError={addressLine2ValidationError}
											handleChange={addressLine2ChangeHandler}
											value={addressLine2}
										/>
									</div>
									<div className={classNames(["half-width", "last"])}>
										<TextField
											name="postalCode"
											type="text"
											label={getTranslation("frontend.registration.identityVerificationPage.inputPostalCodeLabel", true)}
											autoComplete="postal-code"
											validationError={postalCodeValidationError}
											handleChange={postalCodeChangeHandler}
											value={postalCode}
										/>
									</div>
								</div>
								<TextStyled
									className={classNames(["form-section-title"])}
									$kind="heading02"
								>
									<strong>
										{getTranslation("frontend.registration.identityVerificationPage.dateOfBirthTitle")}
									</strong>
								</TextStyled>
								<div className={classNames(["form-field-row"])}>
									<div className={classNames(["one-third-width", "first"])}>
										<DropDownList
											name="dateOfBirthMonth"
											autoComplete="bday-month"
											handleChange={dobMonthChangeHandler}
											validationError={dobMonthValidationError}
											options={availableDobMonths}
											value={dobMonth}
										/>
									</div>
									<div className={classNames(["one-third-width"])}>
										<DropDownList
											name="dateOfBirthDay"
											autoComplete="bday-day"
											handleChange={dobDayChangeHandler}
											validationError={dobDayValidationError}
											options={availableDobDays}
											value={dobDay}
										/>
									</div>
									<div className={classNames(["one-third-width", "last"])}>
										<DropDownList
											name="dateOfBirthYear"
											autoComplete="bday-year"
											handleChange={dobYearChangeHandler}
											validationError={dobYearValidationError}
											options={availableDobYears}
											value={dobYear}
										/>
									</div>

									<ValidationMessage text={dobValidationError} />

								</div>

								<TextStyled
									className={classNames(["form-section-title","with-subtitle"])}
									$kind="heading02"
								>
									<strong>
										{
											data.selectedCountryCode === "US"
								&& getTranslation("frontend.registration.identityVerificationPage.nationalIdentityNumberTitle")
										}
										{
											data.selectedCountryCode !== "US"
								&& getTranslation("frontend.registration.identityVerificationPage.nationalIdentityNumberNonUsTitle")
										}
									</strong>
								</TextStyled>

								<div className={classNames(["form-field-row"])}>
									<TextStyled
										className={classNames("form-section-subtitle")}
										$kind="bodyShort00"
									>
										{
											data.selectedCountryCode === "US"
								&& getTranslation("frontend.registration.identityVerificationPage.nationalIdentityNumberDescription")
										}
										{
											data.selectedCountryCode !== "US"
								&& getTranslation("frontend.registration.identityVerificationPage.nationalIdentityNumberNonUsDescription")
										}
									</TextStyled>
									<TextField
										name="nationalIdentityNumber"
										type="password"
										placeholder={getTranslation(nationalIdentityNumberPlaceholderTranslationKey, true)}
										autoComplete="off"
										validationError={nationalIdentityNumberValidationError}
										maxLength={nationalIdentityNumberMaxLength}
										handleChange={nationalIdentityNumberChangeHandler}
										value={nationalIdentityNumber}
									/>
								</div>

								<TextStyled
									className={classNames(["form-section-title"])}
									$kind="heading02"
								>
									<strong>
										{getTranslation("frontend.registration.enterProviderInformationPage.inputPlaceOfEmploymentLabel")}
									</strong>
								</TextStyled>

								<div className={classNames(["form-field-row"])}>
									<TextField
										name="placeOfEmployment"
										type="text"
										autoComplete="place-of-employment"
										placeholder={getTranslation("frontend.registration.enterProviderInformationPage.inputPlaceOfEmploymentPlaceholder", true)}
										validationError={placeOfEmploymentValidationError}
										handleChange={placeOfEmploymentChangeHandler}
										value={placeOfEmployment}
									/>
								</div>

							</>
						)
					}
					<GlobalValidationMessage text={globalValidationError} />

					<div className={classNames(["submit-container", "identity-verification"])}>
						<div className={classNames(["submit-container-side"])}>
							<div className={classNames(["submit-container--back-button"])}>
								<Button
									name="back"
									style="flatNeutral"
									size="medium"
									disabled={loading}
									clickHandler={goBackClientEventHandler}
								>
									{getTranslation("frontend.generics.back", true)}
								</Button>
							</div>
							<Button
								name="submit"
								size="medium"
								disabled={!canSubmit || loading}
								loading={loading}
								type="submit"
								clickHandler={formSubmitHandler}
							>
								{getTranslation("frontend.generics.next", true)}
							</Button>
						</div>
						<TextStyled
							className={classNames("submit-container--link")}
							$kind="bodyLong00"
						>
							<div>
								{getTranslation("frontend.registration.enterProviderInformationPage.skipStep")}
							</div>
							<a
								href=""
								disabled={loading}
								onClick={skipThisStep}
							>
								{getTranslation("frontend.registration.enterProviderInformationPage.verifyManually")}
							</a>
						</TextStyled>
					</div>
					{
						!isRunningOnProductionEnvironment && (
							<div className={classNames(["submit-container"])}>

								<div className={classNames(["submit-container--force-success-container"])}>
									<span className={classNames(["warning"])}>---- This feature is only enabled on test environments ----</span>
									<Button
										name="submit"
										type="submit"
										size="medium"
										disabled={loading}
										clickHandler={forceSuccessVerificationClickHandler}
									>
									Skip identity check - force successful verification
									</Button>
								</div>
							</div>
						)
					}
				</form>
			</RegisterStyles>
		</LoggedOutStyles>
	)
}

const IdentityVerification = ({ data, updateData, endpoint }) => {
	const registerTrackEvent = useRegisterTrack();
	const isRegister = !!useMatch("/register/*");
	const { isPostSurveyReferral } = useMemoizedContext("member",["isPostSurveyReferral"]);

	useEffect(() => {
		// registration-process-verify-address-view
		// psr-verify-identity-module-view
		registerTrackEvent({
			label: "verify-address",
			action: "view",
			psrLabel: "verify-identity-module",
		})
	}, []);

	return (
		<>
			{
				isRegister
				&& <LoggedOutStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<Form
								data={data}
								updateData={updateData}
								endpoint={endpoint}
							/>
						</div>
					</div>
				</LoggedOutStyles>
			}
			{
				!isRegister
				&& <ReverificationWrap>
					<Form
						data={data}
						updateData={updateData}
						endpoint={endpoint}
					/>
				</ReverificationWrap>
			}
		</>
	);
};

export default IdentityVerification;
