import { ColorGrey80 } from "@sermo/design-tokens";
import { List } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useMemo } from "react";
import styled from "styled-components";
import { StyledPanel } from "@components/LiveEvents/components/LiveEvents.components.styles";
import {
	MobileAndTabletPortrait,
	TabletLandscapeAndDesktop,
} from "@components/MediaQueries/MediaQueries";
import MobileRailCard from "@components/RailCards/components/MobileRailCard";
import { useFetch } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import EventItem from "./EventItem";

// Max length of Live Event items
const MAX_ITEMS = 4;

const CustomList = styled(List)`
	&> div {
		color: ${ColorGrey80};
		/* heading/heading-02 */
		font-family: Sailec;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		letter-spacing: -0.28px;
	}
`;

const Events = ({ trackingCategory }) => {
	const { hasAccess } = useMemoizedContext("member");
	const [data, loading] = useFetch("api/events/getevents");

	const canShowEvents = useMemo(() => (
		hasAccess("canRegisterToEventsInFrontend")
		&& !loading
		&& !!data.items?.length
	), [
		data,
		hasAccess,
		loading,
	]);

	const eventsContent = useMemo(() => data?.items?.slice(0, MAX_ITEMS).map(({
		id,
		isMemberRegistered,
		name,
		totalRegistrants,
		startDateTime,
		endDateTime,
		status,
	}) => (
		<EventItem
			{...{
				id,
				isRegistered: isMemberRegistered,
				name,
				totalRegistrants,
				startDateTime,
				endDateTime,
				status,
			}}
			trackingCategory={trackingCategory}
			key={`event_${id}`}
		/>
	)), [data.items, trackingCategory]);

	const getNumberOfEventsDescription = (events) => {
		switch(events.length) {
			case 1:
				return getTranslation("frontend.events.numberOfEvents.oneNum", true);
			case 2:
			case 3:
			case 4:
			default: // MAX_ITEMS === 4
				return getTranslation("frontend.events.numberOfEvents.multiple", true, events.length);
		}
	}

	if (!canShowEvents) {
		return null;
	}

	return (
		<div data-component="Events">
			<TabletLandscapeAndDesktop>
				<StyledPanel>
					<CustomList
						title={getTranslation("frontend.events.events")}
						className={"custom-heading"}
					>
						{eventsContent}
					</CustomList>
				</StyledPanel>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<MobileRailCard
					content={eventsContent}
					title={getNumberOfEventsDescription(data.items)}
					trackingLabel={"events"}
				/>
			</MobileAndTabletPortrait>
		</div>
	);
};

Events.propTypes = {
	trackingCategory: PropTypes.string,
};

Events.defaultProps = {
	trackingCategory: "right-rail",
};

export default Events;
