import styled from "styled-components";
import { applyZIndex } from "@frontend/styled";
export const MobileEditorContainer = styled.div `
	${applyZIndex("mobile-post-creation")};
	display: flex;
	flex-direction: column;
	-webkit-overflow-scrolling: touch;
	position: fixed;
	top: 50px;
	bottom: 48px;
	left: 0;
	right: 0;
	background-color: ${({ theme }) => theme.color.uiBackground};
	overflow-y: auto;

	textarea {
		height: 200px;
	}
`;
