import { useEffect } from "react";
import useResizeAware from "react-resize-aware";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useMemoizedContext } from "@hooks/Hooks";
export const useGetAdUnitProps = (inFeed) => {
    const { contentHeight, titleHeight, } = useMemoizedContext("postUIViewing", [
        "contentHeight",
        "titleHeight",
    ]);
    if (inFeed) {
        return {
            inFeed: true,
            contentHeight: contentHeight + titleHeight,
            isPadded: true,
        };
    }
    else {
        return {
            inFeed: false,
            contentHeight: contentHeight + titleHeight,
        };
    }
};
export const useMainAndTopResizeAware = () => {
    const [topResizeListener, topSizes] = useResizeAware();
    const [mainResizeListener, mainSizes] = useResizeAware();
    const { dispatch: dispatchDetachedFooter, } = useMemoizedContext("detachedFooter");
    const { mainSectionHeight, topSectionHeight, dispatch: dispatchPostUIViewing, } = useMemoizedContext("postUIViewing", [
        "mainSectionHeight",
        "topSectionHeight",
    ]);
    useEffect(() => {
        if (topSizes.height
            && topSizes.height !== topSectionHeight) {
            dispatchPostUIViewing({
                type: PostUIViewingActionTypes.SET_TOP_SECTION_HEIGHT,
                payload: {
                    topSectionHeight: topSizes.height,
                },
            });
        }
        if (mainSizes.height
            && mainSizes.height !== mainSectionHeight) {
            dispatchPostUIViewing({
                type: PostUIViewingActionTypes.SET_MAIN_SECTION_HEIGHT,
                payload: {
                    mainSectionHeight: mainSizes.height,
                },
            });
        }
    }, [
        dispatchDetachedFooter,
        dispatchPostUIViewing,
        mainSectionHeight,
        mainSizes.height,
        topSectionHeight,
        topSizes.height,
    ]);
    return {
        mainResizeListener,
        topResizeListener,
    };
};
// useEffect(() => {
// 	if (
// 		mainSectionHeight
// 		&& topSectionHeight
// 	) {
// 		let overrideOpenHeight = mainSectionHeight - (
// 			topSectionHeight + parseInt(getVariableFromCSS("--cards-spacing"))
// 		) + 2; // border
//
// 		if (!featured) {
// 			overrideOpenHeight = (
// 				isDesktop
// 					? EXPAND_BOTTOM_HEIGHT_DESKTOP
// 					: EXPAND_BOTTOM_HEIGHT_MOBILE
// 			) + (
// 				type === "isi"
// 					// In such case the ISI footer grows over the space of the Footnote if exists.
// 					? footerTotalHeight - initialHeight
// 					: 0
// 			) + 2;
// 		}
//
// 		console.log("useMainAndTopResizeAware", {
// 			mainSectionHeight,
// 			topSectionHeight,
// 			overrideOpenHeight,
// 		});
//
// 		dispatchDetachedFooter({
// 			type: DetachedFooterActionTypes.SET_OVERRIDE_OPEN_HEIGHT,
// 			payload: {
// 				overrideOpenHeight,
// 			},
// 		});
// 	}
// }, [
// 	dispatchDetachedFooter,
// 	mainSectionHeight,
// 	topSectionHeight,
// ]);
