export var EditorActionTypes;
(function (EditorActionTypes) {
    EditorActionTypes["Mark"] = "mark";
    EditorActionTypes["Block"] = "block";
    EditorActionTypes["Link"] = "link";
    EditorActionTypes["Indentation"] = "indentation";
})(EditorActionTypes || (EditorActionTypes = {}));
export var EditorElementType;
(function (EditorElementType) {
    EditorElementType["p"] = "paragraph";
    EditorElementType["ol"] = "ordered-list";
    EditorElementType["ul"] = "unordered-list";
    EditorElementType["li"] = "list-item";
    EditorElementType["lit"] = "list-item-text";
    EditorElementType["blockquote"] = "quote";
    EditorElementType["pre"] = "code";
    EditorElementType["h1"] = "heading-one";
    EditorElementType["h2"] = "heading-two";
    EditorElementType["h3"] = "heading-three";
    EditorElementType["h4"] = "heading-four";
    EditorElementType["h5"] = "heading-five";
    EditorElementType["h6"] = "heading-six";
    EditorElementType["a"] = "link";
    EditorElementType["mention"] = "mention";
})(EditorElementType || (EditorElementType = {}));
export var EditorActions;
(function (EditorActions) {
    EditorActions["Bold"] = "bold";
    EditorActions["Italic"] = "italic";
    EditorActions["Strike"] = "strikethrough";
    EditorActions["Link"] = "link";
    EditorActions["Ol"] = "ordered-list";
    EditorActions["Ul"] = "unordered-list";
    EditorActions["Indent"] = "indent";
    EditorActions["Outdent"] = "outdent";
})(EditorActions || (EditorActions = {}));
