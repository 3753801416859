import styled, { css } from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const MenuAndMore = styled.div`
	min-height: 32px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
`;

export const CardHeaderContainer = styled.div`
	--cards-padding-mobile: ${props => props.theme.layout.cardsPaddingMobile};
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	flex-direction: row;

	.card-menu {
		padding: 16px 16px 0 0;
		display: flex;

		@media ${MobileAndTabletPortraitQuery} {
			padding: 4px 12px 4px 4px;
			justify-content: space-between;
		}
	}

	.card-menu-inner {
		@media ${MobileAndTabletPortraitQuery} {
			display: flex;
		}
	}

	${props => props.$themeType === "TopBar" && css`
		align-items: center;

		@media ${MobileAndTabletPortraitQuery} {
			padding-bottom: 12px;
			padding-top: 8px;
			align-items: flex-start;
        }
	`}

	${props => props.$expanded && css`
		margin-bottom: 16px;
		flex-direction: row;

		@media ${MobileAndTabletPortraitQuery} {
			flex-direction: column-reverse;
		}
	`}
`;

