import classNames from "classnames";
import Button from "@components/Button/Button";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./FirstSurveyPendingVerification.scss";

const FirstSurveyPendingVerification = () => {
	if ("undefined" === typeof window) {
		return null;
	}

	let title = getTranslation("frontend.registration.firstSurveyPendingVerification.title");
	let descriptionFirst = getTranslation("frontend.registration.firstSurveyPendingVerification.descriptionFirst");
	let descriptionSecond = getTranslation("frontend.registration.firstSurveyPendingVerification.descriptionSecond");
	let descriptionThird = getTranslation("frontend.registration.firstSurveyPendingVerification.descriptionThird");
	let buttonText = getTranslation("frontend.registration.firstSurveyPendingVerification.buttonText");

	return (
		<div>
			<ModuleWrapper
				border={true}
				solid={true}
			>
				<div styleName={classNames(["styles.inner-module-container"])}>
					<div styleName={classNames(["styles.title", "typography.heading-brand-05"])}>{title}</div>
					<div
						styleName={
							classNames([
								"styles.content",
								"typography.body-long-brand-03",
							])
						}
					>
						{descriptionFirst}
					</div>
					<div
						styleName={
							classNames([
								"styles.content",
								"typography.body-long-brand-03",
							])
						}
					>
						{descriptionSecond}
					</div>
					<br /><br />
					<hr></hr>
					<br />
					<div
						styleName={
							classNames([
								"styles.footer-content-container ",
								"typography.body-long-brand-03",
							])
						}
					>
						{descriptionThird}
						<a href="https://www.sermo.com/resources/category/physician-resources/">
							<Button
								size="medium"
							>
								{buttonText}
							</Button>
						</a>
					</div>
				</div>
			</ModuleWrapper>
		</div>
	);
};

export default FirstSurveyPendingVerification;
