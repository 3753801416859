import { DraftStatusTypes } from "@frontend/types/Post/postUI";
export const PostUIActionTypes = Object.freeze({
    CANCEL_EDITING: "CANCEL_EDITING",
    CANCEL_PUBLISHING: "CANCEL_PUBLISHING",
    PUBLISH_DRAFT: "PUBLISH_DRAFT",
    SET_CARD_ELEMENT: "SET_CARD_ELEMENT",
    SET_CARD_DATA: "SET_CARD_DATA",
    SET_CARD_DRAFT_DATA: "SET_CARD_DRAFT_DATA",
    SET_CARD_DRAFT_TOPIC: "SET_CARD_DRAFT_TOPIC",
    START_DRAFT: "START_DRAFT",
    START_EDITING: "START_EDITING",
    START_PUBLISHING: "START_PUBLISHING",
});
const PostUIActionTypesValues = Object.values(PostUIActionTypes);
const PostUIReducer = (state, { payload = {}, type, }) => {
    switch (type) {
        case PostUIActionTypes.CANCEL_EDITING: {
            return {
                ...state,
                draftStatus: DraftStatusTypes.PUBLISHED,
                cardDraftData: {
                    ...state.cardDraftData,
                    ...state.cardData,
                },
            };
        }
        case PostUIActionTypes.CANCEL_PUBLISHING: {
            return {
                ...state,
                publishing: false,
            };
        }
        case PostUIActionTypes.PUBLISH_DRAFT: {
            const cardData = {
                ...state.cardData,
                ...state.cardDraftData,
            };
            if (state.cardData?.id) {
                cardData["id"] = state.cardData.id;
            }
            return {
                ...state,
                cardData,
                draftStatus: DraftStatusTypes.PUBLISHED,
                publishing: false,
            };
        }
        case PostUIActionTypes.SET_CARD_ELEMENT: {
            return {
                ...state,
                cardElement: payload.cardElement,
            };
        }
        case PostUIActionTypes.SET_CARD_DATA: {
            return {
                ...state,
                cardData: payload.cardData,
            };
        }
        case PostUIActionTypes.SET_CARD_DRAFT_DATA: {
            return {
                ...state,
                cardDraftData: payload.cardDraftData,
            };
        }
        case PostUIActionTypes.SET_CARD_DRAFT_TOPIC: {
            return {
                ...state,
                cardDraftData: {
                    ...state.cardDraftData,
                    topic: payload.topic,
                },
            };
        }
        case PostUIActionTypes.START_DRAFT: {
            return {
                ...state,
                publishing: false,
            };
        }
        case PostUIActionTypes.START_EDITING: {
            return {
                ...state,
                draftStatus: DraftStatusTypes.EDITING,
                cardDraftData: {
                    ...state.cardData,
                    ...state.cardDraftData,
                    ...payload.draft,
                    // @ts-ignore
                    topicId: state.cardData.topic?.id,
                },
            };
        }
        case PostUIActionTypes.START_PUBLISHING: {
            return {
                ...state,
                publishing: true,
            };
        }
        default:
            return state;
    }
};
export default PostUIReducer;
