import { ChevronLeftSVG, Icon } from "@sermo/ui-components";
import { useCallback, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@components/Button/Button";
import FollowedIndicator from "@components/Card/components/FollowedIndicator/FollowedIndicator";
import { MobileAndTabletPortrait, MobileAndTabletPortraitQuery, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModalBackground from "@components/ModalBackground/ModalBackground";
import usePostUIAPI from "@contexts/PostUI/usePostUIAPI";
import { EUIKey, useCloseBanner, useCollapsePost, useOpenBanner } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useGetPostTrackingCategory, useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./CardModal.styles";
const CardModal = ({ isEditing = false, }) => {
    const category = useGetPostTrackingCategory();
    const closeBanner = useCloseBanner();
    const collapsePost = useCollapsePost();
    const desktopNavRef = useRef();
    const isMobile = useMediaQuery({ query: MobileAndTabletPortraitQuery });
    const mobileNavRef = useRef();
    const navigate = useNavigate();
    const openBanner = useOpenBanner();
    const postUIAPI = usePostUIAPI();
    const trackEvent = useTrackEvent();
    const { pathname, search, state, } = useLocation();
    const { expandedPost, } = useMemoizedContext("ui", [
        "expandedPost",
    ]);
    const { cardData, } = useMemoizedContext("postUI", [
        "cardData",
    ]);
    const { eventSource, expanded, isSingleCard, } = useMemoizedContext("postUIViewing", [
        "eventSource",
        "expanded",
        "isSingleCard",
    ]);
    const { adFrequency, adId, adType, cardType, header, id: postId, sermoContentCardId, syncPostDataFromExpanded, title, topic, } = useMemoizedContext("postData", [
        "adFrequency",
        "adId",
        "adType",
        "cardType",
        "header",
        "id",
        "sermoContentCardId",
        "title",
        "topic",
    ]);
    const onTouchMove = useCallback((e) => {
        if (expandedPost) {
            e.preventDefault();
            e.stopPropagation();
        }
    }, [expandedPost]);
    useEffect(() => {
        if (mobileNavRef.current) {
            // @ts-ignore
            mobileNavRef.current.addEventListener("touchmove", onTouchMove, { passive: false });
        }
        return () => {
            if (mobileNavRef.current) {
                // @ts-ignore
                mobileNavRef.current.removeEventListener("touchmove", onTouchMove, { passive: false });
            }
        };
    }, [onTouchMove]);
    const handleEditCancel = () => {
        postUIAPI.cancelEdit();
    };
    const handlePostCollapse = () => {
        let area = eventSource || "expand-post";
        let cardTypeLabel = adType
            ? adType.toLowerCase()
            : "post";
        trackEvent({
            category: cardTypeLabel + "-collapsed",
            action: "collapse",
            label: "in-feed",
            postId,
            adId,
            adFrequency,
            expanded,
            area,
            sermoContentCardId,
        });
        // if this function exists we are in an expanded post
        // this method takes the current expanded context and updates the originating cards context with any changes
        if (syncPostDataFromExpanded) {
            syncPostDataFromExpanded(cardData);
        }
        closeBanner("expanded-post");
        collapsePost();
        const pathnameWithoutPost = pathname.replace(/\/post\/\d+(\/.+)?/, "");
        const expandedRemovedRoute = pathname.replace(/\/expanded/, "");
        const goBackState = {
            state: {
                goBack: true,
            },
        };
        if (state?.background
            && !isSingleCard) {
            navigate(-1);
        }
        else if (pathname.match(/\/search/)) {
            navigate(`${pathnameWithoutPost}${search}`, goBackState);
        }
        else if (pathname.match(/\/expanded/)) {
            navigate(expandedRemovedRoute, goBackState);
        }
        else {
            navigate("/feed/all-sermo");
        }
    };
    const trackLinkClick = (e) => {
        if ("A" === e.target.tagName) {
            if (e.target.hasAttribute("href")) {
                const href = e.target.getAttribute("href");
                let area = e.eventSource || eventSource;
                let action = "click";
                let label = "link";
                const title = e.target.textContent || e.target.temporalDivElement;
                if (area) {
                    if (typeof area !== "string") {
                        action = "download";
                        label = area.document;
                        area = area.area;
                    }
                }
                trackEvent({
                    category,
                    action,
                    label,
                    area,
                    url: href,
                    urlTitle: title,
                    postId,
                    adId,
                    adFrequency,
                    expanded,
                    sermoContentCardId,
                });
            }
        }
    };
    const handleBannerTitleClick = (e) => {
        e.eventSource = "banner-title";
        trackLinkClick(e);
    };
    const component = (<>
			<TabletLandscapeAndDesktop>
				<Styles.Banner onClick={handleBannerTitleClick} ref={desktopNavRef}>
					{topic && <Styles.BannerTopic>{topic.name}</Styles.BannerTopic>}
					<Styles.BannerTitle dangerouslySetInnerHTML={{ __html: header || title }}/>
					<FollowedIndicator contextClass="card-expanded-banner"/>
				</Styles.Banner>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<Styles.MobileNavContainer ref={mobileNavRef}>
					<Styles.CardExpanded>
						<Styles.ButtonContainer>
							<Button clickHandler={isEditing
            ? handleEditCancel
            : handlePostCollapse} icon={<Icon src={ChevronLeftSVG}/>} style="flatNeutral"/>
						</Styles.ButtonContainer>
						<Styles.TitleAndTopic>
							{topic && (<Styles.Topic $kind={"overline01"}>
										{topic.name}
									</Styles.Topic>)}
							{title && (<Styles.Title dangerouslySetInnerHTML={{ __html: title }} $isAd={"Ad" === cardType} $kind={"heading02"}/>)}
						</Styles.TitleAndTopic>
					</Styles.CardExpanded>
				</Styles.MobileNavContainer>
			</MobileAndTabletPortrait>
		</>);
    useEffect(() => {
        if (expandedPost) {
            openBanner({
                [EUIKey.BANNER_CAN_CLOSE]: !isMobile,
                [EUIKey.BANNER_CLOSE_METHOD]: handlePostCollapse,
                [EUIKey.BANNER_COMPONENT]: component,
                [EUIKey.BANNER_LABEL]: "expanded-post",
                [EUIKey.BANNER_SUBTYPE]: "expanded-post-banner",
            });
        }
        else {
            closeBanner("expanded-post");
        }
    }, [
        expandedPost,
    ]);
    return (<ModalBackground clickHandler={isEditing
            ? handleEditCancel
            : handlePostCollapse} sponsored={"Ad" === cardType} card={true}/>);
};
export default CardModal;
