import classNames from "classnames";
import React from "react";
import Heading from "../Heading/Heading";
import * as Styles from "./ModuleWrapper.styles";
const ModuleWrapper = ({ border, borderDarker, children, classes, clickHandler, contextClass, customPadding, forwardRef, id, label, mouseEnterHandler, mouseLeaveHandler, nested, notPaddedX, notPaddedY, padded, paddedWider, paddedWidest, solid, type, ...rest }) => {
    const allClasses = [
        "module-wrapper",
        { "solid": solid },
        { "border": border },
        { "border-darker": borderDarker },
        { "nested": nested },
        { "padded": padded },
        { "padded-wider": paddedWider },
        { "padded-widest": paddedWidest },
        { "not-padded-x": notPaddedX },
        { "not-padded-y": notPaddedY },
        { [`context-${contextClass}`]: contextClass },
        ...classes,
    ];
    return (<Styles.Container {...rest} className={classNames(allClasses)} id={id} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} onClick={clickHandler} ref={forwardRef} $customPadding={customPadding}>
			{label && (<Heading context="rail-module" type={type}>
						{label}
					</Heading>)}
			{children}
		</Styles.Container>);
};
ModuleWrapper.defaultProps = {
    border: false,
    borderDarker: false,
    classes: [],
    clickHandler: () => { },
    contextClass: "",
    customPadding: "",
    forwardRef: undefined,
    label: "",
    mouseEnterHandler: () => { },
    mouseLeaveHandler: () => { },
    nested: false,
    notPaddedX: false,
    notPaddedY: false,
    padded: true,
    paddedWider: false,
    paddedWidest: false,
    solid: false,
    type: "default",
};
export default ModuleWrapper;
