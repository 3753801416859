import Button from "@components/Button/Button";
import { ModalContent, StyledAlertText } from "@components/Modal/Modal.styles";
import { EUIKey, useCloseAlert, useOpenAlert } from "@contexts/UI";
import { getCookie } from "@frontend/Utils";
import { useApiEndpoint, useInterval, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const versionChangeStatus = {
    lastNotifiedOfVersionChangeAt: null,
    dismissedAt: null,
};
export const useGetMemberDataAndKeepAlive = () => {
    const apiEndpoint = useApiEndpoint();
    const closeAlert = useCloseAlert();
    const openAlert = useOpenAlert();
    const { active, isTemporarilyAuthenticated, locale, loggedIn, rights, updateMember, } = useMemoizedContext("member", [
        "active",
        "isTemporarilyAuthenticated",
        "locale",
        "loggedIn",
        "rights",
    ]);
    useInterval(() => {
        if (loggedIn
            && active) {
            apiEndpoint("system/getmemberdata").then((dataResponse) => {
                dataResponse.json().then((body) => {
                    if (window.sermo.user.memberId !== body.memberId) {
                        // @ts-ignore
                        window.location = "/login?returnUrl=" + escape(window.location.href);
                        return;
                    }
                    let blockedValues = {};
                    if (isTemporarilyAuthenticated) {
                        blockedValues = {
                            affiliateMember: false,
                            hasAcceptedTermsAndConditions: true,
                            hasBeenAuthenticatedViaToken: false,
                            isTemporarilyAuthenticated: true,
                            hasSetPassword: true,
                            hasConfiguredProfile: true,
                            rights: {
                                ...rights,
                                canAccessFrontendSurveys: true,
                                canWithdrawMoneyFromOwnAccount: true,
                            },
                        };
                    }
                    if (dataResponse.ok) {
                        updateMember({
                            ...body,
                            ...blockedValues,
                        });
                    }
                });
            });
            // We can use this function to call the keep-alive endpoint on the server in case we need to.
            // Fix for IE and Edge:
            // using fetch on Edge is causing the set-cookie header to not set a cookie on the browser.
            // The solution was to add credentials: "same-origin" to the fetch options object.
            apiEndpoint("system/keepalive").then((response) => {
                if (response.status === 401) {
                    // We got a 401 Access Denied. Check the system endpoint to verify access.
                    // If that endpoint fails as well, it means that the member has been signed out,
                    // and we will redirect them to the login screen.
                    fetch(`${locale}/api/system/verifyaccess`, {
                        method: "POST",
                        credentials: "same-origin",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                            "X-Sermo-MemberId": window?.sermo?.user?.memberId ?? "",
                        },
                    }).then((systemResponse) => {
                        if (systemResponse.status === 401) {
                            if ("undefined" !== typeof window) {
                                // @ts-ignore
                                window.location = "/login?returnUrl=" + escape(window.location.href);
                            }
                        }
                    });
                }
                else if (response.status === 503) {
                    window.location.reload();
                }
                else if (response.status === 200) {
                    if (window.sermo
                        && window.sermo.versionHash) {
                        // only notify user on version change every 5 minutes
                        if (Date.now() - 300000 > versionChangeStatus.lastNotifiedOfVersionChangeAt) {
                            response.json().then((responseObj) => {
                                const versionHash = responseObj.versionHash;
                                if (versionHash !== window.sermo.versionHash) {
                                    versionChangeStatus.lastNotifiedOfVersionChangeAt = Date.now();
                                    console.log(`VersionHash has changed. Current VersionHash:
											'${window.sermo.versionHash}',
											New VersionHash: '${versionHash}'.`);
                                    openAlert({
                                        [EUIKey.ALERT_CLOSE_METHOD]: () => {
                                            versionChangeStatus.dismissedAt = Date.now();
                                            closeAlert("version-change");
                                        },
                                        [EUIKey.ALERT_COMPONENT]: (<ModalContent>
												<StyledAlertText>
													{getTranslation("frontend.alerts.refreshPage")}
												</StyledAlertText>
												<Button style="flatNeutral" size="small" clickHandler={() => {
                                                window.location.reload();
                                            }}>
													{getTranslation("frontend.generics.refresh")}
												</Button>
											</ModalContent>),
                                        [EUIKey.ALERT_LABEL]: "version-change",
                                        [EUIKey.ALERT_PERSIST]: true,
                                    });
                                }
                            });
                        }
                    }
                }
            });
        }
    }, 30000);
};
/*
* TODO: add more routes to this file to clean up the AppRoutes.tsx file
*   OR if this seems unnecessary, move the hooks back to the AppRoutes.tsx file
*/ 
