import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import Button from "@components/Button/Button";
import Card from "@components/Card/Card";
import DrugCard from "@components/DrugCard/DrugCard";
import { Loading } from "@components/Helpers/Helpers";
import IndicationCard from "@components/IndicationCard/IndicationCard";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import MemberCard from "@components/MemberCard/MemberCard";
import Pagination from "@components/Pagination/Pagination";
import NoResults from "@components/Search/NoResults";
import { useFetch, useMemoizedContext, useSearchParams } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { CardTypeTypes, PostTypeTypes } from "@types/Post/postData";
import styles from "./Search.scss";

const MobileButtonWrapper = ({ children }) => {
	const isMobile = useMediaQuery({ query: MobileAndTabletPortraitQuery });

	return isMobile
		? <div styleName="styles.mobile-button-wrapper">{children}</div>
		: <>{children}</>;
};

const SearchResultsAll = ({ data }) => {
	const { hasAccess } = useMemoizedContext("member");
	const searchParams = useSearchParams();
	let groups = {};
	data.groups?.forEach(group => {
		groups[group.type] = {
			count: group.count,
			items: group.items,
		};
	});

	return (
		<>
			{
				Object.prototype.hasOwnProperty.call(groups,"Post") && groups.Post.items && (
					<div styleName="styles.results-section">
						<SearchPosts posts={groups.Post.items} />
						<MobileButtonWrapper>
							<Button
								style="secondary"
								to={`/search/posts${searchParams.stringify()}`}
							>
								{getTranslation("frontend.search.seeAllPosts")}
							</Button>
						</MobileButtonWrapper>
					</div>
				)
			}
			{
				Object.prototype.hasOwnProperty.call(groups,"Member") && groups.Member.items && (
					<div styleName="styles.results-section">
						<SearchMembers members={groups.Member.items} />
						<MobileButtonWrapper>
							<Button
								style="secondary"
								to={`/search/people${searchParams.stringify()}`}
							>
								{getTranslation("frontend.search.seeAllMembers")}
							</Button>
						</MobileButtonWrapper>
					</div>
				)
			}
			{
				Object.prototype.hasOwnProperty.call(groups,"DrugRating")
				&& groups.DrugRating.items
				&& hasAccess("canAccessFrontendDrugRatings")
				&& (
					<div styleName="styles.results-section">
						<SearchDrugRatings drugRatings={groups.DrugRating.items} />
						<MobileButtonWrapper>
							<Button
								style="secondary"
								to={`/search/rate${searchParams.stringify()}`}
							>
								{getTranslation("frontend.search.seeAllDrugRatings")}
							</Button>
						</MobileButtonWrapper>
					</div>
				)
			}
		</>
	);
};

export default SearchResultsAll;

const SearchResultsWithData = ({ section, page, onLoadHandler = () => { } }) => {
	const [lastLoadedCount, setLastLoadedCount] = useState(0);
	const searchParams = useSearchParams();
	const query = searchParams.get();

	const {
		countUpdate,
		ratingType,
		postOrder,
		connection,
		postType,
		location,
		dateRange,
		totalCount,
	} = useMemoizedContext("search", [
		"ratingType",
		"postOrder",
		"connection",
		"postType",
		"location",
		"dateRange",
		"totalCount",
	]);

	const [data, loading] = useFetch("api/search/search", {
		query: query,
		type: section,
		page: page,
		ratingType: ratingType,
		postOrder: postOrder,
		connection: connection.map(type => type.value),
		postTypes: postType.map(type => type.value),
		location: location,
		dateRange: dateRange,
	}, !!query);

	useEffect(() => {
		if (!loading && data && data.totalCount > 0) {
			setLastLoadedCount(data.groups[0].items.length);
		}
	}, [loading]);

	useEffect(() => {
		onLoadHandler(lastLoadedCount);
	},[lastLoadedCount])

	useEffect(() => {
		if (page === 0 && data && !loading && totalCount !== data.totalCount) {
			countUpdate(data.totalCount);
		}
	}, [loading, data]);

	if (!query) { return <NoResults />; }

	if (loading && !page) {
		// Only show loading on first page to avoid cloning in Pagination
		return <Loading />;
	}

	if (!data || data.totalCount === 0 || !data?.groups || data.groups.length === 0) {
		if (page === 0) {
			return <NoResults query={query} />;
		}
		return null;
	}

	const { items } = data.groups[0];

	switch (section) {
		case "Post":
			return <SearchPosts posts={items} />;
		case "Member":
			return <SearchMembers members={items} />;
		case "DrugRating":
			return <SearchDrugRatings drugRatings={items} />;
		default:
			return <NoResults query={query} />;
	}
};

const SearchResults = props => {
	const { section } = useParams();

	const typeMap = {
		posts: "Post",
		people: "Member",
		rate: "DrugRating",
	};

	const resolvedProps = {
		...props,
		section: typeMap[section],
	};

	return (
		<Pagination props={resolvedProps}>
			<SearchResultsWithData {...resolvedProps} />
		</Pagination>
	);
};

export { SearchResults };

const SearchPosts = ({ posts }) => posts.map((card, i) => {
	if (PostTypeTypes.COMMENT === card.type) {
		card.cardType = CardTypeTypes.COMMENT_GROUP;
		card.id = card.postId;
		card.title = card.postTitle;
		card.comments = [
			{
				id: card.commentId,
				isAutoTranslated: card.isAutoTranslated,
				posted: card.posted,
				postId: card.postId,
				comment: card.comment,
				display: "Normal",
				user: card.user,
			},
		];
	}

	return <Card
		state={card}
		key={i}
	/>;
});

const SearchMembers = ({ members }) => members.map((member, i) => <MemberCard
	user={member}
	key={i}
/>);

const SearchDrugRatings = ({ drugRatings }) => drugRatings.map((drugRating, i) => {
	if (drugRating.type === "Indication") {
		return <IndicationCard
			indication={drugRating}
			key={i}
		/>;
	}

	return <DrugCard
		drugRating={drugRating}
		key={i}
	/>;
});
