import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useFetch } from "@hooks/Hooks";

const DeleteThread = ({ memberId, aId }) => {
	const [data, loading] = useFetch("api/messages/deletethread", {
		memberId: memberId,
		aId: aId,
	});

	if (loading) {
		return null;
	}
	return <Navigate
		to="/messages"
		replace
	/>;
};

DeleteThread.propTypes = {
	memberId: PropTypes.number,
	aId: PropTypes.number,
};

export { DeleteThread };
