import { PostUIViewingDefaultValues, } from "@contexts/PostUI/Viewing/PostUIViewingContext";
export const PostUIViewingActionTypes = Object.freeze({
    RESET: "RESET",
    SET_COMMENTS_CLICKED_FALSE: "SET_COMMENTS_CLICKED_FALSE",
    SET_COMMENTS_CLICKED_TRUE: "SET_COMMENTS_CLICKED_TRUE",
    SET_COMMENTS_SORT: "SET_COMMENTS_SORT",
    SET_CONTENT_HEIGHT: "SET_CONTENT_HEIGHT",
    SET_EVENT_SOURCE: "SET_EVENT_SOURCE",
    SET_EXPANDED_FALSE: "SET_EXPANDED_FALSE",
    SET_EXPANDED_TRUE: "SET_EXPANDED_TRUE",
    SET_JUST_PUBLISHED_FALSE: "SET_JUST_PUBLISHED_FALSE",
    SET_JUST_PUBLISHED_TRUE: "SET_JUST_PUBLISHED_TRUE",
    SET_TITLE_HEIGHT: "SET_TITLE_HEIGHT",
    SET_TOP_SECTION_HEIGHT: "SET_TOP_SECTION_HEIGHT",
    SET_MAIN_SECTION_HEIGHT: "SET_MAIN_SECTION_HEIGHT",
    TOGGLE_SHOW_INTRODUCTION: "TOGGLE_SHOW_INTRODUCTION",
    TOGGLE_SHOW_ORIGINAL_CONTENT: "TOGGLE_SHOW_ORIGINAL_CONTENT",
});
const PostUIViewingActionTypesValues = Object.values(PostUIViewingActionTypes);
const PostUIViewingReducer = (state, action) => {
    switch (action.type) {
        case PostUIViewingActionTypes.RESET: {
            return {
                ...PostUIViewingDefaultValues,
            };
        }
        case PostUIViewingActionTypes.SET_COMMENTS_CLICKED_FALSE: {
            return {
                ...state,
                commentsClicked: false,
            };
        }
        case PostUIViewingActionTypes.SET_COMMENTS_CLICKED_TRUE: {
            return {
                ...state,
                commentsClicked: true,
            };
        }
        case PostUIViewingActionTypes.SET_COMMENTS_SORT: {
            return {
                ...state,
                commentsSort: action.payload.commentsSort,
            };
        }
        case PostUIViewingActionTypes.SET_CONTENT_HEIGHT: {
            return {
                ...state,
                contentHeight: action.payload.contentHeight,
            };
        }
        case PostUIViewingActionTypes.SET_EVENT_SOURCE: {
            return {
                ...state,
                eventSource: action.payload.eventSource,
            };
        }
        case PostUIViewingActionTypes.SET_EXPANDED_FALSE: {
            return {
                ...state,
                expanded: false,
            };
        }
        case PostUIViewingActionTypes.SET_EXPANDED_TRUE: {
            return {
                ...state,
                expanded: true,
            };
        }
        case PostUIViewingActionTypes.SET_JUST_PUBLISHED_FALSE: {
            return {
                ...state,
                justPublished: false,
            };
        }
        case PostUIViewingActionTypes.SET_JUST_PUBLISHED_TRUE: {
            return {
                ...state,
                justPublished: true,
            };
        }
        case PostUIViewingActionTypes.SET_TITLE_HEIGHT: {
            return {
                ...state,
                titleHeight: action.payload.titleHeight,
            };
        }
        case PostUIViewingActionTypes.SET_TOP_SECTION_HEIGHT: {
            return {
                ...state,
                topSectionHeight: action.payload.topSectionHeight,
            };
        }
        case PostUIViewingActionTypes.SET_MAIN_SECTION_HEIGHT: {
            return {
                ...state,
                mainSectionHeight: action.payload.mainSectionHeight,
            };
        }
        case PostUIViewingActionTypes.TOGGLE_SHOW_INTRODUCTION: {
            return {
                ...state,
                showIntroduction: !state.showIntroduction,
            };
        }
        case PostUIViewingActionTypes.TOGGLE_SHOW_ORIGINAL_CONTENT: {
            return {
                ...state,
                showOriginalContent: !state.showOriginalContent,
            };
        }
        default:
            return state;
    }
};
export default PostUIViewingReducer;
