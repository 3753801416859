import AdPreviewFeaturedSurvey from "@components/AdPreviewLayout/AdPreviewFeaturedSurvey";
import { ClickStop } from "@components/Helpers/Helpers";
import Rail from "@components/Layout/components/Rail";

const RightRail = () => (
	<ClickStop>
		<Rail side={"right"}>
			<AdPreviewFeaturedSurvey />
		</Rail>
	</ClickStop>
);

export default RightRail;