import { ListItem } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";

export const StyledItemTitle = styled(TextStyled)`
	color: #101830;
	margin-top: 4px;

	${StyledTabletLandscapeAndDesktopQuery} {
		margin-bottom: 9px;
	}
`;

export const StyledItem = styled(ListItem)`
	cursor: pointer;

	${StyledTabletLandscapeAndDesktopQuery} {
		padding: 12px 0 16px 16px;
	}

	&:hover {
		background-color: #ecedef;

		a {
			text-decoration: none;
			width: 100%;

			div {
				color: #101830;
			}
		}
	}

`;