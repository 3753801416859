import { useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";
import NoResults from "@components/Search/NoResults";
import SearchResultsAll from "@components/Search/SearchResults";
import { useFetch, useMemoizedContext, useSearchParams } from "@hooks/Hooks";

import typography from "../../scss/typography.scss";
import styles from "./Search.scss";

const SearchContent = () => {
	const searchParams = useSearchParams();
	const passedQuery = searchParams.get();
	const {
		searchUpdate,
		filter,
	} = useMemoizedContext("search", [
		"searchUpdate",
		"filter",
	]);

	const { hasAccess } = useMemoizedContext("member", ["hasAccess"]);

	const [data, loading] = useFetch("api/search/search", {
		query: passedQuery,
		type: null,
		page: 0,
		dateRange: null,
		connection: null,
		location: null,
		specialties: [],
		filter: filter,
	}, hasAccess("canAccessFrontendSearch") && !!passedQuery);

	useEffect(() => {
		searchUpdate({
			query: passedQuery,
		});
	}, [passedQuery]);

	useEffect(() => {
		if (data && !loading) {
			searchUpdate({
				totalCount: data.totalCount,
			});
		}
	}, [loading, data]);

	if (!hasAccess("canAccessFrontendSearch")) {
		return null;
	}

	if (
		loading
		&& passedQuery
	) {
		return <Loading />;
	}

	if (
		!passedQuery
		|| !data
		|| data.groups?.length === 0
	) {
		return <NoResults query={passedQuery} />;
	}

	return <SearchResultsAll
		query={passedQuery}
		data={data}
	/>;
};

export default SearchContent;
