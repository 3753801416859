// Overall components
import ModalColumn from "./ModalColumn";
import ModalColumnWrapper from "./ModalColumnWrapper";
import ModalContainer from "./ModalContainer";
import ModalFieldLabel from "./ModalFieldLabel";
import ModalFooter from "./ModalFooter";
import ModalSection from "./ModalSection";
import ModalSeparator from "./ModalSeparator";
import ModalText from "./ModalText";
import ModalTitle from "./ModalTitle";

// Withdrawal namespace
import AddressCard from "./Withdrawal/AddressCard";
import AmountOverview from "./Withdrawal/AmountOverview";
import AmountValidation from "./Withdrawal/AmountValidation";
import BalanceAvailable from "./Withdrawal/BalanceAvailable";
import DetailPair from "./Withdrawal/DetailPair";
import PaymentMethod from "./Withdrawal/PaymentMethod";
import SubText from "./Withdrawal/SubText";
import Table from "./Withdrawal/Table";

const Withdrawal = { AddressCard, SubText, Table, PaymentMethod, BalanceAvailable, AmountOverview, DetailPair, AmountValidation };

export {
	ModalColumnWrapper,
	ModalSeparator,
	ModalColumn,
	ModalContainer,
	ModalText,
	ModalSection,
	ModalFooter,
	ModalTitle,
	ModalFieldLabel,
	Withdrawal,
};