import React from "react";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SponsoredContentInFeed from "@components/PostContent/components/SponsoredContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader/CardHeader";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const SponsoredQuestionPoll = () => (<>
		<CardContainer>
			<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
				<SharedStyles.AdSpacingContainer>
					<CardHeader />
					<SponsoredContentInFeed />
					<PollDisplay />
				</SharedStyles.AdSpacingContainer>
			</ModuleWrapper>
			<CardFooter />
		</CardContainer>
	</>);
export default SponsoredQuestionPoll;
