import { MinusIcon } from "@sermo/ui-components";
import React, { useMemo, useState } from "react";
import CustomStyleContent from "@components/DetachedFooter/components/shared/CustomStyleContent";
import ExpandableFooter from "@components/DetachedFooter/components/shared/ExpandableFooter";
import { CollapseButton, InFeedCardWrapper } from "@components/DetachedFooter/DetachedFooter.styles";
import { AdTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
const InFeedCardFooter = ({ type, text, className, customStyle, onClick, initialHeight, isPadded, }) => {
    const [openHeight, setOpenHeight] = useState(96);
    const { footerOpen, setFooterClose, footerTotalHeight } = useMemoizedContext("detachedFooter", ["footerOpen", "footerTotalHeight"]);
    const { adType, attachments } = useMemoizedContext("postData", ["adType", "attachments"]);
    const featured = attachments.find((attachment) => Object.prototype.hasOwnProperty.call(attachment, "featured") && attachment.featured);
    const expandBottom = useMemo(() => !featured && adType !== AdTypeTypes.CAROUSEL, [adType, featured]);
    const passToParent = (data) => {
        if (data?.openHeight) {
            setOpenHeight(data?.openHeight);
        }
    };
    const collapse = (event) => {
        event?.stopPropagation();
        setFooterClose();
    };
    /**
     * This is a fix for the footer to be positioned correctly when the ISI is open to overlap Footnote if present.
     */
    const marginTopFix = useMemo(() => footerOpen === type && type === "isi"
        ? `-${footerTotalHeight - initialHeight}px`
        : "0", [footerOpen, footerTotalHeight, initialHeight, type]);
    return (<InFeedCardWrapper initialHeight={initialHeight} openHeight={openHeight} isPadded={isPadded} footerOpen={footerOpen === type} expandBottom={expandBottom} marginTopFix={marginTopFix}>
			{/* Only visible to collapse when open in Feed for positioning */}
			<CollapseButton icon={MinusIcon} kind="flatNeutral" onClick={collapse} size="xsmall"/>
			<ExpandableFooter type={type} customStyle={customStyle} onClick={onClick} passToParent={passToParent} expandBottom={expandBottom} isInline={true} initialHeight={initialHeight}>
				<CustomStyleContent type={type} className={className} customStyle={customStyle} text={text}/>
			</ExpandableFooter>
		</InFeedCardWrapper>);
};
export default InFeedCardFooter;
