import React, { useState } from "react";
import { useSlate } from "slate-react";
import { EditorLinkModal } from "@components/Editor/EditorLinkModal";
import { ToolbarIconButton, ToolbarRoot } from "./Editor.styles";
import { ActionMap, isMarkActive } from "./helper";
import { EditorActions } from "./model/EditorTypes";
export const EditorToolbar = ({ actions, onClick, onInsertLink, disabled }) => {
    const editor = useSlate();
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const entries = actions && actions?.length
        ? Object.entries(ActionMap).filter(([mark]) => actions.includes(mark))
        : Object.entries(ActionMap);
    const handleAction = (action) => (event) => {
        event.preventDefault();
        if (onClick) {
            onClick(event, action);
        }
    };
    const handleLinkAction = () => {
        setOpenLinkModal(prev => !prev);
    };
    const handleSubmit = (value) => {
        const { display, url } = value;
        if (onInsertLink) {
            onInsertLink({
                url,
                display,
            });
        }
        handleLinkAction();
    };
    return (<ToolbarRoot>
			{entries.map((action, key) => {
            const [mark, value] = action;
            const { label, icon } = value;
            return (<ToolbarIconButton disabled={disabled} active={isMarkActive(editor, mark)} key={key} aria-label={label} icon={icon} size="xsmall" kind="flatNeutral" onMouseDown={mark === EditorActions.Link
                    ? handleLinkAction
                    : handleAction(action)}/>);
        })}
			{openLinkModal && (<EditorLinkModal onRequestClose={() => setOpenLinkModal(false)} onSubmit={handleSubmit}/>)}
		</ToolbarRoot>);
};
