import { useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { EUIKey, } from "@contexts/UI/UIContext.types";
import { UIActionTypes } from "@contexts/UI/UIReducer";
import { useMemoizedContext } from "@hooks/Hooks";
export const useCollapsePost = () => {
    const { dispatch } = useMemoizedContext("ui");
    const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
    return useCallback(() => {
        dispatch({
            type: isMobileOrTabletPortrait
                ? UIActionTypes.COLLAPSE_POST_MOBILE
                : UIActionTypes.COLLAPSE_POST,
        });
    }, [dispatch, isMobileOrTabletPortrait]);
};
export const useCloseAlert = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((_alertName) => {
        dispatch({
            type: UIActionTypes.CLOSE_ALERT,
        });
    }, [
        dispatch,
    ]);
};
export const useCloseBanner = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((bannerName) => {
        dispatch({
            type: UIActionTypes.CLOSE_BANNER,
            payload: {
                [EUIKey.BANNER_LABEL]: bannerName,
            },
        });
    }, [dispatch]);
};
export const useCloseMenu = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((_menuName) => {
        dispatch({
            type: UIActionTypes.CLOSE_MENU,
        });
    }, [
        dispatch,
    ]);
};
export const useCloseModal = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((modalName) => {
        dispatch({
            type: UIActionTypes.CLOSE_MODAL,
            payload: {
                [EUIKey.MODAL_LABEL]: modalName,
            },
        });
    }, [dispatch]);
};
export const useCloseTour = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback(() => {
        dispatch({
            type: UIActionTypes.CLOSE_TOUR,
        });
    }, [
        dispatch,
    ]);
};
export const useExpandPost = () => {
    const { dispatch } = useMemoizedContext("ui");
    const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
    return useCallback((post) => {
        dispatch({
            type: isMobileOrTabletPortrait
                ? UIActionTypes.EXPAND_POST_MOBILE
                : UIActionTypes.EXPAND_POST,
            payload: {
                [EUIKey.EXPANDED_POST]: post,
            },
        });
    }, [dispatch, isMobileOrTabletPortrait]);
};
export const useOpenAlert = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((payload) => {
        dispatch({
            type: UIActionTypes.OPEN_ALERT,
            payload,
        });
    }, [
        dispatch,
    ]);
};
export const useOpenBanner = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((payload) => {
        dispatch({
            type: UIActionTypes.OPEN_BANNER,
            payload,
        });
    }, [
        dispatch,
    ]);
};
export const useOpenMenu = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((menuName) => {
        dispatch({
            type: UIActionTypes.OPEN_MENU,
            payload: {
                [EUIKey.MENU_NAME]: menuName,
            },
        });
    }, [
        dispatch,
    ]);
};
export const useOpenModal = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((payload) => {
        dispatch({
            type: UIActionTypes.OPEN_MODAL,
            payload,
        });
    }, [
        dispatch,
    ]);
};
export const useOpenTour = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback(() => {
        dispatch({
            type: UIActionTypes.OPEN_TOUR,
        });
    }, [
        dispatch,
    ]);
};
export const useSetPostEditorSelectedType = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((selectedType) => {
        dispatch({
            type: UIActionTypes.SET_POST_EDITOR_SELECTED_TYPE,
            payload: {
                [EUIKey.POST_EDITOR_SELECTED_TYPE]: selectedType,
            },
        });
    }, [
        dispatch,
    ]);
};
export const useSetSidePanelLabel = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((label) => {
        dispatch({
            type: UIActionTypes.SET_SIDE_PANEL_LABEL,
            payload: {
                [EUIKey.SIDE_PANEL_LABEL]: label,
            },
        });
    }, [
        dispatch,
    ]);
};
export const useToggleBackdrop = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((shouldOpen) => {
        dispatch({
            type: shouldOpen
                ? UIActionTypes.OPEN_BACKDROP
                : UIActionTypes.CLOSE_BACKDROP,
        });
    }, [
        dispatch,
    ]);
};
export const useTogglePostEditor = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((shouldOpen) => {
        dispatch({
            type: shouldOpen
                ? UIActionTypes.OPEN_POST_EDITOR
                : UIActionTypes.CLOSE_POST_EDITOR,
        });
    }, [
        dispatch,
    ]);
};
export const useToggleReloadTour = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((shouldReload = true) => {
        dispatch({
            type: UIActionTypes.SET_RELOAD_TOUR,
            payload: {
                [EUIKey.RELOAD_TOUR]: shouldReload,
            },
        });
    }, [
        dispatch,
    ]);
};
export const useToggleTypeSelector = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((shouldOpen) => {
        dispatch({
            type: UIActionTypes.SET_TYPE_SELECTOR_IS_OPEN,
            payload: {
                [EUIKey.TYPE_SELECTOR_IS_OPEN]: shouldOpen,
            },
        });
    }, [
        dispatch,
    ]);
};
export const useUpdateModalAttributes = () => {
    const { dispatch } = useMemoizedContext("ui");
    return useCallback((payload) => {
        dispatch({
            type: UIActionTypes.UPDATE_MODAL_ATTRIBUTES,
            payload,
        });
    }, [
        dispatch,
    ]);
};
