import { ColorGrey60 } from "@sermo/design-tokens";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import getTranslation from "@translation/translation";

const Wrap = styled.div`
	padding-top: 8px;
	color: ${ColorGrey60};
	font-size: 12px;
	font-family: Sailec;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;
`;

const AmountValidation = ({ min, withdrawalAmountMessage }) => {
	let validationMessage = min
		? getTranslation("system.validationMessages.validationAmountWholeNumGreaterOrEqual", false, min)
		: getTranslation("system.validationMessages.validationAmountMustBeAWholeNumber");

	if (withdrawalAmountMessage) {
		validationMessage = withdrawalAmountMessage;
	}

	return <Wrap>{validationMessage}</Wrap>;
};

AmountValidation.propTypes = {
	withdrawalAmountMessage: PropTypes.string,
	min: PropTypes.string,
};

export default AmountValidation;