import React, { useState, useEffect } from "react";
import { TextField } from "@components/FormFields/FormFields";
import { 
	ModalContainer, 
	ModalTitle, 
	ModalText, 
	ModalSection, 
	ModalFooter, 
} from "@components/Modal/Modals/ModalComponents";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const EnterName = ({ data, updateData }) => {
	const [password, setPassword] = useState("");
	const apiEndpoint = useApiEndpoint();
	const[loading, setLoading] = useState(false);
	const [validationError, setValidationError] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		// We need to invoke this endpoint as rights, etc. 
		// has changed, and the token returned by the last endpoint is invalid.
		// This endpoint will fetch a new one that will work for submitting the new password.
		apiEndpoint("system/verifyaccess");
	}, [])

	useEffect(() => {
		setCanSubmit(password.length > 0);
		setValidationError(false);
	}, [password])

	const submitPassword = (e) => {
		setLoading(true);

		const requestData = {
			password: password,
		};

		apiEndpoint("postsurveyreferralverification/setpassword", requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					updateData({
						...body,
					});
				}
				else {
					setLoading(false);

					if (body.message) {
						setValidationError(body.message);
					}

					if (body.fields !== null) {
						for (var property in body.fields) {
							switch (property) {
								case "password":
									setValidationError(body.fields[property].join("<br />"));
									break;
							}
						}
					}
				}
			});
		});
	};

	return (
		<ModalContainer>
			<ModalTitle>
				{getTranslation("frontend.modals.postSurveyReferrals.setPassword.title")}
			</ModalTitle>
			<ModalSection>
				<ModalText>
					{getTranslation("frontend.modals.postSurveyReferrals.setPassword.subtitle")}
				</ModalText>
			</ModalSection>
			<ModalSection>
				<TextField
					name="password"
					type="password"
					label={getTranslation("frontend.modals.postSurveyReferrals.setPassword.inputLabel", true)}
					handleChange={
						e => {
							setPassword(e.target.value)
						}
					}
					value={password}
					autoComplete="off"
					validationError={validationError}
				/>
			</ModalSection>
			<ModalFooter
				submitDisabled={!canSubmit}
				submitEventHandler={submitPassword}
				submitLoading={loading}
				submitText={getTranslation("frontend.modals.postSurveyReferrals.setPassword.cta", true)}
			/>
		</ModalContainer>
	);
};

export default EnterName;
