export const DraftStatusTypes = Object.freeze({
    COMPOSING: "Composing",
    EDITING: "Editing",
    PUBLISHED: "Published",
});
const DraftStatusTypesValues = Object.values(DraftStatusTypes);
export const CommentsSortTypes = Object.freeze({
    NEWEST: "Newest",
    OLDEST: "Oldest",
    TOP: "Top",
    UNREAD: "Unread",
});
const CommentsSortTypesValues = Object.values(CommentsSortTypes);
