import {
	ColorGrey60,
	ColorRed10,
	ColorRed60,
	ColorUltraviolet10,
	ColorUltraviolet40,
	ColorWhite
} from "@sermo/design-tokens";
import { doCenter, Icon, Tag } from "@sermo/ui-components";
import styled from "styled-components";
import {
	StyledMobileAndTabletPortraitQuery,
	StyledTabletLandscapeAndDesktopQuery
} from "@components/MediaQueries/MediaQueries";

export const StyledLiveContainer = styled.div`
	${doCenter(false, true)}
	color: ${ColorRed60};

	& div {
		margin-left: 8px;
	}

	${StyledTabletLandscapeAndDesktopQuery} {
		margin-bottom: 12px;
	}
`;

export const StyledToday = styled(Tag)`
	color: ${ColorRed60};
	background-color: ${ColorRed10};
`;

export const StyledLive = styled(Tag)`
	color: ${ColorWhite};
	background-color: ${ColorRed60};
`;

export const StyledOnDemand = styled(Tag)`
	margin-bottom: 12px;
	margin-left: 0;
	color: ${ColorUltraviolet40};
	background-color: ${ColorUltraviolet10};

	${StyledMobileAndTabletPortraitQuery} {
		margin: 0;
		background-color: transparent;
		border: 1px solid ${ColorUltraviolet40};
	}
`;

export const StyledCalendar = styled(Icon)`
	color: ${ColorGrey60};
	margin-right: 8px;
`;

export const StyledTime = styled.time`
	display: block;
	color: ${ColorGrey60};

	${StyledTabletLandscapeAndDesktopQuery} {
		margin-bottom: 4px;
	}
`;

export const StyledDateLine = styled.div`
	display: flex;
	flex-direction: row;

	${StyledTabletLandscapeAndDesktopQuery} {
		margin-bottom: 12px;
	}

	${StyledTime} {
		margin-bottom: 0;
		align-self: center;
	}
`;
