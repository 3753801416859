import PropTypes from "prop-types";
import React, { useState } from "react";
import { DropDownList, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalText, ModalFooter, ModalColumnWrapper, ModalColumn } from "./ModalComponents";

export const REQUEST_SPECIALTY_CHANGE_MODAL = "REQUEST_SPECIALTY_CHANGE_MODAL";

const RequestSpecialtyChangeModal = ({
	initialPrimarySpecialty,
	initialSecondarySpecialty,
	fileIsRequiredForSpecialtyChange,
	availableSpecialties,
	closeModalEventHandler,
}) => {
	const apiEndpoint = useApiEndpoint();
	const [loading, setLoading] = useState(false);

	const [primarySpecialty, setPrimarySpecialty] = useState(initialPrimarySpecialty);
	const [primarySpecialtyFile, setPrimarySpecialtyFile] = useState(null);
	const [primarySpecialtyChanged, setPrimarySpecialtyChanged] = useState(false);
	const [primarySpecialtyValidationError, setPrimarySpecialtyValidationError] = useState("");

	const [secondarySpecialty, setSecondarySpecialty] = useState(initialSecondarySpecialty);
	const [secondarySpecialtyFile, setSecondarySpecialtyFile] = useState(null);
	const [secondarySpecialtyChanged, setSecondarySpecialtyChanged] = useState(false);
	const [secondarySpecialtyValidationError, setSecondarySpecialtyValidationError] = useState("");

	const [globalValidationError, setGlobalValidationError] = useState("");

	const [requestSuccessfullySubmitted, setRequestSuccessfullySubmitted] = useState(false);

	const onPrimarySpecialtyChangedHandler = (event) => {
		setPrimarySpecialty(event.target.value);
		setPrimarySpecialtyChanged(event.target.value !== initialPrimarySpecialty);
	};

	const onSecondarySpecialtyChangedHandler = (event) => {
		setSecondarySpecialty(event.target.value);
		setSecondarySpecialtyChanged(event.target.value !== initialSecondarySpecialty);
	};

	const validateForm = () => {
		let isValid = true;

		setGlobalValidationError("");

		if (primarySpecialtyChanged) {
			if (primarySpecialty !== "") {
				if (fileIsRequiredForSpecialtyChange) {
					if (primarySpecialtyFile === null) {
						setPrimarySpecialtyValidationError(
							getTranslation("system.validationMessages.validationProvideProofOfMedicalSpecialty", true)
						);
						isValid = false;
					}
				}
			}
		}

		if (isValid) {
			setPrimarySpecialtyValidationError("");
		}

		if (secondarySpecialtyChanged) {
			if (secondarySpecialty !== "") {
				if (fileIsRequiredForSpecialtyChange) {
					if (secondarySpecialtyFile === null) {
						setSecondarySpecialtyValidationError(
							getTranslation("system.validationMessages.validationProvideProofOfMedicalSpecialty", true)
						);
						isValid = false;
					}
				}
			}
		}

		if (isValid) {
			setSecondarySpecialtyValidationError("");
		}

		return isValid;
	};

	const submitFormClickHandler = () => {
		if (validateForm()) {
			if (primarySpecialtyChanged || secondarySpecialtyChanged) {
				setLoading(true);

				const data = new FormData();

				if (primarySpecialtyChanged) {
					if ("none" === primarySpecialty) {
						data.append("requestPrimarySpecialtyRemoved", true);
					} else {
						data.append("newPrimarySpecialtyId", primarySpecialty);

						if (fileIsRequiredForSpecialtyChange) {
							data.append(
								"newPrimarySpecialtyVerificationFile",
								primarySpecialtyFile,
								primarySpecialtyFile.name
							);
						}
					}
				}

				if (secondarySpecialtyChanged) {
					if ("none" === secondarySpecialty) {
						data.append("requestSecondarySpecialtyRemoved", true);
					} else {
						data.append("newSecondarySpecialtyId", secondarySpecialty);

						if (fileIsRequiredForSpecialtyChange) {
							data.append(
								"newSecondarySpecialtyVerificationFile",
								secondarySpecialtyFile,
								secondarySpecialtyFile.name
							);
						}
					}
				}

				apiEndpoint("settings/requestspecialtychange", data, true).then((dataResponse) => {
					dataResponse.json().then((body) => {
						setLoading(false);

						if (dataResponse.ok) {
							setRequestSuccessfullySubmitted(true);
						} else {
							setGlobalValidationError(body.message);

							if (body.fields !== null) {
								for (let property in body.fields) {
									switch (property) {
										case "requestPrimarySpecialtyRemoved":
										case "newPrimarySpecialtyId":
										case "newPrimarySpecialtyVerificationFile":
											setPrimarySpecialtyValidationError(body.fields[property].join("<br />"));
											break;
										case "requestSecondarySpecialtyRemoved":
										case "newSecondarySpecialtyId":
										case "newSecondarySpecialtyVerificationFile":
											setSecondarySpecialtyValidationError(body.fields[property].join("<br />"));
											break;
									}
								}
							}
						}
					});
				});
			}
		}
	};

	const closeModalClickHandler = () => {
		if (closeModalEventHandler) {
			closeModalEventHandler(!requestSuccessfullySubmitted);
		}
	};

	return (
		<ModalContainer dataComponent={"ChangeSpecialtiesModal"}>
			{
				requestSuccessfullySubmitted && (
					<>
						<ModalText>
							{getTranslation("frontend.modals.requestSpecialtyChange.confirmationDescription")}
						</ModalText>

						<ModalFooter
							{...{
								submitEventHandler: closeModalClickHandler,
								submitText: getTranslation("frontend.generics.OK", true),
							}}
						/>
					</>
				)
			}
			{
				!requestSuccessfullySubmitted && (
					<>
						<ModalColumnWrapper>
							<ModalColumn>
								<DropDownList
									name="primarySpecialtyId"
									label={
										getTranslation(
											"frontend.modals.requestSpecialtyChange.inputFieldLabelPrimarySpecialty",
											true
										)
									}
									autoComplete="medical-specialty"
									validationError={primarySpecialtyValidationError}
									handleChange={onPrimarySpecialtyChangedHandler}
									options={availableSpecialties}
									value={primarySpecialty}
									disabled={loading}
								/>
							</ModalColumn>

							<ModalColumn>
								<DropDownList
									name="secondarySpecialtyId"
									label={
										getTranslation(
											"frontend.modals.requestSpecialtyChange.inputFieldLabelSecondarySpecialty",
											true
										)
									}
									autoComplete="medical-specialty"
									validationError={secondarySpecialtyValidationError}
									handleChange={onSecondarySpecialtyChangedHandler}
									options={availableSpecialties}
									value={secondarySpecialty}
									disabled={loading}
								/>
							</ModalColumn>
						</ModalColumnWrapper>

						<GlobalValidationMessage text={globalValidationError} />

						<ModalFooter
							{...{
								cancelModalEventHandler: closeModalClickHandler,
								submitEventHandler: submitFormClickHandler,
								submitDisabled: loading || !(primarySpecialtyChanged || secondarySpecialtyChanged),
							}}
						/>
					</>
				)
			}
		</ModalContainer>
	);
};

RequestSpecialtyChangeModal.propTypes = {
	initialPrimarySpecialty: PropTypes.string,
	initialSecondarySpecialty: PropTypes.string,
	fileIsRequiredForSpecialtyChange: PropTypes.bool,
	availableSpecialties: PropTypes.array,
	closeModalEventHandler: PropTypes.func,
};

RequestSpecialtyChangeModal.defaultProps = {
	initialPrimarySpecialty: "",
	initialSecondarySpecialty: "",
	fileIsRequiredForSpecialtyChange: true,
	closeModalEventHandler: () => {},
};

export default RequestSpecialtyChangeModal;
