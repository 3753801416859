import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./RatingModal.scss";

const RatingModal = ({ url }) => {
	return (
		<div styleName={classNames("styles.rating-modal")}>
			<iframe
				width="100%"
				height="100%"
				src={url}
			/>
		</div>
	)
}

RatingModal.propTypes = {
	url: PropTypes.string,
};

export default RatingModal;
