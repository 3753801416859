import React from "react";
import Button from "@components/Button/Button";
import Menu from "@components/Menu/Menu";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const SurveysMenu = () => {
	const options = [];

	const { hasAccess } = useMemoizedContext("member",["hasAccess"]);

	if (hasAccess("canAccessFrontendSurveys")) {
		options.push({
			label: getTranslation("frontend.surveys.navigation.availableSurveys"),
			to: "/surveys/availablesurveys",
			exact: true,
			tracking: { category: "surveys-left-rail", action: "click", label: "available-surveys" },
			rightsRequired: ["canAccessFrontendSurveys"],
		});
	}
	if (hasAccess("canAccessFrontendSurveys")) {
		options.push({
			label: getTranslation("frontend.surveys.navigation.surveyHistory"),
			to: "/surveys/surveyhistory",
			exact: true,
			tracking: { category: "surveys-left-rail", action: "click", label: "survey-history" },
			rightsRequired: ["canAccessFrontendSurveys"],
		});
	}
	if (hasAccess("canAccessFrontendSurveys")) {
		options.push({
			label: getTranslation("frontend.surveys.navigation.withdrawalHistory"),
			to: "/surveys/withdrawalhistory",
			exact: true,
			tracking: { category: "surveys-left-rail", action: "click", label: "withdrawal-history" },
			rightsRequired: ["canAccessFrontendSurveys"],
		});
	}

	return (
		<Menu>
			{
				options.map((option, i) => (
					<Button
						key={i}
						{...option}
						contextClass="left-rail"
						alignment="start"
						theme="light"
						style="flatNeutral"
						size="small"
					>
						{option.label}
					</Button>
				))
			}
		</Menu>
	);
}

export default SurveysMenu;
