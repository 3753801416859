import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const Banner = styled.div `
	display: flex;
	align-items: center;
	flex-grow: 1;
`;
export const BannerTopic = styled.div `
	font-family: "Graphik", Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: 500;
	line-height: 14px;
	color: ${(props) => props.theme.color.text05};
`;
export const BannerTitle = styled.div `
	margin-left: 16px;
	font-family: "Graphik", Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: ${(props) => props.theme.color.text05};

	a {
		color: ${(props) => props.theme.color.text05};
		text-decoration: underline;
	}

	p {
		margin: 0;
	}
`;
export const MobileNavContainer = styled.div ``;
export const CardExpanded = styled.div `
	width: 100%;
	display: flex;
	justify-content: stretch;
	--button-width: 40px;
`;
export const ButtonContainer = styled.div `
	width: var(--button-width);
	height: 100%;
	display: flex;
	align-items: center;
`;
export const TitleAndTopic = styled.div `
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;
export const Topic = styled(TextStyled) `
	color: ${(props) => props.theme.color.text04};
`;
export const Title = styled(TextStyled) `
	max-width: 75vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${(props) => props.theme.color.text01};
	${({ $isAd }) => $isAd && "white-space: normal; padding: 8px 0;"}

	p {
		margin: 0;
		padding: 0;
		white-space: inherit;
		overflow: inherit;
		text-overflow: inherit;
	}

	strong {
		white-space: inherit;
		overflow: inherit;
		text-overflow: inherit;
	}
`;
