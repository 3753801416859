import { ColorGrey10, ColorGrey60, ColorUltraviolet50, ColorWhite } from "@sermo/design-tokens";
import { Icon } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const ProfileContainer = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: ${ColorWhite};
	margin-bottom: 8px;
	> div {
		width: 100%;
	}
	> div:not(:first-child) {
		border-top: 1px solid ${ColorGrey10};
	}

	${StyledTabletLandscapeAndDesktopQuery} {
		border-radius: 8px;
		border: 1px solid ${ColorGrey10};
	}

	${StyledMobileAndTabletPortraitQuery} {
		border-radius: 0;
		border-bottom: 1px solid ${ColorGrey10};
	}
`;
export const InfoContainer = styled.div `
	display: flex;
	padding: 16px 16px 8px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;

	.picture-container {
		position: relative;
		> div {
			border: none;
		}
	}

	.camera-icon {
		cursor: pointer;
		position: absolute;
		right: 2px;
		bottom: 2px;
		z-index: 0;
		line-height: 0;
		&:before {
			content: "";
			position: absolute;
			border-radius: 50%;
			background-color: ${ColorWhite};
			width: 20px;
			height: 20px;
			left: -2px;
			top: -2px;
			z-index: -1;
		}
		&>svg {
			height: 16px;
			width: 16px;
		}
	}
`;
export const SubItem = styled.div `
	display: flex;
	padding: 8px 12px 8px 16px;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	${TextStyled} {
		color: ${ColorGrey60};
	}
	&&:hover ${TextStyled}, &&:hover svg path {
		color: ${ColorUltraviolet50};
		fill: ${ColorUltraviolet50};
	}

	${StyledMobileAndTabletPortraitQuery} {
		padding: 16px;
	}
`;
export const IconHint = styled(Icon) `
	path {
		fill: ${ColorGrey60};
	}
`;
export const Onboarding = styled.div `
	display: flex;
	padding: 8px 12px 16px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	cursor: pointer;
	${TextStyled} {
		color: ${ColorGrey60};
	}
`;
export const ProfileInfo = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
`;
export const ProfileIconStyled = styled(Icon) `
	display: inline-block;
	margin-left: 2px;
	position: relative;
	width: 14px;
`;
export const GearIconContainer = styled.div `
	margin-left: 2px;
	cursor: pointer;
`;
export const GearIconStyled = styled(Icon) `
	display: block;
	position: relative;
	&:hover {
		color: ${ColorUltraviolet50};
	}
`;
export const Specialty = styled.div `
	> div > div {
		display: flex;
		align-items: flex-start;
		gap: 4px;
		flex-direction: column;
		div, a {
			color: ${ColorGrey60};
		}
	}
	> a {
		margin-top: 4px;
	}
`;
export const MainSpecialty = styled.div `
	display: flex;
	align-items: center;

	& > div {
		display: inline;
	}

	a {
		vertical-align: middle;

		&:hover {
			color: ${ColorUltraviolet50};
		}
	}
`;
export const FullName = styled(TextStyled).attrs({ "data-component": "DisplayName" }) `
	display: flex;
	align-items: center;
`;
export const AddLink = styled(TextStyled).attrs({ as: "a", "data-component": "AddAnother" }) `
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
	color: ${ColorGrey60};
`;
export const Bar = styled.div `
	width: 100%;
	height: 16px;
	border-radius: 8px;
	background-color: ${ColorGrey10};
`;
export const Progress = styled.div `
	width: ${({ $progress }) => `max(calc(100% * ${$progress}), 16%)`};
	height: 16px;
	padding: 0 8px;
	border-radius: 8px;
	background-color: ${ColorUltraviolet50};
	display: flex;
	align-items: center;
`;
export const ProgressText = styled(TextStyled).attrs({
    forwardedAs: "span",
    $kind: "tag01",
}) `
	&& {
		color: ${ColorWhite};
	}
`;
