import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import ScrollWrapper from "@components/ScrollWrapper/ScrollWrapper";
import { usePrevious } from "@hooks/Hooks";

/**
 * Component that takes whatever component is given to it and a number of pages
 * and renders the component once per page
 * @param {function} children - react component to be used as a page
 * @param {object} props - any props that need to be passed to the child component when it's rendered as a page
 * @param {number} page - the current highest page number to render
 * @param {function} pageLoaded - a call back function that lets the scroll logic container know its safe to check if another page should be loaded
 */
const Paginator = ({ children, props, page, pageLoaded }) => {
	const [pages, setPages] = useState([]);
	const propsPrev = usePrevious(props);

	const renderPage = () => React.cloneElement(children, { key: page,
		page: page,
		onLoadHandler: pageLoaded,
		...props });

	const prevPage = usePrevious(page);

	useEffect(() => {
		setPages([...pages, renderPage(page)]);
	}, []);

	useEffect(() => {
		if (page > prevPage) {
			setPages([...pages, renderPage(page)]);
		} else {
			setPages([renderPage(page)]);
		}
	}, [page, !isEqual(props,propsPrev)]);

	return <>{pages}</>;
};

Paginator.propTypes = {
	children: PropTypes.node,
	props: PropTypes.object,
	page: PropTypes.number,
	pageLoaded: PropTypes.func,
};

/**
 * Component that determines if a new page should be rendered based on scroll
 * @param {int} scrollY - the current scrollY of the window 
 * @param {object} props - any props that need to be passed to the child component when it's rendered as a page
 * @param {func} children - a react component to be rendred as a page
 */

const ScrollLogic = ({ scrollY, props, children }) => {
	const [page, setPage] = useState(0);
	const [canAddPage, setCanAddPage] = useState(false);

	const propsPrev = usePrevious(props);

	useEffect(() => {
		setPage(0);
	}, [!isEqual(props, propsPrev)]);

	const pageLoadedFunction = (count) => {
		if (count > 0) {
			setCanAddPage(true);
		}
	};

	useEffect(() => {
		if ("undefined" !== typeof window) {
			const scrollHeight = window.document.documentElement.scrollHeight;
			if (
				scrollHeight !== window.innerHeight
				&& scrollHeight - window.innerHeight - scrollY < window.innerHeight * 0.66
			) {
				if (canAddPage) {
					setPage(page + 1);
					setCanAddPage(false);
				}
			}
		}
	}, [scrollY]);

	return useMemo(() => (
		<Paginator
			props={props}
			page={page}
			pageLoaded={pageLoadedFunction}
		>
			{children}
		</Paginator>
	), [page, !isEqual(props, propsPrev)]);
};

ScrollLogic.propTypes = {
	children: PropTypes.node,
	props: PropTypes.object,
	scrollY: PropTypes.number,
};

/**
 * Component that renders paginated components based on the window scroll
 * @param {func} children - a react component to be rendred as a page
 * @param {object} props - any props that need to be passed to the child component when it's rendered as a page
 */
const Pagination = ({ children, props }) => useMemo(() => (
	<ScrollWrapper limit={1000 / 15}>
		<ScrollLogic props={props}>{children}</ScrollLogic>
	</ScrollWrapper>
), [children, props]);

Pagination.propTypes = {
	children: PropTypes.node,
	props: PropTypes.object,
};

export default Pagination;
