import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorder, applyZIndex } from "@frontend/styled";
export const Panel = styled.div `
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: auto;
	display: flex;
	flex-direction: column;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	${applyZIndex("over-the-top")};
	background-color: ${({ theme }) => theme.color.uiBackground};
	${({ $side }) => $side === "right" && `left: auto; right: 0; transform: translateX(100%);`};
	${({ $open }) => $open && `transform: translateX(0);`};

	${StyledMobileAndTabletPortraitQuery} {
		width: 300px;
	}
`;
export const Header = styled.div `
	min-height: 50px;
	${applyBorder("bottom")};
	display: flex;
	justify-content: flex-start;
	padding: 0 8px;
	${({ $side }) => $side === "right" && `justify-content: flex-end;`};
`;
export const Body = styled.div `
	flex: 1;
	overflow-x: hidden;
	overflow-y: scroll;
`;
export const Footer = styled.div `
	${applyBorder("top")};
	width: 100%;
`;
