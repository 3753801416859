import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalSection = ({ children, newSection, subSection }) => <div styleName={classNames("styles.modal-section", { "styles.new-section": newSection, "styles.sub-section" : subSection })}>{children}</div>;

ModalSection.propTypes = {
	children: PropTypes.node,
	newSection: PropTypes.bool,
};

ModalSection.defaultProps = {
	newSection: false,
};

export default ModalSection;
