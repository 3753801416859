import classNames from "classnames";
import React from "react";
import Button from "@components/Button/Button";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useMemoizedContext, useSearchParams } from "@hooks/Hooks";

import typography from "../../scss/typography.scss";
import styles from "./Search.scss";

const SearchNav = () => {
	const { hasAccess } = useMemoizedContext("member");
	const { totalCount } = useMemoizedContext("search", ["totalCount"]);
	const searchParams = useSearchParams();
	const query = searchParams.get();

	return (
		<div styleName="styles.search-nav">
			<div styleName={classNames("styles.nav-wrapper", "typography.body-short-brand-00")}>
				<TabletLandscapeAndDesktop>
					{
						query && query !== "" && (
							<div styleName="styles.query-and-number">
								{`Showing ${totalCount} results for `}
								<strong>{query}</strong>
							</div>
						)
					}
				</TabletLandscapeAndDesktop>
				<Button
					activeUnderline={true}
					theme="light"
					style="tab"
					size="large"
					to={`/search/all${searchParams.stringify()}`}
					tracking={
						{
							category: "search-results",
							action: "click",
							label: "all-results",
						}
					}
					contextClass="search-nav"
				>
					All
				</Button>
				<Button
					activeUnderline={true}
					theme="light"
					style="tab"
					size="large"
					to={`/search/posts${searchParams.stringify()}`}
					tracking={
						{
							category: "search-results",
							action: "click",
							label: "all-posts",
						}
					}
					contextClass="search-nav"
				>
					Posts
				</Button>
				<Button
					activeUnderline={true}
					theme="light"
					style="tab"
					size="large"
					to={`/search/people${searchParams.stringify()}`}
					tracking={
						{
							category: "search-results",
							action: "click",
							label: "all-people",
						}
					}
					contextClass="search-nav"
				>
					People
				</Button>
				{
					hasAccess("canAccessFrontendDrugRatings")
					&& (
						<Button
							activeUnderline={true}
							theme="light"
							style="tab"
							size="large"
							to={`/search/rate${searchParams.stringify()}`}
							tracking={
								{
									category: "search-results",
									action: "click",
									label: "all-ratings",
								}
							}
							contextClass="search-nav"
						>
							Ratings
						</Button>
					)
				}
			</div>
		</div>
	);
};

export default SearchNav;
