import { ColorGrey80, ColorGrey100, } from "@sermo/design-tokens";
import styled from "styled-components";

export const Name = styled.div`
	& > div:first-child {
		margin-bottom: 4px;
	}
	& > div:last-child {
		margin-top: 8px;
	}
`;

export const TypeDescription = styled.div`
	color: ${ColorGrey80};
	font-size: 13px;
	font-family: Graphik;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 18px;
`;

export const TypeTitle = styled.div`
	font-weight: 700;
`;

export const WrapImage = styled.div`
	max-width: 22px;
	height: 22px;
	display: inline-block;
	margin-right: 8px;
`;

export const WrapImageAndTitle = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapLogistics = styled.div`
	color: ${ColorGrey100};
	font-size: 13px;
	font-family: Sailec;
	font-weight: 500;
	letter-spacing: 0px;
	line-height: 18px;

	& > div > span {
		padding-right: 24px;
		display: inline-block;
	}
`;