import React from "react";
import styled, { keyframes } from "styled-components";

const circleSize = "8px";
const circleCount = 3;
const circleGap = "8px";
const animationDuration = "500ms";

const slideDown = keyframes`
	0% {
		transform: translateY(0%);
		opacity: 1;
	}

	100% {
		transform: translateY(100%);
		opacity: 0.5;
	}
`;

const slideUp = keyframes`
	0% {
		transform: translateY(100%);
		opacity: 0.5;
	}

	100% {
		transform: translateY(0%);
		opacity: 1;
	}
`;

export const Dot = styled.div`
		display: inline-block;
		box-sizing: border-box;
		width: ${circleSize};
		height: ${circleSize};
		margin: 0 calc(${circleGap} / 2);
		border-radius: 50%;
		background-color: #888;
		animation-duration: ${animationDuration};
		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-timing-function: ease-in-out;
		margin-bottom: ${circleSize};
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:nth-child(odd) {
		animation-name: ${slideDown};
	}

	&:nth-child(even) {
		animation-name: ${slideUp};
	}
`;

export const LoadingContainer = styled.div`
		display: inline-flex;
		justify-content: center;
		height: calc(${circleSize} * 2);
		width: calc(${circleSize} * ${circleCount} + ${circleGap} * (${circleCount} - 1));
`;
