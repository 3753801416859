import React from "react";
import * as Styles from "@components/Messaging/Messaging.styles";
import { useMemoizedContext } from "@hooks/Hooks";
import { DMUnauthenticatedLayout } from "./AdPreview.styles";
import AdPreviewDirectMessage from "./AdPreviewDirectMessage";
import SignIn from "./AdPreviewSignIn";

const AdPreviewDirectMessageLayout = () => {
	const {
		headerHeight,
	} = useMemoizedContext("ui", [
		"headerHeight",
	]);
	const {
		hasAccessToAdPreview,
		loggedIn,
	} = useMemoizedContext("member", [
		"loggedIn",
		"hasAccessToAdPreview",
	]);

	if ( !hasAccessToAdPreview ) {
		return (
			<DMUnauthenticatedLayout $loggedIn={loggedIn}>
				<SignIn/>
			</DMUnauthenticatedLayout>
		)
	}

	return (
		<Styles.MessagingContainer>
			<Styles.MessagingContent
				$top={headerHeight}
			>
				<AdPreviewDirectMessage/>
			</Styles.MessagingContent>
		</Styles.MessagingContainer>
	);
}

export default AdPreviewDirectMessageLayout;
