import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import InlineVideoPLayer from "@components/Card/components/VideoPlayer/InlineVideoPlayer";
import { ContentContainer, contentContainerClickHandler, maxLength, sanitizeLinks, useContentAreaClickEventHandler, useInlineVideos, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./ResourceContentInFeed.styles";
const ResourceContentInFeed = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const inlineVideos = useInlineVideos();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { contentHtml, id, introduction, originalContentHtml, } = useMemoizedContext("postData", [
        "contentHtml",
        "id",
        "introduction",
        "originalContentHtml",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : contentHtml;
        if (introduction) {
            return introduction;
        }
        // Check for max length of string, and shorten accordingly
        if (editorContent.length > maxLength) {
            // The initial trimmed string
            let trimmedString = editorContent.substring(0, maxLength);
            // Trim again to avoid breaking a word and add ...
            trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            return `${trimmedString}...<span id=read-more-portal-${id}></span>`;
        }
        return sanitizeLinks(editorContent);
    }, [
        contentHtml,
        id,
        introduction,
        originalContentHtml,
        showOriginalContent,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				{inlineVideos?.map((iv, i) => (<InlineVideoPLayer el={iv} key={i}/>))}
				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}
				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={"bodyLong02"} $legacy={true} onClick={contentAreaClickEventHandler}/>
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentToDisplay,
        id,
        inlineVideos,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
    ]);
};
export default ResourceContentInFeed;
