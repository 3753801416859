import { TabButton, TabList, Tabs } from "@sermo/ui-components";
import isMobileJS from "ismobilejs";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { activityFeedDefaultProps, activityFeedPropTypes } from "@components/Activity/components/ActivityFeed";
import { useApiEndpoint, useFetch, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./ActivityTabs.styles";

const ActivityTabsPropTypes = {
	...activityFeedPropTypes,
};

const ActivityTabsDefaultProps = {
	...activityFeedDefaultProps,
};

const FollowedPostsTab = ({ setFilterBy, filterBy }) => {
	const apiEndpoint = useApiEndpoint();
	const [data, loading] = useFetch("api/activity/getactivityfeed", {
		page: 0,
		filterBy: "followedPosts",
		refetch: filterBy, // hack to make this recheck for new posts every time the tabs are switched
	});

	const [hasNew, setHasNew] = useState(false);
	const { updateMember } = useMemoizedContext("member");

	const markAllAsSeen = () => {
		if ("followedPosts" === filterBy) {
			if (hasNew) {
				apiEndpoint("activity/marknotificationsseen", { filterBy }).then(() => {
					// setting variable to true so the NavMenu can use it to trigger a refresh
					window.sermo.user.shouldRefreshMemberNotifications = true;
					updateMember({
						shouldRefreshMemberNotifications: true,
					});
					setHasNew(false);
				});
			}
		}
	};

	useEffect(() => {
		if (!loading) {
			setHasNew(
				data.notifications.some((notification) => !notification.seen)
			);
			// only mark all seen on followed post tab
			markAllAsSeen();
		}
	}, [loading]);

	return (
		<TabButton
			value={"followedPosts"}
		>
			<Styles.DotContainer>
				{getTranslation("frontend.navMenu.followedPosts")}
				{hasNew && <Styles.Dot/>}
			</Styles.DotContainer>
		</TabButton>
	);
};

FollowedPostsTab.propTypes = { ...ActivityTabsPropTypes };
FollowedPostsTab.defaultProps = { ...ActivityTabsDefaultProps };

const ActivityTabs = ({ filterBy, setFilterBy }) => {
	const isMobile = isMobileJS(navigator.userAgent).any;
	const navigate = useNavigate();
	const apiEndpoint = useApiEndpoint();
	const isFullPage = !!useMatch("/activity/*");
	const { hasAccess, updateMember } = useMemoizedContext("member");

	const markAllAsRead = (filterBy = "followedPosts") => {
		apiEndpoint("activity/marknotificationsread", { filterBy }).then(() => {
			// setting variable to true so the NavMenu can use it to trigger a refresh
			window.sermo.user.shouldRefreshMemberNotifications = true;
			updateMember({
				shouldRefreshMemberNotifications: true,
			});
		});
	};

	const select = (e, value) => {
		e.stopPropagation();
		if (value === "followedPosts") {
			if (!isMobile && setFilterBy) {
				setFilterBy("followedPosts");
			} else {
				navigate("/activity/followedPosts");
			}
		} else {
			if (!isMobile && setFilterBy) {
				setFilterBy("general");
			} else {
				navigate("/activity/general");
			}
		}
	}

	useEffect(() => {
		if ( "followedPosts" === filterBy ) {
			markAllAsRead();
		} else if (
			"general" === filterBy
			&& isMobile
		) {
			markAllAsRead("general");
		}
	}, [filterBy]);

	if (!hasAccess("canFollowItemsInFrontend")) {
		return null;
	}

	return (
		<Styles.TabsContainer $isFullPage={isFullPage}>
			<Tabs
				onChange={select}
				align={"start"}
				defaultValue={"general"}
			>
				<TabList>
					<TabButton value={"general"}>
						{getTranslation("frontend.navMenu.general")}
					</TabButton>
					<FollowedPostsTab
						filterBy={filterBy}
						setFilterBy={setFilterBy}
					/>
				</TabList>
			</Tabs>
		</Styles.TabsContainer>
	);
};

ActivityTabs.propTypes = ActivityTabsPropTypes;
ActivityTabs.defaultProps = ActivityTabsDefaultProps;

export default ActivityTabs;
