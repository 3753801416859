import { ColorGrey00, ColorUltraviolet50 } from "@sermo/design-tokens";
import { onMobileOrTabletPortrait } from "@sermo/ui-components";
import styled from "styled-components";
import ImageMembership from "./assets/image-membership.png";
import ImageMNOW from "./assets/image-MNOW.png";

export const ModalStyled = styled.div`
	display: flex;
	flex-direction: column;

	header {
		height: 198px;
		background-image: url(${props => props.$app === "mnow"
		? ImageMNOW
		: ImageMembership});
		background-size: cover;
	}

	h3 {
		margin: 16px 0;
		font-weight: bold;
	}

	p b {
		font-weight: bold;
	}

	.okButton {
		margin-top: 32px;
	}

	.cancelButton {
		margin: 16px 0;
	}

	.snooze {
		margin-top: 16px;
	}

	${onMobileOrTabletPortrait(`
		header {
			height: 152px;
		}
	`)}
`;

export const Snooze = styled.div`
	display: flex;
	flex-direction: column;
	background: ${ColorGrey00};
	border-radius: 4px;
	padding: 10px 24px 24px 24px;
	>button {
		margin-top: 10px;
	}
	>div {
		display: flex;
		flex-direction: row;
		margin: 0 auto;
		align-items: center;
		>span {
			margin-right: 8px;
		}
	}
	>div span > button { // InlineDropdown button.
		font-weight: 500;
		color: ${ColorUltraviolet50};
		white-space: nowrap;
	}
`;
