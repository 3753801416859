import { PostUIEditingActionTypes } from "@contexts/PostUI/Editing/PostUIEditingReducer";
import { useMemoizedContext } from "@hooks/Hooks";
const useSaving = () => {
    const { dispatch: dispatchUIEditing, } = useMemoizedContext("postUIEditing");
    // TODO: perhaps use a name other than endpoint  because we aren't saving endpoints but nicknames instead
    const start = (endpoint) => {
        dispatchUIEditing({
            type: PostUIEditingActionTypes.START_SAVING,
            payload: {
                endpoint,
            },
        });
    };
    const stop = (endpoint) => {
        dispatchUIEditing({
            type: PostUIEditingActionTypes.STOP_SAVING,
            payload: {
                endpoint,
            },
        });
    };
    return {
        start,
        stop,
    };
};
export default useSaving;
