import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { applyBorder } from "@frontend/styled";
export const Container = styled.div `
	background-color: ${({ theme }) => theme.color.ui01};
	${({ $bottomBorder }) => $bottomBorder && applyBorder("bottom")}
`;
export const Clickable = styled.div `
	width: 100%;
	min-height: 36px;
	padding: 8px 12px 8px 16px;
	display: flex;
	align-items: center;
	gap: 16px;
	cursor: pointer;
	& > svg {
		color: ${({ theme }) => theme.color.icon03};
	}
`;
export const Text = styled(TextStyled) `
	flex: 1 0 0;
	color: ${({ theme }) => theme.color.text02};
`;
export const CollapseContent = styled.div `
	padding: 0 16px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export const Item = styled.div `
	padding: 12px;
	border: 1px solid ${({ theme }) => theme.color.ui03};
	border-radius: 8px;
`;
