import React from "react";
import { useNavigate } from "react-router-dom";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

export const snoozeOptions = [
	{
		label: getTranslation("frontend.unsubscribe.notifications.snoozeOptions", true, "7"),
		value: "7",
	},
	{
		label: getTranslation("frontend.unsubscribe.notifications.snoozeOptions", true, "30"),
		value: "30",
	},
	{
		label: getTranslation("frontend.unsubscribe.notifications.snoozeOptions", true, "90"),
		value: "90",
	},
];

export const ACTION_IN = "OptIn";
export const ACTION_OUT = "OptOut";

export const intervalOptions = [
	{
		label: getTranslation("system.dateTime.daily", true),
		value: "Daily",
	},
	{
		label: getTranslation("system.dateTime.weekly", true),
		value: "Weekly",
	},
	{
		label: getTranslation("system.dateTime.monthly", true),
		value: "Monthly",
	},
];

export const convertActionToBool = (option) => option?.action === "OptIn";

export const deepClone = (arr) => JSON.parse(JSON.stringify(arr));

export const getLongDate = (date) => {
	const months = ["january", "february", "march", "april", "may", "june", "july",
		"august", "september", "october", "november", "december"];

	const month = getTranslation(`system.dateTime.${months[date.getMonth()]}`, true);

	return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

export const useUnsubscribeModalCallback = (callback) => {
	const navigate = useNavigate();

	return (data, email) => {
		if (data && email) {
			callback && callback();
			navigate(Object.values(data).flat().length
				? `/unsubscribe-survey?email=${email}`
				: "/", { state: { ...data } });
		}
	};
};

const getNumSnoozeDays = (snoozedUntil) => {
	const now = new Date();
	const unsnoozeDate = new Date(snoozedUntil);
	return Math.ceil((unsnoozeDate - now) / (1000 * 60 * 60 * 24));
}

export const getUnsubscribeEmailType = (type) => {
	let emailType;

	switch (type) {
		case "NewsFeaturedContentMemberBenefits":
			emailType = "content";
			break;
		case "CommunityDigest":
			emailType = "digest";
			break;
		case "Events":
			emailType = "events";
			break;
		case "Promotions":
			emailType = "promo";
			break;
		case "NewSurveyAvailable":
			emailType = "surveys";
			break;
		case "NewInboxMessage":
			emailType = "messages";
			break;
		case "ActivityOnPostsAuthored":
			emailType = "myposts";
			break;
		case "ActivityOnPostsFollowed":
			emailType = "postsfollowing";
			break;
		case "PatientCaseCreatedInSameSpecialty":
			emailType = "patientcases";
			break;
		case "GloballyOptOut":
			emailType = "globaloptout";
			break;
		case "PushNotifications":
			emailType = "mobile";
			break;
		default:
			emailType = "other";
	}

	return emailType;
}

// use this forEach event that is changed in the unsubscribe flow
export const useTrackUnsubscribeEvent = () => {
	const trackEvent = useTrackEvent();
	const { locale } = useMemoizedContext("member", ["locale"]);

	return (unsubEvent, email) => {
		const emailType = getUnsubscribeEmailType(unsubEvent.type);
		let label = emailType;
		let action = unsubEvent.action.toLowerCase();

		let cadence = null;
		if (
			unsubEvent.interval
			&& unsubEvent.interval !== "None"
			&& convertActionToBool(unsubEvent)
		) {
			label += `-interval-${unsubEvent.interval.toLowerCase()}`;
			cadence = unsubEvent.interval.toLowerCase();
		}

		let snoozeDays = null;
		if (unsubEvent.snoozedUntil) {
			snoozeDays = getNumSnoozeDays(unsubEvent.snoozedUntil);
			label += `-snooze-${snoozeDays}days`;
			action = "click";
		}

		if (unsubEvent.type === "GloballyOptOut") {
			label += convertActionToBool(unsubEvent)
				? "-enable"
				: "-disable";
			action = "click";
		}

		trackEvent({
			category: window.location.pathname === "/unsubscribe"
				? "unsubscribepageemail"
				: "settingspageemail",
			label,
			action,
			emailType,
			cadence,
			days: snoozeDays,
			email,
			locale,
		});
	}
}