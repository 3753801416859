import styled, { css } from "styled-components";
import { applyZIndex } from "@frontend/styled";
export const NewPostContainer = styled.div `
	margin-bottom: ${({ theme }) => theme.layout.cardsSpacing};
	display: none;
	position: relative;

	${({ $focused }) => $focused && css `
		display: block;
		${applyZIndex("modal")};
	`};
`;
export const ProfilePicContainer = styled.div `
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
`;
