import { Button, Icon, ChevronRightSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import { ONBOARDING_MODAL, OnboardingModal } from "@components/Onboarding/OnboardingModal";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { OnboardingBar, Progress, ProgressText } from "./OnboardingModal.styles";
import {
	ContentWrap,
	OnboardingImage,
	OnboardingModuleWrap,
} from "./OnboardingModule.styles";

export const OnboardingModule = () => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const { tasks } = useMemoizedContext("onboarding", ["tasks"]);

	const completedTasks = tasks?.filter((task) => task.completed);
	const completed = completedTasks?.length;
	const total = tasks?.length;

	if (completed === total) {
		return null;
	}

	const handleCloseClick = () => {
		trackEvent({
			category: "onboarding",
			label: "modal-close",
			action: "click",
		});
		closeModal(ONBOARDING_MODAL);
	};

	const handleOpenClick = () => {
		trackEvent({
			category: "onboarding",
			label: "profile-module-continue",
			action: "click",
		});
		openModal({
			[EUIKey.MODAL_LABEL]: ONBOARDING_MODAL,
			[EUIKey.MODAL_CLOSE_METHOD]: handleCloseClick,
			[EUIKey.MODAL_COMPONENT]: <OnboardingModal/>,
			[EUIKey.MODAL_SUBTYPE]: "onboarding",
		});
	};

	return (
		<>
			<TabletLandscapeAndDesktop>
				<OnboardingModuleWrap>
					<OnboardingImage />
					<ContentWrap>
						<TextStyled
							$kind="bodyShort03"
							as="h4"
						>
							{getTranslation("frontend.onboarding.moduleTitle")}
						</TextStyled>
						<TextStyled
							$kind="bodyLong01"
							as="p"
						>
							{getTranslation("frontend.onboarding.moduleBody")}
						</TextStyled>
						<OnboardingBar>
							<Progress $progress={completed/total}>
								<ProgressText>{completed}/{total}</ProgressText>
							</Progress>
						</OnboardingBar>
						<Button
							fluid
							kind="secondary"
							onClick={handleOpenClick}
							size="small"
						>
							{getTranslation("frontend.onboarding.complete")}
						</Button>
					</ContentWrap>
				</OnboardingModuleWrap>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<OnboardingModuleWrap onClick={handleOpenClick}>
					<ContentWrap>
						<TextStyled
							$kind="bodyLong01"
							as="p"
						>
							{getTranslation("frontend.onboarding.moduleBody")}
						</TextStyled>
						<OnboardingBar>
							<Progress $progress={completed/total}>
								<ProgressText>{completed}/{total}</ProgressText>
							</Progress>
						</OnboardingBar>
					</ContentWrap>
					<Icon
						src={ChevronRightSVG}
						width={16}
						height={16}
					/>
				</OnboardingModuleWrap>
			</MobileAndTabletPortrait>
		</>
	)};

export default OnboardingModule;