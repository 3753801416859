import React from "react";
import Answer from "@components/Card/components/Answer/Answer";
import Comments from "@components/Card/components/Comments/Comments";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import UserGeneratedContentInFeed from "@components/PostContent/components/UserGeneratedContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader/CardHeader";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const QuestionOpenEnded = () => (<CardContainer>
		<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
			<SharedStyles.SpacingContainer>
				<CardHeader />
				<UserGeneratedContentInFeed />
				<Answer />
			</SharedStyles.SpacingContainer>
		</ModuleWrapper>
		{/* @ts-ignore*/}
		<Comments />
		<CardFooter />
	</CardContainer>);
export default QuestionOpenEnded;
