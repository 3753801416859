import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "../../../MediaQueries/MediaQueries";

const Container = styled.div`
	width: 592px;
	text-align: left;
	background-color: ${({ theme }) => theme.color.uiBackground};

	&.context-modal-corona19-smaller {
		width: 516px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;

const ModalContainer = ({ dataComponent, children, className, contextClass = null }) => (
	<Container
		data-component={dataComponent}
		className={
			classNames(
				className,
				"modal-container",
				{
					[`context-modal-${contextClass}`]: contextClass
				}
			)
		}
	>
		{children}
	</Container>
);

ModalContainer.propTypes = {
	dataComponent: PropTypes.string,
	children: PropTypes.node,
	contextClass: PropTypes.string,
	classname: PropTypes.string,
};

ModalContainer.defaultProps = {
	contextClass: "",
};

export default ModalContainer;
