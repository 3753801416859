import { useCallback, useMemo } from "react";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./TranslationToggle.styles";
const TranslationToggle = () => {
    const { dispatch: dispatchPostUIViewing, showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { originalContentHtml, } = useMemoizedContext("postData", [
        "originalContentHtml",
    ]);
    const handleToggleTranslation = useCallback((e) => {
        e.stopPropagation();
        dispatchPostUIViewing({
            type: PostUIViewingActionTypes.TOGGLE_SHOW_ORIGINAL_CONTENT,
        });
    }, [dispatchPostUIViewing]);
    return useMemo(() => {
        if (originalContentHtml) {
            return (<Styles.AutoTranslatedContent $kind="bodyLong02" onClick={handleToggleTranslation}>
					{showOriginalContent
                    && (<Styles.Toggle>
								{getTranslation("frontend.card.showTranslation")}
							</Styles.Toggle>)}
					{!showOriginalContent
                    && (<>
								{getTranslation("frontend.card.autoTranslated")}&nbsp;
								<Styles.Toggle>
									{getTranslation("frontend.card.showOriginal")}
								</Styles.Toggle>
							</>)}
				</Styles.AutoTranslatedContent>);
        }
        return null;
    }, [
        handleToggleTranslation,
        originalContentHtml,
        showOriginalContent,
    ]);
};
export default TranslationToggle;
