import { CommentDefaultValues } from "@contexts/Comment/CommentContext";
import { VotedType } from "@frontend/types/Post/comment";
export const CommentActionTypes = Object.freeze({
    ADD_COMMENT: "ADD_COMMENT",
    ADD_REPLY: "ADD_REPLY",
    DELETE_REPLY: "DELETE_REPLY",
    LOAD_MORE_REPLIES: "LOAD_MORE_REPLIES",
    MARK_BEST: "MARK_BEST",
    RESET_COMMENT: "RESET_COMMENT",
    SET_COMMENT_HTML: "SET_COMMENT_HTML",
    SYNC_ATTACHMENTS: "SYNC_ATTACHMENTS",
    SYNC_REPLY: "SYNC_REPLY",
    TOGGLE_FOLLOW: "TOGGLE_FOLLOW",
    UPDATE_COMMENT: "UPDATE_COMMENT",
    VOTE_UP: "VOTE_UP",
    VOTE_DOWN: "VOTE_DOWN",
});
const CommentActionTypesValues = Object.values(CommentActionTypes);
const CommentReducer = (state, action) => {
    switch (action.type) {
        case CommentActionTypes.ADD_COMMENT:
        case CommentActionTypes.ADD_REPLY: {
            return {
                ...state,
                ...action.payload.comment,
            };
        }
        case CommentActionTypes.DELETE_REPLY: {
            return {
                ...state,
                replies: state.replies.filter((reply) => reply.id !== action.payload.comment.id),
            };
        }
        case CommentActionTypes.LOAD_MORE_REPLIES: {
            return {
                ...state,
                replies: [
                    ...state.replies,
                    ...action.payload.replies,
                ],
            };
        }
        case CommentActionTypes.MARK_BEST: {
            return {
                ...state,
                best: true,
            };
        }
        case CommentActionTypes.RESET_COMMENT: {
            return {
                ...CommentDefaultValues,
            };
        }
        case CommentActionTypes.SET_COMMENT_HTML: {
            return {
                ...state,
                comment: action.payload.commentHtml,
            };
        }
        case CommentActionTypes.SYNC_ATTACHMENTS: {
            return {
                ...state,
                attachments: [
                    ...action.payload.context.attachments,
                ],
            };
        }
        case CommentActionTypes.SYNC_REPLY: {
            const findComment = state.replies.find((comment) => comment.id === action.payload.comment.id);
            if (!findComment) {
                return {
                    ...state,
                    replies: [
                        action.payload.comment,
                        ...state.replies,
                    ],
                };
            }
            return {
                ...state,
                replies: state.replies.map((reply) => {
                    if (reply.id === action.payload.comment.id) {
                        return {
                            ...reply,
                            ...action.payload.comment,
                        };
                    }
                    return reply;
                }),
            };
        }
        case CommentActionTypes.TOGGLE_FOLLOW: {
            return {
                ...state,
                following: !state.following,
            };
        }
        case CommentActionTypes.UPDATE_COMMENT: {
            return {
                ...state,
                ...action.payload.comment,
            };
        }
        case CommentActionTypes.VOTE_UP: {
            if (VotedType.UP === state["voted"]) {
                return {
                    ...state,
                    voted: null,
                    votes: state.votes - 1,
                };
            }
            if (VotedType.DOWN === state["voted"]) {
                return {
                    ...state,
                    voted: VotedType.UP,
                    votes: state.votes + 2,
                };
            }
            return {
                ...state,
                voted: VotedType.UP,
                votes: state.votes + 1,
            };
        }
        case CommentActionTypes.VOTE_DOWN: {
            if (VotedType.DOWN === state["voted"]) {
                return {
                    ...state,
                    voted: null,
                    votes: state.votes + 1,
                };
            }
            if (VotedType.UP === state["voted"]) {
                return {
                    ...state,
                    voted: VotedType.DOWN,
                    votes: state.votes - 2,
                };
            }
            return {
                ...state,
                voted: VotedType.DOWN,
                votes: state.votes - 1,
            };
        }
        default:
            return state;
    }
};
export default CommentReducer;
