import { useParams } from "react-router-dom";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import NewPostDesktop from "@components/NewPost/components/NewPostDesktop";
import NewPostMobile from "@components/NewPost/components/NewPostMobile";
import { PostUIEditingDefaultValues } from "@contexts/PostUI/Editing";
import PostUIEditingProvider from "@contexts/PostUI/Editing/PostUIEditingProvider";
import { useMemoizedContext } from "@hooks/Hooks";
const NewPost = ({ brandAdministrator, focused = false, id, isProfilePage = false, onExit = () => { }, publishCard, } // TODO: most of these props are no longer passed in
) => {
    const { memberId } = useParams();
    const user = useMemoizedContext("member");
    if (isProfilePage
        && (memberId !== id
            || brandAdministrator === false)) {
        return null;
    }
    if (user.hasAccess("canCreateEditAndDeletePostsInFrontend")) {
        const initialState = {
            ...PostUIEditingDefaultValues,
            focused,
        };
        return (<PostUIEditingProvider initialState={initialState}>
				<MobileAndTabletPortrait>
					<NewPostMobile publishCard={publishCard}/>
				</MobileAndTabletPortrait>
				<TabletLandscapeAndDesktop>
					<NewPostDesktop onExit={onExit} publishCard={publishCard}/>
				</TabletLandscapeAndDesktop>
			</PostUIEditingProvider>);
    }
    return null;
};
export default NewPost;
