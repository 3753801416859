/* eslint-disable camelcase */
import { Auth, Analytics } from "aws-amplify";
import isMobile from "ismobilejs";
import pTimeout from "p-timeout";
import uaParser from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";
import { getCookie, setCookie } from "@frontend/Utils";
import { inViewlevels, useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";

export const SESSION_TRACKING_COOKIE = "aws_amplify_session_tracking";

const logTrackingEventsToConsole = false;

const checkForValidity = (value) => (
	value !== undefined
	&& value !== null
	&& value !== "null"
	&& value !== ""
);

const getCheckToIncludeInGoogleAnalytics = (exclude) => (value) => !!(
	checkForValidity(value)
	&& !exclude
);

/*
 * argsInitial object parameters:
 *
 *  - category (string - required): name of the category, for example 'main navigation'.
 *  - action (string - required): name of the action that took place. 'click', 'play', etc.
 *  - label (string - required): a label that identifies the action that took place,
 * 	for example 'pulse', or 'logout' if the tracking was for category 'main navigation'.
 *  - value (integer): a numeric values associated with the event.
 *  - nonInteraction (boolean): an indication of whether this event was triggered by the system
 *  (true) or triggered by the user (false).
 *  - postId (integer or string): the id of the post to associate the event with.
 *  - adId (integer or string): the id of the ad to associate the event with.
 *  - surveyId (integer or string): the id of the survey to associate the event with.
 *  - excludeGoogleAnalytics (bool): true to exclude firing the event to Google Analytics.
 *   We only have 500 events per session. In-feed impressions could easily exceed that.
 *
 * Dimensions: (these ids are generated by Google and cannot changheh their name,
 * although there are names/labels associated to the in the reporting).
 *
 *	- dimension1: user id (member id)   [GA name: userid]
 *	- dimension2: post id				[GA name: postid]
 *  - dimension3: ad id					[GA name: adid]
 *  - dimension4: survey id				[GA name: surveyid]
 *
 * Example invocation to copy and paste to use in your code (delete the parameters you do not need):

import { useTrackEvent } from "../../../../tracking/tracking";
trackEvent({
	action: "",
	adId: null,
	category: "",
	locale: "",
	label: "",
	nonInteraction: false,
	postId: null,
	queryParams: {},
	surveyId: null
	value: null,
});

 * Please look at the default Google Analytics events here - we should try and use as many as we can when matching.
 *    https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 *
 *
 * NOTE: Tracking page views in SPA:
 *
 *		 // Set this tag every time the url changes. All subsequent events will use this page
 * 			until it is set to something else later.
 *       gtag('config', 'GA_MEASUREMENT_ID', {'page_path': '/new-page.html'});
 *
 *       https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
 *
*/
const useTrackEvent = () => {
	const apiEndpoint = useApiEndpoint();
	const {
		loggedIn,
		active,
		queryParams,
	} = useMemoizedContext("member", [
		"loggedIn",
		"active",
		"queryParams",
	]);

	const {
		resourceCenterId,
		resourceCenterItemId,
	} = useMemoizedContext("postData", [
		"resourceCenterId",
		"resourceCenterItemId",
	])

	const getMemberSessionId = async (pinPointTrackingObject) => {
		const memberSessionCookie = getCookie("membership_membersessionid");
		if (!checkForValidity(memberSessionCookie)) {
			const dataResponse = await apiEndpoint("system/getmembersession");
			const body = await dataResponse.json();

			if (dataResponse.ok) {
				if ("undefined" !== typeof body.memberSessionId) {
					pinPointTrackingObject["attributes"]["memberSessionId"] = body.memberSessionId;
				}

				const newMemberSessionInfo = { memberSessionId: body.memberSessionId };
				setCookie("membership_membersessionid", JSON.stringify(newMemberSessionInfo), null, 2);
			}
		}
		else {
			const existingSessionInfo = JSON.parse(memberSessionCookie);
			pinPointTrackingObject["attributes"]["memberSessionId"] = existingSessionInfo.memberSessionId;
		}
	};

	const checkAmplifySessionCookie = async (pinPointTrackingObject) => {
		const sessionCookie = getCookie(SESSION_TRACKING_COOKIE);
		const sessionInfoCheck = JSON.parse(sessionCookie);
		if (
			!checkForValidity(sessionCookie)
			|| (
				pinPointTrackingObject["attributes"]["user_id"] !== undefined
				&& sessionInfoCheck["user_id"] !== pinPointTrackingObject["attributes"]["user_id"]
			)
		) {
			let date = new Date();
			const newSessionInfo
			= {
				user_session_start_time: date.getTime(),
				user_session_id: uuidv4(),
			};

			if (pinPointTrackingObject["attributes"]["user_id"] !== undefined) {
				newSessionInfo["user_id"] = pinPointTrackingObject["attributes"]["user_id"];
			}

			setCookie(SESSION_TRACKING_COOKIE, JSON.stringify(newSessionInfo), null, 20);

			pinPointTrackingObject["attributes"]["user_session_start_timestamp"]
				= newSessionInfo.user_session_start_time;
			pinPointTrackingObject["attributes"]["user_session_id"]
				= newSessionInfo.user_session_id;
		}
		else {
			const existingSessionInfo = JSON.parse(sessionCookie);
			setCookie(SESSION_TRACKING_COOKIE, JSON.stringify(existingSessionInfo), null, 20);

			pinPointTrackingObject["attributes"]["user_session_start_timestamp"]
				= existingSessionInfo.user_session_start_time;
			pinPointTrackingObject["attributes"]["user_session_id"] = existingSessionInfo.user_session_id;

			if (
				undefined === pinPointTrackingObject["attributes"]["user_id"]
				&& undefined !== existingSessionInfo["user_id"]
			) {
				pinPointTrackingObject["attributes"]["user_id"] = existingSessionInfo["user_id"];
			}
		}

		if (loggedIn) {
			await getMemberSessionId(pinPointTrackingObject);
		}
	};

	const truncateTrackingAttributes = (attributes, size) => {
		for (let key in attributes) {
			// https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
			if (
				attributes[key] !== Object(attributes[key])
				&& attributes[key]?.length > size
			) {
				attributes[key] = attributes[key].slice(0, size);
			}
			// if the main tracking object has an object parameter,
			// it needs to be converted to new parameters within the main object,
			// since AWS Amplify can't handle that type of attribute.
			if (attributes[key] === Object(attributes[key])) {
				for (let innerKey in attributes[key]) {
					if (
						attributes[key][innerKey] !== Object(attributes[key][innerKey])
						&& attributes[key][innerKey]?.length > size
					) {
						attributes[key][innerKey] = attributes[key][innerKey].slice(0, size);
						attributes[`${key}_${innerKey}`] = attributes[key][innerKey];
					}
				}

				delete attributes[key];
			}
		}
		return attributes;
	};

	const trackAmplifyPinpointEvent = async (pinPointTrackingObject) => {
		// Configure amplify and track event
		if ("undefined" !== typeof window?.amplifyAnalyticsConfig ) {
			truncateTrackingAttributes(pinPointTrackingObject.attributes, 200);
			await checkAmplifySessionCookie(pinPointTrackingObject);

			Analytics.autoTrack("session", {
				enable: false,
			});

			Analytics.autoTrack("pageView", {
				enable: false,
			});

			Auth.configure(window.amplifyAnalyticsConfig.authConfig);
			Analytics.configure(window.amplifyAnalyticsConfig.analyticsConfig);

			if (window.sermo.env !== "production") {
				console.log(pinPointTrackingObject);
			}

			await pTimeout(Analytics.record(pinPointTrackingObject), {
				milliseconds: 1000,
				message: false,
			});
		}
	}

	/**
	 * @param {Object} argsInitial
	 * @param {function=} callback
	 */
	return async (argsInitial, callback) => {
		if (!active) {
			return;
		}

		if (logTrackingEventsToConsole) {
			console.log("------------------------------- TRACKING EVENT ----------------------------------");
		}

		let {
			action = false,
			adId,
			category = false,
			excludeGoogleAnalytics = false,
			label = false,
			nonInteraction,
			postId,
			surveyId,
			userId,
			value,
			expanded,
			url,
			videoEventType = false,
			area,
			areaPosition,
			areaId,
			urlTitle,
			adFrequency,
			eventId,
			eventRegistrantId,
			eventToken,
			eventRegistrantToken,
			emailType,
			sermoContentCardId,
			occuredOnPath = window?.location?.pathname,
			chapterId,
			newVolume,
			oldVolume,
			state,
			skipDuration,
			videoTime,
		} = argsInitial;

		const checkToIncludeInGoogleAnalytics = getCheckToIncludeInGoogleAnalytics(excludeGoogleAnalytics);

		const internalActionMap = {
			click: "Click",
			view: "View50",
			expand: "Expand",
			"video-progress": "Video",
			download: "Download",
			open: "Open",
			delivered: "Delivered",
			collapse: "Collapse",
			"edit-name": "EditName",
			"enter-password": "EnterPassword",
			"expanded-view": "ExpandedView",
			"accept-click": "Accept",
			"uncheck-click": "Uncheck",
			edit: "edit",
		};

		inViewlevels.forEach((level, index) => {
			if (level) {
				internalActionMap[`view-${level}`] = `View${level}`;

				if (index === internalActionMap.length - 1) {
					internalActionMap.view = `View${level}`;
				}
			}
		})

		if (!category) {
			throw "Parameter 'category' must be set.";
		}

		if (!action) {
			throw "Parameter 'action' must be set.";
		}

		if (!label) {
			throw "Parameter 'label' must be set.";
		}

		if (
			checkForValidity(value)
			&& !Number.isInteger(value)
		) {
			throw "Parameter 'value' must be a number.";
		}

		// By setting property values to undefined instead of null,
		// Pinpoint events won't pick them up. Null values will be passed along to Pinpoint.
		const args = Object.keys(argsInitial).reduce((acc, key) => {
			if (argsInitial[key] !== null) {
				acc[key] = argsInitial[key];
			}
			return acc;
		}, {});

		if (args.contactSource === null) {
			args.contactSource = undefined;
		}

		if (args.contactType === null) {
			args.contactType = undefined;
		}

		const {
			utm_source: source = undefined,
			utm_medium: medium = undefined,
			utm_campaign: campaign = undefined,
			utm_term: term = undefined,
			utm_content: content = undefined,
		} = queryParams;

		let isMobileApp = false;
		let mobileAppDevice = undefined;
		let mobileAppAdvertisingId = undefined;
		let isAuthenticated = false;

		if (window && typeof window.sermo !== "undefined") {
			isMobileApp = window.sermo.isMobileApp;
			mobileAppDevice = window.sermo.mobileAppDevice;
			mobileAppAdvertisingId = window.sermo.mobileAppAdvertisingId;
			isAuthenticated = window.sermo.user.isAuthenticated;

			args.isMobileApp = isMobileApp;
			args.mobileAppDevice = mobileAppDevice;
			args.mobileAppAdvertisingId = mobileAppAdvertisingId;
		}

		let hasProcessedCallback = false;

		let skipAdTrackingForPolls = "poll" === area
			&& "poll-submit" !== label;

		if (category.length > 50) {
			category = category.substring(0, 50);
		}

		if (action.length > 50) {
			action = action.substring(0, 50);
		}

		if (label.length > 50) {
			label = label.substring(0, 50);
		}

		// Create the tracking object.
		let gtagTrackingObject = {
			event_category: category,
			event_label: label,
		};

		// Set the value.
		if (checkToIncludeInGoogleAnalytics(value)) {
			gtagTrackingObject["value"] = value;
		}

		// Set the non-interaction flag.
		if (checkToIncludeInGoogleAnalytics(nonInteraction)) {
			gtagTrackingObject["non_interaction"] = true;
		}

		// Set the user/member id.
		if (checkToIncludeInGoogleAnalytics(userId)) {
			window.gtag("set", { user_id: userId });
			gtagTrackingObject["userid"] = "" + userId;
		} else if (
			!excludeGoogleAnalytics
			&& window
			&& typeof window.sermo !== "undefined"
			&& typeof window.sermo.user !== "undefined"
			&& window.sermo.user.memberId > 0
		) {
			window.gtag("set", { user_id: window.sermo.user.memberId });
			gtagTrackingObject["userid"] = "" + window.sermo.user.memberId;
		}

		// Set the post id.
		if (checkToIncludeInGoogleAnalytics(postId)) {
			gtagTrackingObject["postid"] = "" + postId;
		}

		// Set the ad id.
		if (checkToIncludeInGoogleAnalytics(adId)) {
			gtagTrackingObject["adid"] = "" + adId;
		}

		// Set the sermo content card id.
		if (checkToIncludeInGoogleAnalytics(sermoContentCardId)) {
			gtagTrackingObject["sermocontentcardid"] = "" + sermoContentCardId;
		}

		// Set the survey id.
		if (checkToIncludeInGoogleAnalytics(surveyId)) {
			gtagTrackingObject["surveyid"] = "" + surveyId;
		}

		// Set the source
		if (checkToIncludeInGoogleAnalytics(source)) {
			gtagTrackingObject["source"] = "" + source;
		}

		// Set the medium
		if (checkToIncludeInGoogleAnalytics(medium)) {
			gtagTrackingObject["medium"] = "" + medium;
		}

		// Set the campaign
		if (checkToIncludeInGoogleAnalytics(campaign)) {
			gtagTrackingObject["campaign"] = "" + campaign;
		}

		// Set the term
		if (checkToIncludeInGoogleAnalytics(term)) {
			gtagTrackingObject["term"] = "" + term;
		}

		// Set the content
		if (checkToIncludeInGoogleAnalytics(content)) {
			gtagTrackingObject["content"] = "" + content;
		}

		if (
			logTrackingEventsToConsole
			&& !excludeGoogleAnalytics
		) {
			console.log("   Google Analytics: ");
			console.log("      Category:   " + category);
			console.log("      Label:      " + label);
			console.log("      Additional: " + JSON.stringify(gtagTrackingObject));
		}

		if (!excludeGoogleAnalytics) {
			// Sent the tracking object to Google Analytics.
			window.gtag("event", action, gtagTrackingObject);
		}

		// AWS AMPLIFY/PINPOINT
		// Create the tracking object
		let amplifyEventName = category || "";

		if (label) {
			if (amplifyEventName.length > 0) {
				amplifyEventName += "-";
			}
			amplifyEventName += label;
		}

		if (action) {
			if (amplifyEventName.length > 0) {
				amplifyEventName += "-";
			}
			amplifyEventName += action;
		}

		// currently just used with the trial/lead modal
		// to determine if its in a closable state or not
		if (state) {
			if (amplifyEventName.length > 0) {
				amplifyEventName += "-";
			}
			amplifyEventName += state;
		}

		// Main tracking object
		let pinPointTrackingObject = {
			name: amplifyEventName,
			attributes: {
				uuid: uuidv4(),
				...args
			},
		};

		// Removing duplicate/unwanted data
		pinPointTrackingObject["attributes"]["queryParams"] = undefined;
		pinPointTrackingObject["attributes"]["userType"] = undefined;
		pinPointTrackingObject["attributes"]["userId"] = undefined;

		pinPointTrackingObject["attributes"]["env"] = window.sermo.env;
		pinPointTrackingObject["attributes"]["country"] = window.sermo.countryCode;
		pinPointTrackingObject["attributes"]["locale"] = window.sermo.locale;
		pinPointTrackingObject["attributes"]["user_type"] = 1 === window.sermo.affiliation
			? "physician"
			: "ohcp";
		pinPointTrackingObject["attributes"]["domain"] = window.location.host;
		pinPointTrackingObject["attributes"]["href"] = window.location.href;
		pinPointTrackingObject["attributes"]["path_name"] = window.location.pathname;

		if ("string" === typeof (document.referrer)) {
			pinPointTrackingObject["attributes"]["referrer"] = document.referrer;
		}

		if (source) pinPointTrackingObject["attributes"]["campaign_source"] = source;
		if (medium) pinPointTrackingObject["attributes"]["campaign_medium"] = medium;
		if (campaign) pinPointTrackingObject["attributes"]["campaign_name"] = campaign;
		if (term) pinPointTrackingObject["attributes"]["campaign_term"] = term;
		if (content) pinPointTrackingObject["attributes"]["campaign_content"] = content;

		// video tracking
		if (chapterId) pinPointTrackingObject["attributes"]["chapter_id"] = chapterId;
		if (newVolume) pinPointTrackingObject["attributes"]["new_volume"] = newVolume;
		if (oldVolume) pinPointTrackingObject["attributes"]["old_volume"] = oldVolume;
		if (skipDuration) pinPointTrackingObject["attributes"]["skip_duration"] = skipDuration;
		if (videoTime) pinPointTrackingObject["attributes"]["video_time"] = videoTime;

		const {
			phone,
			tablet,
		} = isMobile(navigator.userAgent);
		pinPointTrackingObject["attributes"]["device"] = tablet
			? "tablet"
			: phone
				? "mobile"
				: "desktop";

		const {
			os,
			browser,
			device,
		} = uaParser(navigator.userAgent);
		if (typeof window.screen !== "undefined") {
			pinPointTrackingObject["attributes"]["screen"] = window.screen.width + "x" + window.screen.height;
		}

		if (
			typeof os !== "undefined"
			&& typeof os.name !== "undefined"
		) {
			pinPointTrackingObject["attributes"]["os"] = os.name + " " + os.version;
		}

		if (
			typeof browser !== "undefined"
			&& typeof browser.name !== "undefined"
		) {
			pinPointTrackingObject["attributes"]["browser"] = browser.name + "/" + browser.version;
		}

		if (
			typeof device !== "undefined"
			&& typeof device.vendor !== "undefined"
		) {
			pinPointTrackingObject["attributes"]["device_detail"] = device.vendor + " " + device.model;
		}

		if (checkForValidity(userId)) {
			pinPointTrackingObject["attributes"]["user_id"] = userId;
		} else if (
			window
			&& typeof window.sermo !== "undefined"
			&& typeof window.sermo.user !== "undefined"
			&& window.sermo.user.memberId > 0
		) {
			pinPointTrackingObject["attributes"]["user_id"] = window.sermo.user.memberId;
			if (checkForValidity(window.sermo.user.primarySpecialtyName)) {
				pinPointTrackingObject["attributes"]["specialty"] = window.sermo.user.primarySpecialtyName;
			}
		}
		if (checkForValidity(resourceCenterId)) {
			pinPointTrackingObject["attributes"]["resource_center_id"] = resourceCenterId;
		}
		if (checkForValidity(resourceCenterItemId)) {
			pinPointTrackingObject["attributes"]["resource_center_item_id"] = resourceCenterItemId;
		}

		if (logTrackingEventsToConsole) {
			console.log("   AWS AMPLIFY/PINPOINT: ");
			console.log("      Name:      " + amplifyEventName);
			console.log("      Additional: " + JSON.stringify(pinPointTrackingObject));
		}

		// check authentication so we dont track on ad preview
		if (
			isAuthenticated
			&& !skipAdTrackingForPolls
			&& (
				adId
				|| resourceCenterId
				|| resourceCenterItemId
				|| sermoContentCardId
			)
		) {
			let eventType = internalActionMap[action];

			if (eventType) {
				if (
					"poll" === area
					&& "poll-submit" === label
				) {
					eventType = "PollAnswerSubmit";
				}

				const teParams = {
					adId,
					expanded,
					eventType,
					source,
					medium,
					campaign,
					term,
					content,
					url,
					area,
					areaPosition,
					areaId,
					urlTitle,
					isMobileApp,
					mobileAppDevice,
					mobileAppAdvertisingId,
					adFrequency,
					resourceCenterId,
					resourceCenterItemId,
					sermoContentCardId,
					occuredOnPath,
				};

				if ("download" === action) {
					teParams["download"] = label;
					teParams["eventType"] = "Download";
				}

				if (videoEventType) {
					teParams["eventType"] = "Video";
					teParams["videoEventType"] = videoEventType;
				}

				if (emailType) {
					teParams["emailType"] = emailType;
				}

				const dataResponse = await apiEndpoint("sc/te", teParams);
				if (dataResponse.ok) {
					const body = await dataResponse.json();
					if (body.eventId !== null) {
						pinPointTrackingObject["attributes"]["adEventId"] = body.eventId;
					}

					if ("function" === typeof callback) {
						await trackAmplifyPinpointEvent(pinPointTrackingObject);
						hasProcessedCallback = true;
						callback(body);
					} else {
						await trackAmplifyPinpointEvent(pinPointTrackingObject);
					}
				} else {
					await trackAmplifyPinpointEvent(pinPointTrackingObject);
				}
			} else {
				await trackAmplifyPinpointEvent(pinPointTrackingObject);
			}
		} else if (
			!adId
			&& (
				eventId
				|| eventToken
			)
		) {
			let eventType = internalActionMap[action];

			if (eventType) {
				await checkAmplifySessionCookie(pinPointTrackingObject);
				let sessionId = pinPointTrackingObject["attributes"]["user_session_id"];

				if (checkForValidity(pinPointTrackingObject["attributes"]["user_id"])) {
					userId = pinPointTrackingObject["attributes"]["user_id"];
				}

				const eventTrackingParams = {
					eventId,
					eventToken,
					eventRegistrantId,
					eventRegistrantToken,
					memberId: userId,
					expanded,
					eventType,
					source,
					medium,
					campaign,
					term,
					content,
					url,
					area,
					areaPosition,
					areaId,
					urlTitle,
					isMobileApp,
					mobileAppDevice,
					mobileAppAdvertisingId,
					sessionId,
				};

				if ("download" === action) {
					eventTrackingParams["download"] = label;
					eventTrackingParams["eventType"] = "Download";
				}

				if (videoEventType) {
					eventTrackingParams["eventType"] = "Video";
					eventTrackingParams["videoEventType"] = videoEventType;
				}

				const dataResponse = await apiEndpoint("events/te", eventTrackingParams);
				if (dataResponse.ok) {
					const body = await dataResponse.json();
					if (body.eventId !== null) {
						pinPointTrackingObject["attributes"]["eventTrackingId"] = body.eventTrackingId;
					}

					await trackAmplifyPinpointEvent(pinPointTrackingObject);
				} else {
					await trackAmplifyPinpointEvent(pinPointTrackingObject);
				}
			} else {
				await trackAmplifyPinpointEvent(pinPointTrackingObject);
			}
		} else {
			await trackAmplifyPinpointEvent(pinPointTrackingObject);
		}

		// GTM DataLayer Events
		window.dataLayer.push({
			"event": amplifyEventName,
			...args,
		});

		// Invoke the callback function if provided.
		if ("function" === typeof callback) {
			if (!hasProcessedCallback) {
				window.setTimeout(() => {
					callback();
				}, 300);
			}
		}

		if (logTrackingEventsToConsole) {
			console.log("---------------------------------------------------------------------------------");
		}
	};
};

export { useTrackEvent };
