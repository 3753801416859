import React from "react";
import { Routes, Route } from "react-router-dom";
import DrugRatingFilter from "./Filters/DrugRatingFilter";
import Location from "./Filters/Location";
import PostedBy from "./Filters/PostedBy";
import PostSort from "./Filters/PostSort";
import PostType from "./Filters/PostType";
import TimeRange from "./Filters/TimeRange";
import styles from "./Search.scss";

const MobileFilterModal = () => (
	<div styleName="styles.filter-modal">
		<Routes>
			<Route
				path="search/posts"
				element={
					(
						<>
							<PostSort />
							<PostType />
							<TimeRange />
							<PostedBy />
							<Location />
						</>
					)
				}
			/>
			<Route
				path="search/people"
				element={
					(
						<>
							<PostedBy />
							<Location />
						</>
					)
				}
			/>
			<Route
				path="search/rate"
				element={(
					<DrugRatingFilter />
				)}
			/>
		</Routes>
	</div>
);

export default MobileFilterModal;
