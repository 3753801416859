export const PollAnswerActionTypes = Object.freeze({
    SET_ANSWER_TEXT: "SET_ANSWER_TEXT",
    SYNC_ANSWER: "SYNC_ANSWER",
});
const PollAnswerActionTypesValues = Object.values(PollAnswerActionTypes);
const PollAnswerReducer = (state, action) => {
    switch (action.type) {
        case PollAnswerActionTypes.SET_ANSWER_TEXT: {
            return {
                ...state,
                answer: action.payload.text,
            };
        }
        case PollAnswerActionTypes.SYNC_ANSWER: {
            const { answer } = action.payload;
            return {
                ...state,
                ...answer,
            };
        }
        default:
            return state;
    }
};
export default PollAnswerReducer;
