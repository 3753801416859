import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalColumnWrapper = ({ children }) => <div styleName={"styles.modal-column-wrapper"}>{children}</div>;

ModalColumnWrapper.propTypes = {
	children: PropTypes.node,
};

export default ModalColumnWrapper;
