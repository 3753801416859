import React from "react";
import PollAnswerContext, { PollAnswerDefaultValues, } from "@contexts/PollAnswer/PollAnswerContext";
import PollAnswerReducer from "@contexts/PollAnswer/PollAnswerReducer";
const PollAnswerProvider = ({ children, answer }) => {
    const initializeAnswer = React.useCallback((answer) => {
        return {
            ...PollAnswerDefaultValues,
            ...answer,
        };
    }, []);
    const [state, dispatch] = React.useReducer(PollAnswerReducer, answer, initializeAnswer);
    const value = {
        ...state,
        dispatch,
    };
    return (<PollAnswerContext.Provider value={value}>
			{children}
		</PollAnswerContext.Provider>);
};
export default PollAnswerProvider;
