import { ColorGrey30, ColorWhite } from "@sermo/design-tokens";
import styled from "styled-components";
import Onboarding from "@assets/images/onboarding.jpg";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const OnboardingModuleWrap = styled.div`
	width: 100%;
	margin: 0 0 16px;
	border: 1px solid rgb(236, 237, 239);
	border-radius: 8px;
	background-color: ${ColorWhite};
	overflow: hidden;

	${StyledMobileAndTabletPortraitQuery} {
		margin: 8px 0;
		padding: 16px;
		border-radius: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		svg {
			color: ${ColorGrey30};
		}
	}
`;

export const OnboardingImage = styled.img.attrs({ src: Onboarding })`
	width: 100%;
	height: 66px;
	object-fit: cover;

	${StyledMobileAndTabletPortraitQuery} {
        height: 77px;
    }
`;

export const ContentWrap = styled.div`
	padding: 16px;

	& > p {
        margin: 8px 0 0;
    }

	& > div {
		margin: 12px 0 0;
	}

	& > button {
        margin: 24px 0 0;
    }

	${StyledMobileAndTabletPortraitQuery} {
		padding: 0 16px 0 0;
		flex-basis: 100%;

		& > p {
            margin: 0;
        }

		& > div {
            margin: 4px 0 0;
        }
	}
`;