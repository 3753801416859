import React from "react";
import PollQuestionContext from "@contexts/PollQuestion/PollQuestionContext";
import PollQuestionReducer from "@contexts/PollQuestion/PollQuestionReducer";
const PollQuestionProvider = ({ children, question }) => {
    const [state, dispatch] = React.useReducer(PollQuestionReducer, question);
    return (<PollQuestionContext.Provider value={{ ...state, dispatch }}>
			{children}
		</PollQuestionContext.Provider>);
};
export default PollQuestionProvider;
