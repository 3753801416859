import AdPreviewProfileOverview from "@components/AdPreviewLayout/AdPreviewProfileOverview";
import CreatePostButton from "@components/CreatePostButton";
import { ClickStop } from "@components/Helpers/Helpers";
import Rail from "@components/Layout/components/Rail";
import FollowMenu from "./AdPreviewFollowMenu";

const LeftRail = () => (
	<Rail side={"left"}>
		<ClickStop>
			<CreatePostButton />
			<AdPreviewProfileOverview />
			<FollowMenu />
		</ClickStop>
	</Rail>
);

export default LeftRail;
