import classNames from "classnames";
import React, { useState, useEffect } from "react";
import Button from "@components/Button/Button";
import { TextField, TermsAndConditions, ValidationMessage } from "@components/FormFields/FormFields";
import { Loading } from "@components/Helpers/Helpers";
import { getUrlParameter } from "@frontend/Utils";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import { useTrackEvent } from "../../tracking/tracking";
import styles from "./LeadOutcome.scss";

const LeadOutcome = () => {
	const trackEvent = useTrackEvent();
	const apiEndpoint = useApiEndpoint();

	const [loading, setLoading] = useState(false);
	const [globalValidationError, setGlobalValidationError] = useState("");

	const [loadingLandingPage, setLoadingLandingPage] = useState(true);
	const [landingPageTitle, setLandingPageTitle] = useState("");
	const [landingPageDescription, setLandingPageDescription] = useState("");
	const [landingPageShowTermsAndConditions, setLandingPageShowTermsAndConditions] = useState(true);

	const [trackNewPasswordEntered, setTrackNewPasswordEntered] = useState(true);

	const [newPassword, setNewPassword] = useState("");
	const [newPasswordValidationError, setNewPasswordValidationError] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [confirmNewPasswordValidationError, setConfirmNewPasswordValidationError] = useState("");

	const [acceptedTermsAndConditions, setAceptedTermsAndConditions] = useState(false);
	const [acceptedTermsAndConditionsValidationError, setAceptedTermsAndConditionsValidationError] = useState("");

	useEffect(() => {
		apiEndpoint("leads/getlandingpage", {}).then(
			dataResponse => {
				setLoadingLandingPage(false);
				dataResponse.json().then(body => {
					if (dataResponse.ok) {
						setLandingPageTitle(body.headline);
						setLandingPageDescription(body.mainText);
						setLandingPageShowTermsAndConditions(body.showAcceptTermsAndConditions);
						setAceptedTermsAndConditionsValidationError(!body.showAcceptTermsAndConditions);
					} else {
						setGlobalValidationError(body.message);
					}
				});
			}
		);
	}, []);

	if ("undefined" === typeof window) {
		return null;
	}

	if (loadingLandingPage) {
		return <Loading />;
	}

	const validateForm = () => {
		let valid = true;

		if (newPassword !== null && newPassword.length >= 6) {
			setNewPasswordValidationError("");
		} else {
			setNewPasswordValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourNewPassword",
					true)
			);
			valid = false;
		}

		if (landingPageShowTermsAndConditions) {
			if (acceptedTermsAndConditions) {
				setAceptedTermsAndConditionsValidationError("");
			} else {
				setAceptedTermsAndConditionsValidationError(
					getTranslation("system.validationMessages.validationYouMustAgreeToTheTermsAndConditions", true)
				);
				valid = false;
			}
		}

		return valid;
	};

	const onAcceptTermsAndConditionsChange = (e) => {
		setAceptedTermsAndConditions(e.target.checked);
	}

	const newPasswordChangeHandler = event => {
		setNewPassword(event.target.value);
		setNewPasswordValidationError("");

		if (trackNewPasswordEntered) {
			setTrackNewPasswordEntered(false);

			trackEvent({
				category: "leadsurvey",
				label: "form",
				action: "enteredpassword",
			});
		}
	};

	const onConfirmNewPasswordChange = (e) => {
		setConfirmNewPassword(e.target.value);
		setConfirmNewPasswordValidationError("");
	}

	const handleFormSubmit = e => {
		e.preventDefault();

		setGlobalValidationError("");

		trackEvent({
			category: "leadsurvey",
			label: "form",
			action: "submit",
		});

		if (validateForm()) {
			setLoading(true);

			let timeZoneId = null;
			try
			{
				timeZoneId = new Intl.DateTimeFormat().resolvedOptions().timeZone;
			}
			catch (ex) {
				console.error(ex);
			}

			let requestData = {
				password: newPassword,
				confirmPassword: confirmNewPassword,
				timeZoneOffset: -new Date().getTimezoneOffset(),
				timeZoneId: timeZoneId,
			};

			if (landingPageShowTermsAndConditions) {
				requestData["acceptTermsAndConditions"] = acceptedTermsAndConditions;
			}

			apiEndpoint("leads/completeconversion", requestData).then(dataResponse => {
				dataResponse.json().then(body => {
					setLoading(false);

					if (dataResponse.ok) {
						trackEvent(
							{
								category: "leadsurvey",
								action: "complete",
								label: "form",
							},
							() => {
								window.location = getUrlParameter("returnUrl") || "/feed/all-sermo" + window.location.search;
							}
						);
					} else {
						if (body.message) {
							setGlobalValidationError(body.message);
						}

						setTrackNewPasswordEntered(true);

						if (body.fields !== null) {
							for (var property in body.fields) {
								switch (property) {
									case "password":
										setNewPasswordValidationError(body.fields[property].join("<br />"));
										break;
									case "confirmPassword":
										setConfirmNewPasswordValidationError(body.fields[property].join("<br />"));
										break;
									case "acceptTermsAndConditions":
										setAceptedTermsAndConditionsValidationError(
											body.fields[property].join("<br />")
										);
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	return (
		<div styleName={classNames(["styles.lead-outcome"])}>
			<div styleName={classNames(["styles.main-content"])}>

				<div styleName={classNames(["styles.content-box"])}>
					<div styleName={classNames(["styles.content-box-inner-container"])}>
						<div
							styleName={classNames(["styles.form-title", "typography.heading-brand-05"])}
							dangerouslySetInnerHTML={{ __html: landingPageTitle }}
						></div>
						<div
							styleName={classNames(["styles.form-subtitle", "typography.body-long-brand-03"])}
							dangerouslySetInnerHTML={{ __html: landingPageDescription }}
						></div>

						<form
							onSubmit={handleFormSubmit}
							styleName={classNames(["styles.login-form"])}
						>
							<div styleName={classNames(["styles.form-field-row"])}>
								<label
									htmlFor="newPassword"
									styleName={classNames(["styles.form-field-label", "typography.body-short-brand-00"])}
								>
									{getTranslation("frontend.firstTimeLoginProfileConfiguration.createYourPassword")}
								</label>
								<TextField
									name="newPassword"
									type="password"
									autoComplete="new-password"
									validationError={newPasswordValidationError}
									handleChange={newPasswordChangeHandler}
									value={newPassword}
								/>
							</div>
							<div styleName={classNames(["styles.form-field-row"])}>
								<label
									htmlFor="newPassword"
									styleName={classNames(["styles.form-field-label", "typography.body-short-brand-00"])}
								>
									{getTranslation("frontend.firstTimeLoginProfileConfiguration.confirmYourPassword")}
								</label>
								<TextField
									name="confirmNewPassword"
									type="password"
									autoComplete="confirm-new-password"
									validationError={confirmNewPasswordValidationError}
									handleChange={onConfirmNewPasswordChange}
									value={confirmNewPassword}
								/>
							</div>

							{
								landingPageShowTermsAndConditions && (
									<div
										styleName={classNames(["styles.form-line", "styles.terms-and-conditions-wrapper"])}
									>
										<TermsAndConditions
											name="acceptTermsAndConditions"
											text={getTranslation("frontend.modals.trialMember.acceptTermsAndConditions")}
											handleChange={onAcceptTermsAndConditionsChange}
											checked={acceptedTermsAndConditions}
										/>
										<div styleName={classNames(["styles.terms-and-conditions-error-wrapper"])}>
											<ValidationMessage text={acceptedTermsAndConditionsValidationError} />
										</div>
									</div>
								)
							}

							<ValidationMessage text={globalValidationError} />

							<div styleName="styles.submit-container">
								<Button
									name="submit"
									clickHandler={handleFormSubmit}
									size="medium"
									disabled={loading}
									type="submit"
								>
									{getTranslation("frontend.generics.next")}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeadOutcome;
