import { ActivityIcon, Icon, MenuSVG, SearchIcon } from "@sermo/ui-components";
import { useNavigate } from "react-router-dom";
import { ClickStop } from "@components/Helpers/Helpers";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import * as Styles from "@components/Navs/components/MobileTopNav/MobileTopNav.styles";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useSetSidePanelLabel } from "@contexts/UI";
import LogoFullSVG from "@frontend/assets/images/Logo.svg";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
const AdPreviewMobileTopNav = () => {
    const navigate = useNavigate();
    const setSidePaneLabel = useSetSidePanelLabel();
    const trackEvent = useTrackEvent();
    const user = useMemoizedContext("member");
    const handleClick = (to, trackingLabel) => {
        trackEvent({
            category: "top-navigation",
            label: trackingLabel,
            action: "click",
        });
        navigate(to);
    };
    const handleBurgerClick = () => {
        trackEvent({
            category: "left-side-panel",
            label: "open",
            action: "click",
        });
        setSidePaneLabel("LeftMobileSidePanel");
    };
    const handleProfileOverviewClick = () => {
        trackEvent({
            category: "right-side-panel",
            label: "open",
            action: "click",
        });
        setSidePaneLabel("RightMobileSidePanel");
    };
    return (<MobileAndTabletPortrait>
			<ClickStop>
				<Styles.Container data-component={"AdPreviewMobileTopNav"}>
					<Styles.Left>
						<Styles.Selection data-component={"Burger"} onClick={handleBurgerClick}>
							<Icon height={28} src={MenuSVG} width={28}/>
						</Styles.Selection>
						<Styles.Selection data-component={"Logo"} onClick={() => handleClick("/", "logo")}>
							<Icon height={24} src={LogoFullSVG} width={24}/>
						</Styles.Selection>
					</Styles.Left>
					<Styles.Right>
						<Styles.Selection data-component={"Activity"} onClick={() => handleClick("/activity", "activity")}>
							<Icon height={24} src={ActivityIcon} width={24}/>
						</Styles.Selection>
						<Styles.Selection data-component={"Search"} onClick={() => handleClick("/search", "search")}>
							<Icon height={24} src={SearchIcon} width={24}/>
						</Styles.Selection>
						<Styles.Selection data-component={"ProfileOverview"} onClick={handleProfileOverviewClick}>
							<ProfilePic user={user} size="large" contextClass="top-nav-menu"/>
						</Styles.Selection>
					</Styles.Right>
				</Styles.Container>
			</ClickStop>
		</MobileAndTabletPortrait>);
};
export default AdPreviewMobileTopNav;
