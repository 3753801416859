import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalText = ({ children, contextClass }) => (
	<div
		styleName={classNames("styles.modal-text", { [`styles.context-modal-text-${contextClass}`]: contextClass })}
	>
		{children}
	</div>
);

ModalText.propTypes = {
	children: PropTypes.node,
	contextClass: PropTypes.string,
};

ModalText.defaultProps = {
	contextClass: "",
};

export default ModalText;
