import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
export const Container = styled.div `
	width: 100%;
	margin-top: calc(64px + ${({ theme }) => theme.layout.topNavHeight});
	display: flex;
	justify-content: center;

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 0;
	}
`;
export const ContentBoxWider = styled.div `
	width: 592px;
	border-radius: ${({ theme }) => theme.layout.borderRadius};
	display: inline-block;
	position: relative;
	flex-grow: 0;
	background-color: ${({ theme }) => theme.color.uiBackground};
	text-align: left;

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;
export const ContentBoxWiderInnerContainer = styled.div `
	padding: 32px 48px 48px;

	${StyledMobileAndTabletPortraitQuery} {
		padding: 32px 16px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`;
export const FormTitle = styled(TextStyled).attrs({
    $kind: "heading05",
}) `
	color: ${({ theme }) => theme.color.text01};
	display: block;
	font-weight: 500;
`;
export const CallToAction = styled(TextStyled).attrs({
    $kind: "bodyShort03",
}) `
	margin-top: 12px;
	color: ${({ theme }) => theme.color.text02};
`;
export const GlobalValidationError = styled(TextStyled).attrs({
    $kind: "bodyShort02",
}) `
	div:first-child {
		font-size: inherit;
		line-height: inherit;
	}
`;
export const LoginForm = styled.form `
	margin-top: 32px;
`;
export const FormFieldRow = styled.div `
	margin-bottom: 16px;
`;
export const FormSubmitButtonContainer = styled.div `
	width: 100%;
	padding-top: 8px;
	display: flex;
	justify-content: stretch;

	& > *:first-child {
		flex-grow: 1;
	}
`;
