import React from "react";
import ParentContextUpdater from "@components/ParentContextUpdater";
import PollContext from "@contexts/Poll/PollContext";
import PollReducer from "@contexts/Poll/PollReducer";
import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { useMemoizedContext } from "@hooks/Hooks";
const ActualPollProvider = ({ children, poll }) => {
    const [state, dispatch] = React.useReducer(PollReducer, poll);
    return (<PollContext.Provider value={{ ...state, dispatch }}>
			{children}
		</PollContext.Provider>);
};
const PollProvider = ({ children }) => {
    const { poll, } = useMemoizedContext("postData", [
        "poll",
    ]);
    if (poll) {
        return (<ActualPollProvider poll={poll}>
				<ParentContextUpdater actionType={PostDataActionTypes.SYNC_POLL} childContext={PollContext} parentContext="postData" payloadKey="poll"/>
				{children}
			</ActualPollProvider>);
    }
    return <>{children}</>;
};
export default PollProvider;
