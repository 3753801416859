import { useEffect } from "react";
import Button from "@components/Button/Button";
import { StyledAlertText } from "@components/Modal/Modal.styles";
import TagChangeModal from "@components/Modal/Modals/TagChangeModal";
import usePostDataAPI from "@contexts/PostData/usePostDataAPI";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { EUIKey, useCloseModal, useOpenAlert, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const useShowPublishedAlert = () => {
    const closeModal = useCloseModal();
    const openAlert = useOpenAlert();
    const openModal = useOpenModal();
    const postDataAPI = usePostDataAPI();
    const trackEvent = useTrackEvent();
    const { expanded } = useMemoizedContext("postUIViewing", ["expanded"]);
    const { adFrequency, adId, id: postId, topic, } = useMemoizedContext("postData", [
        "adFrequency",
        "adId",
        "id",
        "topic",
    ]);
    const showPublishedAlert = () => {
        openAlert({
            [EUIKey.ALERT_COMPONENT]: (<>
					<StyledAlertText>
						{getTranslation("frontend.alerts.publishedIn", false, topic.name)}
					</StyledAlertText>
					{/* @ts-ignore */}
					<Button clickHandler={() => {
                    closeModal("published-tag-alert");
                    openModal({
                        [EUIKey.MODAL_COMPONENT]: (<TagChangeModal postId={postId} topicChange={(newTopic) => {
                                showPublishedAlert();
                                postDataAPI.selectTopic(newTopic);
                                closeModal("change-tag");
                            }} closeModalEventHandler={() => {
                                closeModal("change-tag");
                            }}/>),
                        [EUIKey.MODAL_LABEL]: "change-tag",
                        [EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.changeTag.changeTag", true),
                    });
                    trackEvent({
                        category: "suggested-post",
                        action: "click",
                        label: "wrong-tag",
                        postId,
                        adId,
                        adFrequency,
                        expanded,
                    });
                }} style="flatNeutral" size="small">
						{getTranslation("frontend.alerts.wrongTag")}
					</Button>
				</>),
            [EUIKey.ALERT_LABEL]: "published-tag-alert",
        });
    };
    return showPublishedAlert;
};
const useShowPublishedAlertEffect = ({ el }) => {
    const showPublishedAlert = useShowPublishedAlert();
    const { dispatch: dispatchPostUIViewing, justPublished, } = useMemoizedContext("postUIViewing", ["justPublished"]);
    const { topic } = useMemoizedContext("postData", ["topic"]);
    const { setElement } = useMemoizedContext("scroll");
    const topicPrevious = usePrevious(topic);
    useEffect(() => {
        if (justPublished
            && "undefined" !== typeof topic
            && el.current) {
            showPublishedAlert();
            setElement(el.current);
            dispatchPostUIViewing({
                type: PostUIViewingActionTypes.SET_JUST_PUBLISHED_FALSE,
            });
        }
    }, [el.current]);
    useEffect(() => {
        if (!!topic
            && !!topicPrevious
            && topic["name"] !== topicPrevious["name"]) {
            showPublishedAlert();
        }
    }, [topic]);
    return null;
};
export default useShowPublishedAlertEffect;
