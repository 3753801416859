import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import DateOfBirth from "@components/FormFields/components/DateInput/DateInput";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalText, ModalFooter, ModalSection } from "./../ModalComponents";

const AddDateOfBirth = ({ cancelEventHandler, newDateOfBirthCompleteEventHandler, description, value }) => {
	const apiEndpoint = useApiEndpoint();

	const [loading, setLoading] = useState(false);

	const [didClickSubmit, setDidClickSubmit] = useState(false);

	const [shouldValidateDateOfBirth, setShouldValidateDateOfBirth] = useState(false);
	const [dateOfBirthValidationErrors, setDateOfBirthValidationErrors] = useState(null);

	const [globalValidationError, setGlobalValidationError] = useState("");

	useEffect(() => {
		if (shouldValidateDateOfBirth) {
			setShouldValidateDateOfBirth(false);
		}
	}, [shouldValidateDateOfBirth]);

	const formSubmitHandler = () => {
		setDidClickSubmit(true);
		setShouldValidateDateOfBirth(true);
	};

	const cancelClickEventHandler = () => {
		cancelEventHandler(true);
	};

	const dateOfBirthChangedHandler = (newDateOfBirth) => {
		if (newDateOfBirth) {
			if (didClickSubmit) {
				setLoading(true);

				// not resaving if data haven't changed
				if (Object.keys(newDateOfBirth).every((propName) => newDateOfBirth[propName] === value[propName])) {
					if (newDateOfBirthCompleteEventHandler) {
						newDateOfBirthCompleteEventHandler({ ...value });
						return;
					}
				}

				const data = {
					dobYear: newDateOfBirth.year,
					dobMonth: newDateOfBirth.month,
					dobDay: newDateOfBirth.day,
				};

				apiEndpoint("settings/updatedateofbirth", data).then((dataResponse) => {
					dataResponse.json().then((body) => {
						setLoading(false);

						if (dataResponse.ok) {
							newDateOfBirthCompleteEventHandler(newDateOfBirth);
						} else {
							setGlobalValidationError(body.message);

							if (body.fields) {
								setDateOfBirthValidationErrors(body.fields);
							}
						}
					});
				});
			}
		} else {
			setDidClickSubmit(false);
		}
	};

	return (
		<>
			<ModalSection>
				<ModalText>{description}</ModalText>
			</ModalSection>
			<ModalSection>
				<DateOfBirth
					day={value.day}
					month={value.month}
					year={value.year}
					dateChangedEventHandler={dateOfBirthChangedHandler}
					formValidationErrors={dateOfBirthValidationErrors}
					forceValidation={shouldValidateDateOfBirth}
					addEmptyValues={true}
				/>
			</ModalSection>

			<GlobalValidationMessage text={globalValidationError} />

			<ModalFooter
				{...{
					cancelModalEventHandler: cancelClickEventHandler,
					cancelText: getTranslation("frontend.generics.back", true),
					cancelTracking: {
						category: "withdrawal",
						action: "click",
						label: "cancel-add-date-of-birth", 
					},
					submitEventHandler: formSubmitHandler,
					submitTracking: {
						category: "withdrawal",
						action: "click",
						label: "submit-add-date-of-birth", 
					},
					submitDisabled: loading,
					submitText: getTranslation("frontend.generics.next", true),
				}}
			/>
		</>
	);
};

AddDateOfBirth.propTypes = {
	cancelEventHandler: PropTypes.func,
	newDateOfBirthCompleteEventHandler: PropTypes.func,
	description: PropTypes.object,
	value: PropTypes.object,
};

AddDateOfBirth.defaultProps = {
	cancelEventHandler: () => {},
	newDateOfBirthCompleteEventHandler: () => {},
	value: { year: "", month: "", day: "" },
};

export default AddDateOfBirth;
