import React from "react";
export const PostDataDefaultValues = {
    attachments: [],
    comments: [],
    commentsCount: 0,
    contentHtml: "<p></p>",
    deleted: false,
    hidden: false,
    isAnonymous: false,
    isEditable: true,
    likes: 0,
    mentions: [],
    nextComments: 0,
    pageIndex: 0,
    poll: null,
    previousComments: 0,
    title: "",
    topicId: null,
    topLevelCommentsCount: 0,
    useConsistentScroll: true,
    resourceCenterItemId: null,
    resourceCenterId: null,
};
// TODO: possibly refactor content cards to use contentHtml/title instead of body/header
const PostDataContext = React.createContext(PostDataDefaultValues);
export default PostDataContext;
