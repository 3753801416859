import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import ProfileMenu from "@components/ProfileMenu";
import ProfileOverview from "@components/ProfileOverview";
import SidePanel, { Body, Footer } from "@components/SidePanel";
const RightMobileSidePanel = () => (<MobileAndTabletPortrait>
		<SidePanel label={"RightMobileSidePanel"} side={"right"}>
			<Body>
				<ProfileOverview />
			</Body>
			<Footer>
				<ProfileMenu />
			</Footer>
		</SidePanel>
	</MobileAndTabletPortrait>);
export default RightMobileSidePanel;
