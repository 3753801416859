import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { TextField } from "@components/FormFields/FormFields";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import LosePreferredStatusConfirm from "../LosePreferredStatusConfirm";
import { validateWithdrawalAmount, withdrawalWillRevokePreferredStatus, useModalStepsFramework } from "../withdrawalUtils";
import {
	ModalSection,
	ModalText,
	ModalFooter,
	ModalColumnWrapper,
	ModalColumn,
	Withdrawal,
} from "./../../ModalComponents";
import WithdrawalConfirm from "./../WithdrawalConfirm";

const MDHonors = ({
	availableFunds,
	currency,
	fixedFeeAmount,
	variableFeePercentage,
	isPreferredMember,
	limitToWithdrawalAmounts,
	minimumWithdrawalAmount,
	preferredStatusThreshold,
	stepBackClickHandler,
	usdConversionMultiplier,
	withdrawalCode,
	withdrawalCompleteEventHandler,
	withdrawalModalTextLabelChangeHandler,
	issueNewCard,
}) => {
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const [withdrawAmountValidationError, setWithdrawAmountValidationError] = useState("");
	const [convertedPoints, setConvertedPoints] = useState(0);
	// Used to trigger Modal title change when step changes
	const [stepChanger, setStepChanger] = useState(false);

	const steps = [
		{
			name: "enterWithdrawalAmount",
			condition: true,
		},
		{
			name: "confirmRevokingPreferredStatus",
			condition: withdrawalWillRevokePreferredStatus(
				isPreferredMember,
				preferredStatusThreshold,
				availableFunds,
				withdrawAmount
			),
		},
		{
			name: "confirm",
			condition: true,
		}];

	const [goToNextStep, goToPreviousStep, currentStepName] = useModalStepsFramework(steps, () => { setStepChanger(!stepChanger); });

	useEffect(() => {
		switch (currentStepName()) {
			case "confirm":
				withdrawalModalTextLabelChangeHandler(getTranslation("frontend.modals.withdrawal.stepConfirmTitle", true));
				break;
			default:
				withdrawalModalTextLabelChangeHandler(getTranslation("frontend.modals.withdrawal.selectWithdrawalMethod.title", true));
				break;
		}
	}, [stepChanger]);

	const stepBackClickEventHandler = () => {
		stepBackClickHandler(true);
	};

	const withdrawAmountChangeHandler = (event) => {
		setWithdrawAmount(event.target.value);
		const [isWithdrawalAmountValid, validationMessage] = validateWithdrawalAmount(
			event.target.value,
			currency,
			availableFunds,
			minimumWithdrawalAmount,
			limitToWithdrawalAmounts
		);

		if (isWithdrawalAmountValid) {
			// See multiplier rates here: https://wiki.sermo.com/display/EN/MDHonors+minutes+rates
			let multiplier = Number(usdConversionMultiplier);
			let amountInUsd = parseInt(event.target.value) * multiplier;
			let mdHonorsMultiplier;

			if (amountInUsd >= 200) {
				mdHonorsMultiplier = 0.9;
			} else if (amountInUsd >= 150) {
				mdHonorsMultiplier = 0.86;
			} else if (amountInUsd >= 120) {
				mdHonorsMultiplier = 0.83;
			} else if (amountInUsd >= 100) {
				mdHonorsMultiplier = 0.75;
			} else if (amountInUsd >= 50) {
				mdHonorsMultiplier = 0.7;
			} else if (amountInUsd >= 20) {
				mdHonorsMultiplier = 0.6;
			} else {
				mdHonorsMultiplier = 0.5;
			}

			setConvertedPoints(Math.floor(amountInUsd * mdHonorsMultiplier));
		}

		setWithdrawAmountValidationError(validationMessage);
	};

	const confirmAmountClickHandler = () => {
		const [isWithdrawalAmountValid, validationMessage] = validateWithdrawalAmount(
			withdrawAmount,
			currency,
			availableFunds,
			minimumWithdrawalAmount,
			limitToWithdrawalAmounts
		);
		if (isWithdrawalAmountValid) {
			goToNextStep();
		}
		setWithdrawAmountValidationError(validationMessage);
	};

	return (
		<>
			{
				currentStepName() === "confirm" && (
					<WithdrawalConfirm
						withdrawalCode={withdrawalCode}
						currency={currency}
						withdrawAmount={Number(withdrawAmount)}
						cancelEventHandler={goToPreviousStep}
						withdrawalCompleteEventHandler={withdrawalCompleteEventHandler}
						fixedFeeAmount={fixedFeeAmount}
						variableFeePercentage={variableFeePercentage}
						withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
						issueNewCard={issueNewCard}
					/>
				)
			}
			{
				currentStepName() === "confirmRevokingPreferredStatus" && (
					<LosePreferredStatusConfirm
						currency={currency}
						preferredStatusThreshold={preferredStatusThreshold}
						cancelEventHandler={goToPreviousStep}
						confirmEventHandler={goToNextStep}
					/>
				)
			}
			{
				currentStepName() === "enterWithdrawalAmount" && (
					<>
						<Withdrawal.PaymentMethod
							{...{
								title: getTranslation("frontend.modals.withdrawal.paymentMethodTitle"),
								method: getTranslation("frontend.modals.withdrawal.mdHonors.paymentMethodActual"),
							}}
						/>

						<ModalSection>
							<ModalColumnWrapper>
								<ModalColumn>
									<TextField
										name="withdrawAmount"
										type="text"
										placeholder="0"
										label={getTranslation("frontend.modals.withdrawal.amount", true)}
										autoComplete="withdraw-amount"
										validationError={withdrawAmountValidationError}
										handleChange={withdrawAmountChangeHandler}
										value={withdrawAmount}
									/>
								</ModalColumn>
								<ModalColumn>
									<Withdrawal.BalanceAvailable
										{...{
											amount: getTranslation(
												"frontend.modals.withdrawal.amountAvailable",
												false,
												getCurrencyTranslation(availableFunds, currency, true)
											),
										}}
									/>
								</ModalColumn>
							</ModalColumnWrapper>
							<ModalColumnWrapper>
								<ModalColumn>
									<ModalText>
									= {convertedPoints} {getTranslation("frontend.modals.withdrawal.mdHonors.points")}
									</ModalText>
									<Withdrawal.SubText>
										{getTranslation("frontend.modals.withdrawal.mdHonors.viewYourMDHonorsBalance")}
									</Withdrawal.SubText>
								</ModalColumn>
							</ModalColumnWrapper>
						</ModalSection>

						<ModalSection newSection>
							<Withdrawal.Table>
								<>
									<thead>
										<tr>
											<th>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.firstColumnHeader"
													)
												}
											</th>
											<th>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.secondColumnHeader"
													)
												}
											</th>
											<th>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.thirdColumnHeader"
													)
												}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.firstColumnPoints"
													)
												}
											</td>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.secondColumnPoints"
													)
												}
											</td>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.thirdColumnPoints"
													)
												}
											</td>
										</tr>
										<tr>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.firstColumnDiscount"
													)
												}
											</td>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.secondColumnDiscount"
													)
												}
											</td>
											<td>
												{
													getTranslation(
														"frontend.modals.withdrawal.mdHonors.pointsTable.thirdColumnDiscount"
													)
												}
											</td>
										</tr>
									</tbody>
								</>
							</Withdrawal.Table>
						</ModalSection>

						<ModalSection>
							<Withdrawal.SubText>
								{getTranslation("frontend.modals.withdrawal.mdHonors.visitMdhonorsDescription")}
							</Withdrawal.SubText>
						</ModalSection>

						<ModalFooter
							{...{
								cancelModalEventHandler: stepBackClickEventHandler,
								cancelTracking: {
									category: "withdrawal",
									action: "click",
									label: "cancel-" + withdrawalCode.toLowerCase(),
								},
								cancelText: getTranslation("frontend.generics.back", true),
								submitText: getTranslation("frontend.generics.next", true),
								submitEventHandler: confirmAmountClickHandler,
							}}
						/>
					</>
				)
			}
		</>
	);
};

MDHonors.propTypes = {
	availableFunds: PropTypes.number,
	currency: PropTypes.string,
	fixedFeeAmount: PropTypes.number,
	variableFeePercentage: PropTypes.number,
	isPreferredMember: PropTypes.bool,
	limitToWithdrawalAmounts: PropTypes.array,
	minimumWithdrawalAmount: PropTypes.number,
	preferredStatusThreshold: PropTypes.number,
	stepBackClickHandler: PropTypes.func,
	usdConversionMultiplier: PropTypes.number,
	withdrawalCode: PropTypes.string,
	withdrawalCompleteEventHandler: PropTypes.func,
};

MDHonors.defaultProps = {
	limitToWithdrawalAmounts: [],
	stepBackClickHandler: () => {},
	withdrawalCompleteEventHandler: () => {},
};

export default MDHonors;
