import styled from "styled-components";
import { StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorder } from "@frontend/styled";
import { layoutWithRails } from "@styled/mixins";

export const SurveysLayoutWrap = styled.div.attrs({ className: "surveys-layout" })`
	${layoutWithRails};
`;

export const SurveysPageWrap = styled.div.attrs({ className: "surveys-page" })`
	${StyledTabletLandscapeAndDesktopQuery} {
		width: calc(var(--site-max-width) - (var(--left-rail-width) + (16px * 2)));
		padding-top: 16px;
	}
`;

export const SurveysPageWithRightRailWrap = styled.div.attrs({ className: "surveys-page-with-right-rail" })`
	${StyledTabletLandscapeAndDesktopQuery} {
		width: calc(var(--site-max-width) - (var(--left-rail-width) + var(--right-rail-width) + (16px * 2)));
		padding-top: 16px;
	}
`;

export const SurveysDropNavContainer = styled.div.attrs({ className: "surveys-drop-nav-container" })`
	${applyBorder("bottom")};
`;