import { Button, Icon } from "@sermo/ui-components";
import React, { useMemo, useState } from "react";
import VerificationPictogram from "@assets/images/verification-pictogram.svg";
import { useCloseModal } from "@contexts/UI";
import { loggedOut } from "@frontend/mobile-app";
import { useTrackEvent } from "@frontend/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styled from "./PrivacyPolicy.styles";
const PrivacyPolicy = () => {
    const apiEndpoint = useApiEndpoint();
    const closeModal = useCloseModal();
    const trackEvent = useTrackEvent();
    const [rejected, setRejected] = useState(false);
    const { memberPrivacyPolicyAccepted } = useMemoizedContext("member");
    React.useEffect(() => {
        trackEvent({
            category: "privacy-policy-modal",
            label: "on-load",
            action: "view",
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const handleRejectClick = () => {
        if (rejected) {
            trackEvent({
                category: "privacy-policy-modal",
                label: "deactivate-my-account-button",
                action: "click",
            });
            apiEndpoint("agreements/reject", {
                agreementType: "PrivacyPolicy"
            }).then(() => {
                apiEndpoint("login/signout").then(response => {
                    response.json().then(() => {
                        if (response.ok) {
                            trackEvent({
                                category: "privacy-policy-modal",
                                action: "click",
                                label: "log-out",
                            }, () => {
                                // If running in mobile app, signal native code to stop push notifications.
                                loggedOut();
                                // Full reload cleans up member data and opened banner modals.
                                window.location.href = "/login";
                            });
                        }
                        else {
                            console.log("Error logging out!");
                        }
                    });
                });
            });
        }
        else {
            trackEvent({
                category: "privacy-policy-modal",
                label: "do-not-accept-button",
                action: "click",
            });
            setRejected(true);
        }
    };
    const handleAcceptClick = () => {
        trackEvent({
            category: "privacy-policy-modal",
            label: "accept-button",
            action: "click",
        });
        apiEndpoint("agreements/accept", {
            agreementType: "PrivacyPolicy"
        });
        memberPrivacyPolicyAccepted();
        closeModal("privacy-policy");
    };
    const rejectText = useMemo(() => (rejected
        ? getTranslation("frontend.modals.privacyPolicy.deactivateAccount")
        : getTranslation("frontend.modals.privacyPolicy.doNotAccept")), [rejected]);
    const bodyText = useMemo(() => (rejected
        ? getTranslation("frontend.modals.privacyPolicy.bodyTextRejected")
        : getTranslation("frontend.modals.privacyPolicy.bodyNotTextRejected")), [rejected]);
    const titleText = useMemo(() => (rejected
        ? getTranslation("frontend.modals.privacyPolicy.titleTextRejected")
        : getTranslation("frontend.modals.privacyPolicy.titleNotTextRejected")), [rejected]);
    return (<Styled.ModalWrap>
			<Icon src={VerificationPictogram} width={60} height={60}/>
			<Styled.TitleText>
				{titleText}
			</Styled.TitleText>
			<Styled.BodyText>
				{bodyText}
			</Styled.BodyText>
			{!rejected && <Styled.BodyDateText>
					{getTranslation("frontend.modals.privacyPolicy.effective", true, "24-NOV-2023")}
				</Styled.BodyDateText>}
			<Styled.FooterWrap>
				{!rejected && <Styled.LinkLearnMore>
						<a href={"https://support.sermo.com/hc/en-us/articles/19867183883291-Our-updated-Privacy-Policy"} target={"_blank"} rel="noreferrer">{getTranslation("frontend.card.learnMore")}</a>
					</Styled.LinkLearnMore>}
				<Button onClick={handleRejectClick} size="medium" kind={"primaryWhite"}>
					{rejectText}
				</Button>
				<Button onClick={handleAcceptClick} size="medium">
					{getTranslation("frontend.modals.privacyPolicy.accepted")}
				</Button>
			</Styled.FooterWrap>
		</Styled.ModalWrap>);
};
export default PrivacyPolicy;
