import { useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import DesktopNav from "@components/DesktopNav";
import Banner from "@components/Layout/components/Banner";
import ExpandedPost from "@components/Layout/components/ExpandedPost";
import Footer from "@components/Layout/components/Footer";
import Header from "@components/Layout/components/Header";
import MainContent from "@components/Layout/components/MainContent";
import Modals from "@components/Layout/Modals";
import MobileNav from "@components/MobileNav/MobileNav";
import RatingModal from "@components/Modal/Modals/RatingModal";
import MobileBottomNav from "@components/Navs/components/MobileBottomNav";
import MobilePostTypeSelector from "@components/NewPost/components/TypeSelector/MobileTypeSelector";
import PullToRefresh from "@components/PullToRefresh/PullToRefresh";
import BackdropForSidePanels from "@components/SidePanel/components/Backdrop";
import LeftMobileSidePanel from "@components/SidePanel/components/LeftMobileSidePanel";
import RightMobileSidePanel from "@components/SidePanel/components/RightMobileSidePanel";
import { EUIKey, useCloseMenu, useCollapsePost, useOpenModal } from "@contexts/UI";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
import TopNav from "../TopNav/TopNav";
import * as Styles from "./Layout.styles";
export const STICKY_LOCATION = "Sticky";
export const Layout = ({ children }) => {
    const closeMenu = useCloseMenu();
    const collapsePost = useCollapsePost();
    const isPost = !!useMatch("/post/*");
    const openModal = useOpenModal();
    const [interaction, setInteraction] = useState("mouse");
    const [scrollPosition, setScrollPosition] = useState(0);
    const { expandedPost, menuName, modalIsOpen, } = useMemoizedContext("ui", [
        "expandedPost",
        "menuName",
        "modalIsOpen",
    ]);
    const { scrollLocked, setScrollLocked, } = useMemoizedContext("scroll", [
        "scrollLocked",
    ]);
    const { countryCode, hasAccess, hasConfiguredProfile, locale, primarySpecialtyId, showTourGuide, } = useMemoizedContext("member", [
        "countryCode",
        "hasAccess",
        "hasConfiguredProfile",
        "locale",
        "primarySpecialtyId",
        "showTourGuide",
    ]);
    // On route change always reset scroll position
    // const {length: hIndex, location: { pathname }, action } = useNavigate();
    const routeType = useMemo(() => {
        if (isPost) {
            if (expandedPost) {
                return "postExpanded";
            }
            return "postCollapsed";
        }
        return "other";
    }, [expandedPost, isPost]);
    const routeTypePrev = usePrevious(routeType);
    useEffect(() => {
        // if we are moving between routes that aren't posts always reset
        if ("other" === routeType
            && "other" === routeTypePrev) {
            setScrollPosition(0);
        }
        // moved to a route from a post so ensure the collapse happens
        if ("other" === routeType
            && "other" !== routeTypePrev) {
            collapsePost();
        }
    }, [routeType]);
    const expandedPostPrev = usePrevious(expandedPost);
    const modalIsOpenPrev = usePrevious(modalIsOpen);
    useEffect(() => {
        if ((expandedPost && !expandedPostPrev)
            || (modalIsOpen && !modalIsOpenPrev)) {
            setScrollPosition(window.scrollY);
            setScrollLocked(true);
            document.body.classList.add("post-expanded");
        }
        if ((!expandedPost && !!expandedPostPrev)
            || (!modalIsOpen && !!modalIsOpenPrev)) {
            setScrollLocked(false);
            document.body.classList.remove("post-expanded");
            window.setTimeout(() => {
                window.scroll(0, scrollPosition);
                setScrollPosition(0);
            }, 0);
        }
    }, [
        expandedPost,
        expandedPostPrev,
        modalIsOpen,
        modalIsOpenPrev,
        routeType,
        scrollLocked,
        scrollPosition,
        setScrollLocked,
    ]);
    useEffect(() => {
        if ("undefined" !== typeof window) {
            if (window.location.search.search("srm=true") > -1) {
                if (hasAccess("canAccessFrontendDrugRatings") && hasAccess("canRateDrugsInDrugRatings") && hasConfiguredProfile && !showTourGuide) {
                    // @ts-ignore
                    let drugRatingsUrl = window.sermo.drugRatingsEndpointUrl;
                    drugRatingsUrl += "?country_of_practice_code=" + countryCode;
                    // Ratings are working a bit inconsistent. Instead of always using ISO 4 locale,
                    // it uses ISO 2 for the majority, but ISO 4 for a few specific languages.
                    if (locale === "en-GB" || locale === "es-MX" || locale === "pt-BR") {
                        drugRatingsUrl += "&locale=" + locale;
                    }
                    else {
                        drugRatingsUrl += "&locale=" + locale.substring(0, 2);
                    }
                    drugRatingsUrl += "&primary_specialty_id=" + primarySpecialtyId;
                    drugRatingsUrl += "&m2_u=" + window.sermo.user.memberId;
                    drugRatingsUrl += "&m2_uhash=" + escape(window.sermo.user.m2h);
                    drugRatingsUrl += "&app=m2";
                    if (window.sermo.user.icdCodes) {
                        let codes = window.sermo.user.icdCodes;
                        if (typeof codes.split !== "undefined") {
                            codes = codes.split(",");
                        }
                        for (let i = 0; i < codes.length; i++) {
                            drugRatingsUrl += "&icd10_codes%5B%5D=" + codes[i];
                        }
                    }
                    openModal({
                        [EUIKey.MODAL_COMPONENT]: <RatingModal url={drugRatingsUrl}/>,
                        [EUIKey.MODAL_LABEL]: "rating-modal",
                        [EUIKey.MODAL_TEXT_LABEL]: "Drug Ratings, by doctors for doctors.",
                    });
                }
            }
        }
    }, []);
    const onClick = () => {
        closeMenu();
    };
    // toggle a class to show/hide input outlines when a user uses the tab button
    // mouse movement removes the outline
    const onMouseMove = () => {
        if ("keyboard" === interaction) {
            setInteraction("mouse");
        }
    };
    const onKeyDown = (e) => {
        if ("mouse" === interaction && "Tab" === e.key) {
            setInteraction("keyboard");
        }
    };
    return (<Styles.LayoutContainer data-component={"LayoutContainer"} $mousedNav={"mouse" === interaction} onClick={onClick.bind(this, menuName)} onMouseMove={onMouseMove} onKeyDown={onKeyDown} $scrollLocked={scrollLocked}>
			{window.sermo.isMobileApp && <PullToRefresh />}
			<Header>
				<TopNav />
				<Banner />
				<div id={"resource-navigation-portal"}></div>
				<DesktopNav />
				<MobileNav />
			</Header>
			<ExpandedPost />
			<MainContent>
				{children}
			</MainContent>
			<Modals />
			<Footer>
				<div id={"sticky-footnote-container"}/>
				<div id={"sticky-isi-container"}/>
				<MobileBottomNav />
			</Footer>
			<MobilePostTypeSelector />
			<BackdropForSidePanels />
			<LeftMobileSidePanel />
			<RightMobileSidePanel />
		</Styles.LayoutContainer>);
};
export default Layout;
