import { ArrowDownSVG, ArrowUpSVG, Collapse, Icon, IconButton } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookOpenCoverRegular from "@assets/images/book-open-cover-regular.svg";
import Bookmark from "@assets/images/bookmark.svg";
import BullseyeArrow from "@assets/images/bullseye-arrow.svg";
import { default as FiltersSVG } from "@assets/images/Filters.svg";
import { capitalizeFirstLetterOfEachWordExceptTheFirstWord, feedFilterOptions, } from "@components/Feed/components/FeedHeader/FeedHeader";
import { OptionContainer, SelectorIcon, SelectorLabel } from "@components/Feed/Feed.styles";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { useToggleBackdrop } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import getTranslation from "@translation/translation";
import * as Styles from "./MobileFeedHeader.styles";
import MobileFilterMenu from "./MobileFilterMenu";
const MobileFeedHeader = ({ filter, filterChangeHandler = () => { }, }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const toggleBackdrop = useToggleBackdrop();
    const { topic } = useParams();
    const trackEvent = useTrackEvent();
    useEffect(() => {
        const clickListener = () => {
            if (open) {
                setOpen(false);
                toggleBackdrop(false);
            }
        };
        if (open) {
            window.addEventListener("click", clickListener);
        }
        return () => {
            window.removeEventListener("click", clickListener);
        };
    }, [open]);
    const renderSelected = useMemo(() => {
        if (topic) {
            const topicLabel = capitalizeFirstLetterOfEachWordExceptTheFirstWord(topic);
            return getTranslation(`system.topics.${topicLabel}`, true);
        }
        return feedFilterOptions.filter((option) => option.value === filter)[0].label;
    }, [topic, filter]);
    const renderSubtitle = useMemo(() => {
        if (topic) {
            return null;
        }
        return feedFilterOptions.filter((option) => option.value === filter)[0].description;
    }, [topic, filter]);
    const selectedIcon = useMemo(() => {
        if (topic) {
            if (topic === "saved") {
                return Bookmark;
            }
            return BookOpenCoverRegular;
        }
        return feedFilterOptions.filter((option) => option.value === filter)[0].icon || BullseyeArrow;
    }, [topic, filter]);
    // only all posts and top posts show the filters
    const shouldShowFilters = useMemo(() => (!topic
        && (filter === "allposts"
            || filter === "topposts")
        && !open), [
        filter,
        open,
        topic,
    ]);
    const onChangeFilter = (option) => {
        filterChangeHandler(option.value);
        setOpen(false);
        toggleBackdrop(false);
        const labels = {
            "allposts": "all-posts",
            "topposts": "top-posts",
        };
        trackEvent({
            category: "post-feed-change-feed",
            action: "click",
            label: labels[option.value],
        });
        if (topic) {
            navigate("/feed/all-sermo");
        }
    };
    const renderOption = (option) => {
        if (!topic
            && option.value === filter) {
            return null;
        }
        return (<OptionContainer data-component={"OptionContainer"} $isSelected={option.value === filter} key={option.value} onClick={() => onChangeFilter(option)}>
				<SelectorIcon src={option.icon} height={24} width={24} $isSelected={!topic && option.value === filter}/>
				<SelectorLabel $isSelected={!topic && option.value === filter}>
					<TextStyled as={"span"} $kind={"heading02"}>
						{option.label}
					</TextStyled>
					<TextStyled as={"span"} $kind={"bodyLong00"}>
						{option.description}
					</TextStyled>
				</SelectorLabel>
			</OptionContainer>);
    };
    const handleClickableClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
        toggleBackdrop(!open);
    };
    return (<MobileAndTabletPortrait>
			<Styles.Container data-component={"Feeds"} $open={open || menuOpen}>
				{menuOpen && (<MobileFilterMenu open={menuOpen} setMenuOpen={setMenuOpen}/>)}
				{!menuOpen && (<>
							<Styles.Content>
								<Styles.Clickable data-component={"Clickable"} onClick={handleClickableClick}>
									<Styles.Selected $kind={"heading02"}>
										<Icon src={selectedIcon} height={24} width={24}/>
										<span data-component={"SelectedValue"}>{renderSelected}</span>
										<Icon src={open
                ? ArrowUpSVG
                : ArrowDownSVG} height={12} width={12}/>
									</Styles.Selected>
									{open
                && (<Styles.SelectedSubtitle $kind={"bodyLong00"}>
												{renderSubtitle}
											</Styles.SelectedSubtitle>)}
								</Styles.Clickable>
								{shouldShowFilters
                && (<IconButton kind="flatNeutral" size="xsmall" icon={FiltersSVG} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMenuOpen(true);
                        toggleBackdrop(true);
                    }}/>)}
							</Styles.Content>
							<Collapse open={open}>
								<Styles.CollapseContent>
									{feedFilterOptions.map(renderOption)}
								</Styles.CollapseContent>
							</Collapse>
						</>)}
			</Styles.Container>
		</MobileAndTabletPortrait>);
};
export default MobileFeedHeader;
