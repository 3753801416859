import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import PhoneNumber from "@components/FormFields/components/PhoneNumber/PhoneNumber";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalText, ModalFooter, ModalSection } from "./../ModalComponents";

const AddPhoneNumber = ({
	cancelEventHandler,
	description,
	newPhoneNumberCompleteEventHandler,
	value,
}) => {
	const apiEndpoint = useApiEndpoint();

	if (!value) {
		value = "";
	}

	const [loading, setLoading] = useState(false);

	const [didClickSubmit, setDidClickSubmit] = useState(false);

	const [shouldValidatePhoneNumber, setShouldValidatePhoneNumber] = useState(false);
	const [phoneNumberValidationErrors, setPhoneNumberValidationErrors] = useState(null);

	const [globalValidationError, setGlobalValidationError] = useState("");

	useEffect(() => {
		if (shouldValidatePhoneNumber) {
			setShouldValidatePhoneNumber(false);
		}
	}, [shouldValidatePhoneNumber]);

	const formSubmitHandler = () => {
		setDidClickSubmit(true);
		setShouldValidatePhoneNumber(true);
	};

	const cancelClickEventHandler = () => {
		cancelEventHandler(true);
	};

	const phoneNumberChangedHandler = (newPhoneNumber, numberType) => {
		if (newPhoneNumber) {
			if (didClickSubmit) {
				setLoading(true);

				const data = {
					[numberType]: newPhoneNumber,
				};

				apiEndpoint(`settings/update${numberType.toLowerCase()}`, data).then((dataResponse) => {
					dataResponse.json().then((body) => {
						setLoading(false);

						if (dataResponse.ok) {
							if (newPhoneNumberCompleteEventHandler) {
								newPhoneNumberCompleteEventHandler(newPhoneNumber);
							}
						} else {
							if (body.message) {
								setGlobalValidationError(body.message);
							}

							if (body.fields) {
								setPhoneNumberValidationErrors(body.fields);
							}
						}
					});
				});
			}
		} else {
			setDidClickSubmit(false);
		}
	};

	return (
		<>
			<ModalSection>
				<ModalText>{description}</ModalText>
			</ModalSection>

			<ModalSection>
				<PhoneNumber
					phoneNumberChangedEventHandler={phoneNumberChangedHandler}
					forceValidation={shouldValidatePhoneNumber}
					formValidationErrors={phoneNumberValidationErrors}
					value={value}
				/>
			</ModalSection>
			<GlobalValidationMessage text={globalValidationError} />
			<ModalFooter
				{...{
					cancelModalEventHandler: cancelClickEventHandler,
					cancelText: getTranslation("frontend.generics.back", true),
					cancelTracking: {
						category: "withdrawal",
						action: "click",
						label: "cancel-add-new-phone-number",
					},
					submitEventHandler: formSubmitHandler,
					submitTracking: {
						category: "withdrawal",
						action: "click",
						label: "submit-add-new-phone-number",
					},
					submitDisabled: loading,
					submitText: getTranslation("frontend.generics.next", true),
				}}
			/>
		</>
	);
};

AddPhoneNumber.propTypes = {
	cancelEventHandler: PropTypes.func,
	description: PropTypes.object,
	newAddressCompleteEventHandler: PropTypes.func,
	value: PropTypes.string,
};

AddPhoneNumber.defaultProps = {
	cancelEventHandler: () => {},
	value: {},
};

export default AddPhoneNumber;
