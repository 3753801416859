import React, { useEffect } from "react";
import { useMemoizedContext } from "@hooks/Hooks";

const ScrollManager = ({ children }) => {
	const { el, setElement } = useMemoizedContext("scroll", ["el"]);

	useEffect(() => {
		if (el) {
			if ("undefined" !== typeof window) {
				try {
					el.scrollIntoView({
						// if the element is larger that the window, scroll to the top
						block: el.getBoundingClientRect().height > window?.innerHeight
							? "start"
							: "center",
						behavior: "smooth",
					});
				}
				catch (err) {
					// If browser doesn't support smooth scroll behavior
					el.scrollIntoView();
				}
			}
			setElement(false);
		}
	}, [el]);

	return <>{children}</>;
}

export default ScrollManager;
