export const DetachedFooterActionTypes = Object.freeze({
    SET_FOOTER_HEIGHT: "SET_FOOTER_HEIGHT",
    SET_FOOTER_OPEN: "SET_FOOTER_OPEN",
    SET_FOOTER_CLOSE: "SET_FOOTER_CLOSE",
    SET_OVERRIDE_OPEN_HEIGHT: "SET_OVERRIDE_OPEN_HEIGHT",
});
const DetachedFooterActionTypesValues = Object.values(DetachedFooterActionTypes);
const DetachedFooterReducer = (state, action) => {
    switch (action.type) {
        case DetachedFooterActionTypes.SET_FOOTER_OPEN:
            return {
                ...state,
                footerOpen: action.payload.type,
            };
        case DetachedFooterActionTypes.SET_FOOTER_CLOSE:
            return {
                ...state,
                footerOpen: null,
            };
        case DetachedFooterActionTypes.SET_FOOTER_HEIGHT:
            const newState = { ...state };
            // FIXME: only one footer height per variant, should we allow multiple heights per label ding a proper cleanup?
            const existingHeight = newState.footerHeights.find(f => (f.label === action.payload.label || f.variant === action.payload.variant));
            if (existingHeight) {
                existingHeight.height = action.payload.height;
            }
            else {
                newState.footerHeights.push({
                    label: action.payload.label,
                    height: action.payload.height,
                    variant: action.payload.variant,
                });
            }
            newState.footerTotalHeight = newState.footerHeights.map(footer => footer.height).reduce((a, b) => a + b, 0);
            return newState;
        case DetachedFooterActionTypes.SET_OVERRIDE_OPEN_HEIGHT:
            return {
                ...state,
                overrideOpenHeight: action.payload.overrideOpenHeight,
            };
        default:
            return state;
    }
};
export default DetachedFooterReducer;
