// @ts-nocheck
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import CommentEditor from "@components/Card/components/CommentEditor/CommentEditor";
import { CustomDropDownList } from "@components/FormFields/FormFields";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { CommentActionTypes, CommentProvider } from "@contexts/Comment";
import { PostDataActionTypes } from "@contexts/PostData";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { CardTypeTypes, PostTypeTypes } from "@frontend/types/Post/postData";
import { DraftStatusTypes } from "@frontend/types/Post/postUI";
import { CommentsSortTypes } from "@frontend/types/Post/postUI";
import { useApiEndpoint, useMemoizedContext, usePrevious } from "@hooks/Hooks";
import { CommentsStylesContainer } from "./Comments.styles";
import Comment from "./components/Comment";
import NoAnswers from "./components/NoAnswers";

const Comments = ({
	isReply,
	replies,
	replyToId,
	totalReplies,
	notificationId,
	initialPageIndex,
	initialNextComments,
}) => {
	const apiEndpoint = useApiEndpoint();
	const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });

	const {
		readOnlyMember,
	} = useMemoizedContext("member", [
		"readOnlyMember",
	]);

	const {
		beingReadAt,
		cardType,
		comments: contextComments,
		dispatch: dispatchPostData,
		id,
		isCommentingDisabled,
		isDraft,
		lastRead: lastReadAt,
		nextComments,
		pageIndex,
		postId,
		subType,
		topLevelCommentsCount,
		type,
	} = useMemoizedContext("postData", [
		"beingReadAt",
		"comments",
		"cardType",
		"id",
		"isCommentingDisabled",
		"isDraft",
		"lastRead",
		"nextComments",
		"pageIndex",
		"postId",
		"subType",
		"topLevelCommentsCount",
		"type",
	]);

	const {
		draftStatus,
	} = useMemoizedContext("postUIViewing", [
		"draftStatus",
	]);
	const editing = DraftStatusTypes.EDITING === draftStatus;

	const {
		commentsClicked,
		commentsSort,
		dispatch: dispatchPostUIViewing,
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"commentsClicked",
		"commentsSort",
		"expanded",
	]);

	const {
		id: commentId,
		dispatch: dispatchComment,
	} = useMemoizedContext("comment", [
		"id",
	]);

	const shouldRenderNewCommentEditor = React.useMemo(() => (
		!isReply
		&& "Comment" !== type
		&& (
			[
				PostTypeTypes.DISCUSSION,
				PostTypeTypes.PATIENT_CASE,
			].includes(type)
			|| (CardTypeTypes.RESOURCE_CENTER_ITEM === cardType)
			|| (CardTypeTypes.SERMO_CONTENT_CARD === cardType)
		)
		&& !readOnlyMember
	), [
		isReply,
		type,
		readOnlyMember,
	]);

	// used for scroll on comments button click
	const useStateRef = (processNode) => {
		const [node, setNode] = useState(null);
		const [nodeField, setNodeField] = useState(null);

		const setRef = useCallback(newNode => {
			setNode(newNode);
			setNodeField(processNode(newNode));
		}, [processNode]);

		return [node, nodeField, setRef];
	}

	const [ commentsNode, childCommentElementCount, setStateRef] = useStateRef(
		node => node?.lastElementChild?.childElementCount
	);

	useEffect(() => {
		if (
			expanded
			&& commentsClicked
			&& commentsNode
			&& childCommentElementCount === topLevelCommentsCount
		) {
			commentsNode.scrollIntoView({ behavior: "smooth" });
			dispatchPostUIViewing({
				type: PostUIViewingActionTypes.SET_COMMENTS_CLICKED_FALSE,
			});
		}
	}, [
		childCommentElementCount,
		commentsClicked,
		commentsNode,
		expanded,
		topLevelCommentsCount,
	]);
	// comments button click ^^^

	const [commentSortOptions, setCommentSortOptions] = useState([
		{
			name: CommentsSortTypes.TOP,
			value: CommentsSortTypes.TOP,
		},
		{
			name: CommentsSortTypes.NEWEST,
			value: CommentsSortTypes.NEWEST,
		},
		{
			name: CommentsSortTypes.OLDEST,
			value: CommentsSortTypes.OLDEST,
		},
	]);

	const handleCommentsSortChange = (value) => {
		dispatchPostUIViewing({
			type: PostUIViewingActionTypes.SET_COMMENTS_SORT,
			payload: {
				commentsSort: value,
			},
		});
	};

	const commentsSortPrevious = usePrevious(commentsSort);
	useEffect(() => {
		if (
			commentsSortPrevious
			&& commentsSort !== commentsSortPrevious
			&& (
				!!beingReadAt
				|| CardTypeTypes.RESOURCE_CENTER_ITEM === cardType
			)
		) {
			apiEndpoint(
				"posts/loadcomments",
				{
					id: id || postId,
					page: 0,
					order: commentsSort,
					lastReadAt,
					beingReadAt,
				},
			).then(response => {
				response.json().then(body => {
					dispatchPostData({
						type: PostDataActionTypes.SET_COMMENTS,
						payload: {
							comments: body.comments,
						},
					});
				});
			});
		}
	}, [commentsSort]);

	// Determine if Unread should be present by comparing the notificationId to the pathname
	const { pathname } = useLocation();
	const [showUnread] = useState(pathname.includes(`${id || postId}/activity/${notificationId}`));

	let canShowCaughtUp = showUnread;

	// Determines the number of next comments after the current page
	const [currentNextComments, setCurrentNextComments] = useState(0);

	// Watch for changes on the number of next comments
	useEffect(() => {
		setCurrentNextComments(initialNextComments ?? nextComments ?? 0);
	}, [initialNextComments]);

	useEffect(() => {
		if (showUnread) {
			setCommentSortOptions([
				{
					name: CommentsSortTypes.UNREAD,
					value: CommentsSortTypes.UNREAD,
				},
				...commentSortOptions
			]);
		}
	}, [showUnread]);

	useEffect(() => {
		if (commentSortOptions.filter(option => option.value === "Unread").length > 0) {
			// Wait for update for some reason
			setTimeout(() => {
				dispatchPostUIViewing({
					type: PostUIViewingActionTypes.SET_COMMENTS_SORT,
					payload: {
						commentsSort: CommentsSortTypes.UNREAD,
					},
				});
			}, 300)
		}
	}, [commentSortOptions])

	// if sorted by unread we reverse displat so remaining comments / "load more button" are loaded above those shown
	const sortReversed = isReply && showUnread;

	// ensures we have calculated everything before we render
	const [initialized, setInitialized] = useState(false);

	// pagination
	const [currentPageIndex, setCurrentPageIndex] = useState(0);

	// total count of comments: local and yet to be fetched
	const getTotalComments = () => isReply
		? totalReplies
		: topLevelCommentsCount;

	const [totalComments, setTotalComments] = useState(getTotalComments);

	useEffect(() => {
		setTotalComments(getTotalComments());
	}, [
		isReply,
		totalReplies,
		topLevelCommentsCount,
	]);

	// if this is comments use them but if its replies use the ones passed in instead
	// some or all of these will be put in comments to render
	// based on if there are any "new" comments
	const localComments = isReply
		? replies
		: contextComments;
	// this is what we actually render
	const [comments, setComments] = useState(
		isReply
			? replies
			: contextComments
	);

	useEffect(() => {
		const newComments = isReply
			? replies
			: contextComments;

		if (newComments.length !== comments.length) {
			setComments(newComments);
		}
	}, [
		contextComments,
		isReply,
		replies,
	]);

	const getRemainder = () => {
		const remainder = totalComments - comments.length
		return remainder >= 0
			? remainder
			: 0;
	}
	// how many we have left to show
	const [remainder, setRemainder] = useState(getRemainder());

	// update remainder when render comments change
	useEffect(() => {
		setRemainder(getRemainder());
	}, [comments, comments?.length]);

	const getShowMoreLabel = () => {
		if (remainder === 1) {
			return isReply
				? "reply"
				: type === "Question"
					? "answer"
					: "comment";
		}
		return isReply
			? "replies"
			: type === "Question"
				? "answers"
				: "comments";
	};

	const [showMoreLabel, setShowMoreLabel] = useState(getShowMoreLabel());

	// TODO: this whole initialization/localComments process is a mess, refactor!
	// determines how many of the comments available on load we should show
	const initialize = () => {
		let lc = localComments;

		// if this is a reply and there are new comments only show those
		if (isReply) {
			const newComments = localComments.filter((lc) => lc.new);

			if (newComments.length) {
				lc = newComments;
			}
		}

		setComments(lc);
		setInitialized(true);
	};

	useEffect(() => {
		setShowMoreLabel(getShowMoreLabel());
	}, [remainder]);

	useEffect(() => {
		initialize();
	}, []);

	// watch for comment changes
	useEffect(() => {
		if (initialized) {
			setComments(isReply
				? replies
				: contextComments);
		}
	}, [
		contextComments,
		replies,
	]);

	// watch for page changes
	useEffect(() => {
		setCurrentPageIndex(initialPageIndex ?? pageIndex ?? 0);
	}, [commentsSort]);

	const [replyToCommentId, setReplyToCommentId] = useState(null);
	const [replyToUser, setReplyToUser] = useState(null);

	const resetReplyState = () => {
		setReplyToCommentId(null);
		setReplyToUser(null);
	};

	useEffect(() => {
		if (
			!!replyToCommentId
			&& !!replyToUser
		) {
			resetReplyState();
		}
	}, [
		replies.length,
	]);

	const parentHandleReplyChange = (id, user) => {
		setReplyToCommentId(id);
		setReplyToUser(user);
	};

	// dont render anything till we init the state
	// not shown on poll questions
	// not shown if disbaled
	if (
		editing
		|| isCommentingDisabled
		|| isDraft
		|| (
			"Question" === type
			&& "Poll" === subType
		)
	) {
		return null;
	}

	const showMore = (e) => {
		if (e) {
			if (e.preventDefault) {
				e.preventDefault();
			}
		}

		// if we have comments we have not shown yet show those first
		if (comments.length < localComments.length) {
			setComments(localComments);
		} else {
			if (isReply) {
				apiEndpoint("posts/loadreplies", {
					id: replyToId,
					order: commentsSort,
					page: currentPageIndex + 1,
					commentId,
					notificationId: notificationId,
					lastReadAt,
					beingReadAt,
				}).then((dataResponse) => {
					dataResponse.json().then((body) => {
						if (dataResponse.ok) {
							setCurrentPageIndex(currentPageIndex + 1);
							setCurrentNextComments(body.nextComments);
							dispatchComment({
								type: CommentActionTypes.LOAD_MORE_REPLIES,
								payload: {
									replies: body.replies,
								},
							});
						}
					});
				});
			} else {
				apiEndpoint("posts/loadcomments", {
					id: id || postId,
					order: commentsSort,
					page: currentPageIndex + 1,
					commentId,
					notificationId: notificationId,
					lastReadAt,
					beingReadAt,
				}).then((dataResponse) => {
					dataResponse.json().then((body) => {
						if (dataResponse.ok) {
							setCurrentPageIndex(currentPageIndex + 1);
							setCurrentNextComments(body.nextComments);
							dispatchPostData({
								type: PostDataActionTypes.LOAD_MORE_COMMENTS,
								payload: {
									comments: body.comments,
								},
							});
						}
					});
				});
			}
		}
	};

	let contextClass = "";
	if (
		expanded
		&& !isReply
	) {
		contextClass = "post-creation-footer";
	}
	else if (isReply) {
		contextClass = "comments-reply";
	}

	const onClickHandler = (e) => {
		e.eventSource = "comments";
	};

	if ("Question" === type && "Poll" !== subType && (!comments || comments.length === 0)) {
		return <NoAnswers />;
	}

	return (
		<ModuleWrapper
			notPaddedY={true}
			notPaddedX={isReply && isMobileOrTabletPortrait}
			paddedWidest={expanded && !isReply && CardTypeTypes.RESOURCE_CENTER_ITEM !== cardType}
			paddedWider={CardTypeTypes.RESOURCE_CENTER_ITEM === cardType}
			contextClass={contextClass}
		>
			<ModuleWrapper
				contextClass={
					isReply
						? "comment-reply"
						: null
				}
				padded={false}
			>
				<CommentsStylesContainer
					id={
						expanded
							? "comments-expanded"
							: "comments"
					} // for scrollIntoView() use in CardFooter
					ref={setStateRef}
					onClick={onClickHandler}
					className={
						classNames("module-container", {
							"module-expanded": expanded,
							"context-question": type === "Question",
							"reversed": sortReversed,
						})
					}
				>
					{
						shouldRenderNewCommentEditor
						&& (
							<CommentProvider>
								<CommentEditor />
							</CommentProvider>
						)
					}
					{
						!isReply
						&& CardTypeTypes.COMMENT_GROUP !== cardType
						&& PostTypeTypes.COMMENT !== type
						&& comments.length > 0
						&& (
							<div className={classNames("sort", { "underlined": type !== "Question" })}>
								{/* TODO: In the near future, replace CustomDropDownList with <Select /> component */}
								<CustomDropDownList
									contextClass="flat-padded"
									handleChange={handleCommentsSortChange}
									iconSize={8}
									name={`comments-sort-${id || postId}`}
									options={commentSortOptions}
									size="inline"
									style="inline"
									value={commentsSort}
								/>
							</div>
						)
					}
					<div className={classNames("comments-container", { "replies": isReply })}>
						{
							comments.map((commentObj, i) => {
								if (isReply) {
									commentObj.parent = commentObj.id;
								}

								let showCaughtUp = false;

								// check canShowCaughtUp first because if there is no
								// notificationId none of this needs to run;
								if (canShowCaughtUp) {
									const hasNewReplies = commentObj.replies.some((r) => r.new);

									if (isReply) {
										canShowCaughtUp = false;
									}

									// check canShowCaughtUp again here as it may have just changed
									if (canShowCaughtUp && !commentObj.new) {
										if (!hasNewReplies) {
											canShowCaughtUp = false;
											showCaughtUp = true;
										}
									}
								}

								return (
									<>
										<Comment
											comment={commentObj}
											index={i}
											key={i}
											parentHandleReplyChange={parentHandleReplyChange}
											showCaughtUp={showCaughtUp}
										/>
										{
											commentObj.id === replyToCommentId
											&& !!replyToUser
											&& (
												<CommentProvider comment={{ parentCommentId: commentId }}>
													<CommentEditor
														cancel={resetReplyState}
														key={i}
														replyToUser={replyToUser}
													/>
												</CommentProvider>
											)
										}
									</>
								);
							})
						}
					</div>
					{
						currentNextComments > 0 && (
							<div className={classNames("row")}>
								<a
									href="#"
									onClick={showMore}
									className={classNames("body-short-brand-00", "view-more")}
								>
									{
										`View ${currentNextComments} ${sortReversed
											? "older"
											: "more"} ${showMoreLabel}`
									}
								</a>
							</div>
						)
					}
				</CommentsStylesContainer>
			</ModuleWrapper>
		</ModuleWrapper>
	);
};

Comments.propTypes = {
	isReply: PropTypes.bool,
	replies: PropTypes.array,
	replyToId: PropTypes.number,
	totalReplies: PropTypes.number,
	notificationId: PropTypes.number,
};

Comments.defaultProps = {
	replies: [],
	isReply: false,
};

export default Comments;
