import { useMatch } from "react-router-dom";
import { PollActionTypes } from "@contexts/Poll";
import { PollQuestionActionTypes } from "@contexts/PollQuestion";
import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { useSaving } from "@contexts/PostUI/Editing";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
const usePollAPI = () => {
    const apiEndpoint = useApiEndpoint();
    const isAdPreview = !!useMatch("/ad-preview/*");
    const isPreviewRoute = !!useMatch("/preview/*");
    const isPreview = isAdPreview || isPreviewRoute;
    const save = useSaving();
    const { dispatch: dispatchPostData, id: postId, } = useMemoizedContext("postData", [
        "id",
    ]);
    const { dispatch: dispatchPoll, id: pollId, questions, } = useMemoizedContext("poll", [
        "id",
        "questions",
    ]);
    const { dispatch: dispatchPollQuestion, id: pollQuestionId, } = useMemoizedContext("pollQuestion", [
        "id",
    ]);
    const addAnswer = () => {
        const endpoint = "posts/addAnswer";
        const data = {
            questionId: pollQuestionId,
            answer: "",
        };
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
            if (response.ok) {
                response.json().then((data) => {
                    dispatchPollQuestion({
                        type: PollQuestionActionTypes.ADD_ANSWER,
                        payload: {
                            answer: data,
                        },
                    });
                });
            }
        });
    };
    const addPoll = () => {
        const endpoint = "posts/addQuestion";
        const data = {
            postId,
            text: "",
            isMultipleChoice: false,
        };
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
            if (response.ok) {
                response.json().then((data) => {
                    dispatchPostData({
                        type: PostDataActionTypes.ADD_POLL,
                        payload: {
                            question: data,
                        },
                    });
                });
            }
        });
    };
    const addQuestion = () => {
        const endpoint = "posts/addQuestion";
        const data = {
            postId,
            text: "",
            isMultipleChoice: false,
        };
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
            if (response.ok) {
                response.json().then((data) => {
                    dispatchPoll({
                        type: PollActionTypes.ADD_QUESTION,
                        payload: {
                            question: data,
                        },
                    });
                });
            }
        });
    };
    const deleteAnswer = ({ answerId }) => {
        const endpoint = "posts/deleteAnswer";
        const data = {
            id: answerId,
        };
        save.start(endpoint);
        dispatchPollQuestion({
            type: PollQuestionActionTypes.DELETE_ANSWER,
            payload: {
                answerId,
            },
        });
        apiEndpoint(endpoint, data, false).then(() => {
            save.stop(endpoint);
        });
    };
    const deletePoll = () => {
        questions?.forEach((question) => {
            deleteQuestion({ questionId: question.id });
        });
        const endpoint = "posts/deletePoll";
        const data = {
            id: pollId,
        };
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
        });
        dispatchPostData({
            type: PostDataActionTypes.DELETE_POLL,
        });
    };
    const deleteQuestion = ({ questionId }) => {
        const endpoint = "posts/deleteQuestion";
        const data = {
            id: questionId,
        };
        save.start(endpoint);
        dispatchPoll({
            type: PollActionTypes.DELETE_QUESTION,
            payload: {
                questionId,
            },
        });
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
        });
    };
    const updatePollDuration = ({ duration }) => {
        const endpoint = "posts/updatePoll";
        const data = {
            id: pollId,
            duration,
        };
        dispatchPoll({
            type: PollActionTypes.SET_DURATION,
            payload: {
                duration,
            },
        });
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
        });
    };
    const updateQuestionIsMultipleChoice = ({ id, isMultipleChoice }) => {
        const endpoint = "posts/updateQuestion";
        const data = {
            id,
            isMultipleChoice,
        };
        const type = isMultipleChoice
            ? PollQuestionActionTypes.SET_MULTIPLE_CHOICE
            : PollQuestionActionTypes.SET_SINGLE_CHOICE;
        dispatchPollQuestion && dispatchPollQuestion({
            type,
        });
        save.start(endpoint);
        apiEndpoint(endpoint, data, false).then((response) => {
            save.stop(endpoint);
        });
    };
    // TODO: could make an argument that this belongs in different file since the rest of the methods are for editing
    const vote = ({ questionId, answers }) => {
        const endpoint = "posts/votePoll";
        const data = {
            questionId,
            answers,
        };
        dispatchPollQuestion && dispatchPollQuestion({
            type: PollQuestionActionTypes.VOTE_QUESTION,
            payload: {
                answers,
            },
        });
        if (!isPreview) {
            save.start(endpoint);
            apiEndpoint(endpoint, data, false).then((response) => {
                save.stop(endpoint);
            });
        }
    };
    return {
        addAnswer,
        addPoll,
        addQuestion,
        deleteAnswer,
        deletePoll,
        deleteQuestion,
        updatePollDuration,
        updateQuestionIsMultipleChoice,
        vote,
    };
};
export default usePollAPI;
