import { ColorGrey60 } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
export const TitleText = styled(TextStyled).attrs({ $kind: "heading03" }) `
	letter-spacing: -0.32px;
`;
export const BodyText = styled(TextStyled).attrs({ $kind: "bodyLong02" }) `
`;
export const BodyDateText = styled(TextStyled).attrs({ $kind: "bodyShort02" }) `
	margin-top: 8px;
`;
export const LinkLearnMore = styled(TextStyled).attrs({ $kind: "bodyShort01" }) `
	& > a {
		color: ${ColorGrey60};
		:hover {
			text-decoration: none;
		}
	}
`;
export const FooterWrap = styled(TextStyled).attrs({ $kind: "bodyLong02" }) `
	margin-top: 24px;
	display: flex;
	gap: 8px;
	justify-content: flex-end;

	${LinkLearnMore} {
		align-self: center;
		margin: auto auto auto 0;

		${StyledMobileAndTabletPortraitQuery} {
			margin-left: auto;
		}
	}

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column-reverse;
	}

	& > ${BodyText} {
        padding: 0 16px 0 0;

		${StyledMobileAndTabletPortraitQuery} {
            padding: 0;
        }
    }

	& > a {
		${StyledMobileAndTabletPortraitQuery} {
            margin-top: 24px;
			align-self: flex-end;
        }
    }
`;
export const ModalWrap = styled.div `
	max-width: 512px;

	${TitleText} {
		margin-top: 16px;
	}

	& > ${BodyText} {
		margin-top: 16px;
	}
`;
