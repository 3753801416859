import { Icon, IndicationSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import typography from "../../scss/typography.scss";
import styles from "./DrugCard.scss";

const DrugCard = ({ drugRating }) => {
	const { genericName, name, drugBrandId } = drugRating;

	const [redirect, setRedirect] = useState(false);

	const onClick = () => {
		setRedirect(`/rate/${drugBrandId}`);
	};

	return (
		<div
			styleName="styles.drug"
			onClick={onClick}
		>
			{redirect && <Navigate to={redirect} />}
			<ModuleWrapper solid={true}>
				<div styleName="styles.drug-wrapper">
					<div styleName="styles.left">
						<div styleName="styles.pic-wrapper">
							<Icon
								src={IndicationSVG}
								width={40}
								height={40}
							/>
						</div>
						<div styleName="styles.name-and-generic">
							<div styleName={classNames("styles.generic-name", "typography.heading-brand-02")}>
								{genericName}
							</div>
							<div styleName={classNames("styles.name", "typography.body-short-brand-00")}>{name}</div>
						</div>
					</div>
				</div>
			</ModuleWrapper>
		</div>
	);
};

DrugCard.propTypes = {
	drugRating: PropTypes.shape({
		genericName: PropTypes.string,
		name: PropTypes.string,
		drugBrandId: PropTypes.number,
	}),
};

export default DrugCard;
