import { ColorGrey100 } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { ExpandedSpacingContainer } from "@components/PostViewer/PostViewer.styles";
export const SpacingContainer = styled(ExpandedSpacingContainer) `
	margin-top: 16px;
`;
export const PollDisplayContainer = styled.div `
	&& label {
		color: ${ColorGrey100};
	}
`;
export const ContentContainer = styled.div `
	&& {
		margin-top: 24px;
		margin-bottom: 24px;
	}
`;
export const Content = styled(TextStyled) `
	display: inline;

	& > p:last-child {
		display: inline;
    }

	p,
	ul {
		margin-bottom: 1em;
	}
`;
export const CommentContainer = styled.div `
	&&& > div {
		padding-top: 0;

		${StyledMobileAndTabletPortraitQuery} {
			padding-top: 4px;
		}
	}
`;
export const ReadMore = styled(TextStyled) `
	color: ${({ theme }) => theme.color.interactive01};
	cursor: pointer;
	display: inline;
	margin-left: 8px;
	font-weight: 500;
`;
