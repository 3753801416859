import React, { useReducer } from "react";
import DetachedFooterContext, { DetachedFooterDefaultValues } from "@contexts/DetachedFooter/DetachedFooterContext";
import DetachedFooterReducer, { DetachedFooterActionTypes } from "@contexts/DetachedFooter/DetachedFooterReducer";
const DetachedFooterContextProvider = ({ children, initialState, }) => {
    const initializeState = React.useCallback((init) => {
        return {
            ...DetachedFooterDefaultValues,
            ...init,
        };
    }, []);
    const [state, dispatch] = useReducer(DetachedFooterReducer, initialState, initializeState);
    const setFooterHeight = (height, label, variant) => {
        dispatch({
            type: DetachedFooterActionTypes.SET_FOOTER_HEIGHT,
            payload: {
                height,
                label,
                variant,
            },
        });
    };
    const setFooterOpen = (type) => {
        dispatch({
            type: DetachedFooterActionTypes.SET_FOOTER_OPEN,
            payload: { type },
        });
    };
    const setFooterClose = () => {
        dispatch({
            type: DetachedFooterActionTypes.SET_FOOTER_CLOSE,
        });
    };
    const value = {
        ...state,
        dispatch,
        setFooterHeight,
        setFooterOpen,
        setFooterClose,
    };
    return (<DetachedFooterContext.Provider value={value}>
			{children}
		</DetachedFooterContext.Provider>);
};
export default DetachedFooterContextProvider;
