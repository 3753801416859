import React from "react";
import PostUIEditingContext, { PostUIEditingDefaultValues } from "@contexts/PostUI/Editing/PostUIEditingContext";
import PostUIEditingReducer from "@contexts/PostUI/Editing/PostUIEditingReducer";
const PostUIEditingContextProvider = ({ children, initialState = {} }) => {
    const initializeState = React.useCallback((init) => {
        return {
            ...PostUIEditingDefaultValues,
            ...init,
        };
    }, []);
    const [state, dispatch] = React.useReducer(PostUIEditingReducer, initialState, initializeState);
    const value = {
        ...state,
        dispatch,
    };
    return (<PostUIEditingContext.Provider value={value}>
			{children}
		</PostUIEditingContext.Provider>);
};
export default PostUIEditingContextProvider;
