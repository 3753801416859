import { PopupMenu } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useState } from "react";
import { EUIKey, useOpenAlert } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";
import modalStyles from "../Modal/Modal.scss";

const MiniProfileMenu = ({ user, memberBlock, triggerRef }) => {
	const apiEndpoint = useApiEndpoint();
	const openAlert = useOpenAlert();
	const trackEvent = useTrackEvent();
	const [blocked, setBlocked] = useState(user.blocked);

	const toggleBlockMemberClickEventHandler = () => {
		if (blocked) {
			trackEvent({
				category: "mini-profile",
				action: "click",
				label: "unblock-member",
			});
		} else {
			trackEvent({
				category: "mini-profile",
				action: "click",
				label: "block-member",
			});
		}

		// updates blocked status on a post
		if (memberBlock) {
			memberBlock(user.id);
		}

		setBlocked(!blocked);

		apiEndpoint(`members/${blocked
			? "unblock"
			: "block"}member`, { memberId: user.id });

		const text = blocked
			? getTranslation("frontend.alerts.memberUnblocked")
			: getTranslation("frontend.alerts.memberBlocked");

		openAlert({
			[EUIKey.ALERT_COMPONENT]: <div styleName="modalStyles.alert-text">{text}</div>,
			[EUIKey.ALERT_LABEL]: "member-blocked",
		});
	};

	return (
		<PopupMenu
			triggerRef={triggerRef}
			placement="bottom-end"
			position="fixed"
			items={
				[
					{
						label: blocked
							? getTranslation("frontend.miniProfileMenu.unblockUser")
							: getTranslation("frontend.miniProfileMenu.blockUser"),
						onClick: toggleBlockMemberClickEventHandler
					}
				]
			}
		/>
	);
}

MiniProfileMenu.propTypes = {
	user: PropTypes.shape(UserProps),
	memberBlock: PropTypes.bool,
};

MiniProfileMenu.defaultProps = {
	memberBlock: false,
};

export default MiniProfileMenu;
