import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Media from "@components/Card/components/Media/Media";
import PollDisplay from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import ResourceContentInFeed from "@components/PostContent/components/ResourceContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardHeaderCustom from "@components/PostViewer/components/CardHeaderCustom";
import ResourceInFeedMenu from "@components/PostViewer/components/CardHeaderCustom/components/ResourceInFeedMenu";
import ResourceInFeedTitle from "@components/PostViewer/components/CardHeaderCustom/components/ResourceInFeedTitle";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
const ResourceItemInFeed = () => {
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();
    const { resourceCenterItemId, } = useMemoizedContext("postData", [
        "resourceCenterItemId",
    ]);
    useEffect(() => {
        trackEvent({
            category: "resourceitem",
            label: "content",
            action: "view",
            area: "in-feed",
        });
    }, [
        trackEvent,
    ]);
    const handleCardClick = () => {
        const url = `/resource/item/${resourceCenterItemId}`;
        trackEvent({
            category: "resourceitem",
            label: "expand",
            action: "click",
            area: url,
            url,
        });
        navigate(url);
    };
    return (<>
			<CardContainer onClick={handleCardClick}>
				<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
					<SharedStyles.SpacingContainer>
						<CardHeaderCustom main={<ResourceInFeedTitle />} menu={ResourceInFeedMenu}/>
						<CardHeader />
						<div>
							<Title />
							<ResourceContentInFeed />
						</div>
						<Media />
						<PollDisplay />
					</SharedStyles.SpacingContainer>
				</ModuleWrapper>
				<CardFooter />
			</CardContainer>
		</>);
};
export default ResourceItemInFeed;
