import { PostDataActionTypes } from "@contexts/PostData/PostDataReducer";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
const usePostDataAPI = () => {
    const apiEndpoint = useApiEndpoint();
    const { dispatch: dispatchPostData, id: postId, } = useMemoizedContext("postData", [
        "id",
    ]);
    const selectTopic = (topic) => {
        const endpoint = "posts/selectTopic";
        const requestBody = {
            postId,
            topicId: topic.id,
        };
        apiEndpoint(endpoint, requestBody)
            .then((response) => {
            if (response.ok) {
                dispatchPostData({
                    type: PostDataActionTypes.SET_TOPIC,
                    payload: {
                        topic,
                    },
                });
            }
        });
    };
    return {
        selectTopic,
    };
};
export default usePostDataAPI;
