import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SponsoredVideoPlayer from "@components/Card/components/VideoPlayer/SponsoredVideoPlayer";

const InlineVideoPLayer = ({ el }) => {
	const { dataset } = el;

	// clearing element contents before createPortal.
	const [innerHtmlEmptied, setInnerHtmlEmptied] = useState(false);
	useEffect(() => {
		if (!innerHtmlEmptied) {
			el.innerHTML = "";
			setInnerHtmlEmptied(true)
		}
	}, [innerHtmlEmptied])

	const getAttributeAsBoolean = (attributeName) => dataset[attributeName] === "True"

	if (!innerHtmlEmptied) {
		return null;
	}

	return ReactDOM.createPortal(
		<SponsoredVideoPlayer
			featured={
				{
					autoplay: getAttributeAsBoolean("autoplay"),
					autoplayWhenExpanded: getAttributeAsBoolean("autoplaywhenexpanded"),
					autoplayWithSound: getAttributeAsBoolean("autoplaywithsound"),
					captionsUrl: dataset?.captionsurl,
					disableFullscreen: getAttributeAsBoolean("disablefullscreen"),
					disablePlaybackSpeed: getAttributeAsBoolean("disableplaybackspeed"),
					disableQuality: getAttributeAsBoolean("disablequality"),
					disableRewindAndForward: getAttributeAsBoolean("disablerewindandforward"),
					loop: getAttributeAsBoolean("loop"),
					thumbnail: dataset?.thumbnail,
					url: dataset?.url,
				}
			}
			trackingArea={"inline-video"}
		/>,
		el
	);
}

export default InlineVideoPLayer;