import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import { getUrlParameter } from "../../Utils";
import LeadOutcome from "../LeadOutcome/LeadOutcome";
import "./LeadOutcomeLayout.scss";

const LeadOutcomeLayout = () => {
	const { showLeadLandingPage } = useMemoizedContext("member", ["showLeadLandingPage"]);

	const returnUrl = getUrlParameter("returnUrl");

	if (!showLeadLandingPage) {
		if (returnUrl) {
			if ("undefined" !== typeof window) {
				window.location = returnUrl;
				return;
			}
		}

		return <Navigate
			to="/feed"
			replace
		/>;
	}

	if ("undefined" !== typeof window) {
		return (
			<div styleName="lead-outcome-layout">
				<Routes>
					<Route
						exact
						path="/leadoutcome"
						element={<LeadOutcome />}
					/>
					<Route
						path="*"
						element={
							<Navigate
								to={
									{
										...window.location,
										pathname: "/leadoutcome",
										search: "returnUrl=" + escape(window.location.href),
									}
								}
							/>
						}
					/>
				</Routes>
			</div>
		);
	}

	return (
		<div>
			<div styleName="lead-outcome-layout">
				<Routes>
					<Route
						exact
						path="/leadoutcome"
						element={<LeadOutcome />}
					/>
					<Route
						path="*"
						element={<Navigate to="leadoutcome" />}
					/>
				</Routes>
			</div>
		</div>
	);
};

export default LeadOutcomeLayout;
