import classNames from "classnames";
import React from "react";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useMemoizedContext } from "@hooks/Hooks";
import { useFetch } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import { Loading } from "../../../Helpers/Helpers";
import surveys from "../../Surveys.scss";
import styles from "./WithdrawalHistory.scss";
import WithdrawalHistoryCard from "./WithdrawalHistoryCard";

const WithdrawalHistory = () => {
	const { 
		hasAccess, 
	} = useMemoizedContext("member", [
		"hasAccess",
	]);

	if (!hasAccess("canAccessFrontendSurveys")){
		return (<span>&nbsp;</span>);
	}

	return <WithdrawalHistoryWrapped />
}

const WithdrawalHistoryWrapped = () => {
	const { 
		locale, 
	} = useMemoizedContext("member", [
		"locale",
	]);

	const [data, loading, error] = useFetch("api/surveys/getwithdrawalhistory");

	if ((!data || loading) && !error) {
		return (
			<div styleName={classNames(["styles.withdrawal-history"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<Loading contextClass="surveys" />
				</ModuleWrapper>
			</div>
		);
	}
 
	if (error) {
		return (
			<div styleName={classNames(["styles.withdrawal-history"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<div styleName={classNames(["styles.survey-description", "typography.body-short-brand-02"])}>
						{getTranslation("frontend.surveys.surveyHistory.noWithdrawalsAvailable")}
					</div>
				</ModuleWrapper>
			</div>
		);
	}

	return (
		<div styleName="styles.withdrawal-history">
			<ModuleWrapper
				border={true}
				solid={true}
			>

				<TabletLandscapeAndDesktop>
					<div styleName="typography.heading-brand-03">
						{getTranslation("frontend.surveys.withdrawalHistory.title", true)}
					</div>

					{
						data?.withdrawals !== undefined && data.withdrawals.length > 0 && (
							<table styleName={classNames("surveys.table")}>
								<thead>
									<tr>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-120",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderDate", true)}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-120",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderAmount", true)}
										</th>
										<th styleName={classNames(["surveys.table-header", "typography.heading-brand-00"])}>
											{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderPayment", true)}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-140",
													"typography.heading-brand-00",
												])
											}
										>
											{
												getTranslation(
													"frontend.surveys.withdrawalHistory.tableHeaderConfirmation",
													true
												)
											}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-140",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderStatus", true)}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-90",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.withdrawalHistory.tableHeaderBalance", true)}
										</th>
									</tr>
								</thead>
								<tbody>
									{
										data.withdrawals.map((withdrawal, index) => {
											let date = new Date(Date.parse(withdrawal.withdrawalDate));
											return (
												<tr key={index}>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{
															date.toLocaleDateString(locale, {
																year: "numeric",
																month: "numeric",
																day: "numeric",
															})
														}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{
															getCurrencyTranslation(
																withdrawal.withdrawalAmount,
																withdrawal.currency
															)
														}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{getTranslation(withdrawal.activity)}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{withdrawal.confirmationCode}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
																{
																	"surveys.highlight-status": withdrawal.shouldHighlightStatus,
																},
															])
														}
													>
														{withdrawal.status}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{getCurrencyTranslation(withdrawal.balanceAmount, withdrawal.currency)}
													</td>
												</tr>
											);
										})
									}
								</tbody>
							</table>
						)
					}
				</TabletLandscapeAndDesktop>

				<MobileAndTabletPortrait>
					{
						data?.withdrawals !== undefined && data.withdrawals.length > 0 && (
							<>
								{
									data.withdrawals.map((withdrawal, index) => (
										<WithdrawalHistoryCard
											key={index}
											locale={locale}
											index={index}
											withdrawalDate={withdrawal.withdrawalDate}
											activity={withdrawal.activity}
											currency={withdrawal.currency}
											withdrawalAmount={withdrawal.withdrawalAmount}
											status={withdrawal.status}
											shouldHighlightStatus={withdrawal.shouldHighlightStatus}
											confirmationCode={withdrawal.confirmationCode}
											balanceAmount={withdrawal.balanceAmount}
										/>
									))
								}
							</>
						)
					}
				</MobileAndTabletPortrait>

				{
					(data?.withdrawals === undefined || data.withdrawals.length === 0) && (
						<div styleName="typography.body-short-brand-02">
							{getTranslation("frontend.surveys.surveyHistory.noWithdrawalsAvailable")}
						</div>
					)
				}
			</ModuleWrapper>
		</div>
	);
};

export default WithdrawalHistory;
