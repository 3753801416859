import PropTypes from "prop-types";
import React, { useState } from "react";
import { TextField, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { apiEndpoint } from "../../../Utils";
import { ModalContainer, ModalSection, ModalText, ModalFooter } from "./ModalComponents";

const SetNewUsernameModal = ({ closeModalEventHandler, newUsernameSubmittedSuccessfullyEventHandler }) => {
	const [loading, setLoading] = useState(false);
	const memberContext = useMemoizedContext("member", ["username"]);

	const [newUsername, setNewUsername] = useState(memberContext.username);
	const [newUsernameValidationError, setNewUsernameValidationError] = useState("");
	const [globalValidationError, setGlobalValidationError] = useState("");

	const newUsernameChangeHandler = (event) => {
		setNewUsername(event.target.value);
	};

	const validateForm = () => {
		let valid = true;

		if (newUsername === "") {
			valid = false;
			setNewUsernameValidationError(
				getTranslation("system.validationMessages.validationUsernameIsRequired", true)
			);
		}

		return valid;
	};

	const submitFormClickHandler = () => {
		if (validateForm()) {
			setLoading(true);

			const data = {
				username: newUsername,
			};

			apiEndpoint(`/${memberContext.locale}/api/settings/updateusername`, data).then((dataResponse) => {
				dataResponse.json().then((body) => {
					setLoading(false);

					if (dataResponse.ok) {
						memberContext.updateMember({ username: newUsername });

						if (newUsernameSubmittedSuccessfullyEventHandler) {
							newUsernameSubmittedSuccessfullyEventHandler(newUsername);
						} else {
							closeModalEventHandler();
						}
					} else {
						setGlobalValidationError(body.message);

						if (body.fields !== null) {
							for (var property in body.fields) {
								switch (property) {
									case "username":
									case "newUsername":
										setNewUsernameValidationError(body.fields[property].join("<br />"));
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const closeModalClickHandler = () => {
		closeModalEventHandler(true);
	};

	return (
		<ModalContainer>
			<ModalText>{getTranslation("frontend.modals.setNewUsername.description")}</ModalText>

			<ModalSection>
				<TextField
					name="newUsername"
					type="text"
					autoComplete="new-username"
					label={getTranslation("frontend.modals.setNewUsername.inputFieldLabelUsername", true)}
					validationError={newUsernameValidationError}
					handleChange={newUsernameChangeHandler}
					value={newUsername}
					disabled={loading}
					autoFocus={true}
				/>
			</ModalSection>

			<GlobalValidationMessage text={globalValidationError} />

			<ModalFooter
				{...{
					cancelModalEventHandler: closeModalClickHandler,
					submitEventHandler: submitFormClickHandler,
					submitDisabled: loading,
				}}
			/>
		</ModalContainer>
	);
};

SetNewUsernameModal.propTypes = {
	newUsernameSubmittedSuccessfullyEventHandler: PropTypes.func,
	closeModalEventHandler: PropTypes.func,
};

SetNewUsernameModal.defaultProps = {
	closeModalEventHandler: () => {},
};

export default SetNewUsernameModal;
