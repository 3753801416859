import { useMatch } from "react-router-dom";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useTrackEvent } from "@frontend/tracking/tracking";

const useRegisterTrack = () => {
	const { isPostSurveyReferral } = useMemoizedContext("member",[ "isPostSurveyReferral"]);
	const isRegister = !!useMatch("/register/*");
	const trackEvent = useTrackEvent();

	return (obj, event = false) =>{
		const { 
			action,
			label,
			psrLabel,
			value,
			category,
			area,
		} = obj;
		
		const trackingObject = {
			action,
			value,
		}

		if ( event ) {
			trackEvent({
				category: "events",
				label,
				action,
				area,
				eventToken: window.sermo?.temp?.eventToken,
				eventRegistrantToken: window.sermo?.temp?.eventRegistrantToken,
			});
		} else {
			if (isRegister) {
				trackEvent({
					...trackingObject,
					category: category
						? category
						: "registration-process",
					eventToken: window.sermo?.temp?.eventToken,
					eventRegistrantToken: window.sermo?.temp?.eventRegistrantToken,
					label,
				});
			}
			if ( isPostSurveyReferral ) {
				trackEvent({
					...trackingObject,
					category: category
						? category
						: "psr",
					label: psrLabel
						? psrLabel
						: label,
				});
			}
		}
	}
};

export default useRegisterTrack;