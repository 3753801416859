import { ColorGrey10, ColorGrey30, ColorGrey60, ColorGrey80, ColorWhite } from "@sermo/design-tokens";
import { Icon } from "@sermo/ui-components";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const FeedContainer = styled.div.attrs({ "data-component": "Content" }) `
	${StyledTabletLandscapeAndDesktopQuery} {
		width: var(--cards-width);
		min-width: var(--cards-width); // for ad-preview
		padding-top: var(--cards-spacing);
	}
`;
export const FeedHeaderContainer = styled.div `
	display: flex;
	height: 40px;
	padding: 8px 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	background-color: ${ColorWhite};
	border: 1px solid ${ColorGrey10};
	border-radius: var(--module-wrapper-border-radius);

	${StyledTabletLandscapeAndDesktopQuery} {
		margin-bottom: var(--top-nav-padding);
	}

	${StyledMobileAndTabletPortraitQuery} {
		height: unset;
		min-height: 48px;
		border-radius: 0;
		justify-content: center;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);

		.buttons {
			flex-basis: 100%;

			& > span {
				width: 50%;
			}
		}
	}
`;
export const FeedProfileHeaderContainer = styled.div `
	padding-right: var(--cards-spacing);
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: end;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;

		.buttons {
			flex-basis: 100%;

			& > span {
				width: 50%;
			}
		}
	}
`;
export const DropdownsContainer = styled.div `
	display: flex;
	flex-basis: initial;

	& > * {
		margin-left: 20px;
	}

	${StyledMobileAndTabletPortraitQuery} {
		align-items: center;

		& > *:not(:first-child) {
			margin-left: 8px;
		}

		& > [data-component="select"] [data-component="options"] {
			width: auto;
		}
	}
`;
export const FeedHeaderContent = styled.div `
	display: flex;
	flex: 1;
	padding: 0 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
`;
export const SelectContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	height: 24px;
	> div > div > div > span {
		// Override with heading-2 the default font style from component
		color: ${ColorGrey80};
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: -0.28px;
	}
	button {
		background-color: transparent;
		span {
			color: ${ColorGrey60};
		}
	}
	div[data-popper-placement] {
		// Fine tune the position of the dropdown according Figma design
		width: 250px;
		margin-left: -32px;
		margin-top: -8px;
		button {
			gap: 8px;
		}
	}
`;
export const CustomizeContainer = styled.div `
	display: flex;
	align-items: center;
	> * {
		color: ${ColorGrey60};
		cursor: pointer;
	}
`;
export const SelectorIcon = styled(Icon) `
	margin-left: 4px;
	align-self: flex-start;
	${({ $isSelected }) => !$isSelected && `
		path {
			fill: ${ColorGrey30};
		}
	`};

	${StyledMobileAndTabletPortraitQuery} {
		margin-left: 0;
	}
`;
export const SelectorLabel = styled.span `
	&& span {
		color: ${({ $isSelected }) => $isSelected
    ? ColorGrey80
    : ColorGrey60};
	}
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	text-align: left;
`;
export const OptionContainer = styled.span `
	display: flex;
	width: 100%;
	gap: 8px;
	margin: -4px -8px;
	.description {
		display: ${({ $isSelected }) => $isSelected
    ? "inline"
    : "none"};
	}
	&:hover .description {
		display: inline;
	}

	${StyledMobileAndTabletPortraitQuery} {
		margin: 0;
		cursor: pointer;
		display: flex;
		align-items: center;

		.description {
			display: inline;
		}
	}
`;
