// a test to ensure a string isn't empty or just white space
import { PostTypeTypes } from "@frontend/types/Post/postData";
const notEmptyString = val => val !== "" && val !== "<p></p>" && /\S/.test(val);
const validTopicId = val => !!val && typeof val === "number";
const pollTest = (poll, post) => {
    let passing = true;
    if ("Question" === post.type && "Poll" !== post.subType) {
        return true;
    }
    if (poll?.questions.length < 1) {
        passing = true;
    }
    poll?.questions.forEach(question => {
        if (!question.question
            && PostTypeTypes.QUESTION !== post.type) {
            passing = false;
        }
        if (question.answers.length < 2) {
            passing = false;
        }
        question.answers.forEach(answer => {
            if (!answer.answer) {
                passing = false;
            }
        });
    });
    return passing;
};
const subtypeRequirements = (val, post) => {
    if ("Poll" === val) {
        return !!post.poll && post.poll.questions.length > 0;
    }
    return true;
};
const isSaving = (val, post) => !post.saving;
// things all post types need to publish
const universalRequirements = [
    {
        prop: "contentHtml",
        test: notEmptyString,
    },
    {
        prop: "poll",
        test: pollTest,
    },
    {
        prop: "saving",
        test: isSaving,
    },
    {
        prop: "topicId",
        test: validTopicId,
    },
];
// tests for each post type to be publishable
const requirements = {
    Question: [
        ...universalRequirements,
        {
            prop: "subType",
            test: subtypeRequirements,
        },
    ],
    Discussion: [
        ...universalRequirements,
        {
            prop: "title",
            test: notEmptyString,
        },
    ],
    PatientCase: [
        ...universalRequirements,
        {
            prop: "title",
            test: notEmptyString,
        },
    ],
};
export { requirements };
