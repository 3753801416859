import { useMatch } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./MainContent.styles";
const MainContent = ({ children, }) => {
    const isRunWithPeter = !!useMatch("/runwithpeter/*");
    const applyContentStyles = !isRunWithPeter;
    const { loggedIn, } = useMemoizedContext("member", [
        "loggedIn",
    ]);
    const { footerHeight, headerHeight, } = useMemoizedContext("ui", [
        "footerHeight",
        "headerHeight",
    ]);
    return (<Styles.MainContainer data-component={"MainContainer"} $applyContentStyles={applyContentStyles} $footerHeight={footerHeight} $headerHeight={headerHeight} $loggedOut={!loggedIn}>
			{children}
		</Styles.MainContainer>);
};
export default MainContent;
