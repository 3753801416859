import { useParams } from "react-router-dom";
import Card from "@components/Card/Card";
import FeedHeader from "@components/Feed/components/FeedHeader";
import MobileFeedHeader from "@components/Feed/components/MobileFeedHeader";
import { FeedContainer } from "@components/Feed/Feed.styles";
import { Loading, ClickStop } from "@components/Helpers/Helpers";
import { useFetch, useGetSearchParamsObject } from "@hooks/Hooks";

const AdPreviewFeed = () => {
	const { adId } = useParams();
	const { memberId, segmentId } = useGetSearchParamsObject();
	const [data, loading] = useFetch(
		"api/creativepreview/loadcreativepreviewfeed",
		{ adId: parseInt(adId), memberId, segmentId }
	);

	return (
		<FeedContainer>
			<ClickStop>
				<FeedHeader
					filter={"allposts"}
					filterChangeHandler={()=>{}}
				/>
				<MobileFeedHeader
					filter={"allposts"}
					filterChangeHandler={()=>{}}
				/>
			</ClickStop>
			{
				loading && (
					<Loading/>
				)
			}
			{
				!loading
				&& data.cards?.map((card) => {
					if ("Post" === card.cardType) {
						return (
							<ClickStop key={card.id}>
								<Card state={card}/>
							</ClickStop>
						)
					} else {
						return (
							<Card
								key={card.id}
								state={card}
							/>
						)
					}
				})
			}
		</FeedContainer>
	);
};

export default AdPreviewFeed;
