import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const CommentOnContainer = styled.div `
	padding: 16px 16px 0;
`;
export const CommentOnPost = styled(TextStyled).attrs({
    forwardedAs: "span",
    $kind: "heading01",
}) `
	color: ${({ theme }) => theme.color.text03};
`;
export const CommentOnPostTitle = styled.span `
	color: ${({ theme }) => theme.color.text01};

	& > * {
		display: inline;
	}
`;
export const CommentOnText = styled(TextStyled).attrs({
    $kind: "bodyShort00",
}) `
	color: ${({ theme }) => theme.color.text03};
`;
