import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalTitle = ({ children }) => <div styleName={classNames("styles.modal-title")}>{children}</div>;

ModalTitle.propTypes = {
	children: PropTypes.node,
};

export default ModalTitle;
