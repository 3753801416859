import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { applyBorder } from "@frontend/styled";

export const CommentsStylesContainer = styled.div`
	.module-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		&.module-expanded {
			margin-top: 6px;
		}

		&.reversed {
			flex-direction: column-reverse;
		}
	}

	.row-no-padding {
		margin-bottom: 16px;
		padding: 0;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		padding: 12px 0;
		transition: background-color 0.4s;

		// this is the wrapper around replies
		.wrapper {
		}

		.replies {
			.wrapper {
				padding-left: 0;
				padding-top: 12px;
				padding-bottom: 12px;
				margin: 0;

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}

		// for when replies are reversed
		.reversed .replies .wrapper {
			&:first-of-type {
				padding-bottom: 0;
			}
		}
	}

	.comments-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		.reversed & {
			flex-direction: column-reverse;
		}

		&.replies {
			margin-top: 0;
		}
	}

	.comments {
		max-width: 100%;
	}

	.sort {
		margin-top: 16px;
		&.underlined {
			padding-bottom: 8px;
			${applyBorder("bottom")};
		}
	}

	.comment-mini-profile {
		margin-top: 6px;
		max-width: calc(100% - 16px);
	}

	.no-comments {
		margin: 50px 0 40px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: ${({ theme }) => theme.color.icon03};
	}

	.username {
		color: ${({ theme }) => theme.color.text02};
		margin-right: 10px;
	}

	.row {
		max-width: 100%;
		display: flex;
		align-items: stretch;
		position: relative;
	}

	.comment {
		color: ${({ theme }) => theme.color.text01};
		flex-grow: 1;

		overflow-wrap: break-word;
		word-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	.comment-wrapper {
		overflow-wrap: break-word;
		word-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
		margin-top: 8px;
		${StyledMobileAndTabletPortraitQuery} {
			margin-top: 0;
		}
	}

	.voting {
		display: flex;
		flex-direction: column;
		width: 16px;
		margin-top: -1px;
		align-items: center;
		margin-right: ${({ theme }) => theme.layout.cardsPaddingMobile};
		position: relative;
		height: 48px;
		color: ${({ theme }) => theme.color.text03};
	}

	.voting-voted {
		color: ${({ theme }) => theme.color.text01};
	}

	.votes {
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-left: -1px;
		width: 30px;
	}

	.comment-image {
		max-height: 400px;
		max-width: 100%;
	}

	.line {
		position: relative;
		width: 16px;
		margin-right: calc(${({ theme }) => theme.layout.cardsPaddingMobile} / 2);
		flex-shrink: 0;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 41%;
			width: 0;
			${applyBorder("left")};
			border-width: 2px;
		}
	}

	.comment-footer {
		display: flex;
		align-items: center;
		min-height: 34px;
		margin-bottom: -6px; // because of the overflow button height
	}

	.view-more {
		margin-top: 18px;
		color: ${({ theme }) => theme.color.text02};
		text-align: left;
		font-family: "Sailec", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 12px;
		line-height: 16px;
		text-size-adjust: none;
		text-transform: none;

		.reversed & {
			margin-bottom: 12px;
		}

		.replies & {
			margin-top: 12px;
			margin-bottom: 16px;
		}

		.module-expanded & {
			${StyledMobileAndTabletPortraitQuery} {
				margin-bottom: 36px;
			}
		}
	}

	.blocked {
		cursor: pointer;
		color: ${({ theme }) => theme.color.disabled02};
		display: flex;
		align-items: center;
	}

	.blocked-icon {
		height: 16px;
		margin-right: 8px;
	}

	.reply-button {
		color: ${({ theme }) => theme.color.text03};
		cursor: pointer;
		margin-right: 8px;

		&:hover {
			color: ${({ theme }) => theme.color.text01};
		}
	}

	.new {
		background-color: ${({ theme }) => theme.color.ui07};
	}

	.edited > p:last-of-type:after {
		content: "(edited)";
		color: ${({ theme }) => theme.color.text04};
		padding-left: 8px;
		line-height: 12px;
		font-size: 10px;
	}

	.context-question {
		.line {
			margin-right: calc(${({ theme }) => theme.layout.cardsPaddingMobile} / 2 + 40px); /* normal width + width of profile pic + padding */
		}
		.line:before {
			display: none;
		}
		.comment-mini-profile {
			margin-left: -8px;
		}
		.comment {
			margin-top: -20px;

			${StyledMobileAndTabletPortraitQuery} {
				margin-top: -13px;
			}
		}
		.comments-container {
			margin-top: 8px;
		}
	}
`;