import Modal from "@components/Modal/Modal";
import { useMemoizedContext } from "@hooks/Hooks";
const Banner = () => {
    const { bannerCanClose, bannerCloseMethod, bannerComponent, bannerIsOpen, bannerLabel, bannerSubtype, bannerTextLabel, } = useMemoizedContext("ui", [
        "bannerCanClose",
        "bannerCloseMethod",
        "bannerComponent",
        "bannerIsOpen",
        "bannerLabel",
        "bannerSubtype",
        "bannerTextLabel",
    ]);
    if (bannerIsOpen) {
        return (<Modal canClose={bannerCanClose} clickHandler={bannerCloseMethod} label={bannerLabel} modalType={"banner"} subType={bannerSubtype} textLabel={bannerTextLabel}>
				{bannerComponent}
			</Modal>);
    }
    return null;
};
export default Banner;
