import { Icon, PenSVG } from "@sermo/ui-components";
import classNames from "classnames";
import Button from "@components/Button/Button";
import getTranslation from "@translation/translation";
import { useMemoizedContext } from "../../../../hooks/Hooks";
import typography from "../../../../scss/typography.scss";
import { MemberButton } from "./MemberButton";
import styles from "./MessagesList.scss";
import * as Styles from "./MessagesList.styles";

export const PreviewMessagesList = (data) => {
	const {
		headerHeight,
	} = useMemoizedContext("ui", [
		"headerHeight",
	]);
	
	return (
		<Styles.MessagesList
			$subtractFromHeight={headerHeight}
		>
			<div styleName="styles.header">
				<div styleName={classNames("styles.header-label")}>{getTranslation("frontend.generics.messages")}</div>
				<Button
					icon={
						<Icon
							src={PenSVG}
						/>
					}
					style="icon"
				/>
			</div>
			<div styleName="styles.list">
				<MemberButton
					{...data}
					aId={10}
					id={10}
					numberOfUnread={1}
				/>
			</div>
		</Styles.MessagesList>
	);
}