import styled from "styled-components";
import { useToggleBackdrop } from "@contexts/UI";
import { applyZIndex } from "@frontend/styled";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
export const StyledBackdrop = styled.div `
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	${applyZIndex("backdrop")};
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transform: translateX(-100%);
	transition: opacity transform 0.3s ease-in-out;
	${({ $open }) => $open && `opacity: 1; transform: translateX(0);`};
`;
const Backdrop = () => {
    const toggleBackdrop = useToggleBackdrop();
    const trackEvent = useTrackEvent();
    const { backdropIsOpen, sidePanelLabel, } = useMemoizedContext("ui", [
        "backdropIsOpen",
        "sidePanelLabel",
    ]);
    const handleXClick = () => {
        trackEvent({
            category: "backdrop",
            label: "close",
            action: "click",
        });
        toggleBackdrop(false);
    };
    return (<StyledBackdrop onClick={handleXClick} $open={backdropIsOpen
            || !!sidePanelLabel}/>);
};
export default Backdrop;
