import AdForm from "@components/AdUnits/AdForm";
import SPFooter from "@components/AdUnits/Footer/SPFooter";
import References from "@components/AdUnits/References/References";
import Comments from "@components/Card/components/Comments/Comments";
import Featured from "@components/Card/components/Featured/Featured";
import Images from "@components/Card/components/Images/Images";
import PDFs from "@components/Card/components/PDFs/PDFs";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import { ExpandedCardFooter } from "@components/DetachedFooter";
import { STICKY_LOCATION } from "@components/Layout";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
// TODO: move AdditionalFooterText folder to AdUnits?
import SponsoredContentExpanded from "@components/PostContent/components/SponsoredContentExpanded";
import AdditionalFooterText from "@components/PostViewer/components/AdditionalFooterText";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardModal from "@components/PostViewer/components/CardModal";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useMemoizedContext } from "@hooks/Hooks";
const SponsoredDiscussionExpanded = () => {
    const { contentHeight, titleHeight, } = useMemoizedContext("postUIViewing", [
        "contentHeight",
        "titleHeight",
    ]);
    const adUnitProps = {
        inFeed: false,
        contentHeight: contentHeight + titleHeight,
    };
    const { adForm, footnoteLocation, isiLocation, isiText, footnoteText, customStyle, footnoteAdStyle, footnoteStyle, isiStyle, isISIExpandable, isFootnoteExpandable, } = useMemoizedContext("postData", [
        "adForm",
        "footnoteLocation",
        "isiLocation",
        "isiText",
        "footnoteText",
        "customStyle",
        "footnoteAdStyle",
        "footnoteStyle",
        "isiStyle",
        "isISIExpandable",
        "isFootnoteExpandable",
    ]);
    return (<>
			<CardModal />
			<CardContainer>
				<ModuleWrapper solid={true} paddedWidest={true} notPaddedY={true}>
					<SharedStyles.ExpandedSpacingContainer>
						<CardHeader />
						<Title />
						<Featured />
						{footnoteLocation === "Top" && <SPFooter {...adUnitProps} type={"footnote"}/>}
						{isiLocation === "Top" && <SPFooter {...adUnitProps} type={"isi"}/>}
						<SponsoredContentExpanded />
						<LinkPreview />
						{adForm && <AdForm />}
						{footnoteLocation === "AbovePoll"
            && (<SPFooter {...adUnitProps} type={"footnote"}/>)}
						{isiLocation === "AbovePoll"
            && (<SPFooter {...adUnitProps} type={"isi"}/>)}
						<Images />
						<PDFs />
						<PollDisplay />
						{footnoteLocation === "Default"
            && !isFootnoteExpandable
            && (<SPFooter {...adUnitProps} type={"footnote"}/>)}
						{isiLocation === "Default"
            && !isISIExpandable
            && (<SPFooter {...adUnitProps} type={"isi"}/>)}
						<References />
						<AdditionalFooterText />
					</SharedStyles.ExpandedSpacingContainer>
				</ModuleWrapper>
				<CardFooter />
				{/* @ts-ignore*/}
				<Comments />
			</CardContainer>
			{!!isiText
            && (isiLocation === STICKY_LOCATION
                || isISIExpandable)
            && <ExpandedCardFooter type={"isi"} text={isiText} customStyle={customStyle} className={isiStyle}/>}
			{!!footnoteText
            && (footnoteLocation === STICKY_LOCATION
                || isFootnoteExpandable)
            && <ExpandedCardFooter type={"footnote"} text={footnoteText} customStyle={footnoteAdStyle} className={footnoteStyle}/>}
		</>);
};
export default SponsoredDiscussionExpanded;
