import { Icon, XSVG } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import ModalBackground from "@components/ModalBackground/ModalBackground";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useCloseAlert, useCloseBanner, useCloseModal } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
import {
	BannerContent,
	StyledAlert,
	StyledAlertX,
	StyledBanner,
	StyledLabel,
	StyledModal,
	StyledX
} from "./Modal.styles";

const SIZE_X = 24;

const Modal = ({
	backgroundDarkened,
	canClose,
	children,
	clickHandler,
	hasSubNav,
	label,
	modalType,
	overTheTop,
	persist,
	subType,
	textLabel,
}) => {
	const closeAlert = useCloseAlert();
	const closeBanner = useCloseBanner();
	const closeModal = useCloseModal();
	const [fadeOut, setFadeOut] = useState(false);
	const [fading, setFading] = useState(false);
	const timeout = useRef();
	const noPadding = ["side-panel-left", "side-panel-right"].includes(subType);

	const {
		isReverificationRequired,
		isPostSurveyReferral,
	} = useMemoizedContext("member", [
		"isReverificationRequired",
		"isPostSurveyReferral",
	]);const {
		alertLabel,
		bannerIsOpen,
		bannerLabel,
		expandedPost,
		footerHeight,
		headerHeight,
		modalLabel,
		tourIsOpen,
	} = useMemoizedContext("ui", [
		"alertLabel",
		"bannerIsOpen",
		"bannerLabel",
		"expandedPost",
		"footerHeight",
		"headerHeight",
		"modalLabel",
		"tourIsOpen",
	]);

	useEffect(() => {
		// TODO: remove this eventually. reverification check is a temporary solution
		if (
			(
				"alert" === modalType
				&& !persist
			)
			|| (
				isReverificationRequired
				&& label !== "reverification-alert-banner"
				&& !isPostSurveyReferral && label !== "reverification"
			)
		) {
			setFadeOut(true);
		}
	},[modalType, persist, isReverificationRequired, label]);

	useEffect(() => {
		if (fadeOut && !fading) {
			window.clearTimeout(timeout.current);

			timeout.current = window.setTimeout(() => {
				setFading(true);
				timeout.current = window.setTimeout(() => {
					setFading(false);
					setFadeOut(false);
					if (clickHandler) {
						clickHandler();
					} else {
						closeAlert(label);
					}
				}, 500);
			}, 6000);

			return () => {
				clearTimeout(timeout.current);
			};
		}
	}, [fadeOut]);

	const handleCloseClick = () => {
		if (clickHandler) {
			clickHandler();
		} else {
			switch (modalType) {
				case "alert":
					closeAlert(alertLabel);
					break;
				case "banner":
					closeBanner(bannerLabel);
					break;
				case "modal":
					closeModal(modalLabel);
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		if (
			"banner" === modalType
			&& bannerIsOpen
			&& "expanded-post" === bannerLabel
			&& !expandedPost
		) {
			closeBanner(bannerLabel);
		}
	}, [
		bannerIsOpen,
		expandedPost,
		modalType,
		subType,
	]);

	// automatically close actual modals - not alerts or banners - when the route changes
	const { pathname } = useLocation();
	const pathNamePrev = usePrevious(pathname);
	useEffect(() => {
		if (
			"modal" === modalType
			&& "undefined" !== typeof pathNamePrev
			&& pathNamePrev !== pathname
		) {
			closeModal(label);
		}
	}, [pathname]);

	switch (modalType) {
		case "alert":
			return (
				<>
					<StyledAlert
						data-component={"AlertContainer"}
						className={
							`alert ${subType} ${fading
								? "fade-out"
								: "	"}`
						}
					>
						<ModuleWrapper
							solid={true}
							border={true}
							contextClass="alert"
						>
							{children}
							{
								canClose && (
									<StyledAlertX
										data-component={"CloseAlertCross"}
										onClick={handleCloseClick}
									>
										<Icon
											width={SIZE_X}
											height={SIZE_X}
											src={XSVG}
										/>
									</StyledAlertX>
								)
							}
						</ModuleWrapper>
					</StyledAlert>
				</>
			);
		case "banner":
			return (
				<StyledBanner
					data-component={"BannerContainer"}
					className={`banner-alert ${subType}`}
					$hasSubNav={hasSubNav}
				>
					<BannerContent>
						{children}
						{
							canClose && (
								<StyledX
									data-component={"CloseBannerCross"}
									onClick={handleCloseClick}
								>
									<Icon
										width={SIZE_X}
										height={SIZE_X}
										src={XSVG}
									/>
								</StyledX>
							)
						}
					</BannerContent>
				</StyledBanner>
			);
		case "modal":
		default:
			return (
				<>
					{
						!tourIsOpen
						&& (
							<ModalBackground
								clickHandler={
									canClose
										? handleCloseClick
										: undefined
								}
								key={label}
								sponsored={backgroundDarkened}
								overTheTop={overTheTop}
							/>
						)
					}
					<StyledModal
						data-component={"ModalContainer"}
						className={
							`modal default ${subType} ${overTheTop
								? "over-the-top"
								: ""}`
						}
						$footerHeight={footerHeight}
						$headerHeight={headerHeight}
					>
						<ModuleWrapper
							solid={true}
							border={true}
							contextClass={
								subType
									? `modal-${subType}`
									: "modal"
							}
							padded={!noPadding}
						>
							{
								textLabel && (
									<StyledLabel>
										{textLabel}
									</StyledLabel>
								)
							}
							{
								canClose && (
									<StyledX
										data-component={"CloseModalCross"}
										onClick={handleCloseClick}
									>
										<Icon
											width={SIZE_X}
											height={SIZE_X}
											src={XSVG}
										/>
									</StyledX>
								)
							}
							{children}
						</ModuleWrapper>
					</StyledModal>
				</>
			);
	}
};

Modal.propTypes = {
	backgroundDarkened: PropTypes.bool,
	children: PropTypes.node,
	clickHandler: PropTypes.func,
	canClose: PropTypes.bool,
	hasSubNav: PropTypes.bool,
	label: PropTypes.string,
	modalType: PropTypes.oneOf(["modal", "alert", "banner"]).isRequired,
	overTheTop: PropTypes.bool,
	persist: PropTypes.bool,
	subType: PropTypes.oneOfType([PropTypes.string,PropTypes.bool]),
	textLabel: PropTypes.node,
};

Modal.defaultProps = {
	backgroundDarkened: false,
	canClose: true,
	clickHandler: () => {},
	label: "",
	modalType: "modal",
	overTheTop: false,
	persist: false,
	subType: "",
};

export default Modal;
