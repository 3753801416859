import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { CustomDropDownList } from "@components/FormFields/FormFields";
import { Loading } from "@components/Helpers/Helpers";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useFetch, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const ProfileMobileNavDropDown = () => {
	const { memberId, section } = useParams();
	const { id } = useMemoizedContext("member", ["id"]);
	const [route, setRoute] = useState(section);
	const [redirect, setRedirect] = useState(false);
	const [user, loading] = useFetch("api/profiles/getprofile", { memberId });

	useEffect(() => {
		if (redirect) {
			setRedirect(false);
		}
	}, [route]);

	if (loading) {
		return <Loading />;
	}

	const dropdownOptions = [];

	dropdownOptions.push({
		name: "Overview",
		value: "overview",
	});

	if (!user.brandAdministrator && id === +memberId) {
		dropdownOptions.push({
			name: getTranslation("frontend.generics.followedPosts", true),
			value: "followed-posts",
		});
		dropdownOptions.push({
			name: getTranslation("frontend.generics.hiddenPosts", true),
			value: "hidden",
		});
	}

	dropdownOptions.push({
		name: getTranslation("frontend.generics.followers", true),
		value: "followers",
	});

	if (!user.brandAdministrator) {
		dropdownOptions.push({
			name: getTranslation("frontend.generics.followedMembers", true),
			value: "following",
		});
	}

	const changeHandler = value => {
		setRoute(value);
		setRedirect(true);
	};

	return (
		<MobileAndTabletPortrait>
			{redirect && <Navigate to={`/profile/${memberId}/${route}`} />}
			<ModuleWrapper
				padded={false}
				contextClass="mobile-header"
				solid={true}
			>
				{/* TODO: In the near future, replace CustomDropDownList with <Select /> component */}
				<CustomDropDownList
					value={route}
					options={dropdownOptions}
					handleChange={changeHandler}
				/>
			</ModuleWrapper>
		</MobileAndTabletPortrait>
	);
};

export default ProfileMobileNavDropDown;
