export const applyShadow = (digits) => {
	switch (digits) {
		case "01":
			return `box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.24);`;
		case "02":
			return `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);`;
		case "03":
			return `box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.39);`;
		default:
			return `--error: "Invalid shadow digits: ${digits}";`;
	}
};