import PropTypes from "prop-types";
import React from "react";
import { settingsSection } from "@components/Settings/components/AccountSettings/sections";
import getTranslation from "@translation/translation";
import styles from "./ModalWithdrawalComponents.scss";

const InfoChangeAddress = ({ onClickChangeAddress }) => {
	const onClickHandler = (event) => {
		event.preventDefault();
		onClickChangeAddress();
	}

	return ( // If mailing address is incorrect, please update your mailing address.
		<div
			styleName="styles.address-info"
			onClick={onClickHandler}
		>
			{getTranslation("frontend.modals.withdrawal.infoUpdateAddress", false, `/settings/accountsettings?edit=${settingsSection.ResidentialAddress}`)}
		</div>
	);
};

InfoChangeAddress.propTypes = {
	onClickChangeAddress: PropTypes.func,
}

export default InfoChangeAddress;
