import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import { useTrackEvent } from "../../tracking/tracking";
import { Divider } from "../Helpers/Helpers";
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper";
import styles from "./Footer.scss";
import * as Styles from "./Footer.styles";

const getFooterLinks = (showDoNotSellLink) => ([
	{
		name: getTranslation("frontend.footer.helpCenter"),
		to: "https://support.sermo.com/",
		external: true,
		divider: false,
		tracking: { category: "footer",
			action: "click",
			label: "help-center" },
	},
	{
		name: getTranslation("frontend.footer.about"),
		to: "https://www.sermo.com/about/",
		external: true,
		divider: true,
		tracking: { category: "footer",
			action: "click",
			label: "about" },
	},
	{
		name: getTranslation("frontend.footer.codeOfConduct"),
		to: "https://www.sermo.com/conduct/",
		external: true,
		divider: true,
		tracking: { category: "footer",
			action: "click",
			label: "code-of-conduct" },
	},
	{
		name: getTranslation("frontend.footer.blog"),
		to: "https://www.sermo.com/blog/",
		external: true,
		divider: false,
		lineBreak: true,
		tracking: { category: "footer",
			action: "click",
			label: "blog" },
	},
	{
		name: getTranslation("frontend.footer.media"),
		to: "https://www.sermo.com/press/",
		external: true,
		divider: true,
		tracking: { category: "footer",
			action: "click",
			label: "media" },
	},
	{
		name: getTranslation("frontend.footer.termsOfService"),
		to: "https://www.sermo.com/terms-of-service/",
		external: true,
		divider: true,
		tracking: { category: "footer",
			action: "click",
			label: "terms-of-service" },
	},
	{
		name: getTranslation("frontend.footer.privacy"),
		to: "https://www.sermo.com/privacy-policy/",
		external: true,
		divider: true,
		tracking: { category: "footer",
			action: "click",
			label: "privacy-policy" },
	},
	...([showDoNotSellLink === true && {
		name: getTranslation("frontend.footer.doNotSell"),
		to: "https://www.sermo.com/do-not-sell/",
		external: true,
		divider: false,
		lineBreak: true,
		tracking: { category: "footer",
			action: "click",
			label: "do-not-sell" },
	}]),
]);

export { getFooterLinks };

const Footer = () => {
	const trackEvent = useTrackEvent();
	const {
		showDoNotSellLink,
	} = useMemoizedContext("member", [
		"showDoNotSellLink",
	]);
	const clickEventHandler = tracking => {
		trackEvent(tracking);
	};

	return (
		<Styles.FooterContainer
			data-component="FooterLinks"
		>
			{
				getFooterLinks(showDoNotSellLink).map((link, i) => {
					const { name, to, divider, lineBreak, tracking, external } = link;
					return (
						<span
							key={i}
							styleName={
								classNames(
									"styles.link",
									lineBreak
										? "styles.break"
										: "",
									"typography.body-short-brand-00"
								)
							}
						>
							{divider && <Divider />}
							{
								external && (
									<a
										styleName={classNames("styles.link")}
										onClick={() => clickEventHandler(tracking)}
										target="_blank"
										href={to}
										rel="noreferrer"
									>
										{name}
									</a>
								)
							}
							{
								!external && (
									<NavLink
										styleName={classNames("styles.link")}
										onClick={() => clickEventHandler(tracking)}
										to={to}
									>
										{name}
									</NavLink>
								)
							}
						</span>
					);
				})
			}
		</Styles.FooterContainer>
	);
};

export default Footer;
