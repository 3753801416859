import { Icon, AttachmentPDFSVG } from "@sermo/ui-components";
import classNames from "classnames";
import React from "react";
import Heading from "@components/Heading/Heading";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import layout from "../../../../scss/layout.scss";
import typography from "../../../../scss/typography.scss";
import styles from "./PDFs.scss";

const PDFDisplay = () => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		attachments,
		attachmentsView,
		cardType,
		clickTags,
	} = useMemoizedContext("postData", [
		"attachments",
		"attachmentsView",
		"cardType",
		"clickTags",
	]);

	const pdfs = attachments.filter((attachment) => attachment.type === "PdfDocument");
	const hasImage = attachments.some((attachment) => attachment.type === "Image");

	// ensure PDFs exist
	if (
		pdfs.length === 0
	) {
		return null;
	}

	if (
		!expanded
	) {
		if ( cardType === "Ad" ) {
			// handle attachmentsView in sponsored content
			if (
				attachmentsView
				&& (
					attachmentsView === "FeatureImage"
					|| attachmentsView === "FeatureImageAndISI"
					|| attachmentsView === "Poll"
				)
			){
				return null;
			}
		} else {
			// only show infeed on non sponsored content if there are no images
			if (hasImage) {
				return null;
			}
		}
	}

	const trackPdfClick = (e, pdfDocumentTitle, pdfUrl) => {
		let pdfDocumentFilename = pdfUrl;

		if (clickTags?.attachment) {
			clickTags?.attachment.forEach((url) => {
				fetchUrl(url);
			});
		}

		if (pdfDocumentTitle) {
			pdfDocumentTitle = pdfDocumentTitle.replace(/ /g, "_");
		}

		if (pdfDocumentFilename) {
			pdfDocumentFilename = pdfDocumentFilename.substring(pdfDocumentFilename.lastIndexOf("/") + 1);

			let indexMark = pdfDocumentFilename.indexOf("?");

			if (indexMark <= 0) {
				indexMark = pdfDocumentFilename.indexOf("&");
			}

			if (indexMark > 0) {
				pdfDocumentFilename = pdfDocumentFilename.substring(0, indexMark);
			}

			if (pdfDocumentFilename) {
				pdfDocumentFilename = encodeURIComponent(pdfDocumentFilename.replace(/ /g, "_"));
			}
		}

		if (pdfDocumentTitle) {
			if (pdfDocumentFilename) {
				pdfDocumentTitle = encodeURIComponent(pdfDocumentTitle) + " (" + pdfDocumentFilename + ")";
			}
		} else {
			pdfDocumentTitle = pdfDocumentFilename;
		}

		e.eventSource = {
			area: "attachments",
			document: pdfDocumentTitle,
		};
	};

	return (
		<div styleName="layout.row-no-padding">
			<Heading type="attachments">{getTranslation("frontend.generics.attachments")}</Heading>
			<div styleName="styles.links">
				<span styleName="styles.icon">
					<Icon src={AttachmentPDFSVG}/>
				</span>
				<div styleName="styles.links-wrapper">
					{
						pdfs.map((pdf, i) => {
							let deliminator = ", ";

							if (i === pdfs.length - 1) {
								deliminator = "";
							}
							return (
								<a
									key={i}
									href={pdf.url}
									target="_blank"
									styleName={classNames("styles.link", "typography.body-short-00")}
									onClick={(e) => trackPdfClick(e, pdf.title, pdf.url)}
									rel="noreferrer"
								>
									{pdf.title}
									{deliminator}
								</a>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};

export default PDFDisplay;
