import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const Content = styled(TextStyled) `
	display: inline;

	div,
	p {
		display: inline;
		padding: 0;
		margin: 0;
	}

	~ div button {
		background-color: white;
	}
`;
