export const DEFAULT_HEIGHT = 96;
/**
 * Returns the sticky container for the given footer type from ModalsWrapper.jsx
 * @param type - The type of footer to get the sticky container for (isi or footnote)
 */
export const getStickyContainer = (type) => {
    const isiPortal = document.querySelector("#sticky-isi-container");
    const footNotePortal = document.querySelector("#sticky-footnote-container");
    const container = { isi: isiPortal, footnote: footNotePortal };
    return container[type];
};
