import React from "react";
import { ModalContainer, ModalTitle, ModalText, ModalSection, ModalFooter } from "@components/Modal/Modals/ModalComponents";
import getTranslation from "@translation/translation";

const EnterName = () => (
	<ModalContainer>
		<ModalTitle>
			{getTranslation("frontend.modals.postSurveyReferrals.completed.title")}
		</ModalTitle>
		<ModalSection>
			<ModalText>
				{getTranslation("frontend.modals.postSurveyReferrals.completed.subtitle")}
			</ModalText>
		</ModalSection>
		<ModalFooter
			submitEventHandler={
				() => {
					document.location = "/feed/all-sermo"
					// closeModal("post-survey-referral");
				}
			}
			submitText={getTranslation("frontend.modals.postSurveyReferrals.completed.cta", true)}
		/>
	</ModalContainer>
);

export default EnterName;
