import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import FirstSurveyPendingVerification from "../FirstSurveyPendingVerification/FirstSurveyPendingVerification";
import "./FirstSurveyPendingVerificationLayout.scss";

const FirstSurveyPendingVerificationLayout = () => {
	if ("undefined" !== typeof window) {
		return (
			<div styleName="first-survey-pending-verification-page">
				<Routes>
					<Route
						exact
						path="/firstsurveypendingverification"
						element={<FirstSurveyPendingVerification />}
					/>
					<Route
						path="*"
						element={<Navigate to="firstsurveypendingverification" />}
					/>
				</Routes>
			</div>
		);
	}
};

export default FirstSurveyPendingVerificationLayout;
