import { Radio, ProcessingSpinner } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import { AutomaticOrManualVerificationRadioList, AutomaticOrManualVerificationRadioGroup, RegisterStyles } from "@components/Register/Register.styles";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import RegistrationStep from "../Registration/RegistrationStep";
import loadVouched from "../Vouched/vouched-script";

const automatic = "automatic";
const manual = "manual";

const LoadingSpinner = styled(ProcessingSpinner)`
	width: 100px;
	height: 100px;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	display: flex;
	background-color: ${({ theme }) => theme.globals.background.color};
`;

const VouchedDiv = styled.div`
	height: calc(100vh - 300px); // header spacing and footer height bad magic numbers
	width: 100%;

	&>div {
		height: 100% !important;
		max-height: 100% !important;

		iframe {
			height: 100% !important;
		}
	}
`;

const SelectIdentityVerificationMode = ({ data, updateData, endpoint }) => {
	const registerTrackEvent = useRegisterTrack();
	const apiEndpoint = useApiEndpoint();
	const [AutomaticOrManualVerification, setAutomaticOrManualVerification] = useState(null);
	const [showVouched, setShowVouched] = useState(false);
	const [loading, setLoading] = useState(false);

	const onChange = (e) => {
		registerTrackEvent({
			label: "select-identity-verification-mode",
			action: `select-${e.target.value}`,
		});
		setAutomaticOrManualVerification(e.target.value);
	}

	const formSubmitHandler = () => {
		registerTrackEvent({
			label: "select-identity-verification-mode",
			action: `submit-${AutomaticOrManualVerification}`,
		});
		if ( manual === AutomaticOrManualVerification ) {
			updateData({
				...data,
				currentStep: RegistrationStep.UploadIdentityDocuments,
			});
		}
		if ( automatic === AutomaticOrManualVerification ) {
			if ( Object.hasOwnProperty.call(data,"vouchedJsPluginConfiguration") ) {
				setShowVouched(true);
			}
		}
	}

	const onDone = useCallback((job) => {
		setLoading(true);

		const requestData = {
			jobId: job.id,
		}

		apiEndpoint(`${endpoint}/checkidentityautoverification`, requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);

				if (dataResponse.ok) {
					updateData(body);
					setShowVouched(false);
				}
			});
		});
	});

	const callLoadVouched = useCallback(() => {
		loadVouched({
			...data.vouchedJsPluginConfiguration,
			onSubmit: (obj) => {
				console.warn("onSubmit");
				console.warn(obj);
			},
			onCamera: (obj) => {
				console.warn("onCamera");
				console.warn(obj);
			},
			onCameraEvent: (obj) => {
				console.warn("onCameraEvent");
				console.warn(obj);
			},
			onInit: (obj) => {
				console.warn("onInit");
				console.warn(obj);
			},
			onConfirm: (obj) => {
				console.warn("onConfirm");
				console.warn(obj);
			},
			onDone: onDone,
		});
	}, []);

	if ( showVouched ) {
		callLoadVouched()
	}

	return(
		<LoggedOutStyles>
			<RegisterStyles>
				<div className={
					classNames([
						!showVouched && "content-box-wider",
						showVouched && "content-box-widest",
					])
				}
				>
					<div className={classNames(["content-box-inner-container-vouched"])}>
						{loading && <LoadingSpinner/>}
						{
							!loading
						&& showVouched
						&& <VouchedDiv id='vouched-element'/>
						}
						{
							!loading
						&& !showVouched
						&& <>
							<AutomaticOrManualVerificationRadioGroup
								value={AutomaticOrManualVerification}
								onChange={onChange}
								className={classNames(["form-field-row"])}
							>
								{/* TODO: add translation */}
								<TextStyled
									className={classNames(["form-title"])}
									$kind="heading04"
								>
									<strong>
										How would you like to verify your identity?
									</strong>
								</TextStyled >
								<div>
									<Radio
									// TODO: add translation
										label="Automatic verification"
										value={automatic}
									/>
									<AutomaticOrManualVerificationRadioList>
										<ul>
											{/* TODO: add translations */}
											<li>
												1-3 minutes
											</li>
											<li>
												Government-issue ID and selfie
											</li>
											<li>
												Immediate access to Sermo
											</li>
										</ul>
									</AutomaticOrManualVerificationRadioList>
								</div>
								<div>
									<Radio
									// TODO: add translation
										label="Manual verification"
										value={manual}
									/>
									<AutomaticOrManualVerificationRadioList>
										<ul>
											{/* TODO: add translation */}
											<li>
												1-3 minutes
											</li>
											<li>
												~48hr review process
											</li>
										</ul>
									</AutomaticOrManualVerificationRadioList>
								</div>
							</AutomaticOrManualVerificationRadioGroup>
							<div className={classNames(["submit-container", "identity-verification"])}>

								<div className={classNames(["submit-container-side"])}>
									<div className={classNames(["submit-container--back-button"])}>
										<Button
											name="submit"
											size="medium"
											type="submit"
											clickHandler={formSubmitHandler}
											disabled={null === AutomaticOrManualVerification}
										>
											{getTranslation("frontend.generics.next", true)}
										</Button>
									</div>
								</div>
							</div>
						</>
						}
					</div>
				</div>
			</RegisterStyles>
		</LoggedOutStyles>
	)
};

SelectIdentityVerificationMode.propTypes = {
	data: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
	endpoint: PropTypes.string.isRequired,
}

export default SelectIdentityVerificationMode;
