import React from "react";
import Comments from "@components/Card/components/Comments/Comments";
import Featured from "@components/Card/components/Featured/Featured";
import Images from "@components/Card/components/Images/Images";
import PDFs from "@components/Card/components/PDFs/PDFs";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import LinkPreview from "@components/LinkPreview/LinkPreview";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import UserGeneratedContentExpanded from "@components/PostContent/components/UserGeneratedContentExpanded";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import CardModal from "@components/PostViewer/components/CardModal";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const DiscussionExpanded = () => (<>
		<CardModal />
		<CardContainer>
			<ModuleWrapper solid={true} paddedWidest={true} notPaddedY={true}>
				<SharedStyles.ExpandedSpacingContainer>
					<SharedStyles.HeaderContainer>
						<CardHeader />
					</SharedStyles.HeaderContainer>
					<SharedStyles.TitleContainer>
						<Title />
					</SharedStyles.TitleContainer>
					<Featured />
					<SharedStyles.ContentContainer>
						<UserGeneratedContentExpanded />
					</SharedStyles.ContentContainer>
					<LinkPreview />
					<Images />
					<PDFs />
					<PollDisplay />
				</SharedStyles.ExpandedSpacingContainer>
			</ModuleWrapper>
			<CardFooter />
			{/* @ts-ignore*/}
			<Comments />
		</CardContainer>
	</>);
export default DiscussionExpanded;
