import { Icon, VerifiedCheckSVG, CameraSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import { useMatch } from "react-router-dom";
import UserGroup from "@assets/images/user-group.svg";
import * as Styles from "@components/ProfileOverview/ProfileOverview.styles";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const AdPreviewProfileOverview = () => {
    const isAdPreview = useMatch("/ad-preview/*");
    const user = useMemoizedContext("member", [
        "biography",
        "following",
        "id",
        "joinDate",
        "locale",
        "location",
        "specialties",
        "username",
        "verified",
        "worksAtSermo",
        "countryCode",
        "hasAccess",
        "onboardingCompleted",
        "primarySpecialtyId",
        "secondarySpecialtyId",
    ]);
    return (<>
			<Styles.ProfileContainer data-component={"ProfileOverview"}>
				<Styles.InfoContainer>
					<div className={"picture-container"} data-component={"ProfilePicture"}>
						<ProfilePic user={user} size="large" border={true}/>
						<div className={"camera-icon"} data-component={"EditPicture"}>
							<Icon src={CameraSVG} width={18} height={18}/>
						</div>
					</div>
					<Styles.ProfileInfo>
						<Styles.FullName title={user.displayName} $kind="heading00">
							Morgan Schnapp
							<Styles.ProfileIconStyled src={VerifiedCheckSVG}/>
						</Styles.FullName>
						<Styles.Specialty data-component={"Specialty"}>
							<div>
								<div>
									<div className={"main-speciality"}>
										{isAdPreview
            && (<TextStyled $kind="bodyShort00">
													General Practice (GP)
												</TextStyled>)}
									</div>
								</div>
							</div>
						</Styles.Specialty>
					</Styles.ProfileInfo>
				</Styles.InfoContainer>
				<Styles.SubItem data-component={"Refer"}>
					<Styles.IconHint src={UserGroup}/>
					<TextStyled $kind={"bodyShort00"}>
						{getTranslation("frontend.profileOverview.refer")}
					</TextStyled>
				</Styles.SubItem>
			</Styles.ProfileContainer>
		</>);
};
export default AdPreviewProfileOverview;
