import { useCallback, useMemo, useState } from "react";
import { useFetch } from "@hooks/Hooks";
export const useMentionMember = (minQueryLength = 4) => {
    const [search, setSearch] = useState("");
    const [data] = useFetch("api/search/autocompletemembers", {
        query: search,
    }, search.length >= minQueryLength);
    const members = useMemo(() => {
        if (search.length < minQueryLength) {
            return [];
        }
        return data.members || [];
    }, [search, data, minQueryLength]);
    const handleSearchMention = useCallback((mention) => {
        setSearch(mention);
    }, []);
    return {
        search,
        members,
        handleSearchMention
    };
};
