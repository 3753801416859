import React from "react";
import { CustomStyledExpandable } from "@components/DetachedFooter/DetachedFooter.styles";
const styles = {
    "LargerWithoutBackground": "largerWithoutBackground body-short-legacy-02"
};
export const hasCustomStyle = (customStyle) => !!(customStyle && Object.keys(customStyle).length);
const CustomStyleContent = ({ type, customStyle, text, className }) => (<CustomStyledExpandable customStyle={hasCustomStyle(customStyle)
        ? customStyle
        : undefined}>
		<div className={`${hasCustomStyle(customStyle)
        ? undefined
        : (styles[className] || "default")} ${type}`} dangerouslySetInnerHTML={{ __html: text }}/>
	</CustomStyledExpandable>);
export default CustomStyleContent;
