import { PopupMenu } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useMatch, Navigate } from "react-router-dom";
import { EUIKey, useOpenAlert } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import modalStyles from "../../../Modal/Modal.scss";
import styles from "./MessageWindow.scss";

const MessageWindowMenu = ({ triggerRef, deleteThread, memberId, muted, setMuted, isDma, handleBlockedMember }) => {
	const apiEndpoint = useApiEndpoint();
	const isDMAPreview = !!useMatch("/dma-preview/*");
	const openAlert = useOpenAlert();
	const trackEvent = useTrackEvent();
	const [redirect, setRedirect] = useState(false);

	const blockMemberClickEventHandler = (e) => {
		// stop prop so this all has a chance to fire the redirect before the menu closes
		e.stopPropagation();

		trackEvent({
			category: "messages",
			action: "click",
			label: "block-member",
		});

		apiEndpoint("members/blockmember", { memberId: memberId }).then(() => {
			if (handleBlockedMember) {
				handleBlockedMember();
			}
			setRedirect("/messages");
		});
	};

	const muteMemberToggle = () => {
		const action = muted
			? "unmute"
			: "mute";
		apiEndpoint(`members/${action}member`, { memberId: memberId });
		openAlert({
			[EUIKey.ALERT_COMPONENT]: (
				<div styleName="modalStyles.alert-text">
					{
						muted
							? getTranslation("frontend.alerts.memberUnmuted")
							: getTranslation("frontend.alerts.memberMuted")
					}
				</div>
			),
			[EUIKey.ALERT_LABEL]: "member-muted",
		});

		setMuted(!muted);
	};

	useEffect(() => {
		if (redirect) {
			openAlert({
				[EUIKey.ALERT_COMPONENT]: (
					<div styleName="modalStyles.alert-text">{getTranslation("frontend.alerts.memberBlocked")}</div>
				),
				[EUIKey.ALERT_LABEL]: "member-blocked",
			});
		}
	}, [
		openAlert,
		redirect,
	]);

	let menuOptions = [];

	if (!isDma) {
		menuOptions.push({
			label: muted
				? getTranslation("frontend.miniProfileMenu.unmuteUser")
				: getTranslation("frontend.miniProfileMenu.muteUser"),
			onClick: muteMemberToggle
		})
		menuOptions.push({
			label: getTranslation("frontend.miniProfileMenu.blockUser"),
			onClick: blockMemberClickEventHandler
		});
	}

	if (!isDMAPreview) {
		menuOptions.push({
			label: getTranslation("frontend.messages.deleteThread", true),
			onClick: deleteThread
		});
	}

	return (
		<>
			{
				redirect && <Navigate
					to={redirect}
					replace
				/>
			}
			<PopupMenu
				triggerRef={triggerRef}
				placement="bottom-end"
				position="fixed"
				items={menuOptions}
			/>
		</>
	);
};

MessageWindowMenu.propTypes = {
	muted: PropTypes.bool,
	memberId: PropTypes.number,
	isDma: PropTypes.bool.isRequired,
	setMuted: PropTypes.func.isRequired,
	deleteThread: PropTypes.func.isRequired,
	handleBlockedMember: PropTypes.func,
};

MessageWindowMenu.defaultProps = {
	isDma: false,
	setMuted: () => {},
	deleteThread: () => {},
	handleBlockedMember: () => {},
};

export { MessageWindowMenu };
