import { Portal } from "@sermo/ui-components";
import React from "react";
import CustomStyleContent from "@components/DetachedFooter/components/shared/CustomStyleContent";
import ExpandableFooter from "@components/DetachedFooter/components/shared/ExpandableFooter";
import { EventWrapper } from "@components/DetachedFooter/DetachedFooter.styles";
import { getStickyContainer } from "@components/DetachedFooter/utils";
const EventFooter = ({ type, text, className, customStyle, hasVideo, }) => (<Portal container={getStickyContainer(type)}>
		<EventWrapper hasVideo={hasVideo}>
			<ExpandableFooter type={type} customStyle={customStyle}>
				<CustomStyleContent type={type} className={className} customStyle={customStyle} text={text}/>
			</ExpandableFooter>
		</EventWrapper>
	</Portal>);
export default EventFooter;
