import PropTypes from "prop-types";
import React from "react";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalText, ModalFooter } from "./ModalComponents";

const GenericConfirmModal = ({
	cancelModalEventHandler,
	submitEventHandler,
	cancelText,
	submitText,
	text,
	contextClass,
	containerContextClass,
}) => (
	<ModalContainer contextClass={containerContextClass}>
		<ModalText {...{ contextClass }}>{text}</ModalText>
		<ModalFooter
			{...{
				cancelModalEventHandler,
				submitEventHandler,
				cancelText,
				submitText,
			}}
		/>
	</ModalContainer>
);

GenericConfirmModal.propTypes = {
	cancelModalEventHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	submitEventHandler: PropTypes.func,
	cancelText: PropTypes.string,
	submitText: PropTypes.string,
	text: PropTypes.string,
	contextClass: PropTypes.string,
	containerContextClass: PropTypes.string,
};

GenericConfirmModal.defaultProps = {
	cancelModalEventHandler: false,
	submitEventHandler: () => {},
	cancelText: getTranslation("frontend.generics.cancel", true),
	submitText: getTranslation("frontend.generics.submit", true),
};

export default GenericConfirmModal;
