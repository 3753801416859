import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@components/Button/Button";
import Menu from "@components/Menu/Menu";
import { StyledAlertText } from "@components/Modal/Modal.styles";
import GenericConfirmModal from "@components/Modal/Modals/GenericConfirmModal";
import ReportModal from "@components/Modal/Modals/ReportModal";
import TagChangeModal from "@components/Modal/Modals/TagChangeModal";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { PostDataActionTypes } from "@contexts/PostData";
import usePostDataAPI from "@contexts/PostData/usePostDataAPI";
import usePostUIAPI from "@contexts/PostUI/usePostUIAPI";
import { useCollapsePost } from "@contexts/UI";
import { EUIKey, useCloseModal, useOpenAlert, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { CardTypeTypes } from "@frontend/types/Post/postData";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./CardHeaderMenu.styles";

const CardHeaderMenu = ({ footer = false }) => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const location = useLocation();
	const navigate = useNavigate();
	const openAlert = useOpenAlert();
	const openModal = useOpenModal();
	const postDataAPI = usePostDataAPI();
	const postUIAPI = usePostUIAPI();
	const trackEvent = useTrackEvent();
	const collapsePost = useCollapsePost();

	const {
		id: memberId,
		brandAdministrator,
		followedTopics,
		memberTopicFollow,
		memberTopicUnfollow,
	} = useMemoizedContext("member", [
		"id",
		"brandAdministrator",
		"followedTopics",
	]);

	const {
		cardData,
	} = useMemoizedContext("postUI", [
		"cardData",
	]);

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		adType,
		brand,
		cardType,
		dispatch: dispatchPostData,
		following,
		hidden,
		id: postId,
		isEditable,
		isSolved,
		syncPostDataFromExpanded,
		topic,
		type,
		user,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"adType",
		"brand",
		"cardType",
		"following",
		"hidden",
		"id",
		"isEditable",
		"isSolved",
		"syncPostDataFromExpanded",
		"topic",
		"type",
		"user",
	]);

	const topicFollowing = useMemo(() => {
		if (topic) {
			for (let i = 0; i < followedTopics.length; i++) {
				if (topic.id === followedTopics[i].id) {
					return true;
				}
			}
		}
		return false;
	}, [topic, followedTopics.length])

	const [userUsed, setUserUsed] = useState(false);

	useEffect(() => {
		setUserUsed([CardTypeTypes.POST, CardTypeTypes.SERMO_CONTENT_CARD].includes(cardType)
			? user
			: brand);
	},[cardType, user, brand])

	const [name, setName] = useState(false);
	const [owner, setOwner] = useState(false);
	const [userFollowText, setUserFollowText] = useState("");
	const [anonymous, setAnonymous] = useState(true);
	const [isBrandAdmin, setIsBrandAdmin] = useState(false);
	const [followingUser, setFollowingUser] = useState(false);

	useEffect(() => {
		if ( userUsed ) {
			setName(userUsed.username
				? userUsed.username
				: userUsed.name);
			setOwner(memberId === userUsed.id
				? "self"
				: "other");
			setAnonymous(userUsed.anonymous);
			setFollowingUser(userUsed.following);
			// Only apply the brand administrator logic if the member itself is the brand administrator.
			setIsBrandAdmin(brandAdministrator && memberId === userUsed.id);
		}
	},[
		brandAdministrator,
		memberId,
		userUsed,
	]);

	useEffect(() => {
		setUserFollowText(followingUser
			? `Unfollow ${name}`
			: `Follow ${name}`);
	},[name, followingUser])

	// COPY LINK
	const copyLink = async () => {
		const canBeExpanded = type !== "Question" && !adType?.includes("InFeed");
		const urlForPost = `${window.location.origin}/post/${postId}${canBeExpanded
			? "/expanded"
			: ""}`;

		await navigator.clipboard.writeText(urlForPost);

		trackEvent({
			category: "overflow-post",
			action: "click",
			label: "copy-link",
			postId,
			adId: adId,
			adFrequency: adFrequency,
		});

		openAlert({
			[EUIKey.ALERT_COMPONENT]: <StyledAlertText>{getTranslation("frontend.alerts.copyLink")}</StyledAlertText>,
			[EUIKey.ALERT_LABEL]: "link-copied",
		});
	};

	// FOLLOW / UNFOLLOW POST
	const postFollowText = following
		? getTranslation("frontend.card.header.unfollowPost")
		: getTranslation("frontend.card.header.followPost");

	const followPostToggle = (open = true) => {
		closeModal("post-followed");

		if (following) {
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "unfollow-post",
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		} else {
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "follow-post",
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		}

		dispatchPostData({
			type: PostDataActionTypes.TOGGLE_FOLLOWING,
		});

		const endpoint = following
			? "posts/unfollowPost"
			: "posts/followPost";
		const requestBody = {
			id: postId,
		};
		apiEndpoint(endpoint, requestBody);

		if (open) {
			openAlert({
				[EUIKey.ALERT_COMPONENT]: <FollowPostAlert />,
				[EUIKey.ALERT_LABEL]: "post-followed",
			});
		}
	};

	const FollowPostAlert = () => (
		<>
			<StyledAlertText>
				{
					following
						? getTranslation("frontend.alerts.postUnfollowed")
						: getTranslation("frontend.alerts.postFollowed")
				}
			</StyledAlertText>
			<Button
				clickHandler={
					() => {
						followPostToggle(false);
					}
				}
				style="flatNeutral"
				size="small"
			>
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>
	);

	const postHideToggle = () => {
		closeModal("post-hidden");

		if (hidden) {
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "unhide-post",
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		} else {
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "hide-post",
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		}

		dispatchPostData({
			type: PostDataActionTypes.TOGGLE_HIDDEN,
		});
		// this method takes the current expanded context and updates the originating cards context with any changes
		if (
			expanded
			&& syncPostDataFromExpanded
		) {
			syncPostDataFromExpanded({
				...cardData,
				hidden: !hidden,
				syncPostDataFromExpanded: null,
			});
		}
		// ensure we collapse the post so the user can scroll the feed
		collapsePost();

		const endpoint = hidden
			? "posts/unhidePost"
			: "posts/hidePost";
		const requestBody = {
			id: postId,
		};
		apiEndpoint(endpoint, requestBody);

		if (!hidden) {
			openAlert({
				[EUIKey.ALERT_COMPONENT]: <HidePostAlert />,
				[EUIKey.ALERT_LABEL]: "post-hidden",
			});
		}
	};

	// HIDE POST
	const postHideText = hidden
		? getTranslation("frontend.card.header.unhidePost")
		: getTranslation("frontend.card.header.hidePost");
	const HidePostAlert = () => (
		<>
			<StyledAlertText>
				{
					hidden
						? getTranslation("frontend.alerts.postVisible")
						: getTranslation("frontend.alerts.postHidden")
				}
			</StyledAlertText>
			<Button
				clickHandler={postHideToggle}
				style="flatNeutral"
				size="small"
			>
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>
	);

	// FOLLOW TOPIC
	const getTopicText = () => {
		if (!topic) {
			return "";
		}

		return topicFollowing
			? getTranslation("frontend.card.header.unfollowTopic", true, topic.name)
			: getTranslation("frontend.card.header.followTopic", true, topic.name);
	};

	const getTopicDescription = () => {
		if (!topic) {
			return false;
		}

		return topicFollowing
			? getTranslation("frontend.card.header.topicUnfollowDescription", true, topic.name)
			: getTranslation("frontend.card.header.topicFollowDescription", true, topic.name);
	};

	const topicFollowToggle = useCallback((open = true, shouldFollow) => {
		closeModal("topic-follow");

		const follow = shouldFollow ?? !topicFollowing;

		if (!follow) {
			apiEndpoint("topics/unfollow", { topicId: topic.id });
			memberTopicUnfollow(topic);
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "unfollow-topic-" + topic.slug,
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		} else {
			apiEndpoint("topics/follow", { topicId: topic.id });
			memberTopicFollow(topic);
			trackEvent({
				category: "overflow-post",
				action: "click",
				label: "follow-topic-" + topic.slug,
				postId,
				adId: adId,
				adFrequency: adFrequency,
			});
		}

		if (open) {
			openAlert({
				[EUIKey.ALERT_COMPONENT]: <TopicFollowAlert />,
				[EUIKey.ALERT_LABEL]: "topic-follow",
			});
		}
	}, [followedTopics.length]);

	const TopicFollowAlert = () => (
		<>
			<StyledAlertText>
				{
					topicFollowing
						? getTranslation("frontend.alerts.unfollowedTopic", true, topic.name)
						: getTranslation("frontend.alerts.followTopic", true, topic.name)
				}
			</StyledAlertText>
			<Button
				clickHandler={
					() => {
						topicFollowToggle(false, topicFollowing);
					}
				}
				style="flatNeutral"
				size="small"
			>
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>
	);

	const markSolvedText = useMemo(() => isSolved
		? getTranslation("frontend.alerts.markUnsolved")
		: getTranslation("frontend.alerts.markSolved"), [isSolved]);

	// mark as solved
	const markAsSolved = useCallback((open = true, currentSolved) => {
		const isSolvedLocal = currentSolved ?? isSolved;
		closeModal("mark-solved");

		trackEvent({
			category: "overflow-post",
			action: "click",
			label: "mark-solved",
			postId,
			adId: adId,
			adFrequency: adFrequency,
		});

		const requestBody = {
			postId,
		};

		const endpoint = isSolvedLocal
			? "posts/markPatientCaseUnsolved"
			: "posts/markPatientCaseSolved";

		apiEndpoint(endpoint, requestBody);

		dispatchPostData({
			type: PostDataActionTypes.TOGGLE_IS_SOLVED,
		});

		if (open) {
			openAlert({
				[EUIKey.ALERT_COMPONENT]: <MarkSolvedAlert />,
				[EUIKey.ALERT_LABEL]: "mark-solved",
			});
		}
	}, [isSolved])

	const MarkSolvedAlert = () => (
		<>
			<StyledAlertText>
				{
					isSolved
						? getTranslation("frontend.alerts.patientCaseUnsolved")
						: getTranslation("frontend.alerts.patientCaseSolved")
				}
			</StyledAlertText>
			<Button
				clickHandler={
					() => {
						markAsSolved(false, !isSolved);
					}
				}
				style="flatNeutral"
				size="small"
			>
				{getTranslation("frontend.generics.undo")}
			</Button>
		</>
	);

	// CHANGE TAG
	const submitTopicChange = (topic) => {
		closeModal("change-tag");
		postDataAPI.selectTopic(topic);
	};

	const changeTag = () => {
		closeModal("change-tag");

		trackEvent({
			category: "overflow-post",
			action: "click",
			label: "change-tag",
			postId,
			adId: adId,
			adFrequency: adFrequency,
		});

		openModal({
			[EUIKey.MODAL_COMPONENT]: (
				<TagChangeModal
					postId={postId}
					topicChange={submitTopicChange}
					closeModalEventHandler={() => closeModal("change-tag")}
				/>
			),
			[EUIKey.MODAL_LABEL]: "change-tag",
			[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.changeTag.changeTag", true),
		});
	};

	// DELETE POST
	const confirmDeleteClose = () => {
		closeModal("confirm-delete");
	};

	const resetPageHistoryIfOnPost = () => {
		const postPageRegex = /\/post\/[0-9]+(\/expanded)?/;
		if (location.pathname.match(postPageRegex)) {
			const path = location.pathname.replace(postPageRegex, "")
			navigate(path, { replace: true });
		}
	};

	const deletePost = () => {
		trackEvent({
			category: "overflow-post",
			action: "click",
			label: "delete-post",
			postId,
			adId: adId,
			adFrequency: adFrequency,
		});

		dispatchPostData({
			type: PostDataActionTypes.SET_DELETED_TRUE,
		});

		apiEndpoint("posts/deletePost", { id: postId });

		// this method takes the current expanded context and updates the originating cards context with any changes
		if (
			expanded
			&& syncPostDataFromExpanded
		) {
			syncPostDataFromExpanded({
				...cardData,
				deleted: true,
				syncPostDataFromExpanded: null,
			});
		}

		confirmDeleteClose();
		resetPageHistoryIfOnPost();

		openAlert({
			[EUIKey.ALERT_COMPONENT]: <StyledAlertText>{getTranslation("frontend.alerts.postDeleted")}</StyledAlertText>,
			[EUIKey.ALERT_LABEL]: "post-deleted",
		});
	};

	const confirmDelete = () => {
		openModal({
			[EUIKey.MODAL_CLOSE_METHOD]: confirmDeleteClose,
			[EUIKey.MODAL_COMPONENT]: (
				<GenericConfirmModal
					submitEventHandler={deletePost}
					cancelModalEventHandler={confirmDeleteClose}
					submitText={getTranslation("frontend.generics.delete", true)}
					text={getTranslation("frontend.modals.deletePost.copy", true)}
				/>
			),
			[EUIKey.MODAL_LABEL]: "confirm-delete",
			[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.deletePost.deletePost", true),
		});
	};

	// REPORT POST
	const reportPostClose = () => {
		closeModal("report-post");
	};

	const reportPost = () => {
		trackEvent({
			category: "overflow-post",
			action: "click",
			label: "report-post",
			postId,
			adId: adId,
			adFrequency: adFrequency,
		});

		openModal({
			[EUIKey.MODAL_CLOSE_METHOD]: reportPostClose,
			[EUIKey.MODAL_COMPONENT]: (
				<ReportModal
					id={postId}
					closeModalEventHandler={reportPostClose}
				/>
			),
			[EUIKey.MODAL_LABEL]: "report-post",
			[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.reportPost.reportPost", true),
		});
	};

	const menuOptions = [
		// copy
		{
			onSponsored: false,
			label: "Copy link to post",
			props: {
				clickHandler: copyLink,
			},
		},
	];

	if (!isBrandAdmin) {
		// save / follow post
		menuOptions.push({
			onSponsored: false,
			label: postFollowText,
			description: following
				? getTranslation("frontend.card.header.topicUnfollowDescriptionAdmin")
				: getTranslation("frontend.card.header.topicFollowDescriptionAdmin"),
			props: {
				rightsRequired: ["canFollowItemsInFrontend"],
				clickHandler: followPostToggle,
			},
		});

		// hide post
		menuOptions.push({
			onSponsored: false,
			label: postHideText,
			description: hidden
				? getTranslation("frontend.card.header.postUnhideDescription")
				: getTranslation("frontend.card.header.postHideDescription"),
			props: {
				rightsRequired: ["canHideItemsInFrontend"],
				clickHandler: postHideToggle,
			},
		});

		// Follow user
		const userFollowHandler = () => {
			dispatchPostData({
				type: PostDataActionTypes.TOGGLE_USER_FOLLOWING,
			});
			const endpoint = user.following
				? "members/unfollowMember"
				: "members/followMember";
			const requestBody = {
				memberId: user.id,
			};

			apiEndpoint(endpoint, requestBody);

			openAlert({
				[EUIKey.ALERT_COMPONENT]: (
					<StyledAlertText>
						{
							userUsed.following
								? getTranslation("frontend.alerts.unfollowUser", true, name)
								: getTranslation("frontend.alerts.followUser", true, name)
						}
					</StyledAlertText>
				),
				[EUIKey.ALERT_LABEL]: "user-follow",
			});
		};

		// follow
		menuOptions.push({
			owner: "other",
			onAnon: false,
			label: userFollowText,
			props: {
				rightsRequired: ["canFollowItemsInFrontend"],
				clickHandler: userFollowHandler,
			},
		});

		// follow topic
		menuOptions.push({
			dependency: topic,
			label: getTopicText(),
			description: getTopicDescription(),
			props: {
				rightsRequired: ["canFollowItemsInFrontend"],
				clickHandler: topicFollowToggle,
			},
		});
	}

	menuOptions.push({
		border: true,
	});

	// Mark as solved
	menuOptions.push({
		owner: "self",
		checkPatientCase: true,
		label: markSolvedText,
		props: {
			rightsRequired: ["canCreateEditAndDeletePostsInFrontend"],
			clickHandler: () => {
				markAsSolved();
			},
		},
	});

	// change tag
	menuOptions.push({
		owner: "self",
		label: "Change tag",
		props: {
			clickHandler: changeTag,
			rightsRequired: ["canCreateEditAndDeletePostsInFrontend"],
		},
	});

	// edit post
	menuOptions.push({
		checkEditable: true,
		label: getTranslation("frontend.card.header.editPost"),
		props: {
			rightsRequired: ["canCreateEditAndDeletePostsInFrontend"],
			clickHandler: () => {
				postUIAPI.loadDraft();
			},
		},
	});

	// delete post
	menuOptions.push({
		owner: "self",
		label: getTranslation("frontend.card.header.delete"),
		props: {
			rightsRequired: ["canCreateEditAndDeletePostsInFrontend"],
			clickHandler: () => {
				confirmDelete();
			},
		},
	});

	if (!isBrandAdmin) {
		// report post
		menuOptions.push({
			onSponsored: false,
			forCardTypes: [CardTypeTypes.SERMO_CONTENT_CARD],
			owner: "other",
			label: getTranslation("frontend.card.header.reportPost"),
			props: {
				clickHandler: () => {
					reportPost();
				},
			},
		});
	}

	return (
		<ModuleWrapper
			padded={false}
			solid={true}
			border={true}
			contextClass={
				`card-header-menu${footer
					? "-footer"
					: ""}`
			}
		>
			<Menu
				dataComponent={"CardHeaderMenu"}
			>
				{
					menuOptions.map((menuOption, i) => {
						if (
							!Object.prototype.hasOwnProperty.call(menuOption,"owner")
							|| owner === menuOption.owner
							|| menuOption.forCardTypes?.includes(cardType)
						) {
							if (
								menuOption.checkEditable
								&& !isEditable
							) {
								return false;
							}
							if (
								Object.prototype.hasOwnProperty.call(menuOption,"dependency")
								&& "undefined" === typeof menuOption.dependency
							) {
								return false;
							}
							if (Object.prototype.hasOwnProperty.call(menuOption,"onSponsored") && "Ad" === cardType) {
								return false;
							}
							if (anonymous && Object.prototype.hasOwnProperty.call(menuOption,"onAnon")
							&& !menuOption.onAnon
							) {
								return false;
							}
							if (Object.prototype.hasOwnProperty.call(menuOption,"checkPatientCase")
							&& "PatientCase" !== type
							) {
								return false;
							}
							return (
								<React.Fragment key={i}>
									{
										!menuOption.border && (
											<Button
												theme="light"
												style="flatNeutral"
												alignment="start"
												size="small"
												{...menuOption.props}
											>
												<Styles.ButtonContent>
													<Styles.MenuOptionTitle>
														{menuOption.label}
													</Styles.MenuOptionTitle>
													{
														menuOption.description && (
															<Styles.MenuOptionDescription>
																{menuOption.description}
															</Styles.MenuOptionDescription>
														)
													}
												</Styles.ButtonContent>
											</Button>
										)
									}
									{menuOption.border && <Styles.Border/>}
								</React.Fragment>
							);
						}
						return false;
					})
				}
			</Menu>
		</ModuleWrapper>
	);
};

CardHeaderMenu.propTypes = {
	footer: PropTypes.bool,
};

CardHeaderMenu.defaultProps = {
	footer: false,
};

export default CardHeaderMenu;
