import { ColorRed50, ColorYellow70 } from "@sermo/design-tokens";
import { css } from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const applyModuleTop = (type = "default") => css`
		position: relative;

		&:before {
			height: 8px;
			border-radius: 8px 8px 0 0;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			content: "";
			background: linear-gradient(-90deg, #852dff 0%, #bb59ff 100%);
			${"warning" === type && `background: ${ColorYellow70};`}
			${"alert" === type && `background: ${ColorRed50};`}

			${StyledMobileAndTabletPortraitQuery} {
				height: 4px;
				border: 0;
				border-radius: 0;
			}
		}
	`;