import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useMatch } from "react-router-dom";
import SponsoredVideoPlayer from "@components/Card/components/VideoPlayer/SponsoredVideoPlayer";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./Featured.styles";

const FeaturedImage = ({ featured }) => {
	const { url } = featured;

	return useMemo(() => (
		<Styles.FeaturedImage
			classname="featured-image"
			src={url}
		/>
	), [
		url,
	]);
};

FeaturedImage.propTypes = {
	featured: PropTypes.object.isRequired,
};

const Featured = ({ clickHandler, contextClass }) => {
	const isAdPreview = !!useMatch("/ad-preview/*");

	const {
		expanded,
	} = useMemoizedContext("postUIViewing", [
		"expanded",
	]);

	const {
		adFrequency,
		adId,
		adType,
		attachments,
		cardType,
		clickTags,
		ctaUrl,
		eventId,
		eventPreviewId,
		fieldsView,
		id,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"adType",
		"attachments",
		"cardType",
		"clickTags",
		"ctaUrl",
		"eventId",
		"eventPreviewId",
		"fieldsView",
		"id",
	]);

	const featured = attachments.find((attachment) => Object.prototype.hasOwnProperty.call(attachment, "featured") && attachment.featured);

	if (
		!featured
		|| ("InFeedViewOnly" === featured.inFeedAttachmentDisplayType && expanded )
	) {
		return null;
	}

	const shouldRemoveBottomPadding = !["InFeedUnit", "InFeedCarousel"].includes(adType) && !expanded;
	const { type, isExpandedToFit } = featured;

	const clickContent = (e) => {
		// for posts
		if (clickTags?.imageVideoClickToExpand) {
			clickTags?.imageVideoClickToExpand.forEach((url) => {
				fetchUrl(url);
			})
		}
		// for infeed units
		if (clickTags?.imageClickToCTA) {
			clickTags?.imageClickToCTA.forEach((url) => {
				fetchUrl(url);
			})
		}
		e.eventSource = "featured";
		clickHandler(e);
	};

	const getStyles = () => [
		"featured-item-container",
		{ "featured-expanded": expanded },
		{ [`context-${contextClass}`]: contextClass },
		{ "remove-top-padding": cardType === "Ad" },
		{ "remove-bottom-padding": shouldRemoveBottomPadding },
		{ "is-expanded-to-fit": isExpandedToFit },
		{ "headline-and-read-more": (fieldsView === "HeadlineAndReadMore" && !expanded) },
	]

	if ("Image" === type) {
		if (ctaUrl && "InFeedEvent" !== adType) {
			return (
				<Styles.FeaturedItemAnchor
					href={ctaUrl}
					target="_blank"
					rel="noreferrer"
					onClick={clickContent}
					className={classNames(...getStyles())}
				>
					<FeaturedImage featured={featured} />
				</Styles.FeaturedItemAnchor>
			);
		} else {
			if ("InFeedEvent" === adType) {
				return (
					<Styles.FeaturedItemAnchor
						href={
							isAdPreview && eventPreviewId
								? `/preview/event/${eventPreviewId}`
								: `/event/${eventId}`
						}
						onClick={clickContent}
						className={classNames(...getStyles())}
					>
						<FeaturedImage featured={featured} />
					</Styles.FeaturedItemAnchor>
				);
			}
			else {
				return (
					<Styles.FeaturedItemContainer
						onClick={clickContent}
						className={classNames(...getStyles())}
					>
						<FeaturedImage featured={featured} />
					</Styles.FeaturedItemContainer>
				);
			}
		}
	}

	if (["Mp4Video"].includes(type)) {
		return (
			// update 3 times per second
			<Styles.FeaturedItemContainer className={classNames(...getStyles())}>
				<SponsoredVideoPlayer
					featured={featured}
					cardType={cardType}
					adType={adType}
					expanded={expanded}
					postId={id}
					adId={adId}
					clickTags={clickTags}
					adFrequency={adFrequency}
				/>
			</Styles.FeaturedItemContainer>
		);
	}

	console.warn(`unsupported feature attachment of type ${type} used on ad ${id}`);
	return null;
};

Featured.propTypes = {
	clickHandler: PropTypes.func,
	contextClass: PropTypes.string,
};

Featured.defaultProps = {
	clickHandler: () => {},
};

export default Featured;
