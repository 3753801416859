import { useMatch, useSearchParams } from "react-router-dom";
import Card from "@components/Card/Card";
import { Unsupported } from "@components/MediaQueries/MediaQueries";
import Modal from "@components/Modal/Modal";
import { useMemoizedContext } from "@hooks/Hooks";

const Modals = () => {
	const isRWP = !!useMatch("/runwithpeter/*");
	const [searchParams] = useSearchParams();

	const {
		alertCanClose,
		alertCloseMethod,
		alertComponent,
		alertIsOpen,
		alertLabel,
		alertPersist,
		alertSubtype,
		alertTextLabel,
		modalBackgroundDarkened,
		modalCanClose,
		modalCloseMethod,
		modalComponent,
		modalIsOpen,
		modalLabel,
		modalOverTheTop,
		modalSubtype,
		modalTextLabel,
	} = useMemoizedContext("ui", [
		"alertCanClose",
		"alertCloseMethod",
		"alertComponent",
		"alertIsOpen",
		"alertLabel",
		"alertPersist",
		"alertSubtype",
		"alertTextLabel",
		"modalBackgroundDarkened",
		"modalCanClose",
		"modalCloseMethod",
		"modalComponent",
		"modalIsOpen",
		"modalLabel",
		"modalOverTheTop",
		"modalSubtype",
		"modalTextLabel",
	]);

	return (
		<>
			{
				!isRWP
				&& !searchParams.has("component")
				&& (
					<Unsupported>
						<Modal canClose={false}>Please Rotate Your Phone</Modal>
					</Unsupported>
				)
			}
			{
				alertIsOpen
				&& (
					<Modal
						canClose={alertCanClose}
						clickHandler={alertCloseMethod}
						label={alertLabel}
						modalType={"alert"}
						persist={alertPersist}
						subType={alertSubtype}
						textLabel={alertTextLabel}
					>
						{alertComponent}
					</Modal>
				)
			}
			{
				modalIsOpen
				&& (
					<Modal
						backgroundDarkened={modalBackgroundDarkened}
						canClose={modalCanClose}
						clickHandler={modalCloseMethod}
						label={modalLabel}
						modalType={"modal"}
						overTheTop={modalOverTheTop}
						subType={modalSubtype}
						textLabel={modalTextLabel}
					>
						{modalComponent}
					</Modal>
				)
			}
			<Unsupported>
				<Modal canClose={false}>Please Rotate Your Phone</Modal>
			</Unsupported>
		</>
	);
};

export default Modals;
