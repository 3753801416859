import React from "react";
import Answer from "@components/Card/components/Answer/Answer";
import Comments from "@components/Card/components/Comments/Comments";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SponsoredContentInFeed from "@components/PostContent/components/SponsoredContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader/CardHeader";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
const SponsoredQuestionOpenEnded = () => (<CardContainer>
		<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
			<SharedStyles.AdSpacingContainer>
				<CardHeader />
				<div>
					<SponsoredContentInFeed />
					<Answer />
				</div>
			</SharedStyles.AdSpacingContainer>
		</ModuleWrapper>
		{/* @ts-ignore*/}
		<Comments />
		<CardFooter />
	</CardContainer>);
export default SponsoredQuestionOpenEnded;
