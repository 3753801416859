import { TextStyled } from "@sermo/ui-components/unstable";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { DirectMessageAdFooter } from "@components/DetachedFooter";
import { STICKY_LOCATION } from "@components/Layout";
import { Header } from "@components/Messaging/components/MessageWindow/Header";
import ProfilePic from "@components/ProfilePic/ProfilePic";
import { DetachedFooterProvider } from "@frontend/contexts/DetachedFooter";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { fetchUrl } from "@frontend/Utils";
import { useApiEndpoint } from "@hooks/Hooks";
import * as Styles from "./SharedDirectMessageAd.styles";
const SharedDirectMessageAd = (data) => {
    const trackEvent = useTrackEvent();
    const apiEndpoint = useApiEndpoint();
    const { dma, profilePic, username } = data;
    const isDMAPreview = !!useMatch("/dma-preview/*");
    const { adId, attachments, clickTags, contentHtml, ctaText, ctaUrl, id, isiText, headerText, footerLink, footerLinkText, imageLink, additionalFooterText, isiLocation, footnoteText, footnoteLocation, customStyle, footnoteAdStyle, } = dma;
    const { footerHeight, headerHeight, } = useMemoizedContext("ui", [
        "footerHeight",
        "headerHeight",
    ]);
    const { queryParams, locale, } = useMemoizedContext("member", [
        "queryParams",
        "locale",
    ]);
    const useStyles = createUseStyles({
        customIsi: { ...(customStyle || null) },
        customFootnote: { ...(footnoteAdStyle || null) },
    });
    const customClasses = useStyles();
    const [tagsTracked, setTagsTracked] = useState(false);
    useEffect(() => {
        if (!tagsTracked && clickTags?.view) {
            setTagsTracked(true);
            clickTags?.view.forEach((url) => {
                fetchUrl(url);
            });
        }
    }, []);
    // TRACKING
    const trackOpenEvent = (adId) => {
        apiEndpoint("sc/getdmaaction", { adId: adId }).then((dataResponse) => {
            dataResponse.json().then((body) => {
                if (dataResponse.ok) {
                    trackEvent({
                        category: "directmessagead",
                        action: body.action,
                        label: "message",
                        postId: id,
                        adId: adId,
                        sponsored: true,
                        queryParams: queryParams,
                        locale: locale,
                    });
                }
            });
        });
    };
    const trackViewEvent = (adId) => {
        trackEvent({
            category: "directmessagead",
            action: "view",
            label: "message",
            postId: id,
            adId: adId,
            sponsored: true,
            queryParams: queryParams,
            locale: locale,
        });
    };
    useEffect(() => {
        if (!isDMAPreview) {
            trackOpenEvent(adId);
        }
        else {
            trackViewEvent(adId);
        }
    }, []);
    const onCtaClick = (e) => {
        trackEvent({
            category: "directmessagead",
            action: "click",
            label: "cta-clicked",
            url: ctaUrl,
            area: "cta_button",
            postId: id,
            adId: adId,
            sponsored: true,
            queryParams: queryParams,
            locale: locale,
        });
    };
    const onImageClick = (e) => {
        trackEvent({
            category: "directmessagead",
            action: "click",
            label: "image-clicked",
            url: imageLink,
            area: "image",
            postId: id,
            adId: adId,
            sponsored: true,
            queryParams: queryParams,
            locale: locale,
        });
    };
    const trackLinkClick = (e, area) => {
        if ("A" === e.target.tagName) {
            if (e.target.hasAttribute("href")) {
                let href = e.target.getAttribute("href");
                let category = "directmessagead";
                let action = "click";
                let label = "link";
                let title = e.target.textContent || e.target.temporalDivElement;
                if (area) {
                    if (typeof area !== "string") {
                        if (e.eventSource) {
                            area = e.eventSource.area;
                            action = "download";
                            label = e.eventSource.document;
                        }
                    }
                }
                else {
                    // setting default value if not specified
                    area = "directmessage";
                }
                trackEvent({
                    category,
                    action,
                    label,
                    area,
                    url: href,
                    urlTitle: title,
                    postId: id,
                    adId: adId,
                    locale,
                    queryParams,
                    sponsored: true,
                });
            }
        }
    };
    const onHeaderAreaClick = (e) => {
        if (ctaText !== e.target.textContent) {
            trackEvent({
                category: "directmessagead",
                action: "click",
                label: "cta-clicked",
                area: "header",
                url: ctaUrl,
                postId: id,
                adId: adId,
                locale,
                queryParams,
                sponsored: true,
            });
            window.open(ctaUrl, "_blank");
        }
    };
    const onBodyAreaClick = (e) => {
        trackLinkClick(e, "body");
    };
    const onISIAreaClick = (e) => {
        trackLinkClick(e, "isi");
    };
    const onFooterAreaClick = (e) => {
        trackLinkClick(e, "footer");
    };
    return (<Styles.MessageWindow className={`${customClasses.customIsi} ${customClasses.customFootnote}`} $subtractFromHeight={headerHeight + footerHeight}>
			<Header {...data} isDma={!!adId} aId={adId}/>
			<Styles.CTA $kind={"bodyShort03"} onClick={onHeaderAreaClick}>
				{headerText}
				<Button size="small" to={ctaUrl} external={true} target={ctaUrl?.includes(window?.location?.host) && !ctaUrl?.includes("/ccta/")
            ? "_self"
            : "_blank"} clickHandler={onCtaClick}>
					{ctaText}
				</Button>
			</Styles.CTA>
			<Styles.Content>
				<Styles.ProfilePic>
					<ProfilePic user={{ profilePic: profilePic }} size="medium"/>
				</Styles.ProfilePic>
				<Styles.Message>
					<Styles.Name $kind={"bodyShort02"}>
						{username}
					</Styles.Name>
					<Styles.MessageContent dangerouslySetInnerHTML={{ __html: contentHtml }} $kind={"bodyLong03"} onClick={onBodyAreaClick}/>
					{footerLink
            && footerLinkText
            && (<Styles.FooterLink $kind={"bodyLong03"} onClick={onFooterAreaClick}>
								<a target="_blank" rel="noreferrer" href={footerLink}>
									{footerLinkText}
								</a>
							</Styles.FooterLink>)}
				</Styles.Message>
				{attachments?.length && attachments[0].url && (<>
							<Styles.Ad>
								<Styles.Sponsored $kind={"bodyShort01"}>
									Sponsored
								</Styles.Sponsored>
								<a href={imageLink} onClick={onImageClick} target={imageLink?.includes(window?.location?.host) && !ctaUrl?.includes("/ccta/")
                ? "_self"
                : "_blank"} rel="noreferrer">
									<img alt={"Failed to load"} src={attachments[0].url}/>
								</a>
							</Styles.Ad>
						</>)}
			</Styles.Content>
			{isiText
            && isiLocation !== STICKY_LOCATION
            && (<Styles.Footer $kind={"bodyLong03"} onClick={onISIAreaClick} className={"isi"}>
						<div dangerouslySetInnerHTML={{ __html: isiText }}/>
					</Styles.Footer>)}
			{isiText
            && isiLocation === STICKY_LOCATION
            && (<DirectMessageAdFooter type={"isi"} text={isiText} customStyle={customStyle}/>)}
			{footnoteText
            && footnoteLocation !== STICKY_LOCATION
            && (<Styles.Footer $kind={"bodyLong03"} onClick={onFooterAreaClick} className={"footnote"}>
						<div dangerouslySetInnerHTML={{ __html: footnoteText }}/>
					</Styles.Footer>)}
			{footnoteText
            && footnoteLocation === STICKY_LOCATION
            && (<DirectMessageAdFooter type={"footnote"} text={footnoteText} customStyle={footnoteAdStyle}/>)}
			{additionalFooterText
            && (<Styles.AdditionalFooterText>
						<Styles.Message>
							<TextStyled $kind={"bodyLong03"} dangerouslySetInnerHTML={{ __html: additionalFooterText }}/>
						</Styles.Message>
					</Styles.AdditionalFooterText>)}
		</Styles.MessageWindow>);
};
const SharedDirectMessageAdWithContext = (props) => (<DetachedFooterProvider>
			<SharedDirectMessageAd {...props}/>
		</DetachedFooterProvider>);
export default SharedDirectMessageAdWithContext;
