import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import { ContentContainer, contentContainerClickHandler, insertReadMore, maxLength, sanitizeLinks, useContentAreaClickEventHandler, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import ReadMore from "@components/PostContent/components/ReadMore";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { AdTypeTypes, PostTypeTypes } from "@frontend/types/Post/postData";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./SponsoredContentInFeed.styles";
const SponsoredContentInFeed = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showIntroduction, showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showIntroduction",
        "showOriginalContent",
    ]);
    const { adType, contentHtml, fieldsView, id, introduction, originalContentHtml, postReadMorePosition, type, } = useMemoizedContext("postData", [
        "adType",
        "contentHtml",
        "fieldsView",
        "id",
        "introduction",
        "originalContentHtml",
        "postReadMorePosition",
        "type",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : contentHtml;
        if (PostTypeTypes.QUESTION !== type) {
            if (introduction
                && showIntroduction) {
                if ("Inline" === postReadMorePosition) {
                    return insertReadMore(introduction, `read-more-portal-${id}`);
                }
                return introduction;
            }
            // Check for max length of string, and shorten accordingly
            if (editorContent.length > maxLength
                && AdTypeTypes.SPONSORED === adType) {
                // The initial trimmed string
                let trimmedString = editorContent.substring(0, maxLength);
                // Trim again to avoid breaking a word and add ...
                trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
                if ("Inline" === postReadMorePosition) {
                    return `${trimmedString}...<span id=read-more-portal-${id}></span>`;
                }
                return `${trimmedString}...`;
            }
        }
        if (PostTypeTypes.QUESTION === type
            && AdTypeTypes.SPONSORED === adType
            && introduction) {
            return introduction;
        }
        return sanitizeLinks(editorContent);
    }, [
        adType,
        contentHtml,
        id,
        introduction,
        originalContentHtml,
        postReadMorePosition,
        showIntroduction,
        showOriginalContent,
        type,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || [
                "HeadlineAndReadMore",
                "HeadlineOnly",
                "NoText",
            ].includes(fieldsView)
            || !contentToDisplay) {
            return null;
        }
        return (<>
				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}

				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={PostTypeTypes.QUESTION === type
                ? "heading03"
                : "bodyLong02"} $legacy={PostTypeTypes.QUESTION !== type} $zeroMarginBottom={PostTypeTypes.QUESTION === type} onClick={contentAreaClickEventHandler}/>
					<ReadMore />
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentToDisplay,
        fieldsView,
        id,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
        type,
    ]);
};
export default SponsoredContentInFeed;
