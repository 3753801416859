import { useEffect } from "react";
import Comments from "@components/Card/components/Comments/Comments";
import Media from "@components/Card/components/Media/Media";
import PollDisplay from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import { ResourceCenterFooter } from "@components/DetachedFooter";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import ResourceContentExpanded from "@components/PostContent/components/ResourceContentExpanded";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./ResourceItemExpanded.styles";
const ResourceItemExpanded = () => {
    const { cardData, } = useMemoizedContext("postUI", [
        "cardData",
    ]);
    const { disableCommenting } = cardData;
    const trackEvent = useTrackEvent();
    useEffect(() => {
        trackEvent({
            category: "resourceitem",
            label: "expanded",
            action: "view",
        });
    }, []);
    return (<>
			<CardContainer>
				<ModuleWrapper solid={true} paddedWider={true} notPaddedY={true}>
					<Styles.SpacingContainer>
						<CardHeader />
						<Title />
						<Media />
						<ResourceContentExpanded />
						<Styles.PollDisplayContainer>
							<PollDisplay />
						</Styles.PollDisplayContainer>
						<CardFooter />
					</Styles.SpacingContainer>
				</ModuleWrapper>
				{!disableCommenting
            && (<Styles.CommentContainer>
							{/* @ts-ignore*/}
							<Comments />
						</Styles.CommentContainer>)}
				{cardData.footnoteText && (<ResourceCenterFooter type={"footnote"} text={cardData.footnoteText} customStyle={cardData.footnoteAdStyle}/>)}
				{cardData.isiText && (<ResourceCenterFooter type={"isi"} text={cardData.isiText} customStyle={cardData.customStyle}/>)}
			</CardContainer>
		</>);
};
export default ResourceItemExpanded;
