import { Button } from "@sermo/ui-components";
import React from "react";
import { useTrackEvent } from "@frontend/tracking/tracking";
import getTranslation from "@translation/translation";
import * as Styles from "./ReadOnlyModal.styles";

export const ReadOnlyModal = () => {
	const trackEvent = useTrackEvent();

	React.useEffect(() => {
		trackEvent({
			category: "read-only-modal",
			label: "on-load",
			action: "view",
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleButtonClick = () => {
		trackEvent({
			category: "read-only-modal",
			label: "view-resources-button",
			action: "click",
		});
	};

	return (
		<Styles.ModalWrap>
			<Styles.VerificationPictogram
				src="/assets/images/verification-pictogram.svg"
				alt="verification pictogram"
			/>
			<Styles.TitleText>
				{getTranslation("frontend.modals.readonly.headerText", true)}
			</Styles.TitleText>
			<Styles.BodyText>
				{getTranslation("frontend.modals.readonly.bodyText01")}
			</Styles.BodyText>
			<Styles.BodyText>
				{getTranslation("frontend.modals.readonly.bodyText02")}
			</Styles.BodyText>
			<Styles.HorizontalRule />
			<Styles.FooterWrap>
				<Styles.BodyText>
					{getTranslation("frontend.modals.readonly.bodyText03", true)}
				</Styles.BodyText>
				<a href="https://www.sermo.com/resources/category/physician-resources/">
					<Button
						onClick={handleButtonClick}
						size="medium"
					>
						{getTranslation("frontend.modals.readonly.buttonText", true)}
					</Button>
				</a>
			</Styles.FooterWrap>
		</Styles.ModalWrap>
	);
};