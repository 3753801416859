import { useCloseModal } from "@contexts/UI";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalTitle, ModalFooter } from "../ModalComponents";

const ReadOnlyModal = () => {
	const closeModal = useCloseModal();

	return (
		<ModalContainer>
			<ModalTitle>
				{getTranslation("frontend.modals.readonly.title")}
			</ModalTitle>
			<ModalSection>
				<ModalText>
					{getTranslation("frontend.modals.readonly.description")}
				</ModalText>
			</ModalSection>
			<ModalFooter
				{...{
					submitEventHandler: () => closeModal("readonly"),
					submitText: getTranslation("frontend.generics.done", true),
				}}
			/>
		</ModalContainer>
	);
}

export default ReadOnlyModal;
