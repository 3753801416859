import { datadogRum } from "@datadog/browser-rum";
import { Theme, useTheme } from "@sermo/ui-components";
import isMobile from "ismobilejs";
import { Suspense, useEffect } from "react";
import GlobalState from "./components/GlobalState/GlobalState";
import { ErrorBoundary } from "./components/Helpers/Helpers";
import { Loading } from "./components/Helpers/Helpers";
import Layout from "./components/Layout";
import AppRoutes from "./components/Routes";
import { mainApplicationReady } from "./mobile-app";
// TODO: make 2 separate imports so we can load this without module compilation
import "./scss/main.scss";
import color from "./scss/color-variables.scss";
import layout from "./scss/layout-variables.scss";
import { MembershipGlobalStyles } from "./styled";

const DDEnvironmentVariables = {
	development: {
		env: "development",
		sessionSampleRate: 0,
		sessionReplaySampleRate: 0,
		traceSampleRate: 0,
		allowedTracingUrls: [
			(url) => url.startsWith("http://localhost:32004"),
			(url) => url.startsWith("http://localhost:5000")
		]
	},
	internal: {
		env: "internal",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://internal-membership.stage.s3o.red"),
		]
	},
	staging: {
		env: "staging",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://staging-membership.stage.s3o.red"),
		]
	},
	production: {
		env: "production",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://app.sermo.com"),
		]
	}
}

if (window?.sermo?.env) {
	datadogRum.init({
		...DDEnvironmentVariables[window.sermo.env],
		applicationId: "42f86b38-55b1-4bf4-88ba-925471f071c7",
		clientToken: "pub175f5173b2906b6ecec25346de4e445e",
		site: "datadoghq.com",
		service: "frontend",
		version: window?.sermo?.versionHash,
		trackInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input"
	});

	datadogRum.startSessionReplayRecording();
}

const App = () => {
	useEffect(() => {
		mainApplicationReady();
	}, []);

	const theme = useTheme();

	return (
		<ErrorBoundary>
			<Theme
				value={{ ...theme, color, layout }}
				globalStyle={MembershipGlobalStyles}
			>
				<GlobalState>
					<Layout>
						<Suspense fallback={<Loading contextClass={"lazy-component"}/>}>
							<AppRoutes/>
						</Suspense>
					</Layout>
				</GlobalState>
			</Theme>
		</ErrorBoundary>
	);
};

export default App;

/**
 * Added this global error handler, as React error boundry does not catch errors in:
 *    - Event handlers.
 *    - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks) without await.
 *    - Errors thrown in the error boundary itself (rather than its children).
 */
window.addEventListener("error", (e) => {
	const { phone, tablet, device } = isMobile(navigator.userAgent).android;

	// Ignore the following errors on mobile devices.
	if (phone || tablet || device) {
		const mobileRegexes = [
			// avoids sending the flushControlled error from slatejs on android to the server
			/^Uncaught TypeError: [A-Za-z]+ is not a function$/,
			// avoids sending the flushControlled error from slatejs on android to the server
			/^Uncaught TypeError: Cannot read property 'text' of null$/,
			// "HeyTapBrowser" errors.
			/^Uncaught TypeError: Cannot read property 'getReadMode(Render|Config|Extract)' of undefined$/,

		];

		if (mobileRegexes.some((regex) => e.message.match(regex))) {
			e.stopPropagation();
			return;
		}
	}

	// Ignore these errors on all devices.
	const regexes = [
		// Can be ignored. https://stackoverflow.com/a/50387233/220900
		/^ResizeObserver loop limit exceeded$/,
	];

	if (regexes.some((regex) => e.message.match(regex))) {
		e.stopPropagation();
		return;
	}
});

if (!("toJSON" in Error.prototype)) {
	Object.defineProperty(Error.prototype, "toJSON", {
		value: function () {
			var alt = {};

			Object.getOwnPropertyNames(this).forEach(function (key) {
				alt[key] = this[key];
			}, this);

			return alt;
		},
		configurable: true,
		writable: true,
	});
}
