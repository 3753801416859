import { Icon, NewPostSVG } from "@sermo/ui-components";
import { useLocation } from "react-router-dom";
import { ClickStop } from "@components/Helpers/Helpers";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { CreatePostButton } from "@components/NewPost/components/TypeSelector/TypeSelector.styles";
import { left, rightPreview as right } from "./MobileBottomNav.data";
import * as Styles from "./MobileBottomNav.styles";
const MobileBottomNavPreview = () => {
    const { pathname } = useLocation();
    const renderItems = ({ component, icon, label, to }) => (<Styles.Link data-component={`${label}Nav`} key={label} to={to}>
			<Styles.Selection $isSelected={pathname.startsWith(to)} $kind={"bodyShort00"}>
				{component
            ? component
            : <Icon height={24} width={24} src={icon}/>}
				{label}
			</Styles.Selection>
		</Styles.Link>);
    return (<MobileAndTabletPortrait>
			<ClickStop>
				<Styles.PreviewContainer data-component={"MobileBottomNav"}>
					<Styles.Left>
						{left.map(renderItems)}
					</Styles.Left>
					<CreatePostButton>
						<Icon src={NewPostSVG} width={28} height={28}/>
					</CreatePostButton>
					<Styles.Right>
						{right.map(renderItems)}
					</Styles.Right>
				</Styles.PreviewContainer>
			</ClickStop>
		</MobileAndTabletPortrait>);
};
export default MobileBottomNavPreview;
