import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const EnrolledProgramContainer = styled.div`
	padding: 24px 0 0;
	display: flex;
	align-items: center;

	& > * {
		color: ${({ theme }) => theme.color.text03};
	}

	& > svg {
		margin-right: 4px;
	}
`;

export const AvailableSurveys = styled.div``;

export const AvailableSurveyCardContainer = styled.div`
	padding-bottom: 16px;
`;

export const AvailableSurveyCard = styled.div`
	padding: 16px 0;
	display: flex;
	flex-direction: column;

	${StyledMobileAndTabletPortraitQuery} {
		display: block;
		padding-bottom: 0;
	}
`;

export const TopRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const BottomRow = styled.div`
	display: flex;
	align-items: flex-end;

	${StyledMobileAndTabletPortraitQuery} {
		display: block;
	}
`;

export const LeftColumn = styled.div`
	flex-grow: 1;
`;

export const RightColumn = styled.div`
	padding-left: 96px;

	&:empty {
		display: none;
	}

	${StyledMobileAndTabletPortraitQuery} {
		padding-left: 32px;
	}
`;

export const RightColumnWithButton = styled(RightColumn)`
	${StyledMobileAndTabletPortraitQuery} {
		padding-left: 0;
	}
`;

export const AvailableSurveyCardLeftColumn = styled.div`
	padding-right: 64px;
	flex-grow: 1;
`;

export const AvailableSurveyCardRightColumn = styled.div`
	flex: 0 0 156px;
	padding: 0 10px 0 28px;
	text-align: center;
`;

export const Tag = styled.div`
	display: inline-block;
	position: relative;
	top: 5px;
	color: ${({ theme }) => theme.color.text03};
`;

export const TagInProgress = styled(Tag)`
	border-radius: 2px;
	border: 1px solid ${({ theme }) => theme.color.ui03};
	padding: 0 2px;
`;

export const TagAlmostFull = styled(Tag)`
	color: ${({ theme }) => theme.color.support04};
`;

export const SurveyName = styled.div`
	color: ${({ theme }) => theme.color.text01};
	display: block;
`;

export const SurveyDescription = styled.div`
	padding: 6px 0;
	display: block;

	& > * {
		color: ${({ theme }) => theme.color.text03};
	}
`;

export const SurveyCallout = styled.div`
	margin: 8px 0 12px 0;
	line-height: 14px;
	height: 16px;
`;

export const SurveyCalloutIcon = styled.div`
	display: inline-block;
	margin-top: 1px;
	width: 14px;
	height: 14px;
	border: 2px solid ${({ theme }) => theme.color.support02};
	border-radius: 7px;
	margin-right: 5px;
`;

export const SurveyCalloutText = styled.div`
	vertical-align: top;
`;

export const InnerCard = styled.div`
	display: block;
	width: 100%;
	margin: 16px 0 0 0;
`;

export const InnerCardTitle = styled.div`
	color: ${({ theme }) => theme.color.text03};
	display: inline-block;
	width: 50%;
`;

export const InnerCardValue = styled.div`
	color: ${({ theme }) => theme.color.text01};
	display: inline-block;
	width: 50%;
	text-align: right;
`;

export const InnerCardValueLimited = styled(InnerCardValue)`
	color: ${({ theme }) => theme.color.support04};
`;

export const EarnTitle = styled.div`
	color: ${({ theme }) => theme.color.text03};
	display: block;
`;

export const EarnAmount = styled.div`
	display: block;
	color: ${({ theme }) => theme.color.text01};
	font-family: "Sailec", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 44px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: -0.3px;
	text-transform: none;
	text-size-adjust: none;


	${StyledMobileAndTabletPortraitQuery} {
		font-size: 28px;
		text-align: right;
		padding: 0;
	}
`;

export const ActionButton = styled.div`
	display: block;

	${StyledMobileAndTabletPortraitQuery} {
		margin-top: 26px;
	}
`;

export const MobileTitleIncentiveContainer = styled.div`
	display: flex;
`;

export const MobileTitleContainer = styled.div`
	margin-right: 30px;
`;

export const MobileIncentiveContainer = styled.div`
	flex: 0 0 80px;
	flex-grow: 1;
`;