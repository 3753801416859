import React, { useReducer } from "react";
import { RunWithPeterContext, RunWithPeterInitialValues } from "./RunWithPeterContext";
import { RunWithPeterReducer, INCREMENT_SHOULD_UPDATE, UPDATE_HAS_HIT_GOAL } from "./RunWithPeterReducer";

export const RunWithPeterContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(RunWithPeterReducer, RunWithPeterInitialValues);

	const updateHasHitGoal = (hasHitGoal) => dispatch({ type: UPDATE_HAS_HIT_GOAL, payload: hasHitGoal });
	const forceUpdate = () => dispatch({ type: INCREMENT_SHOULD_UPDATE });

	return (
		<RunWithPeterContext.Provider
			value={
				{
					...state,
					forceUpdate,
					updateHasHitGoal,
				}
			}
		>
			{children}
		</RunWithPeterContext.Provider>
	);
};