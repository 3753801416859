import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useFetch } from "@hooks/Hooks";
import typography from "../../../../scss/typography.scss";
import styles from "./MessagesList.scss";

const reduceMessageListThreads = (oldThreads, newThreads) => {
	if (
		!newThreads
		|| newThreads.length === 0
	) {
		return oldThreads;
	}

	const combinedThreads = [];

	oldThreads.forEach((oldThread) => {
		const newThread = newThreads.find(
			(thread) => {
				if (thread.aId) {
					return thread.aId === oldThread.aId;
				}
				return thread.memberId === oldThread.memberId;
			}
		);

		if (newThread) {
			combinedThreads.push(newThread);
		} else {
			combinedThreads.push(oldThread);
		}
	});
	newThreads.forEach((newThread) => {
		const oldThread = oldThreads.find(
			(thread) => {
				if (thread.aId) {
					return thread.aId === newThread.aId;
				}
				return thread.memberId === newThread.memberId;
			}
		);

		if (!oldThread) {
			combinedThreads.push(newThread);
		}
	});

	return combinedThreads;
};

export const MessagesListPage = ({ page, onPageLoaded, id, type }) => {
	const {
		messagesState,
		setMessageListThreads,
	} = useMemoizedContext("messages", [
		"messagesState",
	]);
	const {
		messageListThreads,
	} = messagesState;

	const body = { page };

	const [isMounted, setIsMounted] = useState(true);

	if (id) {
		const key = "ad" === type
			? "aId"
			: "memberId";
		body[key] = id;
	}

	const [data, loading] = useFetch("api/messages/listthreads", body, isMounted);
	useEffect(() => {
		if(!loading) {
			setIsMounted(false);
		}
		return () => setIsMounted(false); // fix memory leak error
	}, [loading]);

	useEffect(() => {
		if (
			!loading
			&& data?.threads?.length > 0
			&& isMounted
		) {
			/**
			 * concatenates previews messagesListThreads with the new ones that come from the api (page 1,2,3,4...etc)
			 * and then updates the list with the unique values, to not have duplicates.
			 */
			setMessageListThreads(reduceMessageListThreads(messageListThreads, data.threads));
			onPageLoaded();
		}
	}, [data, isMounted, loading, messageListThreads, onPageLoaded, setMessageListThreads]);

	if (loading) {
		return <Loading />;
	}

	return null;
};

MessagesListPage.propTypes = {
	page: PropTypes.number,
	onPageLoaded: PropTypes.func,
	clickHandler: PropTypes.func,
	type: PropTypes.oneOf(["member", "ad"]),
	id: PropTypes.number,
	memberIdState: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	aIdState: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

MessagesListPage.defaultProps = {
	onPageLoaded: () => {},
	clickHandler: () => {},
	id: null,
	type: "member",
	memberIdState: false,
	aIdState: false,
};
