import { PostUIActionTypes } from "@contexts/PostUI";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
const usePostUIAPI = () => {
    const apiEndpoint = useApiEndpoint();
    const { cardData, cardDraftData, dispatch: dispatchPostUI, } = useMemoizedContext("postUI", [
        "cardData",
        "cardDraftData",
    ]);
    const cancelEdit = () => {
        apiEndpoint("posts/deletePost", { id: cardDraftData.id });
        dispatchPostUI({ type: PostUIActionTypes.CANCEL_EDITING });
    };
    const loadDraft = () => {
        apiEndpoint("posts/editPost", { id: cardData.id })
            .then((response) => {
            response.json().then((data) => {
                let html = data.contentHtml;
                const globalNewlineRegex = /(\r\n|\n|\r)/gm;
                if (typeof html === "string"
                    && html.length > 0
                    && html.match(globalNewlineRegex)) {
                    html = html.replace(globalNewlineRegex, "");
                }
                dispatchPostUI({
                    type: PostUIActionTypes.START_EDITING,
                    payload: {
                        draft: {
                            ...data,
                            contentHtml: html,
                        },
                    },
                });
            });
        });
    };
    return {
        cancelEdit,
        loadDraft,
    };
};
export default usePostUIAPI;
