import PropTypes from "prop-types";
import React from "react";
import styles from "./ModalComponents.scss";

const ModalColumn = ({ children }) => <div styleName={"styles.modal-column"}>{children}</div>;

ModalColumn.propTypes = {
	children: PropTypes.node,
};

export default ModalColumn;
