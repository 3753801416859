import { Icon, PenSVG, MessagesSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import Button from "@components/Button/Button";
import { Loading } from "@components/Helpers/Helpers";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { useCloseModal, useOpenModal } from "@contexts/UI";
import { useMemoizedContext } from "@frontend/hooks/Hooks";
import { useFetch } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { EUIKey } from "../../../../contexts/UI";
import typography from "../../../../scss/typography.scss";
import { MemberButton } from "./MemberButton";
import styles from "./MessagesList.scss";
import * as Styles from "./MessagesList.styles";
import { MessagesListPage } from "./MessagesListPage";
import { NewMessageModal } from "./NewMessageModal";

export const MessagesList = ({
	showWindow,
	mobile,
}) => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();

	const {
		headerHeight,
	} = useMemoizedContext("ui", [
		"headerHeight",
	]);

	const {
		messagesState,
		setMessageListThreads,
		setIdByType,
	} = useMemoizedContext("messages", [
		"messagesState",
	]);
	const {
		memberIdState,
		aIdState,
		messageListThreads,
	} = messagesState;

	const {
		queryParams,
		isReverificationRequired,
		isPostSurveyReferral,
		readOnlyMember,
		trialMember,
		affiliateMember,
	} = useMemoizedContext("member", [
		"queryParams",
		"isReverificationRequired",
		"isPostSurveyReferral",
		"readOnlyMember",
		"trialMember",
		"affiliateMember",
	]);

	const { search } = useLocation();

	const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });

	const { draft, pinned } = queryParams;

	let actualBody = { page: 0 };
	const params = useParams();
	const currentMemberId = params.memberId
		? parseInt(params.memberId)
		: undefined;
	const currentAId = params.aId
		? parseInt(params.aId)
		: undefined;
	const type = currentMemberId
		? "member"
		: "ad";

	if ("member" === type && !isNaN(currentMemberId)) {
		actualBody.memberId = currentMemberId;
	}
	if ("ad" === type && !isNaN(currentAId)) {
		actualBody.aId = currentAId;
	}

	if (pinned) {
		actualBody.aId = parseInt(pinned);
	}
	const [body, setBody] = useState(actualBody);

	const [redirect, setRedirect] = useState(false);
	const [page, setPage] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);
	const previousId = usePrevious(currentMemberId || currentAId);
	const [initialLoad, setInitialLoad] = useState(true);
	const el = useRef();

	// body is just used to get this to rerender on route change
	const [data, loading] = useFetch(
		"api/messages/listthreads",
		{
			...body,
			showWindow,
		},
		!draft
	);

	useEffect(() => {
		if (
			!loading
			&& data?.threads
			&& messageListThreads.length === 0
			&& data?.threads.length > 0
			&& initialLoad
		) {
			setMessageListThreads(data.threads);
		}
	}, [
		data?.threads,
		initialLoad,
		loading,
		messageListThreads.length,
		setMessageListThreads,
	])

	const onScroll = useCallback(
		(e) => {
			if (!loadingMore) {
				let scrollTop = el.current.scrollTop;
				let height = el.current.getBoundingClientRect().height;
				let scrollHeight = el.current.scrollHeight;

				// use ref for initial check to load more.
				// after that use e.target to get latest values as ref doesnt update
				if (e) {
					scrollTop = e.target.scrollTop;
					height = e.target.getBoundingClientRect().height;
					scrollHeight = e.target.scrollHeight;
				}

				const total = scrollTop + height;
				if (total >= scrollHeight) {
					setLoadingMore(true);
					setPage(page + 1);
				}
			}
		},
		[loadingMore, page]
	);

	const onPageLoaded = () => {
		setLoadingMore(false);
	};

	// on initial load see if we should loads more by triggering scroll
	useEffect(() => {
		if (
			!loading
			&& initialLoad
		) {
			onScroll();
			setInitialLoad(false);
		}
	}, [initialLoad, loading, onScroll]);

	const clickHandler = useCallback(
		(buttonId, type) => {
			let pinnedQuery = pinned
				? `?pinned=${pinned}`
				: "";
			if (isMobileOrTabletPortrait) {
				if ("member" === type) {
					setRedirect(`/messages/${buttonId}${pinnedQuery}`);
				}
				if ("ad" === type) {
					setRedirect(`/messages/dma/${buttonId}${pinnedQuery}`);
				}
			} else {
				setIdByType(buttonId, type);
				if ("member" === type) {
					setRedirect(`/messages/${buttonId}${pinnedQuery}`);
				}
				else if ("ad" === type) {
					setRedirect(`/messages/dma/${buttonId}${pinnedQuery}`);
				} else {
					setRedirect(`/messages/${buttonId}${pinnedQuery}`);
				}
			}
		},
		[isMobileOrTabletPortrait, setIdByType]
	);

	// if the user used the new message modal and the user is not in the list
	// pass something unique to usefetch to get a fresh list
	useEffect(() => {
		if (!loading && (currentMemberId || currentAId)) {
			const cur = messageListThreads.find((thread) => {
				// == because string
				if ("member" === type) {
					return thread.memberId === currentMemberId;
				}
				if ("ad" === type) {
					return thread.aId === currentAId;
				}
			});

			if (!cur) {
				setBody({
					memberId: "member" === type
						? currentMemberId
						: undefined,
					aId: "ad" === type
						? currentAId
						: undefined,
					page: 1,
				});
			}
		}
		if (
			!mobile
			&& !loading
			&& !(
				currentMemberId
				|| currentAId
			)
			&& messageListThreads.length > 0
		) {
			if (messageListThreads[0]?.memberId) {
				setIdByType(messageListThreads[0].memberId, "member")
				setRedirect(`/messages/${messageListThreads[0].memberId}${search}`);
			}
			if (messageListThreads[0]?.aId) {
				setIdByType(messageListThreads[0].aId, "ad")
				setRedirect(`/messages/dma/${messageListThreads[0].aId}${search}`);
			}
		}
	}, [messageListThreads, currentMemberId, currentAId, loading, mobile, setIdByType, type]);

	useEffect(() => {
		if (
			previousId
			&& (
				currentMemberId
				|| currentAId
			)
			&& previousId !== (
				currentMemberId
				|| currentAId
			)
		) {
			setPage(0);
			setLoadingMore(false);
		}
	}, [currentMemberId, currentAId, previousId]);

	useEffect(() => {
		if (redirect) {
			setRedirect(false);
		}
	}, [redirect]);

	const addMessage = useCallback(
		(messageMemberId) => {
			closeModal("new-message");
			setIdByType(messageMemberId, "member");
			setRedirect(`/messages/${messageMemberId}`);
		},
		[
			closeModal,
			setIdByType,
		]
	);

	const openMessageModal = useCallback(
		() => {
			openModal({
				[EUIKey.MODAL_COMPONENT]: (
					<NewMessageModal
						addMessage={addMessage}
					/>
				),
				[EUIKey.MODAL_LABEL]: "new-message",
				[EUIKey.MODAL_SUBTYPE]: "new-message",
				[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.messages.newMessage", true),
			});
		},
		[
			addMessage,
			openModal,
		]
	);

	const renderPages = useCallback(() => {
		const pages = [];

		for (let i = 0; i <= page; i++) {
			pages.push(
				<MessagesListPage
					page={i}
					onPageLoaded={onPageLoaded}
					key={i}
					clickHandler={clickHandler}
					id={currentMemberId || currentAId || pinned}
					type={
						pinned
							? "ad"
							: "member"
					}
					memberIdState={memberIdState}
					aIdState={aIdState}
				/>
			);
		}
		return pages;
	}, [aIdState, clickHandler, currentMemberId, currentAId, memberIdState, page]);

	const canClickNewMessageButton = !(
		isReverificationRequired
		|| isPostSurveyReferral
		|| readOnlyMember
		|| trialMember
		|| affiliateMember
	);

	// TODO: test to see if replace is necessary on redirect
	return useMemo(() => (
		<Styles.MessagesList
			$subtractFromHeight={headerHeight}
		>
			{
				redirect
				&& (
					<Navigate
						to={redirect}
						replace
					/>
				)
			}
			<div styleName="styles.header">
				<div styleName={classNames("styles.header-label")}>
					{getTranslation("frontend.generics.messages")}
				</div>
				<Button
					icon={<Icon src={PenSVG} />}
					clickHandler={openMessageModal}
					style="icon"
					tracking={
						{
							category: "messages",
							action: "click",
							label: "compose-message",
						}
					}
				/>
			</div>
			<Styles.List
				onScroll={onScroll}
				ref={el}
				$subtractFromHeight={headerHeight}
			>
				{loading && <Loading />}
				{
					!loading && (
						<>
							{
								messageListThreads.length === 0 && (
									<div styleName={classNames("styles.no-messages")}>
										<Icon
											src={MessagesSVG}
											width={40}
											height={40}
										/>
										<div
											styleName={
												classNames(
													"styles.no-messages-text",
													"typography.heading-brand-00"
												)
											}
										>
											{getTranslation("frontend.messages.noMessages")}
										</div>
										<Button
											style="secondary"
											tracking={
												{
													category: "messages",
													action: "click",
													label: "send-message",
												}
											}
											rightsRequired={
												canClickNewMessageButton
													? []
													: ["nonExistingRightDoNotDelete"]
											}
											clickHandler={openMessageModal}
										>
											{getTranslation("frontend.messages.sendAMessage")}
										</Button>
									</div>
								)
							}
							{
								messageListThreads.map((thread, i) => (
									<MemberButton
										{...thread}
										clickHandler={clickHandler}
										key={i}
										memberIdState={memberIdState}
										aIdState={aIdState}
										currentMemberId={currentMemberId}
										currentAId={currentAId}
									/>
								))
							}
							{renderPages()}
						</>
					)
				}
			</Styles.List>
		</Styles.MessagesList>
	),
	// eslint-disable-next-line max-len
	[redirect, openMessageModal, onScroll, loading, messageListThreads, canClickNewMessageButton, renderPages, clickHandler, memberIdState, aIdState, currentMemberId, currentAId]);
};

MessagesList.propTypes = {
	id: PropTypes.number,
	type: PropTypes.oneOf(["member", "ad"]),
	showWindow: PropTypes.bool,
	mobile: PropTypes.bool,
	memberIdState: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	aIdState: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	setMemberIdState: PropTypes.func,
};

MessagesList.defaultProps = {
	id: null,
	type: "member",
	showWindow: false,
	mobile: false,
	memberIdState: false,
	aIdState: false,
};
