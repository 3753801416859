import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";
import SelectWithdrawalFee from "@components/Modal/Modals/Withdrawals/SelectWithdrawalFee";
import { useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import { ModalContainer } from "./ModalComponents";
import styles from "./ModalComponents/Withdrawal/ModalWithdrawalComponents.scss";
import {
	Name,
	TypeDescription,
	TypeTitle,
	WrapImage,
	WrapImageAndTitle,
	WrapLogistics
} from "./WithdrawalModal.styles";
import MDHonors from "./Withdrawals/MDH/MDHonors";
import SelectWithdrawalMethod from "./Withdrawals/SelectWithdrawalMethod";
import WithdrawalFlow from "./Withdrawals/WithdrawalFlow";
import {
	getTranslationSubKey,
	isRequiredFieldMissing,
	SELECT_NEW,
	SELECT_REFILL,
	TYPE_CHECKS,
	TYPE_MASTERCARD,
	TYPE_MDH,
} from "./Withdrawals/withdrawalUtils";

const WithdrawalModal = ({ closeModalEventHandler, withdrawalModalTextLabelChangeHandler }) => {
	const memberContext = useMemoizedContext("member");

	if (
		!memberContext.hasAccess("canWithdrawMoneyFromOwnAccount")
		|| memberContext.hasBeenAuthenticatedViaToken
	) {
		return null;
	}
	return (
		<WithdrawalModalWrapped
			closeModalEventHandler={closeModalEventHandler}
			withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
		/>
	)
}

const WithdrawalModalWrapped = ({ closeModalEventHandler, withdrawalModalTextLabelChangeHandler }) => {
	const apiEndpoint = useApiEndpoint();

	const [loading, setLoading] = useState(false);
	const [selectedWithdrawalType, setSelectedWithdrawalType] = useState("");
	const [minimumWithdrawalAmount, setMinimumWithdrawalAmount] = useState(0.0);
	const [usdConversionMultiplier, setUsdConversionMultiplier] = useState(1.0);
	const [feeAmount, setFeeAmount] = useState(0.0);
	const [feePercentage, setFeePercentage] = useState(0.0);
	const [targetCurrency, setTargetCurrency] = useState("");
	const [targetCurrencyMultiplier, setTargetCurrencyMultiplier] = useState(0.0);
	const [limitToWithdrawalAmounts, setLimitToWithdrawalAmounts] = useState([]);
	const [withdrawalTypeHasBeenSelected, setWithdrawalTypeHasBeenSelected] = useState(false);
	const [agreeFee, setAgreeFee] = useState(false);
	const [mastercardFeeType, setMastercardFeeType] = useState(null);
	const [mastercardFeeHasBeenSelected, setMastercardFeeHasBeenSelected] = useState(false);

	const [withdrawalTypes, setWithdrawalTypes] = useState([]);
	const [currency, setCurrency] = useState("");
	const [availableFunds, setAvailableFunds] = useState(0.0);
	const [preferredStatusThreshold, setPreferredStatusThreshold] = useState(0.0);
	const [isPreferredMember, setIsPreferredMember] = useState(false);
	const [activeCards, setActiveCards] = useState([]);
	const [emailAddress, setEmailAddress] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState(null);
	const [primaryAddress, setPrimaryAddress] = useState(null);
	const [secondaryAddress, setSecondaryAddress] = useState(null);
	const [availableAddressCountries, setAvailableAddressCountries] = useState({});
	const [availableAddressRegions, setAvailableAddressRegions] = useState({});
	const [profileMissingFields, setProfileMissingFields] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState("");

	const [askPhoneNumber, setAskPhoneNumber] = useState(false);
	const [askEmail, setAskEmail] = useState(false);
	const [askAddress, setAskAddress] = useState(false);
	const [showAddressOnConfirm, setShowAddressOnConfirm] = useState(false);
	const [askDateOfBirth, setAskDateOfBirth] = useState(false);
	const [isDateOfBirthVerified, setIsDateOfBirthVerified] = useState(false);
	const [customConfirmStepDescription, setCustomConfirmStepDescription] = useState(null);
	const [customCompleteStepDescription, setCustomCompleteStepDescription] = useState(null);
	const [hasActiveMasterCard, setHasActiveMasterCard] = useState(false);

	useEffect(() => {
		if(activeCards?.indexOf(TYPE_MASTERCARD) >= 0) {
			setHasActiveMasterCard(true);
			setMastercardFeeType(SELECT_REFILL);
		} else {
			// If user doesn't have previously an active MasterCard, default option is creating a new one.
			setMastercardFeeType(SELECT_NEW);
		}
	}, [activeCards]);

	/* Initialize component. */
	useEffect(() => {
		setLoading(true);
		withdrawalModalTextLabelChangeHandler(
			getTranslation("frontend.modals.withdrawal.selectWithdrawalMethod.title", true));

		apiEndpoint("surveys/getavailablewithdrawaltypes", { includeMemberInformation: true }).then((dataResponse) => {
			dataResponse.json().then((body) => {
				if (dataResponse.ok) {
					setCurrency(body.currency);
					setAvailableFunds(body.availableFunds);
					setPreferredStatusThreshold(body.preferredStatusThreshold);
					setIsPreferredMember(body.isPreferredMember);
					setActiveCards(Array.isArray(body.activeCards)
						? body.activeCards
						: []);
					setEmailAddress(body.emailAddress);
					if (body.dateOfBirthYear && body.dateOfBirthMonth && body.dateOfBirthDay) {
						setDateOfBirth({
							year: body.dateOfBirthYear,
							month: body.dateOfBirthMonth,
							day: body.dateOfBirthDay,
						});
					}
					setIsDateOfBirthVerified(body.isDateOfBirthVerified);
					setUsdConversionMultiplier(body.usdConversionMultiplier);
					setProfileMissingFields(Array.isArray(body.profileMissingFields)
						? body.profileMissingFields
						: []);
					setPhoneNumber(body.telephoneNumber);

					let primaryAddressTmp = body.primaryAddress;
					let secondaryAddressTmp = body.secondaryAddress;
					let availableAddressRegionsTmp = [];
					let availableAddressCountriesTmp = [];

					for (let i = 0; i < body.availableRegions.length; i++) {
						availableAddressRegionsTmp.push({
							key: body.availableRegions[i].name,
							value: body.availableRegions[i].key,
							countryCode: body.availableRegions[i].countryCode,
						});

						if (body.primaryAddress) {
							primaryAddressTmp.availableRegionsForSelectedCountry = [];
							if (body.primaryAddress.countryCode) {
								if (body.availableRegions[i].countryCode === body.primaryAddress.countryCode) {
									primaryAddressTmp.availableRegionsForSelectedCountry.push({
										key: body.availableRegions[i].name,
										value: body.availableRegions[i].key,
									});
								}
							}
						}

						if (body.secondaryAddress) {
							secondaryAddressTmp.availableRegionsForSelectedCountry = [];
							if (body.secondaryAddress.countryCode) {
								if (body.availableRegions[i].countryCode === body.secondaryAddress.countryCode) {
									secondaryAddressTmp.availableRegionsForSelectedCountry.push({
										key: body.availableRegions[i].name,
										value: body.availableRegions[i].key,
									});
								}
							}
						}
					}

					setAvailableAddressRegions(availableAddressRegionsTmp);

					for (let i = 0; i < body.availableCountries.length; i++) {
						availableAddressCountriesTmp.push({
							key: body.availableCountries[i].name,
							value: body.availableCountries[i].key,
						});

						if (primaryAddressTmp) {
							if (primaryAddressTmp.countryCode === body.availableCountries[i].key) {
								primaryAddressTmp.country = body.availableCountries[i].name;
							}
						}

						if (secondaryAddressTmp) {
							if (secondaryAddressTmp.countryCode === body.availableCountries[i].key) {
								secondaryAddressTmp.country = body.availableCountries[i].name;
							}
						}
					}
					setAvailableAddressCountries(availableAddressCountriesTmp);

					if (primaryAddressTmp) {
						if (primaryAddressTmp.countryCode) {
							setPrimaryAddress(primaryAddressTmp);
						}
					}

					if (secondaryAddressTmp) {
						if (secondaryAddressTmp.countryCode) {
							setSecondaryAddress(secondaryAddressTmp);
						}
					}

					let loadedWithdrawalTypes = [];

					for (let i = 0; i < body.availableWithdrawalTypes.length; i++) {
						const withdrawalType = body.availableWithdrawalTypes[i];
						const translationSubKey = getTranslationSubKey(withdrawalType.code, true);

						const withdrawalFee = Number(withdrawalType.feeAmount) || 0;
						const decimals = (withdrawalFee % 1 == 0)
							? 0
							: 2;
						const withdrawalFeePercentage = Number(withdrawalType.feePercentage) || 0;

						const targetCurrencyMultiplier = Number(withdrawalType.targetCurrencyAmountMultiplier) || 0;

						let isDisabledKeySuffix = "";

						if (withdrawalType.isDisabled) {
							isDisabledKeySuffix = "Disabled";
						}

						let name = (
							<Name>
								<WrapImageAndTitle>
									<WrapImage>
										<img
											src={`/assets/images/${translationSubKey}.svg`}
											width="22"
										/>
									</WrapImage>
									<TypeTitle>
										{
											getTranslation(
												`frontend.surveys.withdrawalTypes.${translationSubKey}.title`,
												true
											)
										}
									</TypeTitle>
								</WrapImageAndTitle>
								<TypeDescription>
									{
										getTranslation(
										// eslint-disable-next-line max-len
											`frontend.surveys.withdrawalTypes.${translationSubKey}.description${isDisabledKeySuffix}`,
											true,
										)
									}
								</TypeDescription>
								<WrapLogistics>
									{
										getTranslation(
											`frontend.surveys.withdrawalTypes.${translationSubKey}.logistics`,
											false,
											getCurrencyTranslation(withdrawalFee, body.currency, true, decimals),
											withdrawalType.feePercentage || undefined
										)
									}
								</WrapLogistics>
							</Name>
						);

						let item = {
							name: name,
							value: withdrawalType.code,
							minimumWithdrawalAmount: Number(withdrawalType.minimumWithdrawalAmount),
							feeAmount: withdrawalFee,
							feePercentage: withdrawalFeePercentage,
							targetCurrency: withdrawalType.targetCurrencyCode,
							targetCurrencyMultiplier: targetCurrencyMultiplier,
							limitToWithdrawalAmounts: withdrawalType.limitToWithdrawalAmounts,
							disabled: withdrawalType.isDisabled,
							requiredFields: withdrawalType.requiredFields,
						};

						loadedWithdrawalTypes.push(item);

						if (i === 0) {
							setSelectedWithdrawalType(item.value);
						}
					}
					setWithdrawalTypes(loadedWithdrawalTypes);
				} else {
					console.log("Error fetching available withdrawal types!");
				}
				setLoading(false);
			});
		});
	}, []);

	const closeModalClickHandler = () => {
		setSelectedWithdrawalType("");
		closeModalEventHandler(true);
	};

	const withdrawalIssueNewCardEventHandler = (selectedMasterCardFeeType, isAgreeFee) => {
		setMastercardFeeType(selectedMasterCardFeeType)
		setAgreeFee(isAgreeFee);
		setMastercardFeeHasBeenSelected(true);
	};

	const withdrawalTypeSelectedEventHandler = (
		withdrawalType,
		minimumWithdrawalAmount,
		feeAmount,
		feePercentage,
		targetCurrency,
		targetCurrencyMultiplier,
		limitToWithdrawalAmounts,
		requiredFields
	) => {
		/* MasterCard related flags*/
		const isCardReload = withdrawalType === TYPE_MASTERCARD && hasActiveMasterCard;
		const isNewCard = withdrawalType === TYPE_MASTERCARD && !hasActiveMasterCard;

		setSelectedWithdrawalType(withdrawalType);
		setMinimumWithdrawalAmount(minimumWithdrawalAmount);
		setFeeAmount(feeAmount);
		setFeePercentage(feePercentage);
		setTargetCurrency(targetCurrency);
		setTargetCurrencyMultiplier(targetCurrencyMultiplier);
		setWithdrawalTypeHasBeenSelected(true);
		setMastercardFeeHasBeenSelected(withdrawalType !== TYPE_MASTERCARD);
		setLimitToWithdrawalAmounts(limitToWithdrawalAmounts);

		setAskPhoneNumber(isRequiredFieldMissing(requiredFields, profileMissingFields, "phone_number"));

		setAskEmail(isRequiredFieldMissing(requiredFields, profileMissingFields, "email"));

		setAskAddress(isRequiredFieldMissing(requiredFields, profileMissingFields, "address"));

		setShowAddressOnConfirm(
			withdrawalType.indexOf(TYPE_CHECKS) >= 0
			|| isNewCard
			|| isRequiredFieldMissing(requiredFields, profileMissingFields, "address")
		);

		setAskDateOfBirth(
			(isNewCard && !isDateOfBirthVerified)
			|| isRequiredFieldMissing(requiredFields, profileMissingFields, "date_of_birth")
		);

		setCustomConfirmStepDescription(
			isCardReload
				? getTranslation("frontend.modals.withdrawal.mastercard.stepLoadConfirmDescription")
				: null
		);
		setCustomCompleteStepDescription(
			isCardReload
				? getTranslation("frontend.modals.withdrawal.mastercard.stepLoadCompleteDescription")
				: null
		);

		if (withdrawalType === TYPE_MASTERCARD) {
			withdrawalModalTextLabelChangeHandler(
				getTranslation(hasActiveMasterCard
					? "frontend.modals.withdrawal.mastercard.selectCard.title"
					: "frontend.modals.withdrawal.mastercard.selectCard.titleNew", true));
		}
	};

	const showStepBackClickHandler = () => {
		if (selectedWithdrawalType === TYPE_MASTERCARD) {
			withdrawalModalTextLabelChangeHandler(
				getTranslation(hasActiveMasterCard
					? "frontend.modals.withdrawal.mastercard.selectCard.title"
					: "frontend.modals.withdrawal.mastercard.selectCard.titleNew", true));
			setMastercardFeeHasBeenSelected(false);
		} else {
			withdrawalModalTextLabelChangeHandler(
				getTranslation("frontend.modals.withdrawal.selectWithdrawalMethod.title", true)
			);
			setWithdrawalTypeHasBeenSelected(false);
		}
	};

	const showSelectedWithdrawalTypeClickHandler = () => {
		withdrawalModalTextLabelChangeHandler(
			getTranslation("frontend.modals.withdrawal.selectWithdrawalMethod.title", true)
		);
		setWithdrawalTypeHasBeenSelected(false);
	};

	const withdrawalCompleteEventHandler = () => {
		setSelectedWithdrawalType("");
		closeModalEventHandler("backToFeed");
	};

	return (
		<ModalContainer>
			{
				!loading && (
					<>
						{
							!withdrawalTypeHasBeenSelected && (
								<SelectWithdrawalMethod
									withdrawalTypes={withdrawalTypes}
									availableFunds={availableFunds}
									currency={currency}
									selectedWithdrawalType={selectedWithdrawalType}
									preferredStatusThreshold={preferredStatusThreshold}
									isPreferredMember={isPreferredMember}
									withdrawalTypeSelectedEventHandler={withdrawalTypeSelectedEventHandler}
									stepBackClickHandler={closeModalClickHandler}
								/>
							)
						}
						{
							withdrawalTypeHasBeenSelected && !mastercardFeeHasBeenSelected && (
								<SelectWithdrawalFee
									hasActiveCard={hasActiveMasterCard}
									mastercardFeeType={mastercardFeeType}
									isAgreeFee={agreeFee}
									withdrawalIssueNewCardEventHandler={withdrawalIssueNewCardEventHandler}
									stepBackClickHandler={showSelectedWithdrawalTypeClickHandler}
									feeTranslation={getCurrencyTranslation(feeAmount, currency, true, 2)}
								/>
							)
						}
						{
							withdrawalTypeHasBeenSelected && mastercardFeeHasBeenSelected
					&& (selectedWithdrawalType !== TYPE_MDH
						&& (selectedWithdrawalType !== TYPE_MASTERCARD || mastercardFeeType !== null)) && (
								<WithdrawalFlow
									withdrawalCode={selectedWithdrawalType}
									availableFunds={availableFunds}
									currency={currency}
									limitToWithdrawalAmounts={limitToWithdrawalAmounts}
									preferredStatusThreshold={preferredStatusThreshold}
									isPreferredMember={isPreferredMember}
									phoneNumber={phoneNumber}
									emailAddress={emailAddress}
									primaryAddress={primaryAddress}
									secondaryAddress={secondaryAddress}
									dateOfBirth={dateOfBirth}
									needPhoneNumber={askPhoneNumber}
									needEmail={askEmail}
									needAddress={askAddress}
									showAddressOnConfirm={showAddressOnConfirm}
									needDateOfBirth={askDateOfBirth}
									minimumWithdrawalAmount={minimumWithdrawalAmount}
									fixedFeeAmount={feeAmount}
									variableFeePercentage={feePercentage}
									targetCurrency={targetCurrency}
									targetCurrencyMultiplier={targetCurrencyMultiplier}
									allCountries={availableAddressCountries}
									allRegions={availableAddressRegions}
									stepBackClickHandler={showStepBackClickHandler}
									withdrawalCompleteEventHandler={withdrawalCompleteEventHandler}
									withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
									customConfirmStepDescription={customConfirmStepDescription}
									customCompleteStepDescription={customCompleteStepDescription}
									issueNewCard={
										selectedWithdrawalType === TYPE_MASTERCARD
								&& mastercardFeeType !== SELECT_REFILL
									}
								/>
							)
						}
						{
							withdrawalTypeHasBeenSelected && selectedWithdrawalType === TYPE_MDH && (
								<MDHonors
									withdrawalCode={selectedWithdrawalType}
									availableFunds={availableFunds}
									currency={currency}
									limitToWithdrawalAmounts={limitToWithdrawalAmounts}
									usdConversionMultiplier={usdConversionMultiplier}
									preferredStatusThreshold={preferredStatusThreshold}
									isPreferredMember={isPreferredMember}
									minimumWithdrawalAmount={minimumWithdrawalAmount}
									fixedFeeAmount={feeAmount}
									variableFeePercentage={feePercentage}
									stepBackClickHandler={showSelectedWithdrawalTypeClickHandler}
									withdrawalCompleteEventHandler={withdrawalCompleteEventHandler}
									withdrawalModalTextLabelChangeHandler={withdrawalModalTextLabelChangeHandler}
								/>
							)
						}
					</>
				)
			}
			{loading && <Loading />}
		</ModalContainer>
	);
};

WithdrawalModal.propTypes = {
	closeModalEventHandler: PropTypes.func,
	withdrawalModalTextLabelChangeHandler: PropTypes.func,
	customConfirmStepDescription: PropTypes.string,
	customCompleteStepDescription: PropTypes.string,
};

WithdrawalModal.defaultProps = {
	closeModalEventHandler: () => { },
	customConfirmStepDescription: null,
	customCompleteStepDescription: null,
};

export default WithdrawalModal;
