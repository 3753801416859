import React from "react";
import { Dot, LoadingContainer } from "@components/NewPost/components/Loading/Loading.styled";

const Loading = () => (
	<LoadingContainer data-component={"Loading"}>
		<Dot/>
		<Dot/>
		<Dot/>
	</LoadingContainer>
)

export default Loading;