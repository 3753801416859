import getTranslation from "@translation/translation";

const genericRules = (field, requiredText) => (value) => {
	if (
		"" === value.trim()
		&& !field.isOptional
	) {
		return [requiredText];
	}
	return [];
};

const emailRules = (field, requiredText) => (value) => {
	const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (
		value
		&& !emailRegEx.test(value.trim())
	) {
		return [getTranslation("system.validationMessages.validationInvalidEmailAddress", true)];
	}
	return genericRules(field, requiredText)(value);
}

const telephoneRules = (field, requiredText) => (value, values) => {
	if (false === values[`${field.adFormFieldType}IsValid`]) {
		return [getTranslation("system.validationMessages.validationInvalidTelephoneNumber", true)];
	}
	return genericRules(field, requiredText)(value);
}

export const setupForm = (fields) => {
	const initialValues = {};
	const validationRules = {};

	fields?.forEach((field) => {
		const translation = ("Custom" !== field.adFormFieldType)
			? getTranslation(
				`frontend.forms.adForm.label.${field.adFormFieldType.toLowerCase()}`,
				true
			)
			: "This field";
		const requiredTranslation = getTranslation(
			"system.validationMessages.validationIsRequired",
			true,
			translation
		);

		initialValues[field.adFormFieldType] = field.value ?? "";

		if (["PhoneNumber", "MobileNumber"].includes(field.adFormFieldType)) {
			validationRules[field.adFormFieldType] = telephoneRules(field, requiredTranslation);
		}
		else if ("Email" === field.adFormFieldType) {
			validationRules[field.adFormFieldType] = emailRules(field, requiredTranslation);
		}
		else {
			validationRules[field.adFormFieldType] = genericRules(field, requiredTranslation);
		}
	});

	return { initialValues, validationRules };
}
