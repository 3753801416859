import React, { lazy } from "react";
import { useMatch } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import ResourcePreviewSignIn from "./ResourcePreviewSignIn";
const ResourceCenterPage = lazy(() => import(
/* webpackChunkName: "ResourceCenterPage" */
"@components/Resource/components/ResourceCenterPage"));
const ResourceItemPage = lazy(() => import(
/* webpackChunkName: "ResourceItemPage" */
"@components/Resource/components/ResourceItemPage"));
const ResourcePreview = () => {
    const isCenterPreview = !!useMatch("/preview/resourceCenter/:resourceCenterId");
    const isItemPreview = !!useMatch("/preview/resourceItem/:resourceItemId");
    const { hasAccessToPreview, } = useMemoizedContext("member", [
        "hasAccessToPreview",
    ]);
    if (!hasAccessToPreview) {
        return <ResourcePreviewSignIn />;
    }
    switch (true) {
        case isCenterPreview:
            return <ResourceCenterPage />;
        case isItemPreview:
            return <ResourceItemPage />;
        default:
            return null;
    }
};
export default ResourcePreview;
