import styled from "styled-components";
import { applyZIndex } from "@frontend/styled";
export const Container = styled.div `
	width: 100%;
	${applyZIndex("top-nav")};
	background-color: ${({ theme }) => theme.color.uiBackground};
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
`;
export const Left = styled.div `
	min-height: 48px;
	display: flex;
	align-items: center;
	gap: 12px;
`;
export const Right = styled(Left) ``;
export const Selection = styled.div `
	position: relative;
	cursor: pointer;
	padding: 4px;

	& svg {
		color: ${({ theme }) => theme.color.icon02};
	}
`;
