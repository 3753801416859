import classNames from "classnames";
import React from "react";
import { MobileAndTabletPortrait, TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useFetch } from "@hooks/Hooks";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import { Loading } from "../../../Helpers/Helpers";
import surveys from "../../Surveys.scss";
import styles from "./SurveyHistory.scss";
import SurveyHistoryCard from "./SurveyHistoryCard";

const SurveyHistory = () => {
	const {
		locale,
		hasAccess,
	} = useMemoizedContext("member", [
		"locale",
		"hasAccess",
	]);

	const [data, loading, error] = useFetch("api/surveys/getsurveyhistory", {}, hasAccess("canAccessFrontendSurveys"));

	if (!hasAccess("canAccessFrontendSurveys")) {
		return null;
	}

	if (!data || loading) {
		return (
			<div styleName={classNames(["styles.survey-history"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<Loading contextClass="surveys" />
				</ModuleWrapper>
			</div>
		);
	}

	if (error) {
		return (
			<div styleName={classNames(["availableSurveysStyles.available-surveys"])}>
				<ModuleWrapper
					border={true}
					solid={true}
				>
					<div
						styleName={classNames(["availableSurveysStyles.survey-description", "typography.body-short-brand-02"])}
					>
						{getTranslation("frontend.surveys.surveyHistory.noSurveyHistoryAvailable")}
					</div>
				</ModuleWrapper>
			</div>
		);
	}

	const hasSurveys = data.pastSurveys && data.pastSurveys.length > 0;

	return (
		<div styleName="styles.survey-history">
			<ModuleWrapper
				border={true}
				solid={true}
			>
				<TabletLandscapeAndDesktop>
					<div styleName="typography.heading-brand-03">
						{getTranslation("frontend.surveys.surveyHistory.title", true)}
					</div>
					{
						hasSurveys && (
							<table styleName={classNames("surveys.table")}>
								<thead>
									<tr>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-120",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.surveyHistory.tableHeaderCompleted", true)}
										</th>
										<th styleName={classNames(["surveys.table-header", "typography.heading-brand-00"])}>
											{getTranslation("frontend.surveys.surveyHistory.tableHeaderSurveyName", true)}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-140",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.surveyHistory.tableHeaderStatus", true)}
										</th>
										<th
											styleName={
												classNames([
													"surveys.table-header",
													"surveys.table-header-width-100",
													"typography.heading-brand-00",
												])
											}
										>
											{getTranslation("frontend.surveys.surveyHistory.tableHeaderEarned", true)}
										</th>
									</tr>
								</thead>
								<tbody>
									{
										data.pastSurveys.map((survey, index) => {
											const completedDate = new Date(Date.parse(survey.completedAt));
											const enrolledProgramText = survey.programsEnrolled?.[0]?.honorariumShortText;

											return (
												<tr key={index}>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{
															completedDate.toLocaleDateString(locale, {
																year: "numeric",
																month: "numeric",
																day: "numeric",
															})
														}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{survey.name}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
																{
																	"surveys.highlight-status": survey.shouldHighlightStatus,
																},
															])
														}
													>
														{survey.status}
													</td>
													<td
														styleName={
															classNames([
																"surveys.table-content",
																"typography.body-short-brand-02",
															])
														}
													>
														{
															!!survey.honorariumAmount
															&& !!survey.currency
															&& !enrolledProgramText
															&& getCurrencyTranslation(survey.honorariumAmount, survey.currency)
														}
														{!!enrolledProgramText && enrolledProgramText}
													</td>
												</tr>
											);
										})
									}
								</tbody>
							</table>
						)
					}
				</TabletLandscapeAndDesktop>

				<MobileAndTabletPortrait>
					{
						hasSurveys && (
							<>
								{
									data.pastSurveys.map((survey, index) => (
										<SurveyHistoryCard
											locale={locale}
											index={index}
											key={index}
											{...survey}
										/>
									))
								}
							</>
						)
					}
				</MobileAndTabletPortrait>

				{
					!hasSurveys && (
						<div styleName="typography.body-short-brand-02">
							{getTranslation("frontend.surveys.surveyHistory.noSurveyHistoryAvailable")}
						</div>
					)
				}
			</ModuleWrapper>
		</div>
	);
};

export default SurveyHistory;
