import { ColorGrey50, ColorGrey60 } from "@sermo/design-tokens";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const Avatar = styled.img `
	width: 24px;
	height: 24px;
	border-radius: 50%;
	flex-shrink: 0;
	overflow: hidden;
	background-color: ${ColorGrey50};
	background-size: cover;
`;
export const Specialty = styled(TextStyled).attrs({ $kind: "bodyShort02" }) `
	color: ${ColorGrey60};
`;
