import PropTypes from "prop-types";
import {
	EVENT_LIVE,
	EVENT_ON_DEMAND,
	EVENT_SCHEDULED,
	EVENT_TODAY,
	EVENT_CANCELLED,
	EVENT_INACTIVE,
} from "@components/LiveEvents/LiveEvents.utils";

export const StatusPropType = PropTypes.oneOf([
	EVENT_SCHEDULED,
	EVENT_LIVE,
	EVENT_ON_DEMAND,
	EVENT_TODAY,
	EVENT_CANCELLED,
	EVENT_INACTIVE
]);

export const SizesPropType = PropTypes.oneOf(["small","medium", "large"]);

const eventPropTypes = {
	// member
	emailAddress: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	isRegistered: PropTypes.bool,
	// event
	brandStyleId: PropTypes.string,
	description: PropTypes.string,
	startDateTime: PropTypes.instanceOf(Date),
	endDateTime: PropTypes.instanceOf(Date),
	id: PropTypes.number,
	eventToken: PropTypes.string,
	imageUrl: PropTypes.string,
	isISIAutoSized: PropTypes.bool,
	isISIExpandable: PropTypes.bool,
	isFootnoteExpandable: PropTypes.bool,
	isiStyleId: PropTypes.number,
	isiText: PropTypes.string,
	name: PropTypes.string,
	speakers: PropTypes.arrayOf(PropTypes.object),
	sponsors: PropTypes.arrayOf(PropTypes.object),
	status: StatusPropType,
	totalRegistrants: PropTypes.number,
	zoomWebinarId: PropTypes.number,
	zoomRegistrantToken: PropTypes.string,
	isPreRecorded: PropTypes.bool,
	onDemandPreRecordedThumbnailImageUrl: PropTypes.string,
	autoplayVideo: PropTypes.bool,
}

export default eventPropTypes;
