export const sanitizeLinks = (str) => str.replace(/(<a.*?href=)"(.*?)".*?(>)(.*?)<\/a>/g, (match, p1, p2, _p3, p4) => {
    if (p2 === p4) {
        return `${p1}"${p4}" style="word-break: break-all">${p4}</a>`;
    }
    else {
        return match;
    }
});
export const contentContainerClickHandler = (e) => {
    if ("VIDEO" === e.target.tagName) {
        e.target.setAttribute("controls", "true");
        e.target.parentElement.classList.remove("unplayed");
        e.target.play();
    }
    if (e.target.classList.contains("unplayed")) {
        e.target.classList.remove("unplayed");
        const video = e.target.querySelector("video");
        video.setAttribute("controls", "true");
        video.play();
    }
};
const finalClosingTagRegex = /<\/[a-z]+>$/i;
export const insertReadMore = (content, readMoreId) => {
    const closingTagMatch = finalClosingTagRegex.exec(content);
    if (closingTagMatch) {
        return `${content.substring(0, closingTagMatch.index)}<span id=${readMoreId}></span>${closingTagMatch[0]}`;
    }
    return `${content}<span id=${readMoreId}></span>`;
};
