import React, { useReducer } from "react";
import PostUIContext, { PostUIDefaultValues } from "@contexts/PostUI/PostUIContext";
import PostUIReducer from "@contexts/PostUI/PostUIReducer";
const PostUIContextProvider = ({ children, initialState, }) => {
    const initializeState = React.useCallback((init) => {
        return {
            ...PostUIDefaultValues,
            ...init,
        };
    }, []);
    const [state, dispatch] = useReducer(PostUIReducer, initialState, initializeState);
    const value = {
        ...state,
        dispatch,
    };
    return (<PostUIContext.Provider value={value}>
			{children}
		</PostUIContext.Provider>);
};
export default PostUIContextProvider;
