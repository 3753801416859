import { useEffect, useRef } from "react";
import { UIActionTypes, EUIKey } from "@contexts/UI";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./Footer.styles";
const Footer = ({ children, }) => {
    const footerRef = useRef(null);
    const { dispatch: dispatchLayout, } = useMemoizedContext("ui");
    const onFooterResize = () => {
        if (footerRef.current) {
            dispatchLayout({
                type: UIActionTypes.SET_FOOTER_HEIGHT,
                payload: {
                    [EUIKey.FOOTER_HEIGHT]: footerRef.current?.offsetHeight || 0,
                },
            });
        }
    };
    useEffect(() => {
        const resizeObserver = new ResizeObserver(onFooterResize);
        if (footerRef.current) {
            resizeObserver.observe(footerRef.current);
        }
        return () => {
            if (footerRef.current) {
                resizeObserver.unobserve(footerRef.current);
            }
            else {
                resizeObserver.disconnect();
            }
        };
    }, []);
    return (<Styles.Footer ref={footerRef} data-component={"Footer"}>
			<Styles.FooterContent>
				{children}
			</Styles.FooterContent>
		</Styles.Footer>);
};
export default Footer;
