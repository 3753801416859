import React, { useState, useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";
import { ReverificationWrap } from "@components/Register/Register.styles";
import { useFetch } from "@hooks/Hooks";
import config from "./components/PostSurvey/config";

const PostSurvey = () => {
	const [currentStep, setCurrentStep] = useState(null);
	const [postSurveyData, setPostSurveyData] = useState(false);
	const [data, loading, error] = useFetch("api/postsurveyreferralverification/getdata");

	useEffect(() => {
		if ( data && !loading && !error ) {
			setPostSurveyData(data);
		}
	},[data, loading, error])

	useEffect(() => {
		if (postSurveyData) {
			if (postSurveyData.mobileNumber) {
				let number = postSurveyData.mobileNumber;

				if (number[0] === "+") {
					const selectedCountry = postSurveyData.availableCountries.find(c => c.id.toLowerCase() === postSurveyData.selectedCountryCode.toLowerCase());
					number = number.substring((selectedCountry.telephoneCode + "").length + 1);

					postSurveyData.mobileNumber = number;
				}
			}

			if (postSurveyData.currentStep !== currentStep) {
				setCurrentStep(postSurveyData.currentStep);
			}
		}
	}, [postSurveyData]);

	const updatePostSurveyData = (data) => {
		setPostSurveyData({
			...postSurveyData,
			...data,
		});
	};

	if ( loading ) {
		return (
			<ReverificationWrap>
				<Loading/>
			</ReverificationWrap>
		)
	}

	if (!postSurveyData || error || !currentStep || !Object.prototype.hasOwnProperty.call(config, currentStep)) {
		return null;
	}

	const { Component, props } = config[currentStep];

	return (
		<>
			{
				Component && <Component
					data={postSurveyData}
					updateData={updatePostSurveyData}
					{...props}
					endpoint="postsurveyreferralverification"
				/>
			}
		</>
	);
};

export default PostSurvey;
