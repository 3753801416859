import { ColorGrey00, ColorRed50, ColorGrey40, ColorWhite, ColorGrey60, ColorGrey20, ColorUltraviolet50, ColorGrey100, ColorGrey10 } from "@sermo/design-tokens";
import { Button, EmojiSVG, IconButton } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import { Editable } from "slate-react";
import styled, { css } from "styled-components";
export const IconButtonStyled = styled(IconButton) `
`;
export const ButtonStyled = styled(Button) `
`;
export const Root = styled(TextStyled).attrs({
    $kind: "bodyShort02"
}) `
  position: relative;
	border: 1px solid ${ColorGrey20};
	border-radius: 4px;
	background-color: ${ColorWhite};

	${({ borderless }) => borderless && css `
		border: none;
	`};

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  s {
    text-decoration: line-through;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  ul {
    list-style: disc;
  }

  pre {
    display: block;
    background-color: ${ColorGrey00};
    font-family: "Lucida Console", Monaco, monospace;
  }

	a {
		text-decoration: none;
		color: ${ColorUltraviolet50};

		&[data-mention] {
			cursor: pointer;
		}
	}
`;
export const EditableStyled = styled(Editable) `
	padding: 13px 16px;

	${({ readOnly }) => readOnly && css `
		background-color: ${ColorGrey10};
		color: ${ColorGrey40};
		border-color: ${ColorGrey20};
	`};

	[data-slate-node="element"]:not([data-slate-inline]) {
		position: relative;
	}

	[data-slate-placeholder="true"] {
		color: #101830;
		font-size: 14px;
		font-family: "Sailec","Helvetica Neue",Helvetica,Arial,sans-serif;
		line-height: 20px;
		font-weight: 400;
		font-style: normal;
	}
`;
export const Toolbar = styled.div `
  padding: 6px;
  background-color: ${ColorGrey00};
  border-radius: 4px 4px 0 0;
  overflow: hidden;
`;
export const CornerDisplay = styled.div `
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 2px;
`;
export const CharCount = styled.div `
	font-size: 10px;
	color: ${ColorGrey40};
	cursor: default;
	margin-right: 6px;

	${({ error }) => error && css `
		color: ${ColorRed50}50;
		font-weight: 600;
	`};
	}
`;
export const EmojiButton = styled(IconButton).attrs({
    icon: EmojiSVG,
    width: 24,
    height: 24,
    size: "small"
}) `
	fill: ${ColorGrey60};
`;
export const ModalContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
export const ToolbarRoot = styled.div `
	background-color: ${ColorGrey00};
	padding: 6px;
	overflow: hidden;
	border-radius: 4px 4px 0 0;
	gap: 4px;
	display: flex;
`;
export const ToolbarIconButton = styled(IconButton) `
	${({ active }) => active && css `
		background-color: ${ColorGrey20};
		color: ${ColorGrey100};

		&:hover {
			background-color: ${ColorGrey20};
			color: ${ColorGrey100};
		}
	`}
`;
export const EditorEmojiRoot = styled.div `
	.EmojiPickerReact.epr-main {
		position: absolute;
		top: 38px;
		right: 0;
		z-index: 1000;
	}
`;
