import { ColorUltraviolet50 } from "@sermo/design-tokens";
import { Icon, StarOfLifeSVG, HashTagSVG, GearSVG } from "@sermo/ui-components";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Button from "@components/Button/Button";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import Menu from "@components/Menu/Menu";
import BrowseTopics from "@components/Modal/Modals/BrowseTopics/BrowseTopics";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { EUIKey, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const GearContainer = styled.div`
	position: relative;
	width: 100%;
`;

const GearIcon = styled(Icon)`
	position: absolute;
	right: 12px;
	top: -28px;
	cursor: pointer;
	color: ${({ theme }) => theme.color.icon02};
	&:hover {
		color: ${ColorUltraviolet50};
	}
`;

const FollowMenu = () => {
	const isMobile = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();

	const {
		followedTopics,
		hasAccess,
	} = useMemoizedContext("member", [
		"followedTopics",
		"hasAccess",
	]);

	if (
		!followedTopics
		|| !hasAccess("canAccessFrontendPulseFeed")
	) {
		return null;
	}

	const openTopicsModal = () => {
		trackEvent({
			category: "left-navigation-feed",
			action: "click",
			label: "browse-topic",
		});

		openModal({
			[EUIKey.MODAL_COMPONENT]: <BrowseTopics />,
			[EUIKey.MODAL_LABEL]: "browse-topics",
			[EUIKey.MODAL_SUBTYPE]: "browseTopics",
			[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.feedInfo.browseTopics"),
		});
	}

	return (
		<ModuleWrapper
			contextClass="follow-menu"
			// TODO: remove this tour id, only necessary for preliminary testing purposes
			data-component={"TopicsYouFollow"}
			label={getTranslation("frontend.followingMenu.label", true)}
			padded={false}
			type="followed"
		>
			<GearContainer >
				<GearIcon
					data-component="ConfigureTopics"
					src={GearSVG}
					width={
						isMobile
							? 20
							: 24
					}
					height={
						isMobile
							? 20
							: 24
					}
					onClick={openTopicsModal}
				/>
			</GearContainer>
			<Menu
				label="following"
				contextClass="left-rail"
				inline={true}
				noModalBackground
			>
				{
					followedTopics.map((option, i) => {
						const { name, slug, type } = option;

						const icon = type === "Specialty"
							? StarOfLifeSVG
							: HashTagSVG;
						return (
							<Button
								tooltip={name}
								key={i}
								to={`/feed/topic/${slug}`}
								labelPosition="right"
								icon={
									<Icon
										src={icon}
										width={24}
										height={24}
									/>
								}
								contextClass="left-rail"
								theme="light"
								style="flatNeutral"
								size="small"
								alignment="start"
								exact={true}
								tracking={
									{
										category: "left-navigation-feed",
										action: "click",
										label: "feed-" + slug,
									}
								}
							>
								{name}
							</Button>
						);
					})
				}
			</Menu>
		</ModuleWrapper>
	);
};

export default FollowMenu;
