import { useEffect } from "react";
import { useSaving } from "@contexts/PostUI/Editing";
import { useMemoizedContext, usePrevious } from "@hooks/Hooks";
import { useDebounce, useApiEndpoint } from "@hooks/Hooks";

export const PollQuestionEditAPISync = () => {
	const apiEndpoint = useApiEndpoint();
	const debounceDelay = 2000;
	const save = useSaving();

	const {
		publishing,
	} = useMemoizedContext("postUI", [
		"publishing",
	]);

	const pollQuestion = useMemoizedContext("pollQuestion", [
		"id",
		"question",
	]);

	const {
		id: questionId,
		question: questionText,
	} = pollQuestion;

	const apiCall = options => {
		const defaults = {
			endpoint: "posts",
			body: {},
			formData: false,
		};

		const { endpoint, action, body, formData } = {
			...defaults,
			...options,
		};

		return apiEndpoint(`${endpoint}/${action}`, body, formData);
	};

	const [questionTextDebounced] = useDebounce(questionText, debounceDelay);
	const questionTextPrevious = usePrevious(questionTextDebounced);

	useEffect(() => {
		if (
			!publishing
			&& questionTextDebounced
			&& (questionTextDebounced !== questionTextPrevious)
		) {
			apiCall({
				action: "updateQuestion",
				body: {
					id: questionId,
					question: questionText,
				},
			}).then(() => {
				save.stop(`updateQuestion${questionId}`);
			});
		} else if (
			questionText === questionTextPrevious
			&& questionTextDebounced === questionTextPrevious
		) {
			save.stop(`updateQuestion${questionId}`);
		}
	}, [
		questionText,
		questionTextDebounced,
	]);

	return null;
};