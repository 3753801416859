import React from "react";
import styled from "styled-components";
import Featured from "@components/Card/components/Featured/Featured";
import Images from "@components/Card/components/Images/Images";
import { PollDisplay } from "@components/Card/components/Polls/Polls";
import Title from "@components/Card/components/Title/Title";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import SermoContentInFeed from "@components/PostContent/components/SermoContentInFeed";
import CardContainer from "@components/PostViewer/components/CardContainer";
import CardFooter from "@components/PostViewer/components/CardFooter";
import CardHeader from "@components/PostViewer/components/CardHeader";
import * as SharedStyles from "@components/PostViewer/PostViewer.styles";
import { useMemoizedContext } from "@hooks/Hooks";
export const ImageBar = styled.div `
	width: 100%;
	height: 8px;
	margin: 0;
	background-image: url(${({ $url }) => $url});
	background-position: center;
	background-size: cover;
	display: block;
	object-fit: cover;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	position: absolute;
	top: 0;
	left: 0;

	@media ${MobileAndTabletPortraitQuery} {
		border-radius: 0;
	}
`;
const SermoContentCardInFeed = () => {
    const { displayOption, theme, } = useMemoizedContext("postData", [
        "displayOption",
        "theme",
    ]);
    return (<CardContainer>
			<ModuleWrapper solid={true} padded={true} notPaddedY={true}>
				{"TopBar" === theme?.type && <ImageBar $url={theme?.imageUrl}/>}
				<SharedStyles.SpacingContainer>
					<CardHeader />
					<div>
						<Title />
						<SermoContentInFeed />
					</div>
					{"FeatureImage" === displayOption && <Featured />}
					{"ImageAttachments" === displayOption && <Images />}
					{"Poll" === displayOption && <PollDisplay />}
				</SharedStyles.SpacingContainer>
			</ModuleWrapper>
			<CardFooter />
		</CardContainer>);
};
export default SermoContentCardInFeed;
