import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { CustomDropDownList } from "@components/FormFields/FormFields";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const SettingsMobileNavDropDown = () => {
	const { section } = useParams();

	const [route, setRoute] = useState(section);
	const [redirect, setRedirect] = useState(false);
	const { hasAccess } = useMemoizedContext("member", ["hasAccess"]);

	const options = [];

	if (hasAccess("canAccessFrontendAccountSettings")) {
		options.push({
			name: getTranslation("frontend.settings.navigation.accountSettings"),
			value: "accountsettings",
			tracking: {
				category: "account-setting-left-rail",
				action: "click",
				label: "account-overview",
			},
		});
	}

	if (hasAccess("canAccessFrontendLanguagePreferenceSettings")) {
		options.push({
			name: getTranslation("frontend.settings.navigation.languagePreferences"),
			value: "languagepreferences",
			tracking: {
				category: "account-setting-left-rail",
				action: "click",
				label: "language-preferences",
			},
		});
	}

	if (hasAccess("canAccessFrontendEmailNotificationSettings")) {
		options.push({
			name: getTranslation("frontend.settings.navigation.notifications"),
			value: "emailnotifications",
			tracking: {
				category: "account-setting-left-rail",
				action: "click",
				label: "email-notifications",
			},
		});
	}

	if (hasAccess("canAccessFrontendBlockedMemberSettings")) {
		options.push({
			name: getTranslation("frontend.settings.navigation.blockedMembers"),
			value: "blockedmembers",
			tracking: {
				category: "account-setting-left-rail",
				action: "click",
				label: "blocked-members",
			},
		});
	}

	const changeHandler = value => {
		setRoute(value);
		setRedirect(true);
	};

	useEffect(() => {
		if (redirect) {
			setRedirect(false);
		}
	}, [route]);

	return (
		<MobileAndTabletPortrait>
			{redirect && <Navigate to={`/settings/${route}`} />}
			<ModuleWrapper
				padded={false}
				contextClass="mobile-header"
				solid={true}
			>
				{/* TODO: In the near future, replace CustomDropDownList with <Select /> component */}
				<CustomDropDownList
					value={route}
					options={options}
					handleChange={changeHandler}
				/>
			</ModuleWrapper>
		</MobileAndTabletPortrait>
	);
};

export default SettingsMobileNavDropDown;
