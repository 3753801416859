import React from "react";
import { EditorElementType } from "@components/Editor/model/EditorTypes";
export const EditorElement = ({ attributes, children, element }) => {
    switch (element.type) {
        case EditorElementType.mention:
            return (<a {...attributes} role="link" data-mention={element.value}>
					{element.label}
					{children}
				</a>);
        case EditorElementType.a:
            return (<a {...attributes} role="link">
					{element.label}
					{children}
				</a>);
        case EditorElementType.p:
            return (<p {...attributes}>{children}</p>);
        case EditorElementType.ul:
            return (<ul {...attributes}>{children}</ul>);
        case EditorElementType.ol:
            return (<ol {...attributes}>{children}</ol>);
        case EditorElementType.li:
            return (<li {...attributes}>{children}</li>);
        case EditorElementType.lit:
            return (<div {...attributes}>{children}</div>);
        case EditorElementType.blockquote:
            return (<blockquote {...attributes}>{children}</blockquote>);
        case EditorElementType.pre:
            return (<code {...attributes}>{children}</code>);
        case EditorElementType.h1:
            return (<h1 {...attributes}>{children}</h1>);
        case EditorElementType.h2:
            return (<h2 {...attributes}>{children}</h2>);
        case EditorElementType.h3:
            return (<h3 {...attributes}>{children}</h3>);
        case EditorElementType.h4:
            return (<h4 {...attributes}>{children}</h4>);
        case EditorElementType.h5:
            return (<h5 {...attributes}>{children}</h5>);
        case EditorElementType.h6:
            return (<h6 {...attributes}>{children}</h6>);
        default:
            return (<span {...attributes}>{children}</span>);
    }
};
