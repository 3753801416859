import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Attendees from "@components/LiveEvents/components/Attendees/Attendees";
import { StatusPropType } from "@components/LiveEvents/components/EventsContext/EventPropTypes";
import EventTime from "@components/LiveEvents/components/EventTime/EventTime";
import { EVENT_ON_DEMAND } from "@components/LiveEvents/LiveEvents.utils";
import {
	MobileAndTabletPortrait,
	TabletLandscapeAndDesktop
} from "@components/MediaQueries/MediaQueries";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import { StyledItemTitle, StyledItem } from "./styled";

const EventItem = ({
	id,
	name,
	totalRegistrants,
	isRegistered,
	startDateTime,
	startDateTimeUtc,
	endDateTime,
	endDateTimeUtc,
	status,
}) => {
	const { locale } = useMemoizedContext("member", ["locale"]);
	const trackEvent = useTrackEvent();

	const clickEventHandler = (eventId) => {
		trackEvent({
			category: "right-rail",
			action: "click",
			label: "events",
			area: "right-rail",
			eventId: eventId,
		});
	};

	return (
		<>
			<TabletLandscapeAndDesktop>
				<StyledItem>
					<NavLink
						to={`/event/${id}`}
						onClick={() => clickEventHandler(id)}
					>
						<EventTime
							from={startDateTime}
							fromUtc={startDateTimeUtc}
							to={endDateTime}
							toUtc={endDateTimeUtc}
							size={"small"}
							status={status}
							locale={locale}
						/>
						<StyledItemTitle $kind={"bodyShortMedium02"}>
							{name}
						</StyledItemTitle>
						{
							EVENT_ON_DEMAND !== status
					&& <Attendees
						attendees={totalRegistrants}
						registered={isRegistered}
						size={"small"}
						from={startDateTime}
						to={endDateTime}
					/>
						}
					</NavLink>
				</StyledItem>
			</TabletLandscapeAndDesktop>
			<MobileAndTabletPortrait>
				<NavLink
					to={`/event/${id}`}
					onClick={() => clickEventHandler(id)}
				>
					<EventTime
						from={startDateTime}
						fromUtc={startDateTimeUtc}
						to={endDateTime}
						toUtc={endDateTimeUtc}
						size={"small"}
						status={status}
						locale={locale}
					/>
					<StyledItemTitle $kind={"bodyShortMedium01"}>
						{name}
					</StyledItemTitle>
					{
						EVENT_ON_DEMAND !== status
						&& <Attendees
							attendees={totalRegistrants}
							registered={isRegistered}
							size={"small"}
							from={startDateTime}
							to={endDateTime}
						/>
					}
				</NavLink>
			</MobileAndTabletPortrait>
		</>
	)
}

EventItem.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	totalRegistrants: PropTypes.number,
	isRegistered: PropTypes.bool,
	startDateTime: PropTypes.string,
	startDateTimeUtc: PropTypes.string,
	endDateTime: PropTypes.string,
	endDateTimeUtc: PropTypes.string,
	status: StatusPropType,
};

export default EventItem;
