import { useNavigate } from "react-router-dom";
import {
	ACTION_IN,
	ACTION_OUT,
	getUnsubscribeEmailType,
} from "@components/LoggedOut/components/Unsubscribe/utils";
import AffiliateMemberModal from "@components/Modal/Modals/AffiliateMember/AffiliateMemberModal";
import { TrialMemberModal } from "@components/Modal/Modals/TrialMember";
import { NotificationSubscriptionType } from "@components/Modal/Modals/Unsubscribe/config";
import UnsubscribeModal from "@components/Modal/Modals/Unsubscribe/UnsubscribeModal";
import { EUIKey, useCloseModal, useOpenBanner, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { AttachmentType } from "@frontend/types/Post/attachment";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

export const SEEN_TRIAL = "hasSeenTrialModal";

export const isNotToday = (timestamp) => {
	const inputDate = new Date(timestamp);
	const today = new Date();

	inputDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	return inputDate.getTime() !== today.getTime();
}

/**
 *
 * @param url - string
 * @param prefix - string
 * @returns - `${prefix}/api${url}`
 */
export const apiUrl = (url, prefix) => `${prefix}/api${url}`;

const getCookie = name => {
	const value = "; " + document.cookie;
	const parts = value.split("; " + name + "=");
	if (parts.length === 2) {
		return decodeURIComponent(
			parts
				.pop()
				.split(";")
				.shift()
		);
	}

	return null;
};

export { getCookie };

const setCookie = (name, value, days, minutes) => {
	let expires = "";
	if (minutes) {
		let date = new Date();
		date.setTime(date.getTime() + minutes * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export { setCookie };

const deleteCookie = name => {
	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export { deleteCookie };

const fetchUrl = (url, noCors = true) => {
	const options = {
		method: "GET",
		credentials: "same-origin",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
	}

	if (noCors) {
		options["mode"] = "no-cors";
	}

	return fetch(url, options)
}

export { fetchUrl }

const apiEndpoint = (url, data, formData = false, method = "POST", noCors = false, replayOn400 = true) => {
	let bodyData = null;

	const headers = {
		"X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
		Accept: "application/json",
	};

	if (window?.sermo?.user?.memberId !== undefined)
	{
		headers["X-Sermo-MemberId"] = window.sermo.user.memberId;
	}

	if (data !== null) {
		if (!formData) {
			bodyData = JSON.stringify(data);
			headers["Content-Type"] = "application/json";
			headers["Accept"] = "application/json";
		} else {
			bodyData = data;
		}
	} else {
		headers["Content-Type"] = "application/json";
		headers["Accept"] = "application/json";

		if (method === "POST") {
			bodyData = JSON.stringify({});
		}
	}

	return new Promise((resolve, reject) => {
		// Fix for IE and Edge: using fetch on Edge is causing the set-cookie header to not set a cookie on the browser.
		// The solution was to add credentials: "same-origin" to the fetch options object.

		let initValues = {
			method: method,
			credentials: "same-origin",
			headers: headers,
			body: bodyData,
		};

		if (noCors) {
			initValues.mode = "no-cors";
		}

		fetch(url, initValues)
			.then(response => {
				if (response.status === 500) {
					response
						.clone()
						.json()
						.then(body => {
							if (body.debugErrorMessage !== null) {
								if ("undefined" !== typeof window && window.sermo.env) {
									if (window.sermo.env !== "staging" && window.sermo.env !== "production") {
										let a = document.createElement("pre");
										a.appendChild(
											document.createTextNode("Error invoking endpoint: " + url + "\r\n")
										);
										a.appendChild(
											document.createTextNode(
												// eslint-disable-next-line max-len
												"-----------------------------------------------------------------------\r\n"
											)
										);
										a.appendChild(document.createTextNode(body.debugErrorMessage));
										a.setAttribute(
											"style",
											// eslint-disable-next-line max-len
											"border: 4px solid red; margin: 140px 15% 40px 15%; background-color: yellow; color: red; max-height: 80%; overflow: auto; font-family: consolas; padding: 20px 100px; line-height:20px;position: fixed; font-size:12px; top: 0; left: 0; width: 70%;white-space: pre-wrap; z-index:1000;"
										);
										document.body.appendChild(a);
									}
								}
							}
						});
					resolve(response);
				} else if (response.status === 401) {
					// We got a 401 Access Denied. Check the system endpoint to verify access.
					// If that endpoint fails as well, it means that the member has been signed
					// out, and we will redirect them to the login screen.
					let systemEndpoint = url.substring(0, url.indexOf("/api/") + 5) + "system/getmemberdata";
					fetch(systemEndpoint, {
						method: "POST",
						credentials: "same-origin",
						headers: headers,
						body: JSON.stringify({}),
					}).then(systemResponse => {
						if (systemResponse.status === 401) {
							if ("undefined" !== typeof window) {
								window.location = "/login?returnUrl=" + escape(window.location.href);
							}
						}

						resolve(response);
					});
				} else if (response.status === 503) {
					window.location.reload();
				} else if (response.status === 400 && replayOn400) {
					let systemEndpoint = url.substring(0, url.indexOf("/api/") + 5) + "system/verifyaccess";
					fetch(systemEndpoint, {
						method: "POST",
						credentials: "same-origin",
						headers: headers,
					}).then(systemResponse => {
						if (systemResponse.status === 200) {
							// replay
							resolve(apiEndpoint(url, data, formData, method, noCors, false));
						} else {
							resolve(response);
						}
					});
				} else {
					resolve(response);
				}
			})
			.catch(errorData => {
				console.log("API endpoint error: %s", url, errorData);
				reject(errorData);
			});
	});
};

export { apiEndpoint };

export const getAttachmentType = type => {
	if (type.includes("image")) {
		return AttachmentType.IMAGE;
	}
	if (type.includes("video")) {
		return AttachmentType.MP4_VIDEO;
	}
	return AttachmentType.PDF_DOCUMENT;
};

const fileToJSON = file => {
	return {
		lastModified: file.lastModified,
		lastModifiedDate: file.lastModifiedDate,
		name: file.name,
		size: file.size,
		type: getAttachmentType(file.type),
	};
};

export { fileToJSON };

const getUrlParameter = name => {
	if ("undefined" !== typeof window) {
		const storedQueryParam = window?.sermo?.queryParams?.[name];
		if (storedQueryParam) {
			return storedQueryParam;
		}

		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)", "i");
		var results = regex.exec(window.location.search);
		return results === null
			? ""
			: decodeURIComponent(results[1].replace(/\+/g, " "));
	}
	return false;
};

export { getUrlParameter };

const isUrl = url => (/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(url));

export { isUrl };

const removeParamFromQueryString = (fullParamQueryString, paramToRemove) => {
	let startsWithQuestionMark = fullParamQueryString[0] === "?";

	// if it start with question mark remove it
	if (startsWithQuestionMark) {
		fullParamQueryString = fullParamQueryString.substring(1);
	}

	var newQueryString = "";
	var queryParams = fullParamQueryString.split("&");

	for (let i = 0; i < queryParams.length; i++) {
		let paramName = queryParams[i].split("=");

		if (paramName[0] !== paramToRemove) {
			newQueryString = newQueryString + paramName[0] + "=" + paramName[1] + "&";
		}
	}

	let valueToReturn = newQueryString.substring(0, newQueryString.length - 1);

	if (startsWithQuestionMark) {
		if (valueToReturn.length > 0) {
			valueToReturn = "?" + valueToReturn;
		}
	}

	return valueToReturn;
}

export { removeParamFromQueryString };

const removeParamsFromQueryString = (fullParamQueryString, paramsToRemove) => {
	if ("undefined" === typeof fullParamQueryString || "" === fullParamQueryString) {
		return "";
	}

	for (let i = 0; i < paramsToRemove.length; i++) {
		fullParamQueryString = removeParamFromQueryString(fullParamQueryString, paramsToRemove[i]);
	}

	return fullParamQueryString;
}

export { removeParamsFromQueryString };

const removeParamFromUrl = (fullUrl, paramToRemove) => {
	let url = fullUrl;
	let queryString = "";

	if (fullUrl === null) {
		return fullUrl;
	}

	if (fullUrl.indexOf("?") > 0) {
		url = fullUrl.substring(0, fullUrl.indexOf("?"));
		queryString = fullUrl.substring(fullUrl.indexOf("?"));
		queryString = removeParamFromQueryString(queryString, paramToRemove);
	}

	return url + queryString;
}

export { removeParamFromUrl };

const removeParamsFromUrl = (fullUrl, paramsToRemove) => {
	let url = fullUrl;
	let queryString = "";

	if (fullUrl === null) {
		return fullUrl;
	}

	if (fullUrl.indexOf("?") > 0) {
		url = fullUrl.substring(0, fullUrl.indexOf("?"));
		queryString = fullUrl.substring(fullUrl.indexOf("?"));
		queryString = removeParamsFromQueryString(queryString, paramsToRemove);
	}

	return url + queryString;
}

export { removeParamsFromUrl };

// taken from
// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0
		? 0
		: decimals;
	const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export { formatBytes };

const findTrackingLabelFromRights = (rightsRequired) => {
	let label = "";
	if (rightsRequired && rightsRequired.length) {
		switch (rightsRequired[0]) {
			case "canAccessFrontendAccountSettings":
				label = "accountsetting";
				break;
			case "canAccessFrontendActivityNotifications":
				label = "notification";
				break;
			case "canAccessFrontendBlockedMemberSettings":
				label = "blockedmember";
				break;
			case "canAccessFrontendDrugRatings":
				label = "drugrating";
				break;
			case "canAccessFrontendEmailNotificationSettings":
				label = "emailnotification";
				break;
			case "canAccessFrontendLanguagePreferenceSettings":
				label = "languagesetting";
				break;
			case "canAccessFrontendPrivateMessages":
				label = "privatemessage";
				break;
			case "canAccessFrontendPulseFeed":
				label = "feed";
				break;
			case "canAccessFrontendSearch":
				label = "search";
				break;
			case "canAccessFrontendSurveys":
				label = "survey";
				break;
			case "canTakeFrontendSurveys":
				label = "survey";
				break;
			case "canCommentOnPostsInFrontend":
				label = "comment";
				break;
			case "canCreateEditAndDeletePostsInFrontend":
				label = "crudpost";
				break;
			case "canFollowItemsInFrontend":
				label = "follow";
				break;
			case "canHideItemsInFrontend":
				label = "hide";
				break;
			case "canLikeItemsInFrontend":
				label = "like";
				break;
			case "canMuteItemsInFrontend":
				label = "mute";
				break;
			case "canViewOwnProfile":
				label = "profileview";
				break;
			case "canViewPostsInFrontend":
				label = "postview";
				break;
			case "canVoteOnItemsInFrontend":
				label = "vote";
				break;
			case "canWithdrawMoneyFromOwnAccount":
				label = "withdraw";
				break;
			case "canInviteColleaguesInFrontend":
				label = "invite";
				break;
			default:
				label = "popup";
				break;
		}
	}

	return label;
}

export { findTrackingLabelFromRights };

/**
 * Encapsulates the logic to show Affiliate or Trial member modals.
 * @returns {(function(*): (null|undefined))|*}
 */
const usePreconditionModal = (callback) => {
	const NONE = 0;
	const AFFILIATE = 1;
	const TRIAL = 2;
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const {
		locale,
		loggedIn,
		hasAcceptedTermsAndConditions,
		trialMember,
		affiliateMember,
		queryParams,
	} = useMemoizedContext("member", [
		"locale",
		"loggedIn",
		"hasAcceptedTermsAndConditions",
		"trialMember",
		"affiliateMember",
		"queryParams",
	]);

	const baseTrackingObject = {
		label: "timed",
		locale,
	};

	const getTrackingObject = (extraTrackingObj) => {
		return {
			...baseTrackingObject,
			...extraTrackingObj,
			category: affiliateMember
				? "affiliatemembermodal"
				: "trialmembermodal",
			action: "close",
			queryParams,
		}
	};

	const openPreconditionModal = (extraTrackingObj = {}) => {
		const modal = !loggedIn
			? NONE
			: (
				affiliateMember
				&& !hasAcceptedTermsAndConditions
			)
				? AFFILIATE
				: trialMember
					? TRIAL
					: NONE;

		const trackOpeningModal = () => {
			trackEvent({
				...getTrackingObject(extraTrackingObj),
				action: "open",
				area: `affiliate_modal`,
			});
		};

		const closeModalEventHandler = (label) => {
			const trackingObject = getTrackingObject(extraTrackingObj);

			if ("undefined" !== typeof window) {
				if (window.sermo.temp) {
					trackingObject.postId = window.sermo.temp.expandedPostId;
					trackingObject.adId = window.sermo.temp.expandedAdId;

					if (window.sermo.temp.expandedPostId) {
						trackingObject.expanded = true;
					}
				}
			}

			trackEvent(trackingObject);

			closeModal(label);
			callback && callback(affiliateMember
				? "affiliate"
				: "trial", trackingObject);
		};

		const canClose = isNotToday(new Date(+(localStorage.getItem(SEEN_TRIAL) || 0)));
		extraTrackingObj.state = canClose
			? "state1"
			: "state2";

		switch (modal) {
			case AFFILIATE:
				trackOpeningModal();
				openModal({
					[EUIKey.MODAL_LABEL]: "affiliate-member",
					[EUIKey.MODAL_CLOSE_METHOD]: ()=>{
						closeModalEventHandler("affiliate-member")
					},
					[EUIKey.MODAL_COMPONENT]: (
						<AffiliateMemberModal
							actionHandler={ ()=>{closeModalEventHandler("affiliate-member")}}
						/>
					),
				});
				break;
			case TRIAL:
				openModal({
					[EUIKey.MODAL_LABEL]: "trial-member",
					[EUIKey.MODAL_CLOSE_METHOD]: ()=>{closeModalEventHandler("trial-member")},
					[EUIKey.MODAL_CAN_CLOSE]: canClose,
					[EUIKey.MODAL_COMPONENT]: (
						<TrialMemberModal
							closeHandler={ ()=>{closeModalEventHandler("trial-member")}}
							trackingObject={getTrackingObject(extraTrackingObj)}
							affiliateMember={affiliateMember}
						/>
					),
					[EUIKey.MODAL_SUBTYPE]: "trial-member",
				});
				break;
			case NONE:
			default:
				return null;
		}
	};

	return { openPreconditionModal };
}

export { usePreconditionModal };

/**
 * Encapsulates the logic to show Unsubscribe modal.
 * The page expect the following query parameters:
 * - app (string): membership | mnow
 * - email (string): the user email
 * - source (integer): the type of notification email, source of the link
 *
 * Examples:
 * Logged in - /settings/emailnotifications?app=membership&email=test@test.com&source=0
 * Not Logged in - /unsubscribe?app=membership&email=test@test.com&source=0
 * @returns {(function(*): (null|undefined))|*}
 */
const useUnsubscribeModal = (callback) => {
	const apiEndpoint = useApiEndpoint();
	const navigate = useNavigate();
	const closeModal = useCloseModal();
	const openBanner = useOpenBanner();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();

	const {
		locale,
	} = useMemoizedContext("member", [
		"locale"
	]);

	const baseTrackingObject = {
		category: "unsubscribemodal",
		action: "click",
		locale,
	};

	const getTrackingObject = (extraTrackingObj) => {
		return {
			...baseTrackingObject,
			...extraTrackingObj,
			emailType: getUnsubscribeEmailType(extraTrackingObj.emailType),
		}
	};

	/**
	 * Handler for close modal action passing the callback true/false in case the unsubscription was updated or not.
	 * @param emailType The email type source of the unsubscription
	 * @param email The user email
	 * @param data The response object returned by the API call
	 * @param extraTrackingObj Extra data for tracking
	 */
	const closeModalEventHandler = (emailType, email, data, extraTrackingObj) => {
		const trackingObject = getTrackingObject({ ...extraTrackingObj, emailType, email });
		trackEvent(trackingObject);
		closeModal("unsubscribe-modal");
		callback && callback(data, email);
	};

	const callAPI = (payload) => apiEndpoint("unsubscribe/updatenotificationsettings", payload).then(
		dataResponse => {
			if (dataResponse.ok) {
				return dataResponse.json();
			} else {
				openBanner({
					[EUIKey.BANNER_LABEL]: "unsubscribe-survey-alert",
					[EUIKey.BANNER_SUBTYPE]: "error",
					[EUIKey.BANNER_CAN_CLOSE]: false,
					[EUIKey.BANNER_COMPONENT]: (
						<div
							className={"body-short-02"}
							style={{ textAlign: "center" }}
						>
							{getTranslation("frontend.unsubscribe.errorModal", true)}
						</div>
					),
				})
			}
		}).catch(() => {
		console.log("Error updating notification settings");
	});

	const openUnsubscribeModal = (source, email, app) => {
		const closeMethod = () => {
			closeModalEventHandler(source, email, null, { label: "nevermind" });
		};

		const acceptHandler = () => {
			let notificationSubscriptions = [{
				type: source,
				action: ACTION_OUT,
			}];

			if (NotificationSubscriptionType.GloballyOptOut === source) {
				notificationSubscriptions = Object.values(NotificationSubscriptionType).map(
					(type) => {
						if (NotificationSubscriptionType.GloballyOptOut === type) {
							return {
								type,
								action: ACTION_IN,
							};
						}
						return {
							type,
							action: ACTION_OUT,
						}
					}
				);
			}

			const payload = {
				emailAddress: email,
				notificationSubscriptions,
			};

			callAPI(payload, source, email).then((data) => {
				closeModalEventHandler(source, email, data,
					{ label: "unsubscribe" });
			});
		}

		const cancelHandler = () => {
			closeModalEventHandler(source, email, null, { label: "dismiss" });
			navigate("/");
		}

		const snoozeHandler = (days) => {
			const data = {
				emailAddress: email,
				notificationSubscriptions: [
					{
						type: source,
						snoozedUntil: new Date(Date.now()+(1000*60*60*24*days)).toISOString(),
					},
				],
			};
			callAPI(data, source, email).then((data) => {
				closeModalEventHandler(source, email, data,
					{ label: "snooze", days });
			});
		}

		if (source) {
			openModal({
				[EUIKey.MODAL_LABEL]: "unsubscribe-modal",
				[EUIKey.MODAL_CLOSE_METHOD]: closeMethod,
				[EUIKey.MODAL_COMPONENT]: (
					<UnsubscribeModal
						app={app}
						type={source}
						acceptHandler={acceptHandler}
						cancelHandler={cancelHandler}
						snoozeHandler={snoozeHandler}
					/>
				),
			})
		}
	};

	return { openUnsubscribeModal };
}

export { useUnsubscribeModal };

export const getDateDataWithTranslatedMonth = (date) => {
	const dateObj = date
		? new Date(date)
		: new Date();

	const month = dateObj.toLocaleString("default", { month: "long" });
	const monthTranslation = getTranslation(`system.dateTime.${month.toLowerCase()}`, true);
	const day = dateObj.getDate();
	const year = dateObj.getFullYear();

	return {
		day,
		month: monthTranslation,
		year,
	};
}
