import { Icon, PlusIcon, MinusIcon } from "@sermo/ui-components";
import React from "react";
import Button from "@components/Button/Button";
import { TextField } from "@components/FormFields/FormFields";
import * as Styles from "@components/PostEditor/components/PollEdit/PollEdit.styles";
import usePollAPI from "@components/PostEditor/components/PollEdit/usePollAPI";
import { PollAnswerActionTypes, } from "@contexts/PollAnswer";
import { useSaving } from "@contexts/PostUI/Editing";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const PollAnswerEdit = ({ index }) => {
    const pollAPI = usePollAPI();
    const save = useSaving();
    const { id: pollId, } = useMemoizedContext("poll", [
        "id",
    ]);
    const { answers, id: questionId, } = useMemoizedContext("pollQuestion", [
        "answers",
        "id",
    ]);
    const { answer: answerText, dispatch: dispatchPollAnswer, id: answerId, } = useMemoizedContext("pollAnswer", [
        "answer",
        "id",
    ]);
    const handlePollAnswerChange = (e) => {
        if (e.target.value.length <= 150) {
            save.start(`updateAnswer${answerId}`);
            dispatchPollAnswer({
                type: PollAnswerActionTypes.SET_ANSWER_TEXT,
                payload: {
                    text: e.target.value,
                },
            });
        }
    };
    const addAnswer = () => {
        pollAPI.addAnswer();
    };
    const removeAnswer = () => {
        pollAPI.deleteAnswer({ answerId });
    };
    return (<Styles.AnswerEditPlusMinus key={index}>
			<Styles.PollAnswerEdit>
				<TextField name={`poll-${pollId}-question-${questionId}-answer-${answerId}`} placeholder={getTranslation("frontend.poll.answerPlaceholder", true)} value={answerText} handleChange={handlePollAnswerChange} contextClass="solid-background" validationError={answerText.length >= 120
            ? `${150 - answerText.length} characters remaining`
            : false}/>
			</Styles.PollAnswerEdit>
			{answers.length < 7 && (<Button style="icon" icon={<Icon src={PlusIcon}/>} size="large" clickHandler={addAnswer}/>)}
			{answers.length > 2 && (<Button style="icon" icon={<Icon src={MinusIcon}/>} size="large" clickHandler={removeAnswer}/>)}
		</Styles.AnswerEditPlusMinus>);
};
export default PollAnswerEdit;
