export const AttachmentsActionTypes = Object.freeze({
    "ADD_ATTACHMENT": "ADD_ATTACHMENT",
    "DELETE_ATTACHMENT": "DELETE_ATTACHMENT",
    "RESET_ATTACHMENTS": "RESET_ATTACHMENTS",
    "RESET_ATTACHMENT_TO_BE_DELETED": "RESET_ATTACHMENT_TO_BE_DELETED",
    "UPDATE_ATTACHMENT": "UPDATE_ATTACHMENT",
});
const AttachmentsActionTypesValues = Object.values(AttachmentsActionTypes);
const AttachmentsReducer = (state, action) => {
    switch (action.type) {
        case AttachmentsActionTypes.ADD_ATTACHMENT: {
            return {
                ...state,
                attachments: [
                    ...state.attachments,
                    action.payload.attachment,
                ],
            };
        }
        case AttachmentsActionTypes.DELETE_ATTACHMENT: {
            const { composing, id, uuid, } = action.payload;
            const attachmentsToBeDeleted = [...state.attachmentsToBeDeleted];
            const attachments = state.attachments.filter((attachment) => {
                if (attachment.id === id
                    && !composing) {
                    attachmentsToBeDeleted.push(id);
                }
                return uuid
                    ? attachment.uuid !== uuid
                    : attachment.id !== id;
            });
            return {
                ...state,
                attachments,
                attachmentsToBeDeleted,
            };
        }
        case AttachmentsActionTypes.RESET_ATTACHMENTS: {
            return {
                attachments: [],
                attachmentsToBeDeleted: [],
                dispatch: state.dispatch,
            };
        }
        case AttachmentsActionTypes.RESET_ATTACHMENT_TO_BE_DELETED: {
            return {
                ...state,
                attachmentsToBeDeleted: [],
            };
        }
        case AttachmentsActionTypes.UPDATE_ATTACHMENT: {
            return {
                ...state,
                attachments: state.attachments.map((attachment) => {
                    if (attachment.uuid === action.payload.attachment.uuid) {
                        return action.payload.attachment;
                    }
                    return attachment;
                }),
            };
        }
        default:
            return state;
    }
};
export default AttachmentsReducer;
