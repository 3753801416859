import classNames from "classnames";
import React, { useState } from "react";
import Button from "@components/Button/Button";
import {
	TextField,
	TermsAndConditions,
	ValidationMessage,
	GlobalValidationMessage,
} from "@components/FormFields/FormFields";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { getUrlParameter } from "@frontend/Utils";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./FirstTimeLogin.scss";

const SetPassword = () => {
	const apiEndpoint = useApiEndpoint();
	const {
		hasAcceptedTermsAndConditions,
		surveyOutcome,
		surveyOutcomeIncentiveAmount,
		surveyOutcomeIncentiveCurrency,
		updateMember,
		email,
	} = useMemoizedContext("member",[
		"hasAcceptedTermsAndConditions",
		"surveyOutcome",
		"surveyOutcomeIncentiveAmount",
		"surveyOutcomeIncentiveCurrency",
		"updateMember",
		"email",
	]);
	const [loading, setLoading] = useState(false);

	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [newPasswordValidationError, setNewPasswordValidationError] = useState("");
	const [confirmNewPasswordValidationError, setConfirmNewPasswordValidationError] = useState("");

	const [acceptedTermsAndConditions, setAceptedTermsAndConditions] = useState(false);
	const [acceptedTermsAndConditionsValidationError, setAceptedTermsAndConditionsValidationError] = useState("");

	const [globalValidationError, setGlobalValidationError] = useState("");

	if ("undefined" === typeof window) {
		return null;
	}

	const newPasswordChangeHandler = event => {
		setNewPassword(event.target.value);
	};

	const confirmNewPasswordChangeHandler = event => {
		setConfirmNewPassword(event.target.value);
	};

	const onAcceptTermsAndConditionsChange = (e) => {
		setAceptedTermsAndConditions(e.target.checked);
	}

	let translationSuffix = "";

	if (window.sermo.affiliation && window.sermo.affiliation === "mnow") {
		translationSuffix = "Mnow";
	}

	let title = getTranslation(
		"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleDefault" + translationSuffix,
		true
	);
	let description = getTranslation(
		"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionDefault" + translationSuffix,
		false,
		email
	);

	if (hasAcceptedTermsAndConditions) {
		title = getTranslation("frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleAfterTC", true);
		description = getTranslation("frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionAfterTC");
	}

	if (surveyOutcome) {
		let incentiveTranslation = "";
		if (surveyOutcomeIncentiveAmount) {
			incentiveTranslation = getCurrencyTranslation(
				surveyOutcomeIncentiveAmount,
				surveyOutcomeIncentiveCurrency
					? surveyOutcomeIncentiveCurrency
					: "USD",
				true,
				0
			);
		}

		if (surveyOutcome === "QuotaIsFull") {
			title = getTranslation(
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleQuotaIsFull" + translationSuffix,
				true,
				incentiveTranslation
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionQuotaIsFull" + translationSuffix,
				false,
				incentiveTranslation
			);
		} else if (surveyOutcome === "QuotaIsFullNoIncentive") {
			title = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleQuotaIsFullNoIncentive" + translationSuffix,
				true
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionQuotaIsFullNoIncentive" + translationSuffix
			);
		} else if (surveyOutcome === "YouCompletedASurvey") {
			title = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleYouCompletedASurvey" + translationSuffix,
				true
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionYouCompletedASurvey" + translationSuffix
			);
		} else if (surveyOutcome === "YouScreenedOut") {
			title = getTranslation(
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleYouScreenedOut" + translationSuffix,
				true,
				incentiveTranslation
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionYouScreenedOut" + translationSuffix,
				false,
				incentiveTranslation
			);
		} else if (surveyOutcome === "YouScreenedOutNoIncentive") {
			title = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleYouScreenedOutNoIncentive" + translationSuffix,
				true
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionYouScreenedOutNoIncentive" + translationSuffix
			);
		} else {
			title = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.titleSurveyOutcomeDefault" + translationSuffix,
				true
			);
			description = getTranslation(
				// eslint-disable-next-line max-len
				"frontend.firstTimeLoginProfileConfiguration.setPasswordPage.descriptionSurveyOutcomeDefault" + translationSuffix
			);
		}
	}

	const validateForm = () => {
		let valid = true;

		if (newPassword !== null && newPassword.length >= 6) {
			setNewPasswordValidationError("");

			if (confirmNewPassword !== null && confirmNewPassword.length >= 6) {
				setConfirmNewPasswordValidationError("");

				if (newPassword !== confirmNewPassword) {
					setConfirmNewPasswordValidationError(
						getTranslation("system.validationMessages.validationTheTwoPasswordsDoNotMatch", true)
					);
					valid = false;
				}
			} else {
				setConfirmNewPasswordValidationError(
					getTranslation("system.validationMessages.validationConfirmNewPasswordIsRequired", true)
				);
				valid = false;
			}
		} else {
			setConfirmNewPasswordValidationError("");
			setNewPasswordValidationError(
				getTranslation("system.validationMessages.validationPleaseEnterYourNewPassword", true)
			);
			valid = false;
		}

		if (!hasAcceptedTermsAndConditions) {
			if (acceptedTermsAndConditions) {
				setAceptedTermsAndConditionsValidationError("");
			} else {
				setAceptedTermsAndConditionsValidationError(
					getTranslation("system.validationMessages.validationYouMustAgreeToTheTermsAndConditions", true)
				);
				valid = false;
			}
		}

		return valid;
	};

	const submitFormClickHandler = e => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);

			let timeZoneId = null;
			try
			{
				timeZoneId = new Intl.DateTimeFormat().resolvedOptions().timeZone;
			}
			catch (ex) {
				console.error(ex);
			}

			let requestData = {
				newPassword: newPassword,
				confirmNewPassword: confirmNewPassword,
				timeZoneOffset: -new Date().getTimezoneOffset(),
				timeZoneId: timeZoneId,
			};

			if (!hasAcceptedTermsAndConditions) {
				requestData.acceptTermsAndConditions = acceptedTermsAndConditions;
			}

			apiEndpoint("settings/setpassword", requestData).then(dataResponse => {
				dataResponse.json().then(body => {
					if (dataResponse.ok) {
						const returnUrl = getUrlParameter("returnUrl");

						if (returnUrl) {
							// TODO fix this on the server
							setTimeout(() => {
								window.location = returnUrl;
							}, 200);
						} else {
							setLoading(false);

							if (hasAcceptedTermsAndConditions) {
								updateMember({
									hasSetPassword: true,
								});
							} else {
								updateMember({
									hasAcceptedTermsAndConditions: true,
									hasSetPassword: true,
								});
							}
						}
					} else {
						setLoading(false);

						if (body.message) {
							setGlobalValidationError(body.message);
						}

						if (body.fields !== null) {
							for (var property in body.fields) {
								switch (property) {
									case "newPassword":
										setNewPasswordValidationError(body.fields[property].join("<br />"));
										break;
									case "confirmNewPassword":
										setConfirmNewPasswordValidationError(body.fields[property].join("<br />"));
										break;
									case "acceptTermsAndConditions":
										setAceptedTermsAndConditionsValidationError(
											body.fields[property].join("<br />")
										);
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	return (
		<div>
			<ModuleWrapper
				border={true}
				solid={true}
			>
				<div styleName={classNames(["styles.inner-module-container"])}>
					<div styleName={classNames(["styles.step-container"])}>
						<div styleName={classNames(["styles.step-header"])}>
							<div styleName={classNames(["styles.title", "typography.heading-brand-05"])}>{title}</div>
							<div
								styleName={
									classNames([
										"styles.content",
										"styles.content-padding-between-paragraphs",
										"typography.body-long-brand-03",
									])
								}
							>
								{description}
							</div>
							<div styleName="styles.input-fields-container">
								<div styleName="styles.form-line">
									<TextField
										name="newPassword"
										type="password"
										autoComplete="new-password"
										label="Create your password"
										validationError={newPasswordValidationError}
										handleChange={newPasswordChangeHandler}
										value={newPassword}
										disabled={loading}
										autoFocus={true}
									/>
								</div>
								<div styleName="styles.form-line">
									<TextField
										name="confirmNewPassword"
										type="password"
										autoComplete="confirm-new-password"
										label="Confirm your password"
										validationError={confirmNewPasswordValidationError}
										handleChange={confirmNewPasswordChangeHandler}
										value={confirmNewPassword}
										disabled={loading}
									/>
								</div>

								{
									!hasAcceptedTermsAndConditions && (
										<div
											styleName={
												classNames([
													"styles.form-line",
													"styles.terms-and-conditions-wrapper",
												])
											}
										>
											<TermsAndConditions
												name="acceptTermsAndConditions"
												text={
													getTranslation(
														"frontend.login.resetPasswordPage.acceptTermsAndConditions"
													)
												}
												handleChange={onAcceptTermsAndConditionsChange}
												checked={acceptedTermsAndConditions}
											/>
											<div styleName={classNames(["styles.terms-and-conditions-error-wrapper"])}>
												<ValidationMessage text={acceptedTermsAndConditionsValidationError} />
											</div>
										</div>
									)
								}
							</div>

							<GlobalValidationMessage text={globalValidationError} />

							<div styleName="styles.submit-container">
								<Button
									name="submit"
									size="medium"
									clickHandler={submitFormClickHandler}
									disabled={loading}
									loading={loading}
								>
									{getTranslation("frontend.generics.finish", true)}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</ModuleWrapper>
		</div>
	);
};

export default SetPassword;
