import PropTypes from "prop-types";
import React from "react";
import DetailPair from "./DetailPair";
import styles from "./ModalWithdrawalComponents.scss";

const PaymentMethod = ({ title, method, description, additionalNote }) => (
	<div styleName="styles.payment-method">
		<DetailPair
			{...{
				title,
				value: method,
			}}
		/>
		{description && <div styleName="styles.description">{description}</div>}
		{additionalNote && <div styleName="styles.description-note">{additionalNote}</div>}
	</div>
);

PaymentMethod.propTypes = {
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	method: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	additionalNote: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

PaymentMethod.defaultProps = {
	description: "",
	additionalNote: ""
};

export default PaymentMethod;
