import { ColorGrey00, ColorGrey40, ColorGrey60, ColorGrey100, ColorRed50, ColorUltraviolet50 } from "@sermo/design-tokens";
import { Radio, RadioGroup } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import {
	StyledMobileAndTabletPortraitQuery,
	StyledTabletLandscapeAndDesktopQuery,
} from "@components/MediaQueries/MediaQueries";

export const RegisterStyles = styled.div`
    .submit-container--force-success-container {
        margin-top: 20px;
        text-align: center;

        .warning {
            margin-bottom: 6px;
            display: block;
            color: ${ColorRed50};
        }

        div {
            background-color: ${ColorRed50} !important;
            color: yellow;
            width: 100%;
        }
    }

    .reverification {
        width: 512px;

        ${StyledMobileAndTabletPortraitQuery} {
            width: auto;
        }

        .accent & {
            color: ${ColorUltraviolet50};
            font-weight: 600;
        }
    }

    .register {
        position: relative;
        display: block;
        width: 100%;
        background-color: ${ColorGrey00};
        text-align: center;
        padding-top: 96px;

        ${StyledMobileAndTabletPortraitQuery} {
            margin-top: 0;
            padding-top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .half-width {
        display: flex;
        width: 50%;
        padding-right: 8px;
        padding-left: 8px;
        display: inline-block;
        vertical-align: top;

        ${StyledMobileAndTabletPortraitQuery} {
            padding-right: 7px;
            padding-left: 7px;
        }

        &.first {
            padding-left: 0;

            ${StyledMobileAndTabletPortraitQuery} {
                margin-bottom: 16px;
            }
        }

        &.last {
            padding-right: 0;

            .form-label {
                left: 23px;
            }
        }
    }

    .desktop-only {
        &.half-width {
            ${StyledMobileAndTabletPortraitQuery} {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .one-third-width {
        width: 33.3333%;
        padding-right: 15px;
        padding-left: 15px;
        display: inline-block;
        vertical-align: top;

        ${StyledMobileAndTabletPortraitQuery} {
            padding-right: 7px;
            padding-left: 7px;
        }

        &:not(.last) {
            .form-dropdownlist-handle:after {
                right: 25px;
            }
        }

        &.first {
            padding-left: 0;
        }

        &.last {
            padding-right: 0;

            .form-label {
                left: 23px;
            }
        }
    }

    .full-width-buttons {
        margin: 40px 0;

        a,
        button {
            text-align: center;
            display: block;
            margin: 20px 0;
            width: 100%;

            span {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .form {
        margin-top: 24px;

        ${StyledMobileAndTabletPortraitQuery} {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }

    .login-form {
        margin-top: 30px;
    }

    .form-field-row {
        margin-bottom: 18px;

        ${StyledMobileAndTabletPortraitQuery} {
            margin-bottom: 16px;
        }
    }

    .form-section-title {
        padding: 32px 0 16px;

        &:first-child {
            padding-top: 0;
        }

        &.with-subtitle {
            padding-bottom: 4px;
        }
    }

    .form-section-subtitle {
        padding-bottom: 16px;
        color: ${ColorGrey60};
    }

    .submit-container {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 32px;
        align-items: center;
        justify-content: space-between;

        ${StyledMobileAndTabletPortraitQuery} {
            flex-direction: column-reverse;
            flex-grow: 1;
			gap: 16px;

            & > * {
                width: 100%;
            }

            &.enter-provider-information,
            &.identity-verification {
                align-items: flex-end;
            }

            &.upload-verification-documents {
                & > * {
                    text-align: start;
                }
            }
        }
    }

    .enter-name-container {
    }

    .enter-name-submit-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;

        .register & {
            padding-top: 8px;
        }

        ${StyledMobileAndTabletPortraitQuery} {
            flex-direction: column;
            flex-grow: 1;
			gap: 16px;

            & > * {
                width: 100%;
            }
        }
    }

    .submit-container-side {
        display: flex;

        ${StyledMobileAndTabletPortraitQuery} {
            justify-content: flex-end;
        }
    }

    .submit-container--back-button {
        margin-right: 8px;

        ${StyledMobileAndTabletPortraitQuery} {
            div:first-child {
                min-width: 50px;
            }
        }
    }

    .submit-container--skip-button {
        margin-right: 50px;
        display: inline-block;

    ${StyledMobileAndTabletPortraitQuery} {
        margin-right: 0;

        div:first-child {
            min-width: 50px;
        }
    }
    }

    .submit-container--link {
        height: 100%;
        display: block;
        color: ${ColorGrey60};
		text-align: left;
    }

    .form-code-sent-to {
        color: ${ColorGrey60};
        margin-top: 16px;
    }

    .form-code-resent-confirm-message {
    }

    .suggested-providers {
        display: block;
        padding: 0;
        margin: 40px 0 10px 0;
        max-height: 50vh;
        overflow-y: auto;

        border-top: 1px solid #eeeeee;
    }

    .suggested-providers--item {
        display: block;
        padding: 20px 5px;
        border-bottom: 1px solid #eeeeee;
    }

    .suggested-providers--select-button {
        float: right;
        margin-top: 10px;
    }

    .subtitle-help-text {
        padding-bottom: 10px;
    }

    .hidden {
        display: none;
    }

    .full-width-buttons {
        margin: 30px 0 40px 0;

        div {
            width: 100%;
        }

        a,
        button {
            text-align: center;
            display: block;
            margin: 10px 0;
            width: 100%;

            span {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .cannot-see-your-profile {
        margin-top: 0;
        color: ${ColorGrey100};
    }

    .cannot-see-your-profile--link {
        cursor: pointer;
        display: inline-block;
    }

    .terms-and-conditions-wrapper {
        padding-top: 10px;
    }

    .terms-and-conditions-error-wrapper {
        padding-top: 10px;
    }

    .link {
        color: ${ColorUltraviolet50};
        cursor: pointer;
    }

    .dropZones {
        & > div:first-of-type {
            margin-bottom: 16px;
        }
    }

	/* Hack for an issue when a long text oversizes the container,
	instead the text is absolute positioned (.confirmation-text)
	while using a spacer */
	.confirmation-text-spacer {
		margin: 20px 0 8px 0;
	}
	.confirmation-text {
		color: ${ColorGrey60};
	}
`;

export const ReverificationWrap = styled.div`
    width: 512px;

    ${StyledMobileAndTabletPortraitQuery} {
        width: auto;
    }

    .accent & {
        color: ${ColorUltraviolet50};
        font-weight: 600;
    }
`;

export const RegisterWrap = styled.div`
    width: 100%;
    position: relative;
	display: flex;
	flex-direction: column;
    background-color: ${ColorGrey00};

	${StyledTabletLandscapeAndDesktopQuery} {
		min-height: calc(100vh - var(--top-nav-height));
		justify-content: center;
		align-items: center;
	}

    ${StyledMobileAndTabletPortraitQuery} {
        height: 100%;
    }
`;

export const NeedHelp = styled(TextStyled).attrs({ $kind: "bodyShort00" })`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	margin: 24px auto;
	color: ${ColorGrey60};

	div:first-child {
		display: flex;
		flex-direction: row;
		a {
			color: ${ColorGrey60};
			display: contents;
		}
	}

	div:last-child, a {
		color: ${ColorGrey40};
		display: flex;
		flex-direction: row;
		gap: 8px;
	}
`;

export const FullWidthButtons = styled.div`
    margin: 30px 0 40px 0;

    div {
        width: 100%;
    }

    a, button {
        width: 100%;
        margin: 10px 0;
        display: block;
        text-align: center;

        span {
            width: 100%;
            font-size: 14px;
        }
    }
`;

export const SuggestedProvidersItemContent = styled(TextStyled).attrs({ $kind: "bodyShort02" })`
    display: flex;
    cursor: pointer;

    & > div:first-of-type {
        margin-right: 16px;
    }
`;

export const SuggestedProvidersItemName = styled(TextStyled).attrs({ $kind: "heading02" })`
    font-weight: 600;
`;

export const SuggestedProvidersItemDetail = styled(TextStyled).attrs({ $kind: "bodyShort02" })`
    margin-top: 4px;
    color: ${ColorGrey60};
`;

export const DivLink = styled(TextStyled).attrs({ $kind: "bodyLong00" })`
    color: ${ColorUltraviolet50};
    cursor: pointer;

    &:active, &:focus, &:focus-visible, &:hover {
        text-decoration: underline;
    }
`;

export const AutomaticOrManualVerificationRadioGroup = styled(RadioGroup)`
    label {
        font-weight: 600;
    }
`

export const AutomaticOrManualVerificationRadioList = styled.div`
    margin-left: 32px;
`