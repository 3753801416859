import styled from "styled-components";
import {
	applyZIndex,
} from "@frontend/styled";

export const VideoPlayerContainer = styled.div`
    width: 100%;
    position: relative;
	text-align: left;
`;

export const Poster = styled.img`
	${applyZIndex("video-poster")};
	position: absolute;
	width: 100%;
	max-height: 100%;
	object-fit: cover;
	top: 0;
	left: 0;
	cursor: pointer;
`;

export const PlayButton = styled.div`
	${applyZIndex("video-playButton")};
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
