import { Radio } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { GlobalValidationMessage } from "@components/FormFields/FormFields";
import { LoggedOutStyles } from "@components/LoggedOut/LoggedOut.styles";
import RegistrationStep from "@components/Register/components/Registration/RegistrationStep";
import {
	RegisterStyles,
	ReverificationWrap,
	SuggestedProvidersItemContent,
	SuggestedProvidersItemDetail,
	SuggestedProvidersItemName,
} from "@components/Register/Register.styles";
import useRegisterTrack from "@components/Register/RegisterUtils";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const Form = ({ data, updateData, isRegister, endpoint }) => {
	const registerTrackEvent = useRegisterTrack();
	const apiEndpoint = useApiEndpoint();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [selectedProvider, setSelectedProvider] = useState(false)

	useEffect(() => {
		if (data === null || data.suggestedProviders === null || data.suggestedProviders.length === 0) {
			if ( isRegister ) {
				navigate("/register/enterprovider");
			} else {
				updateData({ currentStep: RegistrationStep.EnterProviderInformation });
			}
		}
	},[])

	const noProviderMatchClickHandler = (e) => {
		e.preventDefault();
		updateData({ currentStep: RegistrationStep.EnterProviderInformation });

		// registration-process-physician-profile-other-profile-click
		registerTrackEvent({
			label: "physician-profile-other-profile",
			action: "click",
		});
	};

	const submitProviderClickHandler = () => {
		setLoading(true);
		setGlobalValidationError("");

		const requestData = {
			selectedProviderId: selectedProvider,
		};

		// registration-process-physician-profile-click
		registerTrackEvent({
			label: "physician-profile",
			action: "click",
		});

		apiEndpoint(`${endpoint}/suggestedproviderselected`, requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				setLoading(false);

				if (dataResponse.ok) {
					registerTrackEvent({
						label: "profile",
						area: "profile-selection",
						action: "success",
					}, true);

					updateData({
						selectedProviderId: selectedProvider,
						...body,
					});
				}
				else {
					setGlobalValidationError(body.message);
				}
			});
		});
	};

	const goBackClientEventHandler = (e) => {
		// registration-process-physician-profile-back-click
		registerTrackEvent({
			label: "physician-profile-back",
			action: "click",
		})
		e.preventDefault();
		updateData({ currentStep: RegistrationStep.EnterName });
	};

	if (!data?.suggestedProviders?.length) {
		return null;
	}

	return (
		<LoggedOutStyles>
			<RegisterStyles data-component={"SelectSuggestedProvider"}>
				<TextStyled
					className={classNames("form-title")}
					$kind="heading04"
				>
					{getTranslation("frontend.registration.selectSuggestedProviderPage.title")}
				</TextStyled>

				<ul className={classNames(["suggested-providers"])}>
					{
						data?.suggestedProviders?.length && (
							<>
								{
									data.suggestedProviders.map(provider => (
										<li data-component={"Provider"}
											key={provider.id}
											className={classNames(["suggested-providers--item"])}
										>
											<SuggestedProvidersItemContent onClick={() => setSelectedProvider(provider.id)}>
												<div>
													<Radio
														checked={selectedProvider === provider.id}
														onChange={
															() => {
																setSelectedProvider(provider.id)
															}
														}
														name="provider"
														value={provider.id}
													/>
												</div>
												<div>
													<SuggestedProvidersItemName>
														{provider.firstName} {provider.lastName}
													</SuggestedProvidersItemName>
													<SuggestedProvidersItemDetail>
														{provider.primarySpecialtyName}
													</SuggestedProvidersItemDetail>
													<SuggestedProvidersItemDetail>
														{provider.city}, {provider.region}
													</SuggestedProvidersItemDetail>
												</div>
											</SuggestedProvidersItemContent>
										</li>
									))
								}
							</>
						)
					}
				</ul>

				<TextStyled
					className={classNames(["cannot-see-your-profile"])}
					$kind="bodyLong02"
				>
					{getTranslation("frontend.registration.selectSuggestedProviderPage.buttonDoNotSeeYourNameText")}
					<span
						className={classNames(["cannot-see-your-profile--link"])}
						onClick={noProviderMatchClickHandler}
					>
						&nbsp;
						<a>
							{getTranslation("frontend.registration.selectSuggestedProviderPage.buttonDoNotSeeYourNameLinkText", true)}
						</a>
					</span>
				</TextStyled>

				<GlobalValidationMessage text={globalValidationError} />

				<div className={classNames(["submit-container"])}>
					<div className={classNames(["submit-container-side"])}>
						<div className={classNames(["submit-container--back-button"])}>
							<Button
								name="back"
								size="medium"
								style="flatNeutral"
								disabled={loading}
								clickHandler={goBackClientEventHandler}
							>
								{getTranslation("frontend.generics.back", true)}
							</Button>
						</div>
						<Button
							name="submit"
							size="medium"
							disabled={loading || !selectedProvider}
							clickHandler={submitProviderClickHandler}
						>
							{getTranslation("frontend.generics.next", true)}
						</Button>
					</div>
				</div>
			</RegisterStyles>
		</LoggedOutStyles>
	)
}

const SelectSuggestedProvider = ({ data, updateData, endpoint }) => {
	const isRegister = !!useMatch("/register/*");
	const registerTrackEvent = useRegisterTrack();

	useEffect(() => {
		// registration-process-physician-profile-page-view
		registerTrackEvent({
			label: "physician-profile-page",
			action: "view",
		});
	}, []);

	return (
		<>
			{
				isRegister && <LoggedOutStyles>
					<div className={classNames(["content-box-wider"])}>
						<div className={classNames(["content-box-inner-container"])}>
							<Form
								data={data}
								updateData={updateData}
								isRegister={isRegister}
								endpoint={endpoint}
							/>
						</div>
					</div>
				</LoggedOutStyles>
			}
			{
				!isRegister && <ReverificationWrap>
					<Form
						data={data}
						updateData={updateData}
						isRegister={isRegister}
						endpoint={endpoint}
					/>
				</ReverificationWrap>
			}
		</>
	);
};

export default SelectSuggestedProvider;
