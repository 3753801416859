import { useMemo } from "react";
import PartnerLink from "@components/Card/components/PostContentEditor/PartnerLink";
import InlineVideoPLayer from "@components/Card/components/VideoPlayer/InlineVideoPlayer";
import { ContentContainer, contentContainerClickHandler, insertReadMore, sanitizeLinks, useContentAreaClickEventHandler, useInlineVideos, usePartnerModalLinks, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import ReadMore from "@components/PostContent/components/ReadMore";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./ResourceContentExpanded.styles";
const ResourceContentInFeed = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const inlineVideos = useInlineVideos();
    const partnerModalLinks = usePartnerModalLinks();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showIntroduction, showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showIntroduction",
        "showOriginalContent",
    ]);
    const { contentHtml, introduction, originalContentHtml, resourceCenterItemId, } = useMemoizedContext("postData", [
        "contentHtml",
        "introduction",
        "originalContentHtml",
        "resourceCenterItemId",
    ]);
    const contentToDisplay = useMemo(() => {
        const editorContent = showOriginalContent
            ? originalContentHtml
            : contentHtml;
        if (introduction
            && showIntroduction) {
            return insertReadMore(introduction, `read-more-portal-${resourceCenterItemId}`);
        }
        const sanitizedContent = sanitizeLinks(editorContent);
        return introduction
            ? insertReadMore(sanitizedContent, `read-more-portal-${resourceCenterItemId}`)
            : sanitizedContent;
    }, [
        contentHtml,
        introduction,
        originalContentHtml,
        resourceCenterItemId,
        showIntroduction,
        showOriginalContent,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				{inlineVideos?.map((iv, i) => (<InlineVideoPLayer el={iv} key={i}/>))}
				{partnerModalLinks?.map((element, i) => (<PartnerLink el={element} key={i}/>))}
				<ContentContainer key={resourceCenterItemId} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={"bodyLong03"} onClick={contentAreaClickEventHandler}/>
					<ReadMore />
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentToDisplay,
        resourceCenterItemId,
        inlineVideos,
        partnerModalLinks,
        readOnlyMember,
        resizeListener,
    ]);
};
export default ResourceContentInFeed;
