import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useMemoizedContext } from "@hooks/Hooks";
import FirstTimeLogin from "../FirstTimeLogin/FirstTimeLogin";
import SetPassword from "../FirstTimeLogin/SetPassword";
import "./FirstTimeLoginLayout.scss";

const FirstTimeLoginLayout = () => {
	const {
		hasConfiguredProfile,
		hasSetPassword,
	} = useMemoizedContext("member", [
		"hasConfiguredProfile",
		"hasSetPassword",
	]);

	if (hasConfiguredProfile && hasSetPassword) {
		return <Navigate
			to={`/feed/all-sermo${window.location.search}`}
			replace
		/>;
	}

	if ("undefined" !== typeof window) {
		if (!hasSetPassword) {
			return (
				<div styleName="first-time-login-page">
					<Routes>
						<Route
							exact
							path="/firsttimelogin/setpassword"
							element={<SetPassword />}
						/>
						<Route
							path="*"
							element={
								<Navigate
									to={
										{
											...window.location,
											pathname: "/firsttimelogin/setpassword",
											search: "returnUrl=" + escape(window.location.href),
										}
									}
								/>
							}
						/>
					</Routes>
				</div>
			);
		}

		return (
			<div styleName="first-time-login-page">
				<Routes>
					<Route
						exact
						path="/firsttimelogin/"
						element={<FirstTimeLogin />}
					/>
					<Route
						path="*"
						element={
							<Navigate
								to={
									{
										...window.location,
										pathname: "/firsttimelogin",
										search: "returnUrl=" + escape(window.location.href),
									}
								}
							/>
						}
					/>
				</Routes>
			</div>
		);
	}

	if (!hasSetPassword) {
		return (
			<div styleName="first-time-login-page">
				<Routes>
					<Route
						exact
						path="/firsttimelogin/setpassword"
						element={<SetPassword />}
					/>
					<Route
						path="*"
						element={<Navigate to="/firsttimelogin/setpassword" />}
					/>
				</Routes>
			</div>
		);
	}

	return (
		<div styleName="first-time-login-page">
			<Routes>
				<Route
					exact
					path="/firsttimelogin/"
					element={<FirstTimeLogin />}
				/>
				<Route
					path="*"
					element={<Navigate to="/firsttimelogin" />}
				/>
			</Routes>
		</div>
	);
};

export default FirstTimeLoginLayout;
