import PropTypes from "prop-types";
import { TopicProps } from "./Taxonomies";
import UserProps from "./User";

export const NewPostTypes = ["Question", "Discussion", "PatientCase"];
export const PostTypeProps = ["Question", "Comment", "Discussion", "PatientCase"];
export const PostCardTypeProps = ["Ad", "Post"];
export const PostAdTypes = ["SponsoredPost", "InFeedUnit"];

const PostPropTypes = {
	id: PropTypes.number,
	user: PropTypes.shape(UserProps),
	topics: PropTypes.arrayOf(PropTypes.shape(TopicProps)),
	postDate: PropTypes.string,
	type: PropTypes.oneOf(PostTypeProps),
	liked: PropTypes.bool,
	title: PropTypes.string,
	adType: PropTypes.oneOf(PostAdTypes),
	cardType: PropTypes.oneOf(PostCardTypeProps),
	content: PropTypes.string,
	attachments: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			url: PropTypes.string,
		})
	),
};

export default PostPropTypes;
