import { PollDefaultValues } from "@contexts/Poll/PollContext";
// import { PollDefaultValues } from "./PollContext";
const answerDefaults = {
    id: 2,
    answer: "",
    votes: 0,
};
const questionDefaults = {
    question: "",
    id: 1,
    isMultipleChoice: false,
    // length: null,
    answers: [
        { ...answerDefaults, id: 3 },
        { ...answerDefaults, id: 4 },
    ],
};
/**
 * updates the state of a given poll question
 * updates vote status of each option
 * and adjusts the vote counts of each
 * @param {*} action
 * @param {*} state
 */
// TODO we get back the full vote state from the poll so maybe this is redundant and should just wipe it;
// const pollVote = (action, state) => {
// 	const newState = { ...state };
//
// 	const { questionId, vote } = action.data;
//
// 	const rightQuestion = newState.questions.findIndex(question => question.id === questionId);
//
// 	const question = state.questions[rightQuestion];
// 	const { votes: myVotes, answers } = question;
//
// 	answers.forEach(answer => {
// 		let voteDelta = 0;
//
// 		// if the answer is in the old votes array
// 		if (myVotes.includes(answer.id)) {
// 			// see if its in the new votes
// 			if (!vote.includes(answer.id)) {
// 				// if not reduce the vote count
// 				voteDelta--;
// 			}
// 		} else {
// 			// if the answer is not in the old votes array
// 			// see if it is now
// 			if (vote.includes(answer.id)) {
// 				voteDelta++;
// 			}
// 		}
// 		answer.votes = answer.votes + voteDelta;
// 	});
//
// 	if ("Ad" !== state.cardType && !state.following) {
// 		newState.following = true;
// 	}
//
// 	question.votes = vote;
//
// 	return newState;
// };
// TODO: switch naming convention of action types to be more grammatically correct
export const PollActionTypes = Object.freeze({
    ADD_QUESTION: "ADD_QUESTION",
    DELETE_QUESTION: "DELETE_QUESTION",
    RESET_POLL: "RESET_POLL",
    SYNC_QUESTION: "SYNC_QUESTION",
    SET_DURATION: "SET_DURATION",
    UPDATE_QUESTION: "UPDATE_QUESTION",
});
const PollActionTypesValues = Object.values(PollActionTypes);
const PollReducer = (state, action) => {
    switch (action.type) {
        case PollActionTypes.ADD_QUESTION: {
            const { pollId, ...rest } = action.payload.question;
            const newState = { ...state };
            // @ts-ignore
            newState.questions = [
                ...newState.questions,
                {
                    ...questionDefaults,
                    ...rest,
                },
            ];
            if (pollId) {
                newState.id = pollId;
            }
            return newState;
        }
        case PollActionTypes.DELETE_QUESTION: {
            const { questionId } = action.payload;
            return {
                ...state,
                questions: state.questions.filter((question) => question.id !== questionId),
            };
        }
        case PollActionTypes.RESET_POLL: {
            return {
                ...PollDefaultValues,
                dispatch: state.dispatch,
            };
        }
        case PollActionTypes.SYNC_QUESTION: {
            const { question } = action.payload;
            const newState = { ...state };
            newState.questions = newState.questions.map((q, i) => {
                if (q.id === question.id) {
                    return question;
                }
                return q;
            });
            return newState;
        }
        case PollActionTypes.SET_DURATION: {
            return {
                ...state,
                duration: action.payload.duration,
            };
        }
        case PollActionTypes.UPDATE_QUESTION: {
            const { questionId, question } = action.payload;
            const newState = { ...state };
            newState.questions = state.questions.map((q, i) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        ...question,
                    };
                }
                return q;
            });
            return newState;
        }
        // case PollActionTypes.QUESTIONJ_VOTE: {
        // 	const { questionId, answers } = action.payload;
        // 	const newState = { ...state };
        // 	console.log("newState", newState);
        // 	// newState.votes.forEach((vote) => {
        // 	// 	console.log("vote", vote);
        // 	// });
        //
        // 	// newState.questions = state.questions.map((q, i) => {
        // 	// 	if (q.id === questionId) {
        // 	// 		q.answers.forEach((answer) => {
        // 	// 			if (answers.includes(answer.id)) {
        // 	// 				answer.votes++;
        // 	// 			}
        // 	// 		});
        // 	// 		return {
        // 	// 			...q,
        // 	// 		};
        // 	// 	}
        // 	//
        // 	// 	return q;
        // 	// });
        //
        // 	return newState;
        // }
        default:
            return state;
    }
};
export default PollReducer;
