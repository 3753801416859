import { Icon, XSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "@components/Button/Button";
import { formatBytes } from "@frontend/Utils";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import styles from "./FileUploadDropZone.scss";

const FileUploadDropZone = ({ title, description, validationError, onFileAdded, onFileRemoved }) => {
	const [highlight, setHighlight] = useState(false);
	const [selectedFileName, setSelectedFileName] = useState("");
	const [fileSize, setFileSize] = useState(0);
	let fileInputRef = React.createRef();

	const dragOverHandler = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setHighlight(true);
	};

	const dragLeaveHandler = (event) => {
		setHighlight(false);
	};
	
	const fileAdd = file => {
		setSelectedFileName(file.name);
		setFileSize(file.size);
		if (onFileAdded) {
			onFileAdded(file);
		}
	}
	
	const onFileAddedHandler = (event) => {
		let file = event.target.files.item(0);
		if (file) {
			fileAdd(file)
		}
	};
	
	const dropHandler = (event) => {
		event.preventDefault();
		let file = event.dataTransfer.files.item(0);
		if (file) {
			fileAdd(file)
		}
		setHighlight(false);
	};

	const openFileDialog = () => {
		fileInputRef.current.click();
	};

	const onFileRemovedHandler = (event) => {
		event.stopPropagation();
		event.preventDefault();

		setSelectedFileName("");

		fileInputRef.current.value = null;
		if (onFileRemoved) {
			onFileRemoved();
		}
	};

	return (
		<div
			styleName={
				classNames(
					"styles.droparea",
					highlight
						? "styles.droparea--highlight"
						: ""
				)
			}
			onDragOver={dragOverHandler}
			onDragLeave={dragLeaveHandler}
			onDrop={dropHandler}
			onClick={openFileDialog}
		>
			<div
				styleName={
					classNames(
						"styles.document-area",
						{ "styles.validation-error" :validationError }
					)
				}
			>
				<div styleName="styles.top">
					<div>
						<div styleName={classNames("styles.document-type-title", "typography.body-short-brand-03")}>
							{title}
						</div>
						<div styleName={classNames("styles.document-type-description", "typography.body-short-brand-00")}>
							{description}
						</div>
					</div>
					<div>
						<Button
							name="buttonSelectFile"
							style="secondary"
							size="small"
							formName="SomeRandomNameToPreventParentFormSubmitOnClick"
						>
							{getTranslation("frontend.forms.fileUploadDropZone.selectFileButtonText", true)}
						</Button>
					</div>
				</div>
				<div>
					<div
						styleName={
							classNames(
								"styles.file-selected-container",
								"typography.body-short-brand-00",
								selectedFileName
									? ""
									: "styles.hidden"
							)
						}
					>
						<div styleName="styles.file-selected-filename">
							{selectedFileName}
							{
								fileSize
									? ` (${formatBytes(fileSize)})`
									: null
							}
							<div
								styleName="styles.file-selected-remove-icon"
								onClick={onFileRemovedHandler}
							>
								<Icon src={XSVG} />
							</div>
						</div>
					</div>
				</div>
				<input
					ref={fileInputRef}
					styleName="styles.hidden"
					type="file"
					onChange={onFileAddedHandler}
				/>
			</div>
		</div>
	);
};

FileUploadDropZone.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	validationError: PropTypes.string,
	onFileAdded: PropTypes.func.isRequired,
	onFileRemoved: PropTypes.func.isRequired,
};

FileUploadDropZone.defautProps = {
	onFileAdded: () => {},
	onFileRemoved: () => {},
}

export default FileUploadDropZone;
