import React from "react";
import { CommentsSortTypes } from "@frontend/types/Post/postUI";
// TODO: make sure all of these values have been switched in the code to use this context
export const PostUIViewingDefaultValues = {
    commentsClicked: false,
    commentsSort: CommentsSortTypes.NEWEST,
    contentHeight: 0,
    expanded: false,
    expandedContentFetched: false,
    hasBeenExpanded: false,
    isSingleCard: false,
    justPublished: false,
    showIntroduction: true,
    showOriginalContent: false,
    titleHeight: 0,
    topSectionHeight: 0,
    mainSectionHeight: 0,
};
const PostUIViewingContext = React.createContext(PostUIViewingDefaultValues);
export default PostUIViewingContext;
