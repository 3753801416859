import { Alert, RadioGroup, Radio, TabButton, TabList, Tabs } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import React, { useState } from "react";
import { TextField, GlobalValidationMessage } from "@components/FormFields/FormFields";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalFooter } from "../ModalComponents";
import * as Styled from "./InviteColleaguesModal.styles";

const WarningMessage = ({ message }) => {
	if (!message) {
		return null;
	}

	return (
		<Alert type="warning">
			<TextStyled $kind="heading02">
				{getTranslation("frontend.modals.inviteColleagues.referralLimitReached")}
			</TextStyled>
			<Styled.WarningText dangerouslySetInnerHTML={{ __html: message }} />
		</Alert>
	);
};

WarningMessage.propTypes = {
	message: PropTypes.string,
};

const TabIndexValuesByType = Object.freeze({
	EMAIL: 1,
	LINK: 2,
});

const setTabForInviteType = (inviteType) => {
	const lowerCaseInviteType = inviteType.toLowerCase();
	switch (lowerCaseInviteType) {
		case "invitecolleagueslink":
			return TabIndexValuesByType.LINK;
		case "invitecolleaguesemail":
		case "invitecolleagues":
		default:
			return TabIndexValuesByType.EMAIL;
	}
};

const InviteColleaguesModal = ({ closeModalEventHandler, inviteType }) => {
	const apiEndpoint = useApiEndpoint();
	const trackEvent = useTrackEvent();
	const [loading, setLoading] = useState(false);
	const [didTryToSubmit, setDidTryToSubmit] = useState(false);
	const [invitations, setInvitations] = useState("");
	const [invitationsValidationError, setInvitationsValidationError] = useState("");
	const [personalMessageSelected, setPersonalMessageSelected] = useState(false);
	const [personalMessage, setPersonalMessage] = useState("");
	const [personalMessageValidationError, setPersonalMessageValidationError] = useState("");
	const [globalValidationError, setGlobalValidationError] = useState("");
	const [warningMessage, setWarningMessage] = useState("");
	const [requestSuccessfullySubmitted, setRequestSuccessfullySubmitted] = useState(false);
	const [tabIndex, setTabIndex] = useState(setTabForInviteType(inviteType));
	const [hasClickedCopyLink, setHasClickedCopyLink] = useState(false);
	const { referralLink } = useMemoizedContext("member", ["referralLink"]);
	const abridgedLinkMaxLength = 27;
	const abridgedLinkText = referralLink.length > abridgedLinkMaxLength
		? referralLink.substring(0, abridgedLinkMaxLength) + "..."
		: referralLink;

	React.useEffect(() => {
		if (
			hasClickedCopyLink
			&& tabIndex !== TabIndexValuesByType.LINK
		) {
			setHasClickedCopyLink(false);
		}
	}, [
		hasClickedCopyLink,
		tabIndex,
	]);

	const invitationsChangeHandler = (event) => {
		setInvitations(event.target.value);
	};

	const personalMessageChangeHandler = (event) => {
		setPersonalMessage(event.target.value);
	};

	const validateForm = () => {
		let isValid = true;

		// eslint-disable-next-line max-len
		var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		setGlobalValidationError("");

		if (invitations !== "") {
			setInvitationsValidationError("");

			let emails = invitations.split(",");

			for (let i = 0; i < emails.length; i++) {
				if (!emailRegEx.test(emails[i].trim().toLowerCase())) {
					isValid = false;
					setInvitationsValidationError(
						emails[i]
							+ ":"
							+ getTranslation("system.validationMessages.validationInvalidEmailAddress", true)
					);
					break;
				}
			}
		} else {
			isValid = false;
			setInvitationsValidationError(
				getTranslation("system.validationMessages.validationPleaseProvideAtLeastOneEmail", true)
			);
		}

		if (personalMessageSelected) {
			if (personalMessage !== "") {
				setPersonalMessageValidationError("");
			} else {
				isValid = false;
				setPersonalMessageValidationError(
					getTranslation("system.validationMessages.validationPleaseProvideAMessageInvite", true)
				);
			}
		} else {
			setPersonalMessageValidationError("");
		}

		return isValid;
	};

	const blurHandler = () => {
		if (didTryToSubmit) {
			validateForm();
		}
	};

	const formSubmitClickHandler = () => {
		setDidTryToSubmit(true);

		if (validateForm()) {
			setLoading(true);

			const data = {
				emails: invitations,
				personalMessage: personalMessageSelected
					? personalMessage
					: null,
			};

			apiEndpoint("members/invitecolleagues", data).then((dataResponse) => {
				dataResponse.json().then((body) => {
					setLoading(false);

					const bodyObject = body[0];
					if (bodyObject?.message) {
						setWarningMessage(bodyObject.message);
					}

					if (dataResponse.ok) {
						setRequestSuccessfullySubmitted(true);
					} else {
						if (!bodyObject?.message) {
							setGlobalValidationError(body.message);
						}

						if (body.fields !== null) {
							for (var property in body.fields) {
								switch (property) {
									case "emails":
										setInvitationsValidationError(body.fields[property].join("<br />"));
										break;
									case "personalMessage":
										setPersonalMessageValidationError(body.fields[property].join("<br />"));
										break;
								}
							}
						}
					}
				});
			});
		}
	};

	const messageTypeChangeHandler = (e) => {
		setPersonalMessageSelected(e.target.value === "custom");
	};

	const messageOptions = [
		{
			name: getTranslation("frontend.modals.inviteColleagues.defaultCallToActionMessage", true),
			value: "auto",
		},
		{
			name: getTranslation("frontend.modals.inviteColleagues.personalizedCallToActionMessage", true),
			value: "custom",
		},
	];

	const tabChangeHandler = (event, index) => {
		setTabIndex(index);
	};

	const copyLinkClickHandler = () => {
		trackEvent({
			category: "invite-colleagues-modal",
			label: "copy-referral-link-button",
			action: "click",
		});
		navigator.clipboard.writeText(referralLink);
		setHasClickedCopyLink(true);
	};

	return (
		<ModalContainer dataComponent={"ReferModal"}>
			{
				requestSuccessfullySubmitted && (
					<>
						<ModalText>
							{
								invitations.split(",").length > 1
									? getTranslation("frontend.modals.inviteColleagues.invitationsSentConfirmationMessage")
									: getTranslation("frontend.modals.inviteColleagues.invitationSentConfirmationMessage")
							}
						</ModalText>
						{
							warningMessage && (
								<ModalSection>
									<WarningMessage message={warningMessage} />
								</ModalSection>
							)
						}
						<ModalFooter
							{...{
								submitEventHandler: closeModalEventHandler,
								submitText: getTranslation("frontend.generics.OK", true),
							}}
						/>
					</>
				)
			}

			{
				!requestSuccessfullySubmitted && (
					<>
						<ModalText>{getTranslation("frontend.modals.inviteColleagues.description")}</ModalText>
						<ModalSection>
							<ModalText>
								{getTranslation("frontend.modals.inviteColleagues.note")}
							</ModalText>
						</ModalSection>
						<Tabs
							divider
							full
							onChange={tabChangeHandler}
							value={tabIndex}
						>
							<TabList>
								<TabButton value={1}>
									{getTranslation("frontend.modals.inviteColleagues.emailTab", true)}
								</TabButton>
								<TabButton value={2}>
									{getTranslation("frontend.modals.inviteColleagues.linkTab", true)}
								</TabButton>
							</TabList>
						</Tabs>
						{
							tabIndex === TabIndexValuesByType.EMAIL && (
								<>
									<ModalSection>
										<TextField
											name="invitations"
											type="text"
											placeholder={getTranslation("frontend.modals.inviteColleagues.inputFieldPlaceholderEmails", true)}
											autoComplete="invitations"
											validationError={invitationsValidationError}
											handleChange={invitationsChangeHandler}
											value={invitations}
											handleBlur={blurHandler}
											disabled={loading}
										/>
									</ModalSection>

									<ModalSection newSection>
										<RadioGroup
											defaultValue={messageOptions[0].value}
											name="messageType"
											disabled={loading}
											onChange={messageTypeChangeHandler}
										>
											{
												messageOptions.map(message => (
													<Radio
														key={message.value}
														label={message.name}
														value={message.value}
													/>
												))
											}
										</RadioGroup>
									</ModalSection>

									{
										personalMessageSelected && (
											<ModalSection>
												<TextField
													name="personalMessage"
													type="text"
													placeholder={getTranslation("frontend.modals.inviteColleagues.inputFieldPlaceholderMessage", true)}
													autoComplete="custom-message"
													validationError={personalMessageValidationError}
													handleChange={personalMessageChangeHandler}
													value={personalMessage}
													handleBlur={blurHandler}
													disabled={loading}
												/>
											</ModalSection>
										)
									}

									{
										warningMessage && (
											<ModalSection>
												<WarningMessage message={warningMessage} />
											</ModalSection>
										)
									}

									<GlobalValidationMessage text={globalValidationError} />

									<ModalFooter
										{...{
											cancelModalEventHandler: closeModalEventHandler,
											submitEventHandler: formSubmitClickHandler,
											submitDisabled: !!warningMessage,
											submitLoading: loading,
											submitText: getTranslation("frontend.modals.inviteColleagues.sendInviteButtonText"),
										}}
									/>
								</>
							)
						}
						{
							tabIndex === TabIndexValuesByType.LINK && (
								<>
									<ModalSection>
										<ModalText>
											{getTranslation("frontend.modals.inviteColleagues.linkLabel", true)}
										</ModalText>
										<Styled.CopyLinkWrap>
											<Styled.CopyLinkText>
												{abridgedLinkText}
											</Styled.CopyLinkText>
											<Styled.CopyLinkButton onClick={copyLinkClickHandler}>
												{
													hasClickedCopyLink
														? getTranslation("frontend.generics.copied", true, "!")
														: getTranslation("frontend.generics.copy", true)
												}
											</Styled.CopyLinkButton>
										</Styled.CopyLinkWrap>
									</ModalSection>

									<ModalSection>
										<ModalText>
											{getTranslation("frontend.modals.inviteColleagues.qrCodeLabel", true)}
										</ModalText>
									</ModalSection>
									<ModalSection>
										<Styled.QRCodeWrap>
											<Styled.SvgWrap>
												<QRCodeSVG value={referralLink} />
											</Styled.SvgWrap>
										</Styled.QRCodeWrap>
									</ModalSection>
								</>
							)
						}
					</>
				)
			}
		</ModalContainer>
	);
};

InviteColleaguesModal.propTypes = {
	closeModalEventHandler: PropTypes.func,
	inviteType: PropTypes.string,
};

InviteColleaguesModal.defaultProps = {
	closeModalEventHandler: () => {},
	inviteType: "",
};

export default InviteColleaguesModal;
