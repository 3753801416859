/**
 * Types of notification subscriptions.
 * @type {Readonly<{}>}
 */
export const NotificationSubscriptionType = Object.freeze({
	/**
	 * Flag set when a member is globally opted out.
	 */
	GloballyOptOut: "GloballyOptOut",

	/**
	 * When a member is eligible for a SERMO Challenge. If we are giving $10 away to create a post,
	 * this will be shared across the platform (basically what we call a challenge).
	 */
	EligibleForChallenge: "EligibleForChallenge",

	/**
	 * When a member receive a new inbox message.
	 */
	NewInboxMessage: "NewInboxMessage",

	/**
	 * Activity on posts that the member authored.
	 */
	ActivityOnPostsAuthored: "ActivityOnPostsAuthored",

	/**
	 * Activity on posts that the member is following.
	 */
	ActivityOnPostsFollowed: "ActivityOnPostsFollowed",

	/**
	 * MR and RealTime survey invitations. Send email when new survey is available.
	 */
	NewSurveyAvailable: "NewSurveyAvailable",

	/**
	 * Features a combination of top community discussions, resources, and cases.
	 */
	CommunityDigest: "CommunityDigest",

	/**
	 * Receive product updates, event and community content, and promotion alerts.
	 */
	EventsAndPromotional: "EventsAndPromotional",

	/**
	 * Legacy only: Notification when a patient case the member authored received a comment.
	 */
	PatientCaseReceivesComment: "PatientCaseReceivesComment",

	/**
	 * Notification when a patient case is created in the same specialty as the member.
	 */
	PatientCaseCreatedInSameSpecialty: "PatientCaseCreatedInSameSpecialty",

	/**
	 * Legacy only: Notification when an unresolved patient case should be updated.
	 */
	ReminderToUpdateUnresolvedPatientCase: "ReminderToUpdateUnresolvedPatientCase",

	/**
	 * Receive push notification in the mobile apps.
	 */
	PushNotifications: "PushNotifications",

	/**
	 * Offer of the day reminder.
	 */
	MdHonorsDailyOffers: "MdHonorsDailyOffers",

	/**
	 * Weekly preview of the upcoming offers.
	 */
	MdHonorsWeeklyPreviewOfUpcomingOffers: "MdHonorsWeeklyPreviewOfUpcomingOffers",

	/**
	 * Whether the member want to receive emails about prize drawings and other periodic promotions.
	 */
	MdHonorsOtherPromotions: "MdHonorsOtherPromotions",

	/**
	 * Receive product updates.
	 */
	NewsFeaturedContentMemberBenefits: "NewsFeaturedContentMemberBenefits",

	/**
	 * Receive event and community content.
	 */
	Events: "Events",

	/**
	 * Receive promotion alerts.
	 */
	Promotions: "Promotions",
});

/**
 * Lists every email source type notification:
 * - News, Featured Content, & Exclusive Member Benefits
 * - Events
 * - Community Digests
 * - Promotions
 * - Surveys
 * - Messages
 * - Posts you've created
 * - Posts you're following
 * - New patient cases in my specialty
 * - All
 * @type {Readonly<{}>}
 */
export const Source = Object.freeze({
	GloballyOptOut: 1,
	NewsFeaturedContentMemberBenefits: 2, // Has Snooze
	Events: 3,
	CommunityDigest: 4, // Has Snooze
	Promotions: 5,
	NewSurveyAvailable: 6,
	NewInboxMessage: 7,
	ActivityOnPostsAuthored: 8,
	ActivityOnPostsFollowed: 9,
	PatientCaseCreatedInSameSpecialty : 10,
});

/**
 * Assigns the text for every email source type notification modal.
 * @type {Readonly<{}>}
 */
export const SourceTexts = Object.freeze({
	[NotificationSubscriptionType.NewsFeaturedContentMemberBenefits]: {
		title: "frontend.unsubscribe.modalTitleNews",
		description: "frontend.unsubscribe.modalDescriptionNews",
	},
	[NotificationSubscriptionType.Events]: {
		title: "frontend.unsubscribe.modalTitleEvents",
		description: "frontend.unsubscribe.modalDescriptionEvents",
	},
	[NotificationSubscriptionType.CommunityDigest]: {
		title: "frontend.unsubscribe.modalTitleDigests",
		description: "frontend.unsubscribe.modalDescriptionDigests",
	},
	[NotificationSubscriptionType.Promotions]: {
		title: "frontend.unsubscribe.modalTitlePromotions",
		description: "frontend.unsubscribe.modalDescriptionPromotions",
	},
	[NotificationSubscriptionType.NewSurveyAvailable]: {
		title: "frontend.unsubscribe.modalTitleSurveys",
		description: "frontend.unsubscribe.modalDescriptionSurveys",
	},
	[NotificationSubscriptionType.NewInboxMessage]: {
		title: "frontend.unsubscribe.modalTitleMessages",
		description: "frontend.unsubscribe.modalDescriptionMessages",
	},
	[NotificationSubscriptionType.ActivityOnPostsAuthored]: {
		title: "frontend.unsubscribe.modalTitlePostCreated",
		description: "frontend.unsubscribe.modalDescriptionPostCreated",
	},
	[NotificationSubscriptionType.ActivityOnPostsFollowed]: {
		title: "frontend.unsubscribe.modalTitlePostFollowed",
		description: "frontend.unsubscribe.modalDescriptionPostFollowed",
	},
	[NotificationSubscriptionType.PatientCaseCreatedInSameSpecialty]: {
		title: "frontend.unsubscribe.modalTitleCases",
		description: "frontend.unsubscribe.modalDescriptionCases",
	},
	[NotificationSubscriptionType.GloballyOptOut]: {
		title: "frontend.unsubscribe.modalTitleAll",
		description: "frontend.unsubscribe.modalDescriptionAll",
	},
});

export const SubscriptionTypeMembershipMapping = Object.freeze({
	[Source.GloballyOptOut]: NotificationSubscriptionType.GloballyOptOut,
	[Source.NewsFeaturedContentMemberBenefits]: NotificationSubscriptionType.NewsFeaturedContentMemberBenefits,
	[Source.Events]: NotificationSubscriptionType.Events,
	[Source.CommunityDigest]: NotificationSubscriptionType.CommunityDigest,
	[Source.Promotions]: NotificationSubscriptionType.Promotions,
	[Source.NewSurveyAvailable]: NotificationSubscriptionType.NewSurveyAvailable,
	[Source.NewInboxMessage]: NotificationSubscriptionType.NewInboxMessage,
	[Source.ActivityOnPostsAuthored]: NotificationSubscriptionType.ActivityOnPostsAuthored,
	[Source.ActivityOnPostsFollowed]: NotificationSubscriptionType.ActivityOnPostsFollowed,
	[Source.PatientCaseCreatedInSameSpecialty]: NotificationSubscriptionType.PatientCaseCreatedInSameSpecialty,
});

export const SubscriptionTypeMNOWMapping = Object.freeze({
	[Source.GloballyOptOut]: NotificationSubscriptionType.GloballyOptOut,
	[Source.NewsFeaturedContentMemberBenefits]: NotificationSubscriptionType.NewsFeaturedContentMemberBenefits,
	[Source.Events]: NotificationSubscriptionType.Events,
	[Source.Promotions]: NotificationSubscriptionType.Promotions,
	[Source.NewSurveyAvailable]: NotificationSubscriptionType.NewSurveyAvailable,
});

