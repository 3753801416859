import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { TextField, TermsAndConditions, ValidationMessage } from "@components/FormFields/FormFields";
import { useCloseModal } from "@contexts/UI";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import { ModalContainer, ModalSection, ModalText, ModalTitle, ModalFooter } from "../ModalComponents";
import styles from "./SetPasswordAndAcceptTermsModal.scss"

const SetPasswordAndAcceptTermsModal = () => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const [password, setPassword] = useState("");
	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [passwordValidationError, setPasswordValidationError] = useState(false);
	const [acceptedTermsAndConditionsValidationError, setAceptedTermsAndConditionsValidationError] = useState("");
	const [loading, setLoading] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false); // todo: use this ???
	const { updateMember } = useMemoizedContext("member");

	const passwordChangeHandler = event => {
		setPassword(event.target.value);
	};

	const onAcceptTermsAndConditionsChange = (e) => {
		setAcceptedTermsAndConditions(e.target.checked);
	}

	useEffect(() => {
		setCanSubmit(password.length > 0);
		setPasswordValidationError(false);
	}, [password])

	const submitPasswordEventHandler = (e) => {
		setLoading(true);

		let timeZoneId = null;
		try
		{
			timeZoneId = new Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
		catch (ex) {
			console.error(ex);
		}

		const requestData = {
			newPassword: password,
			confirmNewPassword: password,
			timeZoneOffset: -new Date().getTimezoneOffset(),
			acceptTermsAndConditions: acceptedTermsAndConditions,
			timeZoneId: timeZoneId,
		};

		apiEndpoint("settings/setpassword", requestData).then(dataResponse => {
			dataResponse.json().then(body => {
				if (dataResponse.ok) {
					setLoading(false);
					setShowConfirmationModal(true);
				}
				else {
					setLoading(false);

					if (body.message) {
						setPasswordValidationError(body.message);
					}

					if (body.fields !== null) {
						for (var property in body.fields) {
							switch (property) {
								case "newPassword":
									setPasswordValidationError(body.fields[property].join("<br />"));
									break;
								case "confirmNewPassword":
									setPasswordValidationError(body.fields[property].join("<br />"));
									break;
								case "acceptTermsAndConditions":
									setAceptedTermsAndConditionsValidationError(
										body.fields[property].join("<br />")
									);
									break;
							}
						}
					}
				}
			});
		});
	};

	const closeModalEventHandler = () => {
		updateMember({
			hasSetPassword: true,
			hasAcceptedTermsAndConditions: true,
		});
		closeModal("setPasswordAndAcceptTermsModal");
	};

	if (showConfirmationModal) {
		return (
			<ModalContainer>
				<ModalTitle>
					{getTranslation("frontend.modals.setPasswordAndAcceptTermsModal.completed.title")}
				</ModalTitle>
				<ModalSection>
					<ModalText>
						{getTranslation("frontend.modals.setPasswordAndAcceptTermsModal.completed.subtitle")}
					</ModalText>
				</ModalSection>
				<ModalFooter
					submitEventHandler={closeModalEventHandler}
					submitText={getTranslation("frontend.modals.setPasswordAndAcceptTermsModal.completed.cta", true)}
				/>
			</ModalContainer>
		);
	}

	return (
		<ModalContainer>
			<ModalTitle>
				{getTranslation("frontend.modals.setPasswordAndAcceptTermsModal.setPassword.titleManuallyVerified")}
			</ModalTitle>
			<ModalSection>
				<ModalText>
					{
						getTranslation(
							"frontend.modals.setPasswordAndAcceptTermsModal.setPassword.subtitleManuallyVerified"
						)
					}
				</ModalText>
				<div styleName={classNames(["styles.password-wrapper"])}>
					<TextField
						name="password"
						type="password"
						autoComplete="new-password"
						label={
							getTranslation(
								"frontend.modals.setPasswordAndAcceptTermsModal.setPassword.inputLabel", true
							)
						}
						validationError={passwordValidationError}
						handleChange={passwordChangeHandler}
						value={password}
						disabled={loading}
						autoFocus={true}
					/>
				</div>
				<div styleName={classNames(["styles.terms-and-conditions-wrapper"])}>
					<TermsAndConditions
						name="acceptTermsAndConditions"
						text={
							getTranslation(
								"frontend.login.resetPasswordPage.acceptTermsAndConditions"
							)
						}
						handleChange={onAcceptTermsAndConditionsChange}
						checked={acceptedTermsAndConditions}
					/>
					<div styleName={classNames(["styles.terms-and-conditions-error-wrapper"])}>
						<ValidationMessage text={acceptedTermsAndConditionsValidationError} />
					</div>
				</div>
			</ModalSection>
			<ModalFooter
				{...{
					submitEventHandler: submitPasswordEventHandler,
					submitText: getTranslation("frontend.modals.setPasswordAndAcceptTermsModal.setPassword.cta", true),
				}}
			/>
		</ModalContainer>
	);
}

export default SetPasswordAndAcceptTermsModal;
