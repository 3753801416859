import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";

export const TopSection = styled.div`
position: relative;
`;

export const MainSection = styled.div`
position: relative;
`;

export const Infeed = styled.div`
    margin-bottom: var(--cards-spacing);

    ${StyledMobileAndTabletPortraitQuery} {
        margin-bottom: 4px;
    }
`;

export const Footer = styled.div.attrs(props => props)`
	padding: 16px;
    padding-top: ${props => props.paddingTop
		? "12px"
		: "8px"};

	${StyledMobileAndTabletPortraitQuery} {
		padding: 16px 24px;
        padding-top: ${props => props.paddingTop
		? "24px"
		: "16px"};
	}
`;

export const Headline = styled(TextStyled)`
    color: ${({ theme }) => theme.color.text01};

    .merck & {
        font-weight: 400;
    }
`;

export const Title = styled(TextStyled)`
	color: ${({ theme }) => theme.color.text02};
	padding: 0 16px 8px;
`

export const FooterContentContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	${StyledMobileAndTabletPortraitQuery} {
		flex-direction: column;
	}

	p {
		margin: 0;
	}
`;

export const TimeWrapper = styled.div`
	margin-bottom: 12px;
`;

export const HeadlineDescriptionContainer = styled.div`
	min-height: 24px;

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
	}
`;

export const ButtonContainer = styled.div`
	margin-left: 20px;
	align-self: center;

	${StyledMobileAndTabletPortraitQuery} {
		margin-left: 0;
		margin-top: 20px;
		width: 100%;

		&>div {
			width: 100%;
		}
	}
`;

