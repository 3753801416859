import { ColorGrey00, ColorGrey20 } from "@sermo/design-tokens";
import { Button, DuplicateSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import styled from "styled-components";
import { applyTextColor } from "@styled";

export const QRCodeWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const SvgWrap = styled.div`
	padding: 16px;
	background-color: ${ColorGrey00};

	& > svg {
		width: 150px;
		height: 150px;
	}
`;

export const CopyLinkWrap = styled.div`
	width: fit-content;
	margin-top: 16px;
	border: 1px solid ${ColorGrey20};
	border-radius: 8px;
	display: flex;
	overflow: hidden;
`;

export const CopyLinkText = styled(TextStyled).attrs({ $kind: "bodyShort02" })`
	padding: 14px 24px 14px 16px;
	font-size: 14px;
	line-height: 20px;
	${applyTextColor("text-02")};
	background-color: ${ColorGrey00};
`;

export const CopyLinkButton = styled(Button).attrs({
	kind: "secondary",
	size: "large",
	icon: DuplicateSVG,
})`
	min-width: fit-content;
	padding: 12px 16px 12px 0;
	border-radius: 0;
	box-shadow: none;

	& > svg {
        margin: 0 8px 0 12px;
    }
`;

export const WarningText= styled(TextStyled).attrs({ $kind: "bodyShort01" })`
	${applyTextColor("text-02")};
	margin-top: 2px;

	a {
		${applyTextColor("text-02")};
		text-decoration: underline;
	}
`;