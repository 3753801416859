import { CheckCircleSVG } from "@sermo/ui-components";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { SizesPropType, StatusPropType } from "@components/LiveEvents/components/EventsContext/EventPropTypes";
import { EVENT_LIVE, EVENT_ON_DEMAND, getTiming } from "@components/LiveEvents/LiveEvents.utils";
import getTranslation from "@translation/translation";
import { StyledAttendees, StyledCheck } from "./styled";

const Attendees = ({ attendees, registered = true, size = "small", from, to }) => {
	/**
	 * Minimun amount the amount of attendees to show the attendees component.
	 * @type {number}
	 */
	const MIN_ATTENDEES = 5;
	const DEFAULT_AVATAR_SIZE = 20;
	const font = {
		small: "body-short-01",
		medium: "body-short-02",
		large: "body-short-02",
	}

	const getShowAttendees = () => attendees >= MIN_ATTENDEES;

	const [showAttendees, setShowAttendees] = useState(getShowAttendees());

	useEffect(() => {
		setShowAttendees(getShowAttendees());
	}, [attendees])

	const timing = getTiming(from, to);

	if ([EVENT_LIVE, EVENT_ON_DEMAND].includes(timing)) {
		return null;
	}

	if (!registered && !showAttendees) {
		return null
	}

	return (
		<StyledAttendees
			className={font[size]}
			$kind={"bodyShort00"}
		>
			{
				registered && (
					<>
						<StyledCheck
							src={CheckCircleSVG}
							height={DEFAULT_AVATAR_SIZE}
							width={DEFAULT_AVATAR_SIZE}
						/>
						{showAttendees && getTranslation("frontend.events.youAndMore", true, attendees - 1)}
						{!showAttendees && getTranslation("frontend.events.justYou", true)}
					</>
				)
			}
			{!registered && showAttendees && getTranslation("frontend.events.attending", true, attendees)}
		</StyledAttendees>
	)
}

Attendees.propTypes = {
	from: PropTypes.string,
	to: PropTypes.string,
	size: SizesPropType,
	status: StatusPropType,
	attendees: PropTypes.number,
	registered: PropTypes.bool,
}

export default Attendees;
