import { Icon, MinusSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable"
import PropTypes from "prop-types";
import React from "react";
import { CommentsStylesContainer } from "@components/Card/components/Comments/Comments.styles";

const Hidden = ({ text, onClick, forwardRef }) => (
	<CommentsStylesContainer
		className="blocked"
		onClick={onClick}
		ref={forwardRef}
	>
		<div className="blocked-icon">
			<Icon src={MinusSVG} />
		</div>
		<TextStyled
			as="span"
			$kind="bodyShort00"
		>
			{text}
		</TextStyled>
	</CommentsStylesContainer>
);

Hidden.propTypes = {
	text: PropTypes.oneOfType([ PropTypes.object, PropTypes.string]).isRequired,
	onClick: PropTypes.func.isRequired,
	forwardRef: PropTypes.object,
};

export default Hidden;
