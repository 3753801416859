import React, { useReducer } from "react";

const SEARCH_UPDATE = "SEARCH_UPDATE";
const COUNT_UPDATE = "COUNT_UPDATE";

const initialValues = {
	query: "",
	type: undefined, // Post, Member, DrugRating
	page: 0,
	postOrder: "MostRelevant", // top, newest, oldest
	postType: [], // Question, Discusson, PatientCase, Comment
	dateRange: "Any", // Any, Year, Month, Week, Day
	connection: [], // MySpecialties, PeopleIFollow
	location: "EveryWhere", // EveryWhere, MyCountry, US, NotUS
	ratingType: undefined, // DrugBrand, Indication
	specialtyIds: [],
	totalCount: 0,
	searchUpdate: () => { },
	countUpdate: () => { },
};

const SearchContext = React.createContext(initialValues);

export default SearchContext;

const defaults = {
	Post: {
		sort: "MostRelevant",
		postType: [],
		dateRange: "Any",
		connection: [],
		location: "EveryWhere",
	},
	Member: {
		connection: [],
		location: "EveryWhere",
		specialtyIds: [],
	},
	DrugRating: {
		ratingType: "DrugBrand",
	},
};

const searchReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_UPDATE: {
			return {
				...state,
				...action.payload,
				page: 0,
			};
		}
		case COUNT_UPDATE: {
			return {
				...state,
				page: 0,
				totalCount: action.payload,
			}
		}
		default:
			return state;
	}
};

const SearchContextProvider = ({ children }) => {
	// not using section but maybe should?
	const [searchState, searchDispatch] = useReducer(searchReducer, {
		...initialValues,
		...defaults.Post,
	});

	const searchUpdate = values => {
		searchDispatch({
			type: SEARCH_UPDATE,
			payload: values,
		});
	};

	const countUpdate = value => {
		searchDispatch({
			type: COUNT_UPDATE,
			payload: value,
		});
	}

	return (
		<SearchContext.Provider
			value={
				{
					query: searchState.query,
					type: searchState.type,
					page: searchState.page,
					postOrder: searchState.postOrder,
					postType: searchState.postType,
					dateRange: searchState.dateRange,
					connection: searchState.connection,
					location: searchState.location,
					ratingType: searchState.ratingType,
					specialtyIds: searchState.specialtyIds,
					totalCount: searchState.totalCount,
					searchUpdate: searchUpdate,
					countUpdate: countUpdate,
				}
			}
		>
			{children}
		</SearchContext.Provider>
	);
};

export { SearchContextProvider };
