import styled from "styled-components";
import { StyledMobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
export const MessagesList = styled.div `
	width: var(--messages-list-width);
	height: ${({ $subtractFromHeight }) => `calc(100dvh - ${$subtractFromHeight}px);`};
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;

	& > div {
		padding-right: var(--cards-padding-mobile);
	}

	${StyledMobileAndTabletPortraitQuery} {
		width: 100%;
		margin: 0;
	}
`;
export const List = styled.div `
	height: ${({ $subtractFromHeight }) => `calc(100dvh - ${$subtractFromHeight}px - 60px);`};
	overflow-y: auto;

	.modal & {
		margin: 24px -24px 0 -24px;

		> div {
			max-height: 50vh;
		}
	}

	${StyledMobileAndTabletPortraitQuery} {
			display: flex;
			flex-direction: column;

		.modal & {
			margin: 0 -16px;

			> div {
				max-height: none;
				overflow: auto;
			}
		}
	}
`;
