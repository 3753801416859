import styled, { css } from "styled-components";
export const ContentContainer = styled.div `
	position: relative;
`;
export const legacyStyles = css `
	font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;
export const postContentStyles = css `
	color: ${({ theme }) => theme.color.text01};
	margin-bottom: ${({ theme }) => theme.layout.cardsSpacing};
	overflow: visible;
	word-break: break-word;

	// This does not work on chrome for windows only mac and android
	-ms-hyphens: manual;
	-moz-hyphens: manual;
	-webkit-hyphens: manual;
	hyphens: manual;

	p {
		overflow: hidden;
	}

	em,
	i {
		font-style: italic;
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			color: ${({ theme }) => theme.color.defaultLinkHover};
		}
	}

	sub,
	sup {
		position: relative;
		vertical-align: baseline;
		line-height: 0;
	}

	sup {
		top: -0.5em;
	}

	sub {
		top: 0.4em;
	}

	img {
		max-width: 100%;
	}

	&& strong {
		font-weight: 600;
	}

	${({ $legacy }) => $legacy && legacyStyles};

	${({ $zeroMarginBottom }) => $zeroMarginBottom && css `
		margin-bottom: 0;
	`};
`;
export const expandedStyles = css `
	sup {
		top: -0.5em;
	}
`;
export const marginBottomAdStyles = css `
	p,
	ul {
		margin-bottom: 1em;
	}
`;
export const marginBottomNonAdStyles = css `
	p,
	ul {
		margin-bottom: var(--cards-spacing);
	}
`;
export const editorSyncStyles = css `
	& > * {
		min-height: 16px;
		display: block;
	}
`;
