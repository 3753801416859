import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { TextField } from "@components/FormFields/FormFields";
import { useFetch } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import { MemberButton } from "./MemberButton";
import styles from "./MessagesList.scss";

export const NewMessageModal = ({ addMessage }) => {
	const [query, setQuery] = useState("");
	const searchInputRef = useRef();

	useEffect(() => {
		searchInputRef.current?.focus();
	}, []);
	const changeHandler = (e) => {
		setQuery(e.target.value);
	};

	const [data, loading, error] = useFetch("api/messages/searchrecipients", { search: query });

	const clickHandler = useCallback(
		(id) => {
			addMessage(id);
		},
		[addMessage]
	);
	return (
		<div styleName={classNames("styles.modal")}>
			<div styleName={classNames("styles.header")}>
				<TextField
					forwardRef={searchInputRef}
					name="user-search"
					value={query}
					placeholder={getTranslation("frontend.messages.typeAName", true)}
					contextClass="new-message-modal"
					handleChange={changeHandler}
				/>
			</div>
			<div styleName={classNames("styles.list")}>
				<div>
					<div styleName={classNames("styles.recent", "typography.overline-brand-02")}>
						{getTranslation("frontend.generics.recent")}
					</div>
					<div styleName={classNames("styles.recipient-list")}>
						{
							!loading
						&& !error
						&& data
						&& data.recipients
						&& data.recipients.map((member, i) => <MemberButton
							{...member}
							clickHandler={() => clickHandler(member.id)}
							key={i}
						/>)
						}
					</div>
				</div>
			</div>
		</div>
	);
};

NewMessageModal.propTypes = {
	addMessage: PropTypes.func,
};

NewMessageModal.defaultProps = {
	addMessage: () => {},
};
