import { ColorGrey30 } from "@sermo/design-tokens";
import {
	Checkbox,
	ChevronLeftSVG,
	ChevronRightSVG,
	Icon,
	Radio,
} from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import Button from "@components/Button/Button";
import ParentContextUpdater from "@components/ParentContextUpdater";
import usePollAPI from "@components/PostEditor/components/PollEdit/usePollAPI";
import { PollActionTypes } from "@contexts/Poll";
import { PollQuestionContext, PollQuestionProvider } from "@contexts/PollQuestion";
import { fetchUrl } from "@frontend/Utils";
import {
	useGetPostTrackingCategory,
	useMemoizedContext,
} from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import layout from "../../../../scss/layout.scss";
import typography from "../../../../scss/typography.scss";
import styles from "./Polls.scss";

const ClockIconStyled = styled(Icon)`
	margin-right: 8px;
	&& {
		path {
			fill: ${ColorGrey30};
		}
	}
`;

const QuestionShape = {
	question: PropTypes.string,
	id: PropTypes.number,
	isMultipleChoice: PropTypes.bool,
	length: PropTypes.oneOf(["Day", "Week", "Month"]),
	votes: PropTypes.arrayOf(PropTypes.number),
	answers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			answer: PropTypes.string,
			votes: PropTypes.number,
		})
	),
};

const PollResult = ({ answer, votes, total, id, memberVotes }) => {
	const percent = !votes
		? "0%"
		: `${Math.round((votes / total) * 100)}%`;
	const fraction = `${votes}/${total}`;
	const getMemberSelectedVote = () => memberVotes
		? memberVotes.includes(id)
		: false
	const [memberSelectedVote, setMemberSelectedVote] = useState(getMemberSelectedVote());

	useEffect(() => {
		setMemberSelectedVote(getMemberSelectedVote());
	}, [memberVotes, id]);

	return (
		<div styleName="styles.poll-answer-answered">
			<div styleName={classNames("styles.poll-percent", "typography.number-01")}>{percent}</div>
			<div styleName="styles.poll-bar-and-answer">
				<div styleName="styles.progress-bar">
					<div
						styleName="styles.progress-bar-fill"
						style={{ width: percent }}
					/>
				</div>
				<div
					styleName={
						classNames(
							"styles.poll-answer-text",
							{ "styles.first": memberSelectedVote },
							"typography.body-short-brand-medium-02"
						)
					}
				>
					{answer}
					<span styleName={classNames("styles.poll-vote-count", "typography.body-short-02")}>{fraction}</span>
				</div>
			</div>
		</div>
	);
};

PollResult.propTypes = {
	answer: PropTypes.string,
	votes: PropTypes.number,
	total: PropTypes.number,
	id: PropTypes.number,
	memberVotes: PropTypes.array,
};

PollResult.defaultProps = {
	votes: 0,
	total: 0,
};

const RadioWrap = styled.div`
	display: contents;

	span {
		margin: 0;
	}
`;

const PollAnswer = ({
	answer,
	edit,
	handleClick,
	id,
	index,
	name,
	pollId,
	type,
	voted,
}) => (
	<div
		styleName={classNames("styles.poll-answer", { "styles.selected": voted })}
		onClick={handleClick}
	>
		<div styleName={classNames("styles.poll-input", { "styles.poll-input-view-mode": !edit })}>
			{
				"checkbox" === type && (
					<Checkbox
						index={index}
						name={name}
						option={id}
						checked={voted}
						id={`poll-${pollId}-option-${id}`}
					/>
				)
			}
			{
				"radio" === type && (
					<RadioWrap>
						<Radio
							index={index}
							name={name}
							checked={voted}
							id={`poll-${pollId}-option-${id}`}
						/>
					</RadioWrap>
				)
			}
		</div>
		<label
			styleName={
				classNames("styles.poll-input-label", "typography.body-long-02", {
					"styles.poll-input-label-view-mode": !edit,
				})
			}
			htmlFor={`poll-${pollId}${id}`}
		>
			{answer}
		</label>
	</div>
);

PollAnswer.propTypes = {
	answer: PropTypes.string,
	edit: PropTypes.bool,
	handleClick: PropTypes.func,
	id: PropTypes.number,
	index: PropTypes.number,
	name: PropTypes.string,
	pollId: PropTypes.number,
	type: PropTypes.oneOf(["checkbox", "radio"]),
	voted: PropTypes.bool,
};

// TODO: use poll context
const Question = (
	{
		question,
		pollId,
		onVote,
	}
) => {
	const category = useGetPostTrackingCategory();
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isPreviewRoute = !!useMatch("/preview/*");
	const uid = Date.now();

	const {
		adId,
		clickTags,
		expanded,
		id: postId,
		poll: {
			expiration,
			open,
		},
		sermoContentCardId,
		user,
	} = useMemoizedContext("postData",[
		"adId",
		"adType",
		"cardType",
		"clickTags",
		"expanded",
		"id",
		"poll",
		"sermoContentCardId",
		"user",
	]);

	const {
		id: memberId,
	} = useMemoizedContext("member", [
		"id",
	]);

	const ownPost = useMemo(() => {
		if (
			isAdPreview 
			|| isPreviewRoute
		) {
			return false;
		}
		
		return user && user?.id === memberId;
	}, [user, user?.id, memberId, isAdPreview, isPreviewRoute]);

	const {
		question: questionText,
		answers,
		id,
		isMultipleChoice,
		votes: initialVote,
	} = question;

	// use local vote state so user sees auto update even if post actually fails
	const [vote, setVote] = useState(initialVote ?? 0);
	const [voted, setVoted] = useState(ownPost || vote?.length > 0);

	const [edit, setEdit] = useState(false);
	const [view, setView] = useState(false);

	const total = answers?.reduce((accumulator, currentValue) => accumulator + currentValue.votes, 0);

	// use to keep collapsed and expanded context state in sync
	useEffect(() => {
		setVoted(ownPost || initialVote?.length > 0);
		setVote(initialVote);
	},[initialVote])

	const handleClick = (val) => {
		const value = parseInt(val);

		if (
			isMultipleChoice
			&& vote
		) {
			const newVote = [...vote];
			if (newVote.includes(value)) {
				newVote.splice(newVote.indexOf(value), 1);
			} else {
				newVote.push(value);
			}
			setVote(newVote);
		} else {
			setVote([value]);
		}
	}

	const pollApi = usePollAPI();
	const submitVote = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (vote.length === 0) {
			alert("you must select at least 1 option");
		} else {
			if (clickTags?.pollAnswer) {
				clickTags.pollAnswer.forEach((url) => {
					fetchUrl(url);
				})
			}
			setVoted(true);
			setEdit(false);

			pollApi.vote({
				questionId: id,
				answers: vote,
			});

			onVote();
		}
	};

	const seeResultsToggle = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setView(!view);
	};
	const seeResults = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setEdit(false);
	};

	const getTrackingDataWithGivenLabel = (label) => {
		return {
			category,
			label,
			action: "click",
			area: "poll",
			postId,
			adId,
			expanded,
			sermoContentCardId,
		}
	};

	return (
		<>
			{
				questionText && (
					<div
						styleName={classNames("styles.poll-question", "typography.body-short-brand-medium-02")}
						dangerouslySetInnerHTML={{ __html: questionText }}
					/>
				)
			}
			<form>
				<div styleName={classNames("styles.question")}>
					{
						(!voted || edit) && (
							<div styleName={classNames("styles.poll-cta", "typography.body-short-brand-01")}>
								{isMultipleChoice && getTranslation("frontend.poll.multiCta", true)}
								{!isMultipleChoice && getTranslation("frontend.poll.singleCta", true)}
							</div>
						)
					}
					<div
						styleName="styles.answers"
						onClick={
							(e) => {
								e.stopPropagation();
							}
						}
					>
						{
							answers?.map((answer, index) => {
								if (!open || (voted && !edit) || (!voted && view)) {
									return <PollResult
										answer={answer.answer}
										id={answer.id}
										key={index}
										memberVotes={vote}
										votes={answer.votes}
										total={total}
									/>;
								} else {
									const votedFor = vote
										? vote.includes(answer.id)
										: false;
									const type = isMultipleChoice
										? "checkbox"
										: "radio";

									return (
										<PollAnswer
											answer={answer.answer}
											edit={edit}
											handleClick={() => handleClick(answer.id)}
											id={answer.id}
											index={index}
											key={index}
											name={`poll-${pollId}-${uid}`}
											pollId={id}
											type={type}
											voted={votedFor}
										/>
									);
								}
							})
						}
					</div>

					<div styleName={classNames("styles.poll-footer", "typography.body-short-brand-01")}>
						<div styleName="styles.poll-footer-buttons">
							{
								open
								&& !ownPost
								&& voted
								&& !edit
								&& (
									<Button
										clickHandler={
											(e) => {
												e.stopPropagation();
												setEdit(true);
											}
										}
										theme="light"
										style="flatNeutral"
										size="small"
										tracking={getTrackingDataWithGivenLabel("poll-change-vote")}
									>
										{getTranslation("frontend.poll.changeYourVote")}
									</Button>
								)
							}

							{
								open
								&& !voted
								&& (
									<Button
										clickHandler={seeResultsToggle}
										size="small"
										style="flatNeutral"
										tracking={getTrackingDataWithGivenLabel("poll-see-results")}
									>
										{!view && getTranslation("frontend.poll.seeResults")}
										{view && getTranslation("frontend.poll.addYourVote")}
									</Button>
								)
							}

							{
								open
								&& voted
								&& edit
								&& (
									<Button
										clickHandler={seeResults}
										size="small"
										style="flatNeutral"
										tracking={getTrackingDataWithGivenLabel("poll-see-results")}
									>
										{getTranslation("frontend.poll.seeResults")}
									</Button>
								)
							}

							{
								open
								&& !view
								&& (
									!voted
									|| edit
								)
								&& (
									<Button
										clickHandler={submitVote}
										size="small"
										rightsRequired={
											isAdPreview || isPreviewRoute
												? []
												: ["canVoteOnItemsInFrontend"]
										}
										tracking={getTrackingDataWithGivenLabel("poll-submit")}
									>
										{getTranslation("frontend.poll.submit")}
									</Button>
								)
							}
						</div>
						{
							open
							&& expiration
							&& <div>{expiration}</div>
						}
					</div>
				</div>
			</form>
		</>
	);
};

Question.propTypes = {
	question: PropTypes.shape(QuestionShape),
	pollId: PropTypes.number,
	onVote: PropTypes.func,
};

const PollNav = ({ questions, currentQuestion, clickHandler }) => {
	const category = useGetPostTrackingCategory();

	const {
		expanded,
	} = useMemoizedContext("postUIViewing",[
		"expanded",
	]);

	const {
		adId,
		id: postId,
		sermoContentCardId,
	} = useMemoizedContext("postData",[
		"adId",
		"id",
		"sermoContentCardId",
	]);

	const first = currentQuestion === 0;
	const last = currentQuestion === questions.length - 1;

	const getTrackingDataWithGivenLabel = (label) => {
		return {
			category,
			label,
			action: "click",
			area: "poll",
			postId,
			adId,
			expanded,
			sermoContentCardId,
		}
	};

	return (
		<div styleName={classNames("styles.poll-nav", "typography.overline-02")}>
			<Button
				clickHandler={(e) => clickHandler(e, -1)}
				icon={<ClockIconStyled src={ChevronLeftSVG} />}
				disabled={first}
				theme="light"
				style="icon"
				size="large"
				tracking={getTrackingDataWithGivenLabel("poll-previous-question")}
			/>
			<span styleName="styles.poll-nav-text">{`${currentQuestion + 1} of ${questions.length}`}</span>
			<Button
				clickHandler={(e) => clickHandler(e, 1)}
				icon={<Icon src={ChevronRightSVG} />}
				disabled={last}
				theme="light"
				style="icon"
				size="large"
				tracking={getTrackingDataWithGivenLabel("poll-next-question")}
			/>
		</div>
	);
};

PollNav.propTypes = {
	questions: PropTypes.arrayOf(PropTypes.shape(QuestionShape)),
	currentQuestion: PropTypes.number,
	clickHandler: PropTypes.func,
};

// TODO: what is fieldsView?
export const PollDisplay = () => {
	const {
		expanded,
	} = useMemoizedContext("postUIViewing",[
		"expanded",
	]);

	const {
		adType,
		cardType,
		fieldsView,
	} = useMemoizedContext("postData",[
		"adType",
		"cardType",
		"fieldsView",
	]);

	const {
		questions,
	} = useMemoizedContext("poll", [
		"questions",
	]);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [question, setQuestion] = useState(null);

	useEffect(() => {
		if (questions) {
			setQuestion(questions[currentQuestion]);
		}
	}, [
		currentQuestion,
		questions,
	]);

	if (
		!questions?.length
		|| !question
	) {
		return null;
	}

	const changePoll = (e, offset) => {
		e.preventDefault();
		e.stopPropagation();
		setCurrentQuestion(currentQuestion + offset);
	};

	// auto advance through the poll as the user answers each question
	const onVote = () => {
		if (currentQuestion < questions.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}
		// loop back to the begining after last question is answered
		if (currentQuestion === questions.length - 1) {
			setCurrentQuestion(0);
		}
	};

	return (
		<div
			styleName={
				classNames("styles.questions",
					{ "styles.expanded": expanded },
					{ "styles.headline-and-read-more": "HeadlineAndReadMore" === fieldsView && !expanded }
				)
			}
			onClick={
				(e) => {
					if (("Ad" === cardType && "InFeedUnit" === adType) || "Question" === cardType) {
						e.stopPropagation();
					}
					e.eventSource = "poll";
				}
			}
		>
			{
				questions?.length > 1 && (
					<PollNav
						questions={questions}
						currentQuestion={currentQuestion}
						clickHandler={changePoll}
					/>
				)
			}
			<PollQuestionProvider
				key={question.id}
				question={question}
			>
				<ParentContextUpdater
					actionType={PollActionTypes.SYNC_QUESTION}
					childContext={PollQuestionContext}
					parentContext="poll"
					payloadKey="question"
				/>
				<Question
					question={question}
					key={currentQuestion}
					onVote={onVote}
				/>
			</PollQuestionProvider>
		</div>
	);
};

export default PollDisplay;
