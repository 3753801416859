import classNames from "classnames";
import PropTypes from "prop-types";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import ModalBackground from "@components/ModalBackground/ModalBackground";
import styles from "./Menu.scss";

const Menu = ({ dataComponent, contextClass, children, inline, noModalBackground, }) => (
	<>
		{
			!noModalBackground && (
				<MobileAndTabletPortrait>
					<ModalBackground menu={true} />
				</MobileAndTabletPortrait>
			)
		}
		<div
			data-component={dataComponent}
			styleName={
				classNames("styles.menu", {
					[`styles.context-${contextClass}`]: contextClass,
					"styles.floating": !inline,
				})
			}
		>
			{children}
		</div>
	</>
)

Menu.propTypes = {
	dataComponent: PropTypes.string,
	children: PropTypes.node,
	contextClass: PropTypes.string,
	inline: PropTypes.bool.isRequired,
	noModalBackground: PropTypes.bool,
};

Menu.defaultProps = {
	inline: false,
	noModalBackground: false,
}

export default Menu;
