import PropTypes from "prop-types";
import React from "react";
import getTranslation, { getCurrencyTranslation } from "@translation/translation";
import { ModalSection, ModalText, ModalFooter, ModalTitle } from "./../ModalComponents";

const LosePreferredStatusConfirm = ({
	currency,
	preferredStatusThreshold,
	cancelEventHandler,
	confirmEventHandler,
}) => {
	const cancelClickHandler = () => {
		cancelEventHandler();
	};

	const confirmClickHandler = () => {
		confirmEventHandler();
	};

	return (
		<>
			<ModalTitle>{getTranslation("frontend.modals.withdrawal.losePreferredStatusConfirm.title")}</ModalTitle>

			<ModalSection>
				<ModalText>
					{
						getTranslation(
							"frontend.modals.withdrawal.losePreferredStatusConfirm.description",
							false,
							getCurrencyTranslation(preferredStatusThreshold, currency, true)
						)
					}
				</ModalText>
			</ModalSection>

			<ModalFooter
				{...{
					cancelModalEventHandler: cancelClickHandler,
					cancelTracking: { category: "withdrawal", action: "click", label: "cancel-lose-preferred-status" },
					cancelText: getTranslation(
						"frontend.modals.withdrawal.losePreferredStatusConfirm.buttonTextChangeAmount",
						true
					),
					submitText: getTranslation(
						"frontend.modals.withdrawal.losePreferredStatusConfirm.buttonTextContinueWithdrawal",
						true
					),
					submitEventHandler: confirmClickHandler,
					submitTracking: { category: "withdrawal", action: "click", label: "accept-lose-preferred-status" },
				}}
			/>
		</>
	);
};

LosePreferredStatusConfirm.propTypes = {
	cancelEventHandler: PropTypes.func,
	currency: PropTypes.string,
	preferredStatusThreshold: PropTypes.number,
	confirmEventHandler: PropTypes.func,
};

LosePreferredStatusConfirm.defaultProps = {
	cancelEventHandler: () => {},
	confirmEventHandler: () => {},
};

export default LosePreferredStatusConfirm;
