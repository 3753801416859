import { useEffect, lazy, useMemo } from "react";
import { Route, Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import AcceptTermsAndConditionsLayout from "@components/AcceptTermsAndConditionsLayout/AcceptTermsAndConditionsLayout";
import AdPreviewDirectMessageLayout from "@components/AdPreviewLayout/AdPreviewDirectMessageLayout";
import AdPreviewLayout from "@components/AdPreviewLayout/AdPreviewLayout";
import FirstSurveyPendingVerificationLayout from "@components/FirstSurveyPendingVerificationLayout/FirstSurveyPendingVerificationLayout";
import FirstTimeLoginLayout from "@components/FirstTimeLoginLayout/FirstTimeLoginLayout";
import LeadOutcomeLayout from "@components/LeadOutcomeLayout/LeadOutcomeLayout";
import Pages from "@components/Pages/Pages";
import { base as resourceBase } from "@components/Resource/routes";
import { RunWithPeterGlobalStyle } from "@components/RunWithPeter/RunWithPeter.styles";
import { runWithPeterURL } from "@components/RunWithPeter/variables";
import ZoomEndRedirect from "@components/ZoomEndRedirect/ZoomEndRedirect";
import { useCollapsePost } from "@contexts/UI";
import { getCookie } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import { useGetMemberDataAndKeepAlive } from "./AppRoutes.hooks";
const LoadableFeedLayout = lazy(() => import(
/* webpackChunkName: "FeedLayout" */
/* webpackPrefetch: true */
"../FeedLayout/FeedLayout"));
const LoadableFeedProfileLayout = lazy(() => import(
/* webpackChunkName: "LoadableFeedProfileLayout" */
"../FeedLayout/FeedProfileLayout"));
const LoadableSearchLayout = lazy(() => import(
/* webpackChunkName: "SearchLayout" */
"../Search/SearchLayout"));
const LoadableSurveysLayout = lazy(() => import(
/* webpackChunkName: "SurveysLayout" */
"../SurveysLayout/SurveysLayout"));
const LoadableSettingsLayout = lazy(() => import(
/* webpackChunkName: "SettingsLayout" */
"../SettingsLayout/SettingsLayout"));
const LoadableAccountDeactivationLayout = lazy(() => import(
/* webpackChunkName: "AccountDeactivationLayout" */
"../AccountDeactivationLayout/AccountDeactivationLayout"));
const LoadableDrugRatingLayout = lazy(() => import(
/* webpackChunkName: "DrugRatingLayout" */
"@components/DrugRatingLayout/DrugRatingLayout"));
const LoadableCCTALayout = lazy(() => import(
/* webpackChunkName: "CCTALayout" */
"@components/CCTALayout/CCTALayout"));
const LoadableMessagingLayout = lazy(() => import(
/* webpackChunkName: "MessagingLayout" */
"@components/Messaging/MessagingLayout"));
const LoadableFirstTimeLoginLayout = lazy(() => import(
/* webpackChunkName: "FirstTimeLoginLayout" */
"@components/FirstTimeLoginLayout/FirstTimeLoginLayout"));
const LoadableAcceptTermsAndConditionsLayout = lazy(() => import(
/* webpackChunkName: "AcceptTermsAndConditionsLayout" */
"@components/AcceptTermsAndConditionsLayout/AcceptTermsAndConditionsLayout"));
const LoadableActivityLayout = lazy(() => import(
/* webpackChunkName: "ActivityLayout" */
"@components/Activity/ActivityLayout"));
const LoadableCovid19Layout = lazy(() => import(
/* webpackChunkName: "Covid19Layout" */
"@components/Covid19/Covid19Layout"));
const LoadableEventPreviewLayout = lazy(() => import(
/* webpackChunkName: "EventPreviewLayout" */
"@components/LiveEvents/EventPreviewLayout"));
const LoadableEventsLayout = lazy(() => import(
/* webpackChunkName: "EventsLayout" */
"@components/LiveEvents/EventsLayout"));
const LoadableVideoPreviewLayout = lazy(() => import(
/* webpackChunkName: "VideoPreviewLayout" */
"@components/Video/VideoPreviewLayout"));
const LoadableVideoLayout = lazy(() => import(
/* webpackChunkName: "VideoLayout" */
"@components/Video/VideoLayout"));
const LoadableLeadLinkExpired = lazy(() => import(
/* webpackChunkName: "LeadLinkExpired" */
"@components/LoggedOut/components/LeadLinkExpired/LeadLinkExpired"));
const LoadableLogin = lazy(() => import(
/* webpackChunkName: "Login" */
"@components/LoggedOut/components/Login/Login"));
const UnsubscribeSurvey = lazy(() => import(
/* webpackChunkName: "UnsubscribeSurvey" */
"@components/UnsubscribeSurvey/UnsubscribeSurvey"));
const LoadableUnsubscribe = lazy(() => import(
/* webpackChunkName: "Unsubscribe" */
"@components/LoggedOut/components/Unsubscribe/Unsubscribe"));
const LoadableRegister = lazy(() => import(
/* webpackChunkName: "Register" */
"@components/Register/Register"));
const LoadableRunWithPeter = lazy(() => import(
/* webpackChunkName: "RunWithPeter" */
"@components/RunWithPeter/RunWithPeter"));
const LoadableReadOnlyPage = lazy(() => import(
/* webpackChunkName: "ReadOnlyPage" */
"@components/ReadOnly/ReadOnlyPage"));
const LoadableResource = lazy(() => import(
/* webpackChunkName: "Resource" */
"@components/Resource/Resource"));
const LoadableResourcePreview = lazy(() => import(
/* webpackChunkName: "Resource" */
"@components/ResourcePreview"));
const feedRouteDefault = "/feed/all-sermo";
const feedRoutes = [
    "/feed/:type/post/:postId/*",
    "/feed/:type/:topic/post/:postId/*",
    "/feed/:type/:topic",
    "/feed/:type",
];
const partnerIframeRoutes = [
    {
        from: "/partner/bionical",
        to: "bionical",
    }
];
const postRegex = /(\/post)(\/\d+)(\/expanded)?(\/[^?/]+)*/;
const AppRoutes = () => {
    const collapsePost = useCollapsePost();
    const navigate = useNavigate();
    const location = useLocation();
    const background = location.state?.background;
    const postMatch = location?.pathname?.match(postRegex);
    const postRoutePlusSearch = (postMatch?.[0] ?? "") + location?.search;
    const feedRouteAndMore = feedRouteDefault + postRoutePlusSearch;
    const { loggedIn, locale, hasSetPassword, hasConfiguredProfile, hasAcceptedTermsAndConditions, trialMember, affiliateMember, showLeadLandingPage, isPostSurveyReferral, wasPostSurveyReferralVerified, showFirstSurveyPendingVerificationPage, hasBeenAuthenticatedViaToken, } = useMemoizedContext("member", [
        "loggedIn",
        "locale",
        "hasSetPassword",
        "hasConfiguredProfile",
        "hasAcceptedTermsAndConditions",
        "trialMember",
        "affiliateMember",
        "showLeadLandingPage",
        "isPostSurveyReferral",
        "wasPostSurveyReferralVerified",
        "showFirstSurveyPendingVerificationPage",
        "hasBeenAuthenticatedViaToken",
    ]);
    // when the route changes if there is no background be sure to close expanded post
    useEffect(() => {
        if (!background) {
            collapsePost();
        }
    }, [location]);
    // on initial render clear any old state
    useEffect(() => {
        let isRefresh = false;
        // Does not work in safari.
        if (window.performance?.getEntriesByType !== undefined) {
            const entries = window.performance.getEntriesByType("navigation");
            // @ts-ignore
            isRefresh = entries.length > 0 && entries[0].type === "reload";
        }
        // Deprecated, does not work in safari.
        else if (window.performance?.navigation?.type !== undefined) {
            isRefresh = window.performance.navigation.type === 1;
        }
        // Fallback to session storage check.
        else if (window.sessionStorage !== undefined) {
            isRefresh = window.sessionStorage.getItem("sermo_initial_render") !== null;
            sessionStorage.setItem("sermo_initial_render", "true");
        }
        // if it was a refresh clear old state so we get the single card feed in the background
        if (isRefresh) {
            navigate(location.pathname, {
                state: {},
                // @ts-ignore
                search: location.search || "",
            });
        }
    }, []);
    // on initial render call /api/system/onpageload
    useEffect(() => {
        if (loggedIn) {
            fetch(`/${locale}/api/system/onpageload`, {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                    "X-Sermo-MemberId": window?.sermo?.user?.memberId ?? "",
                },
                body: JSON.stringify({
                    requestPath: window.location.href,
                }),
            });
        }
    }, []);
    useGetMemberDataAndKeepAlive();
    const queryParams = useMemo(() => {
        if ("undefined" !== typeof window) {
            return { ...location };
        }
        return {};
    }, [location]);
    // @ts-ignore
    if (queryParams.pathname === runWithPeterURL) {
        return (<>
				<RunWithPeterGlobalStyle />
				<LoadableRunWithPeter />
			</>);
    }
    // @ts-ignore
    if (queryParams.pathname === "/zoom-end-redirect") {
        return <ZoomEndRedirect />;
    }
    if (loggedIn
        && !isPostSurveyReferral) {
        if (showLeadLandingPage) {
            return (<LeadOutcomeLayout />);
        }
        if (showFirstSurveyPendingVerificationPage) {
            return (<FirstSurveyPendingVerificationLayout />);
        }
        // If the user is affiliate member will accept terms and conditions using the Affiliate Member Modal
        if (!affiliateMember
            && !hasAcceptedTermsAndConditions
            && hasSetPassword) {
            return (<AcceptTermsAndConditionsLayout />);
        }
        if (!trialMember
            && !affiliateMember) {
            if (!hasAcceptedTermsAndConditions
                && hasSetPassword) {
                return (<AcceptTermsAndConditionsLayout />);
            }
            if ((!hasConfiguredProfile
                || !hasSetPassword)
                && !wasPostSurveyReferralVerified) {
                return (<FirstTimeLoginLayout />);
            }
        }
    }
    return (<Routes location={background || location}>
			{/* Index */}
			{loggedIn
            ? (<>
							<Route index path="/" element={<Navigate to={`${feedRouteDefault}${location.search}`} replace/>}/>

							{/* Feed */}
							{feedRoutes.map((route) => (<Route key={route} path={route} element={<LoadableFeedLayout />}/>))}
							<Route 
            // @ts-ignore
            exact path="/feed" element={<Navigate to={feedRouteAndMore} replace/>}/>

							{/* Logged in */}
							<Route path="/login/forgotpassword" element={<Navigate to="/settings/accountsettings" replace/>}/>
							<Route path="/login/*" element={<Navigate to={feedRouteAndMore} replace/>}/>
							<Route path="/unsubscribe" element={<Navigate to={{
                        ...queryParams,
                        pathname: "/settings/emailnotifications",
                    }} replace/>}/>

							{/* Messaging */}
							<Route path="/messages/*" element={<LoadableMessagingLayout />}/>

							{/* Post */}
							<Route path="/post/:postId/*" element={<Navigate to={feedRouteAndMore} replace/>}/>

							{/* Profile */}
							<Route path="/profile/:memberId/:section/post/:postId/*" element={<LoadableFeedProfileLayout />}/>
							<Route path="/profile/:memberId/:section" element={<LoadableFeedProfileLayout />}/>
							<Route path="/profile/:memberId" element={<Navigate to={`${location.pathname}/overview${postRoutePlusSearch}`} replace/>}/>

							{/* Drug Rating */}
							<Route path="/rate/:drugId/indication/:indicationId" element={<LoadableDrugRatingLayout />}/>
							<Route path="/rate/indication/:indicationId" element={<LoadableDrugRatingLayout />}/>
							<Route path="/rate/:drugId" element={<LoadableDrugRatingLayout />}/>
							<Route path="/rate/*" element={<LoadableDrugRatingLayout />}/>

							{/* Settings */}
							{!hasBeenAuthenticatedViaToken
                    && (<Route path="/settings/*" element={<Routes>
												<Route 
                        // @ts-ignore
                        exact path="/" element={<Navigate to="/settings/accountsettings" replace/>}/>
												<Route path="/:section/*" element={<LoadableSettingsLayout />}/>
											</Routes>}/>)}

							{/* Surveys */}
							<Route 
            // @ts-ignore
            exact path="/surveys" element={<Navigate to="/surveys/availablesurveys" replace/>}/>
							<Route path="/surveys/*" element={<LoadableSurveysLayout />}/>
						</>)
            : (<>
							<Route index path="/" element={<Navigate to="/login" replace/>}/>
							<Route path="/login/*" element={<LoadableLogin />}/>
							<Route path="/register/*" element={<LoadableRegister />}/>
							<Route path="/unsubscribe/*" element={<LoadableUnsubscribe />}/>
						</>)}
			{partnerIframeRoutes.map((route, i) => {
            const url = new URL(`${window.location.href}${feedRouteDefault}`);
            url.searchParams.set("partnerModal", route.to);
            return (<Route key={i} path={route.from} element={<Navigate to={url}/>}/>);
        })}
			<Route path="/ad-preview/:adId" element={<AdPreviewLayout />}/>
			<Route path="/ad-preview/:adId/expanded" element={<AdPreviewLayout />}/>
			<Route path="/dma-preview/*" element={<AdPreviewDirectMessageLayout />}/>
			<Route path="/covid19-barometer" element={<Navigate to="/barometer" replace/>}/>
			<Route path="/barometer" element={<LoadableCovid19Layout />}/>
			<Route path="/barometer/:segment" element={<LoadableCovid19Layout />}/>
			<Route path="/preview/event/:eventId" element={<LoadableEventPreviewLayout />}/>
			<Route path="/event/:eventId" element={<LoadableEventsLayout />}/>
			{/* Video */}
			<Route path="/preview/video/:videoId" element={<LoadableVideoPreviewLayout />}/>
			<Route path={"/video/:videoId"} element={<LoadableVideoLayout />}/>

			{/* Resource */}
			<Route path="/preview/resourceCenter/:resourceCenterId" element={<LoadableResourcePreview />}/>
			<Route path="/preview/resourceItem/:resourceItemId" element={<LoadableResourcePreview />}/>
			<Route path={`${resourceBase}/*`} element={<LoadableResource />}/>
			<Route path="/accountdeactivation/*" element={<LoadableAccountDeactivationLayout />}/>
			<Route path="/search/*" element={<LoadableSearchLayout />}/>
			<Route path="/ccta/:adId" element={<LoadableCCTALayout />}/>
			<Route path="/activity/*" element={<LoadableActivityLayout />}/>
			<Route path="/pages/*" element={<Pages />}/>
			<Route path="/firsttimelogin" element={<LoadableFirstTimeLoginLayout />}/>
			<Route path="/accepttermsandconditions" element={<LoadableAcceptTermsAndConditionsLayout />}/>
			<Route path="/read-only" element={<LoadableReadOnlyPage />}/>
			<Route path="/unsubscribe-survey" element={<UnsubscribeSurvey />}/>
			<Route path="/leadoutcome" element={<Navigate to={feedRouteDefault} replace/>}/>
			<Route path="/leadlinkexpired" element={<LoadableLeadLinkExpired />}/>

			{/* Everything else */}
			<Route path="*" element={<Navigate to={loggedIn
                ? feedRouteAndMore
                : `/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`} replace/>}/>
		</Routes>);
};
export default AppRoutes;
