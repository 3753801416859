import { useMemo } from "react";
import { ContentContainer, contentContainerClickHandler, sanitizeLinks, useContentAreaClickEventHandler, useResizeListenerToSetContentHeight, } from "@components/PostContent";
import TranslationToggle from "@components/PostContent/components/TranslationToggle";
import { useMemoizedContext } from "@hooks/Hooks";
import * as Styles from "./UserGeneratedContentExpanded.styles";
const ContentUserGenerated = () => {
    const contentAreaClickEventHandler = useContentAreaClickEventHandler();
    const resizeListener = useResizeListenerToSetContentHeight();
    const { readOnlyMember, } = useMemoizedContext("member", [
        "readOnlyMember",
    ]);
    const { showOriginalContent, } = useMemoizedContext("postUIViewing", [
        "showOriginalContent",
    ]);
    const { contentHtml, id, originalContentHtml, } = useMemoizedContext("postData", [
        "contentHtml",
        "id",
        "originalContentHtml",
    ]);
    const contentToDisplay = useMemo(() => sanitizeLinks(showOriginalContent
        ? originalContentHtml
        : contentHtml), [
        contentHtml,
        originalContentHtml,
        showOriginalContent,
    ]);
    return useMemo(() => {
        if (readOnlyMember
            || !contentToDisplay) {
            return null;
        }
        return (<>
				<ContentContainer key={id} onClick={contentContainerClickHandler}>
					{resizeListener}
					<Styles.Content dangerouslySetInnerHTML={{ __html: contentToDisplay }} $kind={"bodyLong02"} onClick={contentAreaClickEventHandler}/>
				</ContentContainer>
				<TranslationToggle />
			</>);
    }, [
        contentAreaClickEventHandler,
        contentHtml,
        contentToDisplay,
        id,
        readOnlyMember,
        resizeListener,
    ]);
};
export default ContentUserGenerated;
