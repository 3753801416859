import { Icon, ChevronLeftSVG, ChevronRightSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { createUseStyles } from "react-jss"
import useResizeAware from "react-resize-aware";
import { useMediaQuery } from "react-responsive";
import { useInterval, usePrevious } from "react-use";
import styled from "styled-components";
import SPFooter from "@components/AdUnits/Footer/SPFooter";
import Button from "@components/Button/Button";
import Featured from "@components/Card/components/Featured/Featured";
import { MobileAndTabletPortraitQuery, PointerFineQuery } from "@components/MediaQueries/MediaQueries";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import CardHeader from "@components/PostViewer/components/CardHeader";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { fetchUrl } from "@frontend/Utils";
import { useMemoizedContext, useIsElementInView } from "@hooks/Hooks";
// import layout from "../../../scss/layout.scss";
import {
	Footer,
	Title,
	FooterContentContainer,
	Headline,
	HeadlineDescriptionContainer,
	ButtonContainer,
	Infeed,
	MainSection,
	TopSection,
} from "./Infeed.styles";
import { Carousel, Wrapper } from "./InFeedCarousel.styles";

const CardHeaderContainer = styled.div`
	margin: ${({ theme }) => theme.layout.cardsSpacing};
`;

const InFeedCarouselItem = (params) => {
	const {
		item,
		itemStyle,
		onImageClick,
		showBlackBoxText,
		onCtaClick,
		onFooterAreaClick,
		forwardRef,
	} = params;

	return (
		<div
			className="item"
			style={itemStyle}
			ref={forwardRef}
		>
			<div className="image">
				<a
					href={item.ctaUrl}
					onClick={() => { onImageClick(item); }}
					target={
						item.ctaUrl?.includes(window?.location?.host)
						&& !item.ctaUrl?.includes("/ccta/")
							? "_self"
							: "_blank"
					}
					rel="noreferrer"

				>
					<img
						src={item.attachments[0].url}
						alt={item.title}
					/>
				</a>
			</div>
			<Footer
				$paddingTop={!showBlackBoxText}
				onClick={(e) => { onFooterAreaClick(e, item); }}
				className={"footer"}
			>
				<FooterContentContainer>
					<HeadlineDescriptionContainer>
						<Headline
							className="headline"
							$kind="heading01"
							dangerouslySetInnerHTML={{ __html: item.title }}
						/>
						{
							item?.additionalFooterText && (
								<TextStyled
									$kind="bodyShort02"
									dangerouslySetInnerHTML={{ __html: item.additionalFooterText }}
								/>
							)
						}
					</HeadlineDescriptionContainer>
					<ButtonContainer
						onClick={(e) => { onCtaClick(e, item); }}
					>
						<Button
							style="secondary"
							size="xsmall"
							to={item.ctaUrl}
							external={true}
							target={
								item.ctaUrl?.includes(window?.location?.host)
								&& !item.ctaUrl?.includes("/ccta/")
									? "_self"
									: "_blank"
							}
							wrap={false}
						>
							{item.ctaText}
						</Button>
					</ButtonContainer>
				</FooterContentContainer>
			</Footer>
		</div>
	)
}

const InFeedCarousel = () => {
	const [el, setEl] = useState(false);
	const defineEl = useCallback((node) => {
		if (node !== null) {
			setEl(node);
		}
	}, []);

	const trackEvent = useTrackEvent();
	const isMobileOrTabletPortrait = useMediaQuery({ query: MobileAndTabletPortraitQuery });
	const isPointerFine = useMediaQuery({ query: PointerFineQuery })
	const isElementInView = useIsElementInView();

	const isInView = useCallback(() => isElementInView(el),[el, isElementInView]);

	const {
		dispatch,
		expanded,
		mainSectionHeight,
		topSectionHeight,
	} = useMemoizedContext("postUIViewing", [
		"dispatch",
		"expanded",
		"mainSectionHeight",
		"topSectionHeight",
	]);

	const {
		id,
		adId,
		clickTags,
		showBlackBoxText,
		brandStyle,
		inFeedCarouselItems = [],
		autoRotate,
		loop,
		introduction,
	} = useMemoizedContext("postData", [
		"id",
		"adId",
		"clickTags",
		"showBlackBoxText",
		"brandStyle",
		"inFeedCarouselItems",
		"autoRotate",
		"loop",
		"introduction",
	]);

	// CAROUSEL

	const wrapper = useRef();
	const [carouselResizeListener, carouselSizes] = useResizeAware();
	const [topResizeListener, topSizes] = useResizeAware();
	const [mainResizeListener, mainSizes] = useResizeAware();
	// number of times the carousel can be moved
	const [count, setCount] = useState(0);
	// how much wider the items are than the card
	const [diff, setDiff] = useState(0);
	const cardWidth = 230 + 8; // width of card plus padding between
	const itemCount = inFeedCarouselItems.length + (loop
		? 3
		: 0);
	const cardsTotalWidth = (cardWidth * itemCount) + (isMobileOrTabletPortrait
		? 0
		: 32);
	const forceScrollingOffsetAdjustment = 35.5;

	useEffect(() => {
		if (
			topSizes.height
		) {
			dispatch({
				type: PostUIViewingActionTypes.SET_TOP_SECTION_HEIGHT,
				payload: {
					topSectionHeight: topSizes.height,
				},
			});
		}
		if (
			mainSizes.height
		) {
			dispatch({
				type: PostUIViewingActionTypes.SET_MAIN_SECTION_HEIGHT,
				payload: {
					mainSectionHeight: mainSizes.height,
				},
			});
		}
	}, [
		topSectionHeight,
		mainSectionHeight,
		dispatch,
		topSizes.height,
		mainSizes.height,
	]);

	// if the elements change size we need to recalculate the diff
	useEffect(() => {
		const { width } = carouselSizes;

		setDiff(cardsTotalWidth - width)
	}, [cardsTotalWidth, carouselSizes])

	// if the diff changes we need to recalculate the count
	useEffect(() => {
		if (diff < cardWidth) {
			setCount(1);
		} else {
			setCount(Math.ceil(diff / cardWidth));
		}
	}, [cardWidth, diff])

	// how far to "scroll"
	const [offset, setOffset] = useState(0);
	// which step of the potential steps we are on
	const [index, setIndex] = useState(0);

	// is it currently animating
	const [autoRotating, setAutoRotating] = useState(false);
	// used to control if css transitions are enabled
	// generaly tied to autoRotating but can be controlled seperatly
	const [transitionDisabled, setTransitionDisabled] = useState(autoRotating);
	// used to track if auto has started, so we don't accidentally restart
	// if it scroll out and back into view
	const [autoRotateStarted, setAutoRotateStarted] = useState(false);
	// tracks elements inview status
	const [inView, setInView] = useState(isInView());

	// updates in view status
	const updateInView = useCallback(() => {
		const iv = isInView();
		if ( inView !== iv) {
			setInView(iv);
		}
	},[isInView, inView])

	const elPrev = usePrevious(el);
	// this is to get the initial state when the element first renders
	useEffect(() => {
		if (el !== elPrev && "undefined" !== typeof elPrev) {
			updateInView();
		}
	}, [el, elPrev, updateInView])

	// set up and clean up a scroll listener to check in view
	useEffect(() => {
		// only do this if autorotate is enabled
		if (autoRotate) {
			window.addEventListener("scroll", updateInView);
		}

		return () => {
			window.removeEventListener("scroll", updateInView);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// a ref to the timeout used to delay autorotate starting
	const timeout = useRef();

	useEffect(() => {
		// only start the time if we should
		// and its inView,
		// and it hasn't already been started
		if (autoRotate && inView && !autoRotateStarted) {
			timeout.current = setTimeout(() => {
				// check that its actually still in view when the timer expires
				if (inView) {
					setAutoRotating(true);
					setAutoRotateStarted(true);
					window.removeEventListener("scroll", updateInView);
				}
			}, 2000);
		}

		// if it goes out of view clear the timeout, so it doesn't animate offscreen
		if (!inView) {
			clearTimeout(timeout.current);
		}
	// Do not include "updateInView" in the dependencies array or you will get an infinite loop
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		autoRotate,
		inView,
		autoRotateStarted,
	]);

	const [toTrack, setToTrack] = useState(inFeedCarouselItems.map(item => {
		return {
			carouselItem: item,
			tracked: false,
		};
	}));

	const shouldTrack = useCallback((i) => {
		if (!inView) {
			return false;
		}

		const { width } = carouselSizes;

		const itemLeft = i * cardWidth - offset;
		const leftLimit = 0 - (cardWidth / 2);
		const itemRight = itemLeft + cardWidth;
		const rightLimit = width + (cardWidth / 2);
		const itemCountAdjustment = (inFeedCarouselItems.length - 3) * cardWidth;

		const forceTracking = (!autoRotating
			&& (itemLeft < 0 && Math.abs(itemLeft) === ((inFeedCarouselItems.length - 1) * cardWidth))
		);
		const forceScrollingTracking = (autoRotating
			&& itemLeft < 0 && itemRight < 0 && Math.abs(itemRight)
			>= (itemCountAdjustment + forceScrollingOffsetAdjustment)
		);
		const regularTracking = (leftLimit < itemLeft && itemRight < rightLimit);

		// if the item is between our limits track it
		return (regularTracking || forceTracking || forceScrollingTracking);
	},[autoRotating, cardWidth, inFeedCarouselItems.length, inView, offset, carouselSizes]);

	const track = useCallback(() => {
		const newToTrack = [...toTrack];
		toTrack.forEach((item, i) => {
			if (shouldTrack(i)) {
				if (!newToTrack[i].tracked) {
					newToTrack[i].tracked = true;

					// waiting a bit to track image event to make sure the post-view event
					// from AdTracker will be tracked first
					setTimeout(() => {
						trackEvent({
							category: "infeedcarousel",
							action: "view",
							label: "image-" + item.carouselItem.order,
							url: item.carouselItem.ctaUrl,
							postId: id,
							adId: adId,
							expanded: expanded,
							inFeedCarouselItemOrder: item.carouselItem.order,
							inFeedCarouselItemId: item.carouselItem.id,
							carouselLoopStatus: loop,
							carouselAutoRotateStatus: autoRotate,
							area: "carousel",
							areaPosition: item.carouselItem.order,
							areaId: item.carouselItem.id,
						});
					}, 600);
				}
			}
			else {
				// item out of limits, so should be set to track again
				if (offset <= ((inFeedCarouselItems.length - 1) * cardWidth) && newToTrack[i].tracked) {
					newToTrack[i].tracked = false;
				}
			}
		});

		if ( toTrack.length !== newToTrack.length) {
			setToTrack(newToTrack);
		}
	},[adId, autoRotate, cardWidth, expanded, id, inFeedCarouselItems.length, loop, offset, shouldTrack, toTrack, trackEvent])

	// on touch devices we track when the user scrolls the carousel
	const onScroll = useCallback(() => {
		track();
	}, [track]);

	// on when using a mouse to click the buttons we track when in view
	useEffect(() => {
		if (!isPointerFine && toTrack.length && inView) {
			track();
		}
	}, [offset, carouselSizes, inView, toTrack.length, track, isPointerFine])

	// takes the current autoscrolled offset and determines the
	// current or next index
	const getNextIndex = useCallback((direction) => {
		const rawIndex = offset / cardWidth;

		if ("right" === direction) {
			return Math.ceil(rawIndex);
		}

		return Math.floor(rawIndex);
	}, [cardWidth, offset])

	const scrollLeft = useCallback(() => {
		if (autoRotating) {
			setIndex(getNextIndex("left"));
			setAutoRotating(false);
			setAutoRotateStarted(true);
		} else {
			if (index > 0) {
				setIndex(index - 1);
			}
		}

		trackEvent({
			category: "infeedcarousel" + (expanded
				? "-expanded"
				: ""),
			action: "click",
			label: "arrow-left-clicked",
			postId: id,
			adId: adId,
			expanded: expanded,
			carouselLoopStatus: loop,
			carouselAutoRotateStatus: autoRotate,
			area: "carousel",
		});
	}, [autoRotating, expanded, id, adId, loop, autoRotate, index, getNextIndex, setIndex, setAutoRotating, setAutoRotateStarted, trackEvent]);

	const [manualLooping, setManualLooping] = useState(false);

	const firstEl = useRef(false);

	const [loopIt, setLoopIt] = useState(false);

	useEffect(() => {
		if (manualLooping && transitionDisabled) {
			setIndex(0);
			setTimeout(() => {
				setManualLooping(false);
				setTransitionDisabled(autoRotating);
				setLoopIt(false);
			}, 50)
		}
	}, [autoRotating, manualLooping, transitionDisabled])

	useEffect(() => {
		if (loopIt) {
			if (loop && index >= inFeedCarouselItems.length) {
				setManualLooping(true);
				setTransitionDisabled(true);
			} else {
				setLoopIt(false);
			}
		}
	}, [inFeedCarouselItems.length, index, loop, loopIt])

	useEffect(() => {
		if (firstEl.current) {
			firstEl.current.addEventListener("transitionend", () => {
				setLoopIt(true);
			});
		}
	}, [firstEl])

	const scrollRight = useCallback(() => {
		if (autoRotating) {
			const nextIndex = getNextIndex("right");
			if (loop && nextIndex > inFeedCarouselItems.length) {
				setManualLooping(true);
				setTransitionDisabled(true);
			} else {
				setIndex(nextIndex);
			}
			setAutoRotating(false);
			setAutoRotateStarted(true);
		} else {
			clearTimeout(timeout.current);
			if (index < count) {
				setIndex(index + 1);
			}
		}

		trackEvent({
			category: "infeedcarousel" + (expanded
				? "-expanded"
				: ""),
			action: "click",
			label: "arrow-right-clicked",
			postId: id,
			adId: adId,
			expanded: expanded,
			carouselLoopStatus: loop,
			carouselAutoRotateStatus: autoRotate,
			area: "carousel",
		});
	}, [adId, autoRotate, autoRotating, count, expanded, getNextIndex, id, inFeedCarouselItems.length, index, loop, trackEvent]);

	// when the index or the count changes we need to recalculate the offset
	useEffect(() => {
		if (index < count) {
			setOffset(cardWidth * index);
		}
		if (index === count) {
			setOffset(diff);
		}
	}, [index, autoRotating, count, cardWidth, diff]) // updates when autoRotating is disabled to snap to correct index

	// update the inline translate style when the offset changes
	const getItemStyle = useCallback(() => {
		return { transform: `translateX(-${offset}px)` };
	},[offset])

	const [itemStyle, setItemStyle] = useState(getItemStyle)

	// When the offset changes we need to update the inline style that controls the
	useEffect(() => {
		requestAnimationFrame(() => {
			if (!isPointerFine && isMobileOrTabletPortrait) {
				wrapper.current.scrollTo(offset, 0);
			} else {
				setItemStyle(getItemStyle())
			}
		});
	}, [getItemStyle, isPointerFine, offset])

	useEffect(() => {
		setTransitionDisabled(autoRotating);
	}, [autoRotating])

	const fps = 60; // 60 for a good animation
	const frameTime = 1000 / fps;

	useInterval(
		() => {
			if (offset < diff) {
				if (loop && offset >= inFeedCarouselItems.length * cardWidth) {
					setIndex(0);
				} else {
					setOffset(offset + 0.5);
					setIndex(getNextIndex("left"));

					// Keeping this code here incase we want to change the animation in the future
					// // if we are in the animation phase calc how much to move
					// // otherwise dont move
					// const toMove = frame.current <= animatedFrames ?
					// cardWidth * (frame.current / animatedFrames) :
					// 0;

					// // keep the index value correct
					// const nextIndex = getNextIndex("left");

					// // update the offset to move things
					// setOffset(toMove + (cardWidth * index));

					// // update the index on the final frame of the animation
					// if (frame.current === animatedFrames) {
					// 	setIndex(getNextIndex("right"));
					// }

					// // controls when to loop
					// if (frame.current < totalFrames) {
					// 	frame.current = frame.current + 1;
					// } else {
					// 	frame.current = 1;
					// }
				}
			} else {
				setIndex(count);
				// kill the interval when the end is reached
				setAutoRotating(false);
			}
		},
		autoRotating
			? frameTime
			: null
	);

	// CAROUSEL

	// TRACKING

	const onCtaClick = useCallback((_e, item) => {
		setAutoRotating(false)
		if (!expanded) {
			if (item.clickTags?.imageClickToCTA) {
				item.clickTags.imageClickToCTA.forEach((url) => {
					fetchUrl(url);
				})
			}

			trackEvent({
				category: "infeedcarousel" + (expanded
					? "-expanded"
					: ""),
				action: "click",
				label: "cta-" + item.order + "-clicked",
				url: item.ctaUrl,
				postId: id,
				adId: adId,
				expanded: expanded,
				inFeedCarouselItemOrder: item.order,
				inFeedCarouselItemId: item.id,
				carouselLoopStatus: loop,
				carouselAutoRotateStatus: autoRotate,
				area: "cta_button",
				areaPosition: item.order,
				areaId: item.id,
			});
		}
	}, [setAutoRotating, expanded, trackEvent, id, adId, loop, autoRotate]);

	const onImageClick = useCallback((item) => {
		setAutoRotating(false);
		if (!expanded) {
			if (item.clickTags?.imageClickToCTA) {
				item.clickTags.imageClickToCTA.forEach((url) => {
					fetchUrl(url);
				})
			}

			trackEvent({
				category: "infeedcarousel" + (expanded
					? "-expanded"
					: ""),
				action: "click",
				label: "image-" + item.order + "-clicked",
				url: item.ctaUrl,
				postId: id,
				adId: adId,
				expanded: expanded,
				inFeedCarouselItemOrder: item.order,
				inFeedCarouselItemId: item.id,
				carouselLoopStatus: loop,
				carouselAutoRotateStatus: autoRotate,
				area: "image",
				areaPosition: item.order,
				areaId: item.id,
			});
		}
	}, [expanded, trackEvent, id, adId, loop, autoRotate]);

	const trackLinkClick = useCallback((e, area, item) => {
		if ("A" === e.target.tagName) {
			if (e.target.hasAttribute("href")) {
				let href = e.target.getAttribute("href");
				if (item !== undefined) {
					if (href !== item.ctaUrl) {
						let category = "infeedcarousel" + (expanded
							? "-expanded"
							: "");
						let action = "click";
						let label = "link";
						let title = e.target.textContent || e.target.temporalDivElement;

						if (area) {
							if (typeof area !== "string") {
								if (e.eventSource) {
									area = e.eventSource.area;
									action = "download";
									label = e.eventSource.document;
								}
							}
						} else {
							// setting default value if not specified
							area = "carousel";
						}

						trackEvent({
							category,
							action,
							label,
							area,
							url: href,
							urlTitle: title,
							postId: id,
							adId: adId,
							expanded,
							inFeedCarouselItemOrder: item.order,
							inFeedCarouselItemId: item.id,
							carouselLoopStatus: loop,
							carouselAutoRotateStatus: autoRotate,
						});
					}
				}
				else {
					let category = "infeedcarousel" + (expanded
						? "-expanded"
						: "");
					let action = "click";
					let label = "link";
					let title = e.target.textContent || e.target.temporalDivElement;

					if (area) {
						if (typeof area !== "string") {
							if (e.eventSource) {
								area = e.eventSource.area;
								action = "download";
								label = e.eventSource.document;
							}
						}
					} else {
						// setting default value if not specified
						area = "carousel";
					}

					trackEvent({
						category,
						action,
						label,
						area,
						url: href,
						urlTitle: title,
						postId: id,
						adId: adId,
						expanded,
						carouselLoopStatus: loop,
						carouselAutoRotateStatus: autoRotate,
					});
				}
			}
		}
	}, [adId, autoRotate, expanded, id, loop, trackEvent]);

	const onHeaderAreaClick = useCallback(e => {
		trackLinkClick(e, "header");
	}, [trackLinkClick]);

	const onContentAreaClick = useCallback(e => {
		if (clickTags?.introTextClick) {
			clickTags.introTextClick.forEach((url) => {
				fetchUrl(url);
			})
		}
		trackLinkClick(e, "content");
	}, [clickTags, trackLinkClick]);

	const onFeaturedAreaClick = useCallback(e => {
		trackLinkClick(e, "featured");
	}, [trackLinkClick]);

	const onISIAreaClick = useCallback(e => {
		trackLinkClick(e, "isi");
	}, [trackLinkClick]);

	const onFootnoteAreaClick = useCallback(e => {
		trackLinkClick(e, "footnote");
	}, [trackLinkClick]);

	const onFooterAreaClick = useCallback((e, item) => {
		trackLinkClick(e, "footer", item);
	}, [trackLinkClick]);

	// TRACKING

	return useMemo(() => (
		<Infeed
			ref={defineEl}
			className={
				`${brandStyle
					? brandStyle
					: ""
				} ${
					isMobileOrTabletPortrait
						? "mobile"
						: ""
				} ${transitionDisabled
					? "transition-disabled"
					: ""}`
			}
		>
			<ModuleWrapper
				solid={true}
				padded={false}
				border={true}
			>
				<MainSection>
					{mainResizeListener}
					<TopSection>
						{topResizeListener}
						<CardHeaderContainer>
							<CardHeader onClick={onHeaderAreaClick} />
						</CardHeaderContainer>
						<Title
							$kind="bodyShortLegacy02"
							dangerouslySetInnerHTML={{ __html: introduction }}
							onClick={onContentAreaClick}
						/>
					</TopSection>
					<div>
						<ModuleWrapper
							solid={true}
							padded={false}
						>
							<Carousel>
								<Wrapper
									$isPointerFine={isPointerFine}
									ref={wrapper}
									onScroll={
										(e) => {
											onScroll(e);
										}
									}
									onTouchStart={
										() => {
											setAutoRotating(false);
										}
									}
								>
									{carouselResizeListener}
									{
										inFeedCarouselItems.map((item, i) => (
											<InFeedCarouselItem
												key={i}
												forwardRef={
													i === 0
														? firstEl
														: null
												}
												item={item}
												itemStyle={itemStyle}
												onImageClick={onImageClick}
												showBlackBoxText={showBlackBoxText}
												onCtaClick={onCtaClick}
												onFooterAreaClick={onFooterAreaClick}
											/>
										))
									}
									{
										loop
										&& inFeedCarouselItems.map((item, i) => {
											if (i < 3) {
												return (
													<InFeedCarouselItem
														key={i}
														item={item}
														itemStyle={itemStyle}
														onImageClick={onImageClick}
														showBlackBoxText={showBlackBoxText}
														onCtaClick={onCtaClick}
														onFooterAreaClick={onFooterAreaClick}
													/>
												)
											} else {
												return null;
											}
										})
									}
								</Wrapper>
								{
									offset > 0
									&& isPointerFine
									&& <div
										className="button lefft"
									>
										<Button
											style="icon"
											theme="dark"
											icon={<Icon src={ChevronLeftSVG} />}
											clickHandler={scrollLeft}
											overlay={true}
										/>
									</div>
								}
								{
									offset < diff
									&& isPointerFine
									&& <div
										className="button right"
									>
										<Button
											style="icon"
											theme="dark"
											icon={<Icon src={ChevronRightSVG} />}
											clickHandler={scrollRight}
											overlay={true}
											disabled={loopIt}
										/>
									</div>
								}
							</Carousel>
						</ModuleWrapper>
					</div>
					<ModuleWrapper
						solid={true}
						padded={true}
						notPaddedY={true}
					>
						<Featured clickHandler={onFeaturedAreaClick} />
					</ModuleWrapper>
					<SPFooter
						inFeed={false}
						clickHandler={onFootnoteAreaClick}
						type={"footnote"}
					/>

					<SPFooter
						inFeed={false}
						clickHandler={onISIAreaClick}
						type={"isi"}
					/>
				</MainSection>
			</ModuleWrapper>
		</Infeed>
	// eslint-disable-next-line react-hooks/exhaustive-deps
	), [
		brandStyle,
		defineEl,
		diff,
		inFeedCarouselItems,
		introduction,
		isMobileOrTabletPortrait,
		isPointerFine,
		itemStyle,
		loop,
		loopIt,
		offset,
		onContentAreaClick,
		onCtaClick,
		onFeaturedAreaClick,
		onFooterAreaClick,
		onHeaderAreaClick,
		onISIAreaClick,
		onImageClick,
		onScroll,
		carouselResizeListener,
		scrollLeft,
		scrollRight,
		showBlackBoxText,
		transitionDisabled,
	]);
};

const InFeedWithCustomStyle = ({ customStyle }) => {
	const useStyles = createUseStyles({ custom: customStyle });

	const customClasses = useStyles();
	return (
		<div className={customClasses.custom}>
			<InFeedCarousel />
		</div>
	)
}

InFeedWithCustomStyle.propTypes = {
	customStyle: PropTypes.object.isRequired,
};

const InFeedStyleCheck = () => {
	const {
		customStyle = {},
		footnoteAdStyle = {},
	} = useMemoizedContext("postData", [
		"customStyle",
		"footnoteAdStyle",
	]);

	if (customStyle || footnoteAdStyle) {
		return <InFeedWithCustomStyle
			customStyle={{ ...customStyle, ...footnoteAdStyle }}
		/>
	}

	return <InFeedCarousel />
}

export default InFeedStyleCheck;
