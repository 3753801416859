import React from "react";
import ParentContextUpdater from "@components/ParentContextUpdater";
import CommentContext, { CommentDefaultValues } from "@contexts/Comment/CommentContext";
import CommentReducer from "@contexts/Comment/CommentReducer";
import { CommentActionTypes } from "@contexts/Comment/CommentReducer";
import { PostDataActionTypes } from "@contexts/PostData";
import { useMemoizedContext } from "@hooks/Hooks";
const CommentProvider = ({ children, comment = {}, }) => {
    const initializeComment = React.useCallback((comment) => {
        return {
            ...CommentDefaultValues,
            ...comment,
        };
    }, []);
    const [state, dispatch] = React.useReducer(CommentReducer, comment, initializeComment);
    const value = {
        ...state,
        dispatch,
    };
    return (<CommentContext.Provider value={value}>
			{children}
		</CommentContext.Provider>);
};
const CommentProviderWithUpdate = ({ children, comment = {}, }) => {
    const isReply = !!comment?.["parentCommentId"];
    const actionType = isReply
        ? CommentActionTypes.SYNC_REPLY
        : PostDataActionTypes.SYNC_COMMENT;
    const parentContext = isReply
        ? "comment"
        : "postData";
    const payloadKey = "comment";
    const { dispatch } = useMemoizedContext(parentContext, []);
    const parentUpdaterFunction = React.useCallback((context) => {
        if (context?.["id"]) {
            dispatch({
                type: actionType,
                payload: {
                    [payloadKey]: context,
                },
            });
        }
    }, [
        actionType,
        dispatch,
    ]);
    return (<CommentProvider comment={comment}>
			<ParentContextUpdater actionType={actionType} childContext={CommentContext} parentContext={parentContext} parentUpdaterFunction={parentUpdaterFunction} payloadKey={payloadKey}/>
			{children}
		</CommentProvider>);
};
export default CommentProviderWithUpdate;
