import { useMemo } from "react";
import { useMemoizedContext } from "@hooks/Hooks";
const useFooterContext = (type) => {
    const { expanded } = useMemoizedContext("postUIViewing", ["expanded"]);
    const { adType, attachments, attachmentsView, cardType, clickTags, fieldsView, footnoteAutoScrollDelay, footnoteLocation, footnoteStyle, footnoteText, isiAutoScrollDelay, isISIExpandable, isiLocation, isiStyle, isiText, isISIAutoSized, isFootnoteAutoSized, isFootnoteExpandable, showBlackBoxText, useConsistentScroll, } = useMemoizedContext("postData", [
        "adType",
        "attachments",
        "attachmentsView",
        "cardType",
        "clickTags",
        "fieldsView",
        "footnoteAutoScrollDelay",
        "footnoteLocation",
        "footnoteStyle",
        "footnoteText",
        "isiAutoScrollDelay",
        "isISIExpandable",
        "isiLocation",
        "isiStyle",
        "isiText",
        "isISIAutoSized",
        "isFootnoteAutoSized",
        "isFootnoteExpandable",
        "isiLocation",
        "showBlackBoxText",
        "useConsistentScroll",
    ]);
    return useMemo(() => {
        if (type === "footnote") {
            return {
                adType,
                attachments,
                attachmentsView,
                autoScrollDelay: footnoteAutoScrollDelay,
                cardType,
                expanded,
                fieldsView,
                isAutoSized: isFootnoteAutoSized,
                isExpandable: isFootnoteExpandable,
                location: footnoteLocation,
                showBlackBoxText,
                style: footnoteStyle,
                text: footnoteText,
                useConsistentScroll,
            };
        }
        else if (type === "isi") {
            return {
                adType,
                attachments,
                attachmentsView,
                autoScrollDelay: isiAutoScrollDelay,
                cardType,
                clickTags,
                expanded,
                fieldsView,
                isAutoSized: isISIAutoSized,
                isExpandable: isISIExpandable,
                location: isiLocation,
                showBlackBoxText,
                style: isiStyle,
                text: isiText,
                useConsistentScroll,
            };
        }
    }, [type]);
};
export default useFooterContext;
