import React from "react";
import MediaQuery from "react-responsive";

export const DesktopMinWidth = 960;

const DesktopQuery = `(min-width: ${DesktopMinWidth}px)`;

export { DesktopQuery };

const Desktop = ({ children }) => <MediaQuery query={DesktopQuery}>{children}</MediaQuery>

export { Desktop };

export const MobileMaxWidth = DesktopMinWidth - 1;
export const MobileMinWidth = 360;

const MobileQuery = `(max-width: ${DesktopMinWidth - 1}px)`;

export { MobileQuery };

const Mobile = ({ children }) => <MediaQuery query={MobileQuery}>{children}</MediaQuery>

export { Mobile };