import { ColorWhite } from "@sermo/design-tokens";
import { Button, Checkbox, IconButton, XSVG } from "@sermo/ui-components";
import { TextStyled } from "@sermo/ui-components/unstable";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useToggleBackdrop } from "@contexts/UI";
import { applyBorder } from "@frontend/styled";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
const Container = styled.div `
	padding: 16px;
	background-color: ${ColorWhite};
	${applyBorder("bottom")};
	display: flex;
	flex-direction: column;
	gap: 20px;

	label {
		cursor: default;
	}

	> button {
		width: 100%;
	}
`;
const TitleContainer = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Title = styled(TextStyled) `
	flex-basis: 100%;
`;
const ApplyButton = styled(Button) `
	min-width: 226px;
`;
const MobileFilterMenu = ({ open, setMenuOpen }) => {
    const toggleBackdrop = useToggleBackdrop();
    const { feedFiltersQuestions, feedFiltersDiscussions, feedFiltersPatientCases, feedFiltersMemberCountry, feedFiltersMemberSpecialties, update, } = useMemoizedContext("feed", [
        "feedFiltersQuestions",
        "feedFiltersDiscussions",
        "feedFiltersPatientCases",
        "feedFiltersMemberCountry",
        "feedFiltersMemberSpecialties",
    ]);
    const [questions, setQuestions] = useState(feedFiltersQuestions);
    const [discussions, setDiscussions] = useState(feedFiltersDiscussions);
    const [patientCases, setPatientCases] = useState(feedFiltersPatientCases);
    const [memberCountry, setMemberCountry] = useState(feedFiltersMemberCountry);
    const [memberSpecialties, setMemberSpecialties] = useState(feedFiltersMemberSpecialties);
    const reset = () => {
        setQuestions(feedFiltersQuestions);
        setDiscussions(feedFiltersDiscussions);
        setPatientCases(feedFiltersPatientCases);
        setMemberCountry(feedFiltersMemberCountry);
        setMemberSpecialties(feedFiltersMemberSpecialties);
    };
    const updateFeedFilters = () => {
        update({
            feedFiltersQuestions: questions,
            feedFiltersDiscussions: discussions,
            feedFiltersPatientCases: patientCases,
            feedFiltersMemberCountry: memberCountry,
            feedFiltersMemberSpecialties: memberSpecialties,
        });
        setMenuOpen(false);
        toggleBackdrop(false);
    };
    const handleExitClick = () => {
        if (open) {
            setMenuOpen(false);
            toggleBackdrop(false);
        }
    };
    useEffect(() => {
        if (open) {
            window.addEventListener("click", handleExitClick);
        }
        return () => {
            window.removeEventListener("click", handleExitClick);
        };
    }, [open]);
    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);
    return (<>
			<Container onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }}>
				<TitleContainer>
					<Title $kind={"heading02"}>
						{getTranslation("frontend.feedMenu.filterPostsBy")}
					</Title>
					<IconButton kind="flatNeutral" size="xsmall" icon={XSVG} onClick={handleExitClick}/>
				</TitleContainer>
				<Checkbox data-testid="Your Specialty(ies)" defaultChecked label={getTranslation("frontend.feedMenu.yourSpecialties")} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setMemberSpecialties(!memberSpecialties);
        }} onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} checked={memberSpecialties}/>
				<Checkbox data-testid="Your Country" defaultChecked label={getTranslation("frontend.feedMenu.yourCountry")} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setMemberCountry(!memberCountry);
        }} onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} checked={memberCountry}/>
			</Container>
			<Container onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }}>
				<Checkbox data-testid="Patient Cases" defaultChecked label={getTranslation("frontend.generics.patientCases")} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setPatientCases(!patientCases);
        }} onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} checked={patientCases}/>
				<Checkbox data-testid="Discussions" defaultChecked label={getTranslation("frontend.generics.discussions")} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setDiscussions(!discussions);
        }} onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} checked={discussions}/>
				<Checkbox data-testid="Questions" defaultChecked label={getTranslation("frontend.generics.questions")} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setQuestions(!questions);
        }} onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
        }} checked={questions}/>
				<ApplyButton kind={"primary"} size={"small"} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            updateFeedFilters();
        }} disabled={patientCases === feedFiltersPatientCases
            && discussions === feedFiltersDiscussions
            && questions === feedFiltersQuestions
            && memberCountry === feedFiltersMemberCountry
            && memberSpecialties === feedFiltersMemberSpecialties}>
					{getTranslation("frontend.generics.apply")}
				</ApplyButton>
			</Container>
		</>);
};
export default MobileFilterMenu;
