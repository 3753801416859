import { ColorGrey00, ColorGrey60, ColorUltraviolet50 } from "@sermo/design-tokens";
import styled from "styled-components";
import { MobileAndTabletPortraitQuery } from "@components/MediaQueries/MediaQueries";
import { CityStateZipWrap } from "./components/AddressInputs/AddressInputs.styled";

export const ButtonWrap = styled.div`
	padding-top: 8px;
	display: flex;
	flex-direction: row-reverse;

	button {
		padding: 6px 12px;
	}

	@media ${MobileAndTabletPortraitQuery} {
		padding-top: 16px;

		div {
			width: 100% !important;
        }
	}
`;

export const CheckboxText = styled.p`
	color: ${ColorGrey60};
	font-size: 12px;
	font-family: Sailec;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;

	a {
      color: inherit;
      text-decoration: underline;
    }
	a:hover {
		color: ${ColorUltraviolet50};
	}
`;

export const CheckboxWrap = styled.div`
	margin-top: 16px;
	padding-bottom: 8px;
	display: flex;

	& > ${CheckboxText} {
		padding-left: 8px;
	}
`;

export const ExpandWrap = styled.div`
	width: 100%;
	padding: 16px 16px 8px 16px;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(transparent, ${ColorGrey00} 60%);
	display: flex;
	justify-content: center;
`;

export const Header = styled.h2`
	color: rgb(16, 24, 48);
	font-size: 16px;
	font-family: Sailec;
	font-weight: 500;
	letter-spacing: -0.2px;
	line-height: 22px;
`;

export const InfoHeader = styled.h2`
	margin: 16px 0 8px;
	color: ${ColorGrey60};
	font-size: 12px;
	font-family: Sailec;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;

	& > button {
		padding: 0 8px;
		font-size: inherit;
		font-family: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		line-height: inherit;
	}
`;

export const InfoText = styled.p`
	padding-bottom: 6px;
	color: rgb(16, 24, 48);
	font-size: 12px;
	font-family: Sailec;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 16px;
`;

export const InputsWrap = styled.div`
	& > * {
      padding: 12px 0;
    }

	& > ${CityStateZipWrap} {
		padding: 0;
	}

	span {
		font-size: 14px;
	}
`;

export const Subheader = styled.h2`
	color: ${ColorGrey60};
	font-size: 13px;
	font-family: Sailec;
	font-weight: 500;
	letter-spacing: -0.16px;
	line-height: 18px;
`;

export const Wrap = styled.div`
	margin: 16px 0;
	max-height: ${(props) => (props.expanded
		? "unset"
		: "300px")};
	padding: 16px;
	border-radius: 8px;
	background: ${ColorGrey00};
	overflow: hidden;
	position: relative;

	label, [aria-label="dropdown-label"] > span {
		font-size: 12px;
		line-height: 16px;
	}
`;