import { useCallback, useEffect, useMemo, useState, } from "react";
import useResizeAware from "react-resize-aware";
import { PostUIViewingActionTypes } from "@contexts/PostUI/Viewing";
import { useTrackEvent } from "@frontend/tracking";
import { fetchUrl } from "@frontend/Utils";
import { useGetPostTrackingCategory, useMemoizedContext } from "@hooks/Hooks";
export const useContentAreaClickEventHandler = () => {
    const { clickTags, } = useMemoizedContext("postData", [
        "clickTags",
    ]);
    return useCallback((e) => {
        e.eventSource = "content";
        if (clickTags?.content) {
            clickTags?.content.forEach((url) => {
                fetchUrl(url);
            });
        }
    }, [clickTags]);
};
export const useTrackingDefaults = () => {
    const category = useGetPostTrackingCategory();
    const { adFrequency, adId, id: postId, sermoContentCardId, } = useMemoizedContext("postData", [
        "adFrequency",
        "adId",
        "id",
        "sermoContentCardId",
    ]);
    return useMemo(() => {
        return {
            adId,
            adFrequency,
            category,
            postId,
            sermoContentCardId,
        };
    }, [
        adFrequency,
        adId,
        category,
        postId,
        sermoContentCardId,
    ]);
};
export const useInlineVideos = () => {
    const trackEvent = useTrackEvent();
    const trackingDefaults = useTrackingDefaults();
    const [inlineVideos, setInlineVideos] = useState([]);
    const { cardElement } = useMemoizedContext("postUI", ["cardElement"]);
    // Expand effects
    useEffect(() => {
        if (cardElement) {
            setInlineVideos([...cardElement.querySelectorAll(".sermo-video")]);
            setTimeout(() => {
                // Hook up event tracking for videos in the rendered HTML.
                const videos = document.querySelectorAll(".Frontend-components-Editor-Editor__content video");
                // I had to wait for the play event before i could hook up the timeupdate event;
                // otherwise nothing would fire.
                for (const video of videos) {
                    video.addEventListener("play", ((event) => {
                        const playTarget = event.target;
                        if (!playTarget.dataset.attachedAlready) {
                            playTarget.dataset.attachedAlready = "true";
                            playTarget.addEventListener("timeupdate", (event) => {
                                const target = event.target;
                                try {
                                    if (!target.dataset.nextTrackingEvent) {
                                        target.dataset.nextTrackingEvent = "1";
                                    }
                                    let currentTime = target.currentTime;
                                    let progressPercent = (currentTime / target.duration) * 100;
                                    if (target.dataset.nextTrackingEvent === "1") {
                                        if (currentTime < 2) {
                                            target.dataset.nextTrackingEvent = "1";
                                        }
                                        else {
                                            target.dataset.nextTrackingEvent = "2";
                                            trackEvent({
                                                ...trackingDefaults,
                                                action: "inline-video-progress",
                                                label: "2-secs",
                                                value: 2,
                                            });
                                        }
                                    }
                                    else if (target.dataset.nextTrackingEvent === "2"
                                        && progressPercent >= 25
                                        && progressPercent < 50) {
                                        target.dataset.nextTrackingEvent = "3";
                                        trackEvent({
                                            ...trackingDefaults,
                                            action: "inline-video-progress",
                                            label: "25-percent",
                                            value: 25,
                                        });
                                    }
                                    else if (target.dataset.nextTrackingEvent === "3"
                                        && progressPercent >= 50
                                        && progressPercent < 75) {
                                        target.dataset.nextTrackingEvent = "4";
                                        trackEvent({
                                            ...trackingDefaults,
                                            action: "inline-video-progress",
                                            label: "50-percent",
                                            value: 50,
                                        });
                                    }
                                    else if (target.dataset.nextTrackingEvent === "4"
                                        && progressPercent >= 75
                                        && progressPercent < 99) {
                                        target.dataset.nextTrackingEvent = "5";
                                        trackEvent({
                                            ...trackingDefaults,
                                            action: "inline-video-progress",
                                            label: "75-percent",
                                            value: 75,
                                        });
                                    }
                                    else if (target.dataset.nextTrackingEvent === "5"
                                        && progressPercent >= 99) {
                                        target.dataset.nextTrackingEvent = "10";
                                        trackEvent({
                                            ...trackingDefaults,
                                            action: "inline-video-progress",
                                            label: "100-percent",
                                            value: 100,
                                        });
                                    }
                                }
                                catch (ex) {
                                    // eslint-disable-next-line no-console
                                    console.log("Error tracking! " + ex);
                                }
                            });
                        }
                    }));
                }
            }, 800);
        }
    }, [
        cardElement,
    ]);
    return inlineVideos;
};
export const usePartnerModalLinks = () => {
    const [partnerModalLinks, setPartnerModalLinks] = useState([]);
    const { id, } = useMemoizedContext("postData", [
        "id",
    ]);
    useEffect(() => {
        setPartnerModalLinks([...document.querySelectorAll(`.card-${id} .sermo-partner-modal-link`)]);
    }, [id]);
    return partnerModalLinks;
};
export const useResizeListenerToSetContentHeight = () => {
    const [resizeListener, sizes] = useResizeAware();
    const { contentHeight, dispatch: dispatchPostUIViewing, } = useMemoizedContext("postUIViewing", [
        "contentHeight",
    ]);
    useEffect(() => {
        if (sizes.height
            && sizes.height !== contentHeight) {
            dispatchPostUIViewing({
                type: PostUIViewingActionTypes.SET_CONTENT_HEIGHT,
                payload: {
                    contentHeight: sizes.height,
                },
            });
        }
    }, [
        contentHeight,
        dispatchPostUIViewing,
        sizes.height,
    ]);
    return resizeListener;
};
