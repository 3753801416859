import { ColorGrey100 } from "@sermo/design-tokens";
import { Alert } from "@sermo/ui-components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { CheckBox, RadioButtonList } from "@components/FormFields/FormFields";
import { SELECT_NEW, SELECT_REFILL, SELECT_REPLACE } from "@components/Modal/Modals/Withdrawals/withdrawalUtils";
import { useTrackEvent } from "@frontend/tracking/tracking";
import getTranslation from "@translation/translation";
import { ModalSection, ModalFooter } from "./../ModalComponents";

/**
 * Styled Component for alert message on refill card.
 */
const AlertStyled = styled(Alert)`
	margin: 10px 0 25px 0;
`;

/**
 * Styled Component for info message on new issued card.
 */
const NewCardStyled = styled.div`
	margin-bottom: 25px;
	color: ${ColorGrey100};
`;

const SelectWithdrawalFee = ({
	mastercardFeeType,
	isAgreeFee,
	stepBackClickHandler,
	withdrawalIssueNewCardEventHandler,
	hasActiveCard,
	feeTranslation,
}) => {
	const trackEvent = useTrackEvent();
	const [selectedWithdrawalFeeInternal, setSelectedWithdrawalFeeInternal] = useState(mastercardFeeType);
	const [agreeFee, setAgreeFee] = useState(isAgreeFee);

	/**
	 * MasterCard's withdrawal options.
	 */
	const withdrawalFeeTypes = hasActiveCard
		? [
			{
				name: getTranslation("frontend.modals.withdrawal.mastercard.selectCard.optionRefill", true),
				value: SELECT_REFILL,
			},
			{
				name: getTranslation("frontend.modals.withdrawal.mastercard.selectCard.optionReplace", true),
				value: SELECT_REPLACE,

			}
		]
		: [];

	const continueClickHandler = () => {
		withdrawalIssueNewCardEventHandler(selectedWithdrawalFeeInternal, agreeFee);
		trackEvent({
			category: "withdrawal",
			action: "click",
			label: "use-mastercard-" + (selectedWithdrawalFeeInternal !== SELECT_REFILL
				? "new"
				: "old"),
		}).then(() => {});
	};

	const agreeFeeHandler = (event) => {
		setAgreeFee(event.target.checked);
	};

	return (
		<>
			{
				<ModalSection>
					{
						hasActiveCard
							&& (
								<RadioButtonList
									className="full-width"
									name="withdrawalFeeMethods"
									contextClass="withdrawal-options"
									value={selectedWithdrawalFeeInternal}
									options={withdrawalFeeTypes}
									handleChange={setSelectedWithdrawalFeeInternal}
								/>
							)
					}
					{
						!hasActiveCard && (
							<>
								<NewCardStyled className={"body-short-02"}>
									{getTranslation("frontend.modals.withdrawal.mastercard.selectCard.messageNew", true, feeTranslation)}
								</NewCardStyled>
								<CheckBox
									name="agreeFee"
									title={getTranslation("frontend.modals.withdrawal.mastercard.selectCard.agreeFee", true, feeTranslation)}
									size={"small"}
									handleChange={agreeFeeHandler}
									checked={agreeFee}
								/>
							</>
						)
					}
					{
						SELECT_REPLACE === selectedWithdrawalFeeInternal && (
							<>
								<AlertStyled type={"error"}>
									{getTranslation("frontend.modals.withdrawal.mastercard.selectCard.alertFeeReplace", true, feeTranslation)}
								</AlertStyled>
								<CheckBox
									name="agreeFee"
									title={getTranslation("frontend.modals.withdrawal.mastercard.selectCard.agreeFeeReplace", true, feeTranslation)}
									size={"small"}
									handleChange={agreeFeeHandler}
									checked={agreeFee}
								/>
							</>
						)
					}
				</ModalSection>
			}

			<ModalFooter
				{...{
					cancelModalEventHandler: stepBackClickHandler,
					cancelText: getTranslation("frontend.generics.back", true),
					cancelTracking: { category: "withdrawal",
						action: "click",
						label: "cancel-mastercard-fee" },
					submitEventHandler: continueClickHandler,
					submitTracking: { category: "withdrawal",
						action: "click",
						label: "submit-mastercard-fee" },
					submitDisabled: selectedWithdrawalFeeInternal !== SELECT_REFILL && !agreeFee,
					submitText: getTranslation("frontend.generics.next", true),
				}}
			/>
		</>
	);
};

SelectWithdrawalFee.propTypes = {
	mastercardFeeType: PropTypes.oneOf([SELECT_REFILL, SELECT_REPLACE, SELECT_NEW]),
	isAgreeFee: PropTypes.bool,
	stepBackClickHandler: PropTypes.func.isRequired,
	withdrawalIssueNewCardEventHandler: PropTypes.func.isRequired,
	hasActiveCard: PropTypes.bool,
	feeTranslation: PropTypes.string,
};

export default SelectWithdrawalFee;
