import { ProcessingSpinner } from "@sermo/ui-components";
import classNames from "classnames";
import React, { Component } from "react";
import { useMemoizedContext } from "@hooks/Hooks";
import { useABTest, useApiEndpoint } from "@hooks/Hooks";
import typography from "../../scss/typography.scss";
import styles from "./Helpers.scss";

/**
 * when this component mounts it imports the passed component and renders it when it's ready
 * this allows easy code spliting
 * @param {any} importComponent
 */
export default function asyncComponent(importComponent) {
	class AsyncComponent extends Component {

		constructor(props) {
			super(props);

			this.state = {
				component: null,
			};
		}

		async componentDidMount() {
			const { default: component } = await importComponent();

			this.setState({
				component: component,
			});
		}

		render() {
			const C = this.state.component;

			return C
				? <C {...this.props} />
				: null;
		}

	}

	return AsyncComponent;
}

const Divider = () => <span styleName="styles.divider">•</span>;

export { Divider };

const linkHandler = url => {
	let route = url;
	let internal = false;
	if ("undefined" === typeof window) {
		return;
	}
	if (url.search(window.location.host) > -1) {
		route = url.replace(window.location.origin, "");

		if (route.search("//") === 0) {
			route = route.substring(1);
		}

		internal = true;
	}

	return {
		route,
		internal,
	};
};

export { linkHandler };

// taken from https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
const camelize = str => str
	.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0
		? word.toLowerCase()
		: word.toUpperCase())
	.replace(/\s+/g, "");

export { camelize };

const Loading = ({ contextClass }) => (
	<div
		styleName={
			classNames(["styles.loading", contextClass
				? "styles.loading-context-" + contextClass
				: ""])
		}
	>
		<div styleName="styles.spinner">
			<ProcessingSpinner
				width="100%"
				height="100%"
			/>
		</div>
	</div>
);

export { Loading };

class EB extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasErorr: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasErorr: true };
	}

	render() {
		if (this.state.hasErorr) {
			if (this.props.errorComponent) {
				return this.props.errorComponent;
			}
			return null;
		}

		return this.props.children;
	}

}

const ErrorBoundary = ({ children }) => <EB >{children}</EB>;

export { ErrorBoundary };

const caseInsensitivePropertyGetter = (object, propertyName) => object[Object.keys(object).find(k => k.toLowerCase() === propertyName.toLowerCase())];

export { caseInsensitivePropertyGetter };

const ABTest = ({ componentA, componentB, params }) => {
	const Component = useABTest(componentA, componentB);

	return <Component {...params} />;
};

export { ABTest };

const ToolTip = ({ x, y, content }) => (
	<div
		styleName={classNames("styles.tooltip", "typography.body-short-brand-00")}
		style={
			{
				top: y,
				left: x,
			}
		}
	>
		{content}
	</div>
)

export { ToolTip };

const FormFieldLabel = ({ label }) => (
	<div styleName={classNames("typography.body-short-brand-00", "styles.form-field-label")}>{label}</div>
)

export { FormFieldLabel };

const ClickStop = ({ children }) => {
	const stopClick = e => {
		e.stopPropagation();
		e.preventDefault();
	}

	return (
		<div
			styleName="styles.click-stop"
		>
			{children}
			<div
				onClick={stopClick}
				styleName="styles.click-stop-overlay"
				className="click-stop-overlay"
			/>
		</div>
	)
}

export { ClickStop };

const getLastFourDigitsOfTelephoneNumber = (phoneNumber, countryCode, availableCountries) => {
	let lastFourDigits = "";

	if (phoneNumber) {
		let number = phoneNumber;

		if (number[0] === "+") {
			const selectedCountry = availableCountries.find(
				c => c.id.toLowerCase() === countryCode.toLowerCase()
			);
			number = number.substring((selectedCountry.telephoneCode + "").length + 1);
		}

		for (let i = 0; i < number.length; i++) {
			if (i < number.length - 4) {
				lastFourDigits += "*";
			}
			else {
				lastFourDigits += number[i];
			}
		}
	}

	return lastFourDigits;
};

export { getLastFourDigitsOfTelephoneNumber };

const getObfuscateEmail = (email) => {
	let obfuscateEmail = "";

	if (email) {
		const nameLength = email.substr(0, email.indexOf("@")).length;
		const fill = (new Array(nameLength - 2)).fill("*").join("");
		obfuscateEmail = email.substr(0, 2) + fill + email.substr(email.lastIndexOf("@"));
	}

	return obfuscateEmail;
}

export { getObfuscateEmail };