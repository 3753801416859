import { TextStyled } from "@sermo/ui-components/unstable";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { applyBorder, applyZIndex } from "@frontend/styled";
export const Container = styled.div `
	${applyBorder("top")};
	${applyZIndex("top-nav")};
	background-color: ${({ theme }) => theme.color.uiBackground};
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const Left = styled.div `
	flex-basis: 40%;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;
export const Right = styled(Left) ``;
export const Link = styled(NavLink) `
	&:hover {
		text-decoration: none;
	}
`;
export const Selection = styled(TextStyled) `
	min-width: 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.color.text03};
	font-size: 10px;
	font-weight: 500;
	letter-spacing: -0.2px;
	position: relative;

	& svg {
		color: ${({ theme }) => theme.color.icon03};
	}

	${({ $isSelected }) => $isSelected && css `
		color: ${({ theme }) => theme.color.text01};

		& svg {
			color: ${({ theme }) => theme.color.interactive01};
		}
	`};
`;
export const PreviewContainer = styled(Container) `
	& > button {
		margin-top: -20px;
		width: 60px;
		height: 60px;
		border: 4px solid white;
	}
`;
