import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import styles from "./ProgressBar.scss";

const ProgressBar = ({ progressPercent }) => {
	const [preferredStatusBackgroundStyle, setPreferredStatusBackgroundStyle] = useState({ width: "0px" });
	const [preferredStatusDotStyle, setPreferredStatusDotStyle] = useState({ left: "0px" });

	const sliderElementRef = useRef();
	const sliderDotElementRef = useRef();
	const sliderProgressRef = useRef();
	const interval = useRef();

	/* Initialize component. */
	useEffect(() => {
		if (progressPercent > 0) {
			let count = 0;

			// Hack - has to use setInterval as useEffect does not always 
			// fire when the component is fully rendered, resulting in incorrect (too long) width.
			interval.current = setInterval(() => {
				count++;
				const sliderProgressWidth = sliderProgressRef.current.getBoundingClientRect().width;

				if (sliderProgressWidth === 0) {
					clearInterval(interval.current);

					const sliderWidth = sliderElementRef.current.getBoundingClientRect().width;
					const dotWidth = sliderDotElementRef.current.getBoundingClientRect().width;

					const sliderOnePercentInPixels = sliderWidth / 100.0;

					setPreferredStatusBackgroundStyle({
						width: Math.round(sliderOnePercentInPixels * progressPercent) + "px",
					});
					setPreferredStatusDotStyle({
						left: Math.round(sliderOnePercentInPixels * progressPercent - dotWidth * 0.5) + "px",
					});
				}

				if (count > 20) {
					clearInterval(interval.current);
				}
			}, 30);
		}

		return () => {
			clearInterval(interval.current);
		};
	}, [progressPercent]);

	return (
		<div styleName={classNames(["styles.progress-bar"])}>
			<div
				styleName={classNames(["styles.progress-bar-background"])}
				ref={sliderElementRef}
			></div>
			<div
				styleName={classNames(["styles.progress-bar-background-amount"])}
				style={preferredStatusBackgroundStyle}
				ref={sliderProgressRef}
			></div>
			<div
				styleName={classNames(["styles.progress-bar-dot"])}
				ref={sliderDotElementRef}
				style={preferredStatusDotStyle}
			></div>
		</div>
	);
};

ProgressBar.propTypes = {
	progressPercent: PropTypes.number,
};

ProgressBar.defaultProps = {
	progressPercent: 0,
};

export default ProgressBar;
