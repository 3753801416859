import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./ProfilePic.scss";

const ProfilePic = ({ user, contextClass, size, isLink, border, sponsored }) => {
	const { id, profilePic, logoImageUrl } = user;

	const url = profilePic
		? profilePic
		: logoImageUrl
			? logoImageUrl
			: null;

	const image = url
		? <img
			styleName="styles.image"
			src={url}
		/>
		: null;

	const classes = [
		"styles.profile-pic",
		{ [`styles.context-${contextClass}`]: contextClass },
		{ [`styles.size-${size}`]: size },
		{ "styles.border": border },
		{ "styles.sponsored": sponsored },
	];

	if (isLink && id) {
		return (
			<NavLink to={`/profile/${id}`}>
				<div
					styleName={classNames(classes)}
					data-component={"ProfilePic"}
				>
					{image}
				</div>
			</NavLink>
		);
	}

	return (
		<div
			styleName={classNames(classes)}
			data-component={"ProfilePic"}
		>
			{image}
		</div>
	);
};

ProfilePic.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.number,
		profilePic: PropTypes.string,
	}),
	contextClass: PropTypes.string,
	isLink: PropTypes.bool,
	border: PropTypes.bool,
	size: PropTypes.string,
	sponsored: PropTypes.bool,
};

ProfilePic.defaultProps = {
	isLink: false,
	border: false,
};

export default ProfilePic;
