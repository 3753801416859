import styled, { css } from "styled-components";
import { StyledMobileAndTabletPortraitQuery, StyledTabletLandscapeAndDesktopQuery } from "@components/MediaQueries/MediaQueries";
export const MainContainer = styled.div `
	${(props) => props.$applyContentStyles && css `
		min-height: calc(100dvh - ${props.$headerHeight}px - ${props.$footerHeight}px);
	`};

	${(props) => props.$loggedOut && css `
		width: 100%;
		height: 100%;
		flex-direction: column;
		display: flex;

		${StyledMobileAndTabletPortraitQuery} {
			width: 100%;
			margin-top: 0;
		}

		.main-content {
			height: 100%;
			flex-shrink: 1;
			min-height: min-content;
			background-color: ${({ theme }) => theme.color.ui01};
			overflow: auto;

			${StyledTabletLandscapeAndDesktopQuery} {
				max-width: ${({ theme }) => theme.layout.siteMaxWidth};
			}
		}
	`};
`;
