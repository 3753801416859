import { Icon, IconButton, ChevronLeftSVG, VerifiedCheckSVG, DotsHorizontalSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import useResizeAware from "react-resize-aware";
import { NavLink, useMatch } from "react-router-dom";
import styled from "styled-components";
import SermoMiniSVG from "@assets/images/sermo-mini.svg";
import Button from "@components/Button/Button";
import { MobileAndTabletPortrait } from "@components/MediaQueries/MediaQueries";
import { DetachedFooterActionTypes } from "@frontend/contexts/DetachedFooter";
import { useMemoizedContext } from "@hooks/Hooks";
import UserProps from "@props/User";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import modalStyles from "../../../Modal/Modal.scss";
import { DeleteThread } from "./DeleteThread";
import styles from "./MessageWindow.scss";
import { MessageWindowMenu } from "./MessageWindowMenu";

const VerificationCheckIconStyled = styled(Icon)`
	display: inline-block;
	width: 13px;
	height: 13px;
	margin-left: 6px;
	top: 1px;
`;

const Header = ({
	id: memberId,
	aId,
	username,
	specialties,
	blocked,
	muted,
	setMuted,
	verified,
	messageType,
	isDma,
	worksAtSermo,
}) => {
	const isDMAPreview = !!useMatch("/dma-preview/*");
	const isMounted = useRef(false);
	const triggerRef = useRef();
	const [resizeListener, sizes] = useResizeAware();
	const [shouldDelete, setShouldDelete] = useState(false);

	const {
		headerHeight,
	} = useMemoizedContext("ui", [
		"headerHeight",
	]);

	const {
		dispatch: dispatchDetachedFooter,
	} = useMemoizedContext("detachedFooter");

	const {
		messagesState,
		setMessageListThreads,
	} = useMemoizedContext("messages", [
		"messagesState",
	])

	const {
		messageListThreads,
	} = messagesState;

	useEffect(() => {
		if (
			!isMounted.current
			&& sizes.height
			&& headerHeight
			&& (
				isDma
				|| isDMAPreview
			)
		) {
			dispatchDetachedFooter({
				type: DetachedFooterActionTypes.SET_OVERRIDE_OPEN_HEIGHT,
				payload: {
					overrideOpenHeight: document.documentElement.offsetHeight - (headerHeight + sizes.height),
				},
			});
		}

		return () => {
			if (
				isDma
				|| isDMAPreview
			) {
				dispatchDetachedFooter({
					type: DetachedFooterActionTypes.SET_OVERRIDE_OPEN_HEIGHT,
					payload: {
						overrideOpenHeight: 0,
					},
				});
			}
		};
	}, [
		dispatchDetachedFooter,
		headerHeight,
		isMounted.current,
		sizes.height,
	]);

	const handleDeleteBlockMember = () => {
		let newMessageListThreads = [...messageListThreads];
		if (memberId) {
			newMessageListThreads = newMessageListThreads.filter(member => member.memberId !== memberId);
		} else if (aId) {
			newMessageListThreads = newMessageListThreads.filter(member => member.aId !== aId);
		}
		if (newMessageListThreads) {
			setMessageListThreads(newMessageListThreads);
		}
	}

	const deleteThread = () => {
		setShouldDelete(true);
		handleDeleteBlockMember();
	};

	// TODO: created styled components
	return (
		<div styleName={classNames("styles.header")}>
			{resizeListener}
			{
				shouldDelete
				&& !isDMAPreview
				&& (
					<DeleteThread
						memberId={memberId}
						aId={aId}
					/>
				)
			}
			<div styleName={classNames("styles.header-left")}>
				<MobileAndTabletPortrait>
					<Button
						to="/messages"
						icon={<Icon src={ChevronLeftSVG} />}
						style="flat"
						size="small"
						contextClass="messaging-back"
						exact
					/>
				</MobileAndTabletPortrait>
				<div styleName={classNames("styles.header-user-info")}>
					<div styleName={classNames("styles.header-user-name-and-verifycheck")}>
						<div styleName={classNames("styles.header-name", "typography.heading-brand-02")}>
							{
								!isDma
								&& (
									<NavLink to={`/profile/${memberId}`}>
										{username}
										{
											(
												verified
												|| worksAtSermo
											)
											&& (
												<VerificationCheckIconStyled
													src={
														worksAtSermo
															? SermoMiniSVG
															: VerifiedCheckSVG
													}
												/>
											)
										}
									</NavLink>
								)
							}
							{
								isDma
								&& username
							}
						</div>
					</div>
					{
						specialties && specialties.length && (
							<div styleName={classNames("styles.header-specialty", "typography.body-short-brand-00")}>
								{
									worksAtSermo
										? getTranslation("system.feeds.worksAtSermoName")
										: specialties[0].name
								}
							</div>
						)
					}
					{
						("DMA" === messageType || isDMAPreview) && (
							<div styleName={classNames("styles.header-specialty", "typography.body-short-brand-01")}>
							Sponsored
							</div>
						)
					}
				</div>
			</div>
			<div styleName={classNames("styles.header-right")}>
				<IconButton
					ref={triggerRef}
					icon={DotsHorizontalSVG}
					kind="flatNeutral"
					size="small"
				/>
				<MessageWindowMenu
					triggerRef={triggerRef}
					deleteThread={deleteThread}
					handleBlockedMember={handleDeleteBlockMember}
					blocked={blocked}
					muted={muted}
					memberId={memberId}
					setMuted={setMuted}
					isDma={isDma}
					aId={aId}
				/>
			</div>
		</div>
	);
};

Header.propTypes = {
	id: PropTypes.number,
	aId: PropTypes.number,
	username: UserProps.username,
	specialties:  PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		slug: PropTypes.string,
		id: PropTypes.number,
	})),
	verified: UserProps.verified,
	blocked: PropTypes.bool,
	muted: PropTypes.bool,
	setMuted: PropTypes.func,
	isDma: PropTypes.bool.isRequired,
	messageType: PropTypes.string,
	worksAtSermo: PropTypes.bool,
};

Header.defaultProps = {
	setMuted: () => {},
	isDma: false,
	specialties: false,
	worksAtSermo: false,
};

export { Header };
