import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import GenericConfirmModal from "@components/Modal/Modals/GenericConfirmModal";
import ModuleWrapper from "@components/ModuleWrapper/ModuleWrapper";
import { EUIKey, useCloseModal, useOpenModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useMemoizedContext } from "@hooks/Hooks";
import { useApiEndpoint } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../../../scss/typography.scss";
import styles from "./MdHonorsTeaser.scss";

const MdHonorsTeaser = ({ trackingCategory }) => {
	const apiEndpoint = useApiEndpoint();
	const closeModal = useCloseModal();
	const openModal = useOpenModal();
	const trackEvent = useTrackEvent();
	const [loading, setLoading] = useState(false);
	const [showTeaser, setShowTeaser] = useState(false);
	const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

	const toggleShowMoreInfoModalClickHandler = (e) => {
		setShowMoreInfoModal(!showMoreInfoModal);
		return false;
	};

	useEffect(() => {
		if (showMoreInfoModal) {
			trackEvent({
				category: trackingCategory,
				action: "click",
				label: "mdhonors-more-info",
			});
			openModal({
				[EUIKey.MODAL_CLOSE_METHOD]: toggleShowMoreInfoModalClickHandler,
				[EUIKey.MODAL_COMPONENT]: (
					<GenericConfirmModal
						contextClass="mdhonors"
						submitEventHandler={toggleShowMoreInfoModalClickHandler}
						text={getTranslation("frontend.modals.mdHonorsInfo.content")}
						submitText={getTranslation("frontend.generics.OK")}
					/>
				),
				[EUIKey.MODAL_LABEL]: "mdhonors-more-info",
				[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.mdHonorsInfo.title"),
			});
		}

		if (!showMoreInfoModal) {
			closeModal("mdhonors-more-info");
		}
	}, [showMoreInfoModal]);

	/* Initialize component. */
	useEffect(() => {
		setLoading(true);

		apiEndpoint("surveys/getavailablewithdrawaltypes", { includeMemberInformation: true }).then((dataResponse) => {
			if (dataResponse.status === 200) {
				dataResponse.json().then((body) => {
					if (dataResponse.ok) {
						if (body.availableWithdrawalTypes) {
							if (body.availableWithdrawalTypes.some((t) => t.code === "MDH")) {
								setShowTeaser(true);
							}
						}
					}
					setLoading(false);
				});
			}
		});
	}, []);

	return (
		<>
			{
				showTeaser && (
					<ModuleWrapper
						border={true}
						solid={true}
						styleNames={["styles.mdhonors-teaser"]}
						contextClass="rail-card--mdhonors-teaser"
					>
						<div styleName={classNames(["styles.title", "typography.overline-brand-02"])}>
							{getTranslation("frontend.railCards.mdHonorsTeaser.title")}
						</div>
						<div styleName={classNames(["styles.description", "typography.body-short-brand-00"])}>
							{getTranslation("frontend.railCards.mdHonorsTeaser.description")}
						</div>
						<a
							styleName={classNames(["styles.how-it-works", "typography.body-short-brand-00"])}
							onClick={toggleShowMoreInfoModalClickHandler}
						>
							{getTranslation("frontend.railCards.mdHonorsTeaser.openModalText")}
						</a>
						<div styleName={classNames(["styles.bottom-container"])}>
							<a
								styleName={classNames(["styles.link-to-mdhonors", "typography.body-short-brand-00"])}
								href="https://www.mdhonors.com"
								target="_blank"
								rel="noreferrer"
							>
								{getTranslation("frontend.railCards.mdHonorsTeaser.openHdhonorsComText")}
							</a>
						</div>
					</ModuleWrapper>
				)
			}
		</>
	);
};

MdHonorsTeaser.propTypes = {
	trackingCategory: PropTypes.string,
};

MdHonorsTeaser.defaultProps = {
	trackingCategory: "right-rail",
};

export default MdHonorsTeaser;
