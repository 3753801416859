import { Portal } from "@sermo/ui-components";
import React from "react";
import CustomStyleContent from "@components/DetachedFooter/components/shared/CustomStyleContent";
import ExpandableFooter from "@components/DetachedFooter/components/shared/ExpandableFooter";
import { ExpandedCardWrapper } from "@components/DetachedFooter/DetachedFooter.styles";
import { getStickyContainer } from "@components/DetachedFooter/utils";
const ExpandedCardFooter = ({ type, text, className, customStyle, }) => (<Portal container={getStickyContainer(type)}>
		<ExpandedCardWrapper>
			<ExpandableFooter type={type} customStyle={customStyle}>
				<CustomStyleContent type={type} className={className} customStyle={customStyle} text={text}/>
			</ExpandableFooter>
		</ExpandedCardWrapper>
	</Portal>);
export default ExpandedCardFooter;
