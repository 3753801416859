import { useState } from "react";
import { useMatch } from "react-router-dom";
import Button from "@components/Button/Button";
import { TextField, GlobalValidationMessage, } from "@components/FormFields/FormFields";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styles from "./ResourcePreviewSignIn.styles";
const SignIn = () => {
    const apiEndpoint = useApiEndpoint();
    const trackEvent = useTrackEvent();
    const centerMatch = useMatch("/preview/resourceCenter/:resourceCenterId");
    const itemMatch = useMatch("/preview/resourceItem/:resourceItemId");
    const resourceCenterId = centerMatch?.params?.resourceCenterId;
    const resourceItemId = itemMatch?.params?.resourceItemId;
    const [password, setPassword] = useState("");
    const [passwordValidationError, setPasswordValidationError] = useState("");
    const [globalValidationError, setGlobalValidationError] = useState("");
    const [loading, setLoading] = useState(false);
    const { loggedIn, updateMember, } = useMemoizedContext("member", [
        "loggedIn",
    ]);
    const handleLogin = (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        setGlobalValidationError("");
        const data = {
            id: Number(resourceCenterId || resourceItemId),
            password,
            type: resourceCenterId
                ? "ResourceCenter"
                : "ResourceItem",
        };
        // TODO: edge is dumb and wants the data to be a string. may need to update api to support
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/18775440/
        apiEndpoint("preview/authorize", data).then(dataResponse => {
            dataResponse.json().then(body => {
                if (body.isAuthorized) {
                    trackEvent({
                        category: "resource-preview",
                        action: "event",
                        label: "authorize-success",
                        userType: "resource-preview-member",
                    }, () => {
                        if (loggedIn) {
                            updateMember({
                                hasAccessToPreview: true,
                            });
                        }
                        else {
                            window.location.reload();
                        }
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                    trackEvent({
                        category: "resource-preview",
                        action: "event",
                        label: "authorize-failure",
                    });
                    setGlobalValidationError(body.message);
                    setPasswordValidationError(" ");
                    if (body.fields !== null) {
                        for (let property in body.fields) {
                            switch (property) {
                                case "password":
                                    setPasswordValidationError(body.fields[property].join("<br />"));
                                    break;
                            }
                        }
                    }
                }
            });
        });
    };
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordValidationError("");
    };
    return (<Styles.Container>
			<Styles.ContentBoxWider>
				<Styles.ContentBoxWiderInnerContainer>
					<Styles.FormTitle>
						{getTranslation("frontend.adPreviewPage.title")}
					</Styles.FormTitle>
					<Styles.CallToAction>
						{getTranslation("frontend.adPreviewPage.cta")}
					</Styles.CallToAction>
					<Styles.LoginForm onSubmit={handleLogin}>
						<Styles.FormFieldRow>
							<TextField name="password" type="password" autoComplete="current-password" placeholder={getTranslation("frontend.generics.password", true)} validationError={passwordValidationError} handleChange={onPasswordChange} value={password}/>
						</Styles.FormFieldRow>

						<Styles.GlobalValidationError>
							<GlobalValidationMessage className="resource-preview-error" text={globalValidationError}/>
						</Styles.GlobalValidationError>

						<Styles.FormSubmitButtonContainer>
							<Button disabled={loading} loading={loading} clickHandler={handleLogin}>
								{getTranslation("frontend.login.signInPage.loginSubmitButtonText", true)}
							</Button>
						</Styles.FormSubmitButtonContainer>
					</Styles.LoginForm>
				</Styles.ContentBoxWiderInnerContainer>
			</Styles.ContentBoxWider>
		</Styles.Container>);
};
export default SignIn;
