import { TextStyled } from "@sermo/ui-components/unstable";
import styled from "styled-components";
export const Container = styled.div `
	width: 100%;
	padding: 8px 16px;
`;
export const Item = styled.div `
	padding: 8px 0;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;

	svg {
		color: ${({ theme }) => theme.color.icon02};
	}
`;
export const Text = styled(TextStyled) `
	color: ${({ theme }) => theme.color.text02};
`;
