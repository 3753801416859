import { useState, useRef, useEffect } from "react";
import EmojiMenu from "@components/EmojiMenu/EmojiMenu";
import { TextField } from "@components/FormFields/FormFields";
import { useCommentAPI } from "@contexts/Comment";
import { CommentProvider } from "@contexts/Comment";
import { useCloseModal } from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { PostSubTypeTypes } from "@frontend/types/Post/postData";
import { usePreconditionModal } from "@frontend/Utils";
import { useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import layout from "../../../../scss/layout.scss";
import styles from "./Answer.scss";

// TODO: what happened to trial member modal?
const Answer = () => {
	const closeModal = useCloseModal();
	const commentAPI = useCommentAPI();
	const form = useRef();
	const input = useRef();
	const trackEvent = useTrackEvent();
	const [answer, setAnswer] = useState("");
	const [charCount, setCharCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const {
		adFrequency,
		adId,
		hasAnswered,
		id,
		subType,
		type,
		user,
	} = useMemoizedContext("postData", [
		"adFrequency",
		"adId",
		"hasAnswered",
		"id",
		"subType",
		"type",
		"user",
	]);

	const {
		dispatch: dispatchComment,
	} = useMemoizedContext("comment");

	const member = useMemoizedContext("member", [
		"affiliateMember",
		"id",
		"locale",
		"queryParams",
		"readOnlyMember",
		"trialMember",
	]);

	const {
		affiliateMember,
		formatMemberForFeed,
		hasAccess,
		id: userId,
		locale,
		queryParams,
		readOnlyMember,
		trialMember,
	} = member;

	let author = user?.id === userId
		? user
		: member;

	let anonymous = false;

	// if the user is the author of the post and the post was authored anon
	// then show the comment from anon user
	if ( author?.anonymous) {
		anonymous = true;
	}

	const onChange = e => {
		setAnswer(e.target.value);
		setCharCount(e.target.value.length);
	};

	useEffect(() => {
		if (loading) {
			commentAPI.addComment({
				passedData: {
					comment: answer,
					user: formatMemberForFeed(author, anonymous),
					posted: "just now",
					votes: 0,
					voted: false,
					replies: [],
				},
				requestBody: {
					postId: id,
					text: answer,
				},
			});
		}
	}, [loading]);

	const trialMemberModalInitialTrackingObject = {
		label: "answer",
		locale: locale,
		postId: id,
		adId: adId,
		adFrequency: adFrequency,
	};

	const closeTrialMemberModalEventHandler = () => {
		let trackingObject = {
			...trialMemberModalInitialTrackingObject,
			action: "close",
			queryParams: queryParams,
		};

		trackEvent(trackingObject);

		closeModal("trial-member");
	};

	const { openPreconditionModal } = usePreconditionModal(closeTrialMemberModalEventHandler);

	// don't show if editing or the user is the author
	if (
		userId === user?.id
		|| readOnlyMember
	) {
		return null;
	}

	// TODO: remove this when we have a better way to handle this
	// only render this on non-poll questions
	if (
		"Question" === type
		&& PostSubTypeTypes.POLL === subType
	) {
		return null;
	}

	// don't render the input if the user has already answered
	if (hasAnswered) {
		return null;
	}

	const submitAnswer = () => {
		if (!loading) {
			setLoading(true);
		}
	};

	const keyDownHandler = e => {
		if (e.key === "Enter") {
			if (hasAccess("canCommentOnPostsInFrontend")) {
				if (trialMember || affiliateMember) {
					openPreconditionModal(trialMemberModalInitialTrackingObject);

					e.preventDefault();
					e.stopPropagation();
				} else {
					submitAnswer();
				}
			}
		}
	};

	const insertEmoji = emoji => {
		let selectionStart = answer.length;
		const answerArray = answer.split("");
		if (input.current.selectionStart) {
			selectionStart = input.current.selectionStart;
		}
		answerArray.splice(selectionStart, 0, emoji);
		setAnswer(answerArray.join(""));
	};

	const onFormSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		submitAnswer();
	};

	return (
		<div styleName="layout.row-no-padding-no-margin">
			<div styleName="styles.answer">
				<div styleName="styles.wrapper">
					<div styleName="styles.input">
						<form
							action="true"
							ref={form}
							onSubmit={onFormSubmit}
						>
							<TextField
								maxLength={400}
								forwardRef={input}
								name={`${id}-answer`}
								value={answer}
								borderless={true}
								handleChange={onChange}
								disabled={loading}
								placeholder={getTranslation("frontend.poll.answerPlaceholder", true)}
								handleKeyDown={keyDownHandler}
								contextClass="post-answer"
							/>
						</form>
					</div>
					<div styleName="styles.character-counter">{400 - charCount}</div>
					<div styleName="styles.emoji-menu">
						<EmojiMenu clickHandler={insertEmoji} />
					</div>
				</div>
				<hr />
			</div>
		</div>
	);
};

const AnswerWithContext = () => (
	<CommentProvider>
		<Answer />
	</CommentProvider>
);

export default AnswerWithContext;